import * as React from 'react';

import { connect } from 'unistore/react';

import { checkFontsLoaded } from '@/utils/DOM/checkFontsLoaded';
import { c } from '@/utils/strings/c';

interface Props extends SizingState {
  elementClassName: string;
  fillClassName?: string;
  containerClassName?: string;
  width?: string;
  fitToText?: string;
}

interface State {
  height?: number;
}

class SkeletonNewComponent extends React.Component<Props, State> {
  public state: State = {
    height: undefined,
  };

  private ref: React.RefObject<HTMLParagraphElement>;

  constructor(props: Props) {
    super(props);

    this.ref = React.createRef();
  }

  public componentDidMount = async () => {
    await checkFontsLoaded();

    this.update();
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (
      this.props.sizing.isMobile !== prevProps.sizing.isMobile ||
      this.props.sizing.isDesktop !== prevProps.sizing.isDesktop
    ) {
      this.update();
    }
  };

  private update = () => {
    if (!this.ref.current) return;

    const { height } = this.ref.current.getBoundingClientRect();

    this.setState({ height });
  };

  public render = () => {
    const {
      elementClassName,
      containerClassName = '',
      fillClassName = 'u-bg-skeleton',
      width = '100%',
      fitToText,
    } = this.props;
    const { height } = this.state;

    return (
      <div
        className={c(
          ['u-inline-block animate-opacity', containerClassName, fillClassName],
          {
            'opacity-0': !height,
            'opacity-1': height,
          }
        )}
        style={{
          borderRadius: height ? `${height}px ${height}px` : undefined,
          height,
          width: fitToText ? 'fit-content' : width,
        }}
      >
        <div className="opacity-0">
          <p ref={this.ref} className={elementClassName}>
            {fitToText ? fitToText : 'Daisie'}
          </p>
        </div>
      </div>
    );
  };
}

export const SkeletonNew = connect(['sizing'], () => ({}))(
  SkeletonNewComponent
);
