import TagManager from 'react-gtm-module';

import { GtmEvent } from '@/enums/GtmEvent';

import { enableGtmEvents } from '@/utils/featureToggles';

export const sendGtmEvent = (event: GtmEvent, params?: object) => {
  if (!enableGtmEvents()) {
    return;
  }

  if (process.env.DAISIE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log(`gtm: ${event}${!!params ? ` - ${params}` : ''}`);
  }

  TagManager.dataLayer({
    dataLayer: {
      event,
      ...params,
    },
  });
};
