import * as React from 'react';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

import { c } from '@/utils/strings/c';

interface Props {
  id: string;
  width?: string;
  height?: string;
  side?: string;
  className?: string;
  children: React.ReactNode;
  trigger?: React.ReactNode;
  triggerRef?: React.RefObject<any>;
  triggerClassName?: string;
  triggerOnHover?: boolean;
  onClick?: (() => any) | null;
  onToggle?: (open: boolean) => any;
  onClose?: () => void;
  uiTestId?: string;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  disabled?: boolean;
  darkMode?: boolean;
  darkerMode?: boolean;
}

interface State {
  open: boolean;
  isHovering: boolean;
}

class Dropdown extends React.Component<Props, State> {
  private dropdownTriggerButton: React.RefObject<HTMLButtonElement>;
  private dropdownContent: React.RefObject<any>;

  public state = {
    open: false,
    isHovering: false,
  };

  constructor(props: Props) {
    super(props);

    this.dropdownTriggerButton = React.createRef();
    this.dropdownContent = React.createRef();
  }

  public componentWillUnmount() {
    window.removeEventListener('keydown', this.escapeListener);
  }

  private escapeListener = (event: KeyboardEvent) => {
    if (event.keyCode === 27 && this.state.open) {
      this.toggle();
    }
  };

  private toggle = () => {
    if (this.props.disabled) {
      return;
    }

    this.setState(
      ({ open }) => ({ open: !open }),
      () => {
        if (this.props.onToggle) {
          this.props.onToggle(this.state.open);
        }

        (window as any)[
          this.state.open ? 'addEventListener' : 'removeEventListener'
        ]('keydown', this.escapeListener);

        if (!this.state.open && this.props.onClose) {
          this.props.onClose();
        }
      }
    );
  };

  private openHover = () => {
    this.setState({ open: true, isHovering: true });
  };

  private closeHover = () => {
    this.setState({ open: false, isHovering: false });
  };

  private detectOrientation = (width: string) => {
    const dropdownWidth = () => {
      switch (width) {
        case 'xsmall':
          return 184;

        case 'small':
          return 200;

        case 'sm':
          return 280;

        case 'medium':
          return 332;

        case 'large':
          return 415;

        default:
          return 184;
      }
    };

    if (this.dropdownContent && this.dropdownTriggerButton.current) {
      const { left } =
        this.dropdownTriggerButton.current.getBoundingClientRect();

      if (left + dropdownWidth() > window.innerWidth) {
        return 'right';
      } else {
        return 'left';
      }
    }

    return 'left';
  };

  public render() {
    const {
      id = '',
      side,
      width = 'small',
      className = '',
      children,
      trigger = null,
      triggerRef,
      triggerClassName = '',
      triggerOnHover = false,
      onClick,
      uiTestId,
      darkMode,
      darkerMode,
    } = this.props;
    const { open, isHovering } = this.state;

    const triggerProps = {
      className: `c-dropdown__trigger u-1/1@s ${triggerClassName}`,
      'aria-expanded': !!open,
      'aria-haspopup': true,
      'aria-controls': id,
      onTouchStart: triggerOnHover
        ? onClick
          ? onClick
          : this.toggle
        : undefined,
      onClick: onClick ? onClick : this.toggle,
    };

    const orientation = side || this.detectOrientation(width);

    return (
      <div
        className={c(['c-dropdown-container', className, 'relative'], {
          'c-dropdown-container--hover-triggered': triggerOnHover,
          'c-dropdown--active': open,
        })}
      >
        <button
          type="button"
          onClick={this.toggle}
          className={c('c-dropdown__cover fill', {
            'c-dropdown--closed': !open,
          })}
          tabIndex={-1}
        />
        <div
          className="c-dropdown__content u-flex u-align-center u-h-100"
          onMouseEnter={() => {
            if (triggerOnHover && !isHovering) {
              this.openHover();
            }
          }}
          onMouseLeave={() => {
            if (triggerOnHover && isHovering) {
              this.closeHover();
            }
          }}
        >
          {typeof trigger === 'function' ? (
            trigger({
              props: triggerProps,
              open,
              openHover: this.openHover,
            })
          ) : (
            <button
              type="button"
              ref={triggerRef ? triggerRef : this.dropdownTriggerButton}
              data-ui-test-id={isUiTest() && uiTestId ? uiTestId : undefined}
              onMouseOver={() => {
                if (this.props.onMouseOver) {
                  this.props.onMouseOver();
                }

                if (triggerOnHover && open && !isHovering) {
                  this.setState({ open: false });
                }
              }}
              onMouseOut={() => {
                if (this.props.onMouseOut) {
                  this.props.onMouseOut();
                }
              }}
              {...triggerProps}
            >
              {trigger}
            </button>
          )}
          {open && <div className="c-dropdown__connector" />}
          <ReactCSSTransitionGroup
            transitionName="t-dropdown"
            transitionAppear={true}
            transitionAppearTimeout={300}
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
            component="div"
            id={id}
            ref={this.dropdownContent}
            className={c(
              `c-dropdown c-dropdown--${width} c-dropdown--${orientation} u-box-shadow u-border-radius--s`,
              {
                'c-dropdown--closed': !open,
                'c-dropdown--dark-mode': darkMode,
                'c-dropdown--darker-mode': darkerMode,
              }
            )}
          >
            {open
              ? typeof children === 'function'
                ? children({ close: this.toggle })
                : children
              : null}
          </ReactCSSTransitionGroup>
        </div>
      </div>
    );
  }
}

export { Dropdown };
