import React, { useEffect, useRef, useState } from 'react';

import { c } from '@/utils/strings/c';

interface Props {
  count: number;
  characters: string[];
  size: number | string;
}

const OriginalsQuizEmoji = ({ count, characters, size }: Props) => {
  const requestRef = useRef(0);
  const [emojis, setEmojis] = useState<any[]>([]);

  const animate = () => {
    setEmojis((prev: any) =>
      prev.map((emj: any) => ({
        ...emj,
        y: emj.y > 100 ? -5 : emj.y + 0.8 * emj.r,
      }))
    );

    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    const initEmotes = new Array(count).fill(0).map((_, i) => ({
      character: characters[i % characters.length],
      x: Math.random() * 100,
      y: -20 - Math.random() * 100,
      r: 0.1 + Math.random() * 1,
    }));

    setEmojis(initEmotes);
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  return (
    <div className="c-originals-quiz__emoji-rain--wrapper">
      {emojis.map((emoji: any, i: number) => (
        <span
          className={c('c-originals-quiz__emoji-rain--emoji', {
            'c-originals-quiz__emoji-rain--emoji__odd': i % 2 !== 0,
            'c-originals-quiz__emoji-rain--emoji__even': i % 2 === 0,
            'c-originals-quiz__emoji-rain--emoji__third': i % 3 === 0,
          })}
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          style={{
            left: `${emoji.x}%`,
            top: `${emoji.y}%`,
            transform: `scale(${emoji.r})`,
            fontSize: size,
          }}
        >
          {emoji.character}
        </span>
      ))}
    </div>
  );
};

export default OriginalsQuizEmoji;
