import React, { useEffect, useRef, useState } from 'react';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { connect } from 'unistore/react';

import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';

import { WORKSHOP_LEARN_CAROUSEL_SCROLL_MULTIPLIER } from '@/utils/constants';
import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';
interface Props extends SizingState {
  isMobile?: boolean;
  content: LearnContent[];
}

gsap.registerPlugin(ScrollTrigger);

const WorkshopWhatYouLearnCarouselComponent = ({
  isMobile,
  sizing,
  content,
}: Props) => {
  const componentContainer = useRef<any>([]);
  const heading = useRef<any>();
  const cards = useRef<any>([]);
  const cardsContainer = useRef<any>(null);

  const cardsContainerHeightRef = useRef<any>(0);
  const cardHeightRef = useRef<any>(0);
  const carouselGsapTransition = useRef<any>(null);

  const [cardsContainerHeight, setCardsContainerHeight] = useState(0);
  const [cardHeight, setCardHeight] = useState(0);
  const [transitionsLoaded, setTransitionsLoaded] = useState(false);
  const [activeCardIndex, setActiveCardIndex] = useState(0);

  useEffect(() => {
    // ScrollTrigger.refresh();
  }, [sizing]);

  const createCardsRefs = (card: any, index: number) => {
    cards.current[index] = card;
  };

  const syncPositions = () => {
    if (!cardsContainer || !cards) return;
    if (
      cardsContainer.current.clientHeight !== cardsContainerHeightRef.current
    ) {
      cardsContainerHeightRef.current = cardsContainer.current.clientHeight;
      setCardsContainerHeight(cardsContainer.current.clientHeight);
      ScrollTrigger.refresh();
    }

    if (cards.current[0].clientHeight !== cardHeightRef.current) {
      cardHeightRef.current = cards.current[0].clientHeight;
      setCardHeight(cards.current[0].clientHeight);
      ScrollTrigger.refresh();
    }
  };

  useEffect(() => {
    const carouselInterval = setInterval(syncPositions, 1000);

    return () => clearInterval(carouselInterval);
  }, []);

  // look for something like intersection observer
  useEffect(() => {
    if (cardHeight === 0 || cardsContainerHeight === 0) return;

    if (carouselGsapTransition.current) {
      carouselGsapTransition.current.scrollTrigger?.kill();
    }

    const totalCards = cards.current.length;
    const cardWidth = cards.current[0].clientWidth;
    const headingHeight = heading.current.clientHeight || 0;

    // How many px from the left is the carousel pushed
    const leftOffset = window.innerWidth / 2 - cardWidth / 2;

    const maxScroll = -(
      cardWidth * totalCards -
      window.innerWidth / 2 -
      cardWidth / 2
    );

    const containerHeight =
      totalCards * (cardWidth * WORKSHOP_LEARN_CAROUSEL_SCROLL_MULTIPLIER);

    const interval = 1 / totalCards;
    const ranges = cards.current.map((card: any, index: number) => {
      const max = interval * (index + 1);
      const min = interval * index;
      return { min, max };
    });

    carouselGsapTransition.current = gsap.fromTo(
      cards.current,
      {
        x: leftOffset,
      },
      {
        x: maxScroll,
        ease: 'none',
        scrollTrigger: {
          trigger: componentContainer.current,
          // Start from the middle of the card
          // 128 is the bottom margin of the heading
          start: () => `${headingHeight + 128 + cardHeight / 2} center`,
          // this is potentially better
          end: () =>
            `+=${
              containerHeight - (headingHeight + 128 + cardHeight / 2)
            } bottom`,
          // this works better on mobile
          // end: () => `+=${containerHeight} bottom`,
          scrub: 0,
          invalidateOnRefresh: true,
          refreshPriority: 1,
          onUpdate: (self) => {
            const progress = self.progress.toFixed(3);
            const range = ranges.find(
              (range: any) => progress >= range.min && progress <= range.max
            );
            const index = ranges.indexOf(range);
            setActiveCardIndex(index);
          },
        },
      }
    );

    setTransitionsLoaded(true);
  }, [cardHeight, cardsContainerHeight]);

  // 128 is the bottom margin of the heading
  const containerHeight =
    cards.current[0]?.clientWidth *
      WORKSHOP_LEARN_CAROUSEL_SCROLL_MULTIPLIER *
      cards.current.length +
    heading.current?.clientHeight +
    128;

  return (
    <div
      className="c-workshop-what-you-learn"
      style={{ height: containerHeight }}
      ref={componentContainer}
    >
      <h1
        className="c-workshop-sentences__h2 f-inter-medium-1 wrap u-text-center u-bold mb128 mb12@s"
        style={{ fontSize: isMobile ? '3rem' : '4rem' }}
        ref={heading}
      >
        {t("What you'll learn")}
      </h1>

      <div
        className="c-workshop-what-you-learn__cards u-overflow-x-hidden u-sticky u-sticky--t"
        style={{
          top: `calc(50vh - ${
            cardsContainerHeight ? cardsContainerHeight / 2 : 0
          }px)`,
        }}
        ref={cardsContainer}
      >
        {content.map((data, index: number) => {
          const descriptionSentences = data.description.split('.');
          let firstSentence = descriptionSentences[0];

          if (!firstSentence.endsWith('?') && !firstSentence.endsWith('!')) {
            firstSentence = firstSentence + '.';
          }

          descriptionSentences.splice(0, 1);
          const remainingSentences = descriptionSentences.join('.');
          return (
            <section
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={c(
                'c-workshop-what-you-learn__card u-flex u-flex-column',
                {
                  'c-workshop-what-you-learn__card--is-active':
                    index === activeCardIndex,
                }
              )}
              ref={(e) => createCardsRefs(e, index)}
              data-bg-color={cards.current[index] ? 'red' : 'white'}
            >
              <div className="c-workshop-what-you-learn__image relative">
                <ImageLoader
                  src={data.imageUrl}
                  className="u-border-radius--xs fill u-object-cover"
                  lazyload={false}
                />
              </div>
              <div className="u-flex u-flex-column u-justify-center u-3/4@m u-1/1@s pv48 u-text-left">
                <h3 className="f-title-2 f-inter-regular-1 u-bold mb16">
                  {data.title}
                </h3>
                <p className="u-light-grey">
                  <span className="f-text-1 f-inter-medium-1">
                    {firstSentence}
                  </span>
                  <span className="f-text-1 f-inter-light-1">
                    {remainingSentences}
                  </span>
                </p>
              </div>
            </section>
          );
        })}
      </div>
    </div>
  );
};

export const WorkshopWhatYouLearnCarousel = connect(['sizing'])(
  WorkshopWhatYouLearnCarouselComponent
);
