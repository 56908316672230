import delve from 'dlv';

import { CategoryType } from '@/enums/CategoryType';
import { HandStatus } from '@/enums/HandStatus';
import { WorkshopEventStatus } from '@/enums/WorkshopEventStatus';

import { getCuratedImageFromData } from '@/store/helpers/cinematicBackgroundHelpers';
import {
  getDefaultBackground,
  getRoomFromData,
  getRoomsFromData,
} from '@/store/helpers/roomHelpers';
import { authState } from '@/store/modules/auth';

import {
  CATEGORY_ORDER,
  DISCUSSION_CATEGORY_NAME,
  GAMING_CATEGORY_NAME,
  GENERAL_CATEGORY_NAME,
  HIDDEN_PROFILES,
  INITIAL_CONCEPT_CATEGORY_NAME,
  LOOKING_FOR_COLLABORATORS_CATEGORY_NAME,
  MAKE_UP_CATEGORY_NAME,
  TOVE_LO_CATEGORY_NAME,
  URL_AVATAR_DEFAULTS_EXT,
  URL_AVATAR_DEFAULTS_ROOT,
  URL_AVATAR_PROJECT,
  WONDERWOMEN_CATEGORY_NAME,
  WORK_IN_PROGRESS_CATEGORY_NAME,
  WORKSHOP_ROOM_CATEGORY_NAME,
} from '@/utils/constants';
import {
  getTimezoneFull,
  renderDateLabelForWorkshopBatches,
} from '@/utils/date/date-manipulation';
import { t } from '@/utils/i18n/i18n';
import { getDefaultAvatarFromTitle } from '@/utils/media/getDefaultAvatarFromTitle';
import { USER_RING_COLOURS } from '@/utils/media/userRingColour';
import { alphabetiseKeys, isEmpty } from '@/utils/objects/object-manipulation';
import { getDisplayPrice } from '@/utils/strings/currency';
import { letterToNumber } from '@/utils/strings/lerp';
import { getHash } from '@/utils/strings/string-manipulation';

import '@/utils/class-extensions/Array';

// USERS

export const getUserFromData = (user: APIUser): User => {
  let updatedUser: any = {
    id: user.id,
    hashedId: getHash(user.id), // TODO: this should probably come from backend
    ...user.attributes,
    secondaryName: user.attributes.name ? user.attributes.username : '',
    primaryName: user.attributes.name
      ? user.attributes.name
      : user.attributes.username,
    avatar: '',
    isFollowing: false,
    hasBeenBlockedBy: false,
    hasBlocked: false,
    projectCategories: [],
    collaborationInvitations: [],
    collaborationRequests: [],
    notificationPreferences: [],
    ringColour:
      USER_RING_COLOURS[
        letterToNumber(
          user.attributes.name || user.attributes.username,
          USER_RING_COLOURS.length
        )
      ],
  };

  if (user.attributes.isAdmin) {
    updatedUser.isAdmin = user.attributes.isAdmin;
  }

  if (user.attributes.isSSOUserOnly) {
    updatedUser.isSSOUserOnly = user.attributes.isSSOUserOnly;
  }

  if (user.relationships) {
    const {
      avatar: avatarData,
      currentUserData,
      location: locationData,
      roles: rolesData,
      notificationPreferences: notificationPreferencesData,
      projectCategories: projectCategoriesData,
      annotations: annotationsData,
      hostingWorkshops: hostingWorkshopsData,
      attendingWorkshops: attendingWorkshopsData,
    } = user.relationships;

    if (avatarData && avatarData.data) {
      updatedUser.avatar = avatarFallback(
        getProcessorPathFromAttachmentData(avatarData.data)
      );
    }

    if (locationData && locationData.data) {
      updatedUser.location = getLocationFromData(locationData.data);
    }

    if (rolesData && rolesData.data && rolesData.data.length) {
      updatedUser.roles = getRolesFromData(rolesData.data);
    }

    if (currentUserData) {
      updatedUser = {
        ...updatedUser,
        ...delve(currentUserData, 'data.attributes'),
      };
    }

    if (notificationPreferencesData && notificationPreferencesData.data) {
      updatedUser.notificationPreferences = alphabetiseKeys(
        getNotificationPreferencesFromData(notificationPreferencesData.data)
      );
    }

    if (projectCategoriesData && projectCategoriesData.data) {
      updatedUser.projectCategories = getCategoryPreferencesFromData(
        projectCategoriesData.data
      );
    }

    if (annotationsData && annotationsData.data) {
      updatedUser.annotations = getAnnotationsFromData(annotationsData.data);
    }

    if (attendingWorkshopsData && attendingWorkshopsData.data) {
      updatedUser.attendingWorkshops = getWorkshopsFromData(
        attendingWorkshopsData.data
      );
    }

    if (hostingWorkshopsData && hostingWorkshopsData.data) {
      updatedUser.hostingWorkshops = getWorkshopsFromData(
        hostingWorkshopsData.data
      );
    }
  }

  return updatedUser as User;
};

export const getUsersFromData = (users: APIUser[]) =>
  users
    .map(getUserFromData)
    .filter(Boolean)
    .filter((u: User) => !HIDDEN_PROFILES.includes(u.username));

// ATTACHMENTS

export const getProcessorPathFromAttachmentData = (
  attachment: APIData
): string => {
  if (!attachment || isEmpty(attachment.attributes)) return '';
  return attachment.attributes.metadata.processorMediaPath;
};

export const avatarFallback = (avatar = '', project?: boolean) => {
  if (!avatar) {
    avatar = project
      ? URL_AVATAR_PROJECT
      : `${URL_AVATAR_DEFAULTS_ROOT}1${URL_AVATAR_DEFAULTS_EXT}`;
  }

  return avatar;
};

// PROJECT CATEGORIES/INTERESTS

const getCategoryPreferenceFromData = (category: APIProjectCategory): string =>
  category.id;

const getCategoryPreferencesFromData = (
  categories: APIProjectCategory[]
): string[] => categories.map(getCategoryPreferenceFromData).filter(Boolean);

const sortCategoriesByPopularity = (categories: Category[]) =>
  categories
    .map((c: Category) => ({
      ...c,
      order: CATEGORY_ORDER[c.name] ? CATEGORY_ORDER[c.name] : 9999,
    }))
    .sort((a, b) => a.order - b.order);

// NOTIFICATIONS

const getNotificationFromData = (
  notification: APINotification
): DaisieNotification => {
  const {
    actor,
    user,
    project,
    collaborationInvitation,
    collaborationRequest,
    collaborationRequests,
    workshop,
  } = notification.relationships;

  return {
    id: notification.id,
    ...notification.attributes,
    user: actor.data ? getUserFromData(actor.data) : undefined,
    actor: user && user.data ? getUserFromData(user.data) : undefined,
    project:
      project && project.data ? getProjectFromData(project.data) : undefined,
    collaborationInvitation:
      collaborationInvitation && collaborationInvitation.data
        ? getCollaborationInvitationFromData(collaborationInvitation.data)
        : undefined,
    collaborationRequest:
      collaborationRequest && collaborationRequest.data
        ? getCollaborationRequestFromData(collaborationRequest.data)
        : undefined,
    collaborationRequests:
      collaborationRequests && collaborationRequests.data
        ? getCollaborationRequestsFromData(collaborationRequests.data)
        : undefined,
    categories: delve(project, 'data.relationships.categories.data'),
    workshop:
      workshop && workshop.data
        ? getWorkshopFromData(workshop.data)
        : undefined,
  };
};

export const getNotificationsFromData = (notifications: APINotification[]) =>
  notifications.map(getNotificationFromData).filter(Boolean);

const getNotificationPreferencesFromData = (
  notificationPreferences: APINotificationPreferences
): NotificationPreferences[] =>
  delve(notificationPreferences, 'attributes.notificationPreferences');

// PROJECTS

export const userFlagsForProject = (currentUserAttr: any) => {
  if (!currentUserAttr) return undefined;

  // TODO: tech debt: improve permissions model
  return {
    ...currentUserAttr,
    isCollaborator: currentUserAttr.isCollaborator || currentUserAttr.isOwner,
  };
};

export const attachRolesToCollaborators = (
  collaborators: User[],
  roles: any[]
) => {
  roles.forEach((role: any) => {
    const matchingCollaborator = collaborators.find(
      (collab: User) => collab.id === role.user.id
    );
    if (matchingCollaborator) {
      matchingCollaborator.collaboratorRoles = role.roles;
    }
  });

  return collaborators;
};

const getInvitationProjectFromData = (
  project: APIInvitationProject,
  curatedImages?: CuratedImage[]
): InvitationRoom => {
  const ownerData = delve(project, 'relationships.owner.data');
  const categoriesData = delve(project, 'relationships.categories.data');
  const memberCount = delve(project, 'attributes.userCount');

  const backgroundImageData = delve(
    project,
    'relationships.backgroundImage.data'
  );

  const backgroundImage: CuratedImage | undefined = backgroundImageData
    ? getCuratedImageFromData(backgroundImageData)
    : curatedImages
    ? getDefaultBackground(curatedImages)
    : undefined;

  return {
    id: project.id,
    title: project.attributes.title,
    collaboratorCount: project.attributes.userCount,
    slug: project.attributes.slug,
    description: project.attributes.description,
    owner: ownerData ? getUserFromData(ownerData) : undefined,
    categories: categoriesData
      ? getCategoriesFromData(categoriesData)
      : undefined,
    backgroundImage,
    memberCount,
  };
};

export const getProjectFromData = (project: APIProject): Project => {
  const ownerData = delve(project, 'relationships.owner.data');
  const owner = ownerData ? getUserFromData(ownerData) : undefined;

  const collaboratorData = delve(project, 'relationships.collaborators.data');
  const collaboratorRolesData = delve(
    project,
    'relationships.collaboratorRoles.data'
  );
  const collaborators = collaboratorData
    ? getUsersFromData([ownerData, ...collaboratorData])
    : [];
  const collaboratorRoles = getCollaborationRolesFromData(
    collaboratorRolesData
  );
  const collaboratorsWithRoles = attachRolesToCollaborators(
    collaborators,
    collaboratorRoles
  );

  const openRolesData = delve(project, 'relationships.openRoles.data');
  const openRoles = openRolesData
    ? getOpenRolesFromData(openRolesData)
    : undefined;

  const collaborationRequests = getCollaborationRequestsFromData(
    delve(project, 'relationships.collaborationRequests.data')
  ).filter((x) => x.status === 'pending');

  const categories: Category[] = [];
  const isLookingForCollaborators = false;

  const userFlags = userFlagsForProject(
    delve(project, 'relationships.currentUserData.data.attributes')
  );

  const roomId = delve(
    project,
    'relationships.chatRoom.data.attributes.roomID'
  );
  const rootId = delve(project, 'relationships.rootBlock.data.id');
  const privateRootId = delve(
    project,
    'relationships.privateRootBlock.data.id'
  );

  const locationData = delve(project, 'relationships.location.data');
  const location = locationData ? getLocationFromData(locationData) : undefined;

  const annotations = getAnnotationsFromData(
    delve(project, 'relationships.annotations.data')
  );

  return {
    id: project.id,
    ...project.attributes,
    ...userFlags,
    owner,
    collaborators: collaboratorsWithRoles,
    openRoles,
    categories,
    isLookingForCollaborators,
    collaboratorCount:
      project.attributes.userCount || project.attributes.totalUsersCount || 1,
    collaborationRequests,
    rootId,
    privateRootId,
    roomId,
    location,
    annotations,
  };
};

export const getProjectsFromData = (projects: APIProject[]): Project[] =>
  projects.map(getProjectFromData).filter(Boolean);

export const getProjectsAndDiscussionsFromData = (
  projectsAndDiscussions: APIRoom[],
  curatedImages?: CuratedImage[]
) =>
  projectsAndDiscussions
    .map((pd: APIRoom) => getRoomFromData({ roomData: pd, curatedImages }))
    .filter(Boolean);

// ANNOTATIONS

export const getAnnotationsFromData = (
  annotations: APIAnnotation[]
): Annotation[] => {
  if (!annotations) {
    return [];
  }

  return annotations.map(getAnnotationFromData).filter(Boolean);
};

const getAnnotationFromData = (annotation: APIAnnotation): Annotation => {
  const author = delve(annotation, 'relationships.author.data');

  return {
    id: annotation.id,
    text: annotation.attributes.text,
    annotationType: annotation.attributes.annotationType,
    author: author ? getUserFromData(author) : undefined,
  };
};

// FEATURED PROJECT

export const getFeaturedWorkshopProjectOrUserFromData = (
  data: APIRoomsList,
  curatedImages?: CuratedImage[]
) => {
  const featuredWorkshops = getWorkshopsFromData(
    delve(data, 'relationships.workshops.data')
  );
  const featuredRooms = getRoomsFromData(
    delve(data, 'relationships.projects.data'),
    curatedImages
  );
  const featuredUsers = getUsersFromData(
    delve(data, 'relationships.users.data')
  );

  const featuredWorkshop =
    featuredWorkshops && featuredWorkshops[0]
      ? featuredWorkshops[0]
      : undefined;

  const featuredRoom =
    featuredRooms && featuredRooms[0] ? featuredRooms[0] : undefined;

  const featuredUser =
    featuredUsers && featuredUsers[0] ? featuredUsers[0] : undefined;

  // If there is both a featured project and a featured workshop,
  // prioritise the workshop

  let otherData = {};

  if (featuredWorkshop) {
    otherData = {
      workshop: featuredWorkshop,
    };
  } else if (featuredRoom) {
    otherData = {
      room: featuredRoom,
    };
  } else if (featuredUser) {
    otherData = {
      user: featuredUser,
    };
  }

  return {
    id: data.id,
    title: data.attributes.title,
    description: data.attributes.description,
    type: data.attributes.type,
    ...otherData,
  };
};

// COLLABORATION ROLES

export const getCollaborationRoleFromData = (role?: any) => {
  if (!role) return undefined;

  return {
    id: role.id,
    ...role.attributes,
    roles: getRolesFromData(role.relationships.roles.data),
    user: { id: role.relationships.user.data.id },
  };
};

export const getCollaborationRolesFromData = (roles?: any[]) => {
  if (!roles) return [];

  return roles.map(getCollaborationRoleFromData).filter(Boolean);
};

// COLLABORATION REQUESTS

export const getCollaborationRequestFromData = (request?: any) => {
  if (!request) return undefined;

  const requesterData = delve(request, 'relationships.requester.data');
  const projectData = delve(request, 'relationships.project.data');

  return {
    id: request.id,
    ...request.attributes,
    requester: requesterData ? getUserFromData(requesterData) : undefined,
    project: projectData ? { id: projectData.id } : undefined,
  };
};

export const getCollaborationRequestsFromData = (requests?: any[]) => {
  if (!requests) return [];

  return requests.map(getCollaborationRequestFromData).filter(Boolean);
};

// COLLABORATION INVITATIONS

const getCollaborationInvitationFromData = (
  invite: APIInvitation,
  curatedImages?: CuratedImage[]
): CollaborationInvitation => {
  const projectData = delve(invite, 'relationships.project.data');
  const inviterData = delve(invite, 'relationships.inviter.data');
  const inviteeData = delve(invite, 'relationships.invitee.data');

  return {
    id: invite.id,
    ...invite.attributes,
    room: projectData
      ? getInvitationProjectFromData(projectData, curatedImages)
      : undefined,
    inviter: inviterData ? getUserFromData(inviterData) : undefined,
    invitee: inviteeData ? getUserFromData(inviteeData) : undefined,
  };
};

export const getCollaborationInvitationsFromData = (
  invites: APIInvitation[],
  curatedImages?: CuratedImage[]
): CollaborationInvitation[] =>
  invites
    .map((i: APIInvitation) =>
      getCollaborationInvitationFromData(i, curatedImages)
    )
    .filter(Boolean);

// MARKETING PREFERENCES

const getMarketingPreferenceFromData = (preference?: any) => {
  if (!preference) return undefined;

  return {
    uuid: preference.uuid,
    marketingPrefsType: preference.marketingPrefsType,
    isAccepted: preference.isAccepted,
  };
};

export const getMarketingPreferencesFromData = (preferences?: any[]) => {
  if (!preferences) return [];

  return preferences
    .map(getMarketingPreferenceFromData)
    .filter(Boolean)
    .alphabetiseByKey('marketingPrefsType');
};

// CATEGORIES

const categoryTypeMapping = {
  [DISCUSSION_CATEGORY_NAME]: CategoryType.entity,
  [GENERAL_CATEGORY_NAME]: CategoryType.discussion_only,
  [LOOKING_FOR_COLLABORATORS_CATEGORY_NAME]: CategoryType.flag,
  [WORK_IN_PROGRESS_CATEGORY_NAME]: CategoryType.status,
  [INITIAL_CONCEPT_CATEGORY_NAME]: CategoryType.status,
  [WONDERWOMEN_CATEGORY_NAME]: CategoryType.closed_campaign,
  [TOVE_LO_CATEGORY_NAME]: CategoryType.closed_campaign,
  [MAKE_UP_CATEGORY_NAME]: CategoryType.discontinued,
  [GAMING_CATEGORY_NAME]: CategoryType.discontinued,
  [WORKSHOP_ROOM_CATEGORY_NAME]: CategoryType.flag,
};

export const getCategoryFromData = (item: APIProjectCategory): Category => {
  const name = item.attributes.name;
  const slug = name.toLowerCase().replace(/\s/g, '-');
  const categoryType = categoryTypeMapping[name] || CategoryType.industry;

  return {
    id: item.id,
    ...item.attributes,
    slug,
    image:
      delve(item, 'picture.attachment.metadata.processorMediaPath') ||
      `/images/categories/${slug}.jpg`,
    categoryType,
  };
};

export const getCategoriesFromData = (
  items: APIProjectCategory[]
): Category[] => items.map(getCategoryFromData).filter(Boolean);

const orderCategoriesByPreference = (chosen: string[], all: Category[]) => {
  const ordered: Category[] = [];
  const rest: Category[] = [];

  all.map((category: Category) => {
    if (chosen.indexOf(category.id) > -1) {
      ordered.push(category);
    } else {
      rest.push(category);
    }
  });
  return [...ordered, ...rest];
};

export const processCategories = async (
  categories: Category[],
  user?: User
) => {
  const industries = sortCategoriesByPopularity(
    categories.filter((c) => c.categoryType === CategoryType.industry)
  );
  const statuses = categories.filter(
    (c) => c.categoryType === CategoryType.status
  );
  const flags = categories.filter((c) => c.categoryType === CategoryType.flag);
  const campaigns = categories.filter(
    (c) => c.categoryType === CategoryType.campaign
  );
  const closedCampaigns = categories.filter(
    (c) => c.categoryType === CategoryType.closed_campaign
  );
  const entities = categories.filter(
    (c) => c.categoryType === CategoryType.entity
  );
  const interests = industries;

  const discussionOnlyCategories = categories.filter(
    (c) => c.categoryType === CategoryType.discussion_only
  );
  const discussionsCategories = [...discussionOnlyCategories, ...industries];

  let orderedInterests: Category[] = [];
  let orderedIndustries: Category[] = [];
  let interestsOnly: Category[] = [];
  let nonInterestsOnly: Category[] = [];

  if (user && user.projectCategories) {
    orderedInterests = orderCategoriesByPreference(
      user.projectCategories,
      interests
    );
    orderedIndustries = orderCategoriesByPreference(
      user.projectCategories,
      industries
    );

    interestsOnly = user
      ? industries.filter((i: Category) => {
          if (!user) return false;
          return user.projectCategories.includes(i.id);
        })
      : [];

    nonInterestsOnly = user
      ? industries.filter((i: Category) => {
          if (!user) return false;
          return !user.projectCategories.includes(i.id);
        })
      : [];
  }

  const lookingForCollaboratorsCategory =
    categories.find(
      (c: Category) => c.name === LOOKING_FOR_COLLABORATORS_CATEGORY_NAME
    ) || undefined;

  const discussionCategory: Category | undefined =
    categories.find((c: Category) => c.name === DISCUSSION_CATEGORY_NAME) ||
    undefined;

  const workshopRoomCategory: Category | undefined =
    categories.find((c: Category) => c.name === WORKSHOP_ROOM_CATEGORY_NAME) ||
    undefined;

  return {
    allCategories: categories,
    interests,
    orderedInterests,
    interestsOnly,
    nonInterestsOnly,
    industries,
    orderedIndustries,
    campaigns,
    closedCampaigns,
    statuses,
    flags,
    entities,
    discussionCategory,
    lookingForCollaboratorsCategory,
    discussionOnlyCategories,
    discussionsCategories,
    workshopRoomCategory,
  };
};
// ORIGINALS ATTRIBUTES

const getWorkshopOriginalAttributeFromData = (
  item: APIWorkshopOriginalAttributes
): IWorkshopOriginalAttributeOptions => {
  const sections: any[] = item.attributes.classStructure.sections;

  return {
    id: item.id,
    benefits: item.attributes.benefits,
    workshopOutcomes: item.attributes.workshopOutcomes,
    classStructure: {
      title: item.attributes.classStructure.title,
      description: item.attributes.classStructure.description,
      sections: sections,
    },
    gallery: item.attributes.gallery,
  };
};

// ROLES

const getRoleFromData = (item: APIRole): Role => ({
  id: item.id,
  ...item.attributes,
});

export const getRolesFromData = (items: APIRole[]): Role[] =>
  items.map(getRoleFromData).filter(Boolean);

export const getOpenRolesFromData = (items: APIOpenRole[]): OpenRole[] =>
  items.map((role: APIOpenRole) => ({
    id: role.id,
    roleId: delve(role, 'relationships.role.data.id'),
    roleName: delve(role, 'relationships.role.data.attributes.name'),
    description: delve(role, 'attributes.description'),
  }));

// LOCATION

export const getLocationFromData = (item: APILocation): DaisieLocation => ({
  id: item.id,
  name: item.attributes.name,
  placeId: item.attributes.placeId,
});

// WORKSHOPS

export const getWorkshopSectionFromData = (
  section: APIWorkshopSection
): WorkshopSection => ({
  ...section,
  image:
    section.image && section.image.processorPath
      ? section.image.processorPath
      : undefined,
});

export const getWorkshopSectionsFromData = (
  workshopSections: APIWorkshopSection[]
): WorkshopSection[] =>
  workshopSections.map(getWorkshopSectionFromData).filter(Boolean);

const getTimeStatuses = (startString: string, endString: string) => {
  const now = new Date().getTime();
  const start = new Date(startString).getTime();
  const end = new Date(endString).getTime();

  const hasEnded = end < now;
  const hasElapsed = start < now;
  const isUpcoming = start > now;

  return { hasEnded, hasElapsed, isUpcoming };
};

export const getWorkshopSessionFromData = (
  workshopSession: APIWorkshopSession
): WorkshopSession => ({
  id: workshopSession.id,
  ...workshopSession.attributes,
  ...getTimeStatuses(
    workshopSession.attributes.start,
    workshopSession.attributes.end
  ),
});

export const getWorkshopSessionsFromData = (
  workshopSessions: APIWorkshopSession[]
): WorkshopSession[] =>
  workshopSessions.map(getWorkshopSessionFromData).filter(Boolean);

export const getWorkshopBatchFromData = (
  workshopBatch: APIWorkshopBatch
): WorkshopBatch => {
  const { attributes, relationships } = workshopBatch;

  const userFlags = delve(relationships, 'currentUserData.data.attributes');

  const sessionsData = delve(relationships, 'sessions.data');
  const sessions = sessionsData
    ? getWorkshopSessionsFromData(sessionsData).sort(sortSessionsByStartDate)
    : undefined;

  const isFree = attributes.userAccess
    ? attributes.userAccess === 'free'
    : undefined;
  const isSoldOut = attributes.spacesRemaining === 0;

  const projectData = delve(relationships, 'project.data');
  const project = projectData ? getProjectFromData(projectData) : undefined;

  const workshopData = delve(relationships, 'workshop.data');
  const workshop = workshopData ? getWorkshopFromData(workshopData) : undefined;

  return {
    id: workshopBatch.id,
    ...attributes,
    ...userFlags,
    sessionCount: sessions ? sessions.length : undefined,
    ...getTimeStatuses(attributes.start, attributes.end),
    isFree,
    isSoldOut,
    sessions,
    project,
    workshop,
  };
};

export const getWorkshopBatchesFromData = (
  workshopBatches: APIWorkshopBatch[]
): WorkshopBatch[] =>
  workshopBatches.map(getWorkshopBatchFromData).filter(Boolean);

export const getWorkshopFromData = (workshop: APIWorkshop): Workshop => {
  const { attributes, relationships } = workshop;

  const userFlags = delve(relationships, 'currentUserData.data.attributes');

  const categoriesData =
    delve(relationships, 'projectCategories.data') ||
    delve(relationships, 'categories.data');
  const categories = categoriesData
    ? getCategoriesFromData(categoriesData)
    : [];

  const coverMediaData = delve(relationships, 'coverMedia.data');
  const coverMedia =
    getProcessorPathFromAttachmentData(coverMediaData) ||
    getDefaultAvatarFromTitle(attributes.title);

  const mentorData = delve(relationships, 'mentor.data');
  const mentor = mentorData ? getUserFromData(mentorData) : undefined;

  const mentorWorkMediaData = delve(relationships, 'mentorWorkMedia.data');
  const mentorWorkMedia = mentorWorkMediaData
    ? getProcessorPathFromAttachmentData(mentorWorkMediaData)
    : undefined;

  const sectionsData = delve(attributes, 'sections');
  const sections = sectionsData
    ? getWorkshopSectionsFromData(sectionsData)
    : undefined;

  const batchesData = delve(relationships, 'batches.data');
  const batches = batchesData
    ? getWorkshopBatchesFromData(batchesData).sort(sortBatchesByStartDate)
    : undefined;

  const whenLabel = batches
    ? {
        short: renderDateLabelForWorkshopBatches(batches, 'short'),
        long: renderDateLabelForWorkshopBatches(batches, 'long'),
      }
    : undefined;

  const timezone = getTimezoneFull(attributes.start);

  // A workshop has elapsed when the first session of
  // its last batch has started
  const hasElapsed = batches ? batches[batches.length - 1].hasElapsed : false;

  const isSoldOut = attributes.spacesRemaining === 0;

  const isHost = mentor
    ? mentor.username === authState.auth.user?.username
    : false;

  // A workshop is free if all batches are free
  const isFree = attributes.userAccess && attributes.userAccess === 'free';

  const batchesAvailable = batches?.filter(filterAvailableBatches).length ?? 0;

  const nextBatchStart =
    batchesAvailable > 0 && batches
      ? batches.filter(filterAvailableBatches).sort(sortBatchesByStartDate)[0]
          .start
      : undefined;

  // Figure out if the current user has attended all the batches they registered for
  const attendingBatches =
    batches?.filter((b: WorkshopBatch) => b.isAttending).length ?? 0;
  const currentUserElapsedBatches =
    batches?.filter((b: WorkshopBatch) => b.isAttending && b.hasElapsed)
      .length ?? [];
  const hasAttended =
    attendingBatches > 0 && attendingBatches === currentUserElapsedBatches;

  // isInProgress
  let isInProgress: boolean | undefined = undefined;

  if (batches && batches[0]) {
    const now: Date = new Date();
    const firstBatchStart: Date = new Date(batches[0].start);
    const lastBatchEnd: Date = new Date(batches[batches.length - 1].end);

    isInProgress =
      now.getTime() >= firstBatchStart.getTime() &&
      now.getTime() < lastBatchEnd.getTime();
  }

  // onDemandMediaCatchupDuration
  let onDemandMediaCatchupDuration: number | undefined = undefined;
  const onDemandMedias = delve(relationships, 'onDemandMedias.data');

  if (!!onDemandMedias && onDemandMedias[0]) {
    onDemandMediaCatchupDuration =
      onDemandMedias[0]?.relationships?.videoStream?.data?.attributes?.duration;
  }

  const workshopOriginalAttributesData = delve(
    relationships,
    'workshopOriginalAttributes.data'
  );

  const workshopOriginalAttributeOptions = workshopOriginalAttributesData
    ? getWorkshopOriginalAttributeFromData(workshopOriginalAttributesData)
    : undefined;

  return {
    id: workshop.id,
    ...attributes,
    ...userFlags,
    whenLabel,
    timezone,
    isSoldOut,
    hasElapsed,
    isFree,
    isHost,
    categories,
    coverMedia,
    mentor,
    mentorWorkMedia,
    sections,
    batches,
    batchesAvailable,
    nextBatchStart,
    hasAttended,
    isInProgress,
    onDemandMediaCatchupDuration,
    workshopOriginalAttributeOptions,
  };
};

export const getQuizFromData = (quiz: APIQuiz): Quiz => ({
  id: quiz.id,
  ...quiz.attributes,
  createdAt: new Date(quiz.attributes.createdAt),
  updatedAt: new Date(quiz.attributes.updatedAt),
});

export const filterAvailableBatches = (batch: WorkshopBatch) =>
  !batch.hasElapsed && !batch.isSoldOut;

export const sortBatchesByStartDate = (a: WorkshopBatch, b: WorkshopBatch) =>
  new Date(a.start).getTime() - new Date(b.start).getTime();

export const sortSessionsByStartDate = (
  a: WorkshopSession,
  b: WorkshopSession
) => new Date(a.start).getTime() - new Date(b.start).getTime();

export const getWorkshopPriceLabel = ({
  prices,
  numberOfSessions,
}: {
  prices: WorkshopPriceRange;
  numberOfSessions: number;
}): string | undefined => {
  if (!prices || (!prices.minimum && !prices.maximum && !prices.uniform)) {
    return undefined;
  }

  // All available batches have same price
  if (prices.uniform) {
    if (prices.uniform.amount === 0) {
      return t('Free');
    } else {
      return `${getDisplayPrice(
        prices.uniform.currency,
        prices.uniform.amount / numberOfSessions
      )} ${t('/ session')}`;
    }
  }

  // All available batches have different prices
  if (prices.minimum && prices.maximum) {
    if (prices.minimum.amount === 0) {
      return t('From free');
    } else {
      return `${t('From')} ${getDisplayPrice(
        prices.minimum.currency,
        prices.minimum.amount
      )} ${t('/ person')}`;
    }
  }

  return undefined;
};

export const getWorkshopsFromData = (
  workshops: APIWorkshop[],
  options?: {
    showHidden?: boolean;
    hideHasElapsed?: boolean;
    hideIsAttending?: boolean;
    hideSoldOut?: boolean;
    sortByNextBatch?: boolean;
    limit?: number;
  }
): Workshop[] => {
  const filteredWorkshops = workshops
    .map(getWorkshopFromData)
    .filter(Boolean)
    .filter((w: Workshop) =>
      options?.showHidden ? true : w.status !== WorkshopEventStatus.hidden
    )
    .filter((w: Workshop) => (options?.hideHasElapsed ? !w.hasElapsed : true))
    .filter((w: Workshop) => (options?.hideIsAttending ? !w.isAttending : true))
    .filter((w: Workshop) => (options?.hideSoldOut ? !w.isSoldOut : true));

  const sortedWorkshops = options?.sortByNextBatch
    ? filteredWorkshops.sort((a: Workshop, b: Workshop) => {
        if (!a.nextBatchStart || !b.nextBatchStart) return -1;

        return (
          new Date(a.nextBatchStart).getTime() -
          new Date(b.nextBatchStart).getTime()
        );
      })
    : filteredWorkshops;

  const sliceSize = options?.limit ? options.limit : sortedWorkshops.length;
  const limitedWorkshops = sortedWorkshops.slice(0, sliceSize);

  return limitedWorkshops;
};

export const getFeaturedWorkshopsFromEditorialData = (
  listData: APIRoomsList
): Workshop[] => {
  const workshopsData = listData.relationships.workshops.data;

  if (!workshopsData.length) return [];

  return getWorkshopsFromData(workshopsData);
};

// HANDS
export const getHandFromData = (hand: APIHand): Hand => ({
  id: hand.id,
  createdAt: hand.attributes.createdAt,
  updatedAt: hand.attributes.updatedAt,
  status: hand.attributes.status as HandStatus,
  owner: delve(hand, 'relationships.owner.data')
    ? getUserFromData(hand.relationships.owner.data)
    : null,
});

export const getHandsFromData = (items: APIHand[]): Hand[] =>
  items.map(getHandFromData).filter(Boolean);

// BANS
export const getBanFromData = (ban: APIBan): Ban => {
  const bannedUserData = ban.relationships.user.data;
  const banCreatedByUserData = ban.relationships.createdBy?.data;

  return {
    id: ban.id,
    ...ban.attributes,
    createdBy: banCreatedByUserData
      ? getUserFromData(banCreatedByUserData)
      : undefined,
    user: getUserFromData(bannedUserData),
  };
};

export const getBansFromData = (bans: APIBan[]) =>
  bans.map((ban) => getBanFromData(ban)).filter(Boolean);
