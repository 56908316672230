import * as React from 'react';

import { SkeletonNew } from '@/components/global/Skeleton/SkeletonNew';
import { Toast } from '@/components/global/Toaster/Toast';
import { CollectionCard } from '@/components/ondemand/Collections/CollectionCard';
import { getCollections } from '@/components/ondemand/Collections/helpers';

import { COLLECTIONS_FETCH_FAIL } from '@/messages/errors';

interface Props {}

interface State {
  isLoading: boolean;
  collections: Collection[];
}

class CollectionsGrid extends React.Component<Props, State> {
  public state: State = {
    isLoading: true,
    collections: [],
  };

  public componentDidMount = async () => {
    try {
      const collections = await getCollections();

      this.setState({ isLoading: false, collections });
    } catch (e) {
      new Toast({ body: COLLECTIONS_FETCH_FAIL, failure: true }).dispatch();
    }
  };

  private renderLoadingState = () => (
    <div className="u-grid u-grid--3">
      {Array.from(Array(18).keys()).map((_, key: number) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={key}
          className="c-collection-card c-collection-card--loading p32 u-flex u-flex-column pointer-events-none"
        >
          <SkeletonNew
            fillClassName="u-bg-sad-grey"
            elementClassName="f-text-2"
            containerClassName="mb16"
            fitToText="Count"
          />

          <SkeletonNew
            fillClassName="u-bg-sad-grey"
            elementClassName="f-text-1"
            containerClassName=""
            fitToText="Collection Name"
          />
        </div>
      ))}
    </div>
  );

  private renderCollections = () => {
    const { collections } = this.state;

    return (
      <div className="u-grid u-grid--3">
        {collections.map((c: Collection) => (
          <CollectionCard key={c.id} collection={c} />
        ))}
      </div>
    );
  };

  public render = () => {
    const { isLoading } = this.state;

    return isLoading ? this.renderLoadingState() : this.renderCollections();
  };
}

export { CollectionsGrid };
