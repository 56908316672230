import React from 'react';

import { Box, BoxProps, Flex, Grid, Text } from '@chakra-ui/react';

import {
  StarIcon,
  TitleTag,
} from '@/components/design_system/AlexJenkinsClass/atoms';
import {
  AssessmentCard,
  UserFeedbackCard,
} from '@/components/design_system/AlexJenkinsClass/molecules';

import { colors } from '@/styles/chakra-theme/colors';
import { pxToRem } from '@/styles/chakra-theme/metrics';

import { REVIEW_SAMPLE_SIZE } from '@/utils/constants';

export const ReviewsSection = (props: BoxProps) => (
  <Grid
    templateColumns={{
      base: `repeat(9, ${window.innerWidth / 9}px)`,
      md: `repeat(9, ${window.innerWidth / 9}px)`,
      lg: `repeat(9, ${window.innerWidth / 9}px)`,
      xl: `repeat(12, ${window.innerWidth / 12}px)`,
      '2xl': `repeat(16, ${window.innerWidth / 16}px)`,
    }}
    templateRows={{
      base: `repeat(58, ${window.innerWidth / 9}px)`,
      md: `repeat(35, ${window.innerWidth / 9}px)`,
      lg: `repeat(16, ${window.innerWidth / 9}px)`,
      xl: `repeat(16, ${window.innerWidth / 12}px)`,
      '2xl': `repeat(17, ${window.innerWidth / 16}px)`,
    }}
    backgroundImage="linear-gradient(#ffffff20 1px, transparent 1px), linear-gradient(to right, #ffffff20 1px, transparent 1px)"
    bgColor={colors.purpleRain}
    backgroundSize={{
      base: `${window.innerWidth / 9}px ${window.innerWidth / 9}px`,
      md: `${window.innerWidth / 9}px ${window.innerWidth / 9}px`,
      lg: `${window.innerWidth / 9}px ${window.innerWidth / 9}px`,
      xl: `${window.innerWidth / 12}px ${window.innerWidth / 12}px`,
      '2xl': `${window.innerWidth / 16}px ${window.innerWidth / 16}px`,
    }}
    className="reviews"
    {...props}
  >
    <TitleTag
      fontSize={[64, 64, 64, 102, 128]}
      px={{ base: pxToRem(32), md: pxToRem(66) }}
      fontWeight={300}
      bgColor="black"
      transform="rotate(3deg)"
      gridColumn={{ base: '2 / 10', md: '3 / 8', lg: '2 / 5' }}
      gridRow={{ base: '3 / 4', md: '3' }}
    >
      REVIEWS
    </TitleTag>

    <AssessmentCard
      display={{ base: 'none', lg: 'flex' }}
      rating={4.8}
      text="Students give Daisie an average rating 4.8 of 5. See what they say about our classes."
      subtext={`${REVIEW_SAMPLE_SIZE.toLocaleString()}+ reviews`}
      gridColumn={{ base: '0', md: '2 / -2', lg: '2 / -2', xl: '-2 / -10' }}
      gridRow={{
        base: '0',
        md: '6 / 10',
        lg: '6 / 8',
        xl: '6 / 8',
        '2xl': '6 / 8',
      }}
    />

    <Box
      display={{ base: 'flex', lg: 'none' }}
      alignItems="center"
      justifyContent="center"
      bgColor={colors.purpleRain}
      border="1px solid #ffffff20"
      gridColumn={{ base: '2 / 6', md: '2 / 5' }}
      gridRow={{ base: '6 / 10', md: '6 / 9' }}
    >
      <Text fontSize={{ base: 80, md: 128 }} fontWeight={400} color="white">
        4.8
      </Text>
    </Box>

    <Box
      display={{ base: 'block', lg: 'none' }}
      p={pxToRem(12)}
      bgColor={colors.purpleRain}
      border="1px solid #ffffff20"
      gridColumn={{ base: '2 / -2', lg: 'none' }}
      gridRow={{ base: '10 / 17', md: '9 / 12', lg: 'none' }}
    >
      <Text fontSize={24} color="white" fontWeight={400}>
        Students give Daisie an average rating 4.8 of 5. See what they say about
        our classes.
      </Text>

      <Flex mt={{ base: pxToRem(12), md: pxToRem(24) }}>
        {Array.from({ length: 5 }).map((_, i) => (
          <StarIcon
            key={String(i)}
            fontSize={48}
            // transform={`rotate(${-10 * spinMultiplier}deg)`}
            className="spin"
          />
        ))}
      </Flex>

      <Text
        fontSize={{ base: 14, md: 16 }}
        fontWeight={400}
        color="white"
        opacity={0.5}
        mt={{ base: pxToRem(12), md: pxToRem(24) }}
      >
        {`${REVIEW_SAMPLE_SIZE.toLocaleString()}+ reviews`}
      </Text>
    </Box>

    <Box
      gridColumn={{ base: '2 / -2', lg: '2 / -2', '2xl': '3 / -3' }}
      gridRow={{ base: '20', md: '14', lg: '9', xl: '9', '2xl': '9' }}
    >
      <Flex
        justify="space-between"
        pt={[0, pxToRem(190)]}
        px={pxToRem(34)}
        className="reviewCards"
      >
        <UserFeedbackCard
          image="/AlexJenkinsClass/img/user-feedback-1.jpg"
          name="Maya Wata"
          flag="/AlexJenkinsClass/img/uk_flag.png"
          text="This felt like a really positive space and it made me feel really motivated and hopeful about my creative journey."
          rotateDeg={-4}
          bgColor="#D8C549"
          textColor="white"
        />

        <UserFeedbackCard
          image="/AlexJenkinsClass/img/user-feedback-2.jpg"
          name="Grace Chang"
          flag="/AlexJenkinsClass/img/us_flag.png"
          text="It was really eye-opening to hear from an experienced creative and loved the meeting creators before & after the workshop."
          rotateDeg={4}
          bgColor="white"
          textColor="grey"
          nameColor="black"
          mt={{ base: 0, lg: pxToRem(-180) }}
          ml={{
            base: pxToRem(60),
            lg: pxToRem(-110),
            xl: 0,
            '2xl': pxToRem(-110),
          }}
        />

        <UserFeedbackCard
          image="/AlexJenkinsClass/img/user-feedback-3.jpg"
          name="George Bloomfield"
          flag="/AlexJenkinsClass/img/fr_flag.png"
          text="This workshop was super fun! It was like someone made a lecture out of all the little internal debates you have."
          rotateDeg={4}
          bgColor="#60D273"
          textColor="white"
          nameColor="black"
          mr={pxToRem(-20)}
          ml={{ base: 0, md: pxToRem(20), lg: 0 }}
        />
      </Flex>
    </Box>
  </Grid>
);
