import * as React from 'react';

import { Link } from 'react-router-dom';

import { Icon } from '@/components/global/Icon/Icon';

import { IconSize } from '@/enums/IconSize';

import { c } from '@/utils/strings/c';

interface Props {
  to: string;
  text?: string;
  className?: string;
  classNameLink?: string;
  darkMode?: boolean;
}

export const BackButton = (props: Props) => {
  const {
    to,
    text,
    className = '',
    classNameLink = 'u-link-black u-link-black--alt',
    darkMode,
  } = props;

  return (
    <Link
      to={to}
      className={c(['u-flex u-align-center', className, classNameLink], {
        'u-link-white u-link-white--alt': darkMode,
      })}
    >
      <Icon id="chevron-left" size={IconSize.xs} className="mr8" />
      {text && <span>{text}</span>}
    </Link>
  );
};
