export const colors = {
  white: '#ffffff',
  offWhite: '#FFFFF2',
  navBgLight: '#EDEDE1',
  black: '#000000',
  greyDark: '#272727',
  grey: '#737677',
  greyLight: '#ADB2B4',
  purpleGradient: 'linear-gradient(90deg, #8543E3 0%, #633BEB 100%)',
  purpleRain: '#6468DD',
};
