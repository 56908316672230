import React, { useCallback, useEffect, useState } from 'react';

import {
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  Flex,
  Image,
  Tag,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import { ArrowBottomRightIcon } from '@/components/design_system/AlexJenkinsClass/atoms';

import { colors } from '@/styles/chakra-theme/colors';
import { pxToRem } from '@/styles/chakra-theme/metrics';

import { isDesktop } from '@/utils/DOM/sizing';

interface Props extends AccordionProps {
  items: {
    title: string;
    titleTags: string[];
    episodes: {
      title: string;
      description: string;
      duration: string;
      resources?: boolean;
      classIndex: number;
    }[];
  }[];
}

export const Accordion = ({ items, ...props }: Props) => {
  const [isOpenTitle, setIsOpenTitle] = useState('');
  const [isHoverTitle, setIsHoverTitle] = useState('');
  const [isHoverEpisode, setIsHoverEpisode] = useState('');
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const update = useCallback(
    (e: MouseEvent) => {
      if (isDesktop()) {
        setX(e.x - 200);
        setY(e.offsetY - 100);
      }
    },
    [isDesktop, setX, setY]
  );

  useEffect(() => {
    window.addEventListener('mousemove', update);

    return () => {
      window.removeEventListener('mousemove', update);
    };
  }, [update]);

  return (
    <ChakraAccordion allowToggle gap={pxToRem(8)} {...props}>
      {items.map((item, index) => (
        <AccordionItem
          key={item.title}
          mt={pxToRem(index !== 0 ? 8 : 0)}
          border="none"
          borderRadius={[16, 0]}
          height="fit-content"
          bg={
            [isHoverTitle, isOpenTitle].includes(item.title)
              ? [
                  colors.purpleRain,
                  'linear-gradient(90deg, #8543E3 0%, #633BEB 100%), #000000;',
                ]
              : colors.greyDark
          }
        >
          <AccordionButton
            px={pxToRem(28)}
            py={pxToRem(20)}
            onClick={() => {
              setIsOpenTitle(isOpenTitle === item.title ? '' : item.title);
            }}
            onMouseEnter={() => setIsHoverTitle(item.title)}
            onMouseLeave={() => setIsHoverTitle('')}
            _focus={{ border: 'none' }}
          >
            <Flex
              direction={['column', 'column', 'row']}
              flex={1}
              gap={pxToRem(12)}
              align={['left', 'left', 'center']}
            >
              <Text
                color="white"
                fontWeight={400}
                fontSize={[24, 24, 24, 28, 44]}
                width={{ base: '100%', md: '50%' }}
                mr={pxToRem(4)}
                textAlign="left"
                textTransform="uppercase"
              >
                {item.title}
              </Text>
              <Flex gap={pxToRem(12)} wrap="wrap">
                {item.titleTags?.map((tag) => (
                  <Tag
                    key={tag}
                    color="white"
                    fontWeight={400}
                    px={pxToRem(12)}
                    py={pxToRem(2)}
                    bgColor="transparent"
                    border="1px solid white"
                    borderRadius={100}
                    opacity={
                      [isHoverTitle, isOpenTitle].includes(item.title) ? 1 : 0.7
                    }
                    fontSize={[12, 12, 18, 18, 24]}
                    textTransform="uppercase"
                  >
                    {tag}
                  </Tag>
                ))}
              </Flex>
            </Flex>

            <ArrowBottomRightIcon
              fontSize={18}
              mt="auto"
              transform={
                isOpenTitle === item.title ? 'rotate(-90deg)' : undefined
              }
              transitionDuration="0.25s"
            />
          </AccordionButton>

          <AccordionPanel
            px={pxToRem(28)}
            py={pxToRem(20)}
            height="fit-content"
          >
            {item.episodes.map((episode) => (
              <Tooltip
                key={`episode_${episode.title}`}
                label={
                  <Image
                    // eslint-disable-next-line prettier/prettier
                    src={`/AlexJenkinsClass/gifs/CLASS ${episode.classIndex} GIF.gif`}
                    width={240}
                    objectFit="cover"
                  />
                }
                placement="left-start"
                left={x}
                top={y}
                padding="0"
                boxShadow="none"
                isDisabled={
                  !isDesktop() || !navigator?.userAgent?.includes('Chrome')
                }
              >
                <Flex
                  alignItems="baseline"
                  py={pxToRem(32)}
                  w="100%"
                  height="fit-content"
                  borderTopWidth={1}
                  borderColor="rgba(256,256,256,0.1)"
                  cursor="pointer"
                  onMouseEnter={() => setIsHoverEpisode(episode.title)}
                  onMouseLeave={() => setIsHoverEpisode('')}
                >
                  <Flex
                    align="baseline"
                    gap={pxToRem(16)}
                    w={['100%', '50%']}
                    mr={pxToRem(24)}
                    className="episode"
                  >
                    <Text fontSize={[20, 32]} fontWeight={400} color="white">
                      {episode.title}
                      <Tag
                        px={pxToRem(12)}
                        py={pxToRem(2)}
                        mt={[pxToRem(2), pxToRem(12)]}
                        ml={pxToRem(8)}
                        fontSize={18}
                        fontWeight={400}
                        color="white"
                        bgColor="transparent"
                        borderWidth={1}
                        borderColor="white"
                        borderRadius={100}
                        opacity={
                          isHoverEpisode.includes(episode.title) ? 1 : 0.5
                        }
                      >
                        {episode.duration}
                      </Tag>
                    </Text>

                    <Flex>
                      {/* {episode.resources && (
                        <Tag
                          display={['none', 'block']}
                          px={pxToRem(12)}
                          py={pxToRem(2)}
                          fontSize={[12, 18]}
                          fontWeight={400}
                          color="white"
                          bgColor="transparent"
                          borderWidth={1}
                          borderColor="white"
                          borderRadius={100}
                          opacity={
                            isHoverEpisode.includes(episode.title) ? 1 : 0.5
                          }
                        >
                          Resources
                        </Tag>
                      )} */}
                    </Flex>
                  </Flex>

                  <Text
                    display={['none', 'block']}
                    fontSize={24}
                    fontWeight={400}
                    color="white"
                    opacity={isHoverEpisode.includes(episode.title) ? 1 : 0.6}
                    w="70%"
                  >
                    {episode.description}
                  </Text>
                </Flex>
              </Tooltip>
            ))}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </ChakraAccordion>
  );
};
