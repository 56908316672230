import {
  getProcessorPathFromAttachmentData,
  getQuizFromData,
  getWorkshopFromData,
} from '@/store/helpers';

import { Http } from '@/utils/api/Http';

export const getOnDemandMediasFromData = (
  data: APIOnDemandMedia[]
): OnDemandMedia[] => data.map(getOnDemandMediaFromData).filter(Boolean);

export const getOnDemandMediaFromData = (
  data: APIOnDemandMedia
): OnDemandMedia => {
  const {
    id,
    attributes: { title, description, availableUntil, type },
    relationships: { videoStream, workshop, attachment, thumbnail, quiz },
  } = data;

  const onDemandMedia: OnDemandMedia = {
    id,
    title,
    description,
    availableUntil:
      availableUntil !== '' ? new Date(availableUntil) : undefined,
    type,
    streamUrl: !!videoStream
      ? videoStream?.data?.attributes?.playback?.hls
      : undefined,
    thumbnail: thumbnail?.data.attributes.metadata.processorMediaPath,
    duration: !!videoStream
      ? videoStream.data.attributes.duration
      : attachment &&
        attachment.data.attributes.metadata.processorMetadata &&
        attachment.data.attributes.metadata.processorMetadata.duration
      ? convertProcessorDurationString(
          attachment.data.attributes.metadata.processorMetadata.duration
        )
      : undefined,
    readyToStream: !!videoStream
      ? videoStream.data.attributes.readyToStream
      : undefined,
    workshop: !!workshop ? getWorkshopFromData(workshop.data) : undefined,
    attachmentUrl: !!attachment
      ? getProcessorPathFromAttachmentData(attachment.data)
      : undefined,
  };

  if (quiz) {
    onDemandMedia.quiz = getQuizFromData(quiz.data);
  }

  return onDemandMedia;
};

const convertProcessorDurationString = (duration: string): number => {
  const [hours, minutes, seconds] = duration.split(':');

  return (
    Number(hours) * 3600 + Number(minutes) * 60 + Math.floor(Number(seconds))
  );
};

export const getOnDemandMediasForWorkshop = async ({
  workshopUuid,
}: {
  workshopUuid: string;
}): Promise<OnDemandMedia[]> => {
  try {
    const { data } = await new Http(
      `/workshops/${workshopUuid}/onDemandMedia/catchUp`,
      '',
      2
    ).get<APIArray<APIOnDemandMedia>>();

    return getOnDemandMediasFromData(data);
  } catch (e) {
    throw e;
  }
};
