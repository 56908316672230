import * as React from 'react';

import { connect } from 'unistore/react';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { GlobalModal } from '@/enums/GlobalModal';
import { GlobalPortal } from '@/enums/GlobalPortal';

import { modalActions } from '@/store/modules/modal';
import { portalActions } from '@/store/modules/portal';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

import { c } from '@/utils/strings/c';

interface Props
  extends SizingState,
    PortalActions,
    PortalState,
    ModalActions,
    ModalState {
  workshop: Workshop;
  isMobileHero: boolean;
}

class WorkshopShareButtonComponent extends React.Component<Props> {
  public render = () => {
    const {
      sizing: { isMobile },
      workshop: { title },
      isMobileHero,
    } = this.props;

    return (
      <Button
        type={ButtonType.action}
        buttonStyle={ButtonStyle.outlineWhite}
        size={ButtonSize.s}
        iconId="share"
        onClick={() => {
          if (isMobile) {
            this.props.updatePortal({
              portal: GlobalPortal.share_current_page,
              data: {
                mixpanelTrackEventPrefix: 'share_workshop',
                isDarkMode: true,
                twitterText: `${title} on @daisie`,
                emailSubject: `Daisie Workshops: ${title}`,
              },
            });
          } else {
            this.props.updateModal({
              modal: GlobalModal.share_current_page,
              data: {
                mixpanelTrackEventPrefix: 'share_workshop',
                isDarkMode: true,
                twitterText: `${title} on @daisie`,
                emailSubject: `Daisie Workshops: ${title}`,
              },
            });
          }
        }}
        className={c(
          'u-flex u-align-center f-text-2 u-bold u-link-white u-link-white--alt share-button-icon',
          {
            'c-workshop-share-button-mobile': isMobileHero,
          }
        )}
        data-ui-test-id={isUiTest() ? 'workshop-share' : undefined}
      />
    );
  };
}

export const WorkshopShareButton = connect(['sizing', 'room'], () => ({
  ...portalActions(),
  ...modalActions(),
}))(WorkshopShareButtonComponent);
