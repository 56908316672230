import * as React from 'react';

import delve from 'dlv';
import { connect } from 'unistore/react';

import { Icon } from '@/components/global/Icon/Icon';
import { Link } from '@/components/global/Link/Link';
import { BaseNotification } from '@/components/notifications/BaseNotification';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';
import { ButtonGroup } from '@/components/design_system/ButtonGroup/ButtonGroup';
import { ButtonGroupSpacing } from '@/components/design_system/ButtonGroup/ButtonGroupSpacing';

import { IconSize } from '@/enums/IconSize';
import { NotificationItemLocations } from '@/enums/NotificationItemLocations';
import { RequestStatus } from '@/enums/RequestStatus';

import { authActions } from '@/store/modules/auth';
import { roomActions } from '@/store/modules/room';

import { enableRoomNotificationActions } from '@/utils/featureToggles';
import { t } from '@/utils/i18n/i18n';

interface Props extends AuthActions, RoomActions {
  className?: string;
  notification: DaisieNotification;
  location?: NotificationItemLocations;
  onClickToggle: () => void;
  isDarkMode: boolean;
}

interface State {
  status: RequestStatus;
}

class CollaborationInvitationComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      status: delve(props, 'notification.collaborationInvitation.status'),
    };
  }

  private acceptInvitation = async () => {
    const { collaborationInvitation } = this.props.notification;

    if (!collaborationInvitation) return;

    try {
      await this.props.removeCollaborationInvite({
        uuid: collaborationInvitation.id,
        accept: true,
      });

      this.setState({
        status: RequestStatus.accepted,
      });
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  };

  private rejectInvitation = async () => {
    const { collaborationInvitation } = this.props.notification;

    if (!collaborationInvitation) return;

    try {
      await this.props.removeCollaborationInvite({
        uuid: collaborationInvitation.id,
        accept: false,
      });

      this.setState({
        status: RequestStatus.rejected,
      });
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  };

  private renderActions() {
    const { status } = this.state;

    if (status === RequestStatus.pending) {
      if (enableRoomNotificationActions()) {
        return (
          <ButtonGroup spacing={ButtonGroupSpacing.s}>
            <Button
              type={ButtonType.action}
              onClick={this.acceptInvitation}
              iconId="tick"
              iconClassName="u-go"
              buttonStyle={ButtonStyle.light}
              size={IconSize.s}
            />
            <Button
              type={ButtonType.action}
              onClick={this.rejectInvitation}
              iconId="clear"
              iconClassName="u-danger"
              buttonStyle={ButtonStyle.light}
              size={IconSize.s}
            />
          </ButtonGroup>
        );
      } else {
        return null;
      }
    }
    if (status === RequestStatus.accepted) {
      return (
        <div className="u-flex u-align-center pt4">
          <Icon id="tick" size={IconSize.xs} className="mr4 u-go" />
          <span className="f-text-3">{t('Accepted')}</span>
        </div>
      );
    }
    if (status === RequestStatus.rejected) {
      return (
        <div className="u-flex u-align-center pt4">
          <Icon id="clear" size={IconSize.xs} className="mr4 u-danger" />
          <span className="f-text-3">{t('Declined')}</span>
        </div>
      );
    }

    return null;
  }

  public render() {
    const {
      className = '',
      notification,
      location,
      isDarkMode,
      onClickToggle,
    } = this.props;
    const { collaborationInvitation, project } = notification;
    if (
      !collaborationInvitation ||
      collaborationInvitation.status === RequestStatus.revoked ||
      !project
    )
      return null;

    if (location === NotificationItemLocations.ProjectActivity) return null;

    return (
      <BaseNotification
        notification={notification}
        className={className}
        isDarkMode={isDarkMode}
        onClickToggle={onClickToggle}
        primary={
          <span>
            {t('is inviting you to join ')}
            <Link
              to={{
                name: 'room',
                params: { roomSlug: project.slug },
              }}
            >
              {project.title}
            </Link>
          </span>
        }
        secondary={({ date }: { date: string }) => date}
        action={this.renderActions()}
      />
    );
  }
}

const CollaborationInvitation = connect('auth', (store: GlobalStoreState) => ({
  ...roomActions(store),
  ...authActions(store),
}))(CollaborationInvitationComponent);

export { CollaborationInvitation };
