// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';

// Tooltips
export const REPORT_REASON_PLACEHOLDER =
  'Please provide us with any further information';

export const SHARE_IMAGE_MOBILE = `Tap and hold an image to download it to your device.`;

export const SSO_NO_PASSWORD =
  "You've signed up to Daisie with your Google account, so right now you can only log into Daisie this way. You can create a password if you want to log into Daisie without your Google account.";

export const NAVIGATION_SEARCH_BAR_PLACEHOLDER: string = 'Search Daisie';
export const NAVIGATION_SEARCH_BAR_PLACEHOLDER_SHORT: string = 'Search Daisie';

export const TWITTER_SHARE_TEXT: string =
  'Join @daisie: bringing creators together';

export const ROOM_AUDIO_BACKGROUND_DISABLED_COFIRM: string = `If you change page, you'll leave this room. Do you want to continue?`;
