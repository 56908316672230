import * as React from 'react';

import { connect } from 'unistore/react';

import { Icon } from '@/components/global/Icon/Icon';

import { IconSize } from '@/enums/IconSize';

import { c } from '@/utils/strings/c';

interface Props extends SizingState {
  className?: string;
  items: CheckedBulletListItem[];
}

class CheckedBulletListComponent extends React.Component<Props> {
  public render = () => {
    const {
      className = '',
      items,
      sizing: { isMobile },
    } = this.props;

    return (
      <ul className={c([className])}>
        {items.map((i: CheckedBulletListItem, key: number) => (
          <li
            // eslint-disable-next-line react/no-array-index-key
            key={key}
            className={c('u-flex u-align-center', {
              'mb24@m mb12@s': key !== items.length - 1,
            })}
          >
            <Icon
              id="tick"
              size={isMobile ? IconSize.s : IconSize.m}
              className="mr20@m mr12@s u-brand u-flex-hold"
            />
            <p
              className={c('f-text-1', {
                'u-bold': !!i.isBold,
              })}
            >
              {i.text}
            </p>
          </li>
        ))}
      </ul>
    );
  };
}

export const CheckedBulletList = connect(['sizing'], () => ({}))(
  CheckedBulletListComponent
);
