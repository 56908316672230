import * as React from 'react';

import { connect } from 'unistore/react';

import { abtestActions } from '@/store/modules/abtest';

interface Props extends ABTestActions, ABTestState, AuthState {
  children: React.ReactNode;
}

class ABTestComp extends React.Component<Props> {
  public componentDidMount = async () => {
    await this.props.getOrSetVariant();
  };

  public render = () => {
    const {
      children,
      abtest: { variant },
    } = this.props;

    const hideDebugger =
      process.env.DAISIE_ENV !== 'local' ||
      (process.env.DAISIE_ENV === 'local' &&
        process.env.DAISIE_ENABLE_ABTEST_DEBUGGER !== 'true');

    if (hideDebugger) {
      return children;
    }

    return (
      <div>
        <div
          className="p20 m20 u-bg-brand u-white u-fixed"
          style={{
            width: 200,
            top: 0,
            left: 0,
            zIndex: 9999,
            borderRadius: 12,
          }}
        >
          <p>Variant {variant}</p>
          <button
            type="button"
            onClick={() => {
              localStorage.removeItem('ab_test_variant');
              location.reload();
            }}
            className="u-link-white u-link-white--alt u-underline"
          >
            Reset
          </button>
        </div>
        {children}
      </div>
    );
  };
}

export const ABTest = connect(['abtest', 'auth'], () => ({
  ...abtestActions(),
}))(ABTestComp);
