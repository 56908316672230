import * as React from 'react';

const CreateLoader = (path: string, name: string) => ({
  loading: () => null,
  modules: [path],
  render(loaded: any, props: object) {
    const Component = loaded[name];
    return <Component {...props} />;
  },
});

export { CreateLoader };
