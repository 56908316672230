import * as React from 'react';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { CookiesProvider } from 'react-cookie';
import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';
import { BrowserRouter } from 'react-router-dom';
import createStore from 'unistore';
import devtools from 'unistore/devtools';
import { Provider as UnistoreProvider } from 'unistore/react';

import { App } from '@/components/application/App';
import { ABTest } from '@/components/global/ABTest/ABTest';
import { GlobalTooltip } from '@/components/global/GlobalTooltip/GlobalTooltip';
import { ResponsiveObserver } from '@/components/global/ResponsiveObserver/ResponsiveObserver';
import { MaintenanceMode } from '@/components/maintenance_mode/MaintenanceMode';
import { WorkshopAttendModal } from '@/components/workshops/WorkshopAttendModal/WorkshopAttendModal';

import { CinematicBackground } from '@/components/design_system/Cinematic/CinematicBackground/CinematicBackground';

import { OptimisedJoinFlowWorkshopHero } from '@/pages/OptimisedJoinFlow/OptimisedJoinFlowWorkshopHero';

import { store } from '@/store/store';

import { theme } from '@/styles/chakra-theme/theme';

import { HOTJAR_SITE_ID, HOTJAR_SNIPPET_VERSION } from '@/utils/constants';

const unistore =
  process.env.NODE_ENV === 'development'
    ? devtools(createStore<GlobalStoreState>(store))
    : createStore<GlobalStoreState>(store);

TagManager.initialize({
  gtmId: process.env.DAISIE_GTM_ID || '',
});

hotjar.initialize(HOTJAR_SITE_ID, HOTJAR_SNIPPET_VERSION);

class Application extends React.Component {
  public render() {
    return (
      <BrowserRouter>
        <UnistoreProvider store={unistore}>
          <MaintenanceMode>
            <CookiesProvider>
              <ResponsiveObserver />
              <ABTest>
                <ChakraProvider theme={extendTheme({ theme })}>
                  <App />
                </ChakraProvider>
              </ABTest>
              <CinematicBackground />
              <OptimisedJoinFlowWorkshopHero />
              <GlobalTooltip />
              <WorkshopAttendModal />
            </CookiesProvider>
          </MaintenanceMode>
        </UnistoreProvider>
      </BrowserRouter>
    );
  }
}

export default Application;
