/* eslint-disable react/no-array-index-key */
import * as React from 'react';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';
import { SlideToggle } from '@/components/design_system/SlideToggle/SlideToggle';
import { SlideToggleSize } from '@/components/design_system/SlideToggle/SlideToggleSize';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props {}

interface State {
  currentTab?: DesignSystemGuideTab;
  slideToggleDemo: boolean;
}

// GENERAL
enum DesignSystemGuideTab {
  slide_toggle = 'slide_toggle',
  buttons = 'buttons',
}

// BUTTONS
const SAMPLE_TEXT = 'Hamburger';

const BUTTON_STYLES = [
  ButtonStyle.default,
  ButtonStyle.light,
  ButtonStyle.dark,
  ButtonStyle.danger,
  ButtonStyle.dangerInvert,
  ButtonStyle.outlineLight,
];

const BUTTON_TYPES = [ButtonType.action, ButtonType.link];

const BUTTON_SIZES = [ButtonSize.s, ButtonSize.m, ButtonSize.l];

const BUTTON_MODES = ['enabled', 'disabled', 'loading'];

const BUTTON_CONTENT = [
  'icon-text-left',
  'icon-text-right',
  'text',
  'icon',
  'counter',
];

class DesignSystemGuide extends React.Component<Props, State> {
  public state: State = {
    currentTab: undefined,
    slideToggleDemo: false,
  };

  private changeTab = (currentTab?: DesignSystemGuideTab) => {
    if (this.state.currentTab === currentTab) {
      this.setState({ currentTab: undefined });
    } else {
      this.setState({ currentTab });
    }
  };

  private renderSlideToggleSection = () => {
    const { slideToggleDemo } = this.state;

    return (
      <div className="mb32">
        <SlideToggle
          size={SlideToggleSize.l}
          leftAction={() => this.setState({ slideToggleDemo: false })}
          leftText={t('Public')}
          leftIcon="public"
          rightAction={() => this.setState({ slideToggleDemo: true })}
          rightText={t('Private')}
          rightIcon="private"
          toggled={slideToggleDemo}
        />
      </div>
    );
  };

  private renderButtonsSection = () => (
    <div className="mb32">
      {BUTTON_STYLES.map((style: ButtonStyle, key: number) => (
        <div
          key={key}
          className={c('p24', {
            'u-bg-white': style !== ButtonStyle.outlineLight,
            'u-bg-sad-grey u-white': style === ButtonStyle.outlineLight,
          })}
        >
          <h3 className="f-title-3 u-bold mb12">{style}</h3>

          <div className="u-flex mb16">
            {BUTTON_TYPES.map((buttonType: ButtonType, key: number) => (
              <div className="u-flex-1" key={key}>
                <h4 className="f-text-3">{buttonType}</h4>

                {BUTTON_SIZES.map((size: ButtonSize, key: number) => (
                  <div key={key}>
                    <p>{size}</p>

                    {BUTTON_MODES.map((mode: string, key: number) => (
                      <div key={key}>
                        <p>{mode}</p>
                        <div className="u-flex u-flex-wrap">
                          {BUTTON_CONTENT.map(
                            (content: string, key: number) => (
                              <React.Fragment key={key}>
                                {buttonType === ButtonType.action ? (
                                  <Button
                                    type={buttonType}
                                    buttonStyle={style}
                                    onClick={() => true}
                                    text={content === 'icon' ? '' : SAMPLE_TEXT}
                                    size={size}
                                    disabled={mode === 'disabled'}
                                    isLoading={mode === 'loading'}
                                    iconId={
                                      content.includes('icon')
                                        ? 'category-dogs-are-good'
                                        : undefined
                                    }
                                    iconSide={
                                      content === 'icon-text-left'
                                        ? 'left'
                                        : undefined
                                    }
                                    className="mb16 mr16"
                                    counter={
                                      content === 'counter' ? 2 : undefined
                                    }
                                  />
                                ) : (
                                  <Button
                                    type={buttonType}
                                    buttonStyle={style}
                                    linkTo={
                                      buttonType === ButtonType.link
                                        ? 'https://www.daisie.com'
                                        : undefined
                                    }
                                    text={content === 'icon' ? '' : SAMPLE_TEXT}
                                    size={size}
                                    disabled={mode === 'disabled'}
                                    isLoading={mode === 'loading'}
                                    iconId={
                                      content.includes('icon')
                                        ? 'category-dogs-are-good'
                                        : undefined
                                    }
                                    iconSide={
                                      content === 'icon-text-left'
                                        ? 'left'
                                        : undefined
                                    }
                                    className="mb16 mr16"
                                    counter={
                                      content === 'counter' ? 2 : undefined
                                    }
                                  />
                                )}
                              </React.Fragment>
                            )
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );

  public render = () => {
    const { currentTab } = this.state;

    const sectionToggleButtonClassName = 'u-1/1 bb u-text-left mb32';
    const sectionTitleClassName = 'f-title-2 mb16 u-bold';

    return (
      <div className="wrap mv32">
        <h1 className="f-title-1 u-bold mb32">Design system guide</h1>

        <button
          type="button"
          onClick={() => this.changeTab(DesignSystemGuideTab.slide_toggle)}
          className={sectionToggleButtonClassName}
        >
          <h2 className={sectionTitleClassName}>Slide Toggle</h2>
        </button>
        {currentTab === DesignSystemGuideTab.slide_toggle &&
          this.renderSlideToggleSection()}

        <button
          type="button"
          onClick={() => this.changeTab(DesignSystemGuideTab.buttons)}
          className={sectionToggleButtonClassName}
        >
          <h2 className={sectionTitleClassName}>Buttons</h2>
        </button>
        {currentTab === DesignSystemGuideTab.buttons &&
          this.renderButtonsSection()}
      </div>
    );
  };
}

export { DesignSystemGuide };
