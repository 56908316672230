import React from 'react';

import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { connect } from 'unistore/react';

import { TitleTag } from '@/components/design_system/AlexJenkinsClass/atoms';
import { SimpleAccordion } from '@/components/design_system/AlexJenkinsClass/molecules';

import { SubscriptionInterval } from '@/enums/SubscriptionInterval';

import { findProductAndPrice } from '@/store/helpers/subscriptionHelpers';

import { pxToRem } from '@/styles/chakra-theme/metrics';

import { getDisplayPrice } from '@/utils/strings/currency';

interface Props extends BoxProps, SubscriptionState {}

export const FaqSectionComp = (props: Props) => {
  const monthlyPrice: SubscriptionProductPrice | undefined =
    findProductAndPrice({
      productsAndPrices: props.subscription.productsAndPrices,
      currency: props.subscription.userCurrency,
      recurringInterval: SubscriptionInterval.month,
    });

  const yearlyPrice: SubscriptionProductPrice | undefined = findProductAndPrice(
    {
      productsAndPrices: props.subscription.productsAndPrices,
      currency: props.subscription.userCurrency,
      recurringInterval: SubscriptionInterval.year,
    }
  );

  return (
    <Box {...props} className="faq">
      <Flex align="center" mt={[pxToRem(40), pxToRem(160)]}>
        <TitleTag
          fontSize={[40, 64]}
          px={[pxToRem(40), pxToRem(32)]}
          py={pxToRem(8)}
          bgColor="#60D273"
          transform="rotate(-5deg)"
        >
          FAQ
        </TitleTag>
      </Flex>

      <SimpleAccordion
        items={[
          {
            title: 'Who is this course for? ',
            text: (
              <span>
                <p>
                  This course is for anyone who wants to build their skills as
                  an animator, whether you're a budding artist looking to start
                  creating original stories, or a more experienced creator who's
                  looking to push your animations to the next level. The
                  projects are designed so that you can practice and learn
                  regardless of your skill level.
                </p>
                <br />
                <p>
                  If you’re looking to immerse yourself in a 21-day learning
                  experience, level up your animation and storytelling skills,
                  and finish the course with an original animation that you can
                  share with your friends and family, this class is for you.
                </p>
              </span>
            ),
          },
          {
            title:
              'How much animation knowledge and experience do I need to take this course?',
            text: (
              <span>
                <p>
                  This course is packed with information and you'll likely get
                  different things out of it depending on your level of
                  experience and familiarity with making animations.
                </p>
                <br />
                <p>
                  If you're completely new to making animations, this course is
                  designed to get you comfortable and confident creating videos
                  completely on your own. You'll learn the basics of sketching,
                  digital art, storyboarding and editing, and use your creative
                  skills to translate your own stories into animations.
                </p>
                <br />
                <p>
                  If you’re more advanced at making animations, you’ll
                  appreciate Alex’s unique approach to storyboarding, editing
                  and narrative development, and his philosophy on the animation
                  and storytelling process.
                </p>
                <br />
                <p>
                  Whatever experience you have coming into the course, you'll
                  finish with an original animation that you'll be proud to
                  share, and the skills you need to continue on your creative
                  journey.
                </p>
              </span>
            ),
          },
          {
            title: 'Who is Alex Jenkins? ',
            text: (
              <span>
                <p>
                  Alex is an illustrator, cartoonist and animator from the UK.
                  His work explores atire, surrealism and humour in many
                  different (often gruesome) forms. Alex has worked with a wide
                  variety of brands and publications, including Mother Jones,
                  The New York Times, Hustler, VICE, VANS and Time Out.
                </p>
              </span>
            ),
          },
          {
            title: 'How exactly does the 21-day course work?',
            text: (
              <span>
                <p>
                  Once you enroll, you’ll see all 21 classes available to watch.
                  You can choose to watch the classes on a daily basis, as
                  they’ve been structured, or all at once. However you choose!
                </p>
                <br />
                <p>
                  Throughout the course, you can share your ongoing work and
                  give and receive peer feedback. Your peers will answer your
                  questions and offer support and guidance every step of the
                  way.
                </p>
                <br />
                <p>
                  By the end of the course, you’ll finish an animation, applying
                  all the new skills, techniques, and ideas you’ve learned and
                  practiced throughout the course.
                </p>
              </span>
            ),
          },
          {
            title: 'Will I get direct feedback from Alex?',
            text: (
              <span>
                <p>
                  Given the number of students in the class and Alex’s schedule,
                  we can’t guarantee that he will comment on student work
                  directly. He will be a member of the community space and host
                  live Q+A workshops in the first few weeks post launch.
                </p>
              </span>
            ),
          },
          {
            title:
              'Do I need to have design equipment and animation software? ',
            text: (
              <span>
                <p>
                  Yes. You will need art equipment (pens, pencils, paper),
                  Procreate (on the iPad) and Photoshop with the ‘Animator’s
                  Toolbar’ purchased and installed (iPad, Macbook or PC App).
                </p>
                <br />
                <p>
                  There are some other items that you'll see Alex use throughout
                  the course, but these are not required to take the course or
                  complete the projects. If you're interested in purchasing
                  additional equipment or software, we'll provide a
                  comprehensive list of options for a wide range of budgets.
                </p>
              </span>
            ),
          },
          {
            title: 'Are courses live? Do I have to log in at a specific time?',
            text: (
              <span>
                <p>
                  No, all the video classes are pre-recorded, allowing you to
                  watch them whenever is best for you.
                </p>
              </span>
            ),
          },
          {
            title: 'Do I take this course from home?',
            text: (
              <span>
                <p>
                  Yes, the course is taught completely online, allowing you to
                  take it from anywhere. Even though it's online, it will still
                  feel like you're in a classroom and part of a vibrant group of
                  students: you'll be interacting with peers, getting feedback,
                  and learning with hands-on projects.
                </p>
                <br />
                <p>
                  You'll get all the learning benefits of an intensive animation
                  program, but from the comfort of your own home.
                </p>
              </span>
            ),
          },
          {
            title: 'How much does the course cost?',
            text: (
              <span>
                <p>
                  This course is an accelerated process that takes you through
                  an intensive animation and storytelling curriculum in 21 days.
                  You can access this course — and live, daily workshops — with
                  a standard subscription, which starts at{' '}
                  {getDisplayPrice(
                    props.subscription.userCurrency || '',
                    monthlyPrice?.amount || 0
                  )}
                  /mo billed annually (
                  {getDisplayPrice(
                    props.subscription.userCurrency || '',
                    yearlyPrice?.amount || 0
                  )}
                  ).
                </p>
              </span>
            ),
          },
          {
            title: 'Do you have a refund policy?',
            text: (
              <span>
                <p>
                  Yes. If for any reason you want to refund your subscription,
                  please{' '}
                  <a
                    href="mailto:hello@daisie.com"
                    style={{ textDecoration: 'underline' }}
                  >
                    contact us
                  </a>
                  .
                </p>
              </span>
            ),
          },
          {
            title: 'Is there a trial period? ',
            text: (
              <span>
                <p>
                  Yes! We offer a 7-day trial period. During your trial period,
                  you’ll get full access to Daisie.
                </p>
              </span>
            ),
          },
          {
            title: 'Does it matter which time zone I’m in? ',
            text: (
              <span>
                <p>
                  No, it doesn't matter which time zone you are in. You can take
                  this course from anywhere in the world.
                </p>
                <br />
                <p>
                  The classes are pre-recorded, so you can watch them around
                  your own schedule. We recommend you watching a class a day,
                  but you can decide when you will complete them.
                </p>
              </span>
            ),
          },
        ]}
        mt={[pxToRem(40), pxToRem(128)]}
      />
    </Box>
  );
};

export const FaqSection = connect(['subscription'], () => ({}))(FaqSectionComp);
