import delve from 'dlv';

import { AuthType } from '@/enums/AuthType';
import { GtmEvent } from '@/enums/GtmEvent';
import { Http409Message } from '@/enums/Http409Message';
import { HTTPStatusCode } from '@/enums/HTTPStatusCode';
import { TrackingLocation } from '@/enums/TrackingLocation';
import { UserVisibility } from '@/enums/UserVisibility';

import { routes } from '@/routes/index';

import {
  getCollaborationInvitationsFromData,
  getCollaborationRequestsFromData,
  getMarketingPreferencesFromData,
  getUserFromData,
  getUsersFromData,
} from '@/store/helpers';
import { getRoomFromData } from '@/store/helpers/roomHelpers';

import { Http } from '@/utils/api/Http';
import { MAX_VIEWED_PROJECTS } from '@/utils/constants';
import { sendGtmEvent } from '@/utils/gtm/sendGtmEvent';
import { alias, identify, reset, set, track } from '@/utils/mixpanel/mixpanel';
import { alphabetiseKeys, isEmpty } from '@/utils/objects/object-manipulation';

const { DAISIE_LOGIN_STORAGE_KEY: key = '' } = process.env;

const storage = {
  get: () => localStorage.getItem(key),
  set: (value: any) => localStorage.setItem(key, value),
  remove: () => localStorage.removeItem(key),
};

const authState: AuthState = {
  auth: {
    isAuthorised: !!storage.get(),
    user: !!storage.get() ? null : undefined,
    settings: !!storage.get() ? {} : undefined,
    viewedProjects: {
      rooms: [],
      isPreloading: false,
      isFinished: false,
    },
  },
};

const authActions = (store: any) => ({
  login: async (state: GlobalStoreState, credentials: any, type?: string) => {
    try {
      let loginEndpoint = '/users/login';

      if (type === AuthType.GoogleSSO) {
        loginEndpoint = '/users/login/google/web';
      }

      const { data } = await new Http(loginEndpoint).post<APIObject>(
        credentials
      );
      identify(data.id);
      track('log_in');

      storage.set(data.attributes.token);

      store.setState({
        auth: { ...state.auth, isAuthorised: true },
      });

      const { search } = window.location;
      const urlParams = new URLSearchParams(search);

      const redirectTo = !!urlParams.get('to');
      const skipAnalytics = !!urlParams.get('skipAnalytics');

      const defaultRedirectRoute = data.attributes.isNewUser
        ? routes.optimisedJoinFlowChoosePlan
        : routes.home;

      if (skipAnalytics) {
        window.location.href = `${defaultRedirectRoute}?skipAnalytics=true`;
      } else {
        window.location.href = redirectTo
          ? (urlParams.get('to') as string)
          : defaultRedirectRoute;
      }
    } catch (e) {
      reset();
      storage.remove();
      store.setState({
        auth: {
          ...state.auth,
          user: null,
          isAuthorised: false,
        },
      });

      throw new Error(e.status);
    }
  },

  tokenLogin: async (state: GlobalStoreState, token: string | null) => {
    if (!token) {
      throw new Error('Missing token');
    }

    try {
      storage.set(token);

      const { data: userData } = await new Http('/users/me').get<
        APIObject<APIUser>
      >();

      const user = getUserFromData(userData);

      store.setState({ auth: { ...state.auth, isAuthorised: true, user } });
    } catch (e) {
      reset();
      storage.remove();
      store.setState({
        auth: { ...state.auth, user: null, isAuthorised: false },
      });

      throw new Error('Unable to log in with token');
    }
  },

  logout: async (state: GlobalStoreState) => {
    track('log_out');
    reset();
    storage.remove();
    return { auth: { ...state.auth, user: null, isAuthorised: false } };
  },

  signup: async (
    state: GlobalStoreState,
    user: any,
    from: TrackingLocation,
    type?: string
  ) => {
    try {
      const { code, ...properties } = user;

      let signUpEndoint = `/users?code=${code}`;

      if (type === AuthType.GoogleSSO) {
        signUpEndoint = '/users/login/google/web';
      }

      const { data } = await new Http(signUpEndoint).post<APIObject>(
        properties
      );

      if (type === AuthType.GoogleSSO) {
        user.email = data.attributes.email;
        user.username = data.attributes.username;
        user.createdAt = data.attributes.createdAt;
      }
      alias(data.id);
      set({
        $email: user.email,
        $first_name: user.username,
        $created: data.attributes.createdAt,
        username: user.username,
      });
      track('sign_up', { from });

      sendGtmEvent(GtmEvent.SignUp);

      localStorage.setItem(`${data.id}_hide_join_flow_skip`, 'true');
    } catch (e) {
      if (e.status === 409) {
        const body = await e.json();

        if (body && body.errors[0] && body.errors[0].title) {
          switch (body.errors[0].title) {
            case Http409Message.SameUsername:
              throw new Error(Http409Message.SameUsername);
            case Http409Message.SameEmail:
              throw new Error(Http409Message.SameEmail);
            default:
              throw e;
          }
        } else {
          throw e;
        }
      }

      throw e;
    }

    return {
      ...state,
      auth: {
        ...state.auth,
      },
    };
  },

  getCurrentUser: async (
    state: any,
    force: boolean = false,
    skipCache = false
  ) => {
    if (state.auth.user && !force) {
      return state;
    }

    if (state.auth.user === undefined) {
      return state;
    }

    let user;

    try {
      const { data } = await new Http(
        `/users/me${skipCache ? '?skipCache=true' : ''}`
      ).get<APIObject<APIUser>>();

      identify(data.id);

      user = getUserFromData(data);
    } catch (e) {
      if (e.status === HTTPStatusCode.UNAUTHORIZED_401) {
        throw new Error();
      }

      if (e.toString() === 'TypeError: Failed to fetch') {
        return;
      }

      reset();
      storage.remove();
    }

    const { viewedProjects } = state.auth;

    return {
      auth: {
        isAuthorised: !!user,
        user: {
          ...user,
          collaborationInvitations:
            state.auth.user && state.auth.user.collaborationInvitations
              ? state.auth.user.collaborationInvitations
              : [],
        },
        settings: {},
        viewedProjects: viewedProjects.isFinished
          ? viewedProjects
          : {
              rooms: [],
              isPreloading: false,
              isFinished: false,
            },
      },
    };
  },

  updateNotificationPreferences: async (
    state: GlobalStoreState,
    notificationPreferences: NotificationPreferences[]
  ) => {
    try {
      await new Http('/users/me').patch({
        notificationPreferences,
      });
    } catch (e) {
      throw e;
    }

    return {
      auth: {
        ...state.auth,
        user: {
          ...state.auth.user,
          notificationPreferences: alphabetiseKeys(notificationPreferences),
        },
      },
    };
  },

  getMarketingPreferences: async (state: GlobalStoreState) => {
    if (state.auth.user === undefined) {
      return state;
    }

    let marketingPreferences;

    try {
      const { data } = await new Http('/marketingPreferences').get<APIObject>();

      if (isEmpty(data)) {
        throw new Error();
      }

      const marketingPreferencesData = delve(
        data,
        'attributes.marketingPreferences'
      );

      marketingPreferences = getMarketingPreferencesFromData(
        marketingPreferencesData
      );
    } catch (e) {
      throw e;
    }

    return {
      auth: {
        ...state.auth,
        settings: {
          ...state.auth.settings,
          marketingPreferences,
        },
      },
    };
  },

  updateMarketingPreference: async (
    state: GlobalStoreState,
    preference: MarketingPreference
  ) => {
    const marketingPreferences = delve(
      state,
      'auth.settings.marketingPreferences'
    );

    const payload = {};
    payload[preference.marketingPrefsType] = preference.isAccepted;

    try {
      await new Http('/marketingPreferences').patch(payload);
      track('Marketing preferences updated');
    } catch (e) {
      throw e;
    }

    marketingPreferences.forEach((pref: MarketingPreference, index: number) => {
      if (pref.uuid === preference.uuid) {
        marketingPreferences[index].isAccepted = preference.isAccepted;
      }
    });

    return {
      auth: {
        ...state.auth,
        settings: {
          ...state.auth.settings,
          marketingPreferences,
        },
      },
    };
  },

  changeVisibility: async (
    state: GlobalStoreState,
    visibility: UserVisibility
  ) => {
    const { user } = state.auth;

    if (!user) {
      return;
    }

    try {
      await new Http('/users/me').patch<APIObject<APIUser>>({
        visibility,
      });
    } catch (e) {
      throw e;
    }

    const oldAuth = store.getState().auth;

    return {
      auth: {
        ...oldAuth,
        user: {
          ...oldAuth.user,
          visibility,
        },
      },
    };
  },

  updateCurrentUser: async (
    state: GlobalStoreState,
    user: any,
    forceClearLocation?: boolean
  ) => {
    if (!state.auth.user) {
      return;
    }

    let newUser = user;

    try {
      const { data } = await new Http('/users/me').patch<APIObject<APIUser>>(
        user
      );

      newUser = getUserFromData(data);

      // If my own 'user' is in state, wipe it
      if (state.user && state.user.id === state.auth.user.id) {
        store.setState({ user: undefined });
      }
    } catch (e) {
      throw e;
    }

    const oldAuth = store.getState().auth;

    return {
      auth: {
        ...oldAuth,
        isAuthorised: !!user,
        user: {
          ...oldAuth.user,
          ...newUser,
          location: forceClearLocation
            ? undefined
            : newUser.location || oldAuth.user.location,
          projectCategories: newUser.projectCategories.length
            ? newUser.projectCategories
            : oldAuth.user.projectCategories,
          roles:
            newUser.roles && newUser.roles.length
              ? newUser.roles
              : oldAuth.user.roles,
          avatar: state.auth.user.avatar,
          collaborationInvitations: state.auth.user.collaborationInvitations,
        },
      },
    };
  },

  updateCurrentUserPassword: async (state: GlobalStoreState, payload: any) => {
    try {
      await new Http('/password/change').patch(payload);
      track('update_password', { via: 'change-password' });
    } catch (e) {
      throw e;
    }
  },

  setNewAvatarURL: (state: GlobalStoreState, url: string) => {
    if (!state.auth.user) {
      return;
    }

    const avatar = url;

    return {
      auth: {
        ...state.auth,
        user: {
          ...state.auth.user,
          avatar,
        },
      },
    };
  },

  deleteCurrentUser: async (state: GlobalStoreState) => {
    try {
      await new Http('/users/me').delete();
      track('confirm_account_deletion');
      reset();
      storage.remove();
      return { auth: { ...state.auth, user: null, isAuthorised: false } };
    } catch (e) {
      throw e;
    }
  },

  getFollowingUsers: async (
    state: any,
    pageNumber?: number,
    pageSize?: number
  ) => {
    if (!state.auth.user) {
      return;
    }

    const { username } = state.auth.user;

    try {
      const { data } = await new Http(
        `/users/${username.toLowerCase()}/following?pageNumber=${
          pageNumber || 1
        }&pageSize=${pageSize || 9}`
      ).get<APIObject<APIUser>>();

      if (!data.relationships) return;

      const followingData = getUsersFromData(
        delve(data, 'relationships.following.data')
      );

      return {
        auth: {
          ...store.getState().auth,
          user: {
            ...store.getState().auth.user,
            following: {
              ...store.getState().auth.user.following,
              [pageNumber || 1]: followingData,
            },
          },
        },
      };
    } catch (e) {
      throw e;
    }
  },

  getCollaborationInvitations: async (
    state: any,
    curatedImages?: CuratedImage[]
  ) => {
    if (state.auth.user === undefined) {
      return state;
    }

    let collaborationInvitations = [] as CollaborationInvitation[];

    try {
      const { data } = await new Http('/collaborationInvitations').get<
        APIArray<APIInvitation>
      >();

      collaborationInvitations = getCollaborationInvitationsFromData(
        data,
        curatedImages
      ).filter((x) => x.status === 'pending');
    } catch (e) {
      throw e;
    }

    return {
      auth: {
        ...store.getState().auth,
        user: {
          ...store.getState().auth.user,
          collaborationInvitations,
        },
      },
    };
  },

  getCollaborationRequests: async (state: any) => {
    if (state.auth.user === undefined) {
      return state;
    }

    let collaborationRequests = [] as CollaborationRequest[];

    try {
      const { data } = await new Http('/collaborationRequests').get<
        APIArray<APICollaborationRequest>
      >();

      // @todo: remove this once selleck 496 is live
      collaborationRequests = getCollaborationRequestsFromData(data).filter(
        (x) => x.status === 'pending'
      );
    } catch (e) {
      throw e;
    }

    return {
      auth: {
        ...store.getState().auth,
        user: {
          ...store.getState().auth.user,
          collaborationRequests,
        },
      },
    };
  },

  getViewedProjects: async (state: GlobalStoreState) => {
    if (!state.auth.isAuthorised || !state.auth.user) return;

    const viewedProjectsKey = `viewedProjects_${state.auth.user.id}`;

    const storageItem = localStorage.getItem(viewedProjectsKey);

    // Get the projectIds from localStorage, and sort them so
    // the most recently viewed project appears first
    const items = storageItem ? [...storageItem.split(',')].reverse() : [];

    const initialAuthCopy = store.getState().auth;

    // Get the IDs of projects currently loaded in the store,
    // so they don't have to be reloaded
    const alreadyLoadedProjectIds = initialAuthCopy.viewedProjects.rooms.map(
      (p: PreloadedRoom) => p.id
    );

    // Reset the state with placeholders for unloaded projects,
    // or already loaded projects from the store
    store.setState({
      auth: {
        ...state.auth,
        viewedProjects: {
          isPreloading: true,
          isFinished: false,
          rooms: items.map((id: string) => {
            const alreadyLoadedProject =
              initialAuthCopy.viewedProjects.rooms.find(
                (p: PreloadedRoom) => p.id === id
              );

            if (alreadyLoadedProjectIds.includes(id) && alreadyLoadedProject) {
              return alreadyLoadedProject;
            } else {
              return {
                id,
                isLoaded: false,
              };
            }
          }),
        },
      },
    });

    for (const id of items) {
      try {
        // Stop loading projects if no longer on homepage
        if (window.location.pathname !== '/') {
          return;
        }

        const authCopy = store.getState().auth;

        // If a project is already loaded, update the store with it and skip loading it
        const alreadyLoadedProject = initialAuthCopy.viewedProjects.rooms.find(
          (p: PreloadedRoom) => p.id === id
        );

        if (alreadyLoadedProjectIds.includes(id) && alreadyLoadedProject) {
          store.setState({
            auth: {
              ...authCopy,
              viewedProjects: {
                ...authCopy.viewedProjects,
                rooms: authCopy.viewedProjects.rooms.map((p: PreloadedRoom) => {
                  if (p.id === id) {
                    return alreadyLoadedProject;
                  } else {
                    return p;
                  }
                }),
              },
            },
          });

          continue;
        }

        // Get project data
        const { data: projectData } = await new Http(`/projects/${id}`).get<
          APIObject<APIRoom>
        >();

        if (!projectData.relationships) {
          throw new Error();
        }

        const room = getRoomFromData({
          roomData: projectData,
          curatedImages: state.cinematicBackground.curatedImages,
        });

        // Update the store with the loaded project
        store.setState({
          auth: {
            ...authCopy,
            viewedProjects: {
              ...authCopy.viewedProjects,
              rooms: authCopy.viewedProjects.rooms.map((p: PreloadedRoom) => {
                if (p.id === id) {
                  return {
                    ...p,
                    isLoaded: true,
                    room,
                  };
                } else {
                  return p;
                }
              }),
            },
          },
        });
      } catch (e) {
        // Remove deleted rooms from viewedProjects
        if (e && e.status && e.status === 404) {
          const authCopy = store.getState().auth;

          store.setState({
            auth: {
              ...authCopy,
              viewedProjects: {
                ...authCopy.viewedProjects,
                rooms: authCopy.viewedProjects.rooms.filter(
                  (p: PreloadedRoom) => p.id !== id
                ),
              },
            },
          });
        }
      }
    }

    const finalAuthCopy = store.getState().auth;

    return {
      auth: {
        ...finalAuthCopy,
        viewedProjects: {
          ...finalAuthCopy.viewedProjects,
          isFinished: true,
        },
      },
    };
  },

  addToViewedProjects: (state: GlobalStoreState, room: Room) => {
    if (!state.auth.isAuthorised || !state.auth.user) return;

    const viewedProjectsKey = `viewedProjects_${state.auth.user.id}`;

    const storageItem = localStorage.getItem(viewedProjectsKey);

    const items = storageItem
      ? storageItem.split(',').filter((id: string) => id !== room.id)
      : [];

    items.push(room.id);

    localStorage.setItem(
      viewedProjectsKey,
      [items.slice(Math.max(items.length - MAX_VIEWED_PROJECTS, 0))].join(',')
    );
  },
});

export { authState, authActions };
