import * as React from 'react';

import { connect } from 'unistore/react';

import { Icon } from '@/components/global/Icon/Icon';
import { Link } from '@/components/global/Link/Link';
import { Avatar } from '@/components/users/Avatar/Avatar';
import { UserVerified } from '@/components/users/UserVerified/UserVerified';

import { IconSize } from '@/enums/IconSize';

import { parser } from '@/routes/parser';

import { modalActions } from '@/store/modules/modal';
import { portalActions } from '@/store/modules/portal';

import { c } from '@/utils/strings/c';

interface Props extends SizingState, PortalActions, ModalActions {
  users: User[];
  close: () => void;
}

class UserProfileFollowersModalComponent extends React.Component<Props> {
  private dismissModal = async () => {
    const {
      sizing: { isMobile },
    } = this.props;

    if (isMobile) {
      this.props.updatePortal({ portal: undefined });
    } else {
      this.props.updateModal({ modal: undefined });
    }
  };

  private renderFollowers = () => {
    const {
      sizing: { isMobile },
      users,
    } = this.props;

    return (
      <div className={c('mb16 mb32@m', {})}>
        {users.map((u: User) => (
          <div
            className="u-flex u-align-center u-justify-between mb16"
            key={u.id}
          >
            <Link
              to={parser({
                name: 'user',
                params: { username: u.username },
              })}
              className="u-flex u-link-black u-align-center u-1/1"
              onClick={() => {
                this.dismissModal();
              }}
            >
              <Avatar
                user={u}
                src={u.avatar}
                size={!isMobile ? 'xxxl' : 'xl'}
                className="mr16"
              />

              <div className="u-flex u-flex-column u-align-start">
                <p className="u-white u-flex u-align-center f-text-2 u-bold u-word-break u-text-center pb4">
                  <span className="mlauto mrauto">
                    {u.primaryName}
                    <UserVerified user={u} className="relative t2" />
                  </span>
                </p>
                {u.location?.name && (
                  <p className="u-grey f-text-5">
                    <span className="mlauto mrauto u-flex u-align-center">
                      <Icon
                        id="pin"
                        size={IconSize.xs}
                        className="mr4 u-light-grey"
                      />
                      {u.location.name}
                    </span>
                  </p>
                )}
              </div>
            </Link>
          </div>
        ))}
      </div>
    );
  };

  public render = () => <div>{this.renderFollowers()}</div>;
}

export const UserProfileFollowersModal = connect(['sizing'], () => ({
  ...portalActions(),
  ...modalActions(),
}))(UserProfileFollowersModalComponent);
