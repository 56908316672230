import * as React from 'react';

import { CardSkeleton } from '@/components/global/Cards/CardSkeleton';
import { WorkshopCard } from '@/components/global/Cards/WorkshopCard';
import { GenericMasonryBlock } from '@/components/projects/Masonry/GenericMasonryBlock';
import { Masonry } from '@/components/projects/Masonry/Masonry';
import {
  getMasonryTemplatePreset,
  MasonryTemplatePreset,
} from '@/components/projects/Masonry/MasonryTemplates';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

interface Props {
  calendarEvents?: CalendarEvent[];
  workshops: Workshop[];
  className?: string;
  loading?: boolean;
  uiTestIdLoaded?: string;
}

class WorkshopMasonry extends React.Component<Props> {
  private masonry: React.RefObject<Masonry>;

  constructor(props: Props) {
    super(props);

    this.masonry = React.createRef();
  }

  public componentDidMount = () => {
    window.addEventListener('resize', this.repackMasonry);
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (this.props.workshops.length !== prevProps.workshops.length) {
      this.repackMasonry();
    }
  };

  public componentWillUnmount = () => {
    window.removeEventListener('resize', this.repackMasonry);
  };

  private repackMasonry = () => {
    setTimeout(() => {
      if (this.masonry.current) {
        this.masonry.current.repack();
      }
    }, 1);
  };

  public render = () => {
    const { workshops, className, loading, uiTestIdLoaded, calendarEvents } =
      this.props;

    if (loading) {
      return (
        <div className={className}>
          <div className="u-grid u-grid--3">
            {Array.from(Array(6).keys()).map((_, key: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <CardSkeleton key={key} />
            ))}
          </div>
        </div>
      );
    }

    return (
      <div
        className={className}
        data-ui-test-id={
          isUiTest() && uiTestIdLoaded ? uiTestIdLoaded : undefined
        }
      >
        <Masonry
          ref={this.masonry}
          blocks={workshops}
          editable={false}
          template={getMasonryTemplatePreset(
            MasonryTemplatePreset.alternative_1
          )}
          render={(items: MasonryBlock[]) =>
            items.map((item: any, index: number) => {
              const rawWorkshop = item.data as Workshop;
              const workshopId = rawWorkshop.id;

              const workshopCalendarEvent: CalendarEvent | undefined =
                !!calendarEvents
                  ? calendarEvents.find((ce: CalendarEvent) =>
                      ce.workshop && ce.workshop.workshop
                        ? ce.workshop.workshop.id === workshopId
                        : false
                    )
                  : undefined;

              const start: Date = !!workshopCalendarEvent
                ? workshopCalendarEvent.start
                : new Date(rawWorkshop.start);

              const end: Date = !!workshopCalendarEvent
                ? workshopCalendarEvent.end
                : new Date(rawWorkshop.end);

              const isInProgress: boolean = !!workshopCalendarEvent
                ? workshopCalendarEvent.isInProgress
                : false;

              const workshop: Workshop =
                !!workshopCalendarEvent && workshopCalendarEvent.workshop
                  ? workshopCalendarEvent.workshop.workshop
                  : rawWorkshop;

              const rawAttendees: User[] =
                !!workshopCalendarEvent && workshopCalendarEvent.workshop
                  ? workshopCalendarEvent.workshop.attendees
                  : [];

              const rawAttendeeCount: number =
                !!workshopCalendarEvent && workshopCalendarEvent.workshop
                  ? workshopCalendarEvent.workshop.attendeeCount
                  : typeof rawWorkshop.attendeeCount !== 'undefined'
                  ? rawWorkshop.attendeeCount
                  : 0;

              const isAttending: boolean =
                !!workshopCalendarEvent && workshopCalendarEvent.workshop
                  ? workshopCalendarEvent.workshop.isAttending
                  : rawWorkshop.isAttending;

              const batchId: string =
                !!workshopCalendarEvent && workshopCalendarEvent.workshop
                  ? workshopCalendarEvent.workshop.batch.id
                  : rawWorkshop.batches && rawWorkshop.batches[0]
                  ? rawWorkshop.batches[0].id
                  : '';

              const sessionId: string = !!workshopCalendarEvent
                ? workshopCalendarEvent.id.replace('workshopSession_', '')
                : rawWorkshop.batches &&
                  rawWorkshop.batches[0] &&
                  rawWorkshop.batches[0].sessions &&
                  rawWorkshop.batches[0].sessions[0]
                ? rawWorkshop.batches[0].sessions[0].id
                : '';

              return (
                <GenericMasonryBlock
                  key={item.data.id}
                  item={item}
                  index={items.length - index}
                  className="u-flex u-white u-1/1"
                >
                  <WorkshopCard
                    workshop={workshop}
                    batchId={batchId}
                    sessionId={sessionId}
                    start={start}
                    end={end}
                    rawAttendeeCount={rawAttendeeCount}
                    rawAttendees={rawAttendees}
                    className="u-1/1"
                    isAttending={isAttending}
                    isInProgress={isInProgress}
                    hideAttendButton={true}
                  />
                </GenericMasonryBlock>
              );
            })
          }
        />
      </div>
    );
  };
}

export { WorkshopMasonry };
