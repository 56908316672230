import * as React from 'react';

import widont from 'widont';

import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import illoCalendar from '@/components/marketing/HomeCategoryPageSections/SectionPromo/illo-calendar.png';
import illoGift from '@/components/marketing/HomeCategoryPageSections/SectionPromo/illo-gift.png';
import illoGlobe from '@/components/marketing/HomeCategoryPageSections/SectionPromo/illo-globe.png';
import illoHat from '@/components/marketing/HomeCategoryPageSections/SectionPromo/illo-hat.png';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props {
  isGrandfatheringPage?: boolean;
}
class SectionPromo extends React.Component<Props> {
  public render = () => {
    const { isGrandfatheringPage } = this.props;

    return (
      <div
        className={c('wrap pv64 u-flex@m', {
          'pv200@m': !isGrandfatheringPage,
          'pt100@m': isGrandfatheringPage,
        })}
      >
        <div className="u-flex-1 u-text-center mh16 mb64@s">
          <div className="c-marketing-section-promo__circle mhauto mb32 mb44@m">
            <ImageLoader src={illoCalendar} className="ml12" />
          </div>

          <h2 className="f-lynstone-bold-2 mb24">
            {t('Interactive workshops')}
          </h2>

          <p className="f-lynstone-regular-3 u-grey">
            {widont(
              t(
                'Get answers to your most pressing questions and solve real life challenges in live, interactive workshops.'
              )
            )}
          </p>
        </div>

        <div className="u-flex-1 u-text-center mh16 mb0@s">
          <div className="c-marketing-section-promo__circle mhauto mb32 mb44@m">
            <ImageLoader src={illoHat} />
          </div>

          <h2 className="f-lynstone-bold-2 mb24">
            {t('World class creators')}
          </h2>

          <p className="f-lynstone-regular-3 u-grey">
            {widont(
              t(
                'Learn from industry-leading creators across film, fashion, art, photography, design and more.'
              )
            )}
          </p>
        </div>

        <div className="u-flex-1 u-text-center mh16 mb64@s">
          <div className="c-marketing-section-promo__circle mhauto mb32 mb44@m">
            {!isGrandfatheringPage ? (
              <ImageLoader src={illoGlobe} />
            ) : (
              <ImageLoader src={illoGift} />
            )}
          </div>

          <h2 className="f-lynstone-bold-2 mb24">
            {!isGrandfatheringPage
              ? t('Global community')
              : t('Founding member badge')}
          </h2>

          <p className="f-lynstone-regular-3 u-grey">
            {widont(
              !isGrandfatheringPage
                ? t(
                    'Get useful feedback from experts and peers that helps to develop your skills and build your confidence.'
                  )
                : t(
                    'Stand out with a founding member badge on your profile, exclusively available to early community members.'
                  )
            )}
          </p>
        </div>
      </div>
    );
  };
}

export { SectionPromo };
