import * as React from 'react';

import { CardLiveRoomSpeakers } from '@/components/global/Cards/CardLiveRoomSpeakers';
import { CardStyle } from '@/components/global/Cards/CardStyle';
import imgRadio from '@/components/global/Cards/radio.png';
import { Icon } from '@/components/global/Icon/Icon';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { TimeRemaining } from '@/components/global/TimeRemaining/TimeRemaining';

import { ProgressBarTimeLeft } from '@/components/design_system/ProgressBar/ProgressBarTimeLeft';

import { IconSize } from '@/enums/IconSize';

import { renderBulletTimestamp } from '@/utils/date/date-manipulation';
import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props {
  coverImageUrl?: string;
  description?: string;
  start?: Date;
  end?: Date;
  category?: Category;
  type: 'workshop' | 'room';
  actionButton: React.ReactNode;
  isInProgress?: boolean;
  speakingUsers?: User[];
  organiser?: User;
  nudgeUpSpeakers?: boolean;
  useShortMinutesLabel?: boolean;
  cardStyle?: CardStyle;
  isFree: boolean;
}

export const CardCover = (props: Props) => {
  const [hidePill, setHidePill] = React.useState(false);

  const {
    coverImageUrl,
    description,
    start,
    end,
    category,
    type,
    actionButton,
    isInProgress,
    speakingUsers,
    organiser,
    nudgeUpSpeakers,
    useShortMinutesLabel = false,
    cardStyle = CardStyle.default,
    isFree,
  } = props;

  const entityName =
    type === 'workshop'
      ? cardStyle === CardStyle.profile
        ? ''
        : t('Workshop')
      : t('Room');

  const showProgressInfo = type === 'workshop';

  return (
    <div
      className="c-card__cover u-bg-sad-grey mb16 relative"
      onMouseOver={() => setHidePill(true)}
      onMouseOut={() => setHidePill(false)}
    >
      {isFree && (
        <p
          style={{
            padding: '4px 12px',
            fontSize: 12,
            color: '#fff',
            backgroundColor: '#24BF50',
            borderRadius: 8,
            position: 'absolute',
            top: 24,
            left: 24,
            zIndex: 9,
            opacity: hidePill ? 0.2 : 1,
            transition: '0.3s',
          }}
        >
          free workshop
        </p>
      )}
      {coverImageUrl && type === 'workshop' && (
        <ImageLoader
          src={coverImageUrl}
          alt=""
          className="u-object-cover fill"
          convertToWebp={true}
        />
      )}

      {type === 'room' &&
        (!isInProgress ||
          (isInProgress && !speakingUsers) ||
          (isInProgress && speakingUsers && speakingUsers.length === 0)) && (
          <ImageLoader src={imgRadio} alt="" className="c-card__cover__radio" />
        )}

      {type === 'room' &&
        isInProgress &&
        speakingUsers &&
        speakingUsers.length > 0 && (
          <CardLiveRoomSpeakers
            speakingUsers={speakingUsers}
            organiser={organiser}
            nudgeUp={nudgeUpSpeakers}
          />
        )}

      <div className="c-card__meta fill">
        {!!description && (
          <div
            className={c('c-card__meta__description', {
              'mh24 pt24': cardStyle === CardStyle.default,
              'mh20 pt20': cardStyle === CardStyle.profile,
            })}
          >
            <p className="f-text-1a">{description}</p>
          </div>
        )}

        <div
          className={c('c-card__meta__bottom', {
            'mtauto-i': !!description === false,
            m24: cardStyle === CardStyle.default,
            m20: cardStyle === CardStyle.profile,
          })}
        >
          <div
            className={c('u-flex', {
              'u-flex u-align-center': !!category === false && type === 'room',
            })}
          >
            <div className="u-flex-1 mr16">
              <p
                className={c('f-text-3 u-grey u-flex u-align-center', {
                  mb4: !!start,
                  'u-hide': !!category === false && type === 'room',
                })}
              >
                {category ? (
                  <Icon
                    id={`category-${category.slug}`}
                    size={IconSize.xs}
                    className="mr8 u-flex-hold"
                  />
                ) : null}

                <span className="u-flex-hold">
                  {category ? `${category.name} ${entityName}` : entityName}
                </span>
              </p>

              {!!start && !isInProgress && (
                <p className="f-text-3 u-bold">
                  {renderBulletTimestamp(start)}
                </p>
              )}

              {showProgressInfo && !!start && !!end && isInProgress && (
                <div className="u-flex">
                  {cardStyle !== CardStyle.profile && (
                    <p className="f-text-3 u-bold mr4 u-flex-hold">
                      {t('In progress')} •
                    </p>
                  )}
                  <TimeRemaining
                    start={start}
                    end={end}
                    className="f-text-3 u-bold u-danger u-flex-hold"
                    useShortMinutesLabel={useShortMinutesLabel}
                  />
                </div>
              )}
            </div>

            {!!actionButton && actionButton}
          </div>

          {showProgressInfo && !!start && !!end && (
            <ProgressBarTimeLeft start={start} end={end} className="mt16" />
          )}
        </div>

        <div className="c-card__meta__active_bg fill" />
      </div>
    </div>
  );
};
