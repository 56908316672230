import * as React from 'react';

import Hls from 'hls.js';
import { connect } from 'unistore/react';

import { Icon } from '@/components/global/Icon/Icon';
import { LoadingSymbol } from '@/components/global/LoadingSymbol/LoadingSymbol';

import { IconSize } from '@/enums/IconSize';

import { formatSeconds } from '@/utils/media/formatSeconds';
import { c } from '@/utils/strings/c';

interface Props extends SizingState {
  videoUrl: string;
  onMobileFullScreenExit: () => void;
  className?: string;
  actionButton?: () => React.ReactNode;
}

interface State {
  isLoading: boolean;
  currentTime: number;
  duration?: number;
  isPlaying: boolean;
  isScrubberHandleActive: boolean;
  scrubberHandleDragPosition?: number;
  isControlsHidden: boolean;
  isMuted: boolean;
}

class NativePlayerComponent extends React.Component<Props, State> {
  public state: State = {
    isLoading: true,
    currentTime: 0,
    duration: undefined,
    isPlaying: false,
    isScrubberHandleActive: false,
    scrubberHandleDragPosition: undefined,
    isControlsHidden: false,
    isMuted: false,
  };

  // REFS
  private refVideo: React.RefObject<HTMLVideoElement>;
  private refScrubberTrack: React.RefObject<HTMLButtonElement>;

  // RUNTIME VARS
  private timerAutoHideControls?: NodeJS.Timeout;
  private hlsInstance?: Hls;

  // CONFIG
  private AUTO_HIDE_CONTROLS_TIMEOUT: number = 1500;

  constructor(props: Props) {
    super(props);

    this.refVideo = React.createRef();
    this.refScrubberTrack = React.createRef();
  }

  public componentDidMount = () => {
    this.addVideoPlayerEventListeners();

    document.addEventListener('mousemove', this.handleScrubberHandleMouseMove);
    document.addEventListener('mouseup', this.handleScrubberHandleMouseUp);

    this.startAutoHideControlsTimer();

    this.initialiseHls();
  };

  public componentWillUnmount = () => {
    if (this.hlsInstance) {
      this.hlsInstance.destroy();
    }

    this.removeVideoPlayerEventListeners();

    document.removeEventListener(
      'mousemove',
      this.handleScrubberHandleMouseMove
    );
    document.addEventListener('mouseup', this.handleScrubberHandleMouseUp);

    if (this.timerAutoHideControls) {
      clearTimeout(this.timerAutoHideControls);
    }
  };

  // HLS
  private initialiseHls = () => {
    if (!this.refVideo.current) {
      return;
    }

    const { videoUrl } = this.props;

    this.hlsInstance = new Hls();
    this.hlsInstance.attachMedia(this.refVideo.current);

    this.hlsInstance.on(Hls.Events.MEDIA_ATTACHED, () => {
      if (this.hlsInstance) {
        this.hlsInstance.loadSource(
          videoUrl.replace(';media.mp4', ';audio,video(HD)/master.m3u8')
        );
      }
    });
  };

  // AUTOHIDE
  private startAutoHideControlsTimer = () => {
    this.timerAutoHideControls = setTimeout(() => {
      this.setState({ isControlsHidden: true });
    }, this.AUTO_HIDE_CONTROLS_TIMEOUT);
  };

  // EVENT LISTENERS
  private addVideoPlayerEventListeners = () => {
    if (this.refVideo.current) {
      this.refVideo.current.addEventListener(
        'webkitendfullscreen',
        this.handleMobileFullScreenExit
      );

      this.refVideo.current.addEventListener(
        'loadedmetadata',
        this.addLoadedMetadataEventListener
      );

      this.refVideo.current.addEventListener(
        'canplay',
        this.addCanPlayEventListener
      );

      this.refVideo.current.addEventListener('play', this.addPlayEventListener);

      this.refVideo.current.addEventListener(
        'timeupdate',
        this.addTimeUpdateEventListener
      );

      this.refVideo.current.addEventListener(
        'pause',
        this.addPauseEventListener
      );

      this.refVideo.current.addEventListener(
        'volumechange',
        this.addVolumeChangeEventListener
      );
    }
  };

  private removeVideoPlayerEventListeners = () => {
    if (this.refVideo.current) {
      this.refVideo.current.removeEventListener(
        'webkitendfullscreen',
        this.handleMobileFullScreenExit
      );

      this.refVideo.current.removeEventListener(
        'loadedmetadata',
        this.addLoadedMetadataEventListener
      );

      this.refVideo.current.removeEventListener(
        'canplay',
        this.addCanPlayEventListener
      );

      this.refVideo.current.removeEventListener(
        'play',
        this.addPlayEventListener
      );

      this.refVideo.current.removeEventListener(
        'timeupdate',
        this.addTimeUpdateEventListener
      );

      this.refVideo.current.removeEventListener(
        'pause',
        this.addPauseEventListener
      );

      this.refVideo.current.removeEventListener(
        'volumechange',
        this.addVolumeChangeEventListener
      );
    }
  };

  private handleMobileFullScreenExit = () => {
    const {
      sizing: { isMobile },
    } = this.props;

    if (!isMobile) {
      return;
    }

    this.props.onMobileFullScreenExit();
  };

  private addLoadedMetadataEventListener = () => {
    if (this.refVideo.current) {
      this.setState({ duration: this.refVideo.current.duration });
    }
  };

  private addCanPlayEventListener = () => {
    this.setState({ isLoading: false });
  };

  private addPlayEventListener = () => {
    this.setState({ isPlaying: true });
  };

  private addTimeUpdateEventListener = () => {
    if (this.refVideo.current) {
      this.setState({ currentTime: this.refVideo.current.currentTime });
    }
  };

  private addPauseEventListener = () => {
    this.setState({ isPlaying: false });
  };

  private addVolumeChangeEventListener = () => {
    if (this.refVideo.current) {
      this.setState({ isMuted: this.refVideo.current.muted });
    }
  };

  // BUTTON HANDLERS
  private handlePlayPause = (e: any) => {
    e.stopPropagation();

    if (!this.refVideo.current) {
      return;
    }

    const { isPlaying } = this.state;

    if (isPlaying) {
      this.refVideo.current.pause();
    } else {
      this.refVideo.current.play();
    }
  };

  private handleFullScreen = (e: any) => {
    e.stopPropagation();

    if (!this.refVideo.current) {
      return;
    }

    if (this.refVideo.current.requestFullscreen) {
      this.refVideo.current.requestFullscreen();
      // @ts-ignore - browser prefix
    } else if (this.refVideo.current.webkitRequestFullscreen) {
      // @ts-ignore - browser prefix
      this.refVideo.current.webkitRequestFullscreen();
      // @ts-ignore - browser prefix
    } else if (this.refVideo.current.msRequestFullScreen) {
      // @ts-ignore - browser prefix
      this.refVideo.current.msRequestFullScreen();
    }
  };

  private handleForward10 = (e: any) => {
    e.stopPropagation();

    if (!this.refVideo.current) {
      return;
    }

    this.refVideo.current.currentTime = this.refVideo.current.currentTime + 10;
  };

  private handleBackward10 = (e: any) => {
    e.stopPropagation();

    if (!this.refVideo.current) {
      return;
    }

    this.refVideo.current.currentTime = this.refVideo.current.currentTime - 10;
  };

  private handleScrubberTrackClick = (e: any) => {
    e.stopPropagation();

    const { duration } = this.state;

    if (
      !this.refScrubberTrack.current ||
      typeof duration === 'undefined' ||
      !this.refVideo.current
    ) {
      return;
    }

    const { x: scrubberTrackX, width: scrubberTrackWidth } =
      this.refScrubberTrack.current.getBoundingClientRect();

    const clickPosition: number = e.pageX - scrubberTrackX;

    const clickPositionPercent: number =
      (clickPosition / scrubberTrackWidth) * 100;

    this.refVideo.current.currentTime = (duration * clickPositionPercent) / 100;
  };

  private handleVolumeButton = (e: any) => {
    e.stopPropagation();

    if (!this.refVideo.current) {
      return;
    }

    const { isMuted } = this.state;

    this.refVideo.current.muted = !isMuted;
  };

  private handleScrubberHandleMouseDown = () => {
    this.setState({
      isScrubberHandleActive: true,
      scrubberHandleDragPosition: undefined,
    });
  };

  private handleScrubberHandleMouseMove = (e: any) => {
    const { isScrubberHandleActive, duration } = this.state;

    // Controls autohide
    if (this.timerAutoHideControls) {
      clearTimeout(this.timerAutoHideControls);
    }

    this.setState({ isControlsHidden: false }, () => {
      this.startAutoHideControlsTimer();
    });

    if (
      !isScrubberHandleActive ||
      !this.refScrubberTrack.current ||
      typeof duration === 'undefined'
    ) {
      return;
    }

    const { x: scrubberTrackX, width: scrubberTrackWidth } =
      this.refScrubberTrack.current.getBoundingClientRect();

    const dragPosition: number = e.pageX - scrubberTrackX;

    const dragPositionClamped: number =
      dragPosition < 0
        ? 0
        : dragPosition >= scrubberTrackWidth
        ? scrubberTrackWidth
        : dragPosition;

    const scrubberHandleDragPositionPercent: number =
      (dragPositionClamped / scrubberTrackWidth) * 100;

    this.setState(
      {
        scrubberHandleDragPosition: dragPositionClamped,
      },
      () => {
        // TODO: use timer to debounce this
        if (!this.refVideo.current) {
          return;
        }

        this.refVideo.current.currentTime =
          (duration * scrubberHandleDragPositionPercent) / 100;
      }
    );
  };

  private handleScrubberHandleMouseUp = () => {
    this.setState({
      isScrubberHandleActive: false,
      scrubberHandleDragPosition: undefined,
    });
  };

  // RENDERING
  private renderScrubber = () => {
    const { currentTime, duration, scrubberHandleDragPosition } = this.state;

    const playedWidth: number =
      typeof duration === 'undefined' ? 0 : (currentTime / duration) * 100;

    return (
      <div className="c-native-player__scrubber">
        <div className="c-native-player__scrubber__playback_track">
          <div
            className="c-native-player__scrubber__playback_track__played"
            style={{
              width:
                typeof scrubberHandleDragPosition !== 'undefined'
                  ? scrubberHandleDragPosition
                  : `${playedWidth}%`,
            }}
          />
        </div>

        <button
          ref={this.refScrubberTrack}
          type="button"
          className="c-native-player__scrubber__handle_track"
          onClick={this.handleScrubberTrackClick}
        >
          <button
            type="button"
            className="c-native-player__scrubber__handle_track__handle"
            style={{
              left:
                typeof scrubberHandleDragPosition !== 'undefined'
                  ? scrubberHandleDragPosition
                  : `${playedWidth}%`,
            }}
            onMouseDown={this.handleScrubberHandleMouseDown}
          />
        </button>
      </div>
    );
  };

  public render = () => {
    const { className = '', videoUrl, actionButton } = this.props;
    const {
      duration,
      isLoading,
      isPlaying,
      currentTime,
      isControlsHidden,
      isMuted,
    } = this.state;

    // TODO: add poster prop to <video>

    return (
      <div className={c(['c-native-player u-white', className])}>
        <div className="c-native-player__video">
          <video ref={this.refVideo} autoPlay>
            <source
              src={videoUrl.replace(
                ';media.mp4',
                ';audio,video(HD)/master.m3u8'
              )}
            />
          </video>

          <div
            className={c(
              'c-native-player__video__overlay animate-opacity u-hide@s',
              {
                'opacity-1': !isControlsHidden,
                'opacity-0': isControlsHidden && !isLoading,
              }
            )}
            onClick={this.handlePlayPause}
          >
            <div className="c-native-player__video__overlay__centre_controls">
              <div className="c-native-player__video__overlay__centre_controls__content">
                {isLoading ? (
                  <LoadingSymbol size="l" colour="white" />
                ) : (
                  <>
                    <button type="button" onClick={this.handleBackward10}>
                      <Icon id="replay-10" size={IconSize.l} />
                    </button>

                    <button type="button" onClick={this.handlePlayPause}>
                      <Icon
                        id={isPlaying ? 'pause' : 'play-triangle'}
                        size={IconSize.xxl}
                        className="mh44"
                      />
                    </button>

                    <button type="button" onClick={this.handleForward10}>
                      <Icon id="forward-10" size={IconSize.l} />
                    </button>
                  </>
                )}
              </div>

              <div className="c-native-player__video__overlay__centre_controls__bg" />
            </div>

            <div className="c-native-player__video__overlay__bottom_controls">
              <button type="button" onClick={this.handleVolumeButton}>
                <Icon
                  id={isMuted ? 'volume-off' : 'volume-on'}
                  size={IconSize.s}
                />
              </button>

              <div className="u-flex u-2/3 mhauto">
                <p className="c-native-player__timestamp f-text-2 mr24 u-text-right">
                  {formatSeconds(currentTime)}
                </p>

                {this.renderScrubber()}

                <p className="c-native-player__timestamp f-text-2 ml24">
                  {!!duration ? formatSeconds(duration) : '00:00'}
                </p>
              </div>

              {!!actionButton && actionButton()}

              <button type="button" onClick={this.handleFullScreen}>
                <Icon id="full-screen" size={IconSize.s} />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export const NativePlayer = connect(['sizing'], () => ({}))(
  NativePlayerComponent
);
