import * as React from 'react';

import { connect } from 'unistore/react';

import { Toast } from '@/components/global/Toaster/Toast';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';
import { ButtonGroup } from '@/components/design_system/ButtonGroup/ButtonGroup';

import { UserVisibility } from '@/enums/UserVisibility';

import { PROFILE_VISIBILITY_FAIL } from '@/messages/errors';
import { PROFILE_VISIBILITY_SUCCESS } from '@/messages/messages';

import { authActions } from '@/store/modules/auth';
import { modalActions } from '@/store/modules/modal';
import { portalActions } from '@/store/modules/portal';

import { isMobile } from '@/utils/DOM/sizing';
import { t } from '@/utils/i18n/i18n';

interface Props extends PortalActions, ModalActions, AuthActions, AuthState {}

class PrivateProfileModalPortalComponent extends React.Component<Props> {
  private makePrivate = async () => {
    try {
      await this.props.changeVisibility(UserVisibility.LoggedIn);

      if (isMobile()) {
        this.props.updatePortal({
          portal: undefined,
        });

        return;
      }

      this.props.updateModal({
        modal: undefined,
      });

      new Toast({
        body: PROFILE_VISIBILITY_SUCCESS,
      }).dispatch();
    } catch (e) {
      new Toast({
        body: PROFILE_VISIBILITY_FAIL,
        failure: true,
      }).dispatch();
    }
  };

  private dismissModalPortal = () => {
    if (isMobile()) {
      this.props.updatePortal({
        portal: undefined,
      });
    } else {
      this.props.updateModal({
        modal: undefined,
      });
    }
  };

  public render = () => (
    <>
      <p className="f-text-2 mb32 u-very-light-grey">
        {t(
          'By default, anyone can see your profile on Daisie. You can make your account private so that only people logged into Daisie can see what you’re working on. You can switch back to a public account at any time.'
        )}
      </p>

      <ButtonGroup>
        <Button
          type={ButtonType.action}
          text={t('Cancel')}
          onClick={() => this.dismissModalPortal()}
          buttonStyle={ButtonStyle.dark}
          className="u-flex-1@s"
        />

        <Button
          type={ButtonType.action}
          text={t('Make private')}
          onClick={async () => await this.makePrivate()}
          buttonStyle={ButtonStyle.light}
          className="u-flex-1@s"
        />
      </ButtonGroup>
    </>
  );
}

export const PrivateProfileModalPortal = connect(['auth'], (store: any) => ({
  ...portalActions(),
  ...modalActions(),
  ...authActions(store),
}))(PrivateProfileModalPortalComponent);
