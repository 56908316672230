import * as React from 'react';

import { Icon } from '@/components/global/Icon/Icon';
import { Link } from '@/components/global/Link/Link';

import { IconSize } from '@/enums/IconSize';

import { c } from '@/utils/strings/c';

interface Props {
  className?: string;
  iconId?: string;
  text?: string;
  linkTo?: string;
  withShadow?: boolean;
  style?: 'light' | 'dark' | 'light-translucent';
  isNewWorkshopMobile?: boolean;
}

export const LabelPill = (props: Props) => {
  const {
    className = '',
    iconId,
    text,
    linkTo,
    withShadow,
    style = 'dark',
    isNewWorkshopMobile,
  } = props;

  const content = (
    <>
      {iconId && <Icon id={iconId} className="mr8" size={IconSize.xs} />}
      {text && <p className="mt2@m">{text}</p>}
    </>
  );

  return (
    <div
      className={c(
        [
          className,
          'f-text-4 ph16 pv8 u-inline-block u-border-radius--pill u-bold u-white',
        ],
        {
          'u-flex u-align-center': !!iconId,
          'u-bg-translucent-black': style === 'dark',
          'u-bg-translucent-black--shadow': style === 'dark' && withShadow,
          'u-bg-translucent-white': style === 'light',
          'u-bg-very-translucent-white': style === 'light-translucent',
          'f-text-3@s': isNewWorkshopMobile,
        }
      )}
    >
      {linkTo ? (
        <Link
          to={linkTo}
          className={c('u-link-white u-link-white--alt', {
            'u-flex u-align-center': !!iconId,
          })}
        >
          {content}
        </Link>
      ) : (
        content
      )}
    </div>
  );
};
