export const copyLink = (
  reference: React.RefObject<HTMLInputElement>,
  callback: () => any
) => {
  if (reference.current) {
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      const el = reference.current;
      const oldContentEditable = el.contentEditable;
      const oldReadOnly = el.readOnly;
      const range = document.createRange();

      el.contentEditable = 'true';
      el.readOnly = false;
      range.selectNodeContents(el);

      const s = window.getSelection();
      if (s) {
        s.removeAllRanges();
        s.addRange(range);
      }

      el.setSelectionRange(0, 999999);

      el.contentEditable = oldContentEditable;
      el.readOnly = oldReadOnly;
    } else {
      reference.current.select();
    }

    document.execCommand('copy');

    callback();

    reference.current.blur();
  }
};
