import * as React from 'react';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { Toast } from '@/components/global/Toaster/Toast';
import { ToastCard } from '@/components/global/Toaster/ToastCard';

interface State {
  activeToasts: IToast[];
}

class Toaster extends React.Component<{}, State> {
  private toast: Toast;
  private toastLength: number;

  constructor(props: {}) {
    super(props);
    this.toast = new Toast();
    this.toastLength = 2000;
    this.state = {
      activeToasts: [],
    };
  }

  public componentDidMount = () => {
    this.toast.addListener(this.triggerToast);
  };

  public componentWillUnmount = () => {
    this.toast.removeListener(this.triggerToast);
  };

  private triggerToast = (event: any) => {
    const { activeToasts } = this.state;

    const toastObject: IToast = {
      id: performance.now(),
      ...event.detail,
    };

    if (toastObject.id) {
      activeToasts.push(toastObject);

      ((id: number) => {
        setTimeout(() => {
          this.removeToast(id);
        }, this.toastLength);
      })(toastObject.id);

      this.setState({
        activeToasts,
      });
    }
  };

  private removeToast = (id: number) => {
    const { activeToasts } = this.state;

    const removableToastIndex = activeToasts.findIndex(
      (t: IToast) => t.id === id
    );

    activeToasts.splice(removableToastIndex, 1);

    this.setState({
      activeToasts,
    });
  };

  public render() {
    return (
      <ReactCSSTransitionGroup
        transitionName="t-toast"
        transitionAppear={true}
        transitionAppearTimeout={300}
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
        component="div"
      >
        {this.state.activeToasts.map((toast: IToast) => (
          <ToastCard key={toast.id} data={toast} />
        ))}
      </ReactCSSTransitionGroup>
    );
  }
}

export { Toaster };
