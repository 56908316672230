import * as React from 'react';

import { c } from '@/utils/strings/c';

interface Props {
  size: number;
  strokeWidth: number;
  percent: number;
  strokeColour?: string;
  className?: string;
}

class ProgressBarArc extends React.Component<Props> {
  public render = () => {
    const {
      size,
      strokeWidth,
      percent,
      strokeColour = 'white',
      className = '',
    } = this.props;

    const radius = (size - strokeWidth) / 2;
    const circumference = radius * 2 * Math.PI;
    const offset = circumference - (percent / 100) * circumference;

    return (
      <div
        className={c(['dds-progress-bar-arc', className])}
        style={{ width: size, height: size }}
      >
        <svg width={size} height={size}>
          <circle
            stroke={strokeColour}
            strokeWidth={strokeWidth}
            fill="transparent"
            r={radius}
            cx={size / 2}
            cy={size / 2}
            strokeDasharray={`${circumference} ${circumference}`}
            strokeDashoffset={offset}
          />
        </svg>
      </div>
    );
  };
}

export { ProgressBarArc };
