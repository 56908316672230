import * as React from 'react';

import { ProgressBarArc } from '@/components/design_system/ProgressBar/ProgressBarArc';

import { mapRange } from '@/utils/strings/number-manipulation';

interface Props {
  size: number;
  strokeWidth: number;
  durationMs: number;
  className?: string;
}

interface State {
  elapsedMs: number;
  percent: number;
}

class ProgressBarArcTimer extends React.Component<Props, State> {
  // CONFIG
  private REFRESH_RATE: number = 250;

  public state: State = {
    elapsedMs: 0,
    percent: 0,
  };

  private timer?: NodeJS.Timeout;

  public componentDidMount = () => {
    this.animate();
  };

  public componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  };

  private animate = () => {
    const { durationMs } = this.props;

    this.timer = setTimeout(() => {
      this.setState(
        {
          elapsedMs: this.state.elapsedMs + this.REFRESH_RATE,
          percent: mapRange(
            this.state.elapsedMs + this.REFRESH_RATE,
            0,
            durationMs,
            0,
            100
          ),
        },
        () => {
          if (this.state.elapsedMs !== durationMs) {
            this.animate();
          }
        }
      );
    }, this.REFRESH_RATE);
  };

  public render = () => {
    const { percent } = this.state;
    const { size, strokeWidth, className } = this.props;

    return (
      <ProgressBarArc
        size={size}
        strokeWidth={strokeWidth}
        percent={percent}
        className={className}
      />
    );
  };
}

export { ProgressBarArcTimer };
