import {
  CHECK_FONTS_LOADED_DELAY,
  CHECK_FONTS_LOADED_RETRIES,
} from '@/utils/constants';

export const checkFontsLoaded = (parentResolve?: any, attempt: number = 0) =>
  new Promise((resolve, reject) => {
    const html = document.querySelector('html');

    if (!html) {
      return reject();
    }

    const fontsLoaded = html.classList.contains('wf-active');

    if (attempt === CHECK_FONTS_LOADED_RETRIES) {
      return reject();
    }

    if (fontsLoaded) {
      return parentResolve ? parentResolve() : resolve();
    } else {
      setTimeout(() => {
        checkFontsLoaded(parentResolve ? parentResolve : resolve, attempt + 1);
      }, CHECK_FONTS_LOADED_DELAY);
    }
  });
