import React, { useEffect, useRef } from 'react';

import { Carousel } from '@/components/global/Carousel/Carousel';
import imgAvatar1 from '@/components/global/WorkshopReview/images/1.jpg';
import imgAvatar3 from '@/components/global/WorkshopReview/images/3.jpg';
import imgAvatar8 from '@/components/global/WorkshopReview/images/8.jpg';
import imgAvatar10 from '@/components/global/WorkshopReview/images/10.jpg';
import imgAvatar12 from '@/components/global/WorkshopReview/images/12.jpg';
import { Avatar } from '@/components/users/Avatar/Avatar';

import { initWorkshopTransitions } from '@/utils/gsap/workshopTransitions';
import { t } from '@/utils/i18n/i18n';

interface Props extends SizingState {
  titleClass: string;
}

// In order for this to work, all parent containers must not have the CSS overflow property set to hidden
const WorkshopReviewV2: React.FC<Props> = ({ sizing, titleClass }: Props) => {
  const gsapTransitions = useRef<any>([]);

  useEffect(() => {
    if (gsapTransitions.current?.length > 0) {
      //TODO: destroy any current transitions
    }

    const transitionElements = {
      reviewIntros: document.querySelectorAll<HTMLElement>(
        '.c-workshop-review-v2__intro'
      ),
      carouselSlides: document.querySelectorAll(
        '.c-workshop-review-v2 .carousel__item'
      ),
      carouselTrack: document.querySelector(
        '.c-workshop-review-v2 .carousel__track'
      ),
      triggerElement: document.querySelector('.c-workshop-review-v2'),
    };

    // Initialise after 1s due to images not being loaded in time
    // for correct element height to be calculated
    const initializeTransitionTimeout = setTimeout(() => {
      gsapTransitions.current = initWorkshopTransitions(
        sizing.isMobile,
        sizing.isTablet,
        transitionElements
      );
    }, 1000);

    return () => clearTimeout(initializeTransitionTimeout);
  }, []);

  return (
    <section className="c-workshop-review-v2 u-flex u-flex-column u-justify-between mv256 wrap">
      <div className="c-workshop-review-v2__content u-flex u-justify-center u-flex- u-sticky u-text-center mb32">
        <div className="c-workshop-review-v2__intro c-workshop-review-v2__intro--1 absolute absolute--t ph24">
          <h3 className={`${titleClass} c-workshop-sentences__h2 mb32 mb24@s`}>
            Interactive workshops
          </h3>
          <p className="f-text-1 f-inter-light-1">
            Get answers to your most pressing questions and solve real life
            challenges in live, interactive workshops.
          </p>
        </div>
        <div className="c-workshop-review-v2__intro c-workshop-review-v2__intro--2 absolute absolute--t ph24">
          <h3 className={`${titleClass} c-workshop-sentences__h2 mb32 mb24@s`}>
            World-class creators
          </h3>
          <p className="f-text-1 f-inter-light-1">
            Learn from industry-leading creators across film, fashion, art,
            photography, design and more.
          </p>
        </div>
        <div className="c-workshop-review-v2__intro c-workshop-review-v2__intro--3 absolute absolute--t ph24">
          <h3 className={`${titleClass} c-workshop-sentences__h2 mb32 mb24@s`}>
            A Global community
          </h3>
          <p className="f-text-1 f-inter-light-1">
            Get useful feedback from experts and peers that helps to develop
            your skills and build your confidence.
          </p>
        </div>
        <div className="c-workshop-review-v2__carousel wrap">
          <Carousel
            columns={sizing.isMobile ? 1 : sizing.isTablet ? 2 : 4}
            padding={32}
            wrap={true}
            buttonClassName="carousel__button--theme-light u-link-black--alt u-hide u-flex@l"
            counterDotsClassName="u-hide"
            shadowFix={true}
            disableSnap={false}
            showFade={true}
            isLoaded={true}
            hideArrows={true}
          >
            <div className="c-workshop-review-v2__card u-flex u-flex-column pv48 ph32">
              <p className="pb24 u-text-left f-inter-light-3 u-flex-grow">
                {t(
                  "It's greatly helpful to have young directors talking to and advising us. Best workshop ever."
                )}
              </p>
              <div className="u-flex u-align-center ">
                <Avatar
                  src={imgAvatar1}
                  user={null}
                  size="xl"
                  className="mr16 mv2"
                />
                <span className="u-bold">{t('Laila, South America')}</span>
              </div>
            </div>
            <div className="c-workshop-review-v2__card u-flex u-flex-column pv48 ph32">
              <p className="pb24 u-text-left f-inter-light-3 u-flex-grow">
                {t(
                  'The host made sure to answer every question and address every comment. It felt like we were hanging out together!'
                )}
              </p>
              <div className="u-flex u-align-center ">
                <Avatar
                  src={imgAvatar10}
                  user={null}
                  size="xl"
                  className="mr16 mv2"
                />
                <span className="u-bold">{t('James, UK')}</span>
              </div>
            </div>
            <div className="c-workshop-review-v2__card u-flex u-flex-column pv48 ph32">
              <p className="pb24 u-text-left f-inter-light-3 u-flex-grow">
                {t(
                  'Daisie is literally the best thing that happened to me all year.'
                )}
              </p>
              <div className="u-flex u-align-center ">
                <Avatar
                  src={imgAvatar3}
                  user={null}
                  size="xl"
                  className="mr16 mv2"
                />
                <span className="u-bold">{t('Maya, Sweden')}</span>
              </div>
            </div>
            <div className="c-workshop-review-v2__card u-flex u-flex-column pv48 ph32">
              <p className="pb24 u-text-left f-inter-light-3 u-flex-grow">
                {t(
                  'To be able to talk to such a renowned creator was amazing and I enjoyed it a lot.'
                )}
              </p>
              <div className="u-flex u-align-center ">
                <Avatar
                  src={imgAvatar12}
                  user={null}
                  size="xl"
                  className="mr16 mv2"
                />
                <span className="u-bold">{t('Minie, US')}</span>
              </div>
            </div>
            <div className="c-workshop-review-v2__card u-flex u-flex-column pv48 ph32">
              <p className="pb24 u-text-left f-inter-light-3 u-flex-grow">
                {t(
                  'This felt like a really positive space and it made me feel really motivated and hopeful about my creative journey.'
                )}
              </p>
              <div className="u-flex u-align-center ">
                <Avatar
                  src={imgAvatar8}
                  user={null}
                  size="xl"
                  className="mr16 mv2"
                />
                <span className="u-bold">{t('Jonathan, UK')}</span>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default WorkshopReviewV2;
