import * as React from 'react';

import widont from 'widont';

import { Head } from '@/components/global/Head/Head';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { SiteFooter } from '@/components/global/SiteFooter/SiteFooter';

import imgArtSchool from '@/pages/Teach/art-school.svg';
import imgTeachMobile from '@/pages/Teach/teach-mobile.jpg';
import imgTeach from '@/pages/Teach/teach.jpg';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface State {
  displayOverlay: boolean;
}

class Teach extends React.Component<{}, State> {
  public state = {
    displayOverlay: false,
  };

  public render = () => {
    const { displayOverlay } = this.state;

    return (
      <div className="become-a-mentor mtheader">
        <Head title="Become a Mentor" />

        <div className="u-w-100 relative">
          <div className="relative u-h-100vh">
            <ImageLoader
              src={imgTeach}
              className="u-1/1 u-h-100vh u-object-cover u-hide u-block@m c-teach__img"
              backgroundClassName="u-bg-black"
            />

            <ImageLoader
              src={imgTeachMobile}
              className="u-1/1 u-h-100vh u-object-cover u-hide@m"
              backgroundClassName="u-bg-black"
            />

            <div
              className={c(
                'absolute absolute--tl u-1/1 u-h-100 u-flex u-align-center u-justify-center',
                {
                  'u-opacity-0': !displayOverlay,
                  'u-opacity-1': displayOverlay,
                }
              )}
            >
              <div className="wrap u-text-center">
                <img
                  src={imgArtSchool}
                  alt={t('Art School')}
                  className="c-teach__logo mb32 mhauto u-white"
                />

                <h1 className="f-title-2 u-x-bold u-white mb32 mb48@m">
                  {widont(
                    t(
                      'Join 250+ teachers shaping the next-gen learning experience'
                    )
                  )}
                </h1>
                <div className="u-inline-block">
                  <a
                    className="dds-button dds-button--default dds-button--default--size-m dds-button--size-m dds-button--link dds-button--wide-m"
                    href="https://daisieapp.typeform.com/to/Fs9KHUUc"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <p className="u-flex u-align-center u-justify-center u-1/1 animate-opacity f-text-2 opacity-1">
                      <span>{t('Become a Mentor')}</span>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <SiteFooter />
      </div>
    );
  };
}

export { Teach };
