import {
  distanceFromMaxWrapWidth,
  getCookieBannerHeight,
  getDiscordBannerHeight,
  getDownloadAppBannerHeight,
  isChatMobile,
  isDesktop,
  isMobile,
  isTabletOrDesktop,
} from '@/utils/DOM/sizing';

const sizingState: SizingState = {
  sizing: {
    isMobile: isMobile(),
    isTabletOrDesktop: isTabletOrDesktop(),
    isDesktop: isDesktop(),
    isChatMobile: isChatMobile(),
    isTablet: !isMobile() && !isDesktop(),
    cookieBannerHeight: getCookieBannerHeight(),
    downloadAppBannerHeight: getDownloadAppBannerHeight(),
    discordBannerHeight: getDiscordBannerHeight(),
    distanceFromMaxWrapWidth: distanceFromMaxWrapWidth(),
  },
};

const sizingActions = (store: any) => ({
  updateSizing: () => {
    store.setState({
      sizing: {
        isMobile: isMobile(),
        isTabletOrDesktop: isTabletOrDesktop(),
        isDesktop: isDesktop(),
        isChatMobile: isChatMobile(),
        isTablet: !isMobile() && !isDesktop(),
        cookieBannerHeight: getCookieBannerHeight(),
        downloadAppBannerHeight: getDownloadAppBannerHeight(),
        discordBannerHeight: getDiscordBannerHeight(),
        distanceFromMaxWrapWidth: distanceFromMaxWrapWidth(),
      },
    });
  },
});

export { sizingState, sizingActions };
