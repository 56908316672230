import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Icon } from '@/components/global/Icon/Icon';
import { Link } from '@/components/global/Link/Link';
import { LoadingSymbol } from '@/components/global/LoadingSymbol/LoadingSymbol';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { SHARE_IMAGE_MOBILE } from '@/messages/copy';
import {
  PROFILE_PREVIEW_GENERATION_FAIL,
  PROJECT_PREVIEW_GENERATION_FAIL,
} from '@/messages/errors';

import { parser } from '@/routes/parser';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

import { Http } from '@/utils/api/Http';
import { URL_IMAGE_GENERATOR } from '@/utils/constants';
import { isMobile } from '@/utils/DOM/sizing';
import {
  isShareablesEnabled,
  isShareOnDaisieEnabled,
} from '@/utils/featureToggles';
import { copyLink } from '@/utils/forms/copyToClipboard';
import { t } from '@/utils/i18n/i18n';
import { track } from '@/utils/mixpanel/mixpanel';
import { c } from '@/utils/strings/c';

interface Props extends RouteComponentProps {
  className?: string;
  room?: Room;
  user?: User;
  isLoaded?: () => void;
  withPadding?: boolean;
}

interface State {
  copied: boolean;
  loading: boolean;
  imagesLoaded: boolean;
  noBlockPreview?: string;
  oneBlockPreview?: string;
  fourBlockPreview?: string;
  selectedPreview?: string;
}

class ShareOptionsComponent extends React.Component<Props, State> {
  private entityLink: React.RefObject<HTMLInputElement>;
  private entityURL: string;
  private buttonPressTimer: any;

  constructor(props: Props) {
    super(props);

    this.entityLink = React.createRef();

    const { room, user } = this.props;

    this.entityURL = room
      ? `https://www.daisie.com${parser({
          name: 'room',
          params: { roomSlug: room.slug },
        })}`
      : user
      ? `https://www.daisie.com${parser({
          name: 'user',
          params: {
            username: user.username,
          },
        })}`
      : '';

    this.state = {
      copied: false,
      loading: false,
      imagesLoaded: false,
      noBlockPreview: undefined,
      oneBlockPreview: undefined,
      fourBlockPreview: undefined,
      selectedPreview: undefined,
    };
  }

  public componentDidMount = async () => {
    const { user, room } = this.props;

    if (room) {
      track('open_share_project');
    } else if (user) {
      track('open_share_profile');
    }

    if (isShareablesEnabled()) {
      await this.fetchPreviews();
    }
  };

  private fetchPreviews = async () => {
    const { room, user } = this.props;

    if (!room && !user) return;

    this.setState({ loading: true });

    // Helpers for testing loading and error states in UI tests
    const params = new URLSearchParams(this.props.location.search);
    const uiTestParam = decodeURIComponent(
      params.get('uiTestShareModal') || ''
    );

    if (isUiTest() && uiTestParam === 'loading') {
      return;
    } else if (isUiTest() && uiTestParam === 'error') {
      this.setState({ loading: false });
      return;
    }

    try {
      const apiRoute = room
        ? `${URL_IMAGE_GENERATOR}/projects/${room.id}?allowed_block_types=video,image,audio&type=square`
        : user
        ? `${URL_IMAGE_GENERATOR}/portfolios/${user.username}?allowed_block_types=video,image,audio&type=square`
        : undefined;

      if (!apiRoute) {
        throw new Error('Missing user or project');
      }

      const { path: noBlockPreviewPath } = await new Http(
        `${apiRoute}&max_blocks=0`
      ).get<any>();
      const { path: oneBlockPreviewPath } = await new Http(
        `${apiRoute}&max_blocks=1`
      ).get<any>();
      const { path: fourBlockPreviewPath } = await new Http(
        `${apiRoute}&max_blocks=4`
      ).get<any>();

      const oneBlockPreview =
        this.getPreviewUrlHash(noBlockPreviewPath) !==
        this.getPreviewUrlHash(oneBlockPreviewPath)
          ? oneBlockPreviewPath
          : undefined;

      const fourBlockPreview =
        this.getPreviewUrlHash(oneBlockPreviewPath) !==
        this.getPreviewUrlHash(fourBlockPreviewPath)
          ? fourBlockPreviewPath
          : undefined;

      this.setState({
        loading: false,
        noBlockPreview: noBlockPreviewPath,
        oneBlockPreview,
        fourBlockPreview,
        selectedPreview:
          fourBlockPreview || oneBlockPreview || noBlockPreviewPath,
      });

      if (this.props.isLoaded) {
        this.props.isLoaded();
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  private getPreviewUrlHash = (url: string) => {
    if (!url) {
      return '';
    }

    const hashAndExtension = url.split('_').pop();

    return hashAndExtension ? hashAndExtension.replace('.jpg', '') : '';
  };

  private renderPreviews = () => {
    const { room, user } = this.props;

    if (!room && !user) return null;

    const entityType = room ? 'project' : user ? 'user' : undefined;

    const {
      noBlockPreview,
      oneBlockPreview,
      fourBlockPreview,
      selectedPreview,
      imagesLoaded,
    } = this.state;

    const onImageTouchStart = (
      blocksInPreview: number,
      optionsAvailable: number
    ) => {
      this.buttonPressTimer = setTimeout(() => {
        const trackString =
          entityType === 'project'
            ? 'share_project_preview_shareable_downloaded'
            : entityType === 'user'
            ? 'share_profile_preview_shareable_downloaded'
            : '';

        track(trackString, {
          blocksInPreview,
          optionsAvailable,
        });
      }, 1500);
    };

    const onImageTouchEnd = () => {
      clearTimeout(this.buttonPressTimer);
    };

    const onlyOneOptionAvailable =
      noBlockPreview && !oneBlockPreview && !fourBlockPreview;

    const CTAs = (
      <>
        <div className="mobile-and-tablet-only mt16 ph32 u-text-center">
          <span className="u-dark-grey f-text-2">{t(SHARE_IMAGE_MOBILE)}</span>
        </div>
        <div className="desktop-only mt16">
          <div className="u-flex u-align-center">
            <Button
              type={ButtonType.link}
              linkTo={onlyOneOptionAvailable ? noBlockPreview : selectedPreview}
              download={true}
              size={ButtonSize.m}
              text={
                onlyOneOptionAvailable
                  ? t('Download image')
                  : selectedPreview
                  ? t('Download selected image')
                  : t('Select an image to download')
              }
              onClick={() => track('share_shareable_downloaded')}
              className={c('mlauto mrauto', {
                mb24: this.props.withPadding,
              })}
            />

            {/* <a
              download={true}
              className={c('button button--black button--xwide', {
                disabled: !selectedPreview && !onlyOneOptionAvailable,
              })}
            >
              {}
            </a> */}
          </div>
        </div>
      </>
    );

    if (noBlockPreview && !oneBlockPreview && !fourBlockPreview) {
      return (
        <>
          <div className="share-preview-grid--1">
            <div
              className={c('', {
                'u-h-0 opacity-0': !imagesLoaded,
              })}
            >
              <img
                src={noBlockPreview}
                onLoad={() => this.setState({ imagesLoaded: true })}
                onTouchStart={() => onImageTouchStart(0, 1)}
                onTouchEnd={onImageTouchEnd}
                alt={t('Shareable image 1')}
              />
            </div>
          </div>
          {CTAs}
        </>
      );
    }

    if (noBlockPreview && oneBlockPreview && !fourBlockPreview) {
      return (
        <>
          <div className="share-preview-grid--2">
            <div
              className={c('pr8', {
                'u-h-0 opacity-0': !imagesLoaded,
              })}
            >
              <img
                src={noBlockPreview}
                className={c('', {
                  selected: selectedPreview === noBlockPreview,
                })}
                onClick={() =>
                  this.setState({ selectedPreview: noBlockPreview })
                }
                onTouchStart={() => onImageTouchStart(0, 2)}
                onTouchEnd={onImageTouchEnd}
                alt={t('Shareable image 2')}
              />
            </div>
            <div
              className={c('pl8', {
                'u-h-0 opacity-0': !imagesLoaded,
              })}
            >
              <img
                src={oneBlockPreview}
                onLoad={() => this.setState({ imagesLoaded: true })}
                className={c('', {
                  selected: selectedPreview === oneBlockPreview,
                })}
                onClick={() =>
                  this.setState({ selectedPreview: oneBlockPreview })
                }
                onTouchStart={() => onImageTouchStart(1, 2)}
                onTouchEnd={onImageTouchEnd}
                alt={t('Shareable image 3')}
              />
            </div>
          </div>
          {CTAs}
        </>
      );
    }

    if (noBlockPreview && oneBlockPreview && fourBlockPreview) {
      return (
        <>
          <div className="share-preview-grid--3">
            <div
              className={c('share-preview-grid--3__third-image p8', {
                'u-h-0 opacity-0': !imagesLoaded,
              })}
            >
              <img
                src={noBlockPreview}
                className={c('', {
                  selected: selectedPreview === noBlockPreview,
                })}
                onClick={() =>
                  this.setState({ selectedPreview: noBlockPreview })
                }
                onTouchStart={() => onImageTouchStart(0, 3)}
                onTouchEnd={onImageTouchEnd}
                alt={t('Shareable image 2')}
              />
            </div>
            <div
              className={c('share-preview-grid--3__second-image p8', {
                'u-h-0 opacity-0': !imagesLoaded,
              })}
            >
              <img
                onLoad={() => this.setState({ imagesLoaded: true })}
                src={oneBlockPreview}
                className={c('', {
                  selected: selectedPreview === oneBlockPreview,
                })}
                onClick={() =>
                  this.setState({ selectedPreview: oneBlockPreview })
                }
                onTouchStart={() => onImageTouchStart(1, 3)}
                onTouchEnd={onImageTouchEnd}
                alt={t('Shareable image 3')}
              />
            </div>
            <div
              className={c('share-preview-grid--3__main-image p8', {
                'u-h-0 opacity-0': !imagesLoaded,
              })}
            >
              <img
                src={fourBlockPreview}
                className={c('', {
                  selected: selectedPreview === fourBlockPreview,
                })}
                onClick={() =>
                  this.setState({ selectedPreview: fourBlockPreview })
                }
                onTouchStart={() => onImageTouchStart(2, 3)}
                onTouchEnd={onImageTouchEnd}
                alt={t('Shareable image 4')}
              />
            </div>
          </div>
          {CTAs}
        </>
      );
    }

    return (
      <div className="pv64 ph32 u-flex u-align-center u-justify-center">
        <div className="u-flex u-flex-column">
          <span className="f-text-3">
            {room
              ? t(PROJECT_PREVIEW_GENERATION_FAIL)
              : user
              ? t(PROFILE_PREVIEW_GENERATION_FAIL)
              : null}
          </span>
        </div>
      </div>
    );
  };

  private renderLoadingPreviews = () => {
    const { room, user } = this.props;

    const entity = room ? t('room') : user ? t('profile') : '';

    return (
      <div className="pv64 ph32 u-flex u-align-center u-justify-center">
        <div className="u-flex u-flex-column">
          <div className="u-flex u-align-center u-justify-center mb16">
            <LoadingSymbol size="l" />
          </div>
          <span className="f-text-3">
            {t('Generating')} {entity} {t('previews...')}
          </span>
        </div>
      </div>
    );
  };

  private renderSocialMediaLinks = () => {
    const { room, user, withPadding } = this.props;
    const postContent = `${encodeURIComponent(this.entityURL)}`;

    const title = room?.title || user?.name;

    const twitterText = title
      ? encodeURIComponent(`${title} on @daisie`)
      : undefined;

    return (
      <div className="social-buttons-outer">
        <div className="u-scrollbar-disabled social-buttons-inner">
          <div className="social-buttons-grid">
            {isShareOnDaisieEnabled() && (
              <Link
                to={`${parser({ name: 'newConversation' })}?m=${postContent}`}
                className="social-buttons-item"
                onClick={() =>
                  track('share_social_link_clicked', { type: 'daisie_chat' })
                }
              >
                <div
                  className={c(
                    'button--circle button--circle--xlarge button--brand mr16',
                    {
                      ml24: withPadding,
                    }
                  )}
                >
                  <Icon id="send" size="m" className="u-white" />
                </div>
              </Link>
            )}

            <a
              target="_blank"
              className="social-buttons-item"
              rel="noopener noreferrer"
              href={`https://www.twitter.com/share?${
                twitterText ? `text=${twitterText}&` : ''
              }url=${postContent}`}
              onClick={() =>
                track('share_social_link_clicked', { type: 'twitter' })
              }
            >
              <div
                className={c(
                  'button--circle button--circle--xlarge button--twitter mr16',
                  {
                    ml24: withPadding && !isShareOnDaisieEnabled(),
                  }
                )}
              >
                <Icon id="twitter" size="l" className="u-white" />
              </div>
            </a>

            <a
              target="_blank"
              className="social-buttons-item"
              rel="noopener noreferrer"
              href={`https://wa.me/?text=${postContent}`}
              onClick={() =>
                track('share_social_link_clicked', { type: 'whatsapp' })
              }
            >
              <div className="button--circle button--circle--xlarge button--whatsapp mr16">
                <Icon id="whatsapp" size="l" className="u-white" />
              </div>
            </a>

            <a
              target="_blank"
              className="social-buttons-item"
              rel="noopener noreferrer"
              href={`mailto:?subject=Shared%20from%20Daisie&body=${postContent}`}
              onClick={() =>
                track('share_social_link_clicked', { type: 'mail' })
              }
            >
              <div className="button--circle button--circle--xlarge button--grey mr16">
                <Icon id="mail" size="l" className="u-white" />
              </div>
            </a>

            <a
              target="_blank"
              className="social-buttons-item"
              rel="noopener noreferrer"
              href={`https://www.facebook.com/sharer.php?u=${postContent}`}
              onClick={() =>
                track('share_social_link_clicked', { type: 'facebook' })
              }
            >
              <div className="button--circle button--circle--xlarge button--facebook mr16">
                <Icon id="facebook" size="l" className="u-white" />
              </div>
            </a>

            <a
              target="_blank"
              className="social-buttons-item"
              rel="noopener noreferrer"
              href={`https://www.reddit.com/submit?url=${postContent}`}
              onClick={() =>
                track('share_social_link_clicked', { type: 'reddit' })
              }
            >
              <div className="button--circle button--circle--xlarge button--reddit mr16">
                <Icon id="reddit" size="l" className="u-white" />
              </div>
            </a>

            <a
              target="_blank"
              className="social-buttons-item"
              rel="noopener noreferrer"
              href={
                isMobile()
                  ? `fb-messenger://share/?link=${postContent}`
                  : `https://www.facebook.com/dialog/send?app_id=658687918236317&link=${postContent}&display=popup&redirect_uri=${encodeURIComponent(
                      `https://www.daisie.com${window.location.pathname}`
                    )}`
              }
              onClick={() =>
                track('share_social_link_clicked', {
                  type: 'facebook-messenger',
                })
              }
            >
              <div className="button--circle button--circle--xlarge button--fb-messenger mr16">
                <Icon
                  id="facebook-messenger"
                  size="l"
                  className="u-fb-messenger"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  };

  private renderCopyLink = () => {
    const { copied } = this.state;

    return (
      <div
        className={c('dds-input p16 relative', {
          pr80: !copied,
          pr128: copied,
        })}
      >
        <input
          type="text"
          className="p0 u-bg-none"
          id="project-link"
          ref={this.entityLink}
          value={this.entityURL}
        />
        {copied ? (
          <div className="absolute--i absolute--top-center r8">
            <Button
              type={ButtonType.action}
              onClick={() =>
                copyLink(this.entityLink, () => {
                  track('copy_link', { url: this.entityLink.current?.value });
                  this.setState({ copied: true });
                })
              }
              text={t('Copied')}
              iconId="tick"
              size={ButtonSize.s}
            />
          </div>
        ) : (
          <div className="absolute--i absolute--top-center r8">
            <Button
              type={ButtonType.action}
              onClick={() =>
                copyLink(this.entityLink, () => {
                  track('copy_link', {
                    url: this.entityLink.current?.value,
                  });
                  this.setState({ copied: true });
                })
              }
              text={t('Copy')}
              size={ButtonSize.s}
            />
          </div>
        )}
      </div>
    );
  };

  public render = () => {
    const { className, withPadding } = this.props;
    const { loading } = this.state;

    return (
      <div className={className}>
        <div className={c('', { mv24: withPadding, mb24: !withPadding })}>
          {this.renderSocialMediaLinks()}
        </div>
        <div
          className={c('', {
            ph24: withPadding,
            mb24: isShareablesEnabled(),
          })}
        >
          {this.renderCopyLink()}
        </div>

        {isShareablesEnabled() && (
          <div
            className={c('c-share-project__project-preview-container', {
              ph24: withPadding,
            })}
          >
            {loading && this.renderLoadingPreviews()}
            <div
              className={c('animate-opacity--slow', {
                'opacity-1': !loading,
                'opacity-0': loading,
              })}
              data-ui-test-id={
                isUiTest() && !loading ? 'image-generator-loaded' : undefined
              }
            >
              {this.renderPreviews()}
            </div>
          </div>
        )}
      </div>
    );
  };
}

export const ShareOptions = withRouter(ShareOptionsComponent);
