import * as React from 'react';

import { connect } from 'unistore/react';

import { CardSkeleton } from '@/components/global/Cards/CardSkeleton';
import { ClassCard } from '@/components/global/Cards/ClassCard';
import { Head } from '@/components/global/Head/Head';
import { SkeletonNew } from '@/components/global/Skeleton/SkeletonNew';
import { Toast } from '@/components/global/Toaster/Toast';
import { ClassPageVideoPlayer } from '@/components/ondemand/ClassPageVideoPlayer';

import { DaisieSubscriptionTier } from '@/enums/DaisieSubscriptionTier';
import { WorkshopEventType } from '@/enums/WorkshopEventType';

import { CLASSES_FETCH_FAIL } from '@/messages/errors';

import { CalendarCategorySelector } from '@/pages/Calendar/CalendarCategorySelector';

import { routes } from '@/routes';
import { parser } from '@/routes/parser';

import { getWorkshopsFromData } from '@/store/helpers';
import { categoryActions } from '@/store/modules/category';

import { Http } from '@/utils/api/Http';
import { t } from '@/utils/i18n/i18n';
interface Props
  extends CategoryActions,
    CategoryState,
    SizingState,
    AuthState {}

interface State {
  categorisedWorkshops: CategorisedWorkshops[];
  isLoading: boolean;
  // bottomPaddingHeight: used to add extra padding to the page
  // so the IntersectionObserver fires when scrolling to the final category
  bottomPaddingHeight: number;
  selectedCategorySlug?: string;
  currentInlineVideoWorkshopId?: string;
}

interface CategorisedWorkshops {
  category: Category;
  workshops: Workshop[];
}

class ClassesComp extends React.Component<Props, State> {
  // CONFIG
  private classNameCategoryContainer: string = 'mb64';
  private classNameCategoryTitleContainer: string = 'mb24';
  private classNameCategoryTitleText: string = 'f-title-2 u-bold u-white';

  // REFS
  private intersectionObserver?: any;

  public state: State = {
    categorisedWorkshops: [],
    isLoading: true,
    bottomPaddingHeight: 0,
    selectedCategorySlug: undefined,
    currentInlineVideoWorkshopId: undefined,
  };

  public componentDidMount = async () => {
    try {
      await this.props.fetchAndSortCategories();
    } catch (e) {
      // TODO
    }

    await this.fetchClasses();
  };

  private fetchClasses = async () => {
    const {
      organisedCategories: { industries },
      auth: { isAuthorised },
    } = this.props;

    try {
      const { data } = await new Http(
        `/workshops/${
          isAuthorised ? 'catchUps' : 'catchUpsFree'
        }?pageSize=500&pageNumber=1`
      ).get<APIArray<APIWorkshop>>();

      const workshops = getWorkshopsFromData(data);

      // Display Originals at the top
      const originalsCategory = industries.find((i) => i.name === 'Originals');
      if (originalsCategory) {
        // If Originals category exists, take it from the list
        // and place it at the beginning of the array
        industries.splice(industries.indexOf(originalsCategory), 1);
        industries.unshift(originalsCategory);
      }

      const categorisedWorkshops: CategorisedWorkshops[] = industries.map(
        (c: Category) => ({
          category: c,
          workshops: workshops.filter((w: Workshop) =>
            w.categories.map((wc: Category) => wc.slug).includes(c.slug)
          ),
        })
      );

      this.setState(
        {
          categorisedWorkshops,
          isLoading: false,
        },
        () => this.initialiseIntersectionObservers()
      );
    } catch (e) {
      new Toast({
        body: CLASSES_FETCH_FAIL,
        failure: true,
      }).dispatch();
    }
  };

  private initialiseIntersectionObservers = () => {
    const categoryRows = document.querySelectorAll('.c-calendar__day_row');

    if (!categoryRows) {
      return;
    }

    const callback = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          const dataCategorySlugAttr =
            entry.target.getAttribute('data-category');

          this.setState({ selectedCategorySlug: dataCategorySlugAttr });
        }
      });
    };

    // TODO: @ihutc - use two IntersectionObservers, one for long content
    // and another for short content
    this.intersectionObserver = new IntersectionObserver(callback, {
      threshold: 0.05,
      rootMargin: '0px 0px -60% 0px',
    });

    categoryRows.forEach((f: any) => this.intersectionObserver.observe(f));

    const lastCategoryRow = categoryRows[categoryRows.length - 1];

    if (lastCategoryRow) {
      const { height: lastDayRowHeight } =
        lastCategoryRow.getBoundingClientRect();

      const {
        sizing: { isMobile },
      } = this.props;

      // TODO: sort these magic numbers out
      const padding: number = isMobile ? 140 : 156;

      const bottomPaddingHeight =
        window.innerHeight - lastDayRowHeight - padding;

      this.setState({
        bottomPaddingHeight: bottomPaddingHeight < 0 ? 0 : bottomPaddingHeight,
      });
    }
  };

  private renderLoading = () =>
    Array.from(Array(2).keys()).map((_, key: number) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={key} className={this.classNameCategoryContainer}>
        <SkeletonNew
          elementClassName={this.classNameCategoryTitleText}
          containerClassName={this.classNameCategoryTitleContainer}
          width="15%"
          fillClassName="u-bg-almost-black"
        />

        <div className="u-grid u-grid--2">
          <CardSkeleton />
          <CardSkeleton />
        </div>
      </div>
    ));

  private showInlineVideo = (workshop: Workshop) => {
    const { id, slug } = workshop;

    this.setState(
      {
        currentInlineVideoWorkshopId: id,
      },
      () => {
        window.history.replaceState(
          null,
          '',
          `${parser({
            name: 'workshop',
            params: { workshopSlug: slug },
          })}`
        );
      }
    );
  };

  private hideInlineVideo = () => {
    this.setState({ currentInlineVideoWorkshopId: undefined }, () => {
      window.history.replaceState(null, '', parser({ name: 'classes' }));
    });
  };

  private renderClasses = () => {
    const {
      auth: { isAuthorised, user },
    } = this.props;

    const { categorisedWorkshops } = this.state;

    return categorisedWorkshops.map((cw: CategorisedWorkshops) => {
      if (cw.workshops.length === 0) {
        return null;
      }

      return (
        <div
          key={cw.category.id}
          className="c-calendar__day_row u-1/1 mb64"
          data-category={cw.category.slug}
        >
          <h1 className="mb24 f-title-2 u-bold u-white">{cw.category.name}</h1>

          <div className="c-calendar__day_row__grid">
            {cw.workshops.map((w: Workshop) => {
              const isOriginal = w.eventType === WorkshopEventType.original;
              const shouldRedirectToOriginals =
                !isAuthorised ||
                (isAuthorised &&
                  user?.subscriptionTier !== DaisieSubscriptionTier.premium);
              const shouldRedirectToOverview =
                isAuthorised &&
                user?.subscriptionTier === DaisieSubscriptionTier.premium;

              return (
                <ClassCard
                  key={w.id}
                  workshop={w}
                  isOriginal={isOriginal}
                  linkOverride={
                    isOriginal && shouldRedirectToOriginals
                      ? routes.alexJenkinsClass
                      : isOriginal && shouldRedirectToOverview
                      ? parser({
                          name: 'workshop',
                          params: { workshopSlug: `overview/${w.slug}` },
                        })
                      : undefined
                  }
                />
              );
            })}
          </div>
        </div>
      );
    });
  };

  public render = () => {
    const {
      organisedCategories: { industries },
    } = this.props;

    const {
      isLoading,
      categorisedWorkshops,
      bottomPaddingHeight,
      selectedCategorySlug,
      currentInlineVideoWorkshopId,
    } = this.state;

    return (
      <div className="wrap u-flex mt16 mt32@m u-white">
        <Head title={t('Classes')} pathname={t('/classes')} />

        <ClassPageVideoPlayer
          workshopId={currentInlineVideoWorkshopId}
          onClose={() => this.hideInlineVideo()}
          onClickPrevious={() => {}}
          onClickNext={() => {}}
        />

        <div className="c-classes__sidebar__container u-1/4@m mr20 mr12@m">
          <div className="c-classes__sidebar__body pb32">
            {isLoading ? (
              <SkeletonNew
                elementClassName="f-title-2 u-bold"
                containerClassName="mb32 ml12 u-hide@s"
                fitToText={t('Categories')}
                fillClassName="u-bg-almost-black"
              />
            ) : (
              <p className="f-title-2 u-bold mb32 ml12 u-hide@s">
                {t('Categories')}
              </p>
            )}

            <CalendarCategorySelector
              showAllButton={false}
              isLoading={isLoading}
              selectedCategory={
                !!selectedCategorySlug
                  ? industries.filter(
                      (c: Category) => c.slug === selectedCategorySlug
                    )[0]
                  : undefined
              }
              onCategorySelect={(category?: Category) => {
                if (!category) {
                  return;
                }

                this.intersectionObserver.disconnect();

                this.setState({ selectedCategorySlug: category.slug }, () => {
                  const categoryRow = document.querySelector(
                    `[data-category=${category.slug}]`
                  );

                  if (categoryRow) {
                    const { y: top } = categoryRow.getBoundingClientRect();

                    const {
                      sizing: { isMobile },
                    } = this.props;

                    const headerHeight: number = 72;
                    const padding: number = isMobile ? 8 : 20;

                    window.scrollTo({
                      top: window.scrollY + top - headerHeight - padding,
                      behavior: 'smooth',
                    });
                  }

                  setTimeout(() => {
                    this.initialiseIntersectionObservers();
                  }, 1500);
                });
              }}
              categoriesToDisplay={categorisedWorkshops
                .filter((cw: CategorisedWorkshops) => cw.workshops.length > 0)
                .map((cw: CategorisedWorkshops) => cw.category)}
            />
          </div>
        </div>

        <div className="u-1/1 u-3/4@m ml24@m">
          {isLoading ? (
            this.renderLoading()
          ) : (
            <>
              {this.renderClasses()}
              <div
                className="c-calendar__padding u-1/1"
                style={{ height: bottomPaddingHeight }}
              />
            </>
          )}
        </div>
      </div>
    );
  };
}

export const Classes = connect(
  ['organisedCategories', 'sizing', 'auth'],
  () => ({
    ...categoryActions(),
  })
)(ClassesComp);
