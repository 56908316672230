import { HIGHEST_ASCII, LOWEST_ASCII, SAMPLE_AVATARS } from '@/utils/constants';

const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a;
const invlerp = (a: number, b: number, v: number) => clamp((v - a) / (b - a));
const clamp = (v: number, min = 0, max = 1) => Math.min(max, Math.max(min, v));

const letterToNumber = (letter = 'a', total = SAMPLE_AVATARS) => {
  if (!letter) letter = 'a';
  const key = letter[0].toUpperCase().charCodeAt(0);
  return Math.floor(lerp(1, total, invlerp(LOWEST_ASCII, HIGHEST_ASCII, key)));
};

export { lerp, invlerp, letterToNumber };
