import * as React from 'react';

import { t } from '@/utils/i18n/i18n';

interface Props {
  resources?: string;
  tools?: string;
}

export const OriginalsLessonGuideModal = ({ resources, tools }: Props) => (
  <div className="u-overflow-scroll u-scrollbar-disabled">
    <div className="u-white">
      <h2 className="f-lynstone-bold-3 mb12">Resources</h2>
      <p className="f-lynstone-regular-4 mb32">{t(`${resources}`)}</p>
      <h2 className="f-lynstone-bold-3 mb12">Tools</h2>
      <p className="f-lynstone-regular-4 mb12">{t(`${tools}`)}</p>
    </div>
  </div>
);
