import * as React from 'react';

import { flatten } from '@/utils/strings/flatten';

interface Children {
  errors: any;
  submit: any;
  submitValidation?: any;
  changeValidation?: any;
  updateForm?: (key: any) => (event: any) => void;
  updateFormArray?: any;
  updateFormArrayByIndex?: any;
  state: any;
}

interface Props {
  children({ errors, submit, submitValidation }: Children): React.ReactNode;
  submit?(state: any): any;
  submitValidation?(state: any): object;
  changeValidation?(state: any): object;
  initialState: object;
  resetFormOnSubmit?: boolean;
}

interface State {
  errors: any;
  submitted: boolean;
  form: any;
}

/**
 * This component wraps around a form, providing consistent validation and submission.
 *
 * Props:
 * @param submit            function          The method you'd like called if validation succeeds.
 * @param submitValidation  function|promise  An optional method to run validation against the form
 * @param changeValidation  function|promise  An optional method to run validation onChange against the form
 *
 * Render props:
 * @param errors            object    An object of errors, passed from your validation method
 * @param submit            function  The method to call from the forms onSubmit attribute
 * @param submitValidation  function
 * @param changeValidation  function
 * @param state             object    The form state
 * @param updateForm        function  The set state helper
 * @param updateFormArray   function  The set state helper for arrays of objects
 * @param resetFormOnSubmit boolean   Determines whether to reset the form to its initial state on successful form submission
 */
class FormValidator extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      errors: {},
      submitted: false,
      form: this.props.initialState,
    };
  }

  public externalErrors = (errors: any) => {
    this.setState({ errors });
  };

  public resetForm = () => {
    this.setState({ form: this.props.initialState });
  };

  public submit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    const { submit } = this.props;

    await this.submitValidation();

    /**
     * Retrieve the errors (post validation) and flatten to see if any exist
     */
    const { errors } = this.state;
    if (!Object.keys(flatten(errors)).length && submit) {
      try {
        await submit(this.state.form);
        if (this.props.resetFormOnSubmit) {
          this.resetForm();
        }
      } catch (e) {
        // do nothing
      }
    }
  };

  /**
   * Run all provided validators onChange and onSubmit
   *
   * @param validators  array
   */
  private runValidation = async (validators: any[]) => {
    if (!validators.length) {
      return;
    }

    return new Promise((resolve) => {
      setTimeout(resolve, 10);
    }).then(async () => {
      const results = await Promise.all(
        validators.map(async (x) => x && (await x(this.state.form)))
      );

      const errors = Object.assign({}, ...results);
      this.setState({ errors });
    });
  };

  private submitValidation = (): Promise<void> => {
    this.setState({ submitted: true });
    return this.runValidation([
      this.props.submitValidation,
      this.props.changeValidation,
    ]);
  };

  /**
   * onChange, run changeValidation till submitted, then run changeValidation
   * and submitValidation
   */
  private changeValidation = (): Promise<void> => {
    if (this.state.submitted) {
      return this.runValidation([
        this.props.changeValidation,
        this.props.submitValidation,
      ]);
    } else {
      return this.runValidation([this.props.changeValidation]);
    }
  };

  private updateForm = (key: string | string[], modifier?: any) => {
    if (Array.isArray(key)) {
      return ({ value }: { value: string[] }) => {
        const form = { ...this.state.form };
        key.forEach((subKey: string, index: number) => {
          if (modifier) {
            form[subKey] = modifier(value[index]);
          } else {
            form[subKey] = value[index];
          }
        });

        this.setState({ form });
      };
    }

    return ({ value }: { value: string }) => {
      const form = { ...this.state.form };

      if (modifier) {
        form[key] = modifier(value);
      } else {
        form[key] = value;
      }

      this.setState({ form });
    };
  };

  /**
   * Updating an array of objects is more complex
   *
   * @param arrayKey  string  The key for the array property
   * @param typeKey   string  The name of the property to check against
   * @param valueKey  string  The name of the value to set
   * @param type      string  The type item to find
   * @param value     string  The actual value to set
   *
   * @example
   * {
   *   colours: [
   *     {
   *       id: 4,
   *       hex: '#f00'
   *     },
   *     {
   *       id: 5,
   *       hex: '#fff'
   *     }
   *   ]
   * }
   *
   * this.updateFormArray('colours', 'id', 'hex', '4', '#000')
   *
   */
  private updateFormArray = (
    arrayKey: string,
    typeKey: string = 'key',
    valueKey: string = 'value',
    type: string,
    value: string
  ) => {
    const form = { ...this.state.form };

    const index = form[arrayKey].findIndex((x: any) => x[typeKey] === type);
    if (index !== -1) {
      form[arrayKey][index][valueKey] = value;
    } else {
      form[arrayKey].push({ [typeKey]: type, [valueKey]: value });
    }

    this.setState({ form });
  };

  private updateFormArrayByIndex = (
    arrayKey: string,
    index: number = 0,
    valueKey: string = 'value',
    value: string
  ) => {
    const form = { ...this.state.form };

    form[arrayKey][index][valueKey] = value;

    this.setState({ form });
  };

  public render() {
    const { children } = this.props;
    const { errors } = this.state;
    return children({
      errors,
      submit: this.submit,
      submitValidation: this.submitValidation,
      changeValidation: this.changeValidation,
      updateForm: this.updateForm,
      updateFormArray: this.updateFormArray,
      updateFormArrayByIndex: this.updateFormArrayByIndex,
      state: this.state.form,
    });
  }
}

export { FormValidator };
