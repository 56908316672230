import * as React from 'react';

import { LabelPill } from '@/components/design_system/LabelPill/LabelPill';

import { c } from '@/utils/strings/c';

interface Props extends SizingState, AuthState {
  workshop: Workshop;
  fontClass: string;
}

const WorkshopMetadataV2 = ({ workshop, fontClass }: Props) => (
  <div className="c-workshop-metadata-v2 f-lynstone-regular u-flex u-flex-column u-align-center relative u-z-index-1 mh24">
    <LabelPill
      className="u-flex u-justify-center mb32"
      iconId={
        workshop.hasElapsed
          ? `category-${workshop.categories[0].slug}`
          : 'calendar'
      }
      text={
        workshop.hasElapsed
          ? workshop.categories[0].name
          : workshop.whenLabel.short.substring(
              0,
              workshop.whenLabel.short.lastIndexOf(' ')
            )
      }
      style="light-translucent"
      isNewWorkshopMobile={true}
    />
    <h1
      className={c(
        `${fontClass} u-text-center u-x-bold mb32 u-3/4@m u-flex u-justify-center`,
        {
          'u-line-height--workshop-title-extended':
            fontClass === 'f-monument-extended-ultrabold-1' ||
            'f-obviously-extended-semibold-1a',
        }
      )}
    >
      {workshop.title}
    </h1>
    <p className="f-text-1 f-inter-light-1 u-3/5@m mb48 u-text-center u-very-light-grey@m u-light-grey@s">
      {workshop.description}
    </p>
  </div>
);

export default WorkshopMetadataV2;
