import * as React from 'react';

import AnimatedWordmarkBlack from '@/components/global/Wordmark/wordmark-animation-black.png';
import AnimatedWordmarkWhite from '@/components/global/Wordmark/wordmark-animation-white.png';

import { DaisieWordmarkColour } from '@/enums/DaisieWordmarkColour';

import { c } from '@/utils/strings/c';

interface Props {
  className?: string;
  colour?: DaisieWordmarkColour;
}

interface State {
  frame: number;
  animate: boolean;
}

class AnimatedWordmark extends React.Component<Props, State> {
  private timer: NodeJS.Timeout | undefined;
  private width: number = 100;
  private height: number = 31.15;
  private maxFrames: number = 21;

  public state: State = {
    frame: this.maxFrames - 1,
    animate: false,
  };

  public componentWillUnmount = () => {
    this.stopAnimation();
  };

  private startAnimation = () => {
    if (this.state.animate) return;

    this.setState({ animate: true, frame: 0 });

    this.timer = setInterval(() => {
      const { frame } = this.state;

      this.setState({
        frame: frame < this.maxFrames - 1 ? frame + 1 : 0,
      });
    }, 40);
  };

  private stopAnimation = () => {
    if (!this.state.animate) return;

    this.setState({ animate: false });

    if (this.timer) {
      clearInterval(this.timer);
    }
  };

  public render = () => {
    const { className = '', colour } = this.props;
    const { frame, animate } = this.state;

    const backgroundPositionY = animate
      ? frame * this.height
      : (this.maxFrames - 1) * this.height;

    return (
      <div
        className={c(['relative', className])}
        style={{
          width: `${this.width}px`,
          height: `${this.height}px`,
        }}
        onMouseEnter={this.startAnimation}
        onMouseLeave={this.stopAnimation}
      >
        <div
          className={c(
            'absolute absolute--tl c-animated-wordmark c-animated-wordmark--white',
            {
              'opacity-1--i': colour && colour === DaisieWordmarkColour.White,
              'opacity-0--i': colour && colour === DaisieWordmarkColour.Black,
            }
          )}
          style={{
            width: `${this.width}px`,
            height: `${this.height}px`,
            backgroundImage: `url('${AnimatedWordmarkWhite}')`,
            backgroundPositionY: `-${backgroundPositionY}px`,
            backgroundSize: `${this.width}px auto`,
          }}
        />
        <div
          className={c(
            'absolute absolute--tl c-animated-wordmark c-animated-wordmark--black',
            {
              'opacity-0--i': colour && colour === DaisieWordmarkColour.White,
              'opacity-1--i': colour && colour === DaisieWordmarkColour.Black,
            }
          )}
          style={{
            width: `${this.width}px`,
            height: `${this.height}px`,
            backgroundImage: `url('${AnimatedWordmarkBlack}')`,
            backgroundPositionY: `-${backgroundPositionY}px`,
            backgroundSize: `${this.width}px auto`,
          }}
        />
      </div>
    );
  };
}

export { AnimatedWordmark };
