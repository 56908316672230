import * as React from 'react';

import { connect } from 'unistore/react';

import { CardStyle } from '@/components/global/Cards/CardStyle';
import { Toast } from '@/components/global/Toaster/Toast';
import { sendRegisterToWorkshopTrackingEvents } from '@/components/workshops/sendRegisterToWorkshopTrackingEvents';

import { AttendButton } from '@/components/design_system/AttendButton/AttendButton';
import { AttendButtonType } from '@/components/design_system/AttendButton/AttendButtonType';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';

import { DaisieCustomEvent } from '@/enums/DaisieCustomEvent';
import { GlobalModal } from '@/enums/GlobalModal';
import { GlobalPortal } from '@/enums/GlobalPortal';
import { IconSize } from '@/enums/IconSize';

import {
  WORKSHOP_ATTEND_FAIL,
  WORKSHOP_UNATTEND_FAIL,
} from '@/messages/errors';

import { getUsersFromData } from '@/store/helpers';
import {
  registerToWorkshop,
  unregisterFromWorkshop,
} from '@/store/helpers/calendarHelpers';
import { modalActions } from '@/store/modules/modal';
import { portalActions } from '@/store/modules/portal';

import { Http } from '@/utils/api/Http';

interface State {
  isLoadingAttendingChange: boolean;
  isNowAttending: boolean;
  isNowUnattending: boolean;
  rawAttendees: User[];
  displayAttendeeCount: number;
  isAttendeesLoaded: boolean;
  mentor?: User;
}

export const addWorkshopComponentActions = <P extends WorkshopComponentActions>(
  Component: React.ComponentType<P>
) =>
  connect(['auth', 'sizing'], () => ({
    ...modalActions(),
    ...portalActions(),
  }))(
    class AddWorkshopComponentActions extends React.Component<P, State> {
      public state: State = {
        isLoadingAttendingChange: false,
        isNowAttending: false,
        isNowUnattending: false,
        rawAttendees: [],
        displayAttendeeCount: 0,
        isAttendeesLoaded: false,
        mentor: undefined,
      };

      public attendWorkshop = async ({
        batchId,
        workshop,
        timeStart,
        timeEnd,
        showLoadingState,
        onDismiss,
      }: {
        batchId: string;
        workshop: Workshop;
        timeStart?: Date;
        timeEnd?: Date;
        showLoadingState?: boolean;
        onDismiss?: () => void;
      }) => {
        try {
          this.setState({ isLoadingAttendingChange: true });

          if (showLoadingState) {
            window.dispatchEvent(
              new CustomEvent(
                DaisieCustomEvent.show_workshop_attend_modal_loading,
                {
                  detail: {
                    coverImageUrl: workshop.coverMedia,
                  },
                }
              )
            );
          }

          await registerToWorkshop(batchId);

          sendRegisterToWorkshopTrackingEvents({ workshop });

          this.setState({
            isNowAttending: true,
            isNowUnattending: false,
            displayAttendeeCount: this.state.displayAttendeeCount + 1,
          });

          window.dispatchEvent(
            new CustomEvent(DaisieCustomEvent.show_workshop_attend_modal, {
              detail: {
                title: workshop.title,
                description: workshop.description,
                coverImageUrl: workshop.coverMedia,
                timeStart,
                timeEnd,
                onDismiss,
              },
            })
          );
        } catch (e) {
          new Toast({
            body: WORKSHOP_ATTEND_FAIL,
            failure: true,
          }).dispatch();
        }

        this.setState({ isLoadingAttendingChange: false });
      };

      public unattendWorkshop = async (batchId: string) => {
        try {
          this.setState({ isLoadingAttendingChange: true });

          await unregisterFromWorkshop(batchId);

          this.setState({
            isNowUnattending: true,
            isNowAttending: false,
            displayAttendeeCount: this.state.displayAttendeeCount - 1,
          });
        } catch (e) {
          new Toast({
            body: WORKSHOP_UNATTEND_FAIL,
            failure: true,
          }).dispatch();
        }

        this.setState({ isLoadingAttendingChange: false });
      };

      // Used in contexts inside the calendar, where attendees info is included in the
      // CalendarEvent object
      public setWorkshopAttendees = ({
        rawAttendees,
        rawAttendeeCount,
        mentor,
      }: {
        rawAttendees: User[];
        rawAttendeeCount: number;
        mentor: User;
      }) => {
        this.setState({
          mentor,
          rawAttendees,
          displayAttendeeCount: rawAttendeeCount,
          isAttendeesLoaded: true,
        });
      };

      // Used in contexts outside the calendar where the attendees info isn't included
      // e.g. HomepageHero.tsx, FeaturedWorkshop.tsx
      public fetchWorkshopAttendees = async ({
        batchId,
        mentor,
      }: {
        batchId: string;
        mentor: User;
      }) => {
        this.setState({ isAttendeesLoaded: false });

        try {
          const { data } = await new Http(
            `/workshopBatch/${batchId}/attendees`
          ).get<APIObject<APIUser[]>>();

          const rawAttendees = getUsersFromData(data).filter(
            (u: User) => u.id !== mentor.id
          );

          this.setState({ rawAttendees, isAttendeesLoaded: true });
        } catch (e) {
          // TODO
        }
      };

      public handleAttendButton = async ({
        batchId,
        workshop,
        timeStart,
        timeEnd,
      }: {
        batchId: string;
        workshop: Workshop;
        timeStart?: Date;
        timeEnd?: Date;
      }) => {
        // Check if current user has an email address, if they don't, show the modal
        const {
          auth: { user },
          sizing: { isMobile },
        } = this.props;

        if (!user) {
          return;
        }

        if (!user.email) {
          if (isMobile) {
            this.props.updatePortal({
              portal: GlobalPortal.workshop_add_email,
              data: {
                onComplete: async () => {
                  await this.attendWorkshop({
                    batchId,
                    workshop,
                    timeStart,
                    timeEnd,
                  });
                },
              },
            });
          } else {
            this.props.updateModal({
              modal: GlobalModal.workshop_add_email,
              data: {
                onComplete: async () => {
                  await this.attendWorkshop({
                    batchId,
                    workshop,
                    timeStart,
                    timeEnd,
                  });
                },
              },
              className: 'page-modal__content--workshop-add-email',
            });
          }
        } else {
          await this.attendWorkshop({ batchId, workshop, timeStart, timeEnd });
        }
      };

      public renderActionButton = ({
        isLoading,
        isAttendingRaw,
        buttonSize = ButtonSize.m,
        buttonIconSize = IconSize.s,
        cardStyle,
        showStartFreeTrialWhileLoggedOut,
        workshop,
        batchId,
        sessionId,
        isInProgress,
        handleShowOnDemandPlayer,
        isEnded,
        timeStart,
        timeEnd,
        isFreeWorkshopBadgeDisplayed,
      }: {
        // General
        isLoading: boolean;
        isAttendingRaw: boolean;
        // Styling
        buttonSize?: ButtonSize;
        buttonIconSize?: IconSize;
        cardStyle?: CardStyle;
        showStartFreeTrialWhileLoggedOut?: boolean;
        // Workshops
        workshop?: Workshop;
        batchId?: string;
        sessionId?: string;
        isInProgress?: boolean;
        handleShowOnDemandPlayer?: () => void;
        isEnded?: boolean;
        timeStart?: Date;
        timeEnd?: Date;
        isFreeWorkshopBadgeDisplayed?: boolean;
      }) => {
        const {
          sizing: { isMobile },
          auth: { isAuthorised, user },
        } = this.props;

        const { isLoadingAttendingChange, isNowAttending, isNowUnattending } =
          this.state;

        const isAttending: boolean =
          (isAttendingRaw && !isNowUnattending) || isNowAttending;

        const isUnsubscribed: boolean =
          (!!user && user.subscriptionTier === null) || !isAuthorised;

        const isWorkshopPage = window.location.href.includes('workshops');

        return (
          <AttendButton
            // TODO: differentiate between v1 & v2
            isWorkshopV2Page={isWorkshopPage}
            buttonTextClassName={isWorkshopPage ? 'ph8 pv6' : ''}
            attendButtonType={AttendButtonType.workshop}
            buttonSize={buttonSize}
            buttonIconSize={buttonIconSize}
            cardStyle={cardStyle}
            isComponentLoading={isLoading}
            isButtonLoading={isLoadingAttendingChange}
            workshop={workshop}
            handleAttend={async (e: any) => {
              e.preventDefault();

              if (!workshop || !batchId) {
                return;
              }

              await this.handleAttendButton({
                batchId,
                workshop,
                timeStart,
                timeEnd,
              });
            }}
            handleUnattend={async (e: any) => {
              e.preventDefault();

              if (!batchId) {
                return;
              }

              if (isMobile) {
                this.props.updatePortal({
                  portal: GlobalPortal.calendar_event_unattend_confirm,
                  data: {
                    onUnattend: async () => {
                      await this.unattendWorkshop(batchId);
                    },
                  },
                });
              } else {
                this.props.updateModal({
                  modal: GlobalModal.calendar_event_unattend_confirm,
                  data: {
                    onUnattend: async () => {
                      await this.unattendWorkshop(batchId);
                    },
                  },
                });
              }
            }}
            isUnsubscribed={isUnsubscribed}
            isAttending={isAttending}
            isAuthorised={isAuthorised}
            currentUser={!!user ? user : undefined}
            sessionId={sessionId}
            isInProgress={isInProgress}
            showStartFreeTrialWhileLoggedOut={showStartFreeTrialWhileLoggedOut}
            handleShowOnDemandPlayer={handleShowOnDemandPlayer}
            hasWorkshopEnded={isEnded}
            isFreeWorkshopBadgeDisplayed={isFreeWorkshopBadgeDisplayed}
          />
        );
      };

      public render = () => {
        const {
          auth: { user },
        } = this.props;

        const {
          isLoadingAttendingChange,
          isNowAttending,
          isNowUnattending,
          rawAttendees,
          isAttendeesLoaded,
          mentor,
          displayAttendeeCount,
        } = this.state;

        const stateProps = {
          isLoadingAttendingChange,
          isNowAttending,
          isNowUnattending,
          isAttendeesLoaded,
          displayAttendeeCount,
        };

        // Logic to show locally update the attendees array if
        // the current user un/attends the workshop
        const displayAttendees: User[] =
          typeof rawAttendees === 'undefined'
            ? []
            : isNowUnattending
            ? rawAttendees.filter((u: User) => user && u.id !== user.id)
            : isNowAttending
            ? ([
                !!user ? user : [],
                ...rawAttendees.filter((u: User) => user && u.id !== user.id),
              ] as User[])
            : rawAttendees;

        return (
          <Component
            {...(this.props as P)}
            {...stateProps}
            displayAttendees={
              !!mentor ? [mentor, ...displayAttendees] : displayAttendees
            }
            displayAttendeeCount={displayAttendeeCount}
            attendWorkshop={this.attendWorkshop}
            unattendWorkshop={this.unattendWorkshop}
            setWorkshopAttendees={this.setWorkshopAttendees}
            fetchWorkshopAttendees={this.fetchWorkshopAttendees}
            handleAttendButton={this.handleAttendButton}
            renderActionButton={this.renderActionButton}
          />
        );
      };
    }
  );
