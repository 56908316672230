import { useState } from 'react';

// eslint-disable-next-line func-style
function useVideoProgress(workshopSlug: string, onDemandMediaId: string) {
  const splitWorkshopSlug: string[] = workshopSlug.split('-');
  const workshopId: string = `workshop_${splitWorkshopSlug.pop()}`;

  const [progress, setProgress] = useState(() => {
    let videoProgress = JSON.parse(
      localStorage.getItem('videoProgress') || '{}'
    );

    if (!videoProgress?.[workshopId]?.[onDemandMediaId]) {
      videoProgress = {
        ...videoProgress,
        [workshopId]: { ...videoProgress[workshopId], [onDemandMediaId]: 0 },
      };
    }

    localStorage.setItem('videoProgress', JSON.stringify(videoProgress));

    return videoProgress;
  });

  const setVideoProgress = (
    workshopId: string,
    onDemandMediaId: string,
    currentTime: number
  ) => {
    const newProgress = {
      ...progress,
      [workshopId]: { ...progress[workshopId], [onDemandMediaId]: currentTime },
    };

    setProgress(newProgress);

    localStorage.setItem('videoProgress', JSON.stringify(newProgress));
  };

  return [progress, setVideoProgress];
}

export default useVideoProgress;
