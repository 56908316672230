import { Client, Stream } from 'agora-rtc-sdk';
import { RtmChannel, RtmClient } from 'agora-rtm-sdk';

import { HandStatus } from '@/enums/HandStatus';
import { VoiceRoomRole } from '@/enums/VoiceRoomRole';

import { getHandFromData, getHandsFromData } from '@/store/helpers';

import { Http } from '@/utils/api/Http';

const emptyChannelState = {
  activeChannelId: undefined,
  uid: undefined,
  uuid: undefined,
  rtcToken: undefined,
  rtmToken: undefined,
  role: undefined,
  error: undefined,
  rtcClient: undefined,
  rtmClient: undefined,
  localStream: undefined,
  streamList: [],
  users: [],
  // mutedUsers: [],
  loading: false,
  hand: undefined,
  hands: [],
  rtmChannel: undefined,
  room: undefined,
};

const channelState: ChannelState = {
  channel: emptyChannelState,
};

const channelActions = (store: any) => ({
  isLoading: (state: GlobalStoreState, loading: boolean) => ({
    channel: {
      ...store.getState().channel,
      loading,
    },
  }),

  getUsers: async (state: GlobalStoreState) => {
    const activeRtmChannel = store.getState().channel.rtmChannel as RtmChannel;

    if (!activeRtmChannel) {
      throw new Error('No RTM channel active');
    }

    const stateCopy = store.getState();
    const allChannelMembers = await activeRtmChannel.getMembers();

    const users = allChannelMembers.map((userId: string) => {
      const existingInfo = stateCopy.channel.users.find(
        (u: RtmUser) => u.userId === userId
      );

      return {
        userId,
        role:
          state.auth.user && state.channel.role && userId === state.auth.user.id
            ? state.channel.role
            : existingInfo
            ? existingInfo.role
            : VoiceRoomRole.unknown,
      };
    });

    return {
      channel: {
        ...store.getState().channel,
        users,
      },
    };
  },

  updateUserState: async (
    state: GlobalStoreState,
    userId: string,
    isSpeaker: boolean
  ) => {
    const stateCopy = store.getState();

    const updatedUsers = stateCopy.channel.users.map((u: RtmUser) => {
      if (u.userId === userId) {
        return {
          ...u,
          role: isSpeaker ? VoiceRoomRole.host : VoiceRoomRole.audience,
        };
      } else {
        return u;
      }
    });

    return {
      channel: {
        ...stateCopy.channel,
        users: updatedUsers,
      },
    };
  },

  clearChannel: () => ({
    channel: emptyChannelState,
  }),

  setActiveChannelId: (state: GlobalStoreState, chatRoomUuid: string) => ({
    channel: {
      ...emptyChannelState,
      activeChannelId: chatRoomUuid,
      room: store.getState().room.currentRoom,
    },
  }),

  setRTCClient: async (state: GlobalStoreState, rtcClient: Client) => ({
    channel: {
      ...store.getState().channel,
      rtcClient,
    },
  }),

  setRTMClient: async (state: GlobalStoreState, rtmClient: RtmClient) => ({
    channel: {
      ...store.getState().channel,
      rtmClient,
    },
  }),

  setRTMChannel: async (state: GlobalStoreState, rtmChannel: RtmChannel) => ({
    channel: {
      ...store.getState().channel,
      rtmChannel,
    },
  }),

  setLocalStream: async (state: GlobalStoreState, localStream: Stream) => ({
    channel: {
      ...store.getState().channel,
      localStream,
    },
  }),

  setRemoteStreams: async (state: GlobalStoreState, streamList: Stream[]) => ({
    channel: {
      ...store.getState().channel,
      streamList,
    },
  }),

  exitClients: async () => {
    const { localStream, rtcClient, rtmClient } = store.getState().channel;

    if (!rtcClient || !localStream) return;

    rtcClient.unpublish(localStream);

    localStream.close();

    rtcClient.leave(
      () => {
        // console.log('Client succeed to leave.');
      },
      () => {
        // console.log('Client failed to leave.');
      }
    );

    rtmClient.logout();

    return { channel: emptyChannelState };
  },

  getAuthToken: async (state: GlobalStoreState, chatRoomUuid: string) => {
    try {
      const {
        data: {
          attributes: { uid, accessToken, rtmToken, role },
        },
      }: any = await new Http(`/voice/auth`).post({
        chatRoomUuid,
      });

      if (!uid || !accessToken) {
        throw new Error('Invalid/missing UID or access token created');
      }

      return {
        channel: {
          ...store.getState().channel,
          activeChannelId: chatRoomUuid,
          rtcToken: accessToken,
          uid,
          role:
            // TODO: Put 'PUBLISHER' in an enum
            role === 'PUBLISHER' ? VoiceRoomRole.host : VoiceRoomRole.audience,
          rtmToken,
        },
      };
    } catch (error) {
      return {
        channel: {
          ...store.getState().channel,
          error,
        },
      };
    }
  },

  lowerHand: async (state: GlobalStoreState) => {
    try {
      await new Http(`/voice/${state.channel.activeChannelId}/hand`).delete();

      const stateCopy: GlobalStoreState = store.getState();

      // Deprecated
      // await sendAgoraEventMessage({
      //   eventType: AgoraEventType.UserLoweredHand,
      //   channel: stateCopy.channel.rtmChannel,
      // });

      return {
        channel: {
          ...store.getState().channel,
          hand: undefined,
        },
      };
    } catch (error) {
      return {
        channel: {
          ...store.getState().channel,
          error,
        },
      };
    }
  },

  raiseHand: async (state: GlobalStoreState) => {
    try {
      const { data }: any = await new Http(
        `/voice/${state.channel.activeChannelId}/hand`
      ).post();

      const stateCopy: GlobalStoreState = store.getState();

      // Deprecated
      // await sendAgoraEventMessage({
      //   eventType: AgoraEventType.UserRaisedHand,
      //   channel: stateCopy.channel.rtmChannel,
      // });

      return {
        channel: {
          ...store.getState().channel,
          hand: getHandFromData(data),
        },
      };
    } catch (error) {
      return {
        channel: {
          ...store.getState().channel,
          error,
        },
      };
    }
  },

  fetchMyHand: async (state: GlobalStoreState) => {
    try {
      const { data }: any = await new Http(
        `/voice/${state.channel.activeChannelId}/hand`
      ).get();

      return {
        channel: {
          ...store.getState().channel,
          hand: getHandFromData(data),
        },
      };
    } catch (error) {
      return {
        channel: {
          ...store.getState().channel,
          error,
        },
      };
    }
  },

  fetchHands: async (state: GlobalStoreState) => {
    try {
      const { data }: any = await new Http(
        `/voice/${state.channel.activeChannelId}/hands`
      ).get();

      return {
        channel: {
          ...store.getState().channel,
          hands: getHandsFromData(data),
        },
      };
    } catch (error) {
      return {
        channel: {
          ...store.getState().channel,
          error,
        },
      };
    }
  },

  acceptHand: async (state: GlobalStoreState, userId: string) => {
    try {
      await new Http(
        `/voice/${state.channel.activeChannelId}/hand/${userId}`
      ).patch({ status: HandStatus.Accepted });

      return {
        channel: {
          ...store.getState().channel,
          hand: {
            ...store.getState().channel.hand,
            status: HandStatus.Accepted,
          },
        },
      };
    } catch (error) {
      return {
        channel: {
          ...store.getState().channel,
          error,
        },
      };
    }
  },

  declineHand: async (state: GlobalStoreState, userId: string) => {
    try {
      await new Http(
        `/voice/${state.channel.activeChannelId}/hand/${userId}`
      ).patch({ status: HandStatus.Declined });

      return {
        channel: {
          ...store.getState().channel,
          hand: {
            ...store.getState().channel.hand,
            status: HandStatus.Declined,
          },
        },
      };
    } catch (error) {
      return {
        channel: {
          ...store.getState().channel,
          error,
        },
      };
    }
  },

  revokeHand: async (state: GlobalStoreState, userId: string) => {
    try {
      await new Http(
        `/voice/${state.channel.activeChannelId}/hand/${userId}`
      ).patch({ status: HandStatus.Revoked });

      return {
        channel: {
          ...store.getState().channel,
          hand: {
            ...store.getState().channel.hand,
            status: HandStatus.Revoked,
          },
        },
      };
    } catch (error) {
      return {
        channel: {
          ...store.getState().channel,
          error,
        },
      };
    }
  },

  inviteUserToSpeak: async (state: GlobalStoreState, userId: string) => {
    try {
      await new Http(
        `/voice/${state.channel.activeChannelId}/hand/${userId}`
      ).patch({ status: HandStatus.Invited });

      return {
        channel: {
          ...store.getState().channel,
          hand: {
            ...store.getState().channel.hand,
            status: HandStatus.Invited,
          },
        },
      };
    } catch (error) {
      return {
        channel: {
          ...store.getState().channel,
          error,
        },
      };
    }
  },

  acceptInviteToSpeak: async (state: GlobalStoreState) => {
    try {
      if (!state.auth.user) {
        throw new Error();
      }

      await new Http(
        `/voice/${state.channel.activeChannelId}/hand/accept`
      ).post({});

      return {
        channel: {
          ...store.getState().channel,
          hand: {
            ...store.getState().channel.hand,
            status: HandStatus.Accepted,
          },
        },
      };
    } catch (error) {
      return {
        channel: {
          ...store.getState().channel,
          error,
        },
      };
    }
  },

  declineInviteToSpeak: async (state: GlobalStoreState) => {
    try {
      await new Http(
        `/voice/${state.channel.activeChannelId}/hand/decline`
      ).post();

      return {
        channel: {
          ...store.getState().channel,
          hand: {
            ...store.getState().channel.hand,
            status: HandStatus.Lowered,
          },
        },
      };
    } catch (error) {
      return {
        channel: {
          ...store.getState().channel,
          error,
        },
      };
    }
  },

  // setMutedState: (
  //   state: GlobalStoreState,
  //   userId: string,
  //   isMuted: boolean
  // ) => {
  // let mutedUsers = store.getState().channel.mutedUsers;
  // if (isMuted) {
  //   // Add to muted users
  //   mutedUsers = [
  //     ...mutedUsers.filter((uid: string) => uid !== userId),
  //     userId,
  //   ];
  // } else {
  //   // Remove from muted users
  //   mutedUsers = mutedUsers.filter((uid: string) => uid !== userId);
  // }
  // return {
  //   channel: {
  //     ...store.getState().channel,
  //     mutedUsers,
  //   },
  // };
  // },

  updateCachedRoom: (state: GlobalStoreState, setUndefined?: boolean) => {
    const cachedState = store.getState();

    return {
      channel: {
        ...cachedState.channel,
        room: setUndefined ? undefined : cachedState.room.currentRoom,
      },
    };
  },
});

export { channelState, channelActions };
