import React from 'react';

import { connect } from 'unistore/react';

import { Icon } from '@/components/global/Icon/Icon';
import { Link } from '@/components/global/Link/Link';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { IconSize } from '@/enums/IconSize';

import { routes } from '@/routes';

import { sizingActions } from '@/store/modules/sizing';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props extends AuthState, SizingActions, SizingState {
  hideCookieBanner: (scrollTo?: number) => void;
  isDiscordBannerDismissed: boolean;
  cookieBannerVisible: boolean;
  route?: string;
}

class CookiesConsentComponent extends React.Component<Props> {
  public componentDidMount = () => {
    this.props.updateSizing();
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps.cookieBannerVisible !== this.props.cookieBannerVisible) {
      this.props.updateSizing();
    }
  };

  private agree = () => {
    // @ts-ignore
    localStorage.setItem('cookie_consent', true);

    // Remove style from nav-overlay
    const navOverlay = document.querySelector('.nav-overlay') as HTMLDivElement;

    if (navOverlay) {
      navOverlay.style.cssText = '';
    }

    // Save scroll position of container
    const pageLayout = document.querySelector(
      '.page-layout--cookie-banner'
    ) as HTMLDivElement;

    const scrollTo = pageLayout ? pageLayout.scrollTop : undefined;

    this.props.hideCookieBanner(scrollTo);
  };

  public render() {
    const {
      route,
      auth: { isAuthorised },
      sizing: { isMobile },
    } = this.props;

    if (
      localStorage.getItem('cookie_consent') ||
      !this.props.cookieBannerVisible ||
      (route && ['appStoreReferral', 'app'].includes(route))
    ) {
      return null;
    }

    return (
      <aside
        className={c(
          'c-cookie-consent u-1/1 p16@m pv16@s ph20@s absolute@s absolute--b@s',
          {
            'c-cookie-consent__top':
              isAuthorised && !this.props.isDiscordBannerDismissed && !isMobile,
          }
        )}
        data-key-route={route}
        data-auth={isAuthorised}
      >
        <button
          type="button"
          className="u-flex u-justify-end u-1/1 mb12 u-hide@m"
          onClick={() => {
            this.agree();
          }}
        >
          <Icon id="clear" className="u-cloud-grey" size={IconSize.xs} />
        </button>
        <div className="u-cloud-grey c-cookie-consent__wrap u-flex u-align-center u-flex-column@s mv6@s">
          <p className="f-text-3 mr24@m mb0@m mb12@s">
            {isMobile ? (
              t(
                'By clicking ‘OK’ or continuing to use the site, you agree to our use of cookies and similar technologies.'
              )
            ) : (
              <>
                {t(
                  'By clicking ‘OK’ or continuing to use the site, you agree to our use of cookies and similar technologies. To find out how you can control them and for more details, visit our '
                )}
                <Link to={routes.cookiePolicy}>{t('cookie policy')}</Link>
                {t('.')}
              </>
            )}
          </p>
          <div className="u-flex u-align-center u-justify-center@s u-1/1@s">
            {isMobile && (
              <Link to={routes.cookiePolicy} className="f-text-3 mr16@s">
                {t('cookie policy')}
              </Link>
            )}
            <Button
              type={ButtonType.action}
              onClick={this.agree}
              text={t('OK')}
              buttonStyle={ButtonStyle.default}
              className="ml16@s u-2/5@s"
            />
          </div>
        </div>
      </aside>
    );
  }
}

export const CookiesConsent = connect(
  ['auth', 'sizing'],
  (store: GlobalStoreState) => ({
    ...sizingActions(store),
  })
)(CookiesConsentComponent);
