import * as React from 'react';

import { Icon } from '@/components/global/Icon/Icon';
import { Paginator } from '@/components/global/Paginator/Paginator';

import { DEFAULT_MINIMISED_LIST_COUNT } from '@/utils/constants';
import { c } from '@/utils/strings/c';

interface Props {
  data?: any[];
  header?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  renderItem?: (item: any) => React.ReactNode;
  onEndReached?: any;
  fixed?: boolean;
  className?: string;
  emptyRow?: React.ReactNode;
  minimisedLimit?: number;
  minimisedShowText?: string;
  minimisedHideText?: string;
  firstItem?: () => React.ReactNode;
  lastItem?: () => React.ReactNode;
}

interface State {
  isExpanded: boolean;
}

class List extends React.Component<Props, State> {
  public state = {
    isExpanded: false,
  };

  private toggleMinimise = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  public render = () => {
    const {
      data = [],
      renderItem = () => null,
      onEndReached,
      header = null,
      body = null,
      footer = null,
      fixed = false,
      className = '',
      emptyRow = null,
      minimisedLimit,
      minimisedShowText,
      minimisedHideText,
      firstItem,
    } = this.props;

    const { isExpanded } = this.state;

    let minimisedData = data;

    if (minimisedLimit) {
      minimisedData = isExpanded ? data : data.slice(0, minimisedLimit);
    }

    return (
      <>
        <ul
          className={c(['c-list pb24 mt20', className], {
            'c-list--scrollable': !fixed,
          })}
        >
          <Paginator onEndReached={onEndReached}>
            {header}
            {minimisedData && minimisedData.length
              ? minimisedData.map((item: any, index: number, array: any[]) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={index}>
                    {index === 0 && firstItem && firstItem()}
                    {renderItem({ item, index, array })}
                  </React.Fragment>
                ))
              : emptyRow}
            {body}
            {footer}
          </Paginator>
        </ul>

        {minimisedLimit && data.length > DEFAULT_MINIMISED_LIST_COUNT && (
          <button
            type="button"
            onClick={this.toggleMinimise}
            className="f-text-3 u-flex u-dark-grey u-1/1 mt16"
          >
            <span className="u-flex mlauto mrauto">
              {isExpanded ? minimisedHideText : minimisedShowText}
              <Icon
                id={isExpanded ? 'chevron-up' : 'chevron-down'}
                className="ml4"
                size="s"
              />
            </span>
          </button>
        )}
      </>
    );
  };
}

export { List };
