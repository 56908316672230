import * as React from 'react';

import { Icon } from '@/components/global/Icon/Icon';

import { c } from '@/utils/strings/c';

interface Props {
  text: string;
  animated?: boolean;
}

class Badge extends React.Component<Props> {
  public render = () => {
    const { text, animated } = this.props;

    return (
      <div
        className={c('badge', {
          'badge--animated': animated,
        })}
      >
        <Icon id="badge" size="m" className="u-brand" />
        <span>{text}</span>
      </div>
    );
  };
}

export { Badge };
