import React from 'react';

import delve from 'dlv';

import { List } from '@/components/global/List/List';
import { LoadingSymbol } from '@/components/global/LoadingSymbol/LoadingSymbol';
import { Toast } from '@/components/global/Toaster/Toast';
import { UsersListItem } from '@/components/users/UsersList/UsersListItem';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { BLOCKED_USERS_FETCH_FAIL, UNBLOCK_USER_FAIL } from '@/messages/errors';
import { UNBLOCK_USER_SUCCESS } from '@/messages/messages';

import { getUsersFromData } from '@/store/helpers';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

import { Http } from '@/utils/api/Http';
import { t } from '@/utils/i18n/i18n';

interface Props {
  toggle: () => void;
}

interface State {
  blockedUsers: User[];
  blockedUsersLoaded: boolean;
}

class ManageBlockedUsers extends React.Component<Props, State> {
  public state = {
    blockedUsers: [],
    blockedUsersLoaded: false,
  };

  public componentDidMount = async () => {
    try {
      await this.fetchData();
    } catch (e) {
      new Toast({ body: BLOCKED_USERS_FETCH_FAIL, failure: true }).dispatch();
    }
  };

  private fetchData = async () => {
    this.setState({ blockedUsersLoaded: false });

    try {
      const { data } = await new Http('/users/blocked').get<
        APIObject<APIBlockedUsers>
      >();

      const blockedUsers = getUsersFromData(
        delve(data, 'relationships.blocked.data')
      );

      this.setState({ blockedUsers });
    } catch (e) {
      throw new Error();
    }

    this.setState({ blockedUsersLoaded: true });
  };

  private unblockUser = async (user: User) => {
    try {
      const { blockedUsers } = this.state;

      await new Http(`/users/${user.username}/unblock`).post();

      this.setState({
        blockedUsers: blockedUsers.filter((u: User) => u.id !== user.id),
      });

      new Toast({ body: UNBLOCK_USER_SUCCESS }).dispatch();
    } catch (e) {
      new Toast({ body: UNBLOCK_USER_FAIL, failure: true }).dispatch();
    }
  };

  public render = () => {
    const { blockedUsers, blockedUsersLoaded } = this.state;

    if (!blockedUsersLoaded) {
      return (
        <div className="manage-blocked-users u-flex">
          <LoadingSymbol className="mlauto mrauto" />
        </div>
      );
    }

    return (
      <div
        className="manage-blocked-users"
        data-ui-test-id={isUiTest() ? 'blocked-users-visible' : undefined}
      >
        <List
          data={blockedUsers}
          fixed={true}
          emptyRow={
            <p className="f-text-3 u-text-center u-dark-grey">
              {t("You haven't blocked anyone")}
            </p>
          }
          renderItem={({ item: user }) => (
            <li
              key={user.id}
              data-user-username={user.username}
              className="u-flex mb8"
            >
              <UsersListItem
                user={user}
                linkEnabled={false}
                type="userFieldsOnly"
                darkMode={true}
              />
              <Button
                type={ButtonType.action}
                size={ButtonSize.s}
                iconId="clear"
                onClick={async () => await this.unblockUser(user)}
                buttonStyle={ButtonStyle.light}
              />
            </li>
          )}
        />
      </div>
    );
  };
}

export { ManageBlockedUsers };
