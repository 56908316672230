import * as React from 'react';

import { connect } from 'unistore/react';
import widont from 'widont';

import { TRIAL_LENGTH_TEXT_PLURAL } from '@/components/subscription/constants';
import { makePriceLookGood } from '@/components/subscription/helpers';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';
import { SimpleCheckbox } from '@/components/design_system/SimpleCheckbox/SimpleCheckbox';

import { SubscriptionInterval } from '@/enums/SubscriptionInterval';
import { SubscriptionPlanSelectorStyle } from '@/enums/SubscriptionPlanSelectorStyle';

import { routes } from '@/routes';

import { findProductAndPrice } from '@/store/helpers/subscriptionHelpers';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';
import {
  getDisplayPrice,
  getPercentOff,
  getPreviousPricing,
} from '@/utils/strings/currency';

interface Props extends AuthState, SubscriptionState, SizingState, ABTestState {
  selectorStyle: SubscriptionPlanSelectorStyle;
  className?: string;
  selectedSubscriptionInterval?: SubscriptionInterval;
  setSelectedSubscriptionInterval: (
    subscriptionInterval?: SubscriptionInterval
  ) => void;
  customAnnualOfferCallout?: string;
  annualPriceDiscountPercent?: number;
  linkToStudentDiscountFlow?: boolean;
  forStudentDiscount?: boolean;
  redirectTo?: string;
  workshopVariantOneStep?: number;
  isUserComingFromWorkshop?: boolean;
}

class SubscriptionPlanSelectorComp extends React.Component<Props> {
  public componentDidMount = () => {
    // Type checking
    const { selectorStyle } = this.props;

    if (typeof selectorStyle === 'undefined') {
      throw new Error('SubscriptionPlanSelector: missing `selectorStyle` prop');
    }
  };

  private getChoosePlanContinueLink = (
    interval: SubscriptionInterval
  ): string => {
    const {
      linkToStudentDiscountFlow,
      auth: { isAuthorised },
      redirectTo,
    } = this.props;

    let link = '';

    if (linkToStudentDiscountFlow) {
      if (isAuthorised) {
        link =
          interval === SubscriptionInterval.year
            ? routes.studentsSignUpYearlyPay
            : routes.studentsSignUpMonthlyPay;
      } else {
        link =
          interval === SubscriptionInterval.year
            ? routes.studentsSignUpYearly
            : routes.studentsSignUpMonthly;
      }
    } else {
      if (isAuthorised) {
        link =
          interval === SubscriptionInterval.year
            ? routes.optimisedJoinFlowSignUpYearlyPay
            : routes.optimisedJoinFlowSignUpMonthlyPay;
      } else {
        link =
          interval === SubscriptionInterval.year
            ? routes.optimisedJoinFlowSignUpYearly
            : routes.optimisedJoinFlowSignUpMonthly;
      }
    }

    return `${link}${
      !!redirectTo ? `?to=${encodeURIComponent(redirectTo)}` : ''
    }`;
  };

  private renderButtonChoosePlanContinue = (
    interval: SubscriptionInterval,
    user?: User | null,
    workshopVariantOneStep?: number,
    isUserComingFromWorkshop?: boolean,
    isMobile?: boolean,
    variant?: number
  ) => (
    <Button
      type={ButtonType.link}
      linkTo={this.getChoosePlanContinueLink(interval)}
      buttonStyle={ButtonStyle.default}
      size={ButtonSize.xl}
      text={
        (!!user && user.isGrandfathered) ||
        workshopVariantOneStep === 1 ||
        variant === 1
          ? t('Continue')
          : !isMobile && isUserComingFromWorkshop && !user?.hasPastSub
          ? t('Start your free trial')
          : !!user && user.hasPastSub
          ? t('Upgrade')
          : t('Try for free')
      }
      className="u-fit-content u-1/1@s"
    />
  );

  private renderIntervalSelector = ({
    interval,
  }: {
    interval: SubscriptionInterval;
  }) => {
    const {
      selectedSubscriptionInterval,
      subscription: { productsAndPrices, userCurrency },
      selectorStyle,
      customAnnualOfferCallout,
      annualPriceDiscountPercent,
      sizing: { isMobile },
      forStudentDiscount,
      auth: { user },
      abtest: { variant },
    } = this.props;

    const productPrice: SubscriptionProductPrice | undefined =
      findProductAndPrice({
        productsAndPrices,
        currency: userCurrency,
        recurringInterval: interval,
      });

    if (!productPrice) {
      return null;
    }

    const monthlyPrice: number =
      interval === SubscriptionInterval.year
        ? Math.floor(productPrice.amount / 12)
        : productPrice.amount;

    const yearlyPriceWithDiscountPercentOff: number | undefined =
      !!annualPriceDiscountPercent
        ? productPrice.amount * (1 - annualPriceDiscountPercent / 100)
        : undefined;

    const isUserComingFromWorkshop = window.location.href.includes(
      '?to=%2Fworkshops%2F'
    );

    const isNewPriceApplied: boolean = variant === 0 || variant === 1;

    return (
      <button
        type="button"
        className={c(
          'bh bv bh--white-hint bv--white-hint u-border-radius--s relative',
          {
            mr6:
              interval === SubscriptionInterval.year &&
              selectorStyle ===
                SubscriptionPlanSelectorStyle.loggedOutHomepageVertical &&
              !isMobile,
            ml6:
              interval === SubscriptionInterval.month &&
              selectorStyle ===
                SubscriptionPlanSelectorStyle.loggedOutHomepageVertical &&
              !isMobile,
            mb12:
              (interval === SubscriptionInterval.year &&
                selectorStyle ===
                  SubscriptionPlanSelectorStyle.loggedOutHomepageHorizontal) ||
              isMobile,
            'u-bg-charcoal-grey':
              !isUserComingFromWorkshop &&
              selectedSubscriptionInterval === interval,
            'u-translucent':
              isUserComingFromWorkshop &&
              selectedSubscriptionInterval !== interval,
            'u-flex-square u-1/2':
              selectorStyle ===
                SubscriptionPlanSelectorStyle.loggedOutHomepageVertical &&
              !isMobile,
            'ph32 pv32 u-text-left':
              selectorStyle ===
                SubscriptionPlanSelectorStyle.loggedOutHomepageHorizontal &&
              !isMobile,
            'ph44 pv80':
              selectorStyle === SubscriptionPlanSelectorStyle.choosePlan &&
              !isMobile,
            mb80:
              selectorStyle === SubscriptionPlanSelectorStyle.choosePlan &&
              interval === SubscriptionInterval.year &&
              !isMobile &&
              !isUserComingFromWorkshop,
            ml24:
              selectorStyle === SubscriptionPlanSelectorStyle.choosePlan &&
              isUserComingFromWorkshop &&
              interval === SubscriptionInterval.month &&
              !isMobile,
            'u-text-left ph16 pt32 pb24':
              isMobile && interval === SubscriptionInterval.year,
            'u-text-left ph16 pv24':
              isMobile && interval === SubscriptionInterval.month,
            'u-1/2': isUserComingFromWorkshop && !isMobile,
          }
        )}
        onClick={() => {
          this.props.setSelectedSubscriptionInterval(interval);
        }}
      >
        {interval === SubscriptionInterval.year && (
          <p
            className={c(
              'u-bg-go ph8 pv4 f-text-3 u-border-radius u-border-radius--xs absolute absolute--tl',
              {
                'mt16 ml16':
                  selectorStyle ===
                    SubscriptionPlanSelectorStyle.loggedOutHomepageVertical &&
                  !isMobile,
                'u-translate-y-m50 ml32':
                  selectorStyle ===
                    SubscriptionPlanSelectorStyle.loggedOutHomepageHorizontal &&
                  !isMobile,
                'u-translate-y-m50 ml16': isMobile,
                'mt20 ml20 ph16 pv8':
                  selectorStyle === SubscriptionPlanSelectorStyle.choosePlan &&
                  !isMobile &&
                  !isUserComingFromWorkshop,
                'ph16 pv8 u-fixed--center u-translate-y-x-m50':
                  selectorStyle === SubscriptionPlanSelectorStyle.choosePlan &&
                  !isMobile &&
                  isUserComingFromWorkshop,
              }
            )}
          >
            {!!customAnnualOfferCallout
              ? customAnnualOfferCallout
              : isNewPriceApplied && !isUserComingFromWorkshop
              ? widont(t('Best deal of the year'))
              : t('best value')}
          </p>
        )}

        <SimpleCheckbox
          className={c('', {
            'absolute absolute--tr mt16 mr16':
              (selectorStyle ===
                SubscriptionPlanSelectorStyle.loggedOutHomepageVertical &&
                !isMobile) ||
              (selectorStyle === SubscriptionPlanSelectorStyle.choosePlan &&
                !isMobile),
            'absolute absolute--right-center mr32':
              selectorStyle ===
              SubscriptionPlanSelectorStyle.loggedOutHomepageHorizontal,
            'absolute absolute--right-center mr16': isMobile,
          })}
          isChecked={selectedSubscriptionInterval === interval}
          isGreenCheckbox={isUserComingFromWorkshop}
        />

        <div
          className={c('', {
            'absolute absolute--mid-center':
              selectorStyle ===
                SubscriptionPlanSelectorStyle.loggedOutHomepageVertical &&
              !isMobile,
          })}
        >
          <p
            className={c('', {
              'f-lynstone-bold-2 mb8': isMobile,
              'f-lynstone-bold-2 mb16':
                selectorStyle ===
                  SubscriptionPlanSelectorStyle.loggedOutHomepageVertical &&
                !isMobile,
              'f-lynstone-bold-2a mb4':
                selectorStyle ===
                  SubscriptionPlanSelectorStyle.loggedOutHomepageHorizontal &&
                !isMobile,
              mb12:
                selectorStyle === SubscriptionPlanSelectorStyle.choosePlan &&
                !isMobile,
              'f-lynstone-bold-1b': !isUserComingFromWorkshop,
              'f-lynstone-bold-2 mb12': isUserComingFromWorkshop,
              mr64: isMobile,
            })}
          >
            {!yearlyPriceWithDiscountPercentOff &&
              ((selectorStyle ===
                SubscriptionPlanSelectorStyle.loggedOutHomepageVertical ||
                selectorStyle === SubscriptionPlanSelectorStyle.choosePlan) &&
              !isUserComingFromWorkshop ? (
                <>{getDisplayPrice(userCurrency, monthlyPrice)}/mo*</>
              ) : interval === SubscriptionInterval.year ? (
                isUserComingFromWorkshop || user?.hasPastSub ? (
                  t('Annual')
                ) : undefined
              ) : isUserComingFromWorkshop || user?.hasPastSub ? (
                t('Monthly')
              ) : undefined)}

            {selectorStyle === SubscriptionPlanSelectorStyle.choosePlan &&
              yearlyPriceWithDiscountPercentOff && (
                <>
                  {interval === SubscriptionInterval.year && (
                    <>
                      <span className="u-line-through mr8">
                        {getDisplayPrice(userCurrency, monthlyPrice)}
                      </span>
                      {getDisplayPrice(
                        userCurrency,
                        forStudentDiscount && userCurrency !== 'usd'
                          ? yearlyPriceWithDiscountPercentOff / 12
                          : makePriceLookGood(
                              yearlyPriceWithDiscountPercentOff / 12
                            )
                      )}
                      /mo*
                    </>
                  )}

                  {interval === SubscriptionInterval.month && (
                    <>{getDisplayPrice(userCurrency, monthlyPrice)}/mo*</>
                  )}
                </>
              )}

            {selectorStyle ===
              SubscriptionPlanSelectorStyle.loggedOutHomepageHorizontal && (
              <>
                {interval === SubscriptionInterval.year &&
                  yearlyPriceWithDiscountPercentOff && (
                    <>
                      {t('Annual')} -{' '}
                      <span className="u-line-through">
                        {getDisplayPrice(userCurrency, productPrice.amount)}
                      </span>
                      , Now{' '}
                      {getDisplayPrice(
                        userCurrency,
                        yearlyPriceWithDiscountPercentOff
                      )}
                    </>
                  )}

                {interval === SubscriptionInterval.year && (
                  <div>
                    {!forStudentDiscount && isNewPriceApplied && (
                      <>
                        {t('Annual -')}{' '}
                        <span className="f-lynstone-light-2a u-line-through">
                          {getPreviousPricing(userCurrency)}
                        </span>{' '}
                        {t('Now only ')}
                        {getDisplayPrice(userCurrency, productPrice.amount)}
                      </>
                    )}
                  </div>
                )}

                {interval === SubscriptionInterval.month && (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {isNewPriceApplied && !forStudentDiscount ? (
                      <>
                        {t('Monthly -')}{' '}
                        {/* Mar 2022 - temporarily comment out this as there won't be any discount for monthly plan */}
                        {/* <>
                          <span className="f-lynstone-light-2a u-line-through">
                            {userCurrency === 'gbp'
                              ? t('£9.99')
                              : userCurrency === 'eur'
                              ? t('12.99 €')
                              : t('$12.99')}
                          </span>{' '}
                          {t('Now only ')}
                        </> */}
                        {getDisplayPrice(userCurrency, monthlyPrice)}
                      </>
                    ) : (
                      t(`Monthly - first ${TRIAL_LENGTH_TEXT_PLURAL} free`)
                    )}
                  </>
                )}
              </>
            )}
          </p>

          <p
            className={c('u-dark-grey', {
              'f-text-3':
                selectorStyle !== SubscriptionPlanSelectorStyle.choosePlan,
              'f-text-2':
                selectorStyle === SubscriptionPlanSelectorStyle.choosePlan,
              mr64: isMobile,
            })}
          >
            {(selectorStyle ===
              SubscriptionPlanSelectorStyle.loggedOutHomepageVertical ||
              (selectorStyle === SubscriptionPlanSelectorStyle.choosePlan &&
                !yearlyPriceWithDiscountPercentOff)) && (
              <>
                {interval === SubscriptionInterval.year &&
                  t(
                    !isUserComingFromWorkshop
                      ? `*${getDisplayPrice(
                          userCurrency,
                          productPrice.amount
                        )} billed annually${
                          (!!user && user.isGrandfathered) ||
                          (!!user && user.hasPastSub) ||
                          variant === 1
                            ? ``
                            : ` after the trial ends`
                        }.`
                      : `${getDisplayPrice(
                          userCurrency,
                          monthlyPrice
                        )}/month, billed annually at ${getDisplayPrice(
                          userCurrency,
                          productPrice.amount
                        )}`
                  )}

                {interval === SubscriptionInterval.month &&
                  t(
                    !isUserComingFromWorkshop
                      ? `*Billed monthly${
                          (!!user && user.isGrandfathered) ||
                          (!!user && user.hasPastSub)
                            ? ``
                            : ` after the trial ends`
                        }.`
                      : `${getDisplayPrice(
                          userCurrency,
                          monthlyPrice
                        )} billed monthly`
                  )}
              </>
            )}

            {selectorStyle === SubscriptionPlanSelectorStyle.choosePlan &&
              yearlyPriceWithDiscountPercentOff && (
                <>
                  {interval === SubscriptionInterval.year && (
                    <>
                      {t(
                        `*${getDisplayPrice(
                          userCurrency,
                          yearlyPriceWithDiscountPercentOff
                        )} billed annually`
                      )}
                    </>
                  )}

                  {interval === SubscriptionInterval.month &&
                    t('*Billed monthly after the trial ends.')}
                </>
              )}

            {selectorStyle ===
              SubscriptionPlanSelectorStyle.loggedOutHomepageHorizontal && (
              <>
                {interval === SubscriptionInterval.year &&
                  yearlyPriceWithDiscountPercentOff &&
                  t(
                    `${getDisplayPrice(
                      userCurrency,
                      forStudentDiscount && userCurrency !== 'usd'
                        ? yearlyPriceWithDiscountPercentOff / 12
                        : makePriceLookGood(
                            yearlyPriceWithDiscountPercentOff / 12
                          )
                    )}/month, renews annually at full price`
                  )}

                {interval === SubscriptionInterval.year &&
                  !forStudentDiscount &&
                  isNewPriceApplied && (
                    <>
                      {getPercentOff(userCurrency)}
                      {t('% off, limited time only. First 7 days free. ')}
                    </>
                  )}

                {interval === SubscriptionInterval.month &&
                  (isNewPriceApplied && !forStudentDiscount
                    ? t('First 7 days free.')
                    : t(
                        `${getDisplayPrice(
                          userCurrency,
                          productPrice.amount
                        )}/month`
                      ))}
              </>
            )}
          </p>
        </div>

        {selectorStyle === SubscriptionPlanSelectorStyle.choosePlan &&
          !isUserComingFromWorkshop &&
          !isMobile && (
            <div
              className={c(
                'absolute absolute--bottom-center animate-opacity u-z-index-2',
                {
                  'opacity-1': selectedSubscriptionInterval === interval,
                  'opacity-0 pointer-events-none':
                    selectedSubscriptionInterval !== interval,
                }
              )}
              style={{ transform: 'translate(-50%, 50%)' }}
            >
              {this.renderButtonChoosePlanContinue(interval, user, variant)}
            </div>
          )}
      </button>
    );
  };

  public render = () => {
    const {
      className = '',
      selectorStyle,
      sizing: { isMobile },
      selectedSubscriptionInterval = SubscriptionInterval.year,
      auth: { user },
      workshopVariantOneStep,
      abtest: { variant },
    } = this.props;

    const isUserComingFromWorkshop = window.location.href.includes(
      '?to=%2Fworkshops%2F'
    );

    return (
      <div
        className={c('', { 'u-7/8': isUserComingFromWorkshop && !isMobile })}
      >
        <div
          className={c(['u-white u-flex', className], {
            'u-flex-column':
              selectorStyle ===
                SubscriptionPlanSelectorStyle.loggedOutHomepageHorizontal ||
              (selectorStyle === SubscriptionPlanSelectorStyle.choosePlan &&
                !isUserComingFromWorkshop) ||
              isMobile,
          })}
        >
          {this.renderIntervalSelector({ interval: SubscriptionInterval.year })}
          {variant !== 1 &&
            this.renderIntervalSelector({
              interval: SubscriptionInterval.month,
            })}

          {isMobile &&
            !isUserComingFromWorkshop &&
            selectorStyle === SubscriptionPlanSelectorStyle.choosePlan && (
              <div className="mt32 u-1/1 u-flex u-justify-center">
                {this.renderButtonChoosePlanContinue(
                  selectedSubscriptionInterval,
                  user,
                  variant
                )}
              </div>
            )}
        </div>

        {isUserComingFromWorkshop && (
          <div className="mt32 u-1/1 u-flex u-justify-center">
            {this.renderButtonChoosePlanContinue(
              selectedSubscriptionInterval,
              user,
              workshopVariantOneStep,
              isUserComingFromWorkshop,
              isMobile,
              variant
            )}
          </div>
        )}
      </div>
    );
  };
}

export const SubscriptionPlanSelector = connect(
  ['subscription', 'auth', 'sizing', 'abtest'],
  () => ({})
)(SubscriptionPlanSelectorComp);
