import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Dropdown } from '@/components/global/Dropdown/Dropdown';
import { Toast } from '@/components/global/Toaster/Toast';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { COUNTRY_CODES_FETCH_FAIL } from '@/messages/errors';

import { Http } from '@/utils/api/Http';
import { c } from '@/utils/strings/c';
import { getRouteFromUrl } from '@/utils/urls/routes';

interface Props extends RouteComponentProps {
  chosenCountry: string;
  switchToChosenCountry: (countryCallingCode: string) => void;
  usernameState?: string;
}

interface State {
  data: APICountryCode[];
}

class PhoneNumberCodeComponent extends React.Component<Props, State> {
  public state: State = {
    data: [],
  };

  public componentDidMount = async () => {
    await this.fetchPhoneCountryCodes();
  };

  public fetchPhoneCountryCodes = async () => {
    try {
      const { data } = await new Http(
        '/phoneCountryCodes'
      ).get<APICountryCodes>();
      this.setState({ data: data });
    } catch (e) {
      new Toast({
        body: COUNTRY_CODES_FETCH_FAIL,
        failure: true,
      }).dispatch();
    }
  };

  public render = () => {
    const { data } = this.state;
    const {
      chosenCountry,
      location: { pathname },
      usernameState,
    } = this.props;

    const matchedRoute = getRouteFromUrl(pathname);

    const isRegisterPage = matchedRoute === 'register';
    const isOptimisedJoinPage: boolean =
      matchedRoute === 'optimisedJoinFlowSignUpMonthly' ||
      matchedRoute === 'optimisedJoinFlowSignUpYearly' ||
      matchedRoute === 'studentsSignUpMonthly' ||
      matchedRoute === 'studentsSignUpYearly';
    const isLoginPage = matchedRoute === 'login';
    const isHomePage = matchedRoute === 'home';

    const darkMode: boolean =
      isRegisterPage || isLoginPage || isHomePage || isOptimisedJoinPage;

    return (
      <Dropdown
        trigger={
          <Button
            type={ButtonType.action}
            onClick={() => true}
            size={ButtonSize.s}
            buttonStyle={ButtonStyle.transparent}
            text={`+${chosenCountry}`}
            iconId="chevron-down"
            safariTransformFix={true}
            className={c('c-dropdown--phone-code-button', {
              'u-devil-grey': usernameState,
            })}
            tabIndex={0}
          />
        }
        id="calling-code-picker"
        side="left"
        width={
          pathname.includes('rooms') ||
          pathname.includes('join') ||
          pathname.includes('login')
            ? 'sm'
            : 'small'
        }
        className="c-dropdown--phone-code"
        darkMode={darkMode}
      >
        {({ close }: { close: () => void }) => (
          <div className="c-picker c-dropdown--phone-code-list">
            <ul className="dds-dropdown-menu">
              {data.map((d: APICountryCode) => (
                <li key={d.id}>
                  <button
                    type="button"
                    onClick={() => {
                      close();
                      this.props.switchToChosenCountry(
                        d.attributes.callingCode
                      );
                    }}
                  >
                    <div className="u-flex u-justify-between">
                      <div className="f-text-3">
                        <span className="pr8">{d.attributes.countryFlag}</span>
                        <span>{d.attributes.countryName}</span>
                      </div>
                      <span className="f-text-3">{`+${d.attributes.callingCode}`}</span>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </Dropdown>
    );
  };
}

export const PhoneNumberCode = withRouter(PhoneNumberCodeComponent);
