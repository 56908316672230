export const isMobile = () => {
  if (!window.matchMedia) return false;
  return window.matchMedia('(max-width: 45em)').matches;
};

export const isTabletOrDesktop = () => {
  if (!window.matchMedia) return true;
  return window.matchMedia('(min-width: 45em)').matches;
};

export const isDesktop = () => {
  if (!window.matchMedia) return true;
  return window.matchMedia('(min-width: 60em)').matches;
};

export const isChatMobile = () => {
  if (!window.matchMedia) return false;
  return window.matchMedia('(max-width: 768px)').matches;
};

export const getCookieBannerHeight = (): number => {
  const cookieBanner = document.querySelector('.c-cookie-consent');
  return cookieBanner ? cookieBanner.getBoundingClientRect().height : 0;
};

export const getDownloadAppBannerHeight = (): number => {
  const downloadAppBanner = document.querySelector('.c-download-app-banner');
  return downloadAppBanner
    ? downloadAppBanner.getBoundingClientRect().height
    : 0;
};

export const getDiscordBannerHeight = (): number => {
  const downloadAppBanner = document.querySelector('.c-discord-banner');
  return downloadAppBanner
    ? downloadAppBanner.getBoundingClientRect().height
    : 0;
};

export const distanceFromMaxWrapWidth = (): number =>
  // Get value from .wrap in grid.scss
  window.innerWidth - 1440 - 40;
