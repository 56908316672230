import { Language } from '@/enums/Language';

import { CustomDate } from '@/utils/class-extensions/Date';
import { HOURS, MINUTES } from '@/utils/constants';
import { getLanguage } from '@/utils/i18n/getLanguage';
import { t } from '@/utils/i18n/i18n';
import { pluralise } from '@/utils/strings/string-manipulation';

const months = [
  t('January'),
  t('Feburary'),
  t('March'),
  t('April'),
  t('May'),
  t('June'),
  t('July'),
  t('August'),
  t('September'),
  t('October'),
  t('November'),
  t('December'),
];

const shortMonths = [
  t('Jan'),
  t('Feb'),
  t('Mar'),
  t('Apr'),
  t('May'),
  t('Jun'),
  t('Jul'),
  t('Aug'),
  t('Sep'),
  t('Oct'),
  t('Nov'),
  t('Dec'),
];

const addLeadingZero = (n: number): string => `0${n}`.slice(-2);

const getFormattedTime = (d: string): string =>
  [new Date(d).getHours(), new Date(d).getMinutes()]
    .map((n) => addLeadingZero(n))
    .join(':');

const dayMonthCommaYear = (d: string | Date): string => {
  const date = typeof d === 'string' ? new Date(d) : d;
  const day = date.getDate();
  const month = months[date.getMonth()].slice(0, 3);
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

const renderTimeLabel = (createdAt?: string) => {
  if (createdAt) {
    const date = new CustomDate(createdAt);
    const dateObject = date.getDateObject();
    const digitalTime = `${dateObject.hours}:${dateObject.minutes}`;

    if (date.isJustNow()) {
      return `Just now`;
    }

    if (date.isToday()) {
      return `Today at ${digitalTime}`;
    }

    if (date.isYesterday()) {
      return `Yesterday at ${digitalTime}`;
    }

    if (date.getFullYear() !== new Date().getFullYear()) {
      return `${dateObject.shortMonth} ${dateObject.dayDate} ${dateObject.year} at ${digitalTime}`;
    }

    return `${dateObject.shortMonth} ${dateObject.dayDate} at ${digitalTime}`;
  }

  return '';
};

const renderDateLabel = (createdAt?: string) => {
  if (createdAt) {
    const date = new CustomDate(createdAt);
    const dateObject = date.getDateObject();

    if (date.isJustNow()) {
      return `Just now`;
    }

    if (date.isToday()) {
      return `Today`;
    }

    if (date.isYesterday()) {
      return `Yesterday`;
    }

    return `${dateObject.shortMonth} ${dateObject.dayDate}`;
  }

  return '';
};

const getTimeAgoStamp = (time?: string) => {
  if (time) {
    const now = new Date();
    const nowInSeconds = now.getTime() / 1000;
    const then = new CustomDate(time);
    const thenInSeconds = then.getTime() / 1000;

    const secondsInAMinute = 60;
    const secondsInAnHour = 3600;
    const secondsInADay = 86400;
    const secondsInAYear = 31536000;

    const timeAgo = Math.round(nowInSeconds - thenInSeconds);

    if (timeAgo < secondsInAMinute) {
      return `${timeAgo}s`;
    } else if (timeAgo < secondsInAnHour) {
      return `${Math.round(timeAgo / secondsInAMinute)}m`;
    } else if (timeAgo < secondsInADay) {
      return `${Math.round(timeAgo / secondsInAnHour)}h`;
    } else if (timeAgo < secondsInAYear) {
      return `${Math.round(timeAgo / secondsInADay)}d`;
    }

    return `${Math.round(timeAgo / secondsInAYear)}y`;
  }

  return '';
};

const startsInNextHour = (startTime: string) => {
  const now = new Date().getTime();
  const start = new Date(startTime).getTime();

  return start - now < HOURS;
};

const startsInNextFiveMinutes = (startTime: string) => {
  const now = new Date().getTime();
  const start = new Date(startTime).getTime();

  return start - now < 5 * MINUTES;
};

const getEventTimeRemaining = (endTime: string) => {
  const now = new Date().getTime();
  const end = new Date(endTime).getTime();

  return Math.floor((end - now) / 60000);
};

const isInProgress = (startTime: string, endTime: string) => {
  const now = new Date().getTime();
  const start = new Date(startTime).getTime();
  const end = new Date(endTime).getTime();

  return start < now && now < end;
};

const startsInNMinutes = (startTime: string) => {
  const now = new Date().getTime();
  const start = new Date(startTime).getTime();

  return Math.round((start - now) / MINUTES);
};

const getTimezoneAbbreviation = (d: string | Date): string => {
  const longTimezone = getTimezoneFull(d);

  return longTimezone
    .split(' ')
    .map((token: string) => token[0])
    .join('');
};

const getTimezoneFull = (d: string | Date): string => {
  const date = typeof d === 'string' ? new Date(d) : d;

  if (!d) {
    return '';
  }

  const dateString = date.toLocaleTimeString('en-US', { timeZoneName: 'long' });

  return dateString.includes('AM')
    ? dateString.split('AM')[1].trim()
    : dateString.split('PM')[1].trim();
};

// Shows a localised date/time with the timezone abbreviation,
// showing today/tomorrow if applicable
const renderEventDateTime = (
  d: string | Date,
  options?: { lowercase?: boolean; atSeparator?: boolean }
): string => {
  const now = new Date();

  const date = new CustomDate(d);
  const dateObject = date.getDateObject();

  let dateSection = formatDate(dateObject);

  if (date.isToday()) {
    dateSection = options && options.lowercase ? t('today') : t('Today');
  } else if (date.isTomorrow()) {
    dateSection = options && options.lowercase ? t('tomorrow') : t('Tomorrow');
  } else if (dateObject.year !== now.getFullYear()) {
    dateSection = `${dateSection} ${dateObject.year}`;
  }

  const atSeparator = options && options.atSeparator ? ` ${t('at')}` : ',';

  return `${dateSection}${atSeparator} ${formatTime(
    dateObject
  )} ${getTimezoneAbbreviation(date)}`;
};

const formatTime = (dateTime: DateObject): string => {
  const useUSFormat = getLanguage() === Language.enUS;

  if (useUSFormat) {
    return `${dateTime.hours12h}:${dateTime.minutes} ${dateTime.ampm}`;
  } else {
    return `${dateTime.hours}:${dateTime.minutes}`;
  }
};

const formatDate = (dateTime: DateObject, useLongMonth?: boolean): string => {
  const useUSFormat = getLanguage() === Language.enUS;

  if (useUSFormat) {
    // Format like `Aug 28`
    return `${useLongMonth ? dateTime.month : dateTime.shortMonth} ${
      dateTime.dayDate
    }`;
  } else {
    // Format like `28 Aug`
    return `${dateTime.dayDate} ${
      useLongMonth ? dateTime.month : dateTime.shortMonth
    }`;
  }
};

// Shows date/times with → in between, with optional parameters
// to hide the time if the range spans across multiple days
// or hide the end time if the range covers a single day
const renderEventDateTimeRange = ({
  start,
  end,
  hideTimeIfMultipleDays,
  hideEndTime,
}: {
  start: string | Date;
  end?: string | Date;
  hideTimeIfMultipleDays?: boolean;
  hideEndTime?: boolean;
}) => {
  const separator = '→';

  const now = new Date();

  const startDate = new CustomDate(start);
  const startDateObject = startDate.getDateObject();
  const startDateTimezone = getTimezoneAbbreviation(startDate);

  if (end) {
    const endDate = new CustomDate(end);
    const endDateObject = endDate.getDateObject();
    const endDateTimezone = getTimezoneAbbreviation(endDate);

    const isStartEndSameDay =
      startDateObject.dayDate === endDateObject.dayDate &&
      startDateObject.month === endDateObject.month &&
      startDateObject.year === endDateObject.year;

    const isYearDifferent =
      startDateObject.year !== endDateObject.year ||
      startDateObject.year !== now.getFullYear();

    const isTimezonesDifferent = startDateTimezone !== endDateTimezone;

    if (isStartEndSameDay) {
      let result = formatDate(startDateObject);

      if (isYearDifferent) {
        result = `${result} ${startDateObject.year}`;
      }
      if (hideEndTime) {
        result = `${result} • ${formatTime(
          startDateObject
        )} ${startDateTimezone}`;
      } else {
        result = `${result} • ${formatTime(
          startDateObject
        )} ${separator} ${formatTime(endDateObject)} ${startDateTimezone}`;
      }

      return result;
    } else {
      let result = formatDate(startDateObject);

      if (isYearDifferent) {
        result = `${result} ${startDateObject.year}`;
      }

      if (!hideTimeIfMultipleDays) {
        result = `${result} • ${formatTime(startDateObject)}`;

        if (isTimezonesDifferent) {
          result = `${result} ${startDateTimezone}`;
        }
      }

      result = `${result} ${separator} ${formatDate(endDateObject)}`;

      if (isYearDifferent) {
        result = `${result} ${endDateObject.year}`;
      }

      if (!hideTimeIfMultipleDays) {
        result = `${result} • ${formatTime(endDateObject)} `;

        result = `${result}${
          isTimezonesDifferent ? endDateTimezone : startDateTimezone
        }`;
      }

      return result;
    }
  } else {
    return `${formatDate(startDateObject)}, ${formatTime(
      startDateObject
    )} ${startDateTimezone}`;
  }
};

// For showing date/times in the correct format,
// depending on the amount of batches in a workshop
const renderDateLabelForWorkshopBatches = (
  batches: WorkshopBatch[],
  type: 'short' | 'long'
): string | undefined => {
  const availableBatches = batches.filter((b: WorkshopBatch) => !b.hasElapsed);

  if (availableBatches.length === 0) {
    return renderEventDateTimeRange({
      start: batches[0].start,
      end: batches[batches.length - 1].end,
      hideTimeIfMultipleDays: true,
      hideEndTime: type === 'short',
    });
  }

  if (availableBatches.length === 0) {
    return undefined;
  } else if (availableBatches.length === 1) {
    return renderEventDateTimeRange({
      start: availableBatches[0].start,
      end: availableBatches[0].end,
      hideTimeIfMultipleDays: true,
      hideEndTime: type === 'short',
    });
  } else {
    return `${availableBatches.length} ${pluralise(
      'session',
      availableBatches.length
    )}`;
  }
};

const renderBulletTimestamp = (date: Date) => {
  const dateCustomDate = new CustomDate(date);
  const dateObject = dateCustomDate.getDateObject();
  const dateTimezone = getTimezoneAbbreviation(dateCustomDate);

  return `${formatDate(dateObject, true)} • ${formatTime(
    dateObject
  )} ${dateTimezone}`;
};

export {
  addLeadingZero,
  getFormattedTime,
  dayMonthCommaYear,
  getTimeAgoStamp,
  startsInNextHour,
  startsInNextFiveMinutes,
  isInProgress,
  formatTime,
  startsInNMinutes,
  months,
  shortMonths,
  renderDateLabel,
  renderTimeLabel,
  getTimezoneAbbreviation,
  getTimezoneFull,
  renderEventDateTime,
  renderEventDateTimeRange,
  renderDateLabelForWorkshopBatches,
  renderBulletTimestamp,
  getEventTimeRemaining,
};
