import * as React from 'react';

import { connect } from 'unistore/react';

import { sizingActions } from '@/store/modules/sizing';

interface Props extends SizingState, SizingActions {}

class ResponsiveObserverComponent extends React.Component<Props> {
  public componentDidMount = () => {
    this.props.updateSizing();

    window.addEventListener('resize', this.onResize);
  };

  public componentWillUnmount = () => {
    window.removeEventListener('resize', this.onResize);
  };

  private onResize = () => {
    this.props.updateSizing();
  };

  public render = () => null;
}

export const ResponsiveObserver = connect(
  ['sizing'],
  (store: GlobalStoreState) => ({
    ...sizingActions(store),
  })
)(ResponsiveObserverComponent);
