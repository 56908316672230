import { routes } from '@/routes';
import { BOGO_END_DATE } from '@/utils/constants';

const abtestState: ABTestState = {
  abtest: {
    variant: undefined,
  },
};

const getRandomIntForVariant = (max: number) => Math.floor(Math.random() * max);

const abtestActions = () => ({
  getOrSetVariant: async () => {
    let variant: number = 0;

    // 0 = Variant A Monthly and Annual subscriptions (No discount)
    // 1 = Variant B BOGO (Annual subscription + 1 free sub for a friend)
    // 2 = Variant C (40% off) (Not in use)

    // After the BOGO offer ends, please use variant 0 on all routes
    // or create an additional variant when needed
    // variant 1 should be reserved for BOGO only

    const localStorageABVariant = localStorage.getItem('ab_test_variant');
    // Discontinue discount variant for now. Dec 2022
    // TODO: Clean up logic where variant === 2
    // const discountURL: boolean = window.location.pathname.includes('discount');
    // if (discountURL) {
    //   variant = 2;
    //   localStorage.setItem('ab_test_variant', variant.toString());
    // }
    const isBogoOfferRunning = new Date() <= BOGO_END_DATE;

    if (localStorageABVariant === null) {
      // The homepage will force variant 1
      // variant 1 will be used for a BOGO offer (Dec 2022)
      // Any other web entry points will force variant 0 - annual and monthly subscriptions

      if (window.location.pathname === routes.home && isBogoOfferRunning) {
        variant = 1;
      }
      localStorage.setItem('ab_test_variant', variant.toString());
    } else {
      variant = Number(localStorageABVariant);

      // If the BOGO offer ends or the user is on the old variant 2, move them to variant 0
      if ((!isBogoOfferRunning && variant === 1) || variant === 2) {
        localStorage.setItem('ab_test_variant', '0');
        variant = 0;
      }
    }

    return {
      abtest: {
        variant,
      },
    };
  },

  forceVariant: async (state: any, variant: number) => {
    localStorage.setItem('ab_test_variant', variant.toString());

    return {
      abtest: {
        variant,
      },
    };
  },
});

export { abtestActions, abtestState };
