import * as React from 'react';

import { Link } from '@/components/global/Link/Link';
import { BaseNotification } from '@/components/notifications/BaseNotification';

import { t } from '@/utils/i18n/i18n';

interface Props {
  className?: string;
  notification: DaisieNotification;
  parentProject?: Project;
  isDarkMode: boolean;
  onClickToggle: () => void;
}

const CollaboratorAcceptedAll: React.FunctionComponent<Props> = (
  props: Props
) => {
  const {
    className = '',
    notification,
    parentProject,
    isDarkMode,
    onClickToggle,
  } = props;

  const project = notification.project || parentProject;

  if (!project || !notification.collaborationRequests) {
    return null;
  }

  const primary = (
    <>
      {t('accepted all ')}
      {notification.collaborationRequests.length} {t('requests to join ')}
      <Link
        to={{
          name: 'room',
          params: { roomSlug: project.slug },
        }}
      >
        {project.title}
      </Link>
    </>
  );

  return (
    <BaseNotification
      notification={notification}
      className={className}
      primary={primary}
      isDarkMode={isDarkMode}
      onClickToggle={onClickToggle}
      secondary={({ date }: { date: string }) => date}
      icon="tick"
    />
  );
};

export { CollaboratorAcceptedAll };
