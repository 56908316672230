import React from 'react';

import Bowser from 'bowser';

import { Icon } from '@/components/global/Icon/Icon';
import { MobileOS } from '@/components/global/PreMobileWall/MobileOS';

import { GtmEvent } from '@/enums/GtmEvent';
import { IconSize } from '@/enums/IconSize';

import { APP_STORE_LINK } from '@/utils/constants';
import { sendGtmEvent } from '@/utils/gtm/sendGtmEvent';
import { t } from '@/utils/i18n/i18n';

interface Props {
  route?: string;
  hideDownloadAppBanner: (scrollTo?: number) => void;
}

interface State {
  os?: MobileOS;
}

class DownloadAppBanner extends React.Component<Props, State> {
  public state: State = {
    os: undefined,
  };

  public componentDidMount = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);

    // The app banner shouldn't be displayed on Android
    if (browser.getOS().name === 'Android') {
      this.dismiss();
    }

    this.setState({
      os: browser.getOS().name === 'Android' ? MobileOS.android : MobileOS.ios,
    });
  };

  private dismiss = () => {
    // Remove style from nav-overlay
    const navOverlay = document.querySelector('.nav-overlay') as HTMLDivElement;

    if (navOverlay) {
      navOverlay.style.cssText = '';
    }

    // Save scroll position of container
    const pageLayout = document.querySelector(
      '.page-layout--download-app-banner'
    ) as HTMLDivElement;

    const scrollTo = pageLayout ? pageLayout.scrollTop : undefined;

    this.props.hideDownloadAppBanner(scrollTo);
  };

  public render() {
    const { route } = this.props;
    const { os } = this.state;

    if (os === MobileOS.android) return null;

    return (
      <aside
        className="c-download-app-banner u-1/1 ph20 pv12 absolute absolute--t"
        data-key-route={route}
      >
        <div className="u-flex u-align-center u-justify-between">
          <button
            type="button"
            className="u-flex"
            onClick={() => {
              this.dismiss();
            }}
          >
            <Icon id="clear" size={IconSize.xs} />
          </button>

          <p className="f-text-3 mh16">
            {t('Download Daisie for the full experience')}
          </p>

          <a
            href={APP_STORE_LINK}
            onClick={() => sendGtmEvent(GtmEvent.DownloadApp)}
            target="_blank"
            rel="noopener noreferrer"
            className="dds-button dds-button--dark dds-button--dark--size-s dds-button--size-s dds-button--link"
          >
            <p className="u-flex u-align-center u-justify-center u-1/1 animate-opacity f-text-3 opacity-1">
              <span>{t('Install')}</span>
              <Icon id="apple" size={IconSize.xs} className="ml8" />
            </p>
          </a>
        </div>
      </aside>
    );
  }
}

export { DownloadAppBanner };
