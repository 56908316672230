import * as React from 'react';

import { Icon } from '@/components/global/Icon/Icon';
import { Link } from '@/components/global/Link/Link';

import { IconSize } from '@/enums/IconSize';

import { parser } from '@/routes/parser';

interface Props {
  collection: Collection;
}

class CollectionCard extends React.Component<Props> {
  public render = () => {
    const {
      collection: { id: collectionId, name, mediaCount },
    } = this.props;

    return (
      <Link
        to={parser({ name: 'collection', params: { collectionId } })}
        className="c-collection-card p32"
      >
        <p className="f-text-2 mb16 u-flex u-align-center">
          <Icon id="collection" size={IconSize.s} className="mr8" />
          <span>{mediaCount}</span>
        </p>
        <h2 className="f-text-1 u-bold">{name}</h2>
      </Link>
    );
  };
}

export { CollectionCard };
