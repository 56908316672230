import React, { useState } from 'react';

import { Box, Flex, FlexProps, Text } from '@chakra-ui/react';

import { colors } from '@/styles/chakra-theme/colors';
import { pxToRem } from '@/styles/chakra-theme/metrics';

import { StarIcon } from '../../atoms';

interface Props extends FlexProps {
  rating: number;
  text: string;
  subtext: string;
}

export const AssessmentCard = ({ rating, text, subtext, ...props }: Props) => {
  const [spinMultiplier, setSpinMultiplier] = useState<number>(0);

  setInterval(() => {
    setSpinMultiplier(
      (document.querySelector('.wrapper')?.scrollTop || 0) / 100
    );
  }, 1);

  const decimals = rating - Math.floor(rating);
  const decimalsPercent = decimals * 100;

  return (
    <Flex
      direction={['column', 'column', 'row']}
      align={['flex-start', 'center']}
      bg={['transparent', 'linear-gradient(180deg, #8543E3 0%, #633BEB 100%)']}
      borderWidth={1}
      borderColor="rgb(255 255 255 / 15%)"
      {...props}
    >
      <Text
        fontSize={{ base: 80, xl: 100 }}
        fontWeight={400}
        color="white"
        bgColor={[colors.purpleRain, 'transparent']}
        px={[pxToRem(40), pxToRem(28)]}
        py={[pxToRem(40), 0]}
        minW={{
          base: 232,
          md: 219,
          xl: (window.innerWidth / 12) * 2,
          '2xl': (window.innerWidth / 16) * 2,
        }}
        textAlign="center"
      >
        {rating}
      </Text>

      <Box
        borderLeftWidth={1}
        borderColor="rgb(255 255 255 / 15%)"
        p={pxToRem(12)}
        bgColor={[colors.purpleRain, 'transparent']}
        height="100%"
      >
        <Text fontSize={[24, 24, 20, 32]} color="white" fontWeight={400}>
          {text}
        </Text>

        <Flex
          mt={{
            base: pxToRem(18),
            md: pxToRem(12),
            lg: pxToRem(18),
            xl: pxToRem(16),
            '2xl': pxToRem(18),
          }}
        >
          {Array.from({ length: 5 }).map((_, i) => (
            <StarIcon
              key={i}
              fontSize={56}
              // transform={`rotate(${-10 * spinMultiplier}deg)`}
              className="spin"
            />
          ))}
        </Flex>

        <Text
          fontSize={[14, 20]}
          fontWeight={400}
          color="white"
          opacity={0.5}
          mt={{
            base: pxToRem(18),
            md: pxToRem(12),
            lg: pxToRem(18),
            xl: 0,
            '2xl': pxToRem(18),
          }}
        >
          {subtext}
        </Text>
      </Box>
    </Flex>
  );
};
