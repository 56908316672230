import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'unistore/react';
import widont from 'widont';

import { Head } from '@/components/global/Head/Head';
import { Link } from '@/components/global/Link/Link';
import { AnimatedWordmark } from '@/components/global/Wordmark/AnimatedWordmark';
import { Wordmark } from '@/components/global/Wordmark/Wordmark';
import {
  AB_TEST_DISCOUNT_PERCENT_OFF_ACTUAL_GBP_EUR,
  AB_TEST_DISCOUNT_PERCENT_OFF_ACTUAL_USD,
  GRANDFATHER_DISCOUNT_PERCENT_OFF_MONTHLY_ACTUAL_GBP_EUR,
  GRANDFATHER_DISCOUNT_PERCENT_OFF_MONTHLY_ACTUAL_USD,
  GRANDFATHER_DISCOUNT_PERCENT_OFF_YEARLY_ACTUAL_GBP_EUR,
  GRANDFATHER_DISCOUNT_PERCENT_OFF_YEARLY_ACTUAL_USD,
  STUDENT_DISCOUNT_PERCENT_OFF_ACTUAL_GBP_EUR,
  STUDENT_DISCOUNT_PERCENT_OFF_ACTUAL_USD,
  TRIAL_LENGTH_TEXT_PLURAL,
  TRIAL_LENGTH_TEXT_SINGULAR,
} from '@/components/subscription/constants';

import { CheckedBulletList } from '@/components/design_system/CheckedBulletList/CheckedBulletList';

import { Currency } from '@/enums/Currency';
import { DaisieWordmarkColour } from '@/enums/DaisieWordmarkColour';
import { SubscriptionInterval } from '@/enums/SubscriptionInterval';

import { getSubscriptionIntervalFromMatchedRoute } from '@/pages/OptimisedJoinFlow/helpers';

import { routes } from '@/routes';

import { findProductAndPrice } from '@/store/helpers/subscriptionHelpers';

import { REVIEW_RATING, REVIEW_SAMPLE_SIZE } from '@/utils/constants';
import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';
import { getDisplayPrice } from '@/utils/strings/currency';
import { getRouteFromUrl } from '@/utils/urls/routes';

interface Props
  extends SubscriptionState,
    ABTestState,
    AuthState,
    SizingState,
    RouteComponentProps {
  headTitle?: string;
  hideLogo?: boolean;
  className?: string;
  isOptimisedJoinFlowSignUp?: boolean;
  isFreeWorkshop?: boolean;
}

class OptimisedJoinFlowFrameComp extends React.Component<Props> {
  public render = () => {
    const {
      children,
      headTitle,
      hideLogo = false,
      className = '',
      auth: { user },
      isOptimisedJoinFlowSignUp,
      isFreeWorkshop,
    } = this.props;

    const {
      subscription: { productsAndPrices, userCurrency },
      abtest: { variant },
      location: { pathname },
      sizing: { isMobile },
    } = this.props;

    let promotionalCode = localStorage.getItem('promotionalCode');

    if (promotionalCode) {
      promotionalCode = JSON.parse(promotionalCode);
    }

    const matchedRoute = getRouteFromUrl(pathname);

    const isUserComingFromWorkshop = window.location.href.includes(
      '?to=%2Fworkshops%2F'
    );
    const isRouteIncludesPay =
      matchedRoute === 'optimisedJoinFlowSignUpMonthlyPay' ||
      matchedRoute === 'optimisedJoinFlowSignUpYearlyPay';

    const workshopSubscriptionVariant = Number(
      localStorage.getItem('workshop_subscription_variant')
    );

    const isWorkshopVariantOne = isMobile && workshopSubscriptionVariant === 1;

    const subscriptionInterval: SubscriptionInterval | undefined =
      getSubscriptionIntervalFromMatchedRoute(matchedRoute);

    if (!subscriptionInterval || typeof variant === 'undefined') {
      return null;
    }

    const productPrice: SubscriptionProductPrice | undefined =
      findProductAndPrice({
        productsAndPrices,
        currency: userCurrency,
        recurringInterval: subscriptionInterval,
      });

    if (!productPrice) {
      return null;
    }

    const isGrandfathered: boolean = !!user && user.isGrandfathered;

    const hasStudentDiscount: boolean =
      matchedRoute === 'studentsSignUpYearly' ||
      matchedRoute === 'studentsSignUpYearlyPay';

    // Variant = 2 is the 40% discount variant, only applies to annual subscriptions
    const hasABTestDiscount: boolean =
      variant === 2 &&
      subscriptionInterval === SubscriptionInterval.year &&
      !hasStudentDiscount;

    const studentDiscountPercentOff: number =
      userCurrency === 'usd'
        ? STUDENT_DISCOUNT_PERCENT_OFF_ACTUAL_USD
        : STUDENT_DISCOUNT_PERCENT_OFF_ACTUAL_GBP_EUR;

    let displayAmount: number =
      isGrandfathered || variant === 1
        ? productPrice.amount
        : hasStudentDiscount
        ? productPrice.amount * (1 - studentDiscountPercentOff / 100)
        : hasABTestDiscount
        ? userCurrency === 'usd'
          ? productPrice.amount *
            (1 - AB_TEST_DISCOUNT_PERCENT_OFF_ACTUAL_USD / 100)
          : productPrice.amount *
            (1 - AB_TEST_DISCOUNT_PERCENT_OFF_ACTUAL_GBP_EUR / 100)
        : 0;

    // Free with BOGO offer
    if (promotionalCode) {
      displayAmount = 0;
    }

    let pricingCaption: JSX.Element | null =
      hasStudentDiscount || hasABTestDiscount || isGrandfathered ? (
        <>
          {getDisplayPrice(userCurrency, displayAmount)} {t('billed')}{' '}
          {subscriptionInterval === SubscriptionInterval.year
            ? t('annually')
            : t('monthly')}
          {'. '}
          {t('Cancel anytime.')}
        </>
      ) : !!user && user.hasPastSub ? (
        <>
          {t('Daisie is ')}
          {getDisplayPrice(userCurrency, productPrice.amount)} {t('billed')}{' '}
          {subscriptionInterval === SubscriptionInterval.year
            ? t('annually.')
            : t('monthly.')}{' '}
          {t('Cancel anytime.')}
        </>
      ) : isUserComingFromWorkshop && isOptimisedJoinFlowSignUp ? (
        <>
          {t('Try Daisie for free, cancel anytime.')}{' '}
          {getDisplayPrice(userCurrency, productPrice.amount)} {t('billed')}{' '}
          {subscriptionInterval === SubscriptionInterval.year
            ? t('annually')
            : t('monthly')}{' '}
          {t('after')} {TRIAL_LENGTH_TEXT_SINGULAR} {t('free trial.')}{' '}
        </>
      ) : (
        <>
          {getDisplayPrice(userCurrency, productPrice.amount)} {t('billed')}{' '}
          {subscriptionInterval === SubscriptionInterval.year
            ? t('annually')
            : t('monthly')}{' '}
          {t('after')} {TRIAL_LENGTH_TEXT_SINGULAR} {t('free trial.')}{' '}
          <span className="u-bold">{t('Cancel anytime.')}</span>
        </>
      );

    // We don't need a caption for annual, no trial subscription
    if (variant === 1 && !promotionalCode) {
      pricingCaption = (
        <>{t('2 memberships for the price of 1 · Offer ends soon!')}</>
      );
    }

    if (promotionalCode) {
      pricingCaption = (
        <>
          {getDisplayPrice(userCurrency, 0)} {t('for the first 12 months · ')}
          {getDisplayPrice(userCurrency, productPrice.amount)}{' '}
          {t('/year after')}
        </>
      );
    }

    const getTitleTopLine = () => {
      if (isRouteIncludesPay && user?.hasPastSub) {
        return "Let's get started.";
      } else if (!isRouteIncludesPay && user?.hasPastSub) {
        return 'Learn from Industry experts';
      } else if (
        isUserComingFromWorkshop &&
        !user?.hasPastSub &&
        variant !== 1
      ) {
        return 'Get started ';
      } else if (variant === 1 && !isRouteIncludesPay && !promotionalCode) {
        // BOGO offer
        return '2 Memberships for the';
      } else if (variant === 1 && isRouteIncludesPay && !promotionalCode) {
        return '2 Memberships for';
      } else if (!isRouteIncludesPay && promotionalCode) {
        return 'Redeem your free Daisie Membership';
      } else {
        return 'Get the full Daisie experience for ';
      }
    };

    const title = (
      <h1
        className={c('', {
          'f-lynstone-bold-1a u-1/1 mb24': isUserComingFromWorkshop,
          'f-lynstone-bold-1b mb32 u-text-center@s': !isUserComingFromWorkshop,
        })}
      >
        {t(getTitleTopLine())}

        {(hasStudentDiscount || hasABTestDiscount) &&
          !isGrandfathered &&
          !user?.hasPastSub && (
            <span className="mr4 mr16@m u-line-through">
              {getDisplayPrice(userCurrency, productPrice.amount)}
            </span>
          )}

        {!(hasStudentDiscount || hasABTestDiscount) &&
          isGrandfathered &&
          subscriptionInterval === SubscriptionInterval.year && (
            <span className="mr4 mr16@m u-line-through">
              {getDisplayPrice(
                userCurrency,
                productPrice.amount /
                  (1 -
                    (userCurrency === Currency.usd
                      ? GRANDFATHER_DISCOUNT_PERCENT_OFF_YEARLY_ACTUAL_USD
                      : GRANDFATHER_DISCOUNT_PERCENT_OFF_YEARLY_ACTUAL_GBP_EUR) /
                      100)
              )}
            </span>
          )}

        {!(hasStudentDiscount || hasABTestDiscount) &&
          isGrandfathered &&
          subscriptionInterval === SubscriptionInterval.month && (
            <span className="mr4 mr16@m u-line-through">
              {getDisplayPrice(
                userCurrency,
                productPrice.amount /
                  (1 -
                    (userCurrency === Currency.usd
                      ? GRANDFATHER_DISCOUNT_PERCENT_OFF_MONTHLY_ACTUAL_USD
                      : GRANDFATHER_DISCOUNT_PERCENT_OFF_MONTHLY_ACTUAL_GBP_EUR) /
                      100)
              )}
            </span>
          )}

        {!user?.hasPastSub && (
          <span
            className={c('u-block@m', {
              'u-go f-lynstone-bold-1a u-block@s': !isUserComingFromWorkshop,
            })}
          >
            {variant === 1 && !isRouteIncludesPay && !promotionalCode ? (
              'Price of 1'
            ) : !isRouteIncludesPay && promotionalCode ? (
              ''
            ) : (
              <>
                {isUserComingFromWorkshop && t('for ')}
                {getDisplayPrice(userCurrency, displayAmount)}{' '}
                {t(`today${isMobile && isUserComingFromWorkshop ? '.' : ''}`)}
              </>
            )}
          </span>
        )}
      </h1>
    );

    return (
      <div
        className={c(['u-white', className], {
          'u-h-100vh@m': ![
            'optimisedJoinFlowSignUpYearly',
            'optimisedJoinFlowSignUpMonthly',
          ].includes(matchedRoute),
        })}
      >
        <Head
          title={headTitle}
          pathname={this.props.location.pathname}
          isNoindexPage={isRouteIncludesPay}
        />

        {!hideLogo && !isMobile && (
          <div
            className="wrap u-1/1 absolute absolute--x-center u-z-index-2 u-flex u-align-center u-justify-center"
            style={{ height: 72 }}
          >
            <Link to={routes.home}>
              <Wordmark
                colour={DaisieWordmarkColour.White}
                height="20px"
                className="u-hide@m absolute absolute--x-center"
              />
              <AnimatedWordmark
                className="u-hide@s"
                colour={DaisieWordmarkColour.White}
              />
            </Link>
          </div>
        )}

        <div className="u-h-100@m u-flex@m u-flex-column u-align-center u-justify-center">
          <div
            className={c('wrap u-1/1 u-flex@m mt32 mt64@m', {
              'pt60@s': matchedRoute.includes('Pay'),
              'pt8@s':
                !matchedRoute.includes('Pay') && isUserComingFromWorkshop,
            })}
            style={{
              minHeight: !isMobile ? '70%' : undefined,
              justifyContent: 'flex-end',
            }}
          >
            {!isUserComingFromWorkshop ? (
              <div
                className={c(
                  'u-1/1 u-1/2@m pr128@m u-flex u-align-center u-justify-center',
                  {
                    'br--very-translucent-white':
                      !isMobile && !isUserComingFromWorkshop,
                  }
                )}
              >
                <div className="u-1/1">
                  {title}
                  <p className="u-grey mb32 u-text-center u-hide@m">
                    {pricingCaption}
                  </p>

                  <CheckedBulletList
                    className="mb32 u-hide@s"
                    items={[
                      {
                        text: t(
                          `Rated ${REVIEW_RATING} out of 5 by ${REVIEW_SAMPLE_SIZE.toLocaleString()}+ creators`
                        ),
                        isBold: true,
                      },
                      {
                        text: t(
                          'Join unlimited classes taught by industry-leading creators'
                        ),
                      },
                      {
                        text: t(
                          'Meet likeminded filmmakers, musicians, artists, and more'
                        ),
                      },
                      {
                        text: t(
                          'Build your own creative schedule with workshop recordings and recaps'
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            ) : null}
            <div
              className={c(
                'u-1/1 u-1/2@m u-flex u-align-center u-justify-center u-z-index-1',
                {
                  'u-flex-column': isOptimisedJoinFlowSignUp,
                }
              )}
            >
              {isOptimisedJoinFlowSignUp ? (
                <div className="u-1/1">
                  {isFreeWorkshop ? '' : title}
                  {!isWorkshopVariantOne && isMobile ? (
                    <CheckedBulletList
                      className="mb32"
                      items={[
                        {
                          text: t('Live & interactive daily classes'),
                        },
                        {
                          text: t('Unlimited access to 100s of instructors'),
                        },
                        {
                          text: t('Learn alongside likeminded creators'),
                        },
                      ]}
                    />
                  ) : (
                    <p
                      className={c('mb24@s u-grey u-7/8@m', {
                        'f-lynstone-book-2a c-join-flow__frame--caption':
                          !isMobile,
                      })}
                    >
                      {isFreeWorkshop ? '' : pricingCaption}
                    </p>
                  )}
                </div>
              ) : null}

              {children}

              {isUserComingFromWorkshop && isMobile && (
                <div
                  className={c('u-grey mb44 f-text-5 u-text-center', {
                    mt24: isRouteIncludesPay,
                  })}
                >
                  {t('First')} {TRIAL_LENGTH_TEXT_PLURAL} {t('free, then ')}{' '}
                  {getDisplayPrice(userCurrency, productPrice.amount)}{' '}
                  {t('billed')}{' '}
                  {subscriptionInterval === SubscriptionInterval.year
                    ? t('annually.')
                    : t('monthly.')}{' '}
                  <span>{widont(t('Cancel anytime.'))}</span>
                </div>
              )}
            </div>
          </div>

          {!isUserComingFromWorkshop && (
            <p className="u-grey mt16 u-hide@s">{pricingCaption}</p>
          )}
        </div>
      </div>
    );
  };
}

export const OptimisedJoinFlowFrame = connect(
  ['subscription', 'abtest', 'auth', 'sizing'],
  () => ({})
)(withRouter(OptimisedJoinFlowFrameComp));
