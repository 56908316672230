import * as React from 'react';

import { Redirect } from 'react-router';
import { connect } from 'unistore/react';

import { LoadingSymbol } from '@/components/global/LoadingSymbol/LoadingSymbol';

import { routes } from '@/routes';

import { abtestActions } from '@/store/modules/abtest';

interface Props extends ABTestState, ABTestActions {}

interface State {
  isRedirected: boolean;
}

class EmailDiscountLinkComp extends React.Component<Props, State> {
  public state: State = {
    isRedirected: false,
  };

  public componentDidMount = async () => {
    await this.props.forceVariant(2);

    this.setState({ isRedirected: true });
  };

  public render = () => {
    const { isRedirected } = this.state;

    if (isRedirected) {
      return <Redirect to={routes.optimisedJoinFlowSignUpYearlyPay} />;
    }

    return (
      <div className="u-bg-black u-white u-1/1 u-h-100vh u-flex u-align-center u-justify-center">
        <LoadingSymbol size="l" colour="white" />
      </div>
    );
  };
}

export const EmailDiscountLink = connect(['abtest'], () => ({
  ...abtestActions(),
}))(EmailDiscountLinkComp);
