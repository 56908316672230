import * as React from 'react';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

const Checkbox = (props: any) => {
  const {
    label,
    className = '',
    addCampaignClass,
    uiTestId,
    darkMode,
    ...rest
  } = props;

  const campaignClass = addCampaignClass
    ? label.replace(/ /g, '-').toLowerCase()
    : undefined;

  return (
    <>
      <input
        type="checkbox"
        className="c-checkbox screen-reader-only"
        {...rest}
      />
      <label
        htmlFor={props.id}
        children={t(label)}
        className={c(className, {
          [`campaign-${campaignClass} campaign-${campaignClass}--checkbox`]:
            addCampaignClass && campaignClass,
          'c-checkbox-dark-mode': darkMode,
        })}
        data-ui-test-id={isUiTest() && uiTestId ? uiTestId : undefined}
      />
    </>
  );
};

export { Checkbox };
