import {
  getUserFromData,
  getUsersFromData,
  getWorkshopBatchFromData,
  getWorkshopFromData,
} from '@/store/helpers';
import { getRoomFromData } from '@/store/helpers/roomHelpers';

import { Http } from '@/utils/api/Http';

export const getCalendarEventsFromData = (
  items: APICalendarEvent[]
): CalendarEvent[] => items.map(getCalendarEventFromData);

const checkIfInProgress = (start: Date, end: Date): boolean => {
  const now = new Date();
  return now.getTime() >= start.getTime() && now.getTime() < end.getTime();
};

const checkIfEnded = (end: Date): boolean => {
  const now = new Date();
  return now.getTime() >= end.getTime();
};

const checkIfHappeningToday = (start: Date): boolean => {
  // Returns true if `start` is between 00:00 and 23:59 on the users time
  // This will return true, regardless of `isInProgress` and `isEnded`,
  // so remember to filter these out if you don't want to show them
  const now = new Date();

  const todayTimeStart = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    0,
    0,
    0
  );

  const todayTimeEnd = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    23,
    59,
    59
  );

  return (
    start.getTime() >= todayTimeStart.getTime() &&
    start.getTime() < todayTimeEnd.getTime()
  );
};

const checkAllWorkshopsHappeningThisWeek = (start: Date): boolean => {
  const now = new Date();

  const day = now.getDay();
  const diff = now.getDate() - day + (day == 0 ? -6 : 1);
  const thisWeekTimeStart = new Date(now.setDate(diff));

  const thisWeekTimeEnd = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    23,
    59,
    59
  );

  thisWeekTimeEnd.setDate(thisWeekTimeEnd.getDate() + 7);

  return (
    start.getTime() >= thisWeekTimeStart.getTime() &&
    start.getTime() < thisWeekTimeEnd.getTime()
  );
};

const checkIfHappeningThisWeek = (start: Date): boolean => {
  // Returns `true` if the event starts within a rolling 7-day window from now
  // This will return true, regardless of `isInProgress`, `isEnded` and `isHappeningToday`,
  // so remember to filter these out if you want want to show them

  const now = new Date();

  const thisWeekTimeStart = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    0,
    0,
    0
  );

  const thisWeekTimeEnd = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    23,
    59,
    59
  );
  thisWeekTimeEnd.setDate(thisWeekTimeEnd.getDate() + 7);

  return (
    start.getTime() >= thisWeekTimeStart.getTime() &&
    start.getTime() < thisWeekTimeEnd.getTime()
  );
};

export const getCalendarEventFromData = (
  item: APICalendarEvent
): CalendarEvent => {
  const start = new Date(item.attributes.start);
  const end = new Date(item.attributes.end);

  const isInProgress = checkIfInProgress(start, end);
  const isEnded = checkIfEnded(end);
  const isHappeningToday = checkIfHappeningToday(start);
  const isHappeningThisWeek = checkIfHappeningThisWeek(start);

  const allWorkshopsHappeningThisWeek =
    checkAllWorkshopsHappeningThisWeek(start);

  return {
    id: item.id,
    start,
    end,
    isInProgress,
    isEnded,
    isHappeningToday,
    isHappeningThisWeek,
    allWorkshopsHappeningThisWeek,
    workshop:
      item.relationships.batch &&
      item.relationships.batch.data &&
      item.relationships.batch.data.relationships.workshop &&
      item.relationships.batch.data.relationships.attendees &&
      item.relationships.currentUserData
        ? {
            batch: getWorkshopBatchFromData(item.relationships.batch.data),
            workshop: getWorkshopFromData(
              item.relationships.batch.data.relationships.workshop.data
            ),
            attendees: getUsersFromData(
              item.relationships.batch.data.relationships.attendees.data
            ),
            attendeeLimit:
              item.relationships.batch.data.attributes.attendeeLimit,
            attendeeCount: item.attributes.attendeeCount,
            isAttending: item.relationships.currentUserData.data.attributes
              ? item.relationships.currentUserData.data.attributes.isAttending
              : false,
            isInterested: item.relationships.currentUserData.data.attributes
              ? item.relationships.currentUserData.data.attributes.isInterested
              : false,
            isHost: item.relationships.currentUserData.data.attributes
              ? item.relationships.currentUserData.data.attributes.isHost
              : false,
          }
        : undefined,
    room:
      item.relationships.project &&
      item.relationships.organiser &&
      item.relationships.attendees &&
      item.relationships.currentUserData
        ? {
            room: getRoomFromData({
              roomData: item.relationships.project.data,
              curatedImages: [],
            }),
            organiser: getUserFromData(item.relationships.organiser.data),
            attendees: getUsersFromData(item.relationships.attendees.data),
            attendeeCount: item.attributes.attendeeCount,
            isAttending: item.relationships.currentUserData.data.attributes
              ? item.relationships.currentUserData.data.attributes.isAttending
              : false,
            isOrganiser: item.relationships.currentUserData.data.attributes
              ? item.relationships.currentUserData.data.attributes.isOrganiser
              : false,
          }
        : undefined,
  };
};

export const attendProjectMeeting = async (uuid: string) => {
  await new Http(`/projectMeetings/${uuid}/attend`).post();
};

export const unattendProjectMeeting = async (uuid: string) => {
  await new Http(`/projectMeetings/${uuid}/attend`).delete();
};

export const registerToWorkshop = async (batchUuid: string) => {
  await new Http('/workshopBatch/attend/free').post({
    uuid: batchUuid,
  });
};

export const unregisterFromWorkshop = async (batchUuid: string) => {
  await new Http('/workshopBatch/attend').delete({
    uuid: batchUuid,
  });
};
