import {
  addLeadingZero,
  months,
  shortMonths,
} from '@/utils/date/date-manipulation';

export { CustomDate };

const JUST_NOW_THRESHOLD_SECS: number = 100;

class CustomDate extends Date {
  public getDateObject(): DateObject {
    const dayDate = this.getDate();
    const day = this.getDay();
    const monthDate = this.getMonth();
    const shortMonth = shortMonths[monthDate];
    const month = months[monthDate];
    const year = this.getFullYear();
    const seconds = addLeadingZero(this.getSeconds());
    const rawMinutes = this.getMinutes();
    const minutes = addLeadingZero(this.getMinutes());
    const rawHours = this.getHours();
    const hours = addLeadingZero(this.getHours());

    // For showing 12-hour time
    const hours12h = String(this.getHours() % 12 || 12);
    const ampm = this.getHours() < 12 ? 'AM' : 'PM';

    return {
      day,
      dayDate,
      monthDate,
      shortMonth,
      month,
      year,
      hours,
      hours12h,
      ampm,
      rawHours,
      minutes,
      rawMinutes,
      seconds,
    };
  }

  public isSameDateAs(date?: Date): boolean {
    if (!date) return false;

    return (
      this.getFullYear() === date.getFullYear() &&
      this.getMonth() === date.getMonth() &&
      this.getDate() === date.getDate()
    );
  }

  public isToday() {
    const today = new Date();
    return this.isSameDateAs(today);
  }

  public isYesterday() {
    const now = new Date();

    return (
      this.getFullYear() === now.getFullYear() &&
      this.getMonth() === now.getMonth() &&
      this.getDate() === now.getDate()
    );
  }

  public isTomorrow() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    return (
      this.getFullYear() === tomorrow.getFullYear() &&
      this.getMonth() === tomorrow.getMonth() &&
      this.getDate() === tomorrow.getDate()
    );
  }

  public isJustNow() {
    const now = new Date();

    return (
      this.isToday() &&
      this.getMinutes() === now.getMinutes() &&
      this.getHours() === now.getHours() &&
      now.getSeconds() - this.getSeconds() < JUST_NOW_THRESHOLD_SECS
    );
  }
}
