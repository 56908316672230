import * as React from 'react';

import { UserGrid } from '@/components/design_system/UserGrid/UserGrid';

interface Props {
  users: User[];
  close: () => void;
}

export const WorkshopAttendeesModal = (props: Props) => {
  const { users, close } = props;

  return (
    <div className="u-overflow-scroll u-scrollbar-disabled">
      <div className="pv24 u-white">
        <UserGrid users={users} close={close} />
      </div>
    </div>
  );
};
