/* eslint-disable @typescript-eslint/no-magic-numbers */

import { notProduction } from '@/utils/featureToggles';

export const MAX_BIO: number = 160;
export const MAX_NAME: number = 30;
export const MAX_INSTAGRAM_HANDLE = 30;
export const MAX_PROJECT_TITLE = 100;
export const MAX_PROJECT_DESCRIPTION = 240;
export const MAX_USERNAME: number = 30;
export const MAX_USERNAME_RETRIES: number = 10;
export const MAX_EMAIL: number = 254;
export const MAX_LOCATION: number = 50;
export const MAX_ROLE_NAME_LENGTH: number = 50;
export const MAX_WORKSHOP_TITLE: number = 100;

export const EVENT_TOAST = 'toast';

export const LOWEST_ASCII = 48;
export const HIGHEST_ASCII = 90;
export const SAMPLE_AVATARS = 6;

export const MIN_PASSWORD_LENGTH = 8;

export const MAX_PROFILE_PROFESSIONS = 1;

export const CAROUSEL_MARGIN_DEFAULT: string = 'mb32 mb64@m';

export const EXPLORE_CONFIG_TYPE: string = 'learn';
export const MORE_WORKSHOPS_LIST_TYPE: string = 'more-workshops';

export const ALL_LOCATIONS_TEXT = 'Anywhere';
export const ALL_LOCATIONS_QUERY_PARAM = 'anywhere';

export const LOCATION_IRRELEVANT_TEXT = 'Any location';

export const ALL_CATEGORIES_TEXT = 'All communities';
export const ANY_ROLE_TEXT = 'Any profession';

export const DEFAULT_MINIMISED_LIST_COUNT: number = 5;

export const URL_AVATAR_PLACEHOLDER = '/images/avatars/placeholder.svg';
export const URL_AVATAR_PROJECT = '/images/avatars/default-project.svg';
export const URL_AVATAR_DEFAULTS_ROOT = '/images/avatars/default-avatar-';
export const URL_AVATAR_DEFAULTS_EXT = '.webp';

// CATEGORIES
export const DISCUSSION_CATEGORY_NAME = 'Discussion';
// Looking for collaborators is deprecated, but keeping this constant
// so the category is removed from old rooms when rendered on RoomCards etc
export const LOOKING_FOR_COLLABORATORS_CATEGORY_NAME =
  'Looking for Collaborators';
export const WORKSHOP_ROOM_CATEGORY_NAME = 'Workshop';
export const GENERAL_CATEGORY_NAME = 'General';
export const WORK_IN_PROGRESS_CATEGORY_NAME = 'Work in Progress';
export const INITIAL_CONCEPT_CATEGORY_NAME = 'Initial Concept';
export const WONDERWOMEN_CATEGORY_NAME = 'Wonder Women';
export const TOVE_LO_CATEGORY_NAME = 'Daisie x Tove Lo';
export const MAKE_UP_CATEGORY_NAME = 'Makeup';
export const GAMING_CATEGORY_NAME = 'Gaming';
export const LOGGED_OUT_CATEGORY_PAGE_ALLOW_LIST: string[] = [
  'Art',
  'Film',
  'Photography',
  'Design',
  'Music',
];

export const ALL_CATEGORIES_SLUG = 'all-categories';

export const IOS_SAFARI_SAFE_AREA_OFFSET: number = 38;

// FILE UPLOAD TYPES
export const ALLOWED_TYPES_MEDIA_BLOCK = {
  'image/jpeg': 'image',
  'image/png': 'image',
  'image/gif': 'image',
  'video/mp4': 'video',
  'video/avi': 'video',
  'video/quicktime': 'video',
  'video/x-msvideo': 'video',
  'audio/mp3': 'audio',
  'audio/mpeg': 'audio',
  'audio/wav': 'audio',
  'audio/wave': 'audio',
  'audio/x-wav': 'audio',
};

export const ALLOWED_TYPES_BLOCKS = [
  ...Object.keys(ALLOWED_TYPES_MEDIA_BLOCK),
  'image/heic',
  'image/bmp',
  'image/vnd.adobe.photoshop',
  'image/tiff',
  'font/ttf',
  'font/otf',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/postscript',
  'text/rtf',
];

export const ALLOWED_TYPES_AVATAR = ['image/jpeg', 'image/png', 'image/gif'];

export const URL_IMAGE_GENERATOR = notProduction()
  ? 'https://daisie-image-generator.stage.daisie.info'
  : 'https://daisie-image-generator.production.daisie.com';

export const CATEGORY_ORDER = {
  Film: 1,
  Photography: 2,
  Art: 3,
  Music: 4,
  Design: 5,
  Writing: 6,
  Fashion: 7,
  Digital: 8,
  Theatre: 9,
  Gaming: 10,
  Animation: 11,
  Makeup: 12,
};

export const INVALID_FIRST_CHARACTERS_USERNAME = [
  '_',
  '.',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
];

export const DROPDOWN_DEFAULT_HEIGHT: number = 225;

export const CHECK_FONTS_LOADED_RETRIES: number = 20;
export const CHECK_FONTS_LOADED_DELAY: number = 250;

export const MAX_VIEWED_PROJECTS: number = 6;

export const SHARE_LINK_JOIN_DAISIE: string = 'https://www.daisie.com/join';

export const CATEGORY_PAGE_PAGE_SIZE: number = 21;

export const SEARCH_PAGE_SIZE: number = 50;

export const WORKSHOP_ROOM_OWNER: string = 'daisieworkshops';

export const HIDDEN_PROFILES: string[] = [WORKSHOP_ROOM_OWNER];

export const SECONDS = 1000; // milliseconds
export const MINUTES = 60 * SECONDS;
export const HOURS = 60 * MINUTES;
export const DAYS = 24 * HOURS;
export const WEEKS = 7 * DAYS;
export const MONTHS = 30 * DAYS;

export const SHOW_VIEWS_COUNT_AFTER_TIME: number = 1 * DAYS;
export const SHOW_VIEWS_COUNT_AFTER_VIEWS: number = 250;

export const MENTOR_TRACKING_RELEASE_DATE: string = '2022-06-09T12:00:00';

export const APP_STORE_LINK: string =
  'https://apps.apple.com/gb/app/daisie/id1406427065';
export const HELP_CENTRE_URL: string =
  'https://www.notion.so/Help-Center-42279b7752c24438bcb360c7ddf52d83';
export const COMMUNITY_GUIDELINES_URL: string =
  'https://www.notion.so/daisie/Community-Guidelines-d9731ab8db3e4c83872359c41d86606f';
export const VOICE_RECORDING_LEARN_MORE_URL: string =
  'https://www.notion.so/daisie/Help-Center-42279b7752c24438bcb360c7ddf52d83#c85ae6f6971b4ccb9bcadceae39f6cde';

export const TERMS_URL: string =
  'https://www.notion.so/daisie/Daisie-Terms-of-Use-3d51f1389eae4743b40fa81fa3a6a68e';
export const PRIVACY_POLICY_URL: string =
  'https://www.notion.so/daisie/Daisie-Privacy-Policy-9e619d0f68264c06a2db249501a32668';

// HOTJAR
export const HOTJAR_SITE_ID: number =
  process.env.DAISIE_ENV === 'production' ? 2414026 : 2411925;
export const HOTJAR_SNIPPET_VERSION: number = 6;

export const REVIEW_RATING: number = 4.8;
export const REVIEW_SAMPLE_SIZE: number = 10000;

// WORKSHOP
export const WORKSHOP_LEARN_CAROUSEL_SCROLL_MULTIPLIER = 2; // Increase the number for longer scrolling

export const GOOGLE_SIGN_IN_BUTTON_STYLING = {
  theme: 'filled_black',
  size: 'large',
  text: 'signup_with',
  shape: 'pill',
};

//DISCORD
// Why are there 2 of the same? remove 1 after release
export const DISCORD_URL: string = `https://discord.gg/4J23USuy6T`;
export const DISCORD_DAISIE: string = 'https://discord.gg/4J23USuy6T';
export const DISCORD_OAUTH_URL: string =
  'https://discord.com/oauth2/authorize?client_id=1014861399178084362&redirect_uri=https%3A%2F%2Fwww.daisie.com%2Fapps%2Fdiscord%2Fconnect&response_type=code&scope=identify';

export const BOGO_COUPON_ID: string =
  process.env.DAISIE_ENV === 'production' ? 'g8b2IaOO' : 'p6UDJ76x';

// To run this offer again, extend this date here and in selleck
export const BOGO_END_DATE: Date = new Date('2023-01-31T23:59:59');

// Notion links for Alex's classes
export const CLASS_NOTION_LINKS = [
  'https://www.notion.so/Class-1-Meet-Your-Instructor-a60eed74cdce48489632ca85dbd46bd3',
  'https://www.notion.so/daisie/Class-2-Generating-Ideas-d9265deddc7e4223b54e8405dccb63cd',
  'https://www.notion.so/daisie/Class-3-Preparing-Your-Workspace-and-Flow-8b560ad6e4344618ba7c2ae1a62260a1',
  'https://www.notion.so/daisie/Class-4-Creating-A-Storyboard-4dfbab36b8ec4f07b646ede1208efb1f',
  'https://www.notion.so/daisie/Class-5-From-Sketch-To-Screen-6369664f184b413f999e409a90f76263',
  'https://www.notion.so/daisie/Class-6-Introduction-To-Procreate-4c49742f826e42d680327d9b98611221',
  'https://www.notion.so/daisie/Class-7-Understanding-Procreate-Techniques-Tools-46b651c7b9dc44468292b8ddb80798d2',
  'https://www.notion.so/daisie/Class-8-Planning-The-Narrative-c85a20c66e2e4dc89e87aa8c5ab9affa',
  'https://www.notion.so/daisie/Class-9-Creating-First-Frame-5fc9fe164a0544e88746bc12deeba0c6',
  'https://www.notion.so/daisie/Class-10-Building-Your-Storyboard-Out-f021097a734c4da4a634244a5a0ee182',
  'https://www.notion.so/daisie/Class-11-Understanding-Scale-and-Perspective-2fd42dbc03204f4eb57051c7f64777c2',
  'https://www.notion.so/daisie/Class-12-Adding-Expression-7e86eca8b6ad4986abdcc0e937f738e8',
  'https://www.notion.so/daisie/Class-13-Final-Touches-on-Procreate-a272cd45eed549bb812f0d186c308d3d',
  'https://www.notion.so/daisie/Class-14-Photoshop-Intro-The-Animator-s-Toolbar-c25a021b82a14ffb8d6023a1ffd002a9',
  'https://www.notion.so/daisie/Class-15-Organising-Timeline-and-Creating-Intermediate-Frames-dfcd0286796f4f63b32854fd67bd8809',
  'https://www.notion.so/daisie/Class-16-Onion-Skinning-and-Enhancing-Your-Characters-6046ed4073e74c328dd42a67f28912fa',
  'https://www.notion.so/daisie/Class-17-Adding-Colour-To-Your-Characters-395f72aef5a84396be90fb31dcdd422c',
  'https://www.notion.so/daisie/Class-18-Final-Touches-Before-Rendering-03d14fff97464321b8f8a2e69a5d00f2',
  'https://www.notion.so/daisie/Class-19-Exporting-and-Rendering-8572f5fd84df4330bdc4b3cf122142d6',
  'https://www.notion.so/daisie/Class-20-Final-Thoughts-From-Alex-6a46e52a2efa452c9263eb6b48962691',
  'https://www.notion.so/daisie/Class-21-Course-Round-up-69d02ea99d63482fb482370dd9faac8d',
];
