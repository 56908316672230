import { Http } from '@/utils/api/Http';
import { DISCORD_OAUTH_URL } from '@/utils/constants';

const redirectToDiscordOAuth = () => {
  window.location.href = DISCORD_OAUTH_URL;
};

const disconnectUser = async () => {
  try {
    await new Http(`/users/apps/discord/disconnect`).post();
  } catch (e) {
    throw e;
  }
};

const connectUser = async (code: string) =>
  await new Http(`/users/apps/discord/connect`).post({
    code,
  });

export { redirectToDiscordOAuth, disconnectUser, connectUser };
