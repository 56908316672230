/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState } from 'react';

import {
  Box,
  BoxProps,
  Flex,
  Grid,
  Heading,
  Tag,
  Text,
} from '@chakra-ui/react';

import { Accordion } from '@/components/design_system/AlexJenkinsClass/molecules';

import { accordionData } from '@/pages/AlexJenkinsClass/sections/ClassBreakDownSection/settings';

import { pxToRem } from '@/styles/chakra-theme/metrics';

import { isDesktop } from '@/utils/DOM/sizing';

export const ClassBreakDownSection = (props: BoxProps) => {
  const [accordionOpen, setAccordionOpen] = useState<number>(-1);

  return (
    <Grid
      templateColumns={{
        base: `repeat(9, ${window.innerWidth / 9}px)`,
        md: `repeat(9, ${window.innerWidth / 9}px)`,
        lg: `repeat(9, ${window.innerWidth / 9}px)`,
        xl: `repeat(12, ${window.innerWidth / 12}px)`,
        '2xl': `repeat(16, ${window.innerWidth / 16}px)`,
      }}
      templateRows={{
        base: `repeat(${accordionOpen !== -1 ? '52' : '20'}, ${
          window.innerWidth / 9
        }px)`,
        md: `repeat(${accordionOpen !== -1 ? '40' : '14'}, ${
          window.innerWidth / 9
        }px)`,
        lg: `repeat(${accordionOpen !== -1 ? '22' : '10'}, ${
          window.innerWidth / 9
        }px)`,
        xl: `repeat(${accordionOpen !== -1 ? '22' : '10'}, ${
          window.innerWidth / 12
        }px)`,
        '2xl': `repeat(${accordionOpen !== -1 ? '22' : '10'}, ${
          window.innerWidth / 16
        }px)`,
      }}
      bgColor="#000"
      backgroundImage="linear-gradient(#ffffff20 1px, transparent 1px), linear-gradient(to right, #ffffff20 1px, #000 1px)"
      backgroundSize={{
        base: `${window.innerWidth / 9}px ${window.innerWidth / 9}px`,
        md: `${window.innerWidth / 9}px ${window.innerWidth / 9}px`,
        lg: `${window.innerWidth / 9}px ${window.innerWidth / 9}px`,
        xl: `${window.innerWidth / 12}px ${window.innerWidth / 12}px`,
        '2xl': `${window.innerWidth / 16}px ${window.innerWidth / 16}px`,
      }}
      className="classBreakdown"
      {...props}
    >
      <Flex
        align="center"
        px={pxToRem(18)}
        bg="black"
        gridColumn={{ base: '2 / -2', md: '2 / -2', lg: '2 / 8' }}
        gridRow={{ base: '3 / 5', lg: '3 / 5' }}
        border="1px solid #ffffff20" // border to match background grid
      >
        <Heading
          color="white"
          fontSize={{ base: 44, md: 60, lg: 96 }}
          lineHeight={{ base: pxToRem(37), md: pxToRem(56), lg: pxToRem(82) }}
          fontFamily="Originals"
          fontWeight={400}
        >
          CLASS <br /> BREAKDOWN
        </Heading>
      </Flex>

      <Flex
        wrap="wrap"
        align="center"
        justify={!isDesktop() ? 'start' : 'center'}
        px={pxToRem(!isDesktop() ? 16 : 0)}
        bgColor="#000"
        gap={pxToRem(8)}
        gridColumn={{ base: '2 / -2', md: '2 / -2', lg: '2 / 8' }}
        gridRow={{ base: '6 / 9', md: '5', lg: '5' }}
        border="1px solid #ffffff20"
      >
        <Text
          color="white"
          fontWeight={400}
          fontSize={{ base: 16, md: 18, lg: 32 }}
          w={['100%', 'fit-content']}
        >
          Designed for
        </Text>

        <Tag
          bg="white"
          color="black"
          fontWeight={400}
          px={pxToRem(16)}
          py={pxToRem(6)}
          borderRadius={100}
          fontSize={{ base: 14, lg: 24 }}
        >
          BEGINNERS 🧑‍🎨
        </Tag>

        <Tag
          bg="white"
          color="black"
          fontWeight={400}
          px={pxToRem(16)}
          py={pxToRem(6)}
          borderRadius={100}
          fontSize={{ base: 14, lg: 24 }}
        >
          INTERMEDIATES 👩‍🎤
        </Tag>
      </Flex>

      <Box
        gridColumn={{
          base: '2 / -2',
          md: '2 / -2',
          lg: '2 / -2',
          xl: '2 / -2',
          '2xl': '2 / -2',
        }}
        gridRow={{ base: '7', lg: '7' }}
      >
        <Accordion
          items={accordionData}
          onChange={(e) => setAccordionOpen(Number(e))}
        />
      </Box>
    </Grid>
  );
};
