import * as React from 'react';

import { Icon } from '@/components/global/Icon/Icon';

import { IconSize } from '@/enums/IconSize';

interface Props {
  data: IToast;
}

const ToastCard: React.FunctionComponent<Props> = (props: Props) => (
  <div className="pv16 ph24 u-box-shadow f-text-2 c-toast u-flex u-justify-between">
    <p>{props.data.body}</p>

    <div className="u-flex u-align-center ml8">
      {props.data.failure ? (
        <Icon id="clear" size={IconSize.xs} className="u-danger" />
      ) : (
        <Icon id="tick" size={IconSize.s} className="u-go" />
      )}
    </div>
  </div>
);

export { ToastCard };
