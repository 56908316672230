import * as React from 'react';

import { TooltipBehaviour } from '@/components/design_system/Tooltip/TooltipBehaviour';
import { TooltipDirection } from '@/components/design_system/Tooltip/TooltipDirection';
import { TooltipSize } from '@/components/design_system/Tooltip/TooltipSize';

import { TooltipStyle } from '@/enums/TooltipStyle';

import { getParentElement } from '@/utils/DOM/getParentElement';
import { c } from '@/utils/strings/c';

interface Props {
  refAnchor: React.RefObject<any>;
  // Use `refAnchorParentClassName` to define the className of the parent element that positions the anchor
  // This will fix any issues with the tooltip positioning itself in the wrong place
  refAnchorParentClassName?: string;
  tooltipContent: ({
    dismissTooltip,
    redrawTooltip,
  }: {
    dismissTooltip: () => void;
    redrawTooltip: () => void;
  }) => React.ReactNode;
  dismissBehaviour: TooltipBehaviour;
  size?: TooltipSize;
  tooltipDirection?: TooltipDirection;
  onDismiss?: () => void;
  scrollOverflow?: boolean;
  disableMouseOutTimer?: boolean;
  tooltipStyle?: TooltipStyle;
  offsetY?: number;
}

interface State {
  tooltipRender: boolean;
  tooltipShow: boolean;
  tooltipTop?: number;
  tooltipLeft?: number;
  isPositionAnimateable: boolean;
}

class Tooltip extends React.Component<Props, State> {
  private refTooltip: React.RefObject<HTMLDivElement>;

  private timeoutTooltip?: NodeJS.Timeout;

  public state: State = {
    tooltipRender: false,
    tooltipShow: false,
    tooltipTop: undefined,
    tooltipLeft: undefined,
    isPositionAnimateable: false,
  };

  constructor(props: Props) {
    super(props);

    this.refTooltip = React.createRef();
  }

  public componentDidMount = () => {
    window.addEventListener('scroll', this.onScroll);
  };

  public componentWillUnmount = () => {
    window.removeEventListener('scroll', this.onScroll);
  };

  private onScroll = () => {
    this.redrawTooltip();
  };

  private resetTimeout = () => {
    if (this.timeoutTooltip) {
      clearTimeout(this.timeoutTooltip);
    }
  };

  private startTimeout = () => {
    this.timeoutTooltip = setTimeout(
      () => this.dismissTooltip(),
      this.props.disableMouseOutTimer ? 0 : 250
    );
  };

  public showTooltip = () => {
    this.setState({ tooltipRender: true }, () => {
      this.redrawTooltip();

      setTimeout(() => {
        this.setState({ isPositionAnimateable: true });
      }, 250);
    });
  };

  private redrawTooltip = () => {
    const {
      refAnchor,
      refAnchorParentClassName,
      tooltipDirection = TooltipDirection.bottom,
      offsetY: offsetYProp = 0,
    } = this.props;

    if (!refAnchor.current || !this.refTooltip.current) {
      return;
    }

    let offsetX = 0;
    let offsetY = 0;

    if (refAnchorParentClassName) {
      const parent = getParentElement(
        refAnchor.current,
        refAnchorParentClassName
      );

      if (!parent) {
        return;
      }

      const { x: parentX, y: parentY } = parent.getBoundingClientRect();

      offsetX = parentX;
      offsetY = parentY;
    }

    const { width, height, x, y } = refAnchor.current.getBoundingClientRect();

    const { width: tooltipWidth, height: tooltipHeight } =
      this.refTooltip.current.getBoundingClientRect();

    const tooltipLeft =
      tooltipDirection === TooltipDirection.right
        ? x - offsetX + width
        : x - offsetX + width / 2 - tooltipWidth / 2;
    const tooltipTop =
      tooltipDirection === TooltipDirection.right
        ? y - offsetY + height / 2 - tooltipHeight / 2
        : y - offsetY + height;

    this.setState({
      tooltipLeft,
      tooltipTop: tooltipTop + offsetYProp,
      tooltipShow: true,
    });
  };

  public dismissTooltip = () => {
    this.setState({ tooltipShow: false }, () => {
      setTimeout(() => {
        this.setState({ tooltipRender: false, isPositionAnimateable: false });

        if (this.props.onDismiss) {
          this.props.onDismiss();
        }
      }, 1);
    });
  };

  public render = () => {
    const {
      tooltipContent,
      dismissBehaviour,
      onDismiss,
      size = TooltipSize.s,
      scrollOverflow,
      tooltipDirection = TooltipDirection.bottom,
      tooltipStyle = TooltipStyle.default,
    } = this.props;

    const {
      tooltipRender,
      tooltipShow,
      tooltipLeft,
      tooltipTop,
      isPositionAnimateable,
    } = this.state;

    if (!tooltipContent || !tooltipRender) {
      return null;
    }

    return (
      <>
        <div
          ref={this.refTooltip}
          className={c(
            ['dds-tooltip animate-opacity', `dds-tooltip--size-${size}`],
            {
              'opacity-0': !tooltipShow,
              'opacity-1': tooltipShow,
              'dds-tooltip--animate-position': isPositionAnimateable,
              'dds-tooltip--overflow': scrollOverflow,
              'dds-tooltip--direction-right':
                tooltipDirection === TooltipDirection.right,
              'dds-tooltip--style-default':
                tooltipStyle === TooltipStyle.default,
              'dds-tooltip--style-dark': tooltipStyle === TooltipStyle.dark,
              'dds-tooltip--style-dark-rounded':
                tooltipStyle === TooltipStyle.darkRounded,
            }
          )}
          style={{
            top: tooltipTop,
            left: tooltipLeft,
            zIndex: 9999,
          }}
        >
          <div
            className={c('dds-tooltip__arrow', {
              'dds-tooltip__arrow--direction-right':
                tooltipDirection === TooltipDirection.right,
            })}
          />

          <div className="dds-tooltip__content">
            {tooltipContent({
              dismissTooltip: this.dismissTooltip,
              redrawTooltip: this.redrawTooltip,
            })}
          </div>
        </div>

        {dismissBehaviour !== TooltipBehaviour.none && (
          <button
            type="button"
            className="c-room-setting__tooltip__dismiss u-fixed u-fixed--tl u-1/1 u-h-100"
            onMouseOver={
              dismissBehaviour === TooltipBehaviour.hover
                ? this.startTimeout
                : undefined
            }
            onMouseOut={
              dismissBehaviour === TooltipBehaviour.hover
                ? this.resetTimeout
                : undefined
            }
            onClick={() => {
              this.resetTimeout();
              this.dismissTooltip();

              if (onDismiss) {
                onDismiss();
              }
            }}
            style={{ zIndex: 400 }}
          />
        )}
      </>
    );
  };
}

export { Tooltip };
