import * as React from 'react';

import { Icon } from '@/components/global/Icon/Icon';

import { IconSize } from '@/enums/IconSize';

import { c } from '@/utils/strings/c';

interface Props {
  className?: string;
  isChecked: boolean;
  isGreenCheckbox?: boolean;
}

export const SimpleCheckbox = (props: Props) => {
  const { className = '', isChecked, isGreenCheckbox } = props;

  return (
    <button
      type="button"
      className={c(
        [
          'dds-simple-checkbox u-flex u-align-center u-justify-center',
          className,
        ],
        {
          'bh bh--dark-grey bv bv--dark-grey': !isGreenCheckbox,
          'u-bg-brand': isChecked && !isGreenCheckbox,
          'u-bg-go': isChecked && isGreenCheckbox,
        }
      )}
    >
      {isChecked && (
        <Icon
          id="tick"
          size={IconSize.s}
          className={c('', {
            'u-black': isGreenCheckbox,
            'u-white': !isGreenCheckbox,
          })}
        />
      )}
    </button>
  );
};
