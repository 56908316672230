import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'unistore/react';

import { Head } from '@/components/global/Head/Head';
import { NotificationFeed } from '@/components/notifications/NotificationFeed';

import { authActions } from '@/store/modules/auth';

import { t } from '@/utils/i18n/i18n';
import { track } from '@/utils/mixpanel/mixpanel';
import { c } from '@/utils/strings/c';
import { getTrackingLocationFromLocationState } from '@/utils/urls/routes';

interface Props
  extends AuthState,
    AuthActions,
    UserState,
    SizingState,
    RouteComponentProps<{}, {}, LocationState> {
  onClickToggle: () => void;
  isNotificationsOpen: boolean;
  isDarkMode: boolean;
}

class NotificationsComponent extends React.Component<Props> {
  public componentDidMount() {
    const { state } = this.props.location;

    track('open_notifications', {
      from: getTrackingLocationFromLocationState(state),
    });
  }

  public render() {
    const {
      sizing: { isMobile },
      location: { pathname },
      isDarkMode,
      isNotificationsOpen,
    } = this.props;

    return (
      <div
        className={c('u-light-grey', {
          'u-bg-almost-black mt60': isDarkMode,
        })}
      >
        {pathname === '/notifications' && <Head title={t('Notifications')} />}
        {isMobile ? (
          <NotificationFeed
            onClickToggle={this.props.onClickToggle}
            isNotificationsOpen={isNotificationsOpen}
            isDarkMode={isDarkMode}
          />
        ) : (
          <div className="wrap wrap--sm mt8 mt24@m">
            <Head title={t('Notifications')} pathname={t('/notifications')} />
            <h1 className="f-title-2 u-bold mb8 pb16 bb--sad-grey">
              {t('Notifications')}
            </h1>
            <NotificationFeed />
          </div>
        )}
      </div>
    );
  }
}

const Notifications = connect(
  ['auth', 'sizing'],
  authActions
)(withRouter(NotificationsComponent));

export { Notifications };
