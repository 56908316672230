import * as React from 'react';

import * as ReactDOM from 'react-dom';

interface Props {
  component: React.ReactNode;
  to: string;
}

class Portal extends React.Component<Props> {
  public state = {
    loaded: false,
  };

  public componentDidMount() {
    this.setState({ loaded: true });
  }

  public render() {
    if (typeof document === 'undefined' || !this.state.loaded) {
      return null;
    }

    const element = document.querySelector(this.props.to);
    if (!element) {
      return null;
    }

    return ReactDOM.createPortal(this.props.component, element);
  }
}

export { Portal };
