import React from 'react';

import {
  Box,
  BoxProps,
  Flex,
  Grid,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';
import { Stream } from '@cloudflare/stream-react';
import { useHistory } from 'react-router-dom';
import Typewriter from 'typewriter-effect';

import {
  ArrowBottomRightIcon,
  TitleTag,
} from '@/components/design_system/AlexJenkinsClass/atoms';

import { routes } from '@/routes';

import { colors } from '@/styles/chakra-theme/colors';
import { pxToRem } from '@/styles/chakra-theme/metrics';

import { isDesktop } from '@/utils/DOM/sizing';

const heads = [
  '/AlexJenkinsClass/img/heads1.png',
  '/AlexJenkinsClass/img/heads2.png',
  '/AlexJenkinsClass/img/heads3.png',
  '/AlexJenkinsClass/img/heads4.png',
];

export const SkillsHeroSection = (props: BoxProps) => {
  const history = useHistory();

  return (
    <Grid
      templateColumns={{
        base: `repeat(9, ${window.innerWidth / 9}px)`,
        md: `repeat(9, ${window.innerWidth / 9}px)`,
        lg: `repeat(9, ${window.innerWidth / 9}px)`,
        xl: `repeat(12, ${window.innerWidth / 12}px)`,
        '2xl': `repeat(16, ${window.innerWidth / 16}px)`,
      }}
      templateRows={{
        base: `repeat(52, ${window.innerWidth / 9}px)`,
        md: `repeat(30, ${window.innerWidth / 9}px)`,
        lg: `repeat(24, ${window.innerWidth / 9}px)`,
        xl: `repeat(25, ${window.innerWidth / 12}px)`,
        '2xl': `repeat(28, ${window.innerWidth / 16}px)`,
      }}
      bgColor="#000"
      backgroundImage="linear-gradient(#ffffff20 1px, transparent 1px), linear-gradient(to right, #ffffff20 1px, #000 1px)"
      backgroundSize={{
        base: `${window.innerWidth / 9}px ${window.innerWidth / 9}px`,
        md: `${window.innerWidth / 9}px ${window.innerWidth / 9}px`,
        lg: `${window.innerWidth / 9}px ${window.innerWidth / 9}px`,
        xl: `${window.innerWidth / 12}px ${window.innerWidth / 12}px`,
        '2xl': `${window.innerWidth / 16}px ${window.innerWidth / 16}px`,
      }}
      overflowY="hidden"
      overflowX="hidden"
      {...props}
    >
      <Box
        position="relative"
        bg="black"
        px={pxToRem(18)}
        border="1px solid #ffffff20" // border to match background grid
        gridColumn={{
          base: '2 / -2',
          md: '2 / span 7',
          lg: '2 / span 4',
          xl: '2 / span 5',
          '2xl': '2 / span 6',
        }}
        gridRow={{ base: '2 / 9', md: '2 / 4', lg: '3 / 5' }}
      >
        <Heading
          color="white"
          fontSize={{ base: 40, lg: 46, xl: 52 }}
          fontWeight={400}
          fontFamily="NeueMontreal"
        >
          <Box opacity={0}>
            Create an Animation <br /> in 21 days
          </Box>
          <Box position="absolute" inset={0} mt={pxToRem(12)} pl={pxToRem(18)}>
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .changeDelay(70)
                  .typeString('Create an Animation <br /> in 21 days')
                  .start();
              }}
            />
          </Box>
        </Heading>
        <Text
          fontSize={{ base: 40, lg: 46, xl: 52 }}
          color={colors.grey}
          fontWeight={400}
          opacity={0.5}
        >
          with Alex Jenkins
        </Text>
      </Box>

      <Flex
        display={{ base: 'none', lg: 'flex' }}
        justify="space-between"
        align="flex-end"
        bg={colors.purpleGradient}
        p={pxToRem(22)}
        gridColumn={{ base: 0, md: '-7 / -2', lg: '-4 / -2', xl: '-5 / -2' }}
        gridRow={{ base: '4 / span 2', lg: '4 / 4' }}
        cursor="pointer"
        _hover={{ opacity: 0.9 }}
        onClick={() => history.push(routes.optimisedJoinFlowChoosePlan)}
      >
        <Text
          fontSize={{ md: 22, lg: 28 }}
          fontWeight={400}
          lineHeight="normal"
          color="white"
        >
          Enroll now
        </Text>
        <ArrowBottomRightIcon
          fontSize={{ md: 22, lg: 28 }}
          mt="auto"
          transform="rotate(-90deg)"
        />
      </Flex>

      <Box
        gridColumn={{
          base: '2 / -2',
          md: '2 / -2',
          xl: '2 / -2',
        }}
        gridRow={{ base: '10 / 14', md: '6', lg: '6' }}
      >
        <Stream
          autoplay
          muted
          controls
          loop
          src="d757a1ab83efdfd91cbb2ec3ac9e90bc"
        />
      </Box>

      <TitleTag
        fontSize={[40, 40, 40, 52, 64, 64]}
        bgColor={[colors.purpleRain, '#D891C0']}
        gridColumn={{ base: '4', md: '5', xl: '5', '2xl': '7' }}
        gridRow={{ base: '17', md: '13', lg: '12', xl: '14', '2xl': '17' }}
        className="pillMentor"
      >
        MENTOR
      </TitleTag>

      <Image
        gridColumn={{
          base: '2 / 8',
          md: '2 / 6',
          lg: '2 / 5',
          xl: '3 / 6',
          '2xl': '4 / 8',
        }}
        gridRow={{ base: '17 / 22', md: '13', lg: '12', xl: '14', '2xl': '17' }}
        // height={[288, 480]}
        // width={[240, 480]}
        src="/AlexJenkinsClass/img/mentor.png"
        objectFit="cover"
      />

      <TitleTag
        fontSize={[22, 32]}
        bgColor="#60D273"
        gridColumn={{ base: '-6', md: '3', lg: '2', xl: '2', '2xl': '3' }}
        gridRow={{ base: '30', md: '20', lg: '20', xl: '21', '2xl': '25' }}
        className="pillCartoons"
      >
        CARTOONS
      </TitleTag>

      <Box
        gridColumn={{
          base: '2 / -2',
          md: '4 / -2',
          lg: '5 / -2',
          xl: '6 / 11',
          '2xl': '8 / 14',
        }}
        gridRow={{
          base: '27 / 42',
          md: '18 / 24',
          lg: '16 / 21',
          xl: '18 / 22',
          '2xl': '22 / 26',
        }}
        bg="black"
        px={pxToRem(18)}
        border="1px solid #ffffff20"
      >
        <Heading
          color="white"
          fontSize={{ base: 36, lg: 38, '2xl': 44 }}
          fontWeight={400}
          fontFamily="NeueMontreal"
          lineHeight="normal"
          mt={pxToRem(23)}
        >
          Alex Jenkins
        </Heading>
        <Heading
          color={colors.grey}
          fontSize={{ base: 36, lg: 38, '2xl': 44 }}
          fontWeight={400}
          fontFamily="NeueMontreal"
          lineHeight="normal"
        >
          London
        </Heading>
        <Text
          color="white"
          fontWeight={400}
          fontSize={{ base: 22, lg: 26, '2xl': 28 }}
          mt={{ base: pxToRem(96), md: pxToRem(140), lg: pxToRem(50) }}
        >
          Alex is an illustrator, cartoonist and animator from the UK. His work
          explores satire, surrealism and humour in many different (often
          gruesome) forms. Alex has worked with a wide variety of brands and
          publications, including Mother Jones, The New York Times, Hustler,
          VICE, VANS and Time Out.
        </Text>
      </Box>

      <TitleTag
        fontSize={26}
        bgColor="#D8C549"
        gridColumn={{ base: '1', md: '-7', lg: '-5' }}
        gridRow={{ base: '43', md: '24', lg: '22', xl: '23', '2xl': '27' }}
        mt={{ base: pxToRem(92), lg: pxToRem(12) }}
        className="pillIllustration"
      >
        ILLUSTRATION
      </TitleTag>

      {(!isDesktop() ? heads.slice(0, 2) : heads).map((head, index) => (
        <Image
          key={head}
          src={head}
          pt={{ base: 65, md: 42, lg: 65 }}
          gridRow={{ base: -5, md: -3, lg: -1 }}
          gridColumn={{
            base: index * 3 + 3,
            md: index * 4 + 3,
            lg: index * 2 + 2,
            xl: index * 3 + 2,
            '2xl': index * 3 + 4,
          }}
          className={index % 2 === 0 ? 'spinHeadsA' : 'spinHeadsB'}
        />
      ))}
    </Grid>
  );
};
