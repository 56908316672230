import * as React from 'react';

import { connect } from 'unistore/react';
import widont from 'widont';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { IconSize } from '@/enums/IconSize';

import { t } from '@/utils/i18n/i18n';
import { track } from '@/utils/mixpanel/mixpanel';

interface Props extends AuthState, SubscriptionState {
  onContinue: () => void;
}

interface State {
  selectedReasons: boolean[];
  isLoading: boolean;
}

class OptimisedJoinFlowSurveyComp extends React.Component<Props, State> {
  private joinReasons: string[] = [
    'Learn new skills',
    'Network with creators',
    'Get personalized feedback',
    'Launch your career',
    'Just for fun',
  ];

  constructor(props: Props) {
    super(props);

    this.state = {
      selectedReasons: this.joinReasons.map(() => false),
      isLoading: false,
    };
  }

  private onSubmit = () => {
    this.setState({ isLoading: true }, () => {
      const {
        auth: { user },
      } = this.props;

      if (!user) {
        return;
      }

      const { selectedReasons } = this.state;

      const selectedReasonsForMixpanel: string[] = this.joinReasons
        .map((reason: string, index: number) =>
          selectedReasons[index] ? reason : undefined
        )
        .filter((s: string | undefined) => !!s) as string[];

      track('subscription_join_reasons', {
        userId: user.id,
        reasons: selectedReasonsForMixpanel,
      });

      localStorage.setItem(`${user.id}_answered_join_reasons`, 'true');

      setTimeout(() => {
        this.props.onContinue();
      }, 1000);
    });
  };

  public render = () => {
    const { selectedReasons, isLoading } = this.state;

    return (
      <div>
        <h1 className="f-lynstone-bold-1b u-text-center mb20">
          {widont(t('What brings you to Daisie?'))}
        </h1>

        <p className="u-text-center mb32 mb64@l f-text-1 u-grey">
          {widont(t('Select all that apply to you'))}
        </p>

        <div className="u-2/3@l mhauto">
          {this.joinReasons.map((reason: string, key: number) => {
            const isSelected = !!selectedReasons[key];

            return (
              <Button
                // eslint-disable-next-line react/no-array-index-key
                key={key}
                type={ButtonType.action}
                onClick={() => {
                  const { selectedReasons } = this.state;

                  selectedReasons[key] = !isSelected;

                  this.setState({ selectedReasons });
                }}
                buttonStyle={
                  isSelected ? ButtonStyle.light : ButtonStyle.outlineLight
                }
                size={ButtonSize.l}
                text={reason}
                className="u-1/1 mb12"
                iconId={isSelected ? 'tick' : undefined}
                buttonTextClassName="relative"
                iconClassName="absolute absolute--r r-12"
                iconSize={IconSize.s}
                disabled={isLoading}
              />
            );
          })}
        </div>

        <Button
          type={ButtonType.action}
          onClick={() => this.onSubmit()}
          buttonStyle={ButtonStyle.default}
          size={ButtonSize.xl}
          text={t('Continue')}
          className="mhauto mt32 mt64@l"
          disabled={isLoading}
          isLoading={isLoading}
        />
      </div>
    );
  };
}

export const OptimisedJoinFlowSurvey = connect(
  ['auth', 'subscription'],
  () => ({})
)(OptimisedJoinFlowSurveyComp);
