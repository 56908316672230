const prepareMasonryBlock = (data: any): MasonryBlock => {
  const w =
    data.attachment && data.attachment.metadata.width
      ? data.attachment.metadata.width
      : 0;
  const h =
    data.attachment && data.attachment.metadata.height
      ? data.attachment.metadata.height
      : 0;

  return {
    x: 0,
    y: 0,
    w,
    h,
    percentage: 0,
    transform: '',
    data: {
      ...data,
      displayProps: {
        order: 0,
        size: 1,
        ...data.displayProps,
      },
    },
  };
};

export { prepareMasonryBlock };
