import { GlobalPortal } from '@/enums/GlobalPortal';

const portalState: PortalState = {
  portal: {
    portal: undefined,
    data: {},
  },
};

const portalActions = () => ({
  updatePortal: (
    state: GlobalStoreState,
    {
      portal,
      data = {},
    }: { portal?: GlobalPortal; data?: any; header?: string }
  ) => {
    if (portal) {
      document.body.classList.add('prevent-scroll-mobile-no-nav');
    } else {
      document.body.classList.remove('prevent-scroll-mobile-no-nav');
    }

    return {
      portal: {
        portal,
        data,
      },
    };
  },
});

export { portalState, portalActions };
