import * as React from 'react';

import { Icon } from '@/components/global/Icon/Icon';

import { SlideToggleSize } from '@/components/design_system/SlideToggle/SlideToggleSize';

import { IconSize } from '@/enums/IconSize';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

import { c } from '@/utils/strings/c';

interface Props {
  className?: string;
  leftAction: () => void;
  leftText: string;
  leftIcon?: string;
  leftUiTestId?: string;
  rightAction: () => void;
  rightText: string;
  rightIcon?: string;
  rightUiTestId?: string;
  toggled: boolean;
  isFullWidth?: boolean;
  size?: SlideToggleSize;
  showToggle?: boolean;
  isRoomPage?: boolean;
}

interface State {
  leftButtonWidth?: number;
  rightButtonWidth?: number;
  allowTransition: boolean;
}

class SlideToggle extends React.Component<Props, State> {
  private refLeftButton: React.RefObject<HTMLButtonElement>;
  private refRightButton: React.RefObject<HTMLButtonElement>;
  private allowTransitionTimeout?: NodeJS.Timeout;

  // CONFIG
  private PADDING: number = 6;

  public state: State = {
    leftButtonWidth: undefined,
    rightButtonWidth: undefined,
    allowTransition: false,
  };

  constructor(props: Props) {
    super(props);

    this.refLeftButton = React.createRef();
    this.refRightButton = React.createRef();
  }

  public componentDidMount = () => {
    this.checkWidths();
  };

  public componentDidUpdate = () => {
    this.checkWidths();
  };

  public checkWidths = () => {
    if (!this.refLeftButton.current || !this.refRightButton.current) {
      return;
    }

    const { width: leftButtonWidth } =
      this.refLeftButton.current.getBoundingClientRect();

    const { width: rightButtonWidth } =
      this.refRightButton.current.getBoundingClientRect();

    const {
      leftButtonWidth: oldLeftButtonWidth,
      rightButtonWidth: oldRightButtonWidth,
    } = this.state;

    const newLeftButtonWidth = leftButtonWidth - this.PADDING / 2;
    const newRightButtonWidth = rightButtonWidth - this.PADDING / 2;

    if (
      oldLeftButtonWidth === newLeftButtonWidth &&
      oldRightButtonWidth === newRightButtonWidth
    ) {
      return;
    }

    if (this.allowTransitionTimeout) {
      clearTimeout(this.allowTransitionTimeout);
    }

    this.setState({
      leftButtonWidth: newLeftButtonWidth,
      rightButtonWidth: newRightButtonWidth,
    });

    if (newLeftButtonWidth > 0) {
      this.allowTransitionTimeout = setTimeout(() => {
        this.setState({ allowTransition: true });
      }, 250);
    } else {
      this.setState({ allowTransition: false });
    }
  };

  private getIconSize = (): IconSize => {
    const { size = SlideToggleSize.s } = this.props;

    if (size === SlideToggleSize.l) {
      return IconSize.m;
    }

    return IconSize.xs;
  };

  private getIconClassName = (): string => {
    const { size = SlideToggleSize.s, showToggle = true } = this.props;

    return c('', {
      mr12: size === SlideToggleSize.l,
      mr8: size !== SlideToggleSize.l,
      'u-black': !showToggle,
    });
  };

  private getTextClassName = (): string => {
    const { size = SlideToggleSize.s, showToggle = true } = this.props;

    return c('', {
      'f-text-1': size === SlideToggleSize.l,
      'f-text-2': size !== SlideToggleSize.l,
      'u-black': !showToggle,
    });
  };

  public render = () => {
    const {
      className = '',
      leftAction,
      leftText,
      leftIcon,
      leftUiTestId,
      rightAction,
      rightText,
      rightIcon,
      rightUiTestId,
      toggled,
      isFullWidth,
      showToggle = true,
      isRoomPage,
    } = this.props;

    const { leftButtonWidth, rightButtonWidth, allowTransition } = this.state;

    const toggleWidth = toggled ? rightButtonWidth : leftButtonWidth;
    const togglePosition =
      toggled && leftButtonWidth ? leftButtonWidth - this.PADDING : 0;

    const iconSize = this.getIconSize();
    const classNameIcon = this.getIconClassName();
    const classNameText = this.getTextClassName();

    return (
      <div
        className={c(['dds-slide-toggle', className], {
          'u-inline-flex': !isFullWidth,
          'u-flex': isFullWidth,
        })}
      >
        <div
          className={c('relative u-flex', {
            'u-1/1': isFullWidth,
          })}
        >
          <div
            className={c('dds-slide-toggle__active animate-opacity', {
              'dds-slide-toggle__active--skip-transition': !allowTransition,
              'opacity-0': !showToggle,
              'opacity-1': showToggle,
            })}
            style={{
              width: `${toggleWidth}px`,
              transform: `translate(${togglePosition}px, -50%)`,
            }}
          />

          <div
            className={c('dds-slide-toggle__active--hover animate-opacity', {
              'dds-slide-toggle__active--hover--skip-transition':
                !allowTransition,
              'opacity-0': !showToggle,
              'opacity-1': showToggle,
            })}
            style={{
              width: `${toggleWidth}px`,
              transform: `translate(${togglePosition}px, -50%)`,
            }}
          />

          <button
            ref={this.refLeftButton}
            type="button"
            className={c('dds-slide-toggle__button', {
              'dds-slide-toggle__button--active': !toggled,
              'u-1/2': isFullWidth,
              'u-black': isRoomPage,
            })}
            onClick={leftAction}
            data-ui-test-id={isUiTest() ? leftUiTestId : undefined}
          >
            {leftIcon && (
              <Icon id={leftIcon} size={iconSize} className={classNameIcon} />
            )}
            <span className={classNameText}>{leftText}</span>
          </button>

          <button
            ref={this.refRightButton}
            type="button"
            className={c('dds-slide-toggle__button', {
              'dds-slide-toggle__button--active': toggled,
              'u-1/2': isFullWidth,
              'u-black': isRoomPage,
            })}
            onClick={rightAction}
            data-ui-test-id={isUiTest() ? rightUiTestId : undefined}
          >
            {rightIcon && (
              <Icon id={rightIcon} size={iconSize} className={classNameIcon} />
            )}
            <span className={classNameText}>{rightText}</span>
          </button>
        </div>
      </div>
    );
  };
}

export { SlideToggle };
