import { routes } from '@/routes/index';

export const parser = ({ name, params, routesList = routes, query }: Route) => {
  let path: string = routesList[name] || '';

  if (params) {
    Object.keys(params).forEach((key) => {
      const regex = new RegExp(`:${key}`, 'g');
      path = path.replace(regex, params[key]);
      path = path.replace(/\/\?/g, '');
    });
  }
  if (query) {
    const queryParams = new URLSearchParams();
    Object.entries(query).forEach(([key, value]) => {
      queryParams.append(key, value);
    });

    return `${path}?${queryParams.toString()}`;
  }

  return path;
};
