export const TRIAL_LENGTH_TEXT_SINGULAR: string = '7-day';
export const TRIAL_LENGTH_TEXT_PLURAL: string = '7 days';

export const AB_TEST_DISCOUNT_PERCENT_OFF_ACTUAL_USD: number = 42.5;
export const AB_TEST_DISCOUNT_STRIPE_COUPON_NAME_USD: string =
  '40% Discount (USD)';
export const AB_TEST_DISCOUNT_PERCENT_OFF_ACTUAL_GBP_EUR: number = 42.88;
export const AB_TEST_DISCOUNT_STRIPE_COUPON_NAME_GBP_EUR: string =
  '40% Discount (GBP/EUR)';
export const AB_TEST_DISCOUNT_PERCENT_OFF_DISPLAY: number = 40;

export const STUDENT_DISCOUNT_PERCENT_OFF_ACTUAL_USD: number = 65.006501;
export const STUDENT_DISCOUNT_STRIPE_COUPON_NAME_USD: string =
  'Student Discount (USD)';
export const STUDENT_DISCOUNT_PERCENT_OFF_DISPLAY_USD: number = 65;

export const STUDENT_DISCOUNT_PERCENT_OFF_ACTUAL_GBP_EUR: number = 50.00833472;
export const STUDENT_DISCOUNT_STRIPE_COUPON_NAME_GBP_EUR: string =
  'Student Discount (GBP/EUR)';
export const STUDENT_DISCOUNT_PERCENT_OFF_DISPLAY_GBP_EUR: number = 50;

export const GRANDFATHER_DISCOUNT_PERCENT_OFF_YEARLY_ACTUAL_USD: number = 70.008;
export const GRANDFATHER_DISCOUNT_PERCENT_OFF_YEARLY_DISPLAY_USD: number = 70;

export const GRANDFATHER_DISCOUNT_PERCENT_OFF_YEARLY_ACTUAL_GBP_EUR: number = 50.01;
export const GRANDFATHER_DISCOUNT_PERCENT_OFF_YEARLY_DISPLAY_GBP_EUR: number = 50;

export const GRANDFATHER_DISCOUNT_PERCENT_OFF_MONTHLY_ACTUAL_USD: number = 53.89;
export const GRANDFATHER_DISCOUNT_PERCENT_OFF_MONTHLY_DISPLAY_USD: number = 50;

export const GRANDFATHER_DISCOUNT_PERCENT_OFF_MONTHLY_ACTUAL_GBP_EUR: number = 40.04;
export const GRANDFATHER_DISCOUNT_PERCENT_OFF_MONTHLY_DISPLAY_GBP_EUR: number = 40;

export const PROMO_TERMS_LINK: string =
  'https://www.notion.so/daisie/Daisie-Terms-of-Use-3d51f1389eae4743b40fa81fa3a6a68e#8882afda4c834806bb48b7f6f4806bc6';
export const STUDENT_PROMO_TERMS_LINK: string =
  'https://www.notion.so/daisie/Daisie-Terms-of-Use-3d51f1389eae4743b40fa81fa3a6a68e#d8a5ef56a2b247f0959f31523c0b41b4';
