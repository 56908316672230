import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'unistore/react';
import widont from 'widont';

import { FeedHeader } from '@/components/feeds/FeedHeader';
import { BottomBanner } from '@/components/global/BottomBanner/BottomBanner';
import { FAQ } from '@/components/global/FAQ/FAQ';
import { Icon } from '@/components/global/Icon/Icon';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { Link } from '@/components/global/Link/Link';
import { Toast } from '@/components/global/Toaster/Toast';
import { WorkshopReview } from '@/components/global/WorkshopReview/WorkshopReview';
import { YourMentorOrRoomHost } from '@/components/global/YourMentorOrRoomHost/YourMentorOrRoomHost';
import illoGlobe from '@/components/marketing/HomeCategoryPageSections/SectionPromo/illo-globe.png';
import { WorkshopCarouselNew } from '@/components/marketing/HomeCategoryPageSections/SectionWorkshops/WorkshopCarouselNew';
import { OnDemandPlayer } from '@/components/ondemand/OnDemandPlayer';
import { Avatar } from '@/components/users/Avatar/Avatar';
import { addWorkshopComponentActions } from '@/components/workshops/addWorkshopComponentActions';
import illoBulb from '@/components/workshops/images/illo-bulb.png';
import illoFolder from '@/components/workshops/images/illo-folder.png';
import { WorkshopMasonry } from '@/components/workshops/WorkshopMasonry';
import { WorkshopMetadata } from '@/components/workshops/WorkshopMetadata';
import { WorkshopPageSection } from '@/components/workshops/WorkshopPageSection';
import { WorkshopShareButton } from '@/components/workshops/WorkshopShareButton';
import { WorkshopTimeRemaining } from '@/components/workshops/WorkshopTimeRemaining';

import { HeroBlurOverlay } from '@/components/design_system/HeroBlurOverlay/HeroBlurOverlay';

import { DaisieSubscriptionTier } from '@/enums/DaisieSubscriptionTier';
import { SubscriptionInterval } from '@/enums/SubscriptionInterval';
import { WorkshopSectionTemplate } from '@/enums/WorkshopSectionTemplate';

import { WORKSHOP_RELATED_WORKSHOPS_FAIL } from '@/messages/errors';

import { parser } from '@/routes/parser';

import { getWorkshopsFromData } from '@/store/helpers';
import { findProductAndPrice } from '@/store/helpers/subscriptionHelpers';
import { modalActions } from '@/store/modules/modal';
import { portalActions } from '@/store/modules/portal';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

import { Http } from '@/utils/api/Http';
import { isInProgress } from '@/utils/date/date-manipulation';
import { t } from '@/utils/i18n/i18n';
import { track } from '@/utils/mixpanel/mixpanel';
import { c } from '@/utils/strings/c';
import { getDisplayPrice } from '@/utils/strings/currency';

interface Props
  extends AuthState,
    CalendarState,
    ModalActions,
    ModalState,
    PortalActions,
    PortalState,
    SizingState,
    RoomState,
    SubscriptionState,
    WorkshopComponentActions,
    RouteComponentProps {
  workshop: Workshop;
  moreWorkshops: Workshop[];
}

interface State {
  showBottomBanner: boolean;
  viewportHeight?: number;
  headerHeight?: number;
  showBottomSection?: boolean;
  showOnDemandPlayer: boolean;
  isMobileCached: boolean;
  isAutoAttending: boolean;
  similarWorkshops: Workshop[];
  isSimilarWorkshopsLoaded: boolean;
  moreInfoClicked?: boolean;
}

class WorkshopWrapperComponent extends React.Component<Props, State> {
  public state: State = {
    showBottomBanner: false,
    viewportHeight: undefined,
    headerHeight: undefined,
    showBottomSection: false,
    showOnDemandPlayer: false,
    isMobileCached: false,
    isAutoAttending: false,
    similarWorkshops: [],
    isSimilarWorkshopsLoaded: false,
    moreInfoClicked: false,
  };

  private refTester: React.RefObject<HTMLDivElement>;
  private showFooterTitleTimer?: NodeJS.Timeout;

  constructor(props: Props) {
    super(props);

    this.refTester = React.createRef();
  }

  public componentDidMount = async () => {
    const {
      auth: { isAuthorised },
      subscription: { userCurrency },
      workshop: { mentor, batches },
      sizing: { isMobile },
      auth: { user },
      location: { search },
    } = this.props;

    const queryParams = new URLSearchParams(search);

    // Auto-attend after subscribing
    const autoAttendParam = !!queryParams.get('autoattend');

    if (
      autoAttendParam &&
      isAuthorised &&
      user &&
      user.subscriptionTier === DaisieSubscriptionTier.premium &&
      !this.props.workshop.hasElapsed &&
      !this.props.workshop.isAttending
    ) {
      this.setState({ isAutoAttending: true }, () => {
        this.props.attendWorkshop({
          workshop: this.props.workshop,
          batchId: this.props.workshop.batches[0].id,
          timeStart: new Date(this.props.workshop.start),
          timeEnd: new Date(this.props.workshop.end),
          showLoadingState: true,
          onDismiss: () => {
            this.setState({ isAutoAttending: false });
          },
        });

        window.history.replaceState(null, '', this.props.match.url);
      });
    }

    this.showFooterTitleTimer = setInterval(() => this.checkScroll(), 250);

    this.onResize();

    this.fetchSimilarWorkshops();

    if (!isUiTest()) {
      window.addEventListener('resize', this.onResize);
    }

    // Cache the `sizing.isMobile` prop in the component state,
    // so that changing a mobile device orientation doesn't cause
    // the player to unmount
    this.setState({ isMobileCached: isMobile });

    // A/B testing
    if (!isAuthorised) {
      track('pwe_logged_out_workshop_load', {
        currency: userCurrency,
      });
    }

    this.setMobileHeaderHeight();

    if (batches && batches[0]) {
      await this.props.fetchWorkshopAttendees({
        batchId: batches[0].id,
        mentor,
      });
    }

    // On-demand video autoplay
    const {
      workshop: { onDemandMediaCatchup },
    } = this.props;

    const autoplayParam = queryParams.get('autoplay') || '';
    const allowAutoPlay: boolean =
      !!onDemandMediaCatchup &&
      isAuthorised &&
      !!user &&
      user.subscriptionTier !== null;

    const shouldAutoplay: boolean =
      allowAutoPlay && !!autoplayParam && autoplayParam === 'true';

    if (shouldAutoplay) {
      this.setState({ showOnDemandPlayer: true }, () => {
        this.props.history.replace(window.location.pathname);
      });
    }
  };

  public componentWillUnmount = () => {
    if (this.showFooterTitleTimer) {
      clearInterval(this.showFooterTitleTimer);
    }

    if (!isUiTest()) {
      window.removeEventListener('resize', this.onResize);
    }
  };

  private fetchSimilarWorkshops = async () => {
    const { workshop } = this.props;
    const { categories } = workshop;
    const category = categories[0].name;

    this.setState({ isSimilarWorkshopsLoaded: false });

    try {
      const { data } = await new Http(
        `/workshops?pageSize=10&pageNumber=1&sortBy=workshopStartDate${
          !!category ? `&categories=${encodeURIComponent(category)}` : ''
        }&filters=isUpcoming`
      ).get<APIObject<APIWorkshop[]>>();

      const upcomingWorkshops = getWorkshopsFromData(data)
        .filter((w) => w.id !== workshop.id)
        .reverse();

      if (upcomingWorkshops.length === 0) {
        const { data: allWorkshopsData } = await new Http(
          `/workshops?pageSize=10&pageNumber=1&sortBy=workshopStartDate${
            !!category ? `&categories=${encodeURIComponent(category)}` : ''
          }`
        ).get<APIObject<APIWorkshop[]>>();

        this.setState({
          similarWorkshops: getWorkshopsFromData(allWorkshopsData),
          isSimilarWorkshopsLoaded: true,
        });
      } else {
        this.setState({
          similarWorkshops: upcomingWorkshops,
          isSimilarWorkshopsLoaded: true,
        });
      }
    } catch (e) {
      new Toast({
        body: WORKSHOP_RELATED_WORKSHOPS_FAIL,
        failure: true,
      }).dispatch();
    }
  };

  private onResize = () => {
    this.setHeaderHeight();
    this.checkViewportHeight();
  };

  private setMobileHeaderHeight = () => {
    const {
      sizing: { isMobile },
    } = this.props;

    if (!isMobile) {
      return;
    }

    this.setState({
      headerHeight: window.innerHeight,
    });
  };

  private setHeaderHeight = () => {
    const {
      sizing: { isDesktop, isMobile },
    } = this.props;

    if (isMobile) {
      return;
    }

    this.setState({ headerHeight: isDesktop ? undefined : window.innerHeight });
  };

  private checkViewportHeight = () => {
    const {
      sizing: { isDesktop },
    } = this.props;

    if (!this.refTester.current || isDesktop) return;

    const { height: viewportHeight } =
      this.refTester.current.getBoundingClientRect();

    this.setState({ viewportHeight });
  };

  private checkScroll = () => {
    const header = document.querySelector('header[role="banner"]');

    if (!header) return;

    const scrollPosition = window.scrollY;

    if (scrollPosition >= window.innerHeight / 2) {
      this.setState({ showBottomBanner: true });
    } else if (scrollPosition < window.innerHeight) {
      this.setState({ showBottomBanner: false });
    }
  };

  public renderMoreWorkshops = () => {
    const {
      moreWorkshops,
      calendar: { events: calendarEvents },
    } = this.props;

    if (!moreWorkshops || !moreWorkshops.length) {
      return null;
    }

    return (
      <div className="wrap mb20@s">
        <FeedHeader title={t('More workshops')} isLoaded={true} />
        <WorkshopMasonry
          workshops={moreWorkshops}
          calendarEvents={calendarEvents}
        />
      </div>
    );
  };

  public renderSimilarWorkshops = () => {
    const {
      sizing: { isDesktop, isTablet },
      workshop,
    } = this.props;

    const filteredWorkshop = this.state.similarWorkshops
      .filter((w) => w.id !== workshop.id)
      .slice(0, 4);

    return (
      <div className="wrap mb80">
        <FeedHeader title={t('Similar workshops')} isLoaded={true} />
        <WorkshopCarouselNew
          isWorkshopPage={true}
          theme="dark"
          workshops={filteredWorkshop}
          isLoaded={this.state.isSimilarWorkshopsLoaded}
          className="mb0"
          hideArrows={true}
          customCarouselProps={
            isDesktop
              ? {
                  wrap: 4,
                  buttonClassName:
                    'carousel__button--theme-xsmall u-link-black--alt u-hide u-flex@l',
                  buttonClassNameDisabled: 'u-very-translucent',
                  showFade: false,
                  itemClassName: 'u-flex',
                  expectedItems: 10,
                  equalHeight: true,
                }
              : isTablet
              ? {
                  columns: 1,
                  wrap: false,
                  buttonClassName: 'u-hide',
                  showFade: false,
                  itemClassName: 'u-flex',
                  equalHeight: true,
                }
              : undefined
          }
        />
      </div>
    );
  };

  private renderSectionPromo = () => (
    <div className="wrap pv64 u-flex@m pt100@m pb100@m">
      <div className="u-flex-1 u-text-center mh16 mb64@s">
        <div className="c-marketing-section-promo__circle mhauto mb32 mb44@m">
          <ImageLoader src={illoBulb} />
        </div>

        <h2 className="f-lynstone-bold-2 mb24">
          {t('Commit to your creativity')}
        </h2>

        <p className="f-lynstone-regular-3 u-grey">
          {widont(
            t(
              'Get unlimited access to 100+ instructors, with new classes added every day.'
            )
          )}
        </p>
      </div>

      <div className="u-flex-1 u-text-center mh16 mb64@s">
        <div className="c-marketing-section-promo__circle mhauto mb32 mb44@m">
          <ImageLoader src={illoGlobe} />
        </div>

        <h2 className="f-lynstone-bold-2 mb24">
          {t('Learn together on Daisie')}
        </h2>

        <p className="f-lynstone-regular-3 u-grey">
          {widont(
            t(
              'Connect with friends and join classes alongside creators from around the world.'
            )
          )}
        </p>
      </div>

      <div className="u-flex-1 u-text-center mh16 mb0@s">
        <div className="c-marketing-section-promo__circle mhauto mb32 mb44@m">
          <ImageLoader src={illoFolder} />
        </div>

        <h2 className="f-lynstone-bold-2 mb24">{t('All on your terms')}</h2>

        <p className="f-lynstone-regular-3 u-grey">
          {widont(
            t(
              'Live classes. Global community. Expert instructors. There’s something for everyone.'
            )
          )}
        </p>
      </div>
    </div>
  );

  private renderCategoryAndDateInBanner = () => {
    const {
      workshop: { categories, whenLabel },
    } = this.props;

    return (
      <div>
        <div className="f-text-5 u-grey u-text-right@m">
          <Icon
            id={`category-${categories[0].name.toLowerCase()}`}
            width="8px"
            className="mr6"
          />
          {categories[0].name}
        </div>
        <p className="f-text-3 pt4">{whenLabel.short}</p>
      </div>
    );
  };

  private renderWorkshopStatusInBanner = () => {
    const { workshop } = this.props;

    return (
      <div className="f-text-3 u-text-right@m">
        <p>{t('Workshop in progress')}</p>
        <WorkshopTimeRemaining workshop={workshop} />
      </div>
    );
  };

  private renderCallToAction = ({
    hidePricingInfo = false,
    hideCategoryAndDate = false,
  }: {
    hidePricingInfo?: boolean;
    hideCategoryAndDate?: boolean;
  }) => {
    const {
      workshop,
      auth: { isAuthorised },
      workshop: {
        priceLabel,
        isAttending,
        batches,
        isInProgress: workshopIsInProgress,
      },
      subscription: { productsAndPrices, userCurrency },
    } = this.props;

    const yearlyPrice = findProductAndPrice({
      productsAndPrices,
      currency: userCurrency,
      recurringInterval: SubscriptionInterval.year,
    });

    return (
      <p className="u-flex u-align-center u-justify-between@s">
        {priceLabel && !hidePricingInfo && (
          <div className="f-text-2 u-bold u-white mr24">
            {isInProgress(workshop.start, workshop.end) ? (
              <>{this.renderWorkshopStatusInBanner()}</>
            ) : isAuthorised && !hideCategoryAndDate ? (
              <>{this.renderCategoryAndDateInBanner()}</>
            ) : !isAuthorised ? (
              <div>
                {yearlyPrice
                  ? t(
                      `Daisie starts at ${getDisplayPrice(
                        yearlyPrice.currency,
                        Math.floor(yearlyPrice.amount / 12)
                      )}/month`
                    )
                  : null}
              </div>
            ) : undefined}
          </div>
        )}

        <div>
          {this.props.renderActionButton({
            isLoading: false,
            workshop,
            batchId: batches[0].id,
            sessionId: batches[0].sessions[0].id,
            isAttendingRaw: isAttending,
            isInProgress: !!workshopIsInProgress,
            showStartFreeTrialWhileLoggedOut: false,
            handleShowOnDemandPlayer: () => {
              this.setState({ showOnDemandPlayer: true }, () => {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              });
            },
            timeStart: new Date(workshop.start),
            timeEnd: new Date(workshop.end),
          })}
        </div>
      </p>
    );
  };

  private getCoverMediaUrl = (): string => {
    const {
      workshop: { coverMedia },
      sizing: { isMobile, isTablet },
    } = this.props;

    if (isMobile) {
      return coverMedia.replace('resize(1200', 'resize(800');
    } else if (isTablet) {
      return coverMedia.replace('resize(1200', 'resize(1200');
    } else {
      return coverMedia.replace('resize(1200', 'resize(1680');
    }
  };

  public render = () => {
    const {
      sizing: { isMobile, isDesktop, cookieBannerHeight },
      workshop,
      moreWorkshops,
      auth: { isAuthorised, user },
      isAttendeesLoaded,
      displayAttendees,
    } = this.props;

    const {
      showBottomBanner,
      viewportHeight,
      headerHeight,
      showBottomSection,
      showOnDemandPlayer,
      isMobileCached,
      isAutoAttending,
      moreInfoClicked,
    } = this.state;

    const {
      title,
      coverMedia,
      mentor,
      mentorWorkMedia,
      sections = [],
      whenLabel,
      description,
      onDemandMediaCatchup,
      hasElapsed,
    } = workshop;

    const hasBottomSectionCopy = !!sections.find(
      (s: WorkshopSection) => s.name === WorkshopSectionTemplate.bottomCopy
    );

    const hasBottomSectionImage = !!sections.find(
      (s: WorkshopSection) => s.name === WorkshopSectionTemplate.bottomImage
    );

    const hasBottomSections = hasBottomSectionCopy || hasBottomSectionImage;

    const finalHeaderHeight = isMobile
      ? headerHeight
      : `calc(${
          headerHeight ? `${headerHeight}px` : '(var(--vh, 1vh) * 100)'
        } - 72px - ${cookieBannerHeight}px)`;

    const isUnsubscribed: boolean =
      !isAuthorised ||
      (isAuthorised && !!user && user.subscriptionTier === null);

    return (
      <div
        className={c([], {
          mb64: moreWorkshops.length === 0,
        })}
      >
        {!isDesktop && !viewportHeight && (
          <div ref={this.refTester} className="u-100vw u-100vh" />
        )}

        <div
          className="c-workshop-cover relative u-bg-charcoal-grey"
          style={{
            height: finalHeaderHeight,
          }}
        >
          <ImageLoader
            src={this.getCoverMediaUrl()}
            className="absolute fill u-object-cover"
            convertToWebp={true}
          />

          <HeroBlurOverlay />

          {onDemandMediaCatchup && (
            <OnDemandPlayer
              className="absolute absolute--mid-center u-z-index-1"
              onDemandMedia={onDemandMediaCatchup}
              onClose={() => this.setState({ showOnDemandPlayer: false })}
              isVisible={showOnDemandPlayer}
              useMobilePlayer={isMobileCached}
            />
          )}

          <div
            className={c(
              'wrap u-h-100 absolute absolute--t absolute--x-center u-1/1 u-white animate-opacity',
              {
                'opacity-0 pointer-events-none': showOnDemandPlayer,
                'opacity-1': !showOnDemandPlayer,
              }
            )}
          >
            <div className="relative u-h-100 pt12@s">
              {isMobile && !hasElapsed && (
                <WorkshopShareButton workshop={workshop} isMobileHero={true} />
              )}

              <div className="absolute absolute--tl@s absolute--bl@m mb32@m mb80@l u-z-index-1 u-1/1 u-1/2@l u-flex@s u-flex-column@s u-justify-center@s u-align-center@s u-h-100@s">
                <WorkshopMetadata
                  workshop={workshop}
                  attendees={displayAttendees}
                  isAttendeesLoaded={isAttendeesLoaded}
                  className={c('', {
                    mb24:
                      isMobile &&
                      isAuthorised &&
                      !!user &&
                      user.subscriptionTier !== null,
                  })}
                />

                {isMobile && this.renderCallToAction({ hidePricingInfo: true })}
              </div>

              {!isMobile && (
                <div
                  className={c(
                    'u-white pv16@l mb32@m mb64@l absolute@m absolute--br u-1/2 u-flex u-justify-end animate-opacity',
                    {
                      'opacity-1': !isAutoAttending,
                      'opacity-0 pointer-events-none': isAutoAttending,
                    }
                  )}
                >
                  {this.renderCallToAction({
                    hidePricingInfo: false,
                    hideCategoryAndDate: true,
                  })}
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className={c('u-bg-black u-white u-overflow-hidden pv32 pv80@m', {
            'mb32 mb80': moreWorkshops.length > 0 && !hasBottomSections,
          })}
        >
          <div className="wrap u-flex@m mb44 mb128@l u-align-center">
            {!isMobile && (
              <div className="u-flex-1@m mb32@s">
                <WorkshopPageSection
                  sections={sections}
                  name={WorkshopSectionTemplate.topImage}
                />
              </div>
            )}

            {isMobile && (
              <div>
                <div>
                  <h2 className="f-title-2 pb12 u-x-bold">
                    {t('About this workshop')}
                  </h2>
                  <p className="u-grey f-text-2 pb24">{t(description)}</p>
                </div>

                {!moreInfoClicked && (
                  <div
                    className="u-line-behind u-line-behind--black u-grey mb32"
                    onClick={() => this.setState({ moreInfoClicked: true })}
                  >
                    <span className="u-border-radius f-text-3 b--sad-grey u-white">
                      {t('More info')}
                    </span>
                  </div>
                )}

                {moreInfoClicked && (
                  <WorkshopPageSection
                    sections={sections}
                    name={WorkshopSectionTemplate.topCopy}
                  />
                )}

                <div
                  className={c('', {
                    mt12: moreInfoClicked,
                  })}
                >
                  <div className="u-flex mb16">
                    <div className="u-flex-hold mr16">
                      <Avatar
                        size="xl"
                        alt={mentor.primaryName}
                        src={mentor.avatar}
                        user={mentor}
                      />
                    </div>

                    <div className="u-flex u-flex-column u-justify-center">
                      <p className="f-text-3 u-light-grey pb2">
                        {t('Hosted by')}
                      </p>
                      <h4 className="f-text-1 u-bold">{mentor.name}</h4>
                    </div>
                  </div>
                  <div>
                    <p className="f-text-2 u-line-height-1/2 u-grey u-word-break">
                      {sections[0].copy![0].content}
                    </p>
                  </div>
                </div>

                <Link
                  to={parser({
                    name: 'user',
                    params: {
                      username: mentor.username,
                    },
                  })}
                  className="u-line-behind u-line-behind--black u-grey mv32"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="u-border-radius f-text-3 b--sad-grey u-white">
                    {t('View Profile')}
                    <Icon id="person" width="11" className="ml6 u-light-grey" />
                  </span>
                </Link>
              </div>
            )}

            {!isMobile && (
              <div className="u-flex-1@m u-flex u-align-center u-justify-center">
                <WorkshopPageSection
                  sections={sections}
                  name={WorkshopSectionTemplate.topCopy}
                />
              </div>
            )}
          </div>

          {mentor && !isMobile && (
            <div
              className={c('wrap', {
                'mb60@s': !hasBottomSections,
              })}
            >
              <div className="wrap u-flex mb44 mb128@l u-align-end@m u-flex-column-reverse@s">
                <div className="u-flex-1@m mb32@s mt16@s">
                  <div className="u-7/8@m u-text-right@m">
                    {!isMobile && (
                      <Link
                        to={parser({
                          name: 'user',
                          params: {
                            username: mentor.username,
                          },
                        })}
                        className="u-truncate u-flex u-justify-end@m"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Avatar
                          size="xxxxl"
                          alt={mentor.primaryName}
                          src={mentor.avatar}
                          user={mentor}
                        />
                      </Link>
                    )}

                    <Link
                      to={parser({
                        name: 'user',
                        params: {
                          username: mentor.username,
                        },
                      })}
                      className="u-truncate u-flex u-justify-end@m"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h1 className="u-bold f-title-2 pt12@m pb8@m pb12@s u-text-right@m">
                        {mentor.primaryName}
                      </h1>
                    </Link>

                    <p
                      className="u-grey f-text-2 u-line-height-1/2 u-1/2@m u-text-right@m"
                      style={{
                        marginRight: 0,
                        marginLeft: 'auto',
                      }}
                    >
                      {widont(mentor.bio)}
                    </p>
                  </div>
                </div>
                <div className="u-flex-1@m u-flex u-align-center">
                  {mentorWorkMedia && (
                    <div className="">
                      <ImageLoader
                        src={mentorWorkMedia}
                        constrainSquare={false}
                        convertToWebp={true}
                      />
                    </div>
                  )}
                </div>
              </div>

              {hasBottomSections && (
                <>
                  <div className="u-line-behind u-line-behind--black u-black mv100@m mv24@s">
                    <span
                      className="u-border-radius f-text-5 b--sad-grey u-white c-workshop-more-info u-cursor-pointer"
                      onClick={() => {
                        this.setState({
                          showBottomSection: !showBottomSection,
                        });
                      }}
                    >
                      <button type="button">
                        {t(`Show ${showBottomSection ? 'less' : 'more'}`)}
                      </button>
                    </span>
                  </div>

                  {showBottomSection && (
                    <div className="mb60@s">
                      <div className="wrap u-flex@m pv32@s pb80@m u-align-center">
                        {hasBottomSectionImage && (
                          <div
                            className={c('u-flex-1@m', {
                              'mb44@s': hasBottomSectionCopy,
                            })}
                          >
                            <WorkshopPageSection
                              sections={sections}
                              name={WorkshopSectionTemplate.bottomImage}
                              showFullWidthImageCaption={!hasBottomSectionCopy}
                            />
                          </div>
                        )}

                        {hasBottomSectionCopy && (
                          <div className="u-flex-1@m">
                            <WorkshopPageSection
                              sections={sections}
                              name={WorkshopSectionTemplate.bottomCopy}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          {this.renderSimilarWorkshops()}

          {isUnsubscribed && (
            <div>
              {this.renderSectionPromo()}

              <div className="mt100@m mt80@s">
                <WorkshopReview />
              </div>
            </div>
          )}

          {isUnsubscribed && (
            <div className="wrap mv100@m mv44@s">
              <FAQ />
            </div>
          )}

          {this.renderMoreWorkshops()}

          <BottomBanner
            visible={showBottomBanner}
            className="c-workshop__banner u-bg-charcoal-grey"
            flexDirection="column"
          >
            <div className="u-flex u-split">
              <div className="u-flex u-align-center animate-opacity u-flex-hold u-overflow-hidden mr24 u-flex-no-hold">
                <ImageLoader
                  src={coverMedia}
                  className="c-workshop__banner__img mr16 u-flex-hold u-hide@s"
                  convertToWebp={true}
                />

                <div>
                  <p className="u-white f-text-5 mb4">{whenLabel.short}</p>

                  <p className="f-text-2 u-bold u-white mb2">{title}</p>

                  <YourMentorOrRoomHost
                    isLoading={false}
                    workshop={workshop}
                    attendees={displayAttendees}
                    isAttendeesLoaded={isAttendeesLoaded}
                    isCompact={true}
                  />
                </div>
              </div>

              <div className="u-flex-hold">
                {this.renderCallToAction({ hidePricingInfo: false })}
              </div>
            </div>
          </BottomBanner>
        </div>
      </div>
    );
  };
}

export const WorkshopWrapper = connect(
  ['auth', 'sizing', 'room', 'subscription', 'calendar'],
  () => ({ ...portalActions(), ...modalActions() })
)(addWorkshopComponentActions(withRouter(WorkshopWrapperComponent)));
