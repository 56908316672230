import { isMobile } from '@/utils/DOM/sizing';

export const pxToRem = (
  px: number,
  onlyNumber: boolean = false
): number | string =>
  onlyNumber ? Number(px || 0) / 16 : `${Number(px || 0) / 16}rem`;

export const basePaddingHorizontal = pxToRem(isMobile() ? 16 : 62);
export const basePaddingVertical = pxToRem(isMobile() ? 34 : 40);
