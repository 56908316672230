import * as React from 'react';

import {
  CardElement,
  Elements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { ReactCookieProps, withCookies } from 'react-cookie';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'unistore/react';
import widont from 'widont';

import { Icon } from '@/components/global/Icon/Icon';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { Link } from '@/components/global/Link/Link';
import { LoadingSymbol } from '@/components/global/LoadingSymbol/LoadingSymbol';
import { Toast } from '@/components/global/Toaster/Toast';
import { AnimatedWordmark } from '@/components/global/Wordmark/AnimatedWordmark';
import { Wordmark } from '@/components/global/Wordmark/Wordmark';
import imgApplePay from '@/components/subscription/CardBrandLogo/apple_pay.svg';
import {
  AB_TEST_DISCOUNT_PERCENT_OFF_ACTUAL_GBP_EUR,
  AB_TEST_DISCOUNT_PERCENT_OFF_ACTUAL_USD,
  AB_TEST_DISCOUNT_PERCENT_OFF_DISPLAY,
  AB_TEST_DISCOUNT_STRIPE_COUPON_NAME_GBP_EUR,
  AB_TEST_DISCOUNT_STRIPE_COUPON_NAME_USD,
  GRANDFATHER_DISCOUNT_PERCENT_OFF_MONTHLY_DISPLAY_GBP_EUR,
  GRANDFATHER_DISCOUNT_PERCENT_OFF_MONTHLY_DISPLAY_USD,
  GRANDFATHER_DISCOUNT_PERCENT_OFF_YEARLY_DISPLAY_GBP_EUR,
  GRANDFATHER_DISCOUNT_PERCENT_OFF_YEARLY_DISPLAY_USD,
  STUDENT_DISCOUNT_PERCENT_OFF_ACTUAL_GBP_EUR,
  STUDENT_DISCOUNT_PERCENT_OFF_ACTUAL_USD,
  STUDENT_DISCOUNT_PERCENT_OFF_DISPLAY_GBP_EUR,
  STUDENT_DISCOUNT_PERCENT_OFF_DISPLAY_USD,
  STUDENT_DISCOUNT_STRIPE_COUPON_NAME_GBP_EUR,
  STUDENT_DISCOUNT_STRIPE_COUPON_NAME_USD,
  TRIAL_LENGTH_TEXT_SINGULAR,
} from '@/components/subscription/constants';
import {
  STRIPE_ELEMENTS_CONFIG,
  STRIPE_ELEMENTS_FONTS,
} from '@/components/subscription/stripeElementsConfig';
import { SubscriptionError } from '@/components/subscription/SubscriptionError';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { Currency } from '@/enums/Currency';
import { DaisieWordmarkColour } from '@/enums/DaisieWordmarkColour';
import { GtmEvent } from '@/enums/GtmEvent';
import { IconSize } from '@/enums/IconSize';
import { SubscriptionInterval } from '@/enums/SubscriptionInterval';

import {
  STRIPE_SAVE_CARD_FAIL,
  SUBSCRIPTION_CREATE_FAIL,
  SUBSCRIPTION_DISCOUNT_FAIL,
  UNKNOWN_ERROR,
} from '@/messages/errors';

import imgApplePayWordmark from '@/pages/OptimisedJoinFlow/apple-pay-wordmark.svg';
import { getSubscriptionIntervalFromMatchedRoute } from '@/pages/OptimisedJoinFlow/helpers';
import imgJoinRadio from '@/pages/OptimisedJoinFlow/images/join-radio.png';
import imgJoinSlate from '@/pages/OptimisedJoinFlow/images/join-slate.png';
import imgJoinRocket from '@/pages/OptimisedJoinFlow/images/rocket.png';
import gift from '@/pages/OptimisedJoinFlow/images/gift.png';
import { OptimisedJoinFlowFrame } from '@/pages/OptimisedJoinFlow/OptimisedJoinFlowFrame';
import { OptimisedJoinFlowSkipCloseButton } from '@/pages/OptimisedJoinFlow/OptimisedJoinFlowSkipCloseButton';
import { OptimisedJoinFlowSurvey } from '@/pages/OptimisedJoinFlow/OptimisedJoinFlowSurvey';

import { routes } from '@/routes';

import {
  createStripeSubscription,
  findProductAndPrice,
  getPaymentIntent,
  getUserPromotionalCodes,
} from '@/store/helpers/subscriptionHelpers';
import { authActions } from '@/store/modules/auth';
import { subscriptionActions } from '@/store/modules/subscription';

import { enableApplePay } from '@/utils/featureToggles';
import { sendGtmEvent } from '@/utils/gtm/sendGtmEvent';
import { t } from '@/utils/i18n/i18n';
import { track } from '@/utils/mixpanel/mixpanel';
import { c } from '@/utils/strings/c';
import { getRouteFromUrl } from '@/utils/urls/routes';
import { modalActions } from '@/store/modules/modal';
import { portalActions } from '@/store/modules/portal';
import { isMobile } from '@/utils/DOM/sizing';
import { GlobalPortal } from '@/enums/GlobalPortal';
import { GlobalModal } from '@/enums/GlobalModal';
import { Head } from '@/components/global/Head/Head';
import { BOGO_COUPON_ID } from '@/utils/constants';

interface Props
  extends SubscriptionActions,
    SubscriptionState,
    AuthActions,
    AuthState,
    ABTestState,
    SizingState,
    ReactCookieProps,
    ModalActions,
    PortalActions,
    RouteComponentProps {}

interface State {
  hideSkipCloseButton: boolean;
  isSubmitting: boolean;
  nameOnCard: string;
  isCardFormComplete: boolean;
  isCardFormError: boolean;
  redirectToHome: boolean;
  isRenderingCardForm: boolean;
  isAnimatingCardFormOut: boolean;
  isAnimatingIn: boolean;
  isRenderingRemainingSteps: boolean;
  renderNextStep: number;
  redirectTo?: string;
  applePayPaymentRequest?: any;
  renderStripeForm: boolean;
  isAnimatingStripeFormOut: boolean;
  renderApplePayLoading: boolean;
  isApplePaySelected: boolean;
  isShowBogoGift: boolean;
  giftCode?: string;
}

const stripePromise = loadStripe(process.env.DAISIE_STRIPE_API_KEY || '');

class OptimisedJoinFlowPayComp extends React.Component<Props, State> {
  public state: State = {
    hideSkipCloseButton: false,
    isSubmitting: false,
    nameOnCard: '',
    isCardFormComplete: false,
    isCardFormError: false,
    redirectToHome: false,
    isRenderingCardForm: true,
    isAnimatingCardFormOut: false,
    isRenderingRemainingSteps: false,
    renderNextStep: 0,
    isAnimatingIn: false,
    redirectTo: undefined,
    applePayPaymentRequest: undefined,
    renderStripeForm: false,
    isAnimatingStripeFormOut: false,
    renderApplePayLoading: true,
    isApplePaySelected: true,
    isShowBogoGift: false,
  };

  private promotionalCode = localStorage.getItem('promotionalCode')
    ? // @ts-ignore
      JSON.parse(localStorage.getItem('promotionalCode'))
    : null;

  public componentDidMount = () => {
    const {
      auth: { user },
    } = this.props;

    if (!!user && user.subscriptionTier !== null) {
      this.setState({ redirectToHome: true });
      return;
    }

    if (!!user) {
      const hideSkipCloseButton = !!localStorage.getItem(
        `${user.id}_hide_join_flow_skip`
      );

      this.setState({ hideSkipCloseButton: hideSkipCloseButton });
    }

    this.initialiseApplePay();
  };

  private initialiseApplePay = async () => {
    if (!stripePromise || !enableApplePay()) {
      this.setState({
        renderStripeForm: true,
        renderApplePayLoading: false,
      });

      return;
    }

    stripePromise.then(async (stripe: Stripe | null) => {
      if (!stripe) {
        return;
      }

      await this.props.getStripeProductsAndPrices();

      const {
        auth: { user },
        subscription: { productsAndPrices, userCurrency },
        location: { pathname },
        abtest: { variant },
      } = this.props;

      const matchedRoute = getRouteFromUrl(pathname);
      const selectedSubscriptionInterval: SubscriptionInterval | undefined =
        getSubscriptionIntervalFromMatchedRoute(matchedRoute);

      if (!selectedSubscriptionInterval) {
        return;
      }

      const selectedProductPrice = findProductAndPrice({
        productsAndPrices,
        currency: userCurrency,
        recurringInterval: selectedSubscriptionInterval,
      });

      if (!selectedProductPrice) {
        return;
      }

      const isGrandfathered: boolean = this.isGrandfathered({ user });

      const hasStudentDiscount: boolean = this.hasStudentDiscount({
        user,
        matchedRoute,
        selectedSubscriptionInterval,
      });

      const hasABTestDiscount: boolean = this.hasABTestDiscount({
        user,
        hasStudentDiscount,
        variant,
        selectedSubscriptionInterval,
      });

      const freeTrialLabel: string =
        isGrandfathered &&
        selectedSubscriptionInterval === SubscriptionInterval.year
          ? ` with ${
              userCurrency === Currency.usd
                ? GRANDFATHER_DISCOUNT_PERCENT_OFF_YEARLY_DISPLAY_USD
                : GRANDFATHER_DISCOUNT_PERCENT_OFF_YEARLY_DISPLAY_GBP_EUR
            }% off`
          : isGrandfathered &&
            selectedSubscriptionInterval === SubscriptionInterval.month
          ? ` with ${
              userCurrency === Currency.usd
                ? GRANDFATHER_DISCOUNT_PERCENT_OFF_MONTHLY_DISPLAY_USD
                : GRANDFATHER_DISCOUNT_PERCENT_OFF_MONTHLY_DISPLAY_GBP_EUR
            }% off`
          : hasStudentDiscount &&
            selectedSubscriptionInterval === SubscriptionInterval.year
          ? ` with ${
              userCurrency === 'usd'
                ? STUDENT_DISCOUNT_PERCENT_OFF_DISPLAY_USD
                : STUDENT_DISCOUNT_PERCENT_OFF_DISPLAY_GBP_EUR
            }% off for students`
          : hasABTestDiscount &&
            selectedSubscriptionInterval === SubscriptionInterval.year
          ? ` with ${AB_TEST_DISCOUNT_PERCENT_OFF_DISPLAY}% off`
          : ` with ${TRIAL_LENGTH_TEXT_SINGULAR} free trial`;

      const displayAmount: number =
        hasStudentDiscount &&
        selectedSubscriptionInterval === SubscriptionInterval.year
          ? Math.floor(
              selectedProductPrice.amount *
                (1 -
                  (userCurrency === 'usd'
                    ? STUDENT_DISCOUNT_PERCENT_OFF_ACTUAL_USD
                    : STUDENT_DISCOUNT_PERCENT_OFF_ACTUAL_GBP_EUR) /
                    100)
            )
          : hasABTestDiscount &&
            selectedSubscriptionInterval === SubscriptionInterval.year
          ? Math.floor(
              selectedProductPrice.amount *
                (1 -
                  (userCurrency === 'usd'
                    ? AB_TEST_DISCOUNT_PERCENT_OFF_ACTUAL_USD
                    : AB_TEST_DISCOUNT_PERCENT_OFF_ACTUAL_GBP_EUR) /
                    100)
            )
          : selectedProductPrice.amount;

      const pr = stripe.paymentRequest({
        country: 'GB',
        currency: selectedProductPrice.currency,
        total: {
          label: `Daisie – ${
            selectedSubscriptionInterval === SubscriptionInterval.year
              ? t('yearly')
              : t('monthly')
          } subscription${freeTrialLabel}`,
          amount: displayAmount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        // @ts-ignore - hides unsupported wallets from rendering the "pay with" button, seems missing from typings
        disableWallets: ['googlePay', 'browserCard'],
      });

      const canMakePayment = await pr.canMakePayment();

      if (!canMakePayment) {
        this.setState({
          renderStripeForm: true,
          renderApplePayLoading: false,
        });

        return;
      }

      this.setState(
        {
          applePayPaymentRequest: pr,
          renderStripeForm: true,
          renderApplePayLoading: false,
        },
        () => {
          pr.on('paymentmethod', this.handleApplePay);
        }
      );
    });
  };

  private handleApplePay = async (ev: any) => {
    const { cookies } = this.props;

    stripePromise.then(async (stripe: Stripe | null) => {
      if (!stripe) {
        ev.complete('fail');

        new Toast({
          body: STRIPE_SAVE_CARD_FAIL,
          failure: true,
        }).dispatch();

        return;
      }

      try {
        const paymentIntent = await getPaymentIntent();

        await stripe.confirmCardSetup(paymentIntent, {
          payment_method: ev.paymentMethod.id,
        });
      } catch (e) {
        ev.complete('fail');

        new Toast({
          body: STRIPE_SAVE_CARD_FAIL,
          failure: true,
        }).dispatch();

        return;
      }

      ev.complete('success');
      this.hideStripePaymentFormForApplePay();

      const {
        auth: { user },
        subscription: { productsAndPrices, userCurrency, coupons },
        location: { pathname },
        abtest: { variant },
      } = this.props;

      const matchedRoute = getRouteFromUrl(pathname);
      const selectedSubscriptionInterval: SubscriptionInterval | undefined =
        getSubscriptionIntervalFromMatchedRoute(matchedRoute);

      if (!selectedSubscriptionInterval) {
        this.showStripePaymentFormAfterApplePayFail();

        new Toast({
          body: STRIPE_SAVE_CARD_FAIL,
          failure: true,
        }).dispatch();

        return;
      }

      const selectedProductPrice = findProductAndPrice({
        productsAndPrices,
        currency: userCurrency,
        recurringInterval: selectedSubscriptionInterval,
      });

      if (!selectedProductPrice) {
        this.showStripePaymentFormAfterApplePayFail();

        new Toast({
          body: STRIPE_SAVE_CARD_FAIL,
          failure: true,
        }).dispatch();

        return;
      }

      const hasStudentDiscount: boolean = this.hasStudentDiscount({
        user,
        matchedRoute,
        selectedSubscriptionInterval,
      });

      const hasABTestDiscount: boolean = this.hasABTestDiscount({
        user,
        hasStudentDiscount,
        variant,
        selectedSubscriptionInterval,
      });

      const coupon: StripeCoupon | undefined = this.getCoupon({
        hasABTestDiscount,
        coupons,
        userCurrency,
        hasStudentDiscount,
      });

      if ((hasABTestDiscount || hasStudentDiscount) && !coupon) {
        this.showStripePaymentFormAfterApplePayFail();

        new Toast({
          body: SUBSCRIPTION_DISCOUNT_FAIL,
          failure: true,
        }).dispatch();

        return;
      }

      try {
        await createStripeSubscription({
          priceId: selectedProductPrice.priceId,
          paymentMethodId: ev.paymentMethod.id,
          couponId:
            (hasABTestDiscount || hasStudentDiscount) && coupon
              ? coupon.id
              : undefined,
          skipFreeTrial:
            ((hasABTestDiscount || hasStudentDiscount) && coupon) ||
            this.promotionalCode ||
            variant === 1
              ? true
              : undefined,
          referrerWorkshopId: cookies?.get('referrerWorkshopId'),
          promotionalCode: this.promotionalCode || undefined,
        });

        this.afterCardSubmit();
      } catch (e) {
        this.showStripePaymentFormAfterApplePayFail();

        new Toast({
          body: STRIPE_SAVE_CARD_FAIL,
          failure: true,
        }).dispatch();

        return;
      }
    });
  };

  private isGrandfathered = ({ user }: { user?: User | null }): boolean =>
    !!user && user.isGrandfathered;

  private hasStudentDiscount = ({
    user,
    matchedRoute,
    selectedSubscriptionInterval,
  }: {
    user?: User | null;
    matchedRoute: string;
    selectedSubscriptionInterval: SubscriptionInterval;
  }): boolean => {
    const isGrandfatheredUser: boolean = !!user && user.isGrandfathered;

    return (
      !isGrandfatheredUser &&
      matchedRoute === 'studentsSignUpYearlyPay' &&
      selectedSubscriptionInterval === SubscriptionInterval.year
    );
  };

  private hasABTestDiscount = ({
    user,
    hasStudentDiscount,
    variant,
    selectedSubscriptionInterval,
  }: {
    user?: User | null;
    hasStudentDiscount: boolean;
    selectedSubscriptionInterval: SubscriptionInterval;
    variant?: number;
  }) => {
    const isGrandfatheredUser: boolean = !!user && user.isGrandfathered;

    return (
      !isGrandfatheredUser &&
      !hasStudentDiscount &&
      typeof variant !== 'undefined' &&
      variant === 2 &&
      selectedSubscriptionInterval === SubscriptionInterval.year
    );
  };

  private getCoupon = ({
    hasABTestDiscount,
    coupons,
    userCurrency,
    hasStudentDiscount,
  }: {
    hasABTestDiscount: boolean;
    coupons: StripeCoupon[];
    userCurrency: any;
    hasStudentDiscount: boolean;
  }): StripeCoupon | undefined =>
    hasABTestDiscount
      ? coupons.find((c: StripeCoupon) =>
          userCurrency === 'usd'
            ? c.name === AB_TEST_DISCOUNT_STRIPE_COUPON_NAME_USD
            : c.name === AB_TEST_DISCOUNT_STRIPE_COUPON_NAME_GBP_EUR
        )
      : hasStudentDiscount
      ? coupons.find((c: StripeCoupon) =>
          userCurrency === 'usd'
            ? c.name === STUDENT_DISCOUNT_STRIPE_COUPON_NAME_USD
            : c.name === STUDENT_DISCOUNT_STRIPE_COUPON_NAME_GBP_EUR
        )
      : undefined;

  private handleStripe = async (elements: any, stripe: any) => {
    const {
      auth: { user },
      subscription: { productsAndPrices, userCurrency, coupons },
      location: { pathname },
      abtest: { variant },
      cookies,
    } = this.props;

    this.setState({ isSubmitting: true });

    const matchedRoute = getRouteFromUrl(pathname);
    const selectedSubscriptionInterval: SubscriptionInterval | undefined =
      getSubscriptionIntervalFromMatchedRoute(matchedRoute);

    const { nameOnCard } = this.state;

    try {
      if (!nameOnCard || !selectedSubscriptionInterval) {
        throw new Error(SubscriptionError.unknown);
      }

      const selectedProductPrice = findProductAndPrice({
        productsAndPrices,
        currency: userCurrency,
        recurringInterval: selectedSubscriptionInterval,
      });

      if (!selectedProductPrice) {
        throw new Error(SubscriptionError.unknown);
      }

      const paymentIntent = await getPaymentIntent();

      const result = await stripe.confirmCardSetup(paymentIntent, {
        payment_method: {
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            name: nameOnCard,
          },
        },
      });

      const paymentMethodId = result.setupIntent.payment_method;

      if (!paymentMethodId) {
        throw new Error(SubscriptionError.unknown);
      }

      const hasStudentDiscount: boolean = this.hasStudentDiscount({
        user,
        matchedRoute,
        selectedSubscriptionInterval,
      });

      const hasABTestDiscount: boolean = this.hasABTestDiscount({
        user,
        hasStudentDiscount,
        variant,
        selectedSubscriptionInterval,
      });

      const coupon: StripeCoupon | undefined = this.getCoupon({
        hasABTestDiscount,
        coupons,
        userCurrency,
        hasStudentDiscount,
      });

      if ((hasABTestDiscount || hasStudentDiscount) && !coupon) {
        throw new Error(SubscriptionError.unable_to_apply_discount);
      }

      await createStripeSubscription({
        priceId: selectedProductPrice.priceId,
        paymentMethodId,
        couponId:
          (hasABTestDiscount || hasStudentDiscount) && coupon
            ? coupon.id
            : variant === 1
            ? BOGO_COUPON_ID
            : undefined,
        skipFreeTrial:
          ((hasABTestDiscount || hasStudentDiscount) && coupon) ||
          this.promotionalCode ||
          variant === 1
            ? true
            : undefined,
        referrerWorkshopId: cookies?.get('referrerWorkshopId'),
        promotionalCode: this.promotionalCode || undefined,
      });

      this.afterCardSubmit();
    } catch (e) {
      // @ts-ignore
      switch (e.message) {
        case SubscriptionError.unable_to_create_subscription:
          new Toast({
            body: SUBSCRIPTION_CREATE_FAIL,
            failure: true,
          }).dispatch();
          break;
        case SubscriptionError.unknown:
          new Toast({
            body: UNKNOWN_ERROR,
            failure: true,
          }).dispatch();
          break;
        case SubscriptionError.unable_to_apply_discount:
          new Toast({
            body: SUBSCRIPTION_DISCOUNT_FAIL,
            failure: true,
          }).dispatch();
          break;
        default:
          // Assume it's a Stripe error
          new Toast({ body: STRIPE_SAVE_CARD_FAIL, failure: true }).dispatch();
          break;
      }

      this.setState({ isSubmitting: false });
    }
  };

  private afterCardSubmit = async () => {
    const {
      auth: { user },
      abtest: { variant },
      location: { search },
    } = this.props;

    if (!user) {
      return;
    }

    localStorage.setItem(`${user.id}_is_post_subscription`, 'true');
    localStorage.removeItem(`promotionalCode`);

    await this.props.getCurrentUser(true, true);
    await this.props.getUserSubscription(user.username);

    sendGtmEvent(GtmEvent.SignedUpAndSubscribed);

    track('subscription_payment_complete', {
      variant: typeof variant !== 'undefined' ? variant.toString() : undefined,
    });

    const promotionalCodes = await getUserPromotionalCodes();
    const bogoCode = promotionalCodes.find(
      (promoCode: any) => promoCode.couponName === 'BOGO'
    );

    if (bogoCode) {
      // Show gift screen
      this.setState({
        isShowBogoGift: true,
        isRenderingCardForm: false,
        isRenderingRemainingSteps: false,
        giftCode: bogoCode.code,
      });
      return;
    }

    this.redirectAfterPurchases();
  };

  private redirectAfterPurchases = () => {
    const {
      location: { search },
    } = this.props;

    this.setState({ isAnimatingCardFormOut: true }, () => {
      setTimeout(() => {
        this.setState({ isRenderingCardForm: false }, () => {
          const queryParams = new URLSearchParams(search);
          const redirectTo = queryParams.get('to') || undefined;

          if (!!redirectTo) {
            this.setState({
              redirectTo: redirectTo.includes('/workshops/')
                ? `${redirectTo}?autoattend=true`
                : redirectTo,
            });
          } else {
            this.setState({ isRenderingRemainingSteps: true }, () => {
              this.setState({ isAnimatingIn: true });
            });
          }
        });
      }, 250);
    });
  };

  private hideStripePaymentFormForApplePay = () => {
    this.setState({ isAnimatingStripeFormOut: true }, () => {
      setTimeout(() => {
        this.setState(
          {
            renderStripeForm: false,
            renderApplePayLoading: true,
          },
          () => {
            setTimeout(() => {
              this.setState({ isAnimatingStripeFormOut: false });
            }, 1);
          }
        );
      }, 250);
    });
  };

  private showStripePaymentFormAfterApplePayFail = () => {
    this.setState({ isAnimatingStripeFormOut: true }, () => {
      setTimeout(() => {
        this.setState(
          {
            renderStripeForm: true,
            renderApplePayLoading: false,
          },
          () => {
            setTimeout(() => {
              this.setState({ isAnimatingStripeFormOut: false });
            }, 1);
          }
        );
      }, 250);
    });
  };

  public incrementStep = () => {
    this.setState({ renderNextStep: this.state.renderNextStep + 1 });
  };

  private showBogoOfferModal = () => {
    this.props.updateModal({
      modal: GlobalModal.bogo_offer,
      data: {
        promotionalCode: this.state.giftCode,
      },
      onClose: () => {
        this.setState({
          isShowBogoGift: false,
        });
        this.redirectAfterPurchases();
      },
      className: 'page-modal__content--medium',
    });
  };

  public render = () => {
    const {
      hideSkipCloseButton,
      isSubmitting,
      nameOnCard,
      isCardFormComplete,
      isCardFormError,
      redirectToHome,
      isAnimatingCardFormOut,
      isRenderingCardForm,
      isRenderingRemainingSteps,
      isAnimatingIn,
      redirectTo,
      applePayPaymentRequest,
      renderStripeForm,
      isAnimatingStripeFormOut,
      renderApplePayLoading,
      renderNextStep,
      isApplePaySelected,
      isShowBogoGift,
    } = this.state;

    const {
      abtest: { variant },
      location: { pathname },
      auth: { user },
      sizing: { isMobile },
    } = this.props;

    if (!!redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    if (redirectToHome) {
      return <Redirect to={routes.home} />;
    }

    const hasEnteredNameOnCard = nameOnCard !== '';

    const isSubmitButtonDisabled =
      !hasEnteredNameOnCard || !isCardFormComplete || isCardFormError;

    const matchedRoute = getRouteFromUrl(pathname);
    const selectedSubscriptionInterval: SubscriptionInterval | undefined =
      getSubscriptionIntervalFromMatchedRoute(matchedRoute);

    const isGrandfatheredUser: boolean = !!user && user.isGrandfathered;

    const hasStudentDiscount: boolean = false;

    const hasABTestDiscount: boolean =
      !isGrandfatheredUser &&
      !hasStudentDiscount &&
      typeof variant !== 'undefined' &&
      variant === 2 &&
      selectedSubscriptionInterval === SubscriptionInterval.year;

    const isUserComingFromWorkshop = window.location.href.includes(
      '?to=%2Fworkshops%2F'
    );
    const workshopSubscriptionVariant = Number(
      localStorage.getItem('workshop_subscription_variant')
    );

    const buttonSubmitText: string =
      hasStudentDiscount ||
      hasABTestDiscount ||
      isGrandfatheredUser ||
      variant === 1 ||
      this.promotionalCode
        ? t('Buy now')
        : !!user && user.hasPastSub
        ? t('Upgrade now')
        : t(
            `Start ${
              workshopSubscriptionVariant && isUserComingFromWorkshop
                ? 'your '
                : ''
            }free trial`
          );

    const classNameApplePaySelector: string =
      'u-border-radius--s u-flex u-align-center ph20 pv16 u-flex-1';

    const classNameApplePaySelectorActive: string =
      'bh bv bh bv bh--dark-grey bv--dark-grey';

    const classNameApplePaySelectorInactive: string =
      'bh bv bh--white-hint bv--white-hint u-devil-grey';

    const showApplePayCreditCardSelector: boolean =
      !!applePayPaymentRequest && !renderApplePayLoading;

    return (
      <>
        <div
          className="wrap u-1/1 absolute absolute--x-center u-z-index-2 u-flex u-align-center u-justify-center u-hide@s"
          style={{ height: 72 }}
        >
          <Link to={routes.home}>
            <Wordmark
              colour={DaisieWordmarkColour.White}
              height="20px"
              className="u-hide@m absolute absolute--x-center"
            />
            <AnimatedWordmark
              className="u-hide@s"
              colour={DaisieWordmarkColour.White}
            />
          </Link>
        </div>

        {isShowBogoGift && (
          <div
            className={c([
              'u-h-100vh u-flex u-align-center u-justify-center u-white animate',
            ])}
          >
            <Head
              pathname={this.props.location.pathname}
              isNoindexPage={true}
            />
            <div className="u-h-100@m u-flex u-flex-column u-align-center u-justify-center">
              <ImageLoader
                src={gift}
                style={{ maxWidth: '200px' }}
                className="ph32 mb24"
              />
              <h1 className="f-lynstone-bold-1b mb24 u-text-center@s">
                Free gift!
              </h1>
              <p className="u-text-center mb32 f-text-1 u-grey">
                {widont(t('For you to share with someone special'))}
              </p>
              <Button
                type={ButtonType.action}
                onClick={() => {
                  this.showBogoOfferModal();
                }}
                buttonStyle={ButtonStyle.default}
                size={ButtonSize.xl}
                text={'Share'}
              />
            </div>
          </div>
        )}

        {isRenderingCardForm && (
          <>
            {!hideSkipCloseButton && (
              <OptimisedJoinFlowSkipCloseButton
                type="close"
                linkTo={routes.home}
              />
            )}

            <OptimisedJoinFlowFrame
              isOptimisedJoinFlowSignUp={isUserComingFromWorkshop}
              hideLogo={true}
              className={c('animate-opacity', {
                'opacity-1': !isAnimatingCardFormOut,
                'opacity-0 pointer-events-none': isAnimatingCardFormOut,
              })}
            >
              <div
                className={c('u-1/1 animate-opacity u-flex u-flex-column', {
                  'pv64@m': !isUserComingFromWorkshop,
                  'pv44@m': isUserComingFromWorkshop,
                  'opacity-1': !isAnimatingStripeFormOut,
                  'opacity-0': isAnimatingStripeFormOut,
                  relative: showApplePayCreditCardSelector && !isMobile,
                  'pl128@m': !isUserComingFromWorkshop,
                  'mv80@m': renderApplePayLoading,
                })}
                style={
                  showApplePayCreditCardSelector &&
                  !isMobile &&
                  !isUserComingFromWorkshop
                    ? { height: 418, top: 68 }
                    : undefined
                }
              >
                <div
                  className={c('', {
                    'u-7/8': isUserComingFromWorkshop && !isMobile,
                  })}
                >
                  {showApplePayCreditCardSelector && (
                    <div className="u-flex@m mb24">
                      <button
                        type="button"
                        className={c(
                          [classNameApplePaySelector, 'mb12@s mr8@m u-1/1@s'],
                          {
                            [classNameApplePaySelectorActive]:
                              isApplePaySelected,
                            [classNameApplePaySelectorInactive]:
                              !isApplePaySelected,
                          }
                        )}
                        onClick={() =>
                          this.setState({ isApplePaySelected: true })
                        }
                      >
                        <img
                          src={imgApplePay}
                          alt=""
                          className={c('mr16 animate-opacity', {
                            'u-translucent': !isApplePaySelected,
                            'opacity-1': isApplePaySelected,
                          })}
                        />
                        <span>{t('Apple Pay')}</span>

                        {isApplePaySelected && (
                          <Icon
                            id="tick-circle"
                            size={IconSize.m}
                            className="mlauto u-go"
                          />
                        )}
                      </button>

                      <button
                        type="button"
                        className={c(
                          [classNameApplePaySelector, 'ml8@m u-1/1@s'],
                          {
                            [classNameApplePaySelectorActive]:
                              !isApplePaySelected,
                            [classNameApplePaySelectorInactive]:
                              isApplePaySelected,
                          }
                        )}
                        onClick={() =>
                          this.setState({ isApplePaySelected: false })
                        }
                      >
                        <Icon
                          id="credit-card"
                          size={IconSize.m}
                          className="ml4 mr20"
                        />
                        <span>{t('Credit Card')}</span>

                        {!isApplePaySelected && (
                          <Icon
                            id="tick-circle"
                            size={IconSize.m}
                            className="mlauto u-go"
                          />
                        )}
                      </button>
                    </div>
                  )}

                  {renderStripeForm && (
                    <Elements
                      stripe={stripePromise}
                      options={{ fonts: STRIPE_ELEMENTS_FONTS }}
                    >
                      <ElementsConsumer>
                        {({ elements, stripe }) => (
                          <>
                            {((showApplePayCreditCardSelector &&
                              !isApplePaySelected) ||
                              !showApplePayCreditCardSelector) && (
                              <>
                                <input
                                  type="text"
                                  placeholder={t('Name on card')}
                                  className="ph24 pv20 u-white input--dark u-border-radius mb12"
                                  onChange={(e: any) =>
                                    this.setState({
                                      nameOnCard: e.target.value,
                                    })
                                  }
                                  maxLength={128}
                                />

                                <div className="ph24 pv20 mb24 u-bg-charcoal-grey u-border-radius">
                                  <CardElement
                                    options={STRIPE_ELEMENTS_CONFIG}
                                    onChange={(e: any) => {
                                      this.setState({
                                        isCardFormComplete: e.complete,
                                        isCardFormError: !!e.error,
                                      });
                                    }}
                                  />
                                </div>

                                <Button
                                  type={ButtonType.action}
                                  onClick={() => {
                                    if (isUserComingFromWorkshop) {
                                      track('workshop_subscription_variant', {
                                        variant: Number(
                                          workshopSubscriptionVariant
                                        ),
                                      });
                                    }
                                    this.handleStripe(elements, stripe);
                                  }}
                                  buttonStyle={ButtonStyle.default}
                                  size={ButtonSize.xl}
                                  text={buttonSubmitText}
                                  className={c('mhauto', {
                                    'u-1/1@s': isUserComingFromWorkshop,
                                  })}
                                  disabled={isSubmitButtonDisabled}
                                  isLoading={isSubmitting}
                                />
                              </>
                            )}

                            {!!applePayPaymentRequest && isApplePaySelected && (
                              <button
                                type="button"
                                className="c-apple-pay-button u-1/1"
                                onClick={() => {
                                  if (applePayPaymentRequest) {
                                    applePayPaymentRequest.show();
                                  }
                                }}
                              >
                                <span className="mr6">
                                  {t('Subscribe with')}
                                </span>
                                <img
                                  src={imgApplePayWordmark}
                                  alt={t('Apple Pay')}
                                  className=""
                                />
                              </button>
                            )}
                          </>
                        )}
                      </ElementsConsumer>
                    </Elements>
                  )}
                </div>
                {renderApplePayLoading && (
                  <div
                    className={c('', { 'u-7/8@m': isUserComingFromWorkshop })}
                  >
                    <LoadingSymbol
                      size="l"
                      colour="white"
                      className="mhauto u-flex u-justify-center u-align-center"
                    />
                  </div>
                )}
              </div>
            </OptimisedJoinFlowFrame>
          </>
        )}

        {isRenderingRemainingSteps && (
          <>
            {!isMobile && (
              <OptimisedJoinFlowSkipCloseButton
                type="skip"
                linkTo={routes.home}
              />
            )}

            <div
              className={c(
                'u-white u-1/1 u-h-100vh@m u-flex u-align-center u-justify-center animate-opacity pt32@s',
                {
                  'opacity-0 pointer-events-none': !isAnimatingIn,
                  'opacity-1': isAnimatingIn,
                  wrap: isMobile,
                  'c-join-flow__pay--background pt60@s': renderNextStep === 2,
                  'pt100@s': renderNextStep >= 0,
                }
              )}
            >
              {renderNextStep === 0 && (
                <div className="u-flex u-justify-center u-align-center u-flex-column">
                  <ImageLoader
                    src={imgJoinRocket}
                    className="u-1/2@s u-1/4@m"
                  />
                  <h1 className="f-lynstone-bold-1b u-text-center mv20">
                    {widont(t('Congrats!'))}
                  </h1>
                  <p className="u-text-center mb32 mb64@l f-text-1 u-grey">
                    {widont(t('Welcome to the Daisie community.'))}
                  </p>
                  <Button
                    type={ButtonType.action}
                    onClick={() => {
                      this.incrementStep();
                    }}
                    buttonStyle={ButtonStyle.default}
                    size={ButtonSize.xl}
                    text="Get started"
                    className="mhauto"
                  />
                </div>
              )}

              {renderNextStep === 1 && (
                <OptimisedJoinFlowSurvey
                  onContinue={() => {
                    this.incrementStep();
                  }}
                />
              )}

              {renderNextStep === 2 && (
                <div className="u-flex u-align-center@m u-flex-column u-text-center@m">
                  <h1 className="f-lynstone-bold-1b mb20">
                    {widont(t("Let's get creative."))}
                  </h1>
                  <p className="mb20@l f-title-3 u-grey@m u-2/5@l u-3/4@m mb32@s">
                    {widont(
                      t(
                        "Ask questions, get tailored feedback and learn from the industry's brightest creators."
                      )
                    )}
                  </p>

                  <div className="u-flex u-flex-row@m u-flex-column@s u-2/3@m">
                    <div className="u-flex u-flex-column@m u-align-center pb24@s mr44@l">
                      <div className="u-flex@m u-justify-center@m u-1/4@s u-7/8@l c-join-flow__pay--icon-container">
                        <ImageLoader
                          src={imgJoinRadio}
                          className="u-1/3@m c-join-flow__pay--icon"
                        />
                      </div>
                      <div className="u-flex u-flex-column ml12@s u-1/1@s u-7/8@m">
                        <h1 className="f-lynstone-bold-3 mt32@m mb6">
                          {widont(t('Watch back, anytime'))}
                        </h1>
                        <p className="mb32@m f-text-1 u-grey">
                          {widont(
                            t(
                              'Head to the Classes tab to tune in to learning on-demand.'
                            )
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="u-flex u-flex-column@m u-align-center">
                      <div className="u-flex@m u-justify-center@m u-1/4@s u-7/8@l c-join-flow__pay--icon-container">
                        <ImageLoader
                          src={imgJoinSlate}
                          className="u-1/3@m c-join-flow__pay--icon"
                        />
                      </div>
                      <div className="u-flex u-flex-column ml12@s u-1/1@s u-7/8@m">
                        <h1 className="f-lynstone-bold-3 mt32@m mb6">
                          {widont(t('Bite-sized learning'))}
                        </h1>
                        <p className="mb32@m f-text-1 u-grey">
                          {widont(
                            t(
                              'Get your creativity flowing with short clips curated by our team.'
                            )
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  <Button
                    type={ButtonType.action}
                    onClick={() => {
                      this.setState({ redirectToHome: true });
                    }}
                    buttonStyle={ButtonStyle.default}
                    size={ButtonSize.xl}
                    text="Continue"
                    className="mhauto mv32"
                  />
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  };
}

export const OptimisedJoinFlowPay = connect(
  ['subscription', 'auth', 'abtest', 'sizing'],
  (store: GlobalStoreState) => ({
    ...subscriptionActions(store),
    ...authActions(store),
    ...modalActions(),
    ...portalActions(),
  })
)(withRouter(withCookies(OptimisedJoinFlowPayComp)));
