/**
 * Combines a string of static classes and an object of conditional classes.
 * You must always provide the first argument, use an empty string if you do not need any static classes.
 *
 * @param sc - Static string/array of classnames
 * @param cc - Object of conditional classes
 *
 * @returns class string
 *
 * @example
 * ```tsx
 * <div className={c('display-block background-blue', { 'background-red': this.state.hasError })}>
 *  <p>I have lots of classes<p>
 * </div>
 * ```
 */

const c = (sc: string | string[] = '', cc: DynamicObject = {}) =>
  (typeof sc === 'string' ? [sc] : sc)
    .concat(Object.keys(cc).filter((k) => !!cc[k]))
    .filter(Boolean)
    .join(' ')
    .trim();

export { c };
