import * as React from 'react';

import { connect } from 'unistore/react';

import { track } from '@/utils/mixpanel/mixpanel';

interface Props extends AuthState {
  trigger: ({ onClick }: { onClick: () => void }) => React.ReactNode;
}

class FeedbackButtonComponent extends React.Component<Props> {
  private bootIntercom = (shouldLoad: boolean = false) => {
    const { user } = this.props.auth;

    const intercomSdk = document.querySelector('#intercomSdk');

    track('press_feedback_btn');

    const userData = !!user
      ? {
          email: user.email,
          name: user.name,
          avatar: {
            type: 'avatar',
            image_url: user.avatar,
          },
          client: 'web',
          user_id: user.username,
          username: user.username,
          created_at: user.createdAt,
        }
      : {};

    if (!intercomSdk && shouldLoad) {
      this.lazyLoadSdk();
    } else {
      (window as any).Intercom('boot', {
        app_id: (window as any).INTERCOM_ID,
        ...userData,
      });
      (window as any).Intercom('show');
      (window as any).Intercom('onHide', () => {
        (window as any).Intercom('shutdown');
      });
    }
  };

  private lazyLoadSdk = () => {
    const script = document.createElement('script');
    script.src = '/scripts/intercom.js';
    script.id = 'intercomSdk';
    document.head.appendChild(script);

    script.onload = () => {
      window.dispatchEvent(new Event('loadIntercomSdk'));
      this.bootIntercom(false);
    };
  };

  public render() {
    return this.props.trigger({
      onClick: () => {
        this.bootIntercom(false);
      },
    });
  }
}

export const FeedbackButton = connect('auth')(FeedbackButtonComponent);
