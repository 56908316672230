export const accordionData = [
  {
    title: '001 — Character Creation',
    titleTags: ['5 Classes', '1 Hour', '10 Additional Resources'],
    episodes: [
      {
        title: '01 Meet Your Instructor',
        description:
          'Start off by learning about your instructor, Alex, and his artistic preferences.',
        duration: '02:45',
        classIndex: 1,
      },
      {
        title: '02 Generating New Ideas',
        description:
          'Discover Alex’s best practices for getting started and generating new ideas.',
        duration: '04:02',
        classIndex: 2,
      },
      {
        title: '03 Preparing Your Workspace',
        description:
          'Discover the importance of setting the right atmosphere and having the appropriate materials.',
        duration: '19:22',
        classIndex: 3,
      },
      {
        title: '04 Creating A Storyboard',
        description:
          'Learn how to create a captivating storyboard with a clear narrative structure.',
        duration: '13:40',
        resources: true,
        classIndex: 4,
      },
      {
        title: '05 Sketch To Screen',
        description:
          'Learn how to transfer your storyboard from paper to digital format using an iPad Pro and Procreate.',
        duration: '01:51',
        resources: true,
        classIndex: 5,
      },
    ],
  },
  {
    title: '002 — Crafting Narrative',
    titleTags: ['7 Classes', '1.5 Hours', '14 Additional Resources'],
    episodes: [
      {
        title: '06 Introduction To Procreate',
        description: 'Learn how to set up your Procreate space to get started.',
        duration: '12:07',
        classIndex: 6,
      },
      {
        title: '07 Procreate: Techniques & Tools',
        description:
          'Continue learning about the basic tools and techniques of Procreate.',
        duration: '10:15',
        classIndex: 7,
      },
      {
        title: '08 Planning The Narrative',
        description:
          'Learn how to use narrative (mood, colour and more) to bring your comic to life.',
        duration: '02:50',
        classIndex: 8,
      },
      {
        title: '09 Building Your Comic Frame Part 1',
        description:
          'Start building your comic frame by making creative decisions.',
        duration: '08:07',
        classIndex: 9,
      },
      {
        title: '10 Building Your Comic Frame Part 2',
        description:
          'Continue building your comic frame and learn how to use different tools to create characters and more.',
        duration: '06:41',
        classIndex: 10,
      },
      {
        title: '11 Understanding Scale and Perspective',
        description:
          'Discover how to use scale and perspective tools to add depth and mood to your animation.',
        duration: '11:04',
        classIndex: 11,
      },
      {
        title: '12 Adding Expression To Your Character',
        description:
          'Discover how to use scale and perspective tools to add depth and mood to your animation.',
        duration: '03:19',
        classIndex: 12,
      },
    ],
  },
  {
    title: '003 — ANIMATING',
    titleTags: ['9 Classes', '2.5 Hours', '18 Additional Resources'],
    episodes: [
      {
        title: '13 Photoshop: Layers & Colours',
        description:
          'Discover essential photoshop techniques on how to merge layers, use references and more.',
        duration: '21:45',
        classIndex: 13,
      },
      {
        title: '14 Photoshop: The Animator’s Toolbar',
        description:
          'Get an overview of how to use Photoshops Animator Toolbar to help complete your animation.',
        duration: '18:46',
        classIndex: 14,
      },
      {
        title: '15 The Animation Industry',
        description: 'Gain valuable insight into industry standards from Alex.',
        duration: '11:23',
        classIndex: 15,
      },
      {
        title: '16 Onion Skinning For Characters',
        description:
          'Alex will share how to use traditional Onion Skinning in cell animation.',
        duration: '12:31',
        classIndex: 16,
      },
      {
        title: '17 Adding Colour To Your Characters',
        description:
          'Learn how to add colour to your characters. Alex will teach you how to use the eyedropper and paint bucket tools, as well as the importance of making sure all line work is closed.',
        duration: '21:28',
        classIndex: 17,
      },
      {
        title: '18 Final Touches Before Rendering',
        description:
          'Learn how to add a background to your animation. Alex also discusses final touches, which include adjusting timeline and playback speed.',
        duration: '09:45',
        classIndex: 18,
      },
      {
        title: '19 Exporting and Rendering',
        description:
          'Learn how to turn your loop animation into a GIF format. You will also be going through the process of exporting and rendering the animation.',
        duration: '05:46',
        classIndex: 19,
      },
      {
        title: '20 Final Thoughts From Alex',
        description:
          'Alex completes his animation and shares his final thoughts.',
        duration: '08:35',
        classIndex: 20,
      },
      {
        title: '21 Extra Tips',
        description:
          'This bonus class will have extra expert tips such as rendering your animation and more.',
        duration: '67:03',
        classIndex: 21,
      },
    ],
  },
];
