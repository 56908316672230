import { useEffect, useState } from 'react';

interface QuizStatus {
  isCompleted: boolean;
  isSkipped: boolean;
}

// eslint-disable-next-line func-style
function useQuizProgress(onDemandMediaId: string) {
  const [progress, setProgress] = useState(getQuizProgress());

  useEffect(() => {
    const quizProgress = getQuizProgress();
    setProgress(quizProgress);
  }, [onDemandMediaId]);

  // eslint-disable-next-line func-style
  function getQuizProgress() {
    const quizProgress = localStorage.getItem('quizProgress');
    return quizProgress ? JSON.parse(quizProgress) : { [onDemandMediaId]: {} };
  }

  const setQuizProgress = (
    onDemandMediaId: string,
    quizId: string,
    key: string,
    value: boolean
  ) => {
    const newProgress = {
      ...progress,
      [onDemandMediaId]: {
        ...progress[onDemandMediaId],
        [quizId]: { [key]: value },
      },
    };
    setProgress(newProgress);
    localStorage.setItem('quizProgress', JSON.stringify(newProgress));
  };

  const setQuizCompleted = (
    onDemandMediaId: string,
    quizId: string,
    isCompleted: boolean
  ) => {
    setQuizProgress(onDemandMediaId, quizId, 'isCompleted', isCompleted);
  };

  const setQuizSkipped = (
    onDemandMediaId: string,
    quizId: string,
    isSkipped: boolean
  ) => {
    setQuizProgress(onDemandMediaId, quizId, 'isSkipped', isSkipped);
  };

  return [progress, setQuizCompleted, setQuizSkipped];
}

export default useQuizProgress;
