import { isUiTest } from '@/uiTests/helpers/componentHelpers';

import { EVENT_TOAST } from '@/utils/constants';

class Toast {
  private eventName: string = EVENT_TOAST;

  constructor(private data?: IToast) {
    if (data) {
      this.data = data;
    }
  }

  public addListener(handler: (event: any) => any) {
    window.addEventListener(this.eventName, handler);
  }

  public removeListener(handler: (event: any) => any) {
    window.removeEventListener(this.eventName, handler);
  }

  public dispatch() {
    if (isUiTest()) return;

    window.dispatchEvent(
      new CustomEvent(this.eventName, {
        detail: this.data,
      })
    );
  }
}

export { Toast };
