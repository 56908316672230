import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'unistore/react';
import widont from 'widont';

import { Checkbox } from '@/components/global/Forms/Checkbox/Checkbox';
import { FormValidator } from '@/components/global/Forms/FormValidator/FormValidator';
import { Input } from '@/components/global/Forms/Input/Input';
import { Link } from '@/components/global/Link/Link';
import { PhoneNumberCode } from '@/components/global/PhoneNumberCode/PhoneNumberCode';
import { Toast } from '@/components/global/Toaster/Toast';
import {
  PROMO_TERMS_LINK,
  STUDENT_PROMO_TERMS_LINK,
} from '@/components/subscription/constants';
import { checkValidStudentEmail } from '@/components/subscription/helpers';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { AuthType } from '@/enums/AuthType';
import { Http409Message } from '@/enums/Http409Message';
import { TrackingLocation } from '@/enums/TrackingLocation';

import {
  AUTH_PHONE_CODE_FAIL,
  EMAIL_ALREADY_IN_USE,
  EMAIL_ALREADY_IN_USE_TRY_LOGGING_IN,
  EMAIL_INVALID,
  errorMessage,
  FIELD_REQUIRED,
  MISSING_NAME,
  NAME_LENGTH_EXCEEDED,
  PASSWORD_TOO_SHORT,
  PHONE_NUMBER_IN_USE,
  PHONE_NUMBER_VERIFICATION_FAIL,
  SIGNUP_FAIL,
  STUDENT_EMAIL_INVALID,
  USERNAME_GENERATION_FAILED,
  USERNAME_IN_USE,
} from '@/messages/errors';

import { routes } from '@/routes';

import { authActions } from '@/store/modules/auth';

import { Http } from '@/utils/api/Http';
import { debounce } from '@/utils/api/throttle';
import {
  GOOGLE_SIGN_IN_BUTTON_STYLING,
  MAX_NAME,
  MAX_USERNAME_RETRIES,
  MIN_PASSWORD_LENGTH,
} from '@/utils/constants';
import { enablePhoneSignUpLogin } from '@/utils/featureToggles';
import { usernameGenerator } from '@/utils/forms/username-generator';
import {
  isEmailAvailable,
  isEmailValid,
  isPhoneNumberAvailable,
  isUsernameAvailable,
} from '@/utils/forms/validators';
import { t } from '@/utils/i18n/i18n';
import { track } from '@/utils/mixpanel/mixpanel';
import { c } from '@/utils/strings/c';
import { getRouteFromUrl } from '@/utils/urls/routes';

interface Props extends AuthActions, SizingState, RouteComponentProps {
  email?: string | false;
  acceptMarketing?: boolean;
  formLocation: TrackingLocation;
  onLinkClick?: () => void;
  textClassName?: string;
  inputClassName?: string;
  emailAlreadyInUseUrlParam?: string;
  redirectTo?: string;
  onSignUp?: () => void;
  isLoggedOutHome?: boolean;
  isLoggedOutRoom?: boolean;
  optimisedJoinFlowDestination?: string;
  disablePhoneNumberToggle?: boolean;
  isForStudentDiscount?: boolean;
  isForGeneralDiscount?: boolean;
  isUpdatedWorkshopSubFlow?: boolean;
}

interface State {
  email?: string;
  checking: boolean;
  errorMessage?: string;
  loading: boolean;
  isCurrentPreferenceEmail: boolean;
  phoneAuthAttemptUuid?: string;
  chosenCountry: string;
  formattedNumber: string;
  isResendClicked: boolean;
  step: number;
}

class RegistrationFormComponent extends React.Component<Props, State> {
  public state: State = {
    email: '',
    checking: false,
    errorMessage: undefined,
    loading: false,
    isCurrentPreferenceEmail: true,
    phoneAuthAttemptUuid: undefined,
    chosenCountry: '44',
    formattedNumber: '',
    isResendClicked: false,
    step: 0,
  };

  public componentDidMount = async () => {
    // @ts-ignore
    if (!google) return;

    // @ts-ignore
    google.accounts.id.initialize({
      client_id: process.env.DAISIE_SSO_GOOGLE_CLIENT_ID_WEB,
      callback: this.signUpWithGoogle,
    });

    // @ts-ignore
    google.accounts.id.renderButton(
      document.getElementById('google-sign-in'),
      GOOGLE_SIGN_IN_BUTTON_STYLING
    );
  };

  public incrementStep = () => {
    this.setState({ step: this.state.step + 1 });
  };

  public decrementStep = () => {
    this.setState({ step: this.state.step - 1 });
  };

  private submitValidation = (state: any) => {
    const errors: any = {};
    const { email, password, fullName } = state;

    if (!email) errors.email = FIELD_REQUIRED;
    if (!password) errors.password = FIELD_REQUIRED;
    if (!fullName) errors.fullName = FIELD_REQUIRED;

    return errors;
  };

  private changeValidation = async (state: any) => {
    if (this.state.step !== 0) {
      return;
    }

    this.setState({ checking: true });

    const errors: any = {};
    const { fullName, password = '', email, phoneNumber } = state;
    const {
      emailAlreadyInUseUrlParam,
      isForStudentDiscount,
      isUpdatedWorkshopSubFlow,
    } = this.props;

    if (
      !isUpdatedWorkshopSubFlow ||
      (isUpdatedWorkshopSubFlow &&
        state.email &&
        state.password &&
        !state.fullName)
    ) {
      if (fullName.trim().length === 0) {
        errors.fullName = MISSING_NAME;
      }
    }

    if (email.length > 0 && email !== this.state.email) {
      const isValid = isEmailValid(email.toLowerCase());

      if (!isValid) {
        errors.email = widont(EMAIL_INVALID);
      } else {
        const available = await isEmailAvailable(email.toLowerCase());

        if (!available) {
          errors.email = EMAIL_ALREADY_IN_USE_TRY_LOGGING_IN(
            emailAlreadyInUseUrlParam
          );
        } else {
          if (isForStudentDiscount) {
            const isValidStudentEmail = await checkValidStudentEmail(
              email.toLowerCase()
            );

            if (!isValidStudentEmail) {
              errors.email = STUDENT_EMAIL_INVALID;
            } else {
              this.setState({ email });
            }
          } else {
            this.setState({ email });
          }
        }
      }
    }

    if (
      phoneNumber.length > 0 &&
      this.formatPhoneNumber(phoneNumber) !== this.state.formattedNumber
    ) {
      const available = await isPhoneNumberAvailable(
        this.formatPhoneNumber(phoneNumber)
      );

      if (!available) {
        errors.phoneNumber = errorMessage(PHONE_NUMBER_IN_USE);
      } else {
        const phoneNumberWithoutZero = phoneNumber.replace(/^0/, '');

        this.setState({
          formattedNumber: `+${this.state.chosenCountry}${phoneNumberWithoutZero}`,
        });
      }
    }

    if (fullName.length > 0) {
      if (fullName.length > MAX_NAME) {
        errors.fullName = errorMessage(NAME_LENGTH_EXCEEDED, MAX_NAME);
      }
    }

    if (password && password.length < MIN_PASSWORD_LENGTH) {
      errors.password = errorMessage(PASSWORD_TOO_SHORT, MIN_PASSWORD_LENGTH);
    }

    this.setState({ checking: false });

    return errors;
  };

  private generateUsername = async (
    fullName: string,
    retry?: number
  ): Promise<any> => {
    if (retry && retry > MAX_USERNAME_RETRIES) {
      throw new Error(USERNAME_GENERATION_FAILED);
    }

    const generatedUsername = usernameGenerator(
      fullName,
      retry === MAX_USERNAME_RETRIES
    );

    const isAvailable = await isUsernameAvailable(generatedUsername);

    if (isAvailable) {
      track('username_generation_success');

      return generatedUsername;
    } else {
      track('username_generation_failed');
      const newRetry = retry ? retry + 1 : 1;

      return await this.generateUsername(fullName, newRetry);
    }
  };

  private signup = async (user: any, type?: string) => {
    const {
      formLocation,
      location: { search },
      redirectTo: redirectToProp,
      optimisedJoinFlowDestination,
    } = this.props;

    try {
      this.setState({ loading: true });

      await this.props.signup(user, formLocation, type);

      if (!!optimisedJoinFlowDestination) {
        window.location.href = optimisedJoinFlowDestination;
        return;
      }

      const queryParams = new URLSearchParams(search);
      const redirectQueryParam = queryParams.get('to');
      const redirectPath = redirectToProp
        ? redirectToProp
        : redirectQueryParam
        ? decodeURIComponent(redirectQueryParam)
        : null;

      const allowRedirect = redirectPath && redirectPath[0] === '/';

      window.location.href =
        redirectPath && allowRedirect
          ? `${routes.getStarted}?to=${encodeURIComponent(redirectPath)}`
          : routes.getStarted;
    } catch (e) {
      const { errors } = e.json ? await e.json() : [];

      if (e.message && e.message === Http409Message.SameUsername) {
        this.setState({
          errorMessage: USERNAME_IN_USE,
          loading: false,
        });
      } else if (e.message && e.message === Http409Message.SameEmail) {
        this.setState({
          errorMessage: EMAIL_ALREADY_IN_USE,
          loading: false,
        });
      } else if (
        errors[0].detail === 'Phone verification failed - please try again'
      ) {
        this.setState({
          errorMessage: PHONE_NUMBER_VERIFICATION_FAIL,
          loading: false,
        });
      } else {
        this.setState({
          errorMessage: undefined,
          loading: false,
        });
      }

      throw new Error();
    }
  };

  private formatPhoneNumber = (phoneNumber: string) => {
    const phoneNumberWithoutZero = phoneNumber.replace(/^0/, '');
    return `+${this.state.chosenCountry}${phoneNumberWithoutZero}`;
  };

  private submit = async (state: any) => {
    const {
      fullName,
      email,
      phoneNumber,
      password,
      acceptedMarketingCommunications,
    } = state;

    const name = fullName.trim();

    try {
      const user: any = {
        name,
        username: await this.generateUsername(name),
        email,
        password,
        bio: '',
        location: '',
        phoneNumber: this.formatPhoneNumber(phoneNumber),
        acceptedTermsAndConditions: true,
        acceptedMarketingCommunications,
        confirmedAge: true,
      };

      await this.signup(user);
    } catch (e) {
      new Toast({
        body: this.state.errorMessage ?? SIGNUP_FAIL,
        failure: true,
      }).dispatch();
    }
  };

  private termsClause = () => {
    const {
      onLinkClick,
      disablePhoneNumberToggle,
      isForStudentDiscount,
      isForGeneralDiscount,
    } = this.props;

    return (
      <span className="f-text-5 u-grey">
        {t('By continuing, you acknowledge and agree to our ')}
        <Link
          to={routes.terms}
          onClick={onLinkClick}
          className="u-underline u-bold u-link-grey"
        >
          {t('Terms & Conditions')}
        </Link>

        {(isForGeneralDiscount || isForStudentDiscount) && (
          <>
            {', '}
            <a
              href={PROMO_TERMS_LINK}
              className="u-underline u-bold u-link-grey"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('General Promotion Terms')}
            </a>
          </>
        )}

        {isForStudentDiscount && (
          <>
            {', '}
            <a
              href={STUDENT_PROMO_TERMS_LINK}
              className="u-underline u-bold u-link-grey"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Student Promotion Terms')}
            </a>
          </>
        )}

        {t(' and ')}
        <Link
          to={routes.privacyPolicy}
          onClick={onLinkClick}
          className="u-underline u-bold u-link-grey"
        >
          {t('Privacy Policy')}
        </Link>
        {enablePhoneSignUpLogin() && !disablePhoneNumberToggle && (
          <p className="u-inline">
            {t('. If you sign up with SMS, fees may apply.')}
          </p>
        )}
      </span>
    );
  };

  private togglePreference = () => {
    this.setState(({ isCurrentPreferenceEmail }) => ({
      isCurrentPreferenceEmail: !isCurrentPreferenceEmail,
    }));
  };

  private getAuthPhoneCode = async (phoneNum: string) => {
    this.setState({ loading: true });

    try {
      const { chosenCountry } = this.state;
      const phoneNumber = `+${chosenCountry}${phoneNum}`;

      const { data } = await new Http('/users/phoneAuthCode').post<
        APIObject<APIPhoneAuthCode>
      >({ phoneNumber });

      this.setState({ phoneAuthAttemptUuid: data.id });

      if (!this.state.isResendClicked) {
        this.incrementStep();
      }
    } catch (e) {
      new Toast({ body: AUTH_PHONE_CODE_FAIL, failure: true }).dispatch();
    }

    this.setState({ loading: false });
  };

  private submitPhone = async (state: any) => {
    const {
      fullName,
      password,
      phoneNumber,
      acceptedMarketingCommunications,
      authCode,
    } = state;

    const { phoneAuthAttemptUuid } = this.state;

    const name = fullName.trim();

    try {
      const user: any = {
        name,
        username: await this.generateUsername(name),
        password,
        bio: '',
        location: '',
        phoneNumber: this.formatPhoneNumber(phoneNumber),
        acceptedTermsAndConditions: true,
        acceptedMarketingCommunications,
        confirmedAge: true,
        phoneAuthAttemptUuid,
        authCode: authCode,
      };

      await this.signup(user);
    } catch (e) {
      new Toast({ body: SIGNUP_FAIL, failure: true }).dispatch();
    }
  };

  private signUpWithGoogle = async (e: any) => {
    try {
      const idToken = e['credential'];

      const user: any = {
        location: '',
        idToken,
      };

      await this.signup(user, AuthType.GoogleSSO);
    } catch (e) {
      new Toast({ body: SIGNUP_FAIL, failure: true }).dispatch();
    }
  };

  private switchToChosenCountry = (countryCallingCode: string, state: any) => {
    this.setState({ chosenCountry: countryCallingCode }, () => {
      this.changeValidation(state);
    });
  };

  public render = () => {
    const {
      errorMessage: errorMessageText,
      loading,
      chosenCountry,
      step,
    } = this.state;

    const {
      inputClassName = 'input--dark u-white b--none mb12 u-border-radius pv20 pl32',
      sizing: { isMobile },
      location: { search, pathname },
      isLoggedOutHome,
      isLoggedOutRoom,
      disablePhoneNumberToggle,
      isForStudentDiscount,
      isUpdatedWorkshopSubFlow,
    } = this.props;

    const showMarketingCheckbox = true;

    const isUserComingFromWorkshop = window.location.href.includes(
      '?to=%2Fworkshops%2F'
    );

    const matchedRoute = getRouteFromUrl(pathname);
    const isRegisterPage = matchedRoute === 'register';
    const isOptimisedJoinPage: boolean =
      matchedRoute === 'optimisedJoinFlowSignUpMonthly' ||
      matchedRoute === 'optimisedJoinFlowSignUpYearly' ||
      matchedRoute === 'studentsSignUpMonthly' ||
      matchedRoute === 'studentsSignUpYearly';

    const queryParams = new URLSearchParams(search);
    const to = queryParams.get('to') || '';
    const isFreeWorkshop = !!queryParams.get('isFree');

    const isOriginals = window.location.href.includes('originals');

    return (
      <FormValidator
        submit={this.submit}
        submitValidation={this.submitValidation}
        changeValidation={this.changeValidation}
        initialState={{
          fullName: '',
          email: this.props.email ? this.props.email : '',
          phoneNumber: '',
          authCode: '',
          password: '',
          acceptedMarketingCommunications: this.props.acceptMarketing
            ? this.props.acceptMarketing
            : false,
        }}
      >
        {({
          submit,
          changeValidation,
          errors,
          state = {},
          updateForm,
        }: {
          submit(): void;
          submitPhone(): void;
          changeValidation(): void;
          updateForm(key: string): (event: any) => void;
          updateFormArray: any;
          errors: any;
          state: any;
        }) => {
          const preventSubmit =
            this.state.loading ||
            !!Object.keys(errors).length ||
            !state.fullName ||
            !state.password ||
            (state.email && !state.password) ||
            (!state.email && !state.phoneNumber);

          const { checking, isCurrentPreferenceEmail } = this.state;
          return (
            <form
              onSubmit={submit}
              onChange={debounce(changeValidation, 750)}
              className="registration_form"
            >
              {step === 0 && (
                <div
                  className={c('', {
                    'mh128@m ph20@m': isRegisterPage,
                  })}
                >
                  {isOriginals && (
                    <>
                      <div
                        id="google-sign-in"
                        className="google-sso-container u-flex u-justify-center"
                      />
                      <div className="u-line-behind u-line-behind--dark f-text-4 u-text-center mv24">
                        <span>{t('or')}</span>
                      </div>
                    </>
                  )}
                  {!isUpdatedWorkshopSubFlow ? (
                    <>
                      <Input
                        id="fullName"
                        placeholder={t('Full name')}
                        type="text"
                        autoComplete="off"
                        required={true}
                        error={errors.fullName}
                        value={state.fullName}
                        onChange={updateForm('fullName')}
                        onBlur={() => {
                          track('sign_up_field', { type: 'fullName' });
                        }}
                        errorStyle="input-group--error-alt"
                        checking={checking}
                        delay={true}
                        uiTestId="register-name"
                        inputClassName={inputClassName}
                      />

                      {isCurrentPreferenceEmail ? (
                        <Input
                          id="email"
                          placeholder={
                            isForStudentDiscount
                              ? t('Student email')
                              : t('Email')
                          }
                          type="text"
                          required={true}
                          className={!!this.props.email ? 'u-grey' : ''}
                          value={
                            this.props.email ? this.props.email : state.email
                          }
                          disabled={!!this.props.email}
                          error={errors.email}
                          errorStyle="input-group--error-alt"
                          onChange={updateForm('email')}
                          onBlur={() => {
                            track('sign_up_field', { type: 'Email' });
                          }}
                          autoComplete="off"
                          delay={true}
                          checking={checking}
                          uiTestId="register-email"
                          inputClassName={inputClassName}
                        />
                      ) : (
                        <div>
                          <div
                            className={c('absolute mt12 ml12 u-z-index-2', {
                              'pt2 mt12 mvauto u-white': isRegisterPage,
                              'br--dark-grey': !isLoggedOutRoom,
                              br: isLoggedOutRoom,
                              pt2: isLoggedOutHome || isLoggedOutRoom,
                            })}
                          >
                            <PhoneNumberCode
                              chosenCountry={chosenCountry}
                              switchToChosenCountry={(
                                countryCallingCode: string
                              ) => {
                                this.switchToChosenCountry(
                                  countryCallingCode,
                                  state
                                );
                              }}
                            />
                          </div>
                          <Input
                            id="phoneNumber"
                            placeholder={t('Phone number')}
                            type={isMobile ? 'tel' : 'number'}
                            required={true}
                            onChange={updateForm('phoneNumber')}
                            error={errors.phoneNumber}
                            value={state.phoneNumber}
                            autoComplete="off"
                            disabled={this.state.loading}
                            className={c('dds-input__phone-code', {
                              'dds-input__phone-code-logged-out-home':
                                isLoggedOutHome || isLoggedOutRoom,
                            })}
                            errorStyle="input-group--error-alt"
                            inputClassName={inputClassName}
                            uiTestId="login-username"
                          />
                        </div>
                      )}

                      {enablePhoneSignUpLogin() && !disablePhoneNumberToggle && (
                        <div
                          onClick={this.togglePreference}
                          className={c(
                            'pl16@m pb16 f-text-5 u-cursor-pointer u-underline u-text-center@s u-text-position-under',
                            {
                              'u-white': isRegisterPage,
                            }
                          )}
                        >
                          {isCurrentPreferenceEmail ? (
                            <div
                              onClick={() => {
                                state.email = '';
                              }}
                            >
                              <p>{t('Use phone number instead')}</p>
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                state.phoneNumber = '';
                              }}
                            >
                              <p>{t('Use email instead')}</p>
                            </div>
                          )}
                        </div>
                      )}

                      <Input
                        id="password"
                        type="password"
                        placeholder={t('Password')}
                        autoComplete="off"
                        required={true}
                        value={state.password}
                        error={errors.password}
                        errorStyle="input-group--error-alt"
                        onChange={updateForm('password')}
                        onBlur={() => {
                          track('sign_up_field', { type: 'Password' });
                        }}
                        delay={true}
                        checking={checking}
                        uiTestId="register-password"
                        inputClassName={inputClassName}
                      />

                      {showMarketingCheckbox && (
                        <div
                          className={c('mv24 mt24@m', {
                            'u-bg-black': isRegisterPage,
                          })}
                        >
                          <Checkbox
                            id="acceptedMarketingCommunications"
                            label="I'd like to receive creative opportunities, event invites, and Daisie news via email"
                            error={errors.acceptedMarketingCommunications}
                            checked={state.acceptedMarketingCommunications}
                            onChange={() => {
                              updateForm('acceptedMarketingCommunications')({
                                value: !state.acceptedMarketingCommunications,
                              });
                              track('sign_up_field', { type: 'Marketing' });
                            }}
                            className={c('u-text-left f-text-3 u-dark-grey')}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <div>
                      {isCurrentPreferenceEmail ? (
                        <Input
                          id="email"
                          placeholder={
                            isForStudentDiscount
                              ? t('Student email')
                              : t('Email')
                          }
                          type="text"
                          required={true}
                          className={!!this.props.email ? 'u-grey' : ''}
                          value={
                            this.props.email ? this.props.email : state.email
                          }
                          disabled={!!this.props.email}
                          error={errors.email}
                          errorStyle="input-group--error-alt"
                          onChange={updateForm('email')}
                          onBlur={() => {
                            track('sign_up_field', { type: 'Email' });
                          }}
                          autoComplete="off"
                          delay={true}
                          checking={checking}
                          uiTestId="register-email"
                          inputClassName={inputClassName}
                          containerClassName="pb4"
                        />
                      ) : (
                        <div>
                          <div
                            className={c('absolute mt12 ml12 u-z-index-2', {
                              'pt2 mt12 mvauto u-white': isRegisterPage,
                              'br--dark-grey': !isLoggedOutRoom,
                              br: isLoggedOutRoom,
                              pt2: isLoggedOutHome || isLoggedOutRoom,
                            })}
                          >
                            <PhoneNumberCode
                              chosenCountry={chosenCountry}
                              switchToChosenCountry={(
                                countryCallingCode: string
                              ) => {
                                this.switchToChosenCountry(
                                  countryCallingCode,
                                  state
                                );
                              }}
                            />
                          </div>
                          <Input
                            id="phoneNumber"
                            placeholder={t('Phone number')}
                            type={isMobile ? 'tel' : 'number'}
                            required={true}
                            onChange={updateForm('phoneNumber')}
                            error={errors.phoneNumber}
                            value={state.phoneNumber}
                            autoComplete="off"
                            disabled={this.state.loading}
                            className={c('dds-input__phone-code', {
                              'dds-input__phone-code-logged-out-home':
                                isLoggedOutHome || isLoggedOutRoom,
                            })}
                            errorStyle="input-group--error-alt"
                            inputClassName={inputClassName}
                            uiTestId="login-username"
                            containerClassName="pb4"
                          />
                        </div>
                      )}

                      <div className="u-flex">
                        <Input
                          id="fullName"
                          placeholder={t('Your name')}
                          type="text"
                          autoComplete="off"
                          required={true}
                          error={errors.fullName}
                          value={state.fullName}
                          onChange={updateForm('fullName')}
                          onBlur={() => {
                            track('sign_up_field', { type: 'fullName' });
                          }}
                          errorStyle="input-group--error-alt"
                          checking={checking}
                          delay={true}
                          uiTestId="register-name"
                          inputClassName={inputClassName}
                          containerClassName="u-1/2 mr6"
                        />
                        <Input
                          id="password"
                          type="password"
                          placeholder={t('Password')}
                          autoComplete="off"
                          required={true}
                          value={state.password}
                          error={errors.password}
                          errorStyle="input-group--error-alt"
                          onChange={updateForm('password')}
                          onBlur={() => {
                            track('sign_up_field', { type: 'Password' });
                          }}
                          delay={true}
                          checking={checking}
                          uiTestId="register-password"
                          inputClassName={inputClassName}
                          containerClassName="u-1/2 ml6"
                        />
                      </div>
                      {enablePhoneSignUpLogin() &&
                        !disablePhoneNumberToggle &&
                        !isOriginals && (
                          <div
                            onClick={this.togglePreference}
                            className={c(
                              'pb12 pt8 f-text-5 u-cursor-pointer u-underline u-text-center@s u-text-position-under u-text-center',
                              {
                                'u-white': isRegisterPage,
                              }
                            )}
                          >
                            {isCurrentPreferenceEmail ? (
                              <div
                                onClick={() => {
                                  state.email = '';
                                }}
                              >
                                <p>{t('Use phone number instead')}</p>
                              </div>
                            ) : (
                              <div
                                onClick={() => {
                                  state.phoneNumber = '';
                                }}
                              >
                                <p>{t('Use email instead')}</p>
                              </div>
                            )}
                          </div>
                        )}
                    </div>
                  )}
                </div>
              )}

              {step === 1 && state.phoneNumber && (
                <div className="mb80@l u-text-center">
                  <div className="mv24 u-grey">
                    {t("Enter the code we've sent by SMS")}
                  </div>

                  <div
                    className={c('u-grid', {
                      'u-grid--6': !isMobile,
                    })}
                  >
                    {!isMobile && (
                      <Button
                        text=""
                        type={ButtonType.action}
                        onClick={() => {
                          this.decrementStep();
                          this.setState({ errorMessage: undefined });
                          state.phoneNumber = '';
                          state.password = '';
                          state.fullName = '';
                        }}
                        className="u-inline ml32@m"
                        iconId="chevron-left"
                        buttonStyle={
                          isRegisterPage || isOptimisedJoinPage
                            ? ButtonStyle.outlineLight
                            : ButtonStyle.light
                        }
                        size={ButtonSize.s}
                      />
                    )}
                    <div
                      className={c('mh64@m', {
                        'ph20@m': isRegisterPage,
                      })}
                    >
                      <Input
                        id="authCode"
                        placeholder={t('Verification Code')}
                        type="tel"
                        required={true}
                        onChange={updateForm('authCode')}
                        error={errors.authCode}
                        value={state.authCode}
                        maxLength={6}
                        disabled={this.state.loading}
                        autoComplete="off"
                        uiTestId="login-password"
                        inputClassName={c(
                          'u-bg-very-light-grey b--none u-border-radius u-text-center',
                          {
                            'input--dark u-white pv20':
                              isRegisterPage || isOptimisedJoinPage,
                          }
                        )}
                      />
                    </div>
                    <span className="mr64@m" />
                  </div>

                  <div
                    className={c('f-text-5 u-grey u-inline-block mt16', {
                      'u-blue-alert': errorMessageText,
                    })}
                  >
                    {errorMessageText ? (
                      <span>{`${errorMessageText} `}</span>
                    ) : (
                      <>{t("Didn't get your code? ")}</>
                    )}

                    {errorMessageText !== PHONE_NUMBER_IN_USE && (
                      <span
                        className={c('u-underline u-cursor-pointer', {
                          'u-link-grey': !errorMessageText,
                          'u-link-blue-alert': errorMessageText,
                        })}
                        onClick={async () => {
                          this.setState({ isResendClicked: true });
                          await this.getAuthPhoneCode(state.phoneNumber);
                        }}
                      >
                        {errorMessageText ? 'Resend code?' : 'Resend'}
                      </span>
                    )}
                  </div>
                </div>
              )}
              {step === 0 && (
                <div
                  className={c('', {
                    'ph128@m pb64@m mh20@m': isRegisterPage,
                  })}
                >
                  <Button
                    type={ButtonType.submit}
                    disabled={preventSubmit || this.state.checking}
                    text={t(
                      state.phoneNumber ||
                        ((isUpdatedWorkshopSubFlow ||
                          isUserComingFromWorkshop) &&
                          !isFreeWorkshop)
                        ? 'Continue'
                        : 'Create account'
                    )}
                    isLoading={loading}
                    onClick={
                      !isCurrentPreferenceEmail
                        ? async () => {
                            await this.getAuthPhoneCode(state.phoneNumber);
                          }
                        : undefined
                    }
                    uiTestId="register-submit"
                    className={c('mt32 mhauto u-fit-content u-1/1@s', {
                      'dds-button__signup-login': isRegisterPage,
                    })}
                    size={ButtonSize.xl}
                  />
                  {!isOriginals && (
                    <>
                      <div className="u-line-behind u-line-behind--dark f-text-4 u-text-center mv24">
                        <span>{t('or')}</span>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <div
                          id="google-sign-in"
                          className="google-sso-container"
                          style={{ width: isMobile ? '100%' : undefined }}
                        />
                      </div>
                    </>
                  )}
                  <div
                    className={c('mv24 mh32@m u-text-center', {
                      'u-visibility-hidden': isOriginals,
                    })}
                  >
                    {this.termsClause()}
                  </div>
                </div>
              )}
              {step === 1 && (
                <div
                  className={c('pb64@m', {
                    'ph128@m mh20@m': isRegisterPage,
                  })}
                >
                  <Button
                    type={ButtonType.action}
                    onClick={() => {
                      this.submitPhone(state);
                    }}
                    size={ButtonSize.xl}
                    disabled={
                      preventSubmit ||
                      this.state.checking ||
                      state.authCode.length < 6
                    }
                    text={t('Verify')}
                    isLoading={loading}
                    uiTestId="register-submit"
                    className={c('u-1/1 mt32', {
                      'dds-button__signup-login': isRegisterPage,
                    })}
                  />
                </div>
              )}

              {step === 0 && isRegisterPage && (
                <p className="u-grey u-text-center f-text-3 mb32">
                  {t('Already have an account?')}{' '}
                  <Link
                    to={`${routes.login}${
                      !!to ? `?to=${encodeURIComponent(to)}` : ''
                    }`}
                    className="u-link-brand--dark u-underline"
                  >
                    {t('Log In')}
                  </Link>
                </p>
              )}

              {isMobile && step === 1 && (
                <div
                  onClick={() => {
                    this.decrementStep();
                  }}
                  className="u-grey u-underline u-text-center mv20 f-text-5"
                >
                  {t('Back')}
                </div>
              )}
            </form>
          );
        }}
      </FormValidator>
    );
  };
}

export const RegistrationForm = connect(
  ['sizing'],
  authActions
)(withRouter(RegistrationFormComponent));
