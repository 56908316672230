import * as React from 'react';

import { connect } from 'unistore/react';

import { routes } from '@/routes';

import { authActions } from '@/store/modules/auth';

class LogoutComponent extends React.Component<AuthActions & AuthState> {
  constructor(props: AuthActions & AuthState) {
    super(props);

    props.logout();
    window.location.href = routes.home;
  }

  public render() {
    return null;
  }
}

export const Logout = connect('auth', authActions)(LogoutComponent);
