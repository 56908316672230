import React, { useRef } from 'react';

import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Image,
  Link as ChakraLink,
  useDisclosure,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import {
  ArrowTopRightIcon,
  Button as NewButton,
  Text,
} from '@/components/design_system/AlexJenkinsClass';

import { routes } from '@/routes';

import { colors } from '@/styles/chakra-theme/colors';
import { pxToRem } from '@/styles/chakra-theme/metrics';

const navOptions = [
  {
    label: 'Outcomes',
    to: '#outcomes',
  },
  {
    label: 'Overview',
    to: '#overview',
  },
  {
    label: 'Reviews',
    to: '#reviews',
  },
  {
    label: 'FAQ',
    to: '#faq',
  },
];

const socials = [
  {
    label: 'TikTok',
    url: 'https://www.tiktok.com/@daisieapp',
  },
  {
    label: 'Twitter',
    url: 'https://twitter.com/daisie',
  },
  {
    label: 'Discord',
    url: 'https://discord.gg/daisie',
  },
  {
    label: 'YouTube',
    url: 'https://www.youtube.com/channel/UClWp9PFiSBh9h0nnkAM0MqA',
  },
  {
    label: 'Instagram',
    url: 'https://www.instagram.com/daisieapp/',
  },
];

export const BottomMenuMobile = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { isOpen, onToggle } = useDisclosure();
  const history = useHistory();

  return (
    <Box
      ref={containerRef}
      h="fit-content"
      py={pxToRem(8)}
      px={pxToRem(12)}
      position="fixed"
      zIndex={9}
      className="bottomMenuMobile"
    >
      <Drawer isOpen={isOpen} onClose={onToggle} placement="bottom" size="full">
        <DrawerContent
          bgColor={colors.greyDark}
          style={{
            backgroundColor: containerRef?.current?.style.backgroundColor,
            color: containerRef?.current?.style.color,
          }}
        >
          <DrawerHeader
            display="flex"
            justifyContent="space-between"
            padding={pxToRem(20)}
          >
            <Flex flexDir="column" gap={pxToRem(8)}>
              {socials.map((social) => (
                <ChakraLink
                  key={`social_${social.label}`}
                  isExternal
                  color={colors.greyLight}
                  fontSize={pxToRem(14)}
                  href={social.url}
                >
                  {social.label}
                </ChakraLink>
              ))}
            </Flex>

            <Image
              alt="daisie_logo"
              src="/AlexJenkinsClass/img/daisie_logo.png"
              h={pxToRem(30)}
            />
          </DrawerHeader>

          <DrawerBody>
            <Flex
              height="100%"
              direction="column"
              gap={pxToRem(8)}
              justifyContent="end"
            >
              {navOptions.map((option) => (
                <ChakraLink
                  key={`nav_option_${option.label}`}
                  fontSize={pxToRem(40)}
                  textTransform="uppercase"
                  href={option.to}
                  onClick={onToggle}
                >
                  {option.label}
                </ChakraLink>
              ))}
            </Flex>
          </DrawerBody>

          <DrawerFooter alignItems="center" justifyContent="space-between">
            <Text fontSize={18} onClick={onToggle}>
              Close
            </Text>

            <NewButton.Small
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              bgColor={colors.purpleRain}
              color="white"
              px={pxToRem(16)}
              py={pxToRem(14)}
              w={pxToRem(146)}
              h={pxToRem(48)}
              onClick={() => history.push(routes.optimisedJoinFlowChoosePlan)}
            >
              Enroll
              <ArrowTopRightIcon
                mt={pxToRem(8)}
                w={pxToRem(18)}
                h={pxToRem(18)}
              />
            </NewButton.Small>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize={18} onClick={onToggle}>
          Menu
        </Text>

        <NewButton.Small
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px={pxToRem(16)}
          py={pxToRem(14)}
          w={pxToRem(146)}
          h={pxToRem(48)}
          className="enrollBtn"
          onClick={() => history.push(routes.optimisedJoinFlowChoosePlan)}
        >
          Enroll
          <ArrowTopRightIcon mt={pxToRem(8)} w={pxToRem(18)} h={pxToRem(18)} />
        </NewButton.Small>
      </Flex>
    </Box>
  );
};
