export const stripProtocolFromUrl = (url: string = '') =>
  url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '');

export const pluralise = (s: string, c: number = 0) => s + (c === 1 ? '' : 's');

export const getHash = (input: string): number => {
  let hash = 0;
  const len = input.length;

  for (let i = 0; i < len; i++) {
    hash = (hash << 5) - hash + input.charCodeAt(i);
    hash |= 0; // to 32bit integer
  }

  return Math.abs(hash);
};
