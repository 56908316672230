export const isEmpty = (obj: any) => Object.keys(obj).length === 0;

export const alphabetiseKeys = (unordered: { [key: string]: any }) => {
  const ordered: any[] = [];

  Object.keys(unordered)
    .sort()
    .forEach((key) => {
      ordered[key] = unordered[key];
    });

  return ordered;
};
