import * as React from 'react';

import { connect } from 'unistore/react';

import { Link } from '@/components/global/Link/Link';
import { SkeletonNew } from '@/components/global/Skeleton/SkeletonNew';
import { Avatar } from '@/components/users/Avatar/Avatar';

import { AvatarSize } from '@/components/design_system/Avatar/AvatarSize';
import { AvatarStack } from '@/components/design_system/AvatarStack/AvatarStack';

import { GlobalModal } from '@/enums/GlobalModal';
import { GlobalPortal } from '@/enums/GlobalPortal';

import { parser } from '@/routes/parser';

import { modalActions } from '@/store/modules/modal';
import { portalActions } from '@/store/modules/portal';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props
  extends SizingState,
    ModalActions,
    ModalState,
    PortalActions,
    PortalState {
  className?: string;
  isLoading: boolean;
  attendees: User[];
  isAttendeesLoaded: boolean;
  isCompact?: boolean;
  workshop?: Workshop;
  room?: Room;
}

class YourMentorOrRoomHostComp extends React.Component<Props> {
  private classNameSkeleton: string = 'c-logged-in-homepage__skeleton';

  private renderComponent = ({
    user,
    type,
  }: {
    user: User;
    type: 'workshop' | 'room';
  }) => {
    const {
      className = '',
      isCompact,
      attendees,
      isAttendeesLoaded,
      sizing: { isMobile },
      workshop,
    } = this.props;

    const { username, avatar, name } = user;

    return (
      <div className={c(['u-flex', className])}>
        {!isCompact && (
          <Link
            to={parser({
              name: 'user',
              params: { username },
            })}
          >
            <Avatar
              src={avatar}
              user={user}
              alt={name}
              size="xl"
              className="mr12 u-fit-content u-h-fit-content"
            />
          </Link>
        )}

        <div>
          {!isCompact && (
            <p
              className={c('u-bold f-text-3 u-grey', {
                mb2: !isAttendeesLoaded || attendees.length === 0,
              })}
            >
              {type === 'workshop' ? t('Your Mentor') : t('Your Host')}
            </p>
          )}

          <div className="u-flex u-align-center u-flex-wrap">
            {isCompact && (
              <span className="f-text-3 mr4">{t('Hosted by')}</span>
            )}

            <Link
              to={parser({
                name: 'user',
                params: { username },
              })}
              className="f-text-3 pr4 u-bold u-link-white u-link-white--alt"
            >
              {name !== '' ? name : username}
            </Link>

            {isAttendeesLoaded && attendees.length > 0 && (
              <button
                type="button"
                className="u-flex u-link-white u-link-white--alt mt2"
                onClick={(e: any) => {
                  e.preventDefault();

                  if (isMobile) {
                    this.props.updatePortal({
                      portal: GlobalPortal.workshop_attendees,
                      data: { attendees },
                    });
                  } else {
                    this.props.updateModal({
                      modal: GlobalModal.workshop_attendees,
                      data: { attendees },
                      className: 'page-modal__content--workshop-attendee-list',
                    });
                  }
                }}
              >
                <p className="f-text-3 mr8">
                  {workshop?.hasElapsed
                    ? `• ${attendees.length} ${t('students')}`
                    : `+ ${attendees.length} ${t('going')}`}
                </p>
                <AvatarStack
                  users={attendees}
                  maxAvatars={3}
                  avatarSize={AvatarSize.card_attending}
                />
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  public render = () => {
    const { isLoading, workshop, room, className = '' } = this.props;

    return isLoading ? (
      <div className={c(['u-flex', className])}>
        <div className="avatar__container mr12">
          <div className={c(['avatar avatar--xl', this.classNameSkeleton])} />
        </div>
        <div className="u-flex u-flex-column">
          <SkeletonNew
            fitToText={t('Your mentor')}
            elementClassName="f-text-3"
            containerClassName="mb2"
            fillClassName={this.classNameSkeleton}
          />
          <SkeletonNew
            fitToText={t('Host name goes here')}
            elementClassName="f-text-3"
            containerClassName=""
            fillClassName={this.classNameSkeleton}
          />
        </div>
      </div>
    ) : workshop && workshop.mentor ? (
      this.renderComponent({ user: workshop.mentor, type: 'workshop' })
    ) : room && room.owner ? (
      this.renderComponent({ user: room.owner, type: 'room' })
    ) : null;
  };
}

export const YourMentorOrRoomHost = connect(['sizing'], () => ({
  ...modalActions(),
  ...portalActions(),
}))(YourMentorOrRoomHostComp);
