import * as React from 'react';

import { connect } from 'unistore/react';

import { Icon } from '@/components/global/Icon/Icon';

import { c } from '@/utils/strings/c';

interface Props extends SizingState {
  onItemClick: (index: number | undefined) => void;
  isExpanded: boolean;
  section: FaqSection;
  index: number | undefined;
}

interface FaqSection {
  question: string;
  answer: React.ReactNode;
}

const FAQItemGeneralComp = ({
  sizing: { isDesktop },
  onItemClick,
  isExpanded,
  section,
  index,
}: Props) => (
  <div
    // eslint-disable-next-line react/no-array-index-key
    key={index}
  >
    <button
      type="button"
      onClick={() => {
        onItemClick(!isExpanded ? index : undefined);
      }}
      className="u-flex u-align-center u-split pv12 u-flex-column"
    >
      <div className="u-flex u-align-center u-split">
        <p className="u-bold f-text-1">{section.question}</p>
        <Icon
          id={isExpanded ? 'minus' : 'add'}
          width="14px"
          className={c('ml16', {
            'u-light-grey': !isExpanded,
            'u-white': isExpanded,
          })}
        />
      </div>

      {isExpanded && (
        <div
          className={c('mt24 mb8 u-line-height-1/2 u-text-left f-text-2', {
            'u-grey': !isDesktop,
          })}
        >
          {section.answer}
        </div>
      )}
    </button>
  </div>
);

export const FAQItemGeneral = connect(['sizing'])(FAQItemGeneralComp);
