export const USER_RING_COLOURS = [
  'rgba(79, 170, 248, 1)',
  'rgba(108, 198, 8, 1)',
  'rgba(169, 255, 234, 1)',
  'rgba(239, 144, 53, 1)',
  'rgba(254, 252, 86, 1)',
  'rgba(141, 163, 243, 1)',
  'rgba(237, 111, 171, 1)',
  'rgba(231, 171, 115, 1)',
  'rgba(244, 81, 91, 1)',
];
