/* eslint import/no-webpack-loader-syntax: off */
import * as React from 'react';

import IconAdd from '-!svg-react-loader!./icons/add.svg';
import IconAlert from '-!svg-react-loader!./icons/alert.svg';
import IconApple from '-!svg-react-loader!./icons/apple.svg';
import IconArrowDiagonalBlackInvert from '-!svg-react-loader!./icons/arrow-diagonal-black-invert.svg';
import IconArrowDiagonalBlack from '-!svg-react-loader!./icons/arrow-diagonal-black.svg';
import IconArrowDiagonalWhiteInvert from '-!svg-react-loader!./icons/arrow-diagonal-white-invert.svg';
import IconArrowDiagonalWhite from '-!svg-react-loader!./icons/arrow-diagonal-white.svg';
import IconArrowLeftFilled from '-!svg-react-loader!./icons/arrow-left-filled.svg';
import IconArrowLeft from '-!svg-react-loader!./icons/arrow-left.svg';
import IconArrowLink from '-!svg-react-loader!./icons/arrow-link.svg';
import IconArrowLong from '-!svg-react-loader!./icons/arrow-long.svg';
import IconArrowRight from '-!svg-react-loader!./icons/arrow-right.svg';
import IconAttend from '-!svg-react-loader!./icons/attend.svg';
import IconBadge from '-!svg-react-loader!./icons/badge.svg';
import IconBell from '-!svg-react-loader!./icons/bell.svg';
import IconBlockUser from '-!svg-react-loader!./icons/block-user.svg';
import IconCalendar from '-!svg-react-loader!./icons/calendar.svg';
import IconCategoryAnimation from '-!svg-react-loader!./icons/category-animation.svg';
import IconCategoryArt from '-!svg-react-loader!./icons/category-art.svg';
import IconCategoryCareer from '-!svg-react-loader!./icons/category-career.svg';
import IconCategoryDaisieXToveLo from '-!svg-react-loader!./icons/category-daisie-x-tove-lo.svg';
import IconCategoryDesign from '-!svg-react-loader!./icons/category-design.svg';
import IconCategoryDigital from '-!svg-react-loader!./icons/category-digital.svg';
import IconCategoryDogsAreGood from '-!svg-react-loader!./icons/category-dogs-are-good.svg';
import IconCategoryFashion from '-!svg-react-loader!./icons/category-fashion.svg';
import IconCategoryFilm from '-!svg-react-loader!./icons/category-film.svg';
import IconCategoryGeneral from '-!svg-react-loader!./icons/category-general.svg';
import IconCategoryMusic from '-!svg-react-loader!./icons/category-music.svg';
import IconCategoryPhotography from '-!svg-react-loader!./icons/category-photography.svg';
import IconCategoryTheatre from '-!svg-react-loader!./icons/category-theatre.svg';
import IconCategoryWonderwomen from '-!svg-react-loader!./icons/category-wonderwomen.svg';
import IconCategoryWorkshop from '-!svg-react-loader!./icons/category-workshop.svg';
import IconCategoryWriting from '-!svg-react-loader!./icons/category-writing.svg';
import IconChatFilled from '-!svg-react-loader!./icons/chat-filled.svg';
import IconChatHd from '-!svg-react-loader!./icons/chat-hd.svg';
import IconChat from '-!svg-react-loader!./icons/chat.svg';
import IconChevronDown from '-!svg-react-loader!./icons/chevron-down.svg';
import IconChevronLeft from '-!svg-react-loader!./icons/chevron-left.svg';
import IconChevronRight from '-!svg-react-loader!./icons/chevron-right.svg';
import IconChevronUp from '-!svg-react-loader!./icons/chevron-up.svg';
import IconClearFilled from '-!svg-react-loader!./icons/clear-filled.svg';
import IconClear from '-!svg-react-loader!./icons/clear.svg';
import IconCollaboration from '-!svg-react-loader!./icons/collaboration.svg';
import IconCollection from '-!svg-react-loader!./icons/collection.svg';
import IconCompose from '-!svg-react-loader!./icons/compose.svg';
import IconCornerDots from '-!svg-react-loader!./icons/corner-dots.svg';
import IconCreditCard from '-!svg-react-loader!./icons/credit-card.svg';
import IconDaisie from '-!svg-react-loader!./icons/daisie.svg';
import IconDiscord from '-!svg-react-loader!./icons/discord.svg';
import IconEditPaper from '-!svg-react-loader!./icons/edit-paper.svg';
import IconEdit from '-!svg-react-loader!./icons/edit.svg';
import IconExit from '-!svg-react-loader!./icons/exit.svg';
import IconFacebook from '-!svg-react-loader!./icons/facebook.svg';
import IconFacebookMessenger from '-!svg-react-loader!./icons/fb-messenger.svg';
import IconFilter from '-!svg-react-loader!./icons/filter.svg';
import IconFire from '-!svg-react-loader!./icons/fire.svg';
import IconFlag from '-!svg-react-loader!./icons/flag.svg';
import IconForYou from '-!svg-react-loader!./icons/for-you.svg';
import IconForward10 from '-!svg-react-loader!./icons/forward-10.svg';
import IconFoundingMember from '-!svg-react-loader!./icons/founding-member.svg';
import IconFullScreen from '-!svg-react-loader!./icons/full-screen.svg';
import IconGlobe from '-!svg-react-loader!./icons/globe.svg';
import IconGoogle from '-!svg-react-loader!./icons/google.svg';
import IconHandWave from '-!svg-react-loader!./icons/hand-wave.svg';
import IconHelpFilled from '-!svg-react-loader!./icons/help-filled.svg';
import IconHelp from '-!svg-react-loader!./icons/help.svg';
import IconHole from '-!svg-react-loader!./icons/hole.svg';
import IconHome from '-!svg-react-loader!./icons/home.svg';
import IconImageSize from '-!svg-react-loader!./icons/image-size.svg';
import IconInstagramFilled from '-!svg-react-loader!./icons/instagram-filled.svg';
import IconInstagram from '-!svg-react-loader!./icons/instagram.svg';
import IconLightning from '-!svg-react-loader!./icons/lightning.svg';
import IconLink from '-!svg-react-loader!./icons/link.svg';
import IconMail from '-!svg-react-loader!./icons/mail.svg';
import IconMedia from '-!svg-react-loader!./icons/media.svg';
import IconMentorMono from '-!svg-react-loader!./icons/mentor-mono.svg';
import IconMentor from '-!svg-react-loader!./icons/mentor.svg';
import IconMicOff from '-!svg-react-loader!./icons/mic-off.svg';
import IconMicOn from '-!svg-react-loader!./icons/mic-on.svg';
import IconMinus from '-!svg-react-loader!./icons/minus.svg';
import IconMoreVert from '-!svg-react-loader!./icons/more-vert.svg';
import IconOriginalsChevronDown from '-!svg-react-loader!./icons/originals-chevron-down.svg';
import IconOriginalsChevronUp from '-!svg-react-loader!./icons/originals-chevron-up.svg';
import IconOriginalsFullScreen from '-!svg-react-loader!./icons/originals-fullscreen.svg';
import IconOriginalsMuted from '-!svg-react-loader!./icons/originals-muted.svg';
import IconOriginalsPause from '-!svg-react-loader!./icons/originals-pause.svg';
import IconOriginalsPlay from '-!svg-react-loader!./icons/originals-play.svg';
import IconOriginalsUnmuted from '-!svg-react-loader!./icons/originals-unmuted.svg';
import IconPause from '-!svg-react-loader!./icons/pause.svg';
import IconPersonOutline from '-!svg-react-loader!./icons/person-outline.svg';
import IconPerson from '-!svg-react-loader!./icons/person.svg';
import IconPinFilled from '-!svg-react-loader!./icons/pin-filled.svg';
import IconPin from '-!svg-react-loader!./icons/pin.svg';
import IconPlayTriangle from '-!svg-react-loader!./icons/play-triangle.svg';
import IconPlay from '-!svg-react-loader!./icons/play.svg';
import IconPlus from '-!svg-react-loader!./icons/plus.svg';
import IconPrivateHd from '-!svg-react-loader!./icons/private-hd.svg';
import IconPrivate from '-!svg-react-loader!./icons/private.svg';
import IconProfession from '-!svg-react-loader!./icons/profession.svg';
import IconProject from '-!svg-react-loader!./icons/project.svg';
import IconPublic from '-!svg-react-loader!./icons/public.svg';
import IconQuote from '-!svg-react-loader!./icons/quote.svg';
import IconRabbit from '-!svg-react-loader!./icons/rabbit.svg';
import IconRaiseHand from '-!svg-react-loader!./icons/raise-hand.svg';
import IconRedEye from '-!svg-react-loader!./icons/red-eye.svg';
import IconReddit from '-!svg-react-loader!./icons/reddit.svg';
import IconReplay10 from '-!svg-react-loader!./icons/replay-10.svg';
import IconSaveAlt from '-!svg-react-loader!./icons/save-alt.svg';
import IconSearch from '-!svg-react-loader!./icons/search.svg';
import IconSend from '-!svg-react-loader!./icons/send.svg';
import IconSettings from '-!svg-react-loader!./icons/settings.svg';
import IconShare from '-!svg-react-loader!./icons/share.svg';
import IconSharedInterest from '-!svg-react-loader!./icons/shared-interest.svg';
import IconSparkleHd from '-!svg-react-loader!./icons/sparkle-hd.svg';
import IconSubscriber from '-!svg-react-loader!./icons/subscriber.svg';
import IconTextAlignLeft from '-!svg-react-loader!./icons/text-align-left.svg';
import IconTickCircle from '-!svg-react-loader!./icons/tick-circle.svg';
import IconTick from '-!svg-react-loader!./icons/tick.svg';
import IconTikTok from '-!svg-react-loader!./icons/tiktok.svg';
import IconTrash from '-!svg-react-loader!./icons/trash.svg';
import IconTwitter from '-!svg-react-loader!./icons/twitter.svg';
import IconVerified from '-!svg-react-loader!./icons/verified.svg';
import IconVolumeOff from '-!svg-react-loader!./icons/volume-off.svg';
import IconVolumeOn from '-!svg-react-loader!./icons/volume-on.svg';
import IconWarning from '-!svg-react-loader!./icons/warning.svg';
import IconWhatsapp from '-!svg-react-loader!./icons/whatsapp.svg';
import IconWorkshopHd from '-!svg-react-loader!./icons/workshop-hd.svg';
import IconYoutube from '-!svg-react-loader!./icons/youtube.svg';
import IconZoom from '-!svg-react-loader!./icons/zoom.svg';
import IconClose from '-!svg-react-loader!./icons/close.svg';
import IconPlayRound from '-!svg-react-loader!./icons/play-round.svg';
import IconCheckmark from '-!svg-react-loader!./icons/checkmark.svg';

interface Props {
  id?: string;
  src?: string;
  size?: string;
  width?: string;
  height?: string;
  className?: string;
  fill?: string;
}

class Icon extends React.Component<Props> {
  private sizes = {
    xs: '12px',
    s: '18px',
    m: '24px',
    ml: '28px',
    l: '32px',
    xl: '40px',
    xxl: '54px',
  };

  private returnComponent = (
    id: string,
    w: string,
    h: string,
    className: string,
    fill: string
  ) => {
    const params = { className, fill, width: w, height: h };

    switch (id) {
      case 'add':
        return <IconAdd {...params} />;
      case 'alert':
        return <IconAlert {...params} />;
      case 'apple':
        return <IconApple {...params} />;
      case 'arrow-diagonal-black':
        return <IconArrowDiagonalBlack {...params} />;
      case 'arrow-diagonal-black-invert':
        return <IconArrowDiagonalBlackInvert {...params} />;
      case 'arrow-diagonal-white':
        return <IconArrowDiagonalWhite {...params} />;
      case 'arrow-diagonal-white-invert':
        return <IconArrowDiagonalWhiteInvert {...params} />;
      case 'arrow-left':
        return <IconArrowLeft {...params} />;
      case 'arrow-left-filled':
        return <IconArrowLeftFilled {...params} />;
      case 'arrow-right':
        return <IconArrowRight {...params} />;
      case 'arrow-link':
        return <IconArrowLink {...params} />;
      case 'arrow-long':
        return <IconArrowLong {...params} />;
      case 'attend':
        return <IconAttend {...params} />;
      case 'badge':
        return <IconBadge {...params} />;
      case 'bell':
        return <IconBell {...params} />;
      case 'block-user':
        return <IconBlockUser {...params} />;
      case 'calendar':
        return <IconCalendar {...params} />;
      case 'category-animation':
        return <IconCategoryAnimation {...params} />;
      case 'category-art':
        return <IconCategoryArt {...params} />;
      case 'category-career':
        return <IconCategoryCareer {...params} />;
      case 'category-daisie-x-tove-lo':
        return <IconCategoryDaisieXToveLo {...params} />;
      case 'category-design':
        return <IconCategoryDesign {...params} />;
      case 'category-digital':
        return <IconCategoryDigital {...params} />;
      case 'category-dogs-are-good':
        return <IconCategoryDogsAreGood {...params} />;
      case 'category-fashion':
        return <IconCategoryFashion {...params} />;
      case 'category-film':
        return <IconCategoryFilm {...params} />;
      case 'category-general':
        return <IconCategoryGeneral {...params} />;
      case 'category-music':
        return <IconCategoryMusic {...params} />;
      case 'category-photography':
        return <IconCategoryPhotography {...params} />;
      case 'category-theatre':
        return <IconCategoryTheatre {...params} />;
      case 'category-wonderwomen':
      case 'category-wonder-women':
        return <IconCategoryWonderwomen {...params} />;
      case 'category-workshop':
        return <IconCategoryWorkshop {...params} />;
      case 'category-writing':
        return <IconCategoryWriting {...params} />;
      case 'chat':
        return <IconChat {...params} />;
      case 'chat-filled':
        return <IconChatFilled {...params} />;
      case 'chat-hd':
        return <IconChatHd {...params} />;
      case 'chevron-down':
        return <IconChevronDown {...params} />;
      case 'chevron-left':
        return <IconChevronLeft {...params} />;
      case 'chevron-right':
        return <IconChevronRight {...params} />;
      case 'chevron-up':
        return <IconChevronUp {...params} />;
      case 'clear':
        return <IconClear {...params} />;
      case 'clear-filled':
        return <IconClearFilled {...params} />;
      case 'compose':
        return <IconCompose {...params} />;
      case 'collaboration':
        return <IconCollaboration {...params} />;
      case 'collection':
        return <IconCollection {...params} />;
      case 'corner-dots':
        return <IconCornerDots {...params} />;
      case 'credit-card':
        return <IconCreditCard {...params} />;
      case 'daisie':
      case 'category-originals':
        return <IconDaisie {...params} />;
      case 'discord':
        return <IconDiscord {...params} />;
      case 'edit':
        return <IconEdit {...params} />;
      case 'edit-paper':
        return <IconEditPaper {...params} />;
      case 'exit':
        return <IconExit {...params} />;
      case 'facebook':
        return <IconFacebook {...params} />;
      case 'facebook-messenger':
        return <IconFacebookMessenger {...params} />;
      case 'filter':
        return <IconFilter {...params} />;
      case 'flag':
        return <IconFlag {...params} />;
      case 'for-you':
        return <IconForYou {...params} />;
      case 'forward-10':
        return <IconForward10 {...params} />;
      case 'founding-member':
        return <IconFoundingMember {...params} />;
      case 'full-screen':
        return <IconFullScreen {...params} />;
      case 'google':
        return <IconGoogle {...params} />;
      case 'globe':
        return <IconGlobe {...params} />;
      case 'help':
        return <IconHelp {...params} />;
      case 'help-filled':
        return <IconHelpFilled {...params} />;
      case 'home':
        return <IconHome {...params} />;
      case 'hole':
        return <IconHole {...params} />;
      case 'hand-wave':
        return <IconHandWave {...params} />;
      case 'image-size':
        return <IconImageSize {...params} />;
      case 'instagram':
        return <IconInstagram {...params} />;
      case 'instagram-filled':
        return <IconInstagramFilled {...params} />;
      case 'link':
        return <IconLink {...params} />;
      case 'mail':
        return <IconMail {...params} />;
      case 'media':
        return <IconMedia {...params} />;
      case 'mentor-mono':
        return <IconMentorMono {...params} />;
      case 'mentor':
        return <IconMentor {...params} />;
      case 'mic-on':
        return <IconMicOn {...params} />;
      case 'mic-off':
        return <IconMicOff {...params} />;
      case 'minus':
        return <IconMinus {...params} />;
      case 'more-vert':
        return <IconMoreVert {...params} />;
      case 'originals-full-screen':
        return <IconOriginalsFullScreen {...params} />;
      case 'originals-pause':
        return <IconOriginalsPause {...params} />;
      case 'originals-play':
        return <IconOriginalsPlay {...params} />;
      case 'originals-muted':
        return <IconOriginalsMuted {...params} />;
      case 'originals-unmuted':
        return <IconOriginalsUnmuted {...params} />;
      case 'originals-chevron-up':
        return <IconOriginalsChevronUp {...params} />;
      case 'originals-chevron-down':
        return <IconOriginalsChevronDown {...params} />;
      case 'pause':
        return <IconPause {...params} />;
      case 'person':
        return <IconPerson {...params} />;
      case 'person-outline':
        return <IconPersonOutline {...params} />;
      case 'pin':
        return <IconPin {...params} />;
      case 'pin-filled':
        return <IconPinFilled {...params} />;
      case 'play-triangle':
        return <IconPlayTriangle {...params} />;
      case 'play':
        return <IconPlay {...params} />;
      case 'play-round':
        return <IconPlayRound {...params} />;
      case 'private':
        return <IconPrivate {...params} />;
      case 'private-hd':
        return <IconPrivateHd {...params} />;
      case 'project':
        return <IconProject {...params} />;
      case 'public':
        return <IconPublic {...params} />;
      case 'profession':
        return <IconProfession {...params} />;
      case 'quote':
        return <IconQuote {...params} />;
      case 'raise-hand':
        return <IconRaiseHand {...params} />;
      case 'rabbit':
        return <IconRabbit {...params} />;
      case 'reddit':
        return <IconReddit {...params} />;
      case 'replay-10':
        return <IconReplay10 {...params} />;
      case 'red-eye':
        return <IconRedEye {...params} />;
      case 'save-alt':
        return <IconSaveAlt {...params} />;
      case 'search':
        return <IconSearch {...params} />;
      case 'send':
        return <IconSend {...params} />;
      case 'settings':
        return <IconSettings {...params} />;
      case 'share':
        return <IconShare {...params} />;
      case 'shared-interest':
        return <IconSharedInterest {...params} />;
      case 'sparkle-hd':
        return <IconSparkleHd {...params} />;
      case 'subscriber':
        return <IconSubscriber {...params} />;
      case 'text-align-left':
        return <IconTextAlignLeft {...params} />;
      case 'tick-circle':
        return <IconTickCircle {...params} />;
      case 'tick':
        return <IconTick {...params} />;
      case 'tiktok':
        return <IconTikTok {...params} />;
      case 'trash':
        return <IconTrash {...params} />;
      case 'twitter':
        return <IconTwitter {...params} />;
      case 'verified':
        return <IconVerified {...params} />;
      case 'volume-off':
        return <IconVolumeOff {...params} />;
      case 'volume-on':
        return <IconVolumeOn {...params} />;
      case 'warning':
        return <IconWarning {...params} />;
      case 'whatsapp':
        return <IconWhatsapp {...params} />;
      case 'workshop-hd':
        return <IconWorkshopHd {...params} />;
      case 'youtube':
        return <IconYoutube {...params} />;
      case 'lightning':
        return <IconLightning {...params} />;
      case 'fire':
        return <IconFire {...params} />;
      case 'zoom':
        return <IconZoom {...params} />;
      case 'plus':
        return <IconPlus {...params} />;
      case 'close':
        return <IconClose {...params} />;
      case 'checkmark':
        return <IconCheckmark {...params} />;
      default:
        if (process.env.DAISIE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.warn(`Can't find icon ${this.props.id}`);
        }

        return null;
    }
  };

  public render = () => {
    const {
      id,
      src,
      size,
      width,
      height,
      className = '',
      fill = 'currentColor',
    } = this.props;

    let w = this.sizes.m;
    let h = this.sizes.m;

    if (size) {
      w = h = this.sizes[size] ? this.sizes[size] : this.sizes.m;
    }

    if (width) {
      w = h = width;
    }

    if (height) {
      h = height;
    }

    if (src) {
      return (
        <svg {...{ className, fill, width: w, height: h }}>
          <use href={`${src}#${id}`} />
        </svg>
      );
    }

    if (!id) return null;

    return this.returnComponent(id, w, h, className, fill);
  };
}

export { Icon };
