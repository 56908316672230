import * as React from 'react';

import { connect } from 'unistore/react';

import { Link } from '@/components/global/Link/Link';

import { AvatarSize } from '@/components/design_system/Avatar/AvatarSize';
import { AvatarStack } from '@/components/design_system/AvatarStack/AvatarStack';

import { GlobalModal } from '@/enums/GlobalModal';
import { GlobalPortal } from '@/enums/GlobalPortal';

import { parser } from '@/routes/parser';

import { modalActions } from '@/store/modules/modal';
import { portalActions } from '@/store/modules/portal';

import { enableAttendeeListOnWorkshopAndRoomCards } from '@/utils/featureToggles';
import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props
  extends SizingState,
    ModalActions,
    ModalState,
    PortalActions,
    PortalState {
  title: string;
  attendees: User[];
  organiser: User;
  attendeeCount?: number;
  isProfile?: boolean;
  isLiveRoom?: boolean;
  isEnded?: boolean;
}

class CardTitleAttendeesComp extends React.Component<Props> {
  public render = () => {
    const {
      title,
      attendees,
      organiser,
      attendeeCount,
      isProfile,
      isLiveRoom,
      isEnded,
      sizing: { isMobile },
    } = this.props;

    return (
      <>
        <h2
          className={c('f-text-1 u-bold', {
            mb8: !isProfile,
            'f-text-2 u-very-light-grey mb4': isProfile,
          })}
        >
          {title}
        </h2>

        <div className="u-flex u-align-center">
          <AvatarStack
            users={isLiveRoom ? [organiser] : attendees}
            className="mr8"
            avatarSize={AvatarSize.card_attending}
          />

          <p className="f-text-3" style={{ color: 'white' }}>
            <span>{t('Hosted by')}</span>{' '}
            <Link
              to={parser({
                name: 'user',
                params: { username: organiser.username },
              })}
              className="u-bold u-link-white--alt"
            >
              {organiser.primaryName}
            </Link>
            {typeof attendeeCount !== 'undefined' && attendeeCount > 0 ? (
              enableAttendeeListOnWorkshopAndRoomCards() ? (
                <button
                  type="button"
                  className="u-link-white u-link-white--alt ml4"
                  onClick={(e: any) => {
                    e.preventDefault();

                    if (isMobile) {
                      this.props.updatePortal({
                        portal: GlobalPortal.workshop_attendees,
                        data: { attendees },
                      });
                    } else {
                      this.props.updateModal({
                        modal: GlobalModal.workshop_attendees,
                        data: { attendees },
                        className:
                          'page-modal__content--workshop-attendee-list',
                      });
                    }
                  }}
                >{` + ${attendeeCount} ${
                  isEnded ? t('joined') : t('going')
                }`}</button>
              ) : (
                ` + ${attendeeCount} ${isEnded ? t('joined') : t('going')}`
              )
            ) : null}
          </p>
        </div>
      </>
    );
  };
}

export const CardTitleAttendees = connect(['sizing'], () => ({
  ...modalActions(),
  ...portalActions(),
}))(CardTitleAttendeesComp);
