import * as React from 'react';

import { c } from '@/utils/strings/c';

interface Props {
  children: React.ReactNode;
  visible?: boolean;
  className?: string;
  flexDirection?: 'row' | 'column';
}

interface State {
  isVisible: boolean;
  isAnimatedIn: boolean;
}

class BottomBanner extends React.Component<Props, State> {
  public state: State = {
    isVisible:
      typeof this.props.visible !== 'undefined' ? this.props.visible : true,
    isAnimatedIn: false,
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (this.props.visible && !prevProps.visible) {
      this.setState({ isVisible: true }, () => {
        setTimeout(() => {
          this.setState({ isAnimatedIn: true });
        }, 1);
      });
    } else if (!this.props.visible && prevProps.visible) {
      this.setState({ isAnimatedIn: false }, () => {
        setTimeout(() => {
          this.setState({ isVisible: false });
        }, 250);
      });
    }
  };

  public render = () => {
    const {
      children,
      className = 'u-bg-white',
      visible,
      flexDirection = 'row',
    } = this.props;
    const { isVisible, isAnimatedIn } = this.state;

    const useAnimation = typeof visible !== 'undefined';

    if (!isVisible) return null;

    return (
      <div
        className={c(
          [
            'u-box-shadow--bottom-banner u-100vw u-fixed u-fixed--b u-send-to-front animate-opacity pv20',
            className,
          ],
          {
            'opacity-1': isAnimatedIn || !useAnimation,
            'opacity-0': !isAnimatedIn && useAnimation,
          }
        )}
      >
        <div
          className={c('wrap u-flex u-align-center', {
            'u-flex-row': flexDirection === 'row',
            'u-flex-column': flexDirection === 'column',
          })}
        >
          {children}
        </div>
      </div>
    );
  };
}

export { BottomBanner };
