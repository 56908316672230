import * as React from 'react';

import { WorkshopCard } from '@/components/global/Cards/WorkshopCard';

interface Props {
  calendarEvent: CalendarEvent;
  speakingUsers?: User[];
  nudgeUpSpeakers?: boolean;
  disableRoomLink?: boolean;
  disableAttendButton?: boolean;
}

class CalendarCard extends React.Component<Props> {
  public render = () => {
    const { calendarEvent } = this.props;
    const { workshop, start, end, isInProgress, isEnded } = calendarEvent;

    const sharedProps = {
      start,
      end,
      isInProgress,
      isEnded,
    };

    return !!workshop ? (
      <WorkshopCard
        {...sharedProps}
        calendarEvent={calendarEvent}
        workshop={workshop.workshop}
        rawAttendees={workshop.attendees}
        rawAttendeeCount={workshop.attendeeCount}
        isAttendingRaw={workshop.isAttending}
        batchId={workshop.batch.id}
        sessionId={calendarEvent.id.replace('workshopSession_', '')}
      />
    ) : null;
  };
}

export { CalendarCard };
