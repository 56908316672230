import React from 'react';

import { Button as ChakraButton, ButtonProps } from '@chakra-ui/react';

import { pxToRem } from '@/styles/chakra-theme/metrics';
import { colors } from '@/styles/chakra-theme/colors';

interface Props extends ButtonProps {
  children: (React.ReactElement | string)[];
}

const defaultProps = {
  fontSize: 16,
  fontWeight: 400,
  borderRadius: 0,
  transition: '0.4s',
  _hover: {
    opacity: 0.8,
  },
};

export const Button = ({ children, ...props }: Props) => (
  <ChakraButton {...defaultProps} {...props}>
    {children}
  </ChakraButton>
);

Button.Small = (props: Props) => (
  <Button
    display="flex"
    flexDir="row"
    justifyContent="center"
    alignItems="flex-end"
    px={pxToRem(16)}
    py={pxToRem(8)}
    bg="white"
    color="black"
    {...props}
  >
    {props.children}
  </Button>
);

Button.Gradient = (props: Props) => (
  <Button
    w={pxToRem(359)}
    h={pxToRem(119)}
    display="flex"
    flexDir="row"
    justifyContent="space-between"
    alignItems="flex-end"
    padding={`${pxToRem(16)} ${pxToRem(16)} ${pxToRem(16)} ${pxToRem(20)}`}
    bg={colors.purpleGradient}
    color="white"
    _hover={{ opacity: 0.9 }}
    {...props}
  >
    {props.children}
  </Button>
);
