import * as React from 'react';

import { connect } from 'unistore/react';

import { Dropdown } from '@/components/global/Dropdown/Dropdown';
import { Icon } from '@/components/global/Icon/Icon';
import { Link } from '@/components/global/Link/Link';

import { IconSize } from '@/enums/IconSize';

import { routes } from '@/routes';
import { parser } from '@/routes/parser';

import { categoryActions } from '@/store/modules/category';

import { getCategoryIcon } from '@/utils/category/category';
import { LOGGED_OUT_CATEGORY_PAGE_ALLOW_LIST } from '@/utils/constants';
import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props extends CategoryActions, CategoryState {
  className: string;
}

class NavigationCategoriesLoggedOutDropdownComp extends React.Component<Props> {
  public componentDidMount = async () => {
    await this.props.fetchAndSortCategories();
  };

  public render = () => {
    const {
      className,
      organisedCategories: { industries },
    } = this.props;

    const filteredIndustries = industries.filter((i: Category) =>
      LOGGED_OUT_CATEGORY_PAGE_ALLOW_LIST.includes(i.name)
    );

    return (
      <Dropdown
        trigger={
          <p className={c([className, 'u-flex u-align-center'])}>
            <span className="mr8">{t('Categories')}</span>
            <Icon id="chevron-down" size={IconSize.xs} />
          </p>
        }
        id="navigation-categories-logged-out-dropdown"
        side="left"
        darkerMode={true}
      >
        {({ close }: { close: () => void }) => (
          <div className="c-picker u-1/1 u-flex u-flex-column p16">
            {filteredIndustries.map((i: Category, index: number) => (
              <Link
                key={i.id}
                to={parser({ name: 'categoryIndex', params: { slug: i.slug } })}
                className={c('u-link-white', {
                  mb12: index !== filteredIndustries.length - 1,
                })}
                onClick={close}
              >
                <div className="u-split">
                  <div className="u-flex u-align-center">
                    {getCategoryIcon({
                      category: i,
                      className: 'mr12',
                    })}
                    <span className="no-wrap u-no-caps">{i.name}</span>
                  </div>
                </div>
              </Link>
            ))}
            <a
              href={routes.cryptoSignUp}
              className="u-link-white mt12 u-flex u-align-center"
              onClick={close}
            >
              <Icon id="hole" size={IconSize.s} className="mr12" />
              {t('Web3 (NEW)')}
            </a>
          </div>
        )}
      </Dropdown>
    );
  };
}

export const NavigationCategoriesLoggedOutDropdown = connect(
  ['organisedCategories'],
  () => ({
    ...categoryActions(),
  })
)(NavigationCategoriesLoggedOutDropdownComp);
