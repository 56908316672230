import * as React from 'react';

import { Icon } from '@/components/global/Icon/Icon';
import { Link } from '@/components/global/Link/Link';
import { LoadingSymbol } from '@/components/global/LoadingSymbol/LoadingSymbol';

import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { IconSize } from '@/enums/IconSize';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

import { c } from '@/utils/strings/c';

const getIconSize = (size: ButtonSize): IconSize => {
  switch (size) {
    case ButtonSize.s:
      return IconSize.xs;
    case ButtonSize.sm:
      return IconSize.xs;
    case ButtonSize.m:
      return IconSize.s;
    case ButtonSize.l:
      return IconSize.m;
    case ButtonSize.xl:
      return IconSize.m;
  }
};

const getLoadingSymbolSize = (size: ButtonSize): 's' | 'sm' | 'm' | 'l' => {
  switch (size) {
    case ButtonSize.s:
      return 's';
    case ButtonSize.sm:
      return 's';
    case ButtonSize.m:
      return 'm';
    case ButtonSize.l:
      return 'm';
    case ButtonSize.xl:
      return 'm';
  }
};

const getIconMargin = (
  size: ButtonSize,
  iconSide: 'left' | 'right'
): string => {
  let result = 'm';

  if (iconSide === 'left') {
    result = `${result}r`;
  } else {
    result = `${result}l`;
  }

  if (size === ButtonSize.s) {
    result = `${result}8`;
  } else if (size === ButtonSize.sm) {
    result = `${result}8`;
  } else if (size === ButtonSize.m) {
    result = `${result}12`;
  } else if (size === ButtonSize.l) {
    result = `${result}16`;
  }

  return result;
};

const getLoadingSymbolColor = (buttonStyle: ButtonStyle): 'white' | 'black' => {
  switch (buttonStyle) {
    case ButtonStyle.default:
    case ButtonStyle.dark:
    case ButtonStyle.dangerInvert:
    case ButtonStyle.outlineLight:
      return 'white';
    default:
      return 'black';
  }
};

export const Button = (props: ButtonProps) => {
  const {
    className = '',
    size = ButtonSize.m,
    buttonStyle = ButtonStyle.default,
    modifier,
    type,
    buttonRef,
    uiTestId,
    counter,
    disabled,
    isLoading,
    content: contentOverride,
    safariTransformFix = false,
    buttonTextClassName = '',
  } = props;

  const {
    text,
    iconId,
    iconSide = 'right',
    iconClassName = '',
    iconSize: iconSizeOverride,
  } = props as TextIconButton;

  const iconOnly = !!iconId && !!text === false;

  const buttonClassName = c(
    [
      className,
      'dds-button',
      `dds-button--${buttonStyle}`,
      `dds-button--${buttonStyle}--size-${size}`,
      `dds-button--size-${size}`,
    ],
    {
      'dds-button--link': type === ButtonType.link,
      [`dds-button--size-${size}--icon-only`]: iconOnly,
      [`dds-button--wide-${size}`]: modifier === 'wide',
      relative: isLoading,
      'u-translate-3d-fix': safariTransformFix,
    }
  );

  const iconSize = iconSizeOverride ? iconSizeOverride : getIconSize(size);
  const iconClassNameString = c([iconClassName], {
    [getIconMargin(size, iconSide)]: !!text,
  });

  const loadingSymbolSize = getLoadingSymbolSize(size);
  const loadingSymbolColour = getLoadingSymbolColor(buttonStyle);

  const icon = (
    <Icon id={iconId} size={iconSize} className={iconClassNameString} />
  );

  const loadingSymbol = isLoading ? (
    <LoadingSymbol
      colour={loadingSymbolColour}
      className="absolute absolute--mid-center"
      size={loadingSymbolSize}
    />
  ) : null;

  const content = contentOverride ? (
    contentOverride
  ) : (
    <>
      <p
        className={c(
          [
            'u-flex u-align-center u-justify-center u-1/1 animate-opacity',
            buttonTextClassName,
          ],
          {
            relative: !!counter,
            'f-text-3': size === ButtonSize.s,
            'f-text-2': size === ButtonSize.m,
            'opacity-0': isLoading,
            'opacity-1': !isLoading,
          }
        )}
      >
        {iconId && iconSide === 'left' && icon}
        {text && <span>{text}</span>}
        {!!counter && (
          <span className={c(['counter-badge', `counter-badge--size-${size}`])}>
            {counter}
          </span>
        )}
        {iconId && iconSide === 'right' && icon}
      </p>
      {loadingSymbol}
    </>
  );

  if (type === ButtonType.link) {
    const { linkTo, onClick, download, downloadFilename, newWindow } =
      props as LinkButton;

    const onClickAction = (e: any) => {
      if (disabled || isLoading) {
        e.preventDefault();
        return;
      }

      if (onClick) {
        onClick();
      }
    };

    const isEmail: boolean =
      typeof linkTo === 'string' && linkTo.includes('mailto:');

    if (download || isEmail) {
      // This `newWindow` conditional is verbose to make eslint happy
      if (newWindow) {
        return (
          <a
            // @ts-ignore
            href={linkTo}
            download={downloadFilename || true}
            target="_blank"
            rel="noopener noreferrer"
            className={c([buttonClassName], {
              'dds-button--disabled': disabled || isLoading,
            })}
            onClick={onClickAction}
            data-ui-test-id={isUiTest() && uiTestId ? uiTestId : undefined}
          >
            {content}
          </a>
        );
      } else {
        return (
          <a
            // @ts-ignore
            href={linkTo}
            download={downloadFilename || true}
            className={c([buttonClassName], {
              'dds-button--disabled': disabled || isLoading,
            })}
            onClick={onClickAction}
            data-ui-test-id={isUiTest() && uiTestId ? uiTestId : undefined}
          >
            {content}
          </a>
        );
      }
    } else {
      return (
        <Link
          to={linkTo}
          className={c([buttonClassName], {
            'dds-button--disabled': disabled || isLoading,
          })}
          onClick={onClickAction}
          target={newWindow ? '_blank' : undefined}
          rel={newWindow ? 'noopener noreferrer' : undefined}
          data-ui-test-id={isUiTest() && uiTestId ? uiTestId : undefined}
        >
          {content}
        </Link>
      );
    }
  } else if (type === ButtonType.action || type === ButtonType.submit) {
    const { onClick, onMouseOver, onMouseOut } = props as ActionButton;

    return (
      <button
        ref={buttonRef}
        type={type === ButtonType.submit ? 'submit' : 'button'}
        className={buttonClassName}
        data-ui-test-id={isUiTest() && uiTestId ? uiTestId : undefined}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        disabled={isLoading || disabled}
        // TODO
        tabIndex={0}
      >
        {content}
      </button>
    );
  }

  return null;
};
