export const getWorkshopStatsFromData = (
  data: APIWorkshopStats[]
): WorkshopStats[] => data.map(getWorkshopStatsItemFromData).filter(Boolean);

export const getWorkshopStatsItemFromData = (
  data: APIWorkshopStats
): WorkshopStats => {
  const {
    id,
    attributes: {
      title,
      slug,
      stats: {
        adViewsCount,
        attendeeCount,
        debutAttendeeCount,
        linkClicks,
        mentorEarnings,
        relatedSubscriptionsCount,
        signedUpForWorkshopCount,
        viewsCount,
        referralCount,
        flatFee,
      },
    },
    relationships: { coverMedia },
  } = data;

  return {
    id,
    title,
    slug,
    coverMediaUrl: coverMedia.data.attributes.metadata.processorMediaPath,
    adViewsCount,
    attendeeCount,
    debutAttendeeCount,
    linkClicks,
    mentorEarnings,
    relatedSubscriptionsCount,
    signedUpForWorkshopCount,
    viewsCount,
    referralCount,
    flatFee,
  };
};
