import * as React from 'react';

import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'unistore/react';
import widont from 'widont';

import { Head } from '@/components/global/Head/Head';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { Link } from '@/components/global/Link/Link';
import { SkeletonNew } from '@/components/global/Skeleton/SkeletonNew';
import { AnimatedWordmark } from '@/components/global/Wordmark/AnimatedWordmark';
import { Wordmark } from '@/components/global/Wordmark/Wordmark';
import { WorkshopReview } from '@/components/global/WorkshopReview/WorkshopReview';
import { SectionPromo } from '@/components/marketing/HomeCategoryPageSections/SectionPromo/SectionPromo';
import {
  AB_TEST_DISCOUNT_PERCENT_OFF_ACTUAL_GBP_EUR,
  AB_TEST_DISCOUNT_PERCENT_OFF_ACTUAL_USD,
  AB_TEST_DISCOUNT_PERCENT_OFF_DISPLAY,
  STUDENT_DISCOUNT_PERCENT_OFF_ACTUAL_GBP_EUR,
  STUDENT_DISCOUNT_PERCENT_OFF_ACTUAL_USD,
  TRIAL_LENGTH_TEXT_SINGULAR,
} from '@/components/subscription/constants';
import imgBackground from '@/components/subscription/images/background.png';
import { SubscriptionPlanSelector } from '@/components/subscription/SubscriptionPlanSelector/SubscriptionPlanSelector';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { DaisieCustomEvent } from '@/enums/DaisieCustomEvent';
import { DaisieWordmarkColour } from '@/enums/DaisieWordmarkColour';
import { SubscriptionInterval } from '@/enums/SubscriptionInterval';
import { SubscriptionPlanSelectorStyle } from '@/enums/SubscriptionPlanSelectorStyle';

import { OptimisedJoinFlowSkipCloseButton } from '@/pages/OptimisedJoinFlow/OptimisedJoinFlowSkipCloseButton';

import { routes } from '@/routes';

import { getWorkshopFromData } from '@/store/helpers';
import { findProductAndPrice } from '@/store/helpers/subscriptionHelpers';

import { Http } from '@/utils/api/Http';
import { t } from '@/utils/i18n/i18n';
import { track } from '@/utils/mixpanel/mixpanel';
import { c } from '@/utils/strings/c';
import { getDisplayPrice } from '@/utils/strings/currency';
import { getRandomInt } from '@/utils/strings/number-manipulation';
import { getRouteFromUrl } from '@/utils/urls/routes';

interface Props
  extends ABTestState,
    AuthState,
    SizingState,
    SubscriptionState,
    RouteComponentProps {}

interface State {
  selectedSubscriptionInterval?: SubscriptionInterval;
  redirectToHome: boolean;
  redirectToYearlyPlan: boolean;
  workshopVariantOneStep: number;
  selectedWorkshop?: Workshop;
  isSelectedWorkshopLoaded: boolean;
}

class OptimiseJoinFlowChoosePlanComp extends React.Component<Props, State> {
  public state: State = {
    selectedSubscriptionInterval: SubscriptionInterval.year,
    redirectToHome: false,
    redirectToYearlyPlan: false,
    workshopVariantOneStep: 0,
    selectedWorkshop: undefined,
    isSelectedWorkshopLoaded: false,
  };

  public componentDidMount = () => {
    const {
      auth: { user },
      abtest: { variant },
      sizing: { isMobile },
    } = this.props;

    this.fetchWorkshop();

    if (!!user && user.subscriptionTier !== null) {
      this.setState({ redirectToHome: true });
      return;
    }

    if (!!variant && variant === 2) {
      this.setState({ redirectToYearlyPlan: true });
      return;
    }

    let workshopVariant: number = 0;

    const workshopSubscriptionVariant = localStorage.getItem(
      'workshop_subscription_variant'
    );

    if (
      window.location.href.includes('?to=%2Fworkshops%2F') &&
      workshopSubscriptionVariant === null
    ) {
      workshopVariant = getRandomInt(0, 2);
      localStorage.setItem(
        'workshop_subscription_variant',
        workshopVariant.toString()
      );

      if (isMobile) {
        track('workshop_subscription_variant', {
          variant: Number(workshopSubscriptionVariant),
        });
      }
    }
  };

  private fetchWorkshop = async () => {
    this.setState({ isSelectedWorkshopLoaded: false });

    try {
      const workshopID = window.location.href.split('-').pop();
      const { data } = await new Http(`/workshops/workshop_${workshopID}`).get<
        APIObject<APIWorkshop>
      >();

      const selectedWorkshop = getWorkshopFromData(data);
      this.setState({
        selectedWorkshop,
        isSelectedWorkshopLoaded: true,
      });
    } catch (e) {
      // TODO
    }
  };

  public handlePlanChangeForPricing = () => {
    this.setState({
      selectedSubscriptionInterval: SubscriptionInterval.month,
    });
  };

  public render = () => {
    const {
      abtest: { variant },
      location: { pathname, search },
      auth: { isAuthorised, user },
      sizing: { isMobile },
      subscription: { userCurrency, productsAndPrices },
    } = this.props;

    const {
      selectedSubscriptionInterval,
      redirectToHome,
      redirectToYearlyPlan,
      workshopVariantOneStep,
      selectedWorkshop,
      isSelectedWorkshopLoaded,
    } = this.state;

    const isUserComingFromWorkshop = window.location.href.includes(
      '?to=%2Fworkshops%2F'
    );
    const workshopSubscriptionVariant = localStorage.getItem(
      'workshop_subscription_variant'
    );

    const queryParams = new URLSearchParams(search);
    const redirectTo = queryParams.get('to') || undefined;

    if (redirectToYearlyPlan) {
      return (
        <Redirect
          to={`${
            isAuthorised
              ? routes.optimisedJoinFlowSignUpYearlyPay
              : routes.optimisedJoinFlowSignUpYearly
          }${!!redirectTo ? `?to=${encodeURIComponent(redirectTo)}` : ``}`}
        />
      );
    }

    if (redirectToHome) {
      return <Redirect to={routes.home} />;
    }

    if (typeof variant === 'undefined') {
      return null;
    }

    const matchedRoute = getRouteFromUrl(pathname);

    const isGrandfathered: boolean = !!user && user.isGrandfathered;
    const hasStudentDiscount: boolean = matchedRoute === 'studentsChoosePlan';
    const hasABTestDiscount: boolean = variant === 2 && !hasStudentDiscount;

    const currentSubscriptionInterval =
      selectedSubscriptionInterval === 'year'
        ? SubscriptionInterval.year
        : SubscriptionInterval.month;

    const productPrice: SubscriptionProductPrice | undefined =
      findProductAndPrice({
        productsAndPrices,
        currency: userCurrency,
        recurringInterval: currentSubscriptionInterval,
      });

    if (!productPrice) {
      return null;
    }

    const displayAmount: number = isGrandfathered ? productPrice.amount : 0;

    const title = (
      <h1 className="f-lynstone-bold-1a u-1/1 mb24">
        {user && user.hasPastSub ? (
          <>{t("Let's get started.")}</>
        ) : (
          <>
            {t('Get started ')}
            <span className="u-block@m">
              {t('for ')}
              {getDisplayPrice(userCurrency, displayAmount)} {t('today.')}
            </span>
          </>
        )}
      </h1>
    );

    const pricingCaption = (
      <div className="u-grey mb32">
        {t('Try Daisie for free, cancel anytime.')}{' '}
        {getDisplayPrice(userCurrency, productPrice.amount)} {t('billed')}{' '}
        {productPrice?.recurringInterval === SubscriptionInterval.year
          ? t('annually')
          : t('monthly')}{' '}
        {t('after')} {TRIAL_LENGTH_TEXT_SINGULAR} {t('free trial.')}{' '}
      </div>
    );

    const subscriptionPlanSelector = (
      <SubscriptionPlanSelector
        workshopVariantOneStep={workshopVariantOneStep}
        isUserComingFromWorkshop={isUserComingFromWorkshop}
        selectorStyle={SubscriptionPlanSelectorStyle.choosePlan}
        className="mb32"
        selectedSubscriptionInterval={selectedSubscriptionInterval}
        setSelectedSubscriptionInterval={(
          subscriptionInterval?: SubscriptionInterval
        ) => {
          this.setState({
            selectedSubscriptionInterval: subscriptionInterval,
          });
        }}
        annualPriceDiscountPercent={
          // Set to undefined when `isGrandfathered` because the price on Stripe
          // already has the discount applied to it
          isGrandfathered
            ? undefined
            : hasStudentDiscount
            ? userCurrency === 'usd'
              ? STUDENT_DISCOUNT_PERCENT_OFF_ACTUAL_USD
              : STUDENT_DISCOUNT_PERCENT_OFF_ACTUAL_GBP_EUR
            : hasABTestDiscount
            ? userCurrency === 'usd'
              ? AB_TEST_DISCOUNT_PERCENT_OFF_ACTUAL_USD
              : AB_TEST_DISCOUNT_PERCENT_OFF_ACTUAL_GBP_EUR
            : undefined
        }
        customAnnualOfferCallout={
          hasStudentDiscount
            ? t('Special offer — Students')
            : hasABTestDiscount
            ? t(`Special offer — ${AB_TEST_DISCOUNT_PERCENT_OFF_DISPLAY}% off`)
            : variant === 1
            ? t('2-For-1 Offer Ends Soon.')
            : undefined
        }
        linkToStudentDiscountFlow={hasStudentDiscount}
        forStudentDiscount={hasStudentDiscount}
        redirectTo={redirectTo}
      />
    );

    return (
      <div className="u-h-100vh@m u-flex@m">
        <Head pathname={t('/join/choose-plan')} />

        {isAuthorised && (
          <OptimisedJoinFlowSkipCloseButton type="close" linkTo={routes.home} />
        )}

        <div
          className="wrap u-1/1 absolute absolute--x-center u-z-index-2 u-flex u-align-center u-justify-center u-hide@s"
          style={{ height: 72 }}
        >
          <Link to={routes.home}>
            <Wordmark
              colour={DaisieWordmarkColour.White}
              height="20px"
              className="u-hide@m absolute absolute--x-center"
            />
            <AnimatedWordmark
              className="u-hide@s"
              colour={DaisieWordmarkColour.White}
            />
          </Link>
        </div>

        {isUserComingFromWorkshop && workshopVariantOneStep !== 1 && (
          <div className="c-workshop-hero-wrapper__spacer" />
        )}

        <div
          className={c('wrap u-flex@m u-white u-h-100@m mb44@s', {
            'mt32 pt8': workshopVariantOneStep === 1,
          })}
        >
          <div className="u-1/1 u-1/2@m u-flex u-align-center u-justify-center">
            {!isUserComingFromWorkshop && (
              <div className="u-1/1">
                <h1
                  className={c('mt32@s mb32 mr128@m u-text-center@s', {
                    'f-lynstone-bold-2': isMobile,
                    'f-lynstone-bold-1': !isMobile,
                  })}
                >
                  {variant === 1 ? (
                    <>
                      {t('Buy one.')}
                      <span className="u-block">{t('Give one,')}</span>
                      <span className="u-block@m u-go u-block@s">
                        {t('free')}
                      </span>
                    </>
                  ) : (
                    t('Learn from industry experts')
                  )}
                </h1>

                <h2 className="u-dark-grey f-lynstone-book-2a u-hide@s">
                  {variant === 1
                    ? t(
                        'Get unlimited access to 300+ classes and give a free All-Access Pass to someone special. '
                      )
                    : t(
                        'Boost your learning alongside creators from around the world. Choose a Daisie plan and get started.'
                      )}
                </h2>

                <h2 className="u-white u-hide@m u-text-center f-text-1 u-bold mb64">
                  {t(
                    'Boost your learning alongside artists from around the world.'
                  )}
                </h2>
              </div>
            )}
          </div>

          <div
            className={c(
              'u-1/1 u-1/2@m u-flex u-align-center u-justify-center u-z-index-2',
              {
                'u-flex-column': isUserComingFromWorkshop,
              }
            )}
          >
            {isUserComingFromWorkshop && (
              <div>
                {workshopVariantOneStep === 0 ? (
                  <div className="u-z-index-1 u-text-center@s">
                    <h1
                      className={c('mb24@m mb20@s u-text-center@s', {
                        'f-yell-1z u-letter-spacing': isMobile,
                        'f-lynstone-bold-1a': !isMobile,
                        'mt20@s': workshopSubscriptionVariant === '1',
                      })}
                    >
                      {widont(t('Learn from creative experts'))}
                    </h1>
                    {isSelectedWorkshopLoaded ? (
                      <p className="f-lynstone-book-2a mb16@m mb44@s u-7/8@m u-grey">
                        {t('Join ')}
                        <span className="u-white u-bold">
                          {t(
                            `${
                              selectedWorkshop?.mentor.name
                                ? selectedWorkshop?.mentor.name
                                : selectedWorkshop?.mentor.username
                            }'s `
                          )}
                        </span>
                        {widont(
                          t(
                            `workshop and get unlimited access to classes on Daisie. `
                          )
                        )}
                        {/* TODO: this needs to be fixed properly when refactoring JoinFlow components */}
                        {!isMobile && (
                          <> ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ</>
                        )}
                      </p>
                    ) : (
                      <p className="u-7/8@m mb24">
                        <SkeletonNew
                          elementClassName="f-lynstone-book-2a"
                          fillClassName="c-logged-in-homepage__skeleton"
                          fitToText={`Join ${
                            selectedWorkshop?.eventType === 'original'
                              ? selectedWorkshop?.mentor?.name
                              : 'Daisie'
                          }'s workshop and get unlimited access to 100+ other instructors in live, daily classes. Get started for free.`}
                        />
                      </p>
                    )}
                  </div>
                ) : (
                  <div>
                    {title}
                    {pricingCaption}
                  </div>
                )}
              </div>
            )}
            {!isUserComingFromWorkshop ||
            (isUserComingFromWorkshop && workshopSubscriptionVariant === '0') ||
            (workshopSubscriptionVariant === '1' &&
              workshopVariantOneStep === 1) ||
            !isMobile ? (
              <div className={c('u-1/1 mh64@m')}>
                {subscriptionPlanSelector}
              </div>
            ) : (
              <div className="u-1/1">
                <Button
                  type={ButtonType.action}
                  onClick={() => {
                    this.setState(
                      {
                        workshopVariantOneStep:
                          this.state.workshopVariantOneStep + 1,
                      },
                      () => {
                        window.dispatchEvent(
                          new CustomEvent(
                            DaisieCustomEvent.force_hide_onboarding_workshop_hero
                          )
                        );
                      }
                    );
                  }}
                  buttonStyle={ButtonStyle.default}
                  size={ButtonSize.xl}
                  text={t('Try for free')}
                  className="u-fit-content u-1/1@s"
                />
              </div>
            )}
          </div>
        </div>

        {isMobile && !isUserComingFromWorkshop && (
          <>
            <div className="wrap u-white">
              <SectionPromo />
            </div>

            <div className="u-white">
              <WorkshopReview />
            </div>

            <div className="wrap u-white mv64">
              <h1 className="f-lynstone-bold-2 mb32 u-text-center">
                {t('Learn from industry experts')}
              </h1>

              <p className="u-white u-text-center f-text-1 u-bold mb64">
                {t(
                  'Boost your learning alongside artists from around the world.'
                )}
              </p>

              {subscriptionPlanSelector}
            </div>
          </>
        )}

        {!isUserComingFromWorkshop && (
          <ImageLoader
            src={imgBackground}
            className="absolute absolute--bottom-center u-1/2@m u-1/3@l mhauto u-hide@s"
          />
        )}
      </div>
    );
  };
}

export const OptimiseJoinFlowChoosePlan = connect(
  ['abtest', 'auth', 'sizing', 'subscription'],
  () => ({})
)(withRouter(OptimiseJoinFlowChoosePlanComp));
