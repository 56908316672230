export {};

declare global {
  interface Array<T> {
    alphabetiseByKey(key?: string): T[];
  }
}

if (!Array.prototype.alphabetiseByKey) {
  Object.defineProperty(Array.prototype, 'alphabetiseByKey', {
    value<T>(this: T[], key?: string): T[] {
      this.sort((a, b) => a[key || 'name'].localeCompare(b[key || 'name']));
      return this;
    },
    enumerable: false,
  });
}
