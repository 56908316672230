import * as React from 'react';

import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';

import { AvatarSize } from '@/components/design_system/Avatar/AvatarSize';

import { avatarFallback } from '@/store/helpers';

import { compressProfilePicture } from '@/utils/media/compress';
import { c } from '@/utils/strings/c';

interface Props {
  className?: string;
  user: User;
  size?: AvatarSize;
  disableImageLoadAnimation?: boolean;
  sizePx?: number;
  style?: any;
  showRingColour?: boolean;
}

interface State {
  fallbackAvatarPath?: string;
}

class Avatar extends React.Component<Props, State> {
  public state: State = {
    fallbackAvatarPath: undefined,
  };

  private handleImageError = () => {
    this.setState({
      fallbackAvatarPath: avatarFallback(),
    });
  };

  public render = () => {
    const {
      user,
      className = '',
      size,
      sizePx,
      disableImageLoadAnimation,
      style = {},
      showRingColour,
    } = this.props;

    const { fallbackAvatarPath } = this.state;

    return (
      <div
        className={c(
          [
            className,
            'dds-avatar u-bg-light-grey relative u-overflow-hidden u-overflow-hidden-fix',
          ],
          {
            [`dds-avatar--${size}`]: size,
          }
        )}
        style={{
          ...style,
          width: sizePx,
          paddingBottom: sizePx,
          border:
            showRingColour && user.ringColour
              ? `${user.ringColour} 2px solid`
              : undefined,
        }}
      >
        {fallbackAvatarPath ? (
          <ImageLoader
            src={fallbackAvatarPath}
            className="dds-avatar__avatar"
            backgroundClassName="u-bg-light-grey"
            noAnimations={disableImageLoadAnimation}
            constrainSquare={true}
            convertToWebp={true}
          />
        ) : (
          <ImageLoader
            src={compressProfilePicture(user.avatar)}
            className="dds-avatar__avatar"
            backgroundClassName="u-bg-light-grey"
            onError={this.handleImageError}
            noAnimations={disableImageLoadAnimation}
            constrainSquare={true}
            convertToWebp={true}
          />
        )}
      </div>
    );
  };
}

export { Avatar };
