import * as React from 'react';

import { Avatar } from '@/components/design_system/Avatar/Avatar';
import { AvatarSize } from '@/components/design_system/Avatar/AvatarSize';

import { c } from '@/utils/strings/c';

interface Props {
  className?: string;
  users: User[];
  maxAvatars?: number;
  disableImageLoadAnimation?: boolean;
  avatarSize?: AvatarSize;
}

export const AvatarStack = (props: Props) => {
  const {
    className = '',
    users,
    maxAvatars = 3,
    disableImageLoadAnimation,
    avatarSize,
  } = props;

  const usersToShow = [...users].splice(0, maxAvatars).reverse();

  return (
    <div className={c(['dds-avatar-stack u-flex', className])}>
      {usersToShow.map((user: User) => (
        <Avatar
          key={user.id}
          user={user}
          className="dds-avatar-stack__avatar"
          disableImageLoadAnimation={disableImageLoadAnimation}
          size={avatarSize}
        />
      ))}
    </div>
  );
};
