import * as React from 'react';

import { ButtonGroupSpacing } from '@/components/design_system/ButtonGroup/ButtonGroupSpacing';

import { c } from '@/utils/strings/c';

interface Props {
  className?: string;
  spacing?: ButtonGroupSpacing;
}

class ButtonGroup extends React.Component<Props> {
  public render = () => {
    const { className = '', spacing = ButtonGroupSpacing.normal } = this.props;

    return (
      <div
        className={c([
          'dds-button-group u-flex',
          `dds-button-group--${spacing}`,
          className,
        ])}
      >
        {this.props.children}
      </div>
    );
  };
}

export { ButtonGroup };
