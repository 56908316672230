import mixpanel from 'mixpanel-browser';

const env = !!process.env.DAISIE_MIXPANEL && process.env.NODE_ENV !== 'test';

if (process.env.DAISIE_MIXPANEL) {
  mixpanel.init(process.env.DAISIE_MIXPANEL);
}

export const track = (name: string, props?: any | undefined) => {
  const urlParams = new URLSearchParams(window.location.search);
  const skipAnalytics = !!urlParams.get('skipAnalytics');

  if (skipAnalytics) {
    return;
  }

  if (process.env.DAISIE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log(name, props);
  }

  return env && mixpanel.track(name, { platform: 'web', ...props });
};

export const identify = (id?: string | undefined) =>
  env && mixpanel.identify(id);

export const alias = (a: string) => env && mixpanel.alias(a);

export const set = (prop: string | any, to?: any) =>
  env && mixpanel.people.set(prop, to);

export const reset = () => env && mixpanel.reset();
