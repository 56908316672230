import * as React from 'react';

import {
  Redirect,
  Route,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';

import { Http } from '@/utils/api/Http';

interface Props extends RouteComponentProps {
  hidePageNotFound: boolean;
  setHidePageNotFound: (newState: boolean) => void;
}

interface State {
  redirects: any[];
}

class CustomRedirectsComponent extends React.Component<Props, State> {
  public state = {
    redirects: [],
  };

  public componentDidMount = async () => {
    if (this.props.location.pathname === '/') {
      return;
    }

    const { data } = await new Http('/redirect').get<APIObject<APIData[]>>();

    this.setState({
      redirects: data.map((r: APIData) => ({
        id: r.id,
        key: r.attributes.key,
        origin: r.attributes.origin,
        destination: r.attributes.destination,
      })),
    });
  };

  public render = () => {
    const { redirects } = this.state;

    if (!redirects.length) return null;

    return redirects.map(
      ({
        id,
        origin,
        destination,
      }: {
        id: string;
        origin: string;
        destination: string;
      }) => {
        const isInternalLink = destination[0] === '/';

        return (
          <Route
            key={id}
            render={() => {
              if (isInternalLink) {
                return <Redirect to={destination} />;
              } else {
                if (!this.props.hidePageNotFound) {
                  this.props.setHidePageNotFound(true);
                }

                window.location.href = destination;

                return null;
              }
            }}
            exact={true}
            path={origin}
          />
        );
      }
    );
  };
}

export const CustomRedirects = withRouter(CustomRedirectsComponent);
