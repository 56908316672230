import React, { useEffect, useState } from 'react';

import { connect } from 'unistore/react';
import widont from 'widont';

import { Carousel } from '@/components/global/Carousel/Carousel';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { Link } from '@/components/global/Link/Link';
import { Toast } from '@/components/global/Toaster/Toast';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { WORKSHOP_RELATED_WORKSHOPS_FAIL } from '@/messages/errors';

import { routes } from '@/routes';
import { parser } from '@/routes/parser';

import { getWorkshopsFromData } from '@/store/helpers';

import { Http } from '@/utils/api/Http';
import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props extends SizingState {
  workshop: Workshop;
  isOriginals?: boolean;
  fontClass?: string;
}

const RecommendedWorkshopsComponent = ({
  workshop,
  sizing,
  isOriginals,
  fontClass,
}: Props) => {
  const [recommendedWorkshops, setRecommendedWorkshops] = useState<Workshop[]>(
    []
  );
  const [isRecommendedWorkshopsLoaded, setIsRecommendedWorkshopsLoaded] =
    useState(false);

  useEffect(() => {
    fetchRecommendedWorkshops();
  }, []);

  const fetchRecommendedWorkshops = async () => {
    const { categories } = workshop;
    const category = categories[0].name;

    setIsRecommendedWorkshopsLoaded(false);

    try {
      const { data } = await new Http(
        `/workshops?pageSize=10&pageNumber=1&sortBy=workshopStartDate${
          !!category ? `&categories=${encodeURIComponent(category)}` : ''
        }&filters=isUpcoming`
      ).get<APIObject<APIWorkshop[]>>();

      const upcomingWorkshops = getWorkshopsFromData(data)
        .filter((w) => w.id !== workshop.id)
        .reverse();

      if (upcomingWorkshops.length === 0) {
        const { data: allWorkshopsData } = await new Http(
          `/workshops?pageSize=10&pageNumber=1&sortBy=workshopStartDate${
            !!category ? `&categories=${encodeURIComponent(category)}` : ''
          }`
        ).get<APIObject<APIWorkshop[]>>();

        setRecommendedWorkshops(getWorkshopsFromData(allWorkshopsData));
        setIsRecommendedWorkshopsLoaded(true);
      } else {
        setRecommendedWorkshops(upcomingWorkshops);
        setIsRecommendedWorkshopsLoaded(true);
      }
    } catch (e) {
      new Toast({
        body: WORKSHOP_RELATED_WORKSHOPS_FAIL,
        failure: true,
      }).dispatch();
    }
  };

  const filteredWorkshop = recommendedWorkshops
    .filter((w) => w.id !== workshop.id)
    .slice(0, 4);

  const renderCards = (
    <>
      {filteredWorkshop.map((w: Workshop) => (
        <Link
          key={w.id}
          to={parser({
            name: 'workshop',
            params: { workshopSlug: w.slug },
          })}
          className="c-workshop-recommended-carousel__item relative u-1/1 u-white"
        >
          <div
            className={c('relative', {
              'u-width-fill-available': sizing.isMobile,
              'fill ': !sizing.isMobile,
            })}
          >
            <div
              className="opacity-1 absolute u-z-index u-flex u-border-radius u-border-radius--s u-1/1"
              style={{
                backgroundImage:
                  'linear-gradient(to bottom, transparent, #111111)',
                bottom: '0',
                height: sizing.isMobile ? '352px' : '100%',
              }}
            />
            <ImageLoader
              src={w.coverMedia}
              className="u-1/1 u-object-cover u-border-radius u-border-radius--s"
              style={{ height: sizing.isMobile ? '26rem' : '100%' }}
              convertToWebp={true}
            />
            <h1
              className={c(
                'u-bold mh20@s mh32@m u-1/2@l u-overflow-hidden absolute absolute--center',
                {
                  'f-title-1': !sizing.isTablet,
                  'f-title-2': sizing.isTablet,
                }
              )}
              style={{ width: 'inherit', margin: 'auto' }}
            >
              {widont(w.title)}
            </h1>
            <div className="u-flex mb20 ml20 absolute absolute--bl">
              <div className="u-flex-hold mr12">
                <ImageLoader
                  src={w.mentor.avatar}
                  className="u-rounded c-section-workshops__workshop_carousel__card__avatar"
                  convertToWebp={true}
                />
              </div>

              <div className="u-flex-1 u-flex u-flex-column u-justify-center">
                {!isOriginals && (
                  <p className="f-text-3 u-light-grey">{t('Instructor')}</p>
                )}
                <p className="f-text-3 u-bold">{w.mentor.name}</p>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </>
  );

  return (
    <div
      className={c('', {
        'u-bg-almost-black pv60 mb128@m': isOriginals && sizing.isDesktop,
      })}
    >
      <div
        className={c('mv80@s mt80@m mb128@m mhauto@l', {
          'mb200@l': !isOriginals,
          'wrap--m': sizing.isDesktop,
        })}
      >
        <div
          className={c(
            'u-flex u-align-center u-justify-between mhauto wrap mb60@m mb20@s u-1/1@s',
            {
              'u-3/4@m': !sizing.isDesktop,
            }
          )}
        >
          {isOriginals ? (
            <h2 className={c(`f-${fontClass}-bold-1b u-very-light-grey`)}>
              {t('Upcoming live workshops')}
            </h2>
          ) : (
            <h2 className="c-workshop-sentences__h2 f-inter-medium-1">
              <span className="u-block">Similar</span>
              workshops
            </h2>
          )}

          {!sizing.isMobile && (
            <div>
              {isOriginals ? (
                <Link to={routes.calendar} className="u-originals-yellow">
                  {widont(t('View more'))}
                </Link>
              ) : (
                <Button
                  type={ButtonType.link}
                  linkTo={routes.calendar}
                  text={t('View All')}
                  buttonStyle={ButtonStyle.outlineLight}
                  size={ButtonSize.m}
                  buttonTextClassName="ph8 pv4"
                />
              )}
            </div>
          )}
        </div>
        {sizing.isMobile ? (
          <div className="c-workshop-similar-carousel">
            <Carousel
              columns={1.1}
              padding={24}
              wrap={false}
              buttonClassName="carousel__button--theme-light u-link-black--alt u-hide u-flex@l"
              counterDotsClassName="u-hide"
              shadowFix={true}
              disableSnap={true}
              showFade={false}
              isLoaded={isRecommendedWorkshopsLoaded}
              hideArrows={true}
            >
              {renderCards}
            </Carousel>
          </div>
        ) : (
          <div
            className={c('u-grid u-grid--1 mhauto wrap', {
              'u-3/4@m': !sizing.isDesktop,
            })}
            style={{
              gridGap: '24px',
            }}
          >
            {renderCards}
          </div>
        )}
      </div>
    </div>
  );
};

export const RecommendedWorkshops = connect(['sizing'])(
  RecommendedWorkshopsComponent
);
