import React from 'react';

import { Heading, Text as ChakraText, TextProps } from '@chakra-ui/react';

import { pxToRem } from '@/styles/chakra-theme/metrics';

import { isMobile } from '@/utils/DOM/sizing';

const kinds = {
  light: {
    weight: 300,
    style: 'normal',
  },
  lightItalic: {
    weight: 300,
    style: 'italic',
  },
  regular: {
    weight: 400,
    style: 'normal',
  },
  regularItalic: {
    weight: 400,
    style: 'italic',
  },
  semiBold: {
    weight: 600,
    style: 'normal',
  },
  semiBoldItalic: {
    weight: 600,
    style: 'italic',
  },
  bold: {
    weight: 700,
    style: 'normal',
  },
  boldItalic: {
    weight: 700,
    style: 'italic',
  },
};
interface Props extends TextProps {
  children: (React.ReactElement | string)[] | React.ReactElement | string;
  kind?:
    | 'light'
    | 'lightItalic'
    | 'regular'
    | 'regularItalic'
    | 'semiBold'
    | 'semiBoldItalic'
    | 'bold'
    | 'boldItalic';
}

export const Text = ({ children, kind = 'regular', ...props }: Props) => (
  <ChakraText
    fontSize={pxToRem(28)}
    letter-spacing="0.02em"
    lineHeight="140%"
    fontFamily="NeueMontreal"
    fontWeight={kinds[kind].weight}
    fontStyle={kinds[kind].style}
    {...props}
  >
    {children}
  </ChakraText>
);

export const Title = ({ children, ...props }: Props) => (
  <Heading
    fontSize={pxToRem(isMobile() ? 40 : 52)}
    lineHeight={pxToRem(isMobile() ? 48 : 62.4)}
    fontFamily="NeueMontreal"
    fontWeight="400"
    letterSpacing="2%"
    {...props}
  >
    {children}
  </Heading>
);
