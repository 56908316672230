export enum NotificationTypes {
  CollaborationInvitation = 'collaboration-invite-me',
  CollaborationInvite = 'collaboration-invite',
  CollaborationInviteAccept = 'collaboration-invite-accept',
  CollaborationInviteDecline = 'collaboration-invite-decline',
  CollaborationRequest = 'collaboration-request',
  CollaboratorRequestAcceptAll = 'collaboration-request-accept-all',
  CollaboratorRequestAccept = 'collaboration-request-accept',
  CollaborationRequestReject = 'collaboration-request-reject',
  CollaboratorRemove = 'collaborator-remove',
  FirstProject = 'first-project',
  FirstCollaborator = 'first-collaborator',
  FollowRequest = 'follow',
  FollowingUserIsHostingWorkshop = 'following-user-is-hosting-workshop',
  FollowingUserProjectCreated = 'following-user-project-created',
  FollowingUserProjectJoined = 'following-user-project-joined',
  FollowingUserPortfolioUpdated = 'following-user-portfolio-updated',
  FollowingProjectUpdated = 'following-project-updated',
  ProjectAppreciated = 'project-appreciated',
  ProjectCategoriesUpdated = 'project-categories-updated',
  ProjectDescriptionUpdated = 'project-description-updated',
  ProjectFollowed = 'project-followed',
  ProjectTitleUpdated = 'project-title-updated',
  WaitlistInvite = 'waitlist-invite',
}
