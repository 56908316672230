import * as React from 'react';

import { Link } from '@/components/global/Link/Link';

import { routes } from '@/routes';

import {
  DISCORD_URL,
  HELP_CENTRE_URL,
  PRIVACY_POLICY_URL,
  TERMS_URL,
} from '@/utils/constants';
import { enableCareersPage } from '@/utils/featureToggles';
import { t } from '@/utils/i18n/i18n';

const SiteFooter = () => (
  <footer
    className="site-footer wrap u-2/3@m pv44 u-flex@s u-flex-column-reverse@s"
    role="contentinfo"
  >
    <section className="mb32@m mt12@s u-text-center@s">
      <p className="u-bold u-very-light-grey">
        &copy; {new Date().getFullYear()} {t('Daisie Limited')}
      </p>
    </section>

    <section className="site-footer__menu u-flex u-justify-between u-align-center@s u-flex-column@s">
      <ul className="u-grey">
        <li>
          <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">
            {t('Terms of Use')}
          </a>
        </li>
        <li>
          <a
            href={PRIVACY_POLICY_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Privacy Policy')}
          </a>
        </li>
        <li>
          <a href={HELP_CENTRE_URL} target="_blank" rel="noopener noreferrer">
            {t('Help Centre')}
          </a>
        </li>
      </ul>
      <ul className="u-grey">
        {/* 
        <li>
          <Link to={routes.about}>{t('About')}</Link>
        </li> 
        */}

        <li>
          <a href="https://daisieapp.typeform.com/to/yeaQO7">
            {t('Partnerships')}
          </a>
        </li>
        <li>
          <a href="https://blog.daisie.com">{t('Blog')}</a>
        </li>
        {enableCareersPage() && (
          <li>
            <Link to={routes.careers}>{t('Careers')}</Link>
          </li>
        )}
      </ul>
      <ul className="u-link-very-light-grey u-flex u-flex-column@m u-align-center@s u-align-flex-start">
        <li>
          <a href={DISCORD_URL}>{t('Discord')}</a>
        </li>
        <li>
          <a href="https://www.instagram.com/daisieapp/">{t('Instagram')}</a>
        </li>
        <li>
          <a href="https://www.tiktok.com/@daisieapp">{t('TikTok')}</a>
        </li>
        {/* 
        <li>
          <a href="https://twitter.com/daisie">{t('Twitter')}</a>
        </li>
        <li>
          <a href="https://www.facebook.com/daisieapp/">{t('Facebook')}</a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UClWp9PFiSBh9h0nnkAM0MqA">
            {t('YouTube')}
          </a>
        </li> 
        */}
      </ul>
    </section>
  </footer>
);

export { SiteFooter };
