import * as React from 'react';

import { Icon } from '@/components/global/Icon/Icon';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';

interface Props {
  isMobile?: boolean;
  workshop: Workshop;
  theme?: string;
}

const WorkshopOutcomes = ({ isMobile, workshop, theme = 'purple' }: Props) => {
  const outcomeImages: any = workshop.attributes.outcomeImages;

  return (
    <section className="c-workshop-outcomes c-workshop-outcomes relative mb128 mh24@s mv48@s">
      <div className="c-workshop-outcomes__left relative pr64@s">
        <div className="c-workshop-outcomes__img-container relative">
          <ImageLoader
            src={outcomeImages[0].path}
            className="fill u-object-cover"
          />
        </div>
        {!isMobile && (
          <div
            className={`c-workshop-outcomes__label c-workshop-outcomes__label--theme-${theme} wrap mt16`}
          >
            <Icon id="play-triangle" size="xs" />
            <span className="u-bold f-text-4 u-uppercase mh8">
              {workshop.whenLabel.short}
            </span>
          </div>
        )}
      </div>
      <div className="c-workshop-outcomes__right relative pl64@s pt20@s pt200@m">
        <div className="c-workshop-outcomes__img-container relative">
          <ImageLoader
            src={outcomeImages[1].path}
            className="fill u-object-cover"
          />
        </div>
        {workshop.mentor.url && !isMobile && (
          <div
            className={`c-workshop-outcomes__label c-workshop-outcomes__label--theme-${theme} c-workshop-outcomes__label--link absolute absolute--tr wrap`}
          >
            <Icon id="play-triangle" size="xs" />
            <a
              href={`https://${workshop.mentor.url}`}
              target="_blank"
              rel="noopener noreferrer"
              className="u-underline"
            >
              <span className="u-bold f-text-4 u-uppercase mh8">
                Show more work
              </span>
            </a>
          </div>
        )}
        {!isMobile && (
          <div
            className={`c-workshop-outcomes__label c-workshop-outcomes__label--theme-${theme} c-workshop-outcomes__label--name absolute`}
          >
            <Icon id="play-triangle" size="xs" />
            <span className="u-bold f-text-4 u-uppercase mh8">
              {workshop.mentor.name}
            </span>
          </div>
        )}
      </div>
    </section>
  );
};

export default WorkshopOutcomes;
