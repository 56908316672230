import { Currency } from '@/enums/Currency';

const getSymbolFromCurrencyCodeStart = (currencyCode: string) => {
  switch (currencyCode.toLowerCase()) {
    case Currency.gbp:
      return '£';
    case Currency.eur:
      return '';
    case Currency.brl:
      return 'R$';
    case Currency.zar:
      return 'R';
    case Currency.inr:
      return '₹';
    case Currency.cad:
    case Currency.mxn:
      return '$';
    default:
      return '$';
  }
};

const getSymbolFromCurrencyCodeEnd = (currencyCode: string) => {
  switch (currencyCode.toLowerCase()) {
    case Currency.eur:
      return ' €';
    case Currency.cad:
      return ' CAD';
    case Currency.mxn:
      return ' MXN';
    default:
      return '';
  }
};

const getMultiplierFromCurrencyCode = (currencyCode: string) => {
  switch (currencyCode.toLowerCase()) {
    case Currency.gbp:
    case Currency.usd:
    case Currency.brl:
    case Currency.inr:
    case Currency.cad:
    case Currency.zar:
    case Currency.mxn:
    case Currency.eur:
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      return 0.01;
    default:
      return 1;
  }
};

const formatCurrencyAmount = (amount: number, currencyCode: string) => {
  const parsedAmount = amount.toFixed(amount % 1 === 0 ? 0 : 2);

  switch (currencyCode.toLowerCase()) {
    case 'eur':
      return String(parsedAmount).replace(/\./g, ',');
    default:
      return parsedAmount;
  }
};

const getDisplayPrice = (currencyCode: string, amount: number) =>
  `${getSymbolFromCurrencyCodeStart(currencyCode)}${formatCurrencyAmount(
    getMultiplierFromCurrencyCode(currencyCode) * amount,
    currencyCode
  )}${getSymbolFromCurrencyCodeEnd(currencyCode)}`;

const getPercentOff = (userCurrency: Currency) => {
  switch (userCurrency) {
    case Currency.usd:
      return '65';
    case Currency.gbp:
      return '40';
    case Currency.brl:
      return '61';
    case Currency.cad:
      return '23';
    case Currency.mxn:
      return '44';
    case Currency.zar:
      return '62';
    case Currency.inr:
      return '74';
    default:
      return '40';
  }
};

const getPreviousPricing = (userCurrency: Currency) => {
  switch (userCurrency) {
    case Currency.gbp:
      return '£59.99';
    case Currency.eur:
      return '59.99 €';
    case Currency.brl:
      return 'R$215.80';
    case Currency.cad:
      return '$51.93 CAD';
    case Currency.mxn:
      return '$822.99 MXN';
    case Currency.zar:
      return 'R668.54';
    case Currency.inr:
      return '₹3,165.31';
    default:
      return '$99.99';
  }
};

export { getDisplayPrice, getPercentOff, getPreviousPricing };
