import * as React from 'react';

import { Icon } from '@/components/global/Icon/Icon';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { Link } from '@/components/global/Link/Link';

import { Avatar } from '@/components/design_system/Avatar/Avatar';

import { IconSize } from '@/enums/IconSize';

import { parser } from '@/routes/parser';

import { formatSeconds } from '@/utils/media/formatSeconds';
import { c } from '@/utils/strings/c';

interface Props {
  className?: string;
  workshop: Workshop;
  onClick?: (e: any) => void;
  linkOverride?: string;
  isOriginal?: boolean;
}

interface State {}

class ClassCard extends React.Component<Props, State> {
  public render = () => {
    const {
      className = '',
      workshop,
      onClick,
      linkOverride,
      isOriginal,
    } = this.props;
    const {
      slug,
      coverMedia,
      title,
      mentor,
      description,
      onDemandMediaCatchupDuration,
    } = workshop;

    return (
      <Link
        to={
          !!linkOverride
            ? linkOverride
            : `${parser({
                name: 'workshop',
                params: { workshopSlug: slug },
              })}`
        }
        className={c(['c-card', className])}
        onClick={onClick}
      >
        <div className="c-card__cover u-bg-sad-grey mb16 relative">
          {workshop?.isFree && (
            <p
              style={{
                padding: '4px 12px',
                fontSize: 12,
                color: '#fff',
                backgroundColor: '#24BF50',
                borderRadius: 8,
                position: 'absolute',
                top: 24,
                left: 24,
                zIndex: 9,
                transition: '0.3s',
              }}
            >
              free workshop
            </p>
          )}
          <ImageLoader
            src={coverMedia}
            alt=""
            className="u-object-cover fill"
            convertToWebp={true}
          />

          <div className="c-card__meta fill">
            {!isOriginal && (
              <Icon
                id="play"
                size={IconSize.xxl}
                className="absolute absolute--mid-center"
              />
            )}

            {!!onDemandMediaCatchupDuration && !isOriginal && (
              <p className="f-text-3 u-bold absolute absolute--br mr24 mb24">
                {formatSeconds(onDemandMediaCatchupDuration)}
              </p>
            )}
          </div>
        </div>

        <h2 className="f-text-1 u-bold mb8">{title}</h2>

        <p
          className="f-text-2 u-grey mb12 u-truncate u-truncate--2l u-overflow-hidden"
          style={{ height: 40 }}
        >
          {description}
        </p>

        <Link
          to={parser({ name: 'user', params: { username: mentor.username } })}
          className="u-flex u-align-center u-link-white--alt"
        >
          <Avatar user={mentor} className="mr12" />

          <p className="f-text-3 u-bold">
            {mentor.name !== '' ? mentor.name : mentor.username}
          </p>
        </Link>
      </Link>
    );
  };
}

export { ClassCard };
