import * as React from 'react';

import { connect } from 'unistore/react';

import { Icon } from '@/components/global/Icon/Icon';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { SkeletonNew } from '@/components/global/Skeleton/SkeletonNew';

import { IconSize } from '@/enums/IconSize';

import imgShowAll from '@/pages/Calendar/show-all.png';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props extends CategoryState {
  isLoading?: boolean;
  selectedCategory?: Category;
  onCategorySelect: (category?: Category) => void;
  showAllButton: boolean;
  categoriesToDisplay?: Category[];
}

class CalendarCategorySelectorComp extends React.Component<Props> {
  private classNameButton: string =
    'c-calendar__category_selector__item u-flex u-align-center';

  private classNameImage: string =
    'c-calendar__category_selector__item__image mr16 u-object-cover';

  private classNameText: string = 'u-white f-text-2';

  public render = () => {
    const {
      organisedCategories: { industries: rawIndustries },
      selectedCategory,
      isLoading,
      showAllButton,
      categoriesToDisplay,
    } = this.props;

    const industries: Category[] = !!categoriesToDisplay
      ? categoriesToDisplay
      : rawIndustries;

    if (industries.length === 0 || isLoading) {
      return (
        <div className="c-calendar__category_selector u-white">
          {Array.from(Array(9).keys()).map((_, key: number) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={key}
              className={c([
                this.classNameButton,
                'pointer-events-none opacity-1--i',
              ])}
            >
              <div
                className={c([
                  this.classNameImage,
                  'c-calendar__category_selector__item__image--skeleton',
                ])}
              />

              <SkeletonNew
                elementClassName={this.classNameText}
                fillClassName="u-bg-almost-black"
                width="40%"
              />
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className="c-calendar__category_selector u-white">
        {showAllButton && (
          <button
            type="button"
            className={c([this.classNameButton], {
              'c-calendar__category_selector__item--selected':
                !selectedCategory,
            })}
            onClick={() => this.props.onCategorySelect(undefined)}
          >
            <ImageLoader
              src={imgShowAll}
              alt=""
              className={this.classNameImage}
            />
            <span className={this.classNameText}>{t('Show all')}</span>
          </button>
        )}

        {industries.map((industry: Category) => (
          <button
            key={industry.id}
            type="button"
            className={c([this.classNameButton], {
              'c-calendar__category_selector__item--selected':
                selectedCategory && selectedCategory.id === industry.id,
            })}
            onClick={() => this.props.onCategorySelect(industry)}
          >
            <div className="c-calendar__category_selector__item__image">
              <Icon id={`category-${industry.slug}`} size={IconSize.s} />
            </div>

            <span className="c-calendar__category_selector__item__name u-bold u-hide@s">
              {industry.name}
            </span>
          </button>
        ))}
      </div>
    );
  };
}

export const CalendarCategorySelector = connect(
  ['organisedCategories'],
  () => ({})
)(CalendarCategorySelectorComp);
