import * as React from 'react';

import { Redirect } from 'react-router-dom';
import { connect } from 'unistore/react';

import { Head } from '@/components/global/Head/Head';
import { Icon } from '@/components/global/Icon/Icon';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { LoadingSymbol } from '@/components/global/LoadingSymbol/LoadingSymbol';
import { Toast } from '@/components/global/Toaster/Toast';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { GlobalModal } from '@/enums/GlobalModal';
import { GlobalPortal } from '@/enums/GlobalPortal';
import { IconSize } from '@/enums/IconSize';
import { UserFlairType } from '@/enums/UserFlairType';

import { INSTRUCTOR_DASHBOARD_LOAD_FAIL } from '@/messages/errors';

import {
  INSTRUCTOR_DASHBOARD_HELP_EMAIL_ADDRESS,
  INSTRUCTOR_DASHBOARD_HELP_EMAIL_SUBJECT,
  INSTRUCTOR_DASHBOARD_HELP_PAYMENT_INQUIRIES_EMAIL_ADDRESS,
  INSTRUCTOR_DASHBOARD_HELP_PAYMENT_INQUIRIES_EMAIL_SUBJECT,
  PAYOUT_CURRENCY,
} from '@/pages/InstructorDashboard/constants';
import { getWorkshopStatsFromData } from '@/pages/InstructorDashboard/helpers';

import { routes } from '@/routes';
import { parser } from '@/routes/parser';

import { getUsersFromData, getWorkshopFromData } from '@/store/helpers';
import { modalActions } from '@/store/modules/modal';
import { portalActions } from '@/store/modules/portal';

import { Http } from '@/utils/api/Http';
import { MENTOR_TRACKING_RELEASE_DATE, MINUTES } from '@/utils/constants';
import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';
import { getDisplayPrice } from '@/utils/strings/currency';
import { WorkshopEventType } from '@/enums/WorkshopEventType';

interface Props
  extends AuthState,
    SizingState,
    ModalActions,
    ModalState,
    PortalActions,
    PortalState {}

interface AttendeeData {
  workshopUuid: string;
  attendees: User[];
}

interface State {
  redirectToHome: boolean;
  isLoading: boolean;
  workshops: WorkshopStats[];
  upcomingWorkshops: WorkshopStats[];
  elapsedWorkshops: WorkshopStats[];
  expandedWorkshopIds: string[];
  fullWorkshopData: Workshop[];
  totalEarnings: number;
  attendeeData: AttendeeData[];
  customUrls: APIRedirect[];
}

class InstructorDashboardComp extends React.Component<Props, State> {
  public state: State = {
    redirectToHome: false,
    isLoading: true,
    workshops: [],
    upcomingWorkshops: [],
    elapsedWorkshops: [],
    expandedWorkshopIds: [],
    fullWorkshopData: [],
    totalEarnings: 0,
    attendeeData: [],
    customUrls: [],
  };

  // CONFIG
  private CONFIG_EXPAND_SECTIONS_ON_LOAD: boolean = true;

  public componentDidMount = async () => {
    const {
      auth: { user },
    } = this.props;

    if (!user) {
      return;
    }

    const showMentorDashboard: boolean = user.flair === UserFlairType.mentor;

    if (!showMentorDashboard) {
      this.setState({ redirectToHome: true });
      return;
    }

    try {
      const { data } = await new Http(
        `/workshops/stats/users/${user.username}?pageSize=200&pageNumber=1`
      ).get<APIArray<APIWorkshopStats>>();

      const { data: customUrls } = await new Http('/redirect').get<
        APIObject<APIRedirect[]>
      >();

      this.setState({ customUrls });

      const workshops = getWorkshopStatsFromData(data);

      // TODO: some data about the workshop we need isn't available on the `GET /workshops/stats/users/:username` endpoint,
      // so get the full workshop in a separate request for now
      const workshopIds = workshops.map((w: WorkshopStats) => w.id);

      const fullWorkshopDataPromises = workshopIds.map(async (id: string) => {
        try {
          const { data } = await new Http(`/workshops/${id}`).get<
            APIObject<APIWorkshop>
          >();

          return getWorkshopFromData(data);
        } catch (e) {
          throw new Error();
        }
      });

      let fullWorkshopData = await Promise.all(fullWorkshopDataPromises);

      // Get attendee data
      const attendeeDataPromises: Array<Promise<AttendeeData>> =
        fullWorkshopData.map(async (w: Workshop) => {
          // Assume the first batch has the info we want
          const batchId: string | undefined =
            w.batches && w.batches[0] ? w.batches[0].id : undefined;

          if (!batchId) {
            throw new Error();
          }

          try {
            const { data } = await new Http(
              `/workshopBatch/${batchId}/attendees`
            ).get<APIObject<APIUser[]>>();

            return {
              workshopUuid: w.id,
              attendees: getUsersFromData(data),
            };
          } catch (e) {
            throw e;
          }
        });

      // Remove originals from the workshop list
      fullWorkshopData = fullWorkshopData.filter(
        (w) => w.eventType !== WorkshopEventType.original
      );

      const attendeeData: AttendeeData[] = await Promise.all(
        attendeeDataPromises
      );

      // Sort the workshops into upcoming and elapsed
      const now: Date = new Date();

      const upcomingWorkshops: WorkshopStats[] = workshops.filter(
        (ws: WorkshopStats) => {
          const foundFullWorkshopData = fullWorkshopData.find(
            (w: Workshop) => w.id === ws.id
          );

          if (!foundFullWorkshopData) {
            return false;
          }

          const { end } = foundFullWorkshopData;

          return now.getTime() < new Date(end).getTime();
        }
      );

      const elapsedWorkshops: WorkshopStats[] = workshops.filter(
        (ws: WorkshopStats) => {
          const foundFullWorkshopData = fullWorkshopData.find(
            (w: Workshop) => w.id === ws.id
          );

          if (!foundFullWorkshopData) {
            return false;
          }

          const { end } = foundFullWorkshopData;

          return now.getTime() >= new Date(end).getTime();
        }
      );

      this.setState({
        workshops,
        upcomingWorkshops,
        elapsedWorkshops,
        fullWorkshopData,
        attendeeData,
        totalEarnings: this.calculateTotalEarnings({
          workshops,
          fullWorkshopData,
        }),
        isLoading: false,
        expandedWorkshopIds: this.CONFIG_EXPAND_SECTIONS_ON_LOAD
          ? workshops.map((ws: WorkshopStats) => ws.id)
          : [],
      });
    } catch (e) {
      new Toast({
        body: INSTRUCTOR_DASHBOARD_LOAD_FAIL,
        failure: true,
      }).dispatch();
    }
  };

  private calculateTotalEarnings = ({
    workshops,
    fullWorkshopData,
  }: {
    workshops: WorkshopStats[];
    fullWorkshopData: Workshop[];
  }): number =>
    workshops
      .filter((ws: WorkshopStats) => {
        const matchedWorkshop = fullWorkshopData.find(
          (fullWorkshop: Workshop) => {
            fullWorkshop.id === ws.id;
          }
        );

        if (matchedWorkshop) {
          const mentorTrackingReleaseDate: Date = new Date(
            MENTOR_TRACKING_RELEASE_DATE
          );

          // Get the 1st part of the ISO string
          // and create a date out of it so it doesn't depend on browser's timezone
          const createdAtIsoString: string =
            matchedWorkshop.createdAt.split('.')[0];
          const workshopCreatedAtDate: Date = new Date(createdAtIsoString);

          return workshopCreatedAtDate > mentorTrackingReleaseDate;
        }

        return false;
      })
      .map((ws: WorkshopStats) => ws.mentorEarnings)
      .reduce((total, num) => total + num, 0);

  private toggleSection = (workshopId: string) => {
    const { expandedWorkshopIds } = this.state;

    if (expandedWorkshopIds.includes(workshopId)) {
      // hide
      this.setState({
        expandedWorkshopIds: expandedWorkshopIds.filter(
          (id: string) => id !== workshopId
        ),
      });
    } else {
      // show
      this.setState({
        expandedWorkshopIds: [...expandedWorkshopIds, workshopId],
      });
    }
  };

  private renderBreakdownBlock = ({
    title,
    value,
    onClick,
  }: {
    title: string;
    value: number;
    onClick?: () => void;
  }) => (
    <button
      type="button"
      className="c-instructor-dashboard__block u-border-radius--s p16 mr16 u-text-left"
      onClick={onClick}
    >
      <p className="f-text-3 u-grey u-bold mb4">{title}</p>
      <p className="f-title-2 u-white">{value}</p>
    </button>
  );

  private renderWorkshop = (workshop: WorkshopStats, key: number) => {
    const {
      auth: { user },
      sizing: { isMobile },
    } = this.props;

    const { expandedWorkshopIds, workshops, fullWorkshopData, attendeeData } =
      this.state;

    const { id, title, coverMediaUrl, slug } = workshop;

    const fullWorkshopDataItem: Workshop | undefined = fullWorkshopData.find(
      (w: Workshop) => w.id === workshop.id
    );

    if (!fullWorkshopDataItem || !user) {
      return null;
    }

    // Workshop page visits
    const workshopPageVisits: number = workshop.viewsCount;

    // Attendees
    const workshopAttendees: number = workshop.attendeeCount;

    // Referrals
    const workshopReferralCount: number = workshop.referralCount;

    // Flat fee
    const workshopFlatFee: number = workshop.flatFee / 100;

    const mentorTrackingReleaseDate: Date = new Date(
      MENTOR_TRACKING_RELEASE_DATE
    );

    // Get the 1st part of the ISO string
    // and create a date out of it so it doesn't depend on browser's timezone
    const createdAtIsoString: string =
      fullWorkshopDataItem.createdAt.split('.')[0];
    const workshopCreatedAtDate: Date = new Date(createdAtIsoString);

    const isExpanded: boolean = expandedWorkshopIds.includes(id);
    const isExpandable: boolean =
      workshopCreatedAtDate > mentorTrackingReleaseDate;

    // Get sessionId for Zoom join now link
    const sessionId: string | undefined =
      fullWorkshopDataItem.batches &&
      fullWorkshopDataItem.batches[0] &&
      fullWorkshopDataItem.batches[0].sessions &&
      fullWorkshopDataItem.batches[0].sessions[0]
        ? fullWorkshopDataItem.batches[0].sessions[0].id
        : undefined;

    const now = new Date();

    const startTime = new Date(fullWorkshopDataItem.start);
    const endTime = new Date(fullWorkshopDataItem.end);

    const showJoinNowLink: boolean =
      !!sessionId &&
      // Give a 5 min buffer before the start so instructors can join in advance
      now.getTime() >= startTime.getTime() - 5 * MINUTES &&
      now.getTime() < endTime.getTime();

    return (
      <div
        className={c('u-bg-charcoal-grey u-border-radius--s', {
          mb32: key !== workshops.length - 1,
        })}
      >
        <button
          type="button"
          className="p24 u-flex u-1/1"
          onClick={() => this.toggleSection(id)}
        >
          {!!coverMediaUrl && (
            <ImageLoader
              src={coverMediaUrl}
              className="c-instructor-dashboard__image u-object-cover u-border-radius--s mr24 u-hide@s"
            />
          )}

          <div className="u-text-left mvauto u-flex-1">
            <p className="f-text-2 u-bold mb4">{title}</p>
            <p className="f-text-3 u-grey">
              {fullWorkshopDataItem.whenLabel.long}
            </p>
          </div>

          {workshopCreatedAtDate > mentorTrackingReleaseDate && (
            <p className="u-white f-title-2 mvauto">
              {getDisplayPrice(PAYOUT_CURRENCY, workshop.mentorEarnings)}
            </p>
          )}

          {isExpandable && (
            <Icon
              id={isExpanded ? 'chevron-up' : 'chevron-down'}
              size={IconSize.xs}
              className="ml16 mvauto"
            />
          )}
        </button>

        {isExpanded && isExpandable && (
          <div className="c-instructor-dashboard__breakdown ph24 pb24">
            <div className="u-flex@m c-instructor-dashboard__breakdown-wrapper">
              {this.renderBreakdownBlock({
                title: t('Workshop views'),
                value: workshopPageVisits,
              })}

              {this.renderBreakdownBlock({
                title: t('Registrants'),
                // Subtract 2 to exclude mentor and daisieworkshops from this count
                value: workshopAttendees - 2,
                onClick: () => {
                  const attendeeDataForThisWorkshop = attendeeData.find(
                    (ad: AttendeeData) => ad.workshopUuid === workshop.id
                  );

                  if (!attendeeDataForThisWorkshop) {
                    return;
                  }

                  if (isMobile) {
                    this.props.updatePortal({
                      portal: GlobalPortal.workshop_attendees,
                      data: {
                        attendees: attendeeDataForThisWorkshop.attendees,
                      },
                    });
                  } else {
                    this.props.updateModal({
                      modal: GlobalModal.workshop_attendees,
                      data: {
                        title: t('Registrants'),
                        attendees: attendeeDataForThisWorkshop.attendees,
                      },
                      className: 'page-modal__content--workshop-attendee-list',
                    });
                  }
                },
              })}
              {workshopCreatedAtDate > mentorTrackingReleaseDate &&
                this.renderBreakdownBlock({
                  title: t('Referrals'),
                  value: workshopReferralCount,
                })}

              {this.renderBreakdownBlock({
                title: t('Flat fee'),
                value: workshopFlatFee,
              })}
            </div>

            <div className="u-flex u-flex-column@s mt32">
              {showJoinNowLink ? (
                <Button
                  type={ButtonType.link}
                  linkTo={parser({
                    name: 'workshopJoinNow',
                    params: {
                      workshopSlug: workshop.slug,
                      sessionId: sessionId
                        ? sessionId.replace('workshopSession_', '')
                        : '',
                    },
                  })}
                  buttonStyle={ButtonStyle.default}
                  size={ButtonSize.s}
                  text={t('Join on Zoom')}
                  newWindow={true}
                  className="mr16@m mb12@s"
                  iconSide="left"
                  iconId="zoom"
                />
              ) : (
                <Button
                  type={ButtonType.link}
                  linkTo={parser({
                    name: 'workshop',
                    params: { workshopSlug: workshop.slug },
                  })}
                  buttonStyle={ButtonStyle.outlineLight}
                  size={ButtonSize.s}
                  text={t('Go to workshop')}
                  newWindow={true}
                  className="mr16@m mb12@s"
                />
              )}

              <Button
                type={ButtonType.action}
                onClick={() => {
                  const customUrl = this.state.customUrls.find(
                    (customUrl: APIRedirect) =>
                      customUrl.attributes.destination ===
                      `/workshops/${slug}?utm_source=mentor`
                  );
                  const linkToShare: string = customUrl
                    ? `${window.location.origin}${customUrl.attributes.origin}`
                    : `${window.location.origin}/workshops/${slug}?utm_source=mentor`;

                  if (isMobile) {
                    this.props.updatePortal({
                      portal: GlobalPortal.share_link,
                      data: {
                        link: linkToShare,
                      },
                    });
                  } else {
                    this.props.updateModal({
                      modal: GlobalModal.share_link,
                      data: {
                        link: linkToShare,
                      },
                    });
                  }
                }}
                buttonStyle={ButtonStyle.outlineLight}
                size={ButtonSize.s}
                text={t('Share link')}
                className="mr16@m mb12@s"
              />

              <Button
                type={ButtonType.action}
                onClick={() => {
                  if (isMobile) {
                    this.props.updatePortal({
                      portal: GlobalPortal.workshop_instagram_assets,
                      data: {
                        workshop: fullWorkshopDataItem,
                      },
                    });
                  } else {
                    this.props.updateModal({
                      modal: GlobalModal.workshop_instagram_assets,
                      data: {
                        workshop: fullWorkshopDataItem,
                      },
                    });
                  }
                }}
                buttonStyle={ButtonStyle.outlineLight}
                size={ButtonSize.s}
                text={t('Download assets')}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  private renderLinks = ({ alignCentre }: { alignCentre?: boolean }) => (
    <div className={c('mv64 u-flex@m', { 'u-justify-center': alignCentre })}>
      <Button
        type={ButtonType.link}
        linkTo={`mailto:${INSTRUCTOR_DASHBOARD_HELP_EMAIL_ADDRESS}?subject=${encodeURIComponent(
          INSTRUCTOR_DASHBOARD_HELP_EMAIL_SUBJECT
        )}`}
        buttonStyle={ButtonStyle.outlineLight}
        size={ButtonSize.s}
        text={t('Get support')}
        className="u-fit-content mb16@s mr16@m"
        iconSide="left"
        iconId="help-filled"
      />

      {/* TODO: Add functionality */}
      {/* <Button
        type={ButtonType.link}
        linkTo={INSTRUCTOR_DASHBOARD_SCHEDULE_NEXT_CLASS_LINK}
        buttonStyle={ButtonStyle.outlineLight}
        size={ButtonSize.s}
        text={t('Schedule next class')}
        className="u-fit-content mb16@s mr16@m"
        iconSide="left"
        iconId="calendar"
      /> */}

      <Button
        type={ButtonType.action}
        onClick={() =>
          this.props.updateModal({
            modal: GlobalModal.refer_an_instructor,
            data: {},
          })
        }
        buttonStyle={ButtonStyle.outlineLight}
        size={ButtonSize.s}
        text={t('Refer an instructor')}
        className="u-fit-content mb16@s mr16@m"
        iconSide="left"
        iconId="collaboration"
      />

      <Button
        type={ButtonType.link}
        linkTo={`mailto:${INSTRUCTOR_DASHBOARD_HELP_PAYMENT_INQUIRIES_EMAIL_ADDRESS}?subject=${encodeURIComponent(
          INSTRUCTOR_DASHBOARD_HELP_PAYMENT_INQUIRIES_EMAIL_SUBJECT
        )}`}
        buttonStyle={ButtonStyle.outlineLight}
        size={ButtonSize.s}
        text={t('Payment Inquiries')}
        className="u-fit-content"
        iconSide="left"
        iconId="send"
      />
    </div>
  );

  private renderContent = () => {
    const { isLoading, workshops, elapsedWorkshops, upcomingWorkshops } =
      this.state;

    if (isLoading) {
      return (
        <div className="u-flex u-justify-center">
          <LoadingSymbol size="l" colour="white" />
        </div>
      );
    }

    if (!isLoading && workshops.length === 0) {
      return (
        <div className="mt64">
          <p className="f-title-2 u-dark-grey u-text-center">
            {t(`You haven't run a workshop yet`)}
          </p>

          {this.renderLinks({ alignCentre: true })}
        </div>
      );
    }

    const classNameSubheading: string = 'f-text-2 u-bold mt32@m mt64@m mb24';

    return (
      <>
        {upcomingWorkshops.length > 0 && (
          <>
            <p className={c([classNameSubheading])}>{t('Upcoming')}</p>

            {upcomingWorkshops.map((w: WorkshopStats, key: number) =>
              this.renderWorkshop(w, key)
            )}
          </>
        )}

        {elapsedWorkshops.length > 0 && (
          <>
            <p className={c([classNameSubheading])}>{t('Elapsed')}</p>

            {elapsedWorkshops.map((w: WorkshopStats, key: number) =>
              this.renderWorkshop(w, key)
            )}
          </>
        )}

        {this.renderLinks({})}
      </>
    );
  };

  public render = () => {
    const { redirectToHome, totalEarnings, isLoading } = this.state;
    const {
      auth: { user },
    } = this.props;

    if (redirectToHome) {
      return <Redirect to={routes.home} />;
    }

    if (!user) {
      return null;
    }

    return (
      <div className="wrap u-white mt16 mt44@m">
        <Head title={t('Instructor Dashboard')} />

        <div
          className={c('u-hide@s u-flex mb4 animate-opacity', {
            'opacity-1': !isLoading,
            'opacity-0 pointer-events-none': isLoading,
          })}
        >
          <p className="mlauto f-text-3 u-bold u-grey">{t('Total earnings')}</p>
        </div>

        <div className="mb44 u-flex@m u-split@m">
          <h1 className="f-title-1 u-bold">
            {t('Welcome back,')} {!!user.name ? user.name : user.username}!
          </h1>

          <div
            className={c('u-text-right@m animate-opacity mt16@s', {
              'opacity-1': !isLoading,
              'opacity-0 pointer-events-none': isLoading,
            })}
          >
            <p className="mlauto f-text-3 u-bold u-grey u-hide@m">
              {t('Total earnings')}
            </p>

            <p className="u-go f-title-1 u-bold">
              {getDisplayPrice(PAYOUT_CURRENCY, totalEarnings)}
            </p>
          </div>
        </div>
        {this.renderContent()}
      </div>
    );
  };
}

export const InstructorDashboard = connect(['auth', 'sizing'], () => ({
  ...modalActions(),
  ...portalActions(),
}))(InstructorDashboardComp);
