import React from 'react';

import { Box, Flex, Image, Link } from '@chakra-ui/react';

import {
  ArrowBottomRightIcon,
  ArrowTopRightIcon,
} from '@/components/design_system/AlexJenkinsClass/atoms';

import { AnimateInView } from '@/pages/AlexJenkinsClass/animations/AnimateInView';

import { routes } from '@/routes';

import { colors } from '@/styles/chakra-theme/colors';
import { pxToRem } from '@/styles/chakra-theme/metrics';

import { isMobile } from '@/utils/DOM/sizing';

export const Footer = ({ ...props }) => {
  const menuItems = [
    { label: 'Outcomes', href: '#outcomes' },
    { label: 'Overview', href: '#overview' },
    { label: 'Reviews', href: '#reviews' },
    { label: 'FAQ', href: '#faq' },
    {
      label: 'Enroll',
      href: routes.optimisedJoinFlowChoosePlan,
    },
  ];

  const linksFooter = [
    {
      label: 'Privacy Policy',
      url: 'https://www.notion.so/daisie/Daisie-Privacy-Policy-9e619d0f68264c06a2db249501a32668',
    },
    {
      label: 'Terms of Use',
      url: 'https://www.notion.so/daisie/Daisie-Terms-of-Use-3d51f1389eae4743b40fa81fa3a6a68e',
    },
    {
      label: 'Help Centre',
      url: 'https://www.notion.so/Help-Center-42279b7752c24438bcb360c7ddf52d83',
    },
  ];

  const socialFooter = [
    { iconName: 'discord', url: 'https://discord.gg/daisie' },
    { iconName: 'instagram', url: 'https://www.instagram.com/daisieapp/' },
    { iconName: 'tiktok', url: 'https://www.tiktok.com/@daisieapp' },
    { iconName: 'twitter', url: 'https://twitter.com/daisie' },
    {
      iconName: 'youtube',
      url: 'https://www.youtube.com/channel/UClWp9PFiSBh9h0nnkAM0MqA',
    },
  ];

  const socialFooterMobile = [
    { iconName: 'discord', href: '' },
    { iconName: 'instagram', href: '' },
    { iconName: 'twitter', href: '' },
  ];

  if (isMobile()) {
    return (
      <Box
        w="100%"
        px={pxToRem(16)}
        pt={pxToRem(40)}
        bg={colors.purpleRain}
        className="footer"
      >
        <Flex h="50%" w="100%">
          <Box
            bg="rgba(255, 255, 255, 0.15)"
            backdropFilter="blur(20px)"
            px={pxToRem(20)}
            h="fit-content"
          >
            {menuItems.map((item) => {
              const isEnroll = item.label === 'Enroll';

              return (
                <Link
                  key={item.label}
                  display="block"
                  minW={160}
                  fontSize={18}
                  bg={isEnroll ? 'white' : 'transparent'}
                  color={isEnroll ? 'greyDark' : 'white'}
                  fontWeight={400}
                  px={pxToRem(isEnroll ? 16 : 0)}
                  mt={pxToRem(isEnroll ? 8 : 0)}
                  py={pxToRem(isEnroll ? 0 : 16)}
                  cursor="pointer"
                  _hover={{ borderBottomWidth: 1, mb: pxToRem(-1) }}
                  href={item.href}
                >
                  {item.label}
                  {isEnroll && (
                    <ArrowTopRightIcon
                      ml={pxToRem(16)}
                      w={pxToRem(18)}
                      h={pxToRem(18)}
                    />
                  )}
                </Link>
              );
            })}
          </Box>

          <Flex
            flexDir="column"
            h="100%"
            w="100%"
            justify="space-between"
            align="flex-end"
          >
            <AnimateInView name="rubberBand">
              <Flex
                justify="center"
                align="center"
                bg="#60D273"
                mb={pxToRem(120)}
                px={pxToRem(16)}
                py={pxToRem(28)}
                borderRadius={60}
                cursor="pointer"
                transition="transform 0.5s"
                transform="rotate(-10deg)"
                _hover={{ transform: 'scale(1.2)' }}
                onClick={() =>
                  scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  })
                }
              >
                <ArrowBottomRightIcon
                  fontSize={22}
                  transform="rotate(-135deg)"
                />
              </Flex>
            </AnimateInView>
          </Flex>
        </Flex>

        <Flex justify="space-between" align="center" mt={pxToRem(64)}>
          {socialFooterMobile.map((link) => (
            <Link
              key={link.iconName}
              display="block"
              fontSize={18}
              color="white"
              fontWeight={400}
              py={pxToRem(8)}
              _hover={{ borderBottomWidth: 1, mb: pxToRem(-1) }}
              cursor="pointer"
              href={link.href}
              target="_blank"
            >
              <Image
                alt={`${link.iconName} logo`}
                src={`/AlexJenkinsClass/img/icons/${link.iconName}.svg`}
                w={22}
              />
            </Link>
          ))}
        </Flex>

        <Flex
          gap={pxToRem(24)}
          align="center"
          justify="center"
          mt={pxToRem(30)}
        >
          {linksFooter.map((link) => (
            <Link
              key={link.label}
              isExternal
              display="block"
              fontSize={16}
              color="white"
              fontWeight={400}
              py={pxToRem(8)}
              _hover={{ borderBottomWidth: 1, mb: pxToRem(-1) }}
              cursor="pointer"
              href={link.url}
              target="_blank"
              opacity={0.5}
            >
              {link.label}
            </Link>
          ))}
        </Flex>
      </Box>
    );
  }

  return (
    <Box
      bg="linear-gradient(90deg, #8543E3 0%, #633BEB 100%)"
      px={pxToRem(46)}
      py={pxToRem(22)}
      {...props}
    >
      <Flex wrap="wrap" justify="space-between" align="center">
        <Box
          bg="rgba(255, 255, 255, 0.15)"
          backdropFilter="blur(20px)"
          p={pxToRem(20)}
        >
          {menuItems.map((item) => {
            const isEnroll = item.label === 'Enroll';

            return (
              <Link
                key={item.label}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                minW={160}
                fontSize={18}
                bg={isEnroll ? 'white' : 'transparent'}
                color={isEnroll ? 'greyDark' : 'white'}
                fontWeight={400}
                px={pxToRem(isEnroll ? 16 : 0)}
                mt={pxToRem(isEnroll ? 8 : 0)}
                py={pxToRem(isEnroll ? 8 : 16)}
                cursor="pointer"
                _hover={{ borderBottomWidth: 1, mb: pxToRem(-1) }}
                href={item.href}
              >
                {item.label}
                {isEnroll && (
                  <ArrowTopRightIcon
                    ml={pxToRem(16)}
                    mt={pxToRem(8)}
                    fontSize={24}
                  />
                )}
              </Link>
            );
          })}
        </Box>

        <AnimateInView name="rubberBand">
          <Flex
            justify="center"
            align="center"
            bg="#60D273"
            px={pxToRem(34)}
            py={pxToRem(42)}
            borderRadius={50}
            cursor="pointer"
            transform="rotate(-10deg)"
            transition="transform 0.5s"
            _hover={{ transform: 'scale(1.2)' }}
            onClick={() =>
              scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }
          >
            <ArrowBottomRightIcon fontSize={38} transform="rotate(-135deg)" />
          </Flex>
        </AnimateInView>
      </Flex>

      <Flex wrap="wrap" justify="space-between" align="center" mt={pxToRem(48)}>
        <Flex
          gap={[pxToRem(6), pxToRem(6), pxToRem(40)]}
          direction={['column', 'column', 'row']}
        >
          {linksFooter.map((link) => (
            <Link
              key={link.label}
              isExternal
              display="block"
              fontSize={16}
              color="white"
              fontWeight={400}
              py={pxToRem(8)}
              _hover={{ borderBottomWidth: 1, mb: pxToRem(-1) }}
              cursor="pointer"
              href={link.url}
              target="_blank"
              opacity={0.5}
            >
              {link.label}
            </Link>
          ))}
        </Flex>

        <Flex
          wrap="wrap"
          gap={[pxToRem(24), pxToRem(46), pxToRem(64)]}
          align="center"
        >
          {socialFooter.map((link) => (
            <Link
              key={link.iconName}
              isExternal
              display="block"
              fontSize={18}
              color="white"
              fontWeight={400}
              py={pxToRem(8)}
              _hover={{ borderBottomWidth: 1, mb: pxToRem(-1) }}
              cursor="pointer"
              href={link.url}
              target="_blank"
            >
              <Image
                alt={`${link.iconName} logo`}
                src={`/AlexJenkinsClass/img/icons/${link.iconName}.svg`}
                w={18}
              />
            </Link>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};
