import * as React from 'react';

import {
  Link,
  Redirect,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import { connect } from 'unistore/react';

import { RegistrationForm } from '@/components/accounts/RegistrationForm/RegistrationForm';

import { TrackingLocation } from '@/enums/TrackingLocation';

import { OptimisedJoinFlowFrame } from '@/pages/OptimisedJoinFlow/OptimisedJoinFlowFrame';
import { OptimisedJoinFlowSkipCloseButton } from '@/pages/OptimisedJoinFlow/OptimisedJoinFlowSkipCloseButton';

import { routes } from '@/routes';
import { parser } from '@/routes/parser';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';
import { getRouteFromUrl } from '@/utils/urls/routes';

interface Props
  extends ABTestState,
    AuthState,
    SizingState,
    RouteComponentProps {}

interface State {
  redirectToHome: boolean;
}

class OptimisedJoinFlowSignUpComp extends React.Component<Props, State> {
  public state: State = {
    redirectToHome: false,
  };

  public componentDidMount = () => {
    const {
      auth: { isAuthorised },
    } = this.props;

    if (isAuthorised) {
      this.setState({ redirectToHome: true });
      return;
    }
  };

  public render = () => {
    const {
      location: { pathname, search },
      abtest: { variant },
      auth: { isAuthorised },
      sizing: { isMobile },
    } = this.props;

    const { redirectToHome } = this.state;

    if (redirectToHome) {
      return <Redirect to={routes.home} />;
    }

    const matchedRoute: string = getRouteFromUrl(pathname);

    const optimisedJoinFlowDestination = parser({ name: `${matchedRoute}Pay` });

    const hasABTestDiscount: boolean =
      typeof variant !== 'undefined' &&
      variant === 2 &&
      matchedRoute === 'optimisedJoinFlowSignUpYearly';
    const hasStudentDiscount: boolean = matchedRoute === 'studentsSignUpYearly';

    const queryParams = new URLSearchParams(search);
    const redirectTo = queryParams.get('to') || undefined;
    const isFreeWorkshop = !!queryParams.get('isFree');

    const isUserComingFromWorkshop = window.location.href.includes(
      '?to=%2Fworkshops%2F'
    );

    return (
      <>
        {isAuthorised && (
          <OptimisedJoinFlowSkipCloseButton type="close" linkTo={routes.home} />
        )}

        <OptimisedJoinFlowFrame
          isOptimisedJoinFlowSignUp={isUserComingFromWorkshop}
          isFreeWorkshop={isFreeWorkshop}
        >
          <div
            className={c('u-z-index-1 u-flex u-flex-column u-align-center', {
              'pl128@m pv64@m ': !isUserComingFromWorkshop,
              'pv44@m': isUserComingFromWorkshop,
            })}
          >
            {(!isUserComingFromWorkshop || isFreeWorkshop) && (
              <>
                <h2 className="u-text-center f-yell-1b mt32@s mb16">
                  {t('Sign Up')}
                </h2>
                <p className="u-text-center f-text-2 u-grey mb32 mb64@m">
                  {t('Already have an account? ')}
                  <Link
                    to={routes.login}
                    className="u-link-brand u-link-brand--dark u-underline"
                  >
                    {t('Log in')}
                  </Link>
                </p>
              </>
            )}

            <div>
              <RegistrationForm
                formLocation={TrackingLocation.join}
                textClassName="u-white"
                // emailAlreadyInUseUrlParam={`?to=${encodeURIComponent(to)}`}
                optimisedJoinFlowDestination={
                  isFreeWorkshop && !!redirectTo
                    ? `/workshops/${encodeURIComponent(redirectTo)}`
                    : `${optimisedJoinFlowDestination}${
                        !!redirectTo
                          ? `?to=${encodeURIComponent(redirectTo)}`
                          : ''
                      }`
                }
                disablePhoneNumberToggle={hasStudentDiscount}
                isForStudentDiscount={hasStudentDiscount}
                isForGeneralDiscount={hasABTestDiscount}
                isUpdatedWorkshopSubFlow={isUserComingFromWorkshop && !isMobile}
              />
            </div>
          </div>
        </OptimisedJoinFlowFrame>
      </>
    );
  };
}

export const OptimisedJoinFlowSignUp = connect(
  ['abtest', 'auth', 'sizing'],
  () => ({})
)(withRouter(OptimisedJoinFlowSignUpComp));
