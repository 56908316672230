import * as React from 'react';

import { connect } from 'unistore/react';

import { Icon } from '@/components/global/Icon/Icon';

import { DaisieSubscriptionTier } from '@/enums/DaisieSubscriptionTier';
import { UserFlairType } from '@/enums/UserFlairType';

import { c } from '@/utils/strings/c';

interface Props extends AuthState {
  user: Partial<User>;
  className?: string;
  size?: 's' | 'm';
}

class UserVerifiedComponent extends React.Component<Props> {
  public render = () => {
    const { user, className = '', size = 's' } = this.props;

    let iconSize;
    let iconWidth;
    let iconHeight;

    if (size === 's') {
      iconWidth = '14px';
      iconHeight = '14px';
    } else if (size === 'm') {
      iconSize = 's';
    }

    if (!user) {
      return null;
    }

    const currentUser =
      this.props.auth &&
      this.props.auth.user &&
      this.props.auth.user.id &&
      this.props.auth.user.subscriptionTier
        ? this.props.auth.user
        : undefined;

    const showSubscriberFlair: boolean =
      !!user &&
      !!currentUser &&
      user.id === currentUser.id &&
      currentUser.subscriptionTier === DaisieSubscriptionTier.premium;

    if (!showSubscriberFlair && !!user.flair === false) {
      return null;
    }

    return (
      <div
        className={c(['u-inline-flex', className], {
          ml4: size === 's',
          ml8: size === 'm',
        })}
      >
        <Icon
          id={
            !!user.flair && user.flair === UserFlairType.mentor
              ? 'mentor'
              : !!user.flair &&
                (user.flair === UserFlairType.founding_member ||
                  user.flair === UserFlairType.super_user)
              ? 'founding-member'
              : !!user.flair &&
                (user.flair === UserFlairType.team_daisie ||
                  user.flair === UserFlairType.verified)
              ? 'verified'
              : showSubscriberFlair
              ? 'subscriber'
              : ''
          }
          size={iconSize}
          width={iconWidth}
          height={iconHeight}
        />
      </div>
    );
  };
}

export const UserVerified = connect(['auth'], () => ({}))(
  UserVerifiedComponent
);
