import { c } from '@/utils/strings/c';
import { secondsToTime } from '@/utils/strings/converters';
import React, { FC } from 'react';
import { Icon } from '../global/Icon/Icon';
import OriginalsVideoProgressButton from './OriginalsVideoProgressButton';

interface Props {
  episode: OnDemandMedia;
  episodeNumber: number;
  onEpisodeSelect: (onDemandMediaId: string) => void;
  progress: number;
  isCompleted: boolean;
  isPlaying: boolean;
  isCurrentEpisode: boolean;
}

const OriginalsVideoCard = ({
  progress,
  isCompleted,
  episode,
  episodeNumber,
  onEpisodeSelect,
  isPlaying,
  isCurrentEpisode,
}: Props) => (
  <li
    className="c-originals-video-card"
    style={{ backgroundColor: 'black' }}
    onClick={() => onEpisodeSelect(episode.id)}
  >
    <span className="u-grey u-inline-block mb8 u-bold f-text-3">{`Class ${episodeNumber} • ${secondsToTime(
      episode.duration || 0,
      true
    )}`}</span>
    <div className="u-flex u-white u-align-center">
      <div
        className={c('c-originals-video-card__thumbnail u-1/4', {
          'c-originals-video-card__thumbnail--completed': isCompleted,
        })}
        style={{
          backgroundImage: episode.thumbnail
            ? `url("${episode.thumbnail}")`
            : "url('/thumbnail-placeholder.png')",
        }}
      >
        {isCompleted && (
          <Icon
            id="checkmark"
            size="s"
            className="absolute absolute--mid-center u-z-index-1"
          />
        )}
      </div>
      <span
        className={c(
          'c-originals-video-card__title u-grey f-lynstone-bold-4 u-3/4 mh16',
          {
            'u-white': isCurrentEpisode,
          }
        )}
      >
        {episode.title}
      </span>
      <OriginalsVideoProgressButton progress={progress} isPlaying={isPlaying} />
    </div>
  </li>
);

export default OriginalsVideoCard;
