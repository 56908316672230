import { SubscriptionError } from '@/components/subscription/SubscriptionError';

import { Currency } from '@/enums/Currency';
import { SubscriptionInterval } from '@/enums/SubscriptionInterval';

import { Http } from '@/utils/api/Http';

export const getUserSubscription = (
  subscription?: APIUserSubscription
): UserSubscription | undefined => {
  if (!subscription) {
    return undefined;
  }

  const { attributes } = subscription;

  const {
    subscriptionPaymentType,
    status,
    stripeSubscriptionId,
    appleSubscriptionId,
    grandfatheredSubscription,
    freeTrialEnds,
    stripeSubscription,
  } = attributes;

  const stripeSubscriptionObj = stripeSubscription as APIStripeSubscription;

  return {
    subscriptionPaymentType,
    status,
    stripeSubscriptionId,
    appleSubscriptionId,
    grandfatheredSubscription,
    freeTrialEnds,
    subscriptionAmount: stripeSubscriptionObj?.plan?.amount,
    subscriptionCurrency: stripeSubscriptionObj?.plan?.currency,
    subscriptionInterval: stripeSubscriptionObj?.plan?.interval,
    paymentCardBrand:
      stripeSubscriptionObj?.default_payment_method?.card?.brand,
    paymentCardLastFourDigits:
      stripeSubscriptionObj?.default_payment_method?.card?.last4,
    currentPeriodStart: stripeSubscriptionObj?.current_period_start
      ? new Date(stripeSubscriptionObj.current_period_start * 1000)
      : undefined,
    currentPeriodEnd: stripeSubscriptionObj?.current_period_end
      ? new Date(stripeSubscriptionObj.current_period_end * 1000)
      : undefined,
    trialStart: stripeSubscriptionObj?.trial_start
      ? new Date(stripeSubscriptionObj.trial_start * 1000)
      : undefined,
    trialEnd: stripeSubscriptionObj?.trial_end
      ? new Date(stripeSubscriptionObj.trial_end * 1000)
      : undefined,
    cancelAtPeriodEnd: !!stripeSubscriptionObj
      ? stripeSubscriptionObj.cancel_at_period_end
      : false,
    hasPaymentError: attributes.hasPaymentIssue,
    discount: stripeSubscriptionObj.discount?.coupon.percent_off
      ? {
          percentOff: stripeSubscriptionObj.discount.coupon.percent_off,
          durationInMonths:
            stripeSubscriptionObj.discount.coupon.duration_in_months || 0,
        }
      : undefined,
  };
};

export const getStripeProductsAndPrices = (
  productsAndPrices: APIStripeProductsAndCoupons
): SubscriptionProductPrice[] =>
  productsAndPrices.attributes.prices.map((price: APIStripePrice) => ({
    priceId: price.id,
    productId: price.product.id,
    currency: price.currency,
    amount: price.unit_amount,
    recurringInterval:
      // NOTE: the monthly EUR subscription is current set to renew after
      // a day for testing, but pretend its a monthly subscription so the frontend works
      price.recurring.interval === SubscriptionInterval.day
        ? SubscriptionInterval.month
        : price.recurring.interval,
    nickname: !!price.nickname ? price.nickname : undefined,
    isGrandfathering:
      price.metadata && price.metadata.isGrandfathered === 'true'
        ? true
        : false,
  }));

export const getStripeCouponsFromData = (
  data: APIStripeCoupon[]
): StripeCoupon[] => data.map(getStripeCouponFromData).filter(Boolean);

export const getStripeCouponFromData = (
  data: APIStripeCoupon
): StripeCoupon => {
  const { id, name, duration_in_months, percent_off, valid } = data;

  return {
    id,
    name,
    duration_in_months,
    percent_off,
    valid,
  };
};

export const getUserPromotionalCodes = async () => {
  try {
    const { data } = await new Http('/users/me/promotionalCodes').get();
    return data;
  } catch (e) {
    return [];
  }
};

export const getPaymentIntent = async (): Promise<string> => {
  const { data } = await new Http('/stripe/setupIntents').post();

  return data.attributes.setupIntentClientSecret;
};

export const createStripeSubscription = async ({
  priceId,
  paymentMethodId,
  couponId,
  skipFreeTrial,
  referrerWorkshopId,
  promotionalCode,
}: {
  priceId: string;
  paymentMethodId: string;
  couponId?: string;
  skipFreeTrial?: boolean;
  referrerWorkshopId?: string;
  promotionalCode?: string;
}) => {
  try {
    await new Http(
      `/stripe/subscriptions${!!skipFreeTrial ? '?skipFreeTrial=true' : ''}`
    ).post({
      priceId,
      paymentMethodId,
      couponId,
      referrerWorkshopId,
      promotionalCode,
    });
  } catch (e) {
    throw new Error(SubscriptionError.unable_to_create_subscription);
  }
};

export const findProductAndPrice = ({
  productsAndPrices,
  currency,
  recurringInterval,
}: {
  productsAndPrices?: SubscriptionProductPrice[];
  currency: Currency;
  recurringInterval: SubscriptionInterval;
}): SubscriptionProductPrice | undefined => {
  if (!productsAndPrices) {
    return undefined;
  }

  const results = productsAndPrices
    .filter(
      (productAndPrice: SubscriptionProductPrice) =>
        productAndPrice.currency === currency &&
        productAndPrice.recurringInterval === recurringInterval
    )
    .sort(
      (a: SubscriptionProductPrice, b: SubscriptionProductPrice) =>
        a.amount - b.amount
    );

  if (results.length < 1) {
    return undefined;
  }

  return results[0];
};
