import navigatorLanguages from 'navigator-languages';

import { Language } from '@/enums/Language';

export const getLanguage = (): Language => {
  const languages = navigatorLanguages();

  const hasMainLanguage = languages && languages[0];

  if (!hasMainLanguage) return Language.enGB;

  const mainLanguage = languages[0].toLowerCase();

  switch (mainLanguage) {
    case 'en-us':
      return Language.enUS;
    default:
      return Language.enGB;
  }
};
