const sanitise = (html: string): string =>
  html
    .replace(/<div>/g, '')
    .replace(/<\/div>/g, '')
    .replace(/<em>/g, '')
    .replace(/<\/em>/g, '')
    .replace(/<br>/g, '')
    .replace(/&nbsp;/g, ' ')
    .replace(/&amp;/g, '&');

const htmlToString = (html: string): string => {
  const result: string = html
    .split('</div>')
    .map((el: string) => {
      if (el === '<br>') {
        return '\n\n';
      } else {
        return el
          .replace(/<div>/g, '')
          .replace(/<\/div>/g, '')
          .replace(/<em>/g, '')
          .replace(/<\/em>/g, '')
          .replace(/<br>/g, '')
          .replace(/&nbsp;/g, ' ');
      }
    })
    .join('\n');

  return result.slice(0, -1);
};

const stringToHtml = (str: string): string => {
  const result: string = str
    .split('\n')
    .map((el: string) => {
      if (el === '') {
        return '<div><br></div>';
      } else {
        return `<div>${
          el.slice(-1) === ' ' ? `${el.slice(0, -1)}&nbsp;` : el
        }</div>`;
      }
    })
    .join('');

  // Add a <br> to an empty string to allow the cursor to be clicked
  return htmlToString(result).length === 0 ? '<div><br></div>' : result;
};

const digitToWord = (digit: number): string => {
  if (digit > 10 || digit < 0) {
    throw new Error();
  }

  const digits: string[] = [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
  ];

  return digits[digit];
};

const secondsToTime = (seconds: number, onlyNumbers?: boolean) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const sec = Math.floor((seconds % 3600) % 60);

  if (onlyNumbers) {
    return `${hours > 0 ? hours * 60 + minutes : minutes}:${
      String(sec).length === 1 ? `0${sec}` : sec
    }`;
  }

  return `${hours ? `${hours}' hr ` : ''}${minutes} min`;
};

export { htmlToString, stringToHtml, sanitise, digitToWord, secondsToTime };
