import * as React from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'unistore/react';

import { CalendarCard } from '@/components/global/Cards/CalendarCard';
import { CardSkeleton } from '@/components/global/Cards/CardSkeleton';
import { Head } from '@/components/global/Head/Head';
import { SkeletonNew } from '@/components/global/Skeleton/SkeletonNew';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';
import { Calendar } from '@/components/design_system/Calendar/Calendar';

import { WorkshopEventType } from '@/enums/WorkshopEventType';

import { CalendarCategorySelector } from '@/pages/Calendar/CalendarCategorySelector';
import { FeaturedWorkshop } from '@/pages/Calendar/FeaturedWorkshop';

import { calendarActions } from '@/store/modules/calendar';
import { categoryActions } from '@/store/modules/category';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props
  extends CalendarActions,
    CalendarState,
    CategoryActions,
    CategoryState,
    AuthState,
    SizingState,
    RouteComponentProps<LocationState> {}

interface State {
  isLoadedLocal: boolean;
  selectedDate?: DateMonthYear;
  selectedCategory?: Category;
  paginatedEvents?: PaginatedCalendarEvent[];
  // bottomPaddingHeight: used to add extra padding to the page
  // so the IntersectionObserver fires when scrolling to the final date
  bottomPaddingHeight: number;
  featuredWorkshop?: CalendarEvent;
  hidePastEventsThisMonth: boolean;
}

interface PaginatedCalendarEvent {
  date: Date;
  events: CalendarEvent[];
}

class CalendarPageComp extends React.Component<Props, State> {
  // CONFIG
  private classNameDayRowContainer: string = 'mb64';
  private classNameDayRowTitleContainer: string = 'mb24';
  private classNameDayRowTitleText: string = 'f-title-2 u-bold u-white';

  // REFS
  private refCalendar: React.RefObject<Calendar>;
  private intersectionObserver?: any;

  constructor(props: Props) {
    super(props);

    this.refCalendar = React.createRef();

    this.state = {
      isLoadedLocal: false,
      selectedDate: undefined,
      selectedCategory: undefined,
      paginatedEvents: undefined,
      bottomPaddingHeight: 0,
      hidePastEventsThisMonth: true,
    };
  }

  public componentDidMount = async () => {
    try {
      await this.props.fetchAndSortCategories();
    } catch (e) {
      // TODO
    }

    try {
      const earliestWorkshopDate: Date = new Date();
      earliestWorkshopDate.setHours(0, 0, 0, 0);

      await this.props.getCalendar(earliestWorkshopDate);
    } catch (e) {
      // TODO
    }

    const {
      calendar: { events },
    } = this.props;

    const now = new Date();

    const upcomingEvents = events.filter(
      (ce: CalendarEvent) => ce.start.getTime() >= now.getTime()
    );

    const selectedDateDay = upcomingEvents[0]
      ? upcomingEvents[0].start.getDate()
      : now.getDate();

    const selectedDateMonth = upcomingEvents[0]
      ? upcomingEvents[0].start.getMonth()
      : now.getMonth();

    const selectedDateYear = upcomingEvents[0]
      ? upcomingEvents[0].start.getFullYear()
      : now.getFullYear();

    const nextEventIsNextMonth: boolean = upcomingEvents[0]
      ? upcomingEvents[0].start.getMonth() !== now.getMonth()
      : false;

    this.setState(
      {
        selectedDate: {
          date: selectedDateDay,
          month: selectedDateMonth,
          year: selectedDateYear,
        },
        paginatedEvents: this.paginateEvents(
          selectedDateMonth,
          selectedDateYear,
          this.state.selectedCategory
        ),
        featuredWorkshop: this.getFeaturedWorkshop(),
        isLoadedLocal: true,
      },
      () => {
        this.initialiseIntersectionObservers();

        if (nextEventIsNextMonth && this.refCalendar.current) {
          this.refCalendar.current.nextMonth();
        }
      }
    );
  };

  public componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { selectedCategory: prevSelectedCategory } = prevState;
    const { selectedCategory, selectedDate } = this.state;
    const {
      calendar: { events },
    } = this.props;

    if (prevSelectedCategory !== selectedCategory && selectedDate) {
      // TODO: set selectedDate to first event date

      if (selectedCategory) {
        const { month, year } = selectedDate;

        this.setState({
          paginatedEvents: this.paginateEvents(month, year, selectedCategory),
        });
      } else {
        const month = new Date(events[0].start).getMonth();
        const year = new Date(events[0].start).getFullYear();

        this.setState(
          {
            paginatedEvents: this.paginateEvents(month, year),
          },
          () => {
            this.refCalendar.current?.setMonthYear({ month, year });
          }
        );
      }
    }
  };

  private getFeaturedWorkshop = () => {
    const {
      calendar: { events },
    } = this.props;

    const now = new Date();
    const startOfToday = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      0,
      0,
      0,
      0
    );

    const filteredEvents = events.filter(
      (ce: CalendarEvent) =>
        ce.start.getTime() >= startOfToday.getTime() &&
        !!ce.workshop &&
        !ce.isEnded
    );

    return filteredEvents[0];
  };

  private initialiseIntersectionObservers = () => {
    const dayRows = document.querySelectorAll('.c-calendar__day_row');

    if (!dayRows) {
      return;
    }

    const callback = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          const dataDateAttr = entry.target.getAttribute('data-date');

          const date = Number(dataDateAttr.split('-')[2]);
          const month = Number(dataDateAttr.split('-')[1]) - 1;
          const year = Number(dataDateAttr.split('-')[0]);

          this.setState({ selectedDate: { date, month, year } });

          if (
            this.refCalendar.current &&
            month !== this.refCalendar.current.state.currentMonthYear.month
          ) {
            this.refCalendar.current.setMonthYear({ month, year });
          }
        }
      });
    };

    this.intersectionObserver = new IntersectionObserver(callback, {
      threshold: 0.25,
      rootMargin: '0px 0px -60% 0px',
    });

    dayRows.forEach((f: any) => this.intersectionObserver.observe(f));

    const lastDayRow = dayRows[dayRows.length - 1];

    if (lastDayRow) {
      const { height: lastDayRowHeight } = lastDayRow.getBoundingClientRect();

      // TODO: sort these magic numbers out
      const bottomPaddingHeight =
        window.innerHeight - lastDayRowHeight - 32 - 32 - 32 - 72;

      this.setState({
        bottomPaddingHeight: bottomPaddingHeight < 0 ? 0 : bottomPaddingHeight,
      });
    }
  };

  // `month` starts at 1 for January, rather than 0 as default in JS
  private getDaysInMonth = (month: number, year: number) =>
    new Date(year, month, 0).getDate();

  private paginateEvents = (
    selectedMonth: number,
    selectedYear: number,
    filterByCategory?: Category
  ): PaginatedCalendarEvent[] => {
    const {
      calendar: { events },
    } = this.props;

    const filteredEvents = events.filter(
      (e) => e.workshop?.workshop.eventType !== WorkshopEventType.original
    );

    const totalDaysWithEvents = filteredEvents
      .map((event) => ({
        month: new Date(event.start).getMonth(),
        year: new Date(event.end).getFullYear(),
      }))
      .reduce(
        (sum, dateItem) =>
          sum + this.getDaysInMonth(dateItem.month + 1, dateItem.year),
        0
      );

    return Array.from(Array(totalDaysWithEvents).keys())
      .map((_, date: number) => {
        const thisDayStart = new Date(
          selectedYear,
          selectedMonth,
          date + 1,
          0,
          0,
          0,
          0
        );

        const thisDayEnd = new Date(
          selectedYear,
          selectedMonth,
          date + 1,
          23,
          59,
          59,
          999
        );

        const eventsThisDay = filteredEvents
          .filter(
            (ce: CalendarEvent) =>
              ce.start.getTime() >= thisDayStart.getTime() &&
              ce.start.getTime() < thisDayEnd.getTime()
          )
          .filter((ce: CalendarEvent) => {
            if (filterByCategory) {
              if (!!ce.workshop) {
                return ce.workshop.workshop.categories
                  .map((c: Category) => c.id)
                  .includes(filterByCategory.id);
              } else if (!!ce.room) {
                return ce.room.room.industries
                  .map((c: Category) => c.id)
                  .includes(filterByCategory.id);
              } else {
                return false;
              }
            } else {
              return true;
            }
          });

        return {
          date: thisDayStart,
          events: eventsThisDay,
        };
      })
      .filter((pce: PaginatedCalendarEvent) => pce.events.length > 0);
  };

  private scrollToDate = (
    dmy: DateMonthYear,
    callback?: () => void,
    preCallback?: () => void
  ) => {
    const { date, month, year } = dmy;

    const formattedDate = `0${date}`.slice(-2);
    const formattedMonth = `0${month + 1}`.slice(-2);

    const section = document.querySelector(
      `[data-date="${year}-${formattedMonth}-${formattedDate}"]`
    );

    if (section && this.intersectionObserver) {
      this.intersectionObserver.disconnect();

      if (preCallback) {
        preCallback();
      }

      const headerHeight = 72 + 32;
      const { top } = section.getBoundingClientRect();

      window.scrollTo({
        top: window.scrollY + top - headerHeight,
        behavior: 'smooth',
      });

      setTimeout(() => {
        this.initialiseIntersectionObservers();
      }, 1500);
    }

    if (callback) {
      callback();
    }
  };

  private getFirstDateWithEvent = (month: number) => {
    let {
      calendar: { events },
    } = this.props;

    events = events.filter(
      (e) => e.workshop?.workshop.eventType !== WorkshopEventType.original
    );

    const firstEventDate = events.find(
      (event) =>
        new Date(event.start).getMonth() + 1 === month + 1 &&
        new Date(event.end) >= new Date()
    )?.start;

    return firstEventDate ? new Date(firstEventDate).getDate() : 1;
  };

  private handleMonthChange = (date: DateMonthYear) => {
    const newSelectedDate = {
      date: this.getFirstDateWithEvent(date.month),
      month: date.month,
      year: date.year,
    };

    this.setState(
      {
        hidePastEventsThisMonth: true,
        selectedDate: newSelectedDate,
      },
      () => this.scrollToDate(newSelectedDate)
    );
  };

  public render = () => {
    const {
      selectedDate,
      selectedCategory,
      paginatedEvents: paginatedEventsRaw,
      bottomPaddingHeight,
      featuredWorkshop,
      hidePastEventsThisMonth,
      isLoadedLocal,
    } = this.state;

    const {
      calendar: { isLoaded: isLoadedStore, eventDateFirst, eventDateLast },
      sizing: { isMobile },
      auth: { isAuthorised },
    } = this.props;

    let {
      calendar: { events },
    } = this.props;

    events = events.filter(
      (e) => e.workshop?.workshop.eventType !== WorkshopEventType.original
    );

    const isLoaded: boolean = isLoadedLocal && isLoadedStore;

    const paginatedEvents = paginatedEventsRaw
      ? paginatedEventsRaw.filter((pce: PaginatedCalendarEvent) => {
          const now = new Date();

          const hasAllEventsThisDayPassed =
            now.getTime() > pce.events[pce.events.length - 1].end.getTime();

          return !(hidePastEventsThisMonth && hasAllEventsThisDayPassed);
        })
      : undefined;

    const hasPastEvents: boolean =
      !!paginatedEventsRaw && paginatedEventsRaw.length > 0;

    return (
      <div className="wrap u-flex mt32">
        <Head title={t('Calendar')} pathname={t('/calendar')} />

        <div className="u-scrollbar-disabled u-hide@s c-calendar__sidebar__container u-1/4 mr12">
          <div className="c-calendar__sidebar__body pb32 u-scrollbar-disabled">
            <Calendar
              ref={this.refCalendar}
              className="mb32 ml12"
              isLoading={!isLoaded}
              selectedDate={selectedDate}
              onSelectDate={(selectedDate: DateMonthYear) => {
                const prevSelectedDate = this.state.selectedDate;

                if (!prevSelectedDate) {
                  return;
                }

                const now = new Date();

                const selectedDateObj = new Date(
                  selectedDate.year,
                  selectedDate.month,
                  selectedDate.date,
                  23,
                  59,
                  59,
                  999
                );

                const hidePastEventsThisMonth =
                  now.getTime() > selectedDateObj.getTime()
                    ? false
                    : this.state.hidePastEventsThisMonth;

                this.setState({ selectedDate, hidePastEventsThisMonth }, () => {
                  const currentSelectedDate = this.state.selectedDate;

                  if (!currentSelectedDate) {
                    return;
                  }

                  const { date, month, year } = currentSelectedDate;

                  if (month !== prevSelectedDate.month) {
                    this.setState(
                      {
                        paginatedEvents: this.paginateEvents(
                          month,
                          year,
                          selectedCategory
                        ),
                      },
                      () => {
                        this.scrollToDate(
                          currentSelectedDate,
                          () => {
                            if (date && date >= 1 && date <= 7) {
                              if (this.refCalendar.current) {
                                this.refCalendar.current.nextMonth();
                              }
                            } else {
                              if (this.refCalendar.current) {
                                this.refCalendar.current.prevMonth();
                              }
                            }
                          },
                          () => {
                            setTimeout(() => {
                              this.setState({
                                selectedDate: {
                                  date,
                                  month,
                                  year,
                                },
                              });
                            }, 1);
                          }
                        );
                      }
                    );

                    return;
                  }

                  this.scrollToDate(currentSelectedDate);
                });
              }}
              events={events}
              filterEventsByCategory={selectedCategory}
              minMonthYear={
                eventDateFirst
                  ? {
                      month: eventDateFirst.getMonth(),
                      year: eventDateFirst.getFullYear(),
                    }
                  : undefined
              }
              maxMonthYear={
                eventDateLast
                  ? {
                      month: eventDateLast.getMonth(),
                      year: eventDateLast.getFullYear(),
                    }
                  : undefined
              }
              onChangeMonth={this.handleMonthChange}
            />

            {isAuthorised ? (
              <>
                <h3
                  className={c('mb24 ml12 u-white f-text-1 u-bold', {
                    'opacity-1': isLoaded,
                    'u-translucent': !isLoaded,
                  })}
                >
                  {t('Categories')}
                </h3>
                <CalendarCategorySelector
                  showAllButton={true}
                  isLoading={!isLoaded}
                  selectedCategory={selectedCategory}
                  onCategorySelect={(category?: Category) => {
                    this.setState({ selectedCategory: category });
                  }}
                />
              </>
            ) : null}
          </div>
        </div>

        <div className="u-1/1 u-3/4@m ml24@m">
          {isLoaded ? (
            !!paginatedEvents && paginatedEvents.length > 0 ? (
              <>
                {paginatedEvents.map((pce: PaginatedCalendarEvent) => {
                  const now = new Date();

                  const formattedDateSectionTitle = new Intl.DateTimeFormat(
                    'en-GB',
                    {
                      weekday: isMobile ? 'long' : undefined,
                      day: 'numeric',
                      month: 'long',
                    }
                  ).format(pce.date);

                  const formattedDateDataAttr = new Intl.DateTimeFormat(
                    'en-GB'
                  ).formatToParts(pce.date);

                  const thisDayRowStart = new Date(
                    pce.date.getFullYear(),
                    pce.date.getMonth(),
                    pce.date.getDate(),
                    0,
                    0,
                    0,
                    0
                  );

                  const nextEvent = events.filter(
                    (ce: CalendarEvent) => ce.end.getTime() >= now.getTime()
                  )[0];

                  const hasFeaturedWorkshop: boolean =
                    !!featuredWorkshop &&
                    thisDayRowStart.getFullYear() ===
                      nextEvent.start.getFullYear() &&
                    thisDayRowStart.getMonth() === nextEvent.start.getMonth() &&
                    thisDayRowStart.getDate() === nextEvent.start.getDate() &&
                    typeof selectedCategory === 'undefined';

                  const featuredWorkshopBelongsToThisDate: boolean =
                    !!featuredWorkshop &&
                    thisDayRowStart.getDate() ===
                      featuredWorkshop.start.getDate();

                  const shouldFilterOutFeaturedWorkshop: boolean =
                    hasFeaturedWorkshop;

                  // Remove the featured event from this date section and put any
                  // elapsed workshops last
                  const filteredEvents = pce.events
                    .filter((ce: CalendarEvent) => {
                      if (
                        !!featuredWorkshop &&
                        shouldFilterOutFeaturedWorkshop
                      ) {
                        return featuredWorkshop.id !== ce.id;
                      } else {
                        return true;
                      }
                    })
                    .sort((a: CalendarEvent, b: CalendarEvent) => {
                      const resA = a.isEnded ? 1 : 0;
                      const resB = b.isEnded ? 1 : 0;

                      return resA - resB;
                    });

                  return (
                    <div
                      key={pce.date.getTime()}
                      className={c([
                        'c-calendar__day_row',
                        this.classNameDayRowContainer,
                      ])}
                      data-date={`${formattedDateDataAttr[4].value}-${formattedDateDataAttr[2].value}-${formattedDateDataAttr[0].value}`}
                    >
                      {hasFeaturedWorkshop &&
                        !featuredWorkshopBelongsToThisDate && (
                          <FeaturedWorkshop
                            isLoading={false}
                            workshopCalendarEvent={featuredWorkshop}
                          />
                        )}

                      <h2
                        className={c([
                          this.classNameDayRowTitleContainer,
                          this.classNameDayRowTitleText,
                        ])}
                      >
                        {formattedDateSectionTitle}
                      </h2>

                      {hasFeaturedWorkshop &&
                        featuredWorkshopBelongsToThisDate && (
                          <FeaturedWorkshop
                            isLoading={false}
                            workshopCalendarEvent={featuredWorkshop}
                          />
                        )}

                      <div
                        className={c('u-white', {
                          'c-calendar__day_row__grid':
                            filteredEvents.length > 1,
                          'c-calendar__day_row__grid_fill':
                            filteredEvents.length === 1,
                        })}
                      >
                        {filteredEvents.map((calendarEvent: CalendarEvent) => {
                          const isToday =
                            calendarEvent.start.getDate() === now.getDate() &&
                            calendarEvent.start.getMonth() === now.getMonth() &&
                            calendarEvent.start.getFullYear() ===
                              now.getFullYear();

                          return (
                            <CalendarCard
                              key={calendarEvent.id}
                              calendarEvent={calendarEvent}
                              disableRoomLink={!isToday}
                              disableAttendButton={calendarEvent.isInProgress}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}

                <div
                  className="c-calendar__padding u-1/1"
                  style={{ height: bottomPaddingHeight }}
                />
              </>
            ) : (
              <div className="pv32 u-text-center u-flex u-flex-column u-align-center u-justify-center u-h-100">
                <div className="u-flex u-flex-column u-align-center u-justify-center mb64">
                  <p className="f-title-2 u-bold u-grey mb4">
                    {hidePastEventsThisMonth && hasPastEvents
                      ? t('No upcoming events found')
                      : t('No events found')}
                  </p>
                  <p className="u-dark-grey u-1/2">
                    {t(
                      'Choose another category or month to see more workshops and rooms.'
                    )}
                  </p>

                  {hidePastEventsThisMonth && hasPastEvents && (
                    <Button
                      type={ButtonType.action}
                      onClick={() => {
                        this.setState({ hidePastEventsThisMonth: false });
                      }}
                      size={ButtonSize.s}
                      buttonStyle={ButtonStyle.dark}
                      text={t('Show past events')}
                      className="mt24"
                    />
                  )}
                </div>
              </div>
            )
          ) : (
            <>
              <div className={this.classNameDayRowContainer}>
                <SkeletonNew
                  elementClassName={this.classNameDayRowTitleText}
                  containerClassName={this.classNameDayRowTitleContainer}
                  width="15%"
                  fillClassName="u-bg-almost-black"
                />

                <FeaturedWorkshop
                  isLoading={true}
                  workshopCalendarEvent={undefined}
                />

                <div className="u-grid u-grid--2">
                  <CardSkeleton />
                  <CardSkeleton />
                </div>
              </div>

              {Array.from(Array(2).keys()).map((_, key: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={key} className={this.classNameDayRowContainer}>
                  <SkeletonNew
                    elementClassName={this.classNameDayRowTitleText}
                    containerClassName={this.classNameDayRowTitleContainer}
                    width="15%"
                    fillClassName="u-bg-almost-black"
                  />

                  <div className="u-grid u-grid--2">
                    <CardSkeleton />
                    <CardSkeleton />
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    );
  };
}

export const CalendarPage = connect(
  ['calendar', 'organisedCategories', 'sizing', 'auth'],
  () => ({
    ...calendarActions(),
    ...categoryActions(),
  })
)(CalendarPageComp);
