export enum GlobalPortal {
  block_user = 'block_user',
  calendar_event_unattend_confirm = 'calendar_event_unattend_confirm',
  delete_account = 'delete_account',
  invite_friends = 'invite_friends',
  manage_blocked_users = 'manage_blocked_users',
  private_profile = 'private_profile',
  profile_menu = 'profile_menu',
  profile_picture_crop = 'profile_picture_crop',
  report_user = 'report_user',
  share_current_page = 'share_current_page',
  share_link = 'share_link',
  share_project = 'share_project',
  share_profile = 'share_profile',
  subscription_cancel_confirm = 'subscription_cancel_confirm',
  workshop_attendees = 'workshop_attendees',
  workshop_add_email = 'workshop_add_email',
  mentor_followers = 'mentor_followers',
  workshop_instagram_assets = 'workshop_instagram_assets',
  users_who_to_follow = 'users_who_to_follow',
  bogo_offer = 'bogo_offer',
  lesson_guide = 'lesson_guide',
}
