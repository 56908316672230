import { HTTPStatusCode } from '@/enums/HTTPStatusCode';

import { routes } from '@/routes';

import { RELEASE_VERSION } from '@/release';

class Http {
  private route: string;
  private headers: any = {};

  constructor(
    route: string = '',
    authToken: string = '',
    apiVersion: number = 1
  ) {
    const { DAISIE_LOGIN_STORAGE_KEY: key = '', DAISIE_API_URL: base = '' } =
      process.env;

    if (route.indexOf('http') > -1) {
      this.route = route;
    } else {
      this.route = `${base}/v${apiVersion}${route}`;
    }

    const token = authToken || localStorage.getItem(key) || undefined;
    this.setDefaultHeaders(token);
  }

  public s3upload(file: File | Blob, onProgress?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      if (onProgress) {
        xhr.upload.addEventListener('progress', onProgress);
      }
      xhr.open('PUT', this.route, true);
      xhr.send(file);
      xhr.onerror = () => {
        reject(xhr.responseText);
      };
      xhr.onload = () => {
        if (xhr.status === HTTPStatusCode.OK_200) {
          resolve(xhr);
        } else {
          reject(xhr.responseText);
        }
      };
    });
  }

  public upload = (file: File) =>
    this.request({
      body: file,
      method: 'POST',
    });

  public post = <T>(data: object = {}): Promise<T> =>
    this.request({
      body: JSON.stringify(data),
      method: 'POST',
    });

  public put = <T>(data: object = {}): Promise<T> =>
    this.request({
      body: JSON.stringify(data),
      method: 'PUT',
    });

  public patch = <T>(data: object = {}): Promise<T> =>
    this.request({
      body: JSON.stringify(data),
      method: 'PATCH',
    });

  public delete = <T>(data: object = {}): Promise<T> =>
    this.request({
      body: JSON.stringify(data),
      method: 'DELETE',
    });

  public get = <T>(): Promise<T> => this.request();

  public head = <T>(): Promise<T> =>
    this.request({
      method: 'HEAD',
    });

  public getHeaders() {
    return this.headers;
  }

  public getRoute() {
    return this.route;
  }

  public setHeaders = (headers = {}) => {
    this.headers = {
      ...this.headers,
      ...headers,
    };
  };

  private setDefaultHeaders = (token?: string) => {
    const headers: any = {
      'Content-Type': 'application/json',
      'daisie-client': 'web',
      'app-version': RELEASE_VERSION,
    };

    if (token) {
      headers[process.env.DAISIE_HEADER_TOKEN || ''] = token;
    }

    const auth = process.env.DAISIE_API_AUTH || '';
    if (auth) {
      headers.Authorization = `Basic ${btoa(auth)}`;
    }

    this.setHeaders(headers);
  };

  private request = <T>(options = {}): Promise<T> =>
    fetch(this.getRoute(), {
      headers: this.getHeaders(),
      ...options,
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }

        // Don't try to renew the JWT token from the response headers when on /logout,
        // it can cause the user to stay logged in due to requests made before we
        // redirect back to the homepage
        if (window.location.pathname !== routes.logout) {
          const {
            DAISIE_HEADER_TOKEN: headerToken = '',
            DAISIE_LOGIN_STORAGE_KEY: key = '',
          } = process.env;

          const token = res.headers.get(headerToken);

          if (token) {
            localStorage.setItem(key, token);
          }
        }

        return res;
      })
      .then((res) =>
        res.status !== HTTPStatusCode.NO_CONTENT_204
          ? res.json()
          : { data: { attributes: {} } }
      );
}

export { Http };
