import * as React from 'react';

import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';

import { avatarFallback } from '@/store/helpers';

import { compressProfilePicture } from '@/utils/media/compress';
import { c } from '@/utils/strings/c';

interface Props {
  size?: string;
  className?: string;
  src: string;
  alt?: string;
  user: User | ShortenedUser | null;
}

interface State {
  src?: string;
}

class Avatar extends React.Component<Props, State> {
  public state = {
    src: undefined,
  };

  private onImageError = () => {
    const { user } = this.props;

    if (!user) return;

    this.setState({ src: avatarFallback(undefined) });
  };

  public render() {
    const { size, src: propsSrc, alt, className = '' } = this.props;
    const { src: updatedSrc } = this.state;

    const src = updatedSrc || propsSrc;

    return (
      <div className={c(['avatar__container relative', className])}>
        <ImageLoader
          src={compressProfilePicture(src)}
          alt={alt}
          onError={this.onImageError}
          className={c('avatar', { [`avatar--${size}`]: size })}
        />
      </div>
    );
  }
}

export { Avatar };
