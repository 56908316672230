export enum CategoryType {
  status = 'status',
  entity = 'entity',
  industry = 'industry',
  campaign = 'campaign',
  flag = 'flag',
  closed_campaign = 'closed_campaign',
  discontinued = 'discontinued',
  discussion_only = 'discussion_only',
}
