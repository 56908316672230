import * as React from 'react';

import { PackBlock, PackGaps } from '@/components/projects/Masonry/Packer';

import { DaisieCustomEvent } from '@/enums/DaisieCustomEvent';

import { throttle } from '@/utils/api/throttle';
import { prepareMasonryBlock } from '@/utils/blocks/blocks';
import { c } from '@/utils/strings/c';

interface Props {
  blocks: Array<
    | Project
    | User
    | CollaborationRequest
    | CollaborationInvitation
    | GenericMasonryBlockItem
  >;
  onRepack?(blocks: MasonryBlock[]): void;
  template: MasonryTemplate[];
  editable?: boolean;
  render: (items: MasonryBlock[]) => React.ReactNode;
  wrapperSelector?: string;
  smallSpacing?: boolean;
}

interface State {
  template: MasonryTemplate;
  rendered: boolean;
  timer: number;
}

class Masonry extends React.Component<Props, State> {
  private element: React.RefObject<HTMLDivElement>;

  private throttledSetTemplate: any;
  private throttledRepack: any;

  constructor(props: Props) {
    super(props);

    this.element = React.createRef();

    this.state = {
      template: this.props.template[0],
      rendered: false,
      timer: window.setInterval(this.findMasonryItems, 100),
    };
  }

  public componentDidMount() {
    this.throttledSetTemplate = throttle(this.setTemplate, 200);
    this.throttledRepack = throttle(this.repack, 200);

    window.addEventListener('load', this.setTemplate);
    window.addEventListener('resize', this.throttledSetTemplate);
    window.addEventListener(
      DaisieCustomEvent.repack,
      this.throttledSetTemplate
    );

    this.setTemplate();
  }

  public componentWillUnmount() {
    window.removeEventListener('load', this.setTemplate);
    window.removeEventListener('resize', this.throttledSetTemplate);
    window.removeEventListener(
      DaisieCustomEvent.repack,
      this.throttledSetTemplate
    );

    if (this.state.timer) {
      window.clearInterval(this.state.timer);
    }
  }

  private findMasonryItems = () => {
    if (this.element.current && this.element.current.querySelector('*')) {
      window.clearInterval(this.state.timer);

      const images = this.element.current.querySelectorAll('img');
      [].forEach.call(images, (el: HTMLImageElement) => {
        el.addEventListener('load', this.throttledRepack);
      });

      const media = this.element.current.querySelectorAll('video, audio');
      [].forEach.call(media, (el: HTMLMediaElement) => {
        el.addEventListener('loadedmetadata', this.throttledRepack);
      });

      this.setState({ rendered: true });
    }
  };

  private getTemplate(): MasonryTemplate {
    if (!window.matchMedia) return this.props.template[0];

    const matches = this.props.template.map(
      (x) => window.matchMedia(`(min-width: ${x.breakpoint})`).matches
    );

    const index = matches.lastIndexOf(true);
    return this.props.template[index] || this.props.template[0];
  }

  /**
   * Set the columns on window resize
   */
  private setTemplate = () => {
    const template = this.getTemplate();

    if (this.state.template.breakpoint !== template.breakpoint) {
      this.setState({ template });
    }

    setTimeout(() => {
      this.repack();
    }, 10);
  };

  /**
   * Helper function to place a block and create the transform string
   */
  private placeBlock = ({
    block,
    column,
    y,
    height,
    percentage,
    gridColumns,
    gridWidth,
  }: BlockPlacement) => {
    const single = gridWidth / gridColumns;
    block.x = single * column;
    block.y = y;
    block.h = height;
    block.w = single * block.data.displayProps.size;
    block.percentage = percentage;
    block.transform = `translate3d(${block.x}px, ${block.y}px, 0px)`;
    return block;
  };

  public repack = () => {
    this.setState(this.state);
  };

  /**
   * Pack the grid
   */
  private pack = () => {
    const { template } = this.state;
    const { smallSpacing } = this.props;
    const gridColumns = template.columns || 4;
    let gridWidth = 1000;

    if (this.element.current) {
      gridWidth = this.element.current.clientWidth;
    } else {
      if (typeof document !== 'undefined') {
        const wrapper = document.querySelector(
          this.props.wrapperSelector || '.project-wrapper__primary'
        );
        if (wrapper) {
          gridWidth = wrapper.clientWidth;
        }
      }
    }

    const columns = Array.from({ length: gridColumns }, () => 0);
    let gaps: Gap[][] = [];

    const blocks = [...this.props.blocks.map(prepareMasonryBlock)];

    /**
     * Loop the blocks and get their heights
     */
    blocks.forEach((block) => {
      let height = 0;
      const percentage = template.widths[block.data.displayProps.size - 1] || 1;
      const size = Math.ceil((template.columns / 100) * percentage);
      let blockWidth = null;
      let blockHeight = null;

      const blockData = block.data as Block;

      if (blockData.attachment && blockData.attachment.metadata) {
        blockWidth = blockData.attachment.metadata.width;
        blockHeight = blockData.attachment.metadata.height;
      }

      if (blockHeight && blockWidth) {
        const ratio = blockHeight / blockWidth;
        const offset = smallSpacing ? 4 : 24;

        height = Math.round(
          ((gridWidth / gridColumns) * size - offset) * ratio + offset
        );
      } else {
        if (typeof document !== 'undefined') {
          const el = document.getElementById(block.data.id);
          height = el ? el.clientHeight : 100;
        } else {
          height = 100;
        }
      }

      /**
       * Full width blocks act as a clearfix, effectively starting
       * a new masonry below them.
       */
      if (percentage === 100) {
        const tallestColumn = Math.max(...columns);
        block = this.placeBlock({
          block,
          column: 0,
          y: tallestColumn,
          height,
          percentage,
          gridColumns,
          gridWidth,
        });
        columns.forEach((x, i) => (columns[i] = tallestColumn + height));
        gaps = [];
        return;
      }

      /**
       * First try and fill any gaps
       * Note, this mutates the gaps array
       */
      const gap = PackGaps(gaps, height, size);
      if (gap.placedInGap) {
        block = this.placeBlock({
          block,
          column: gap.column,
          y: gap.y,
          height,
          percentage,
          gridColumns,
          gridWidth,
        });
        return;
      }

      const { column, y, newGaps } = PackBlock(columns, height, size);
      newGaps.forEach((x) => gaps.push(x));
      block = this.placeBlock({
        block,
        column,
        y,
        height,
        percentage,
        gridColumns,
        gridWidth,
      });
      return;
    });

    // Set the container height
    if (this.element.current) {
      this.element.current.style.height = `${Math.max(...columns)}px`;
    }

    blocks.sort(this.sortBlocks);
    return blocks;
  };

  /**
   * Sort blocks by y, then x
   */
  private sortBlocks = (a: MasonryBlock, b: MasonryBlock) => {
    if (b.y === a.y) {
      return b.x > a.x ? -1 : 1;
    } else {
      return b.y > a.y ? -1 : 1;
    }
  };

  public render() {
    const { editable, smallSpacing } = this.props;
    const items = this.pack();

    return (
      <div
        className={c('blocks blocks--masonry', {
          'blocks--editable': editable,
          'blocks--hidden': !this.state.rendered,
          'blocks--masonry--small-spacing': smallSpacing,
        })}
        ref={this.element}
        data-columns={this.state.template.columns}
      >
        {this.props.render(items)}
      </div>
    );
  }
}

export { Masonry };
