import * as React from 'react';

import { c } from '@/utils/strings/c';

interface Props {
  text: string;
  useBackground?: boolean;
  classNameContainer?: string;
  styleA: RibbonStyle;
  styleB: RibbonStyle;
  styleC?: RibbonStyle;
  repetitions?: number;
}

interface RibbonStyle {
  className: string;
  rotate: string;
  scale?: number;
  translateX?: string;
  translateY?: string;
  transformOrigin?: string;
}

class Ribbons extends React.Component<Props> {
  public render = () => {
    const {
      text,
      useBackground,
      classNameContainer = '',
      styleA,
      styleB,
      styleC,
      repetitions = 40,
    } = this.props;

    const ribbonText = Array.from(Array(repetitions).keys()).map((_, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <span key={i}>{text}</span>
    ));

    return (
      <section className={c(['ribbons__container', classNameContainer])}>
        {useBackground && <div className="ribbons__container__bg" />}
        <div
          className={c(['ribbons__ribbon', styleA.className])}
          style={{
            transform: `rotate(${styleA.rotate}) scale(${
              styleA.scale || 1.0
            }) translateX(${styleA.translateX || 0}) translateY(${
              styleA.translateY || 0
            })`,
          }}
        >
          {ribbonText}
        </div>
        <div
          className={c(['ribbons__ribbon', styleB.className])}
          style={{
            transform: `rotate(${styleB.rotate}) scale(${
              styleB.scale || 1.0
            }) translateX(${styleB.translateX || 0}) translateY(${
              styleB.translateY || 0
            })`,
          }}
        >
          {ribbonText}
        </div>
        {styleC && (
          <div
            className={c(['ribbons__ribbon', styleC.className])}
            style={{
              transform: `rotate(${styleC.rotate}) scale(${
                styleC.scale || 1.0
              }) translateX(${styleC.translateX || 0}) translateY(${
                styleC.translateY || 0
              })`,
            }}
          >
            {ribbonText}
          </div>
        )}
      </section>
    );
  };
}

export { Ribbons };
