import * as React from 'react';

import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { LoadingSymbol } from '@/components/global/LoadingSymbol/LoadingSymbol';

import { Http } from '@/utils/api/Http';
import { URL_IMAGE_GENERATOR } from '@/utils/constants';
import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props {
  workshop: Workshop;
}

interface State {
  isLoading: boolean;
  imageUrlPrimary?: string;
  imageUrlInstructor?: string;
  imageUrlReminder?: string;
}

export class InstagramAssetsModalPortal extends React.Component<Props, State> {
  public state: State = {
    isLoading: true,
    imageUrlPrimary: undefined,
    imageUrlInstructor: undefined,
    imageUrlReminder: undefined,
  };

  public componentDidMount = async () => {
    const { workshop } = this.props;
    const { id: workshopId } = workshop;

    try {
      const getImageUrlPrimary = new Promise<void>(async (resolve, reject) => {
        try {
          const { path } = await new Http(
            `${URL_IMAGE_GENERATOR}/workshops/${workshopId}?type=ig_story_primary`
          ).get();

          this.setState({ imageUrlPrimary: path }, () => resolve());
        } catch (e) {
          return reject();
        }
      });

      const getImageUrlInstructor = new Promise<void>(
        async (resolve, reject) => {
          try {
            const { path } = await new Http(
              `${URL_IMAGE_GENERATOR}/workshops/${workshopId}?type=ig_story_instructor`
            ).get();

            this.setState({ imageUrlInstructor: path }, () => resolve());
          } catch (e) {
            return reject();
          }
        }
      );

      const getImageUrlReminder = new Promise<void>(async (resolve, reject) => {
        try {
          const { path } = await new Http(
            `${URL_IMAGE_GENERATOR}/workshops/${workshopId}?type=ig_story_reminder`
          ).get();

          this.setState({ imageUrlReminder: path }, () => resolve());
        } catch (e) {
          return reject();
        }
      });

      await Promise.all([
        getImageUrlPrimary,
        getImageUrlInstructor,
        getImageUrlReminder,
      ]);

      this.setState({ isLoading: false });
    } catch (e) {
      // TODO: show toast
    }
  };

  public render = () => {
    const { isLoading, imageUrlPrimary, imageUrlInstructor, imageUrlReminder } =
      this.state;

    return (
      <div className="relative">
        <div
          className={c('animate-opacity', {
            'opacity-1': !isLoading,
            'opacity-0 pointer-events-none': isLoading,
          })}
        >
          <div className="c-instructor-dashboard__assets_container u-flex">
            <div className="c-instructor-dashboard__ig_asset ml20@s">
              {!!imageUrlPrimary && <ImageLoader src={imageUrlPrimary} />}
            </div>
            <div className="c-instructor-dashboard__ig_asset mh12 mh24@m">
              {!!imageUrlInstructor && <ImageLoader src={imageUrlInstructor} />}
            </div>
            <div className="c-instructor-dashboard__ig_asset mr20@s">
              {!!imageUrlReminder && <ImageLoader src={imageUrlReminder} />}
            </div>
          </div>

          <p className="mt24 u-1/1 u-grey u-text-center u-hide@m">
            {t('Tap and hold an image to save it')}
          </p>

          <p className="mt24 u-1/1 u-grey u-text-center u-hide@s">
            {t('Right-click an image to save it')}
          </p>
        </div>

        <div
          className={c(
            'absolute absolute--tl u-1/1 u-h-100 u-flex u-align-center u-justify-center',
            {
              'opacity-1': isLoading,
              'opacity-0 pointer-eventsnone': !isLoading,
            }
          )}
        >
          <LoadingSymbol size="l" colour="white" />
        </div>
      </div>
    );
  };
}
