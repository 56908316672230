import * as React from 'react';

import Markdown from 'react-markdown';
import { connect } from 'unistore/react';

import { Icon } from '@/components/global/Icon/Icon';
import { YourMentorOrRoomHost } from '@/components/global/YourMentorOrRoomHost/YourMentorOrRoomHost';
import { WorkshopShareButton } from '@/components/workshops/WorkshopShareButton';

import { LabelPill } from '@/components/design_system/LabelPill/LabelPill';

import { SubscriptionInterval } from '@/enums/SubscriptionInterval';

import { findProductAndPrice } from '@/store/helpers/subscriptionHelpers';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';
import { getDisplayPrice } from '@/utils/strings/currency';

interface Props extends SizingState, SubscriptionState, AuthState {
  workshop: Workshop;
  attendees: User[];
  isAttendeesLoaded: boolean;
  className?: string;
}

class WorkshopMetadataComponent extends React.Component<Props> {
  public render = () => {
    const {
      className = '',
      sizing: { isMobile },
      workshop,
      attendees,
      isAttendeesLoaded,
      subscription: { productsAndPrices, userCurrency },
      auth: { user },
    } = this.props;

    const { title, description, whenLabel, categories } = workshop;

    const isSubscribed: boolean = !!user && user.subscriptionTier !== null;

    const renderEventDate = () => (
      <div className="u-flex u-flex-wrap">
        {whenLabel && (
          <p className="u-bold u-flex u-align-center relative">
            <span className="f-text-2">{whenLabel.short}</span>
          </p>
        )}
      </div>
    );

    const renderBottomContent = () => (
      <div className="c-workshop-metadata__bottom">
        <h1 className="mb16 f-yell-1 u-text-center@s">{title}</h1>

        <YourMentorOrRoomHost
          isLoading={false}
          workshop={workshop}
          attendees={attendees}
          isAttendeesLoaded={isAttendeesLoaded}
          isCompact={isMobile}
          className={c('', {
            'u-justify-center u-align-center': isMobile,
          })}
        />

        {!isMobile && (
          <Markdown
            className="f-text-2 c-workshop-metadata__description mt8 mt12 u-1/2@m u-1/1@l"
            disallowedElements={[
              'image',
              'imageReference',
              'table',
              'tableHead',
              'tableBody',
              'tableRow',
              'tableCell',
              'html',
              'virtualHtml',
              'thematicBreak',
            ]}
          >
            {description}
          </Markdown>
        )}
      </div>
    );

    const renderPrice = () => {
      const yearlyPrice = findProductAndPrice({
        productsAndPrices,
        currency: userCurrency,
        recurringInterval: SubscriptionInterval.year,
      });

      return (
        <p className="f-text-2 mv24">
          {t(
            `Daisie is ${
              yearlyPrice &&
              `${getDisplayPrice(
                yearlyPrice.currency,
                Math.floor(yearlyPrice.amount / 12)
              )}`
            }/month (billed annually)`
          )}
        </p>
      );
    };

    return (
      <div className={className}>
        {isMobile ? (
          <>
            <div>{renderBottomContent()}</div>
            <div className="u-flex u-flex-column u-align-center pt60">
              <div className="pb24@m">
                <div className="f-text-3 u-light-grey u-text-center pb4">
                  <Icon
                    id={`category-${categories[0].slug}`}
                    width="8px"
                    className="mr6"
                  />
                  {categories[0].name}
                </div>

                {!workshop.hasElapsed && (
                  <div className="u-bold">{renderEventDate()}</div>
                )}
              </div>

              {!isSubscribed && renderPrice()}
            </div>
          </>
        ) : (
          <div className="c-workshop-metadata">
            <div className="c-workshop-metadata__top mb16 u-flex u-align-center u-justify-between">
              <div className="u-flex u-align-center">
                <LabelPill
                  iconId={`category-${categories[0].slug}`}
                  text={categories[0].name}
                  withShadow={true}
                />

                {!workshop.hasElapsed && (
                  <>
                    <div className="ph12">{renderEventDate()}</div>
                    <WorkshopShareButton workshop={workshop} />
                  </>
                )}
              </div>
            </div>

            {renderBottomContent()}
          </div>
        )}
      </div>
    );
  };
}

export const WorkshopMetadata = connect(
  ['sizing', 'subscription', 'auth'],
  () => ({})
)(WorkshopMetadataComponent);
