import * as React from 'react';

import { UsersListItem } from '@/components/users/UsersList/UsersListItem';

interface Props {
  user: User;
}

class UserCardboard extends React.Component<Props> {
  public render = () => {
    const { user } = this.props;

    return (
      <UsersListItem
        user={user}
        type="normal"
        linkClassName="u-card u-card--dark p16"
        darkMode={true}
      />
    );
  };
}

export { UserCardboard };
