import React, { useRef } from 'react';

interface Props {
  word: string;
}

const TextCircle = React.memo(({ word }: Props) => {
  const charRotation = useRef(0);

  const calculateCharacterRotation = (wordIndex: number, charIndex: number) => {
    // Divide the circle into even parts depending on the number of letters
    // including 4 gaps that will be placed between words
    const degPerCharacter = 360 / (word.length * 4 + 4);

    if (charIndex === 0 && wordIndex !== 0) {
      charRotation.current = charRotation.current + degPerCharacter * 2;
    } else {
      charRotation.current = charRotation.current + degPerCharacter;
    }

    return charRotation.current;
  };

  return (
    <div className="c-text-circle__char-groups relative">
      {Array.from(Array(4).keys()).map((elm, wordIndex: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={wordIndex} className="c-text-circle__char-group absolute">
          {word.split('').map((char, charIndex) => (
            <span
              style={{
                transform: `translateX(-50%) rotate(${calculateCharacterRotation(
                  wordIndex,
                  charIndex
                )}deg)`,
              }}
              className="c-text-circle__char absolute absolute--tb u-bold"
              // eslint-disable-next-line react/no-array-index-key
              key={charIndex}
            >
              {char}
            </span>
          ))}
        </div>
      ))}
    </div>
  );
});

export default TextCircle;
