import * as React from 'react';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';
import { ButtonGroup } from '@/components/design_system/ButtonGroup/ButtonGroup';

import { t } from '@/utils/i18n/i18n';

interface Props {
  closeModalPortal: () => void;
  onUnattend: () => void;
}

export const CalendarEventUnattendConfirm = (props: Props) => {
  const { closeModalPortal, onUnattend } = props;

  return (
    <div className="u-flex u-flex-column">
      <p className="mb32 u-white">{t('Are you sure you want to cancel?')}</p>

      <ButtonGroup className="mlauto@m">
        <Button
          type={ButtonType.action}
          onClick={() => {
            closeModalPortal();
          }}
          size={ButtonSize.m}
          buttonStyle={ButtonStyle.dark}
          text={t('No')}
          className="u-1/1@s"
        />

        <Button
          type={ButtonType.action}
          onClick={async () => {
            onUnattend();
            closeModalPortal();
          }}
          size={ButtonSize.m}
          buttonStyle={ButtonStyle.default}
          text={t('Yes')}
          className="u-1/1@s"
        />
      </ButtonGroup>
    </div>
  );
};
