/* eslint-disable no-restricted-imports */
import React, { useEffect } from 'react';

import { Box } from '@chakra-ui/react';
import gsap from 'gsap';

import { BottomMenuMobile } from '@/components/design_system/AlexJenkinsClass/organisms/BottomMenuMobile';

import { landingPageAnimations } from '@/pages/AlexJenkinsClass/animations/animations';

import { pxToRem } from '@/styles/chakra-theme/metrics';

import { isDesktop } from '@/utils/DOM/sizing';

import {
  ClassBreakDownSection,
  ClassForYouSection,
  FaqSection,
  Footer,
  Header,
  ReviewsSection,
  SkillsHeroSection,
  SkillsLearningSection,
} from './sections';

/**
 * grid boxes dimensions are:
 * sm: 48px x 48px,
 * >= md: 120px x 120px
 */

export const AlexJenkinsClass = () => {
  useEffect(() => {
    gsap.delayedCall(1, landingPageAnimations);
  }, []);

  return (
    <Box scrollBehavior="smooth" fontFamily="NeueMontreal" overflowX="hidden">
      <Box overflow="auto" overflowX="hidden" h="100%" className="wrapper">
        <Header />

        <SkillsHeroSection />

        <SkillsLearningSection id="outcomes" mt={{ base: -95, lg: -75 }} />

        <ClassBreakDownSection id="overview" />

        <ClassForYouSection />

        <ReviewsSection id="reviews" />

        <FaqSection
          id="faq"
          px={[pxToRem(22), pxToRem(42), pxToRem(62)]}
          pt={[pxToRem(40), pxToRem(62)]}
          pb={pxToRem(100)}
        />

        <Footer id="footer" px={[pxToRem(22), pxToRem(42), pxToRem(62)]} />

        {!isDesktop() && <BottomMenuMobile />}

        {/* <MouseParticles g={0} tha={0} life={2} color="white" /> */}
      </Box>
    </Box>
  );
};
