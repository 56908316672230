import * as React from 'react';

import { Icon } from '@/components/global/Icon/Icon';
import { Link } from '@/components/global/Link/Link';

import { IconSize } from '@/enums/IconSize';

import { t } from '@/utils/i18n/i18n';

interface Props {
  type: 'skip' | 'close';
  linkTo: string;
}

export const OptimisedJoinFlowSkipCloseButton = (props: Props) => {
  const { type, linkTo } = props;

  if (type === 'close') {
    return (
      <Link to={linkTo} className="absolute absolute--tr" style={{ zIndex: 4 }}>
        <Icon id="clear" size={IconSize.s} className="u-white mt24 mr24" />
      </Link>
    );
  } else {
    return (
      <Link
        to={linkTo}
        className="u-link-white--alt absolute absolute--tr mt24 mr24 u-white"
        style={{ zIndex: 4 }}
      >
        {t('Skip')}
      </Link>
    );
  }
};
