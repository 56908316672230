import * as React from 'react';

import { Head } from '@/components/global/Head/Head';
import { CollectionsGrid } from '@/components/ondemand/Collections/CollectionsGrid';

import { t } from '@/utils/i18n/i18n';

interface Props {}

interface State {}

class Explore extends React.Component<Props, State> {
  public render = () => (
    <div className="wrap u-white mt32">
      <Head title={t('Explore')} pathname={t('/explore')} />

      <h1 className="f-title-2 u-bold mb32">{t('Explore')}</h1>

      <CollectionsGrid />
    </div>
  );
}

export { Explore };
