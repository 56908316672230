import React from 'react';

import { Box, BoxProps, Image, Text } from '@chakra-ui/react';

import { TitleTag } from '@/components/design_system/AlexJenkinsClass/atoms';

import { pxToRem } from '@/styles/chakra-theme/metrics';

interface Props extends BoxProps {
  tag: string;
  title: string;
  text: string;
}

export const DetailsCard = ({ tag, title, text, ...props }: Partial<Props>) => (
  <Box bg="#000" border="1px solid #ffffff20" p={pxToRem(16)} {...props}>
    <TitleTag
      key={tag}
      color="white"
      bg="transparent"
      border="1px solid white"
      fontSize={[24, 24, 14, 24, 24]}
      fontWeight={400}
      py={{ base: pxToRem(20), md: pxToRem(12), lg: pxToRem(25) }}
    >
      {tag}
    </TitleTag>

    <Text
      fontSize={{ base: 28, lg: 32, xl: 38, '2xl': 32 }}
      fontWeight={400}
      color="white"
      mt={{ base: pxToRem(18), md: pxToRem(16), lg: pxToRem(22) }}
      lineHeight={1.2}
    >
      {title}
    </Text>

    <Text
      fontSize={{ base: 16, lg: 18, xl: 24, '2xl': 18 }}
      fontWeight={400}
      color="white"
      mt={{ base: pxToRem(18), md: pxToRem(12), lg: pxToRem(32) }}
    >
      {text}
    </Text>
  </Box>
);
