import * as React from 'react';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { connect } from 'unistore/react';

import { ManageBlockedUsers } from '@/components/accounts/AccountSettings/ManageBlockedUsers';
import { PrivateProfileModalPortal } from '@/components/accounts/AccountSettings/PrivateProfileModalPortal';
import {
  BlockUser,
  DeleteAccount,
  MarkdownHelp,
  ReportUser,
  UnfollowUser,
} from '@/components/application/Loadables';
import { CloseButton } from '@/components/global/Button/CloseButton';
import { CalendarEventUnattendConfirm } from '@/components/global/Cards/CalendarEventUnattendConfirm';
import { ProfilePictureCrop } from '@/components/global/ProfilePictureCrop/ProfilePictureCrop';
import { SaveChanges } from '@/components/global/SaveChanges/SaveChanges';
import { ShareLink } from '@/components/global/ShareLink/ShareLink';
import { ShareOptions } from '@/components/share/ShareOptions';
import { SubscriptionCancelConfirmModal } from '@/components/subscription/SubscriptionSettings/SubscriptionCancelConfirmModal';
import { UserProfileFollowersModal } from '@/components/users/UserProfile/UserProfileFollowersModal';
import { WorkshopAddEmail } from '@/components/workshops/WorkshopAddEmail';
import { WorkshopAttendeesModal } from '@/components/workshops/WorkshopAttendeesModal';
import { WorkshopReferAnInstructorModal } from '@/components/workshops/WorkshopReferAnInstructorModal';

import { GlobalModal } from '@/enums/GlobalModal';
import { IconSize } from '@/enums/IconSize';

import { InstagramAssetsModalPortal } from '@/pages/InstructorDashboard/InstagramAssetsModalPortal';

import { modalActions } from '@/store/modules/modal';

import { SHARE_LINK_JOIN_DAISIE } from '@/utils/constants';
import { c } from '@/utils/strings/c';
import { OriginalsLessonGuideModal } from '@/components/originals/OriginalsLessonGuideModal';
import { OptimisedJoinFlowBogoModal } from '@/pages/OptimisedJoinFlow/OptimisedJoinFlowBogoModal';

type Props = ModalState & ModalActions;

class GlobalModalComp extends React.Component<Props> {
  public componentDidMount() {
    window.addEventListener('keydown', this.escapeListener);
  }

  public componentWillUnmount() {
    document.body.classList.remove('prevent-scroll-all');

    window.removeEventListener('keydown', this.escapeListener);
  }

  private clearModal = () => {
    if (this.props.modal.disableClose) return;

    if (this.props.modal.onClose) {
      this.props.modal.onClose();
    }

    this.props.updateModal({ modal: undefined });
  };

  private escapeListener = (event: KeyboardEvent) => {
    if (event.keyCode === 27 && this.open() && !this.props.modal.disableClose) {
      this.clearModal();
      event.preventDefault();
    }
  };

  private open = () => !!this.props.modal.modal;

  public render() {
    const {
      modal,
      data = {},
      className = '',
      bodyClassName = '',
    } = this.props.modal;

    const open = this.open();

    const {
      block_user,
      unfollow_user,
      delete_account,
      invite_friends,
      manage_blocked_users,
      markdown,
      private_profile,
      profile_picture_crop,
      report_user,
      save_changes,
      share_current_page,
      share_link,
      share_project,
      share_profile,
      sign_up_modal,
      workshop_attendees,
      subscription_cancel_confirm,
      calendar_event_unattend_confirm,
      workshop_add_email,
      mentor_followers,
      workshop_instagram_assets,
      refer_an_instructor,
      lesson_guide,
      bogo_offer,
    } = GlobalModal;

    const header =
      {
        [block_user]: () =>
          `${data.user.hasBlocked ? 'Unblock' : 'Block'} ${
            data.user.primaryName
          }`,
        [unfollow_user]: () => `Unfollow ${data.username}`,
        [invite_friends]: () => 'Invite friends',
        [manage_blocked_users]: () => 'Blocked people',
        [markdown]: () => 'Styling your text',
        [private_profile]: () => 'Make your profile private?',
        [profile_picture_crop]: false,
        [report_user]: () => 'Could you tell us more?',
        [share_current_page]: () => data.title || 'Share',
        [share_link]: () => data.title || 'Share',
        [share_project]: () => 'Share',
        [share_profile]: () => 'Share',
        [sign_up_modal]: false,
        [workshop_attendees]: () => 'Attendees',
        [mentor_followers]: () => `Followers`,
        [workshop_attendees]: () => data.title || 'Attendees',
        [workshop_instagram_assets]: () => 'Download assets',
        [refer_an_instructor]: () => 'Refer an instructor',
        [lesson_guide]: () => 'Class guide',
        [bogo_offer]: () => 'Share',
      }[modal] || '';

    const hideCloseButton =
      {
        [block_user]: () => true,
        [private_profile]: () => true,
        [report_user]: () => true,
        [save_changes]: () => true,
      }[modal] || false;

    const darkMode =
      {
        [workshop_attendees]: () => true,
        [invite_friends]: () => true,
        [share_profile]: () => true,
        [unfollow_user]: () => true,
        [manage_blocked_users]: () => true,
        [private_profile]: () => true,
        [calendar_event_unattend_confirm]: () => true,
        [report_user]: () => true,
        [block_user]: () => true,
        [delete_account]: () => true,
        [workshop_add_email]: () => true,
        [mentor_followers]: () => true,
        [share_link]: () => true,
        [workshop_instagram_assets]: () => true,
        [refer_an_instructor]: () => true,
        [lesson_guide]: () => true,
        [share_current_page]: () => true,
        [bogo_offer]: () => true,
      }[modal] ||
      data.isDarkMode ||
      false;

    return (
      <ReactCSSTransitionGroup
        transitionName="t-dropdown"
        transitionAppear={true}
        transitionAppearTimeout={300}
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
        component="aside"
        className={c('page-modal', {
          'page-modal--closed': !open,
        })}
        onTransitionEnd={() => {
          if (this.props.modal.modal) {
            document.body.classList.add('prevent-scroll-all');
          } else {
            document.body.classList.remove('prevent-scroll-all');
          }
        }}
      >
        {open ? (
          <>
            <button
              type="button"
              onClick={data.disableEasyDismiss ? undefined : this.clearModal}
              className={c('page-modal__cover fill', {
                'page-modal--closed': !open,
                'page-modal__cover--dark': darkMode,
              })}
              tabIndex={-1}
            />

            <div
              className={c(
                [
                  'global-modal page-modal__content pl24 pr24 u-scrollbar-disabled',
                  className,
                ],
                {
                  relative: !header,
                  'page-modal__content--dark': darkMode,
                }
              )}
            >
              {header && (
                <header
                  className={c(
                    'global-modal__header pt24 pb16 mb24 u-flex u-justify-between u-align-center',
                    {
                      bb: !darkMode,
                      'global-modal__header--dark bb--dark-grey': darkMode,
                    }
                  )}
                  style={{ zIndex: 10 }}
                >
                  <h1
                    className={c('f-title-2 u-x-bold', {
                      'u-white': darkMode,
                    })}
                  >
                    {header()}
                  </h1>
                  {!hideCloseButton && (
                    <CloseButton
                      onClick={this.clearModal}
                      isDarkMode={darkMode}
                      size={IconSize.xs}
                    />
                  )}
                </header>
              )}
              <div
                className={c(['page-modal__body', bodyClassName], {
                  'pt24 c-modal__content': !header,
                  pb24: modal !== mentor_followers,
                })}
              >
                {modal === block_user && (
                  <BlockUser user={data.user} toggle={this.clearModal} />
                )}
                {modal === unfollow_user && (
                  <UnfollowUser
                    user={data.username}
                    toggle={this.clearModal}
                    onSuccess={data.onSuccess}
                  />
                )}
                {modal === delete_account && (
                  <DeleteAccount toggle={this.clearModal} />
                )}
                {modal === manage_blocked_users && (
                  <ManageBlockedUsers toggle={this.clearModal} />
                )}
                {modal === markdown && <MarkdownHelp />}
                {modal === invite_friends && (
                  <ShareLink
                    from={data.from}
                    link={SHARE_LINK_JOIN_DAISIE}
                    mixpanelTrackEventPrefix="invite"
                    isDarkMode={darkMode}
                    twitterText={data.twitterText}
                  />
                )}
                {modal === private_profile && <PrivateProfileModalPortal />}
                {modal === profile_picture_crop && (
                  <ProfilePictureCrop
                    image={data.image}
                    fileName={data.fileName}
                    fileMimeType={data.fileMimeType}
                  />
                )}
                {modal === report_user && (
                  <ReportUser user={data.user} toggle={this.clearModal} />
                )}
                {modal === save_changes && <SaveChanges data={data} />}
                {modal === share_current_page && (
                  <ShareLink
                    from={data.from}
                    mixpanelTrackEventPrefix={data.mixpanelTrackEventPrefix}
                    isDarkMode={darkMode}
                    twitterText={data.twitterText}
                    emailSubject={data.emailSubject}
                  />
                )}
                {modal === share_link && (
                  <ShareLink
                    from={data.from}
                    link={data.link}
                    mixpanelTrackEventPrefix={data.mixpanelTrackEventPrefix}
                    isDarkMode={darkMode}
                    twitterText={data.twitterText}
                    emailSubject={data.emailSubject}
                    emailBody={data.emailBody}
                  />
                )}
                {modal === share_project && (
                  <ShareOptions room={data.project} />
                )}
                {modal === share_profile && <ShareOptions user={data.user} />}
                {modal === workshop_attendees && (
                  <WorkshopAttendeesModal
                    users={data.attendees}
                    close={this.clearModal}
                  />
                )}
                {modal === mentor_followers && (
                  <UserProfileFollowersModal
                    users={data.followers}
                    close={this.clearModal}
                  />
                )}
                {modal === subscription_cancel_confirm && (
                  <SubscriptionCancelConfirmModal />
                )}
                {modal === calendar_event_unattend_confirm && (
                  <CalendarEventUnattendConfirm
                    onUnattend={data.onUnattend}
                    closeModalPortal={this.clearModal}
                  />
                )}
                {modal === workshop_add_email && (
                  <WorkshopAddEmail
                    onComplete={data.onComplete}
                    closeModalPortal={this.clearModal}
                  />
                )}
                {modal === workshop_instagram_assets && (
                  <InstagramAssetsModalPortal workshop={data.workshop} />
                )}
                {modal === refer_an_instructor && (
                  <WorkshopReferAnInstructorModal />
                )}
                {modal === lesson_guide && (
                  <OriginalsLessonGuideModal
                    resources={data.resources}
                    tools={data.tools}
                  />
                )}
                {modal === bogo_offer && (
                  <OptimisedJoinFlowBogoModal
                    onDismiss={data.onClose}
                    link={`https://daisie.com/promo/bogo?promotionalCode=${data.promotionalCode}`}
                  />
                )}
              </div>
            </div>
          </>
        ) : null}
      </ReactCSSTransitionGroup>
    );
  }
}

export const GlobalModalComponent = connect(
  'modal',
  modalActions
)(GlobalModalComp);
