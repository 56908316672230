import { useState } from 'react';

// eslint-disable-next-line func-style
function useVideoHistory(workshopSlug: string) {
  const splitWorkshopSlug: string[] = workshopSlug.split('-');
  const workshopId: string = `workshop_${splitWorkshopSlug.pop()}`;

  const [videoHistory, setVideoHistory] = useState(() => {
    let videoHistory: any = localStorage.getItem('videoHistory');

    if (videoHistory) {
      videoHistory = JSON.parse(videoHistory);
    } else {
      videoHistory = { [workshopId]: undefined };
    }

    return videoHistory;
  });

  const setLastVideoWatched = (workshopId: string, onDemandMediaId: string) => {
    const newVideoHistory = {
      ...videoHistory,
      [workshopId]: onDemandMediaId,
    };

    localStorage.setItem('videoHistory', JSON.stringify(newVideoHistory));

    setVideoHistory(newVideoHistory);
  };

  return [videoHistory, setLastVideoWatched];
}

export default useVideoHistory;
