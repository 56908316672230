import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'unistore/react';

import { DiscordPopup } from '@/components/global/DiscordPopup/DiscordPopup';
import { DownloadAppBanner } from '@/components/global/DownloadAppBanner/DownloadAppBanner';
import { GlobalModalComponent } from '@/components/global/Modal/GlobalModalComponent';
import { Navigation } from '@/components/global/Navigation/Navigation';
import { SettingsTray } from '@/components/global/SettingsTray/SettingsTray';
import { Toaster } from '@/components/global/Toaster/Toaster';
import { CookiesConsent } from '@/components/legal/CookiesConsent/CookiesConsent';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

import { c } from '@/utils/strings/c';
import { getRouteFromUrl } from '@/utils/urls/routes';
import { routes } from '@/routes';

interface AppFrameProps extends SizingState, RouteComponentProps {
  children?: React.ReactNode;
  isAuthorised?: boolean;
}

interface State {
  cookieBannerVisible: boolean;
  height: string;
  top: string;
  paddingTop: string;
  isDownloadAppBannerDismissed: boolean;
  isDiscordPopUpDismissed: boolean;
  isDiscordBannerDismissed: boolean;
}

class AppFrameComponent extends React.Component<AppFrameProps, State> {
  public state = {
    cookieBannerVisible: isUiTest()
      ? false
      : !localStorage.getItem('cookie_consent'),
    height: 'auto',
    top: '0px',
    paddingTop: '60px',
    isDownloadAppBannerDismissed: !!localStorage.getItem(
      'dismissed_download_app_banner'
    ),
    isDiscordPopUpDismissed: !!localStorage.getItem('dismissed_discord_popup'),
    isDiscordBannerDismissed: !!localStorage.getItem(
      'dismissed_discord_banner'
    ),
  };

  public componentDidMount = () => {
    if (isUiTest()) {
      const params = new URLSearchParams(this.props.location.search);
      const showCookieBanner = decodeURIComponent(
        params.get('showCookieBanner') || ''
      );

      if (showCookieBanner === 'true') {
        this.setState({ cookieBannerVisible: true });
      }
    }

    this.redrawSections();

    window.addEventListener('resize', this.redrawSections);
  };

  public componentDidUpdate = (prevProps: AppFrameProps) => {
    const {
      location: { pathname },
    } = this.props;

    const {
      location: { pathname: prevPathname },
    } = prevProps;

    if (pathname !== prevPathname) {
      this.redrawSections();
    }
  };

  public componentWillUnmount = () => {
    window.removeEventListener('resize', this.redrawSections);
  };

  private redrawSections = () => {
    // On these routes, fix the viewport to 100vh
    const fixHeightRoutes = ['roomsCreate'];

    const {
      cookieBannerVisible,
      isDownloadAppBannerDismissed,
      isDiscordBannerDismissed,
    } = this.state;
    const {
      location: { pathname },
      sizing: { isMobile },
    } = this.props;
    const matchedRoute = getRouteFromUrl(pathname);
    const cookieBanner = document.querySelector('.c-cookie-consent');
    const discordBanner = document.querySelector('.c-discord-banner');
    const downloadAppBanner = document.querySelector('.c-download-app-banner');

    const downloadAppBannerHeight =
      downloadAppBanner?.getBoundingClientRect().height;
    if (!isDownloadAppBannerDismissed) {
      this.setState({
        top: matchedRoute === 'home' ? `${downloadAppBannerHeight}px` : '0px',
      });
    }
    if (
      cookieBannerVisible &&
      cookieBanner &&
      !isDiscordBannerDismissed &&
      discordBanner
    ) {
      const { height: discordBannerHeight } =
        discordBanner.getBoundingClientRect();
      this.setState({
        top: isMobile ? `${discordBannerHeight}px` : '140px',
      });
    } else if (cookieBannerVisible && cookieBanner) {
      const { height: cookieBannerHeight } =
        cookieBanner.getBoundingClientRect();

      this.setState({
        paddingTop: isMobile
          ? '0'
          : `${cookieBannerHeight + this.state.paddingTop}px`,
        top:
          matchedRoute === 'home' && isMobile
            ? `${downloadAppBannerHeight}px`
            : '0px',
      });
    } else if (!isDiscordBannerDismissed && discordBanner) {
      const { height: discordBannerHeight } =
        discordBanner.getBoundingClientRect();
      this.setState({
        paddingTop: isMobile
          ? '0'
          : `${discordBannerHeight + this.state.paddingTop}px`,
        top: `${discordBannerHeight}px`,
      });
    } else if (matchedRoute && fixHeightRoutes.includes(matchedRoute)) {
      this.setState({ height: `${window.innerHeight}px` });
    } else {
      this.setState({ height: 'auto' });
    }
  };

  public hideCookieBanner = (scrollTo?: number) => {
    this.setState({ cookieBannerVisible: false }, () => {
      const {
        sizing: { isMobile },
      } = this.props;

      const pageLayout = document.querySelector(
        '.page-layout'
      ) as HTMLDivElement;

      if (!isMobile && pageLayout) {
        pageLayout.style.cssText = '';
      }

      if (scrollTo) {
        window.scrollTo({ top: scrollTo, left: 0 });
      }
    });
  };

  public hideDiscordPopUp = () => {
    this.setState({ isDiscordPopUpDismissed: true });
  };

  public hideDownloadAppBanner = (scrollTo?: number) => {
    localStorage.setItem('dismissed_download_app_banner', 'true');
    this.setState({ isDownloadAppBannerDismissed: true }, () => {
      const pageLayout = document.querySelector(
        '.page-layout'
      ) as HTMLDivElement;

      if (pageLayout) {
        pageLayout.style.cssText = '';
      }

      if (scrollTo) {
        window.scrollTo({ top: scrollTo, left: 0 });
      }
    });
  };

  public hideDiscordBanner = (scrollTo?: number) => {
    localStorage.setItem('dismissed_discord_banner', 'true');
    this.setState({ isDiscordBannerDismissed: true }, () => {
      const pageLayout = document.querySelector(
        '.page-layout'
      ) as HTMLDivElement;

      if (pageLayout) {
        pageLayout.style.cssText = '';
      }

      if (scrollTo) {
        window.scrollTo({ top: scrollTo, left: 0 });
      }
    });
  };

  public render = () => {
    const matchedRoute =
      getRouteFromUrl(this.props.location.pathname) || 'home';

    const {
      isAuthorised,
      sizing: { isMobile },
    } = this.props;

    const {
      height,
      top,
      paddingTop,
      cookieBannerVisible,
      isDownloadAppBannerDismissed,
      isDiscordPopUpDismissed,
      isDiscordBannerDismissed,
    } = this.state;

    const shouldSkipDiscordAnnouncement = [
      routes.studentsSignUpYearlyPay,
      routes.studentsSignUpMonthlyPay,
      routes.optimisedJoinFlowSignUpYearlyPay,
      routes.optimisedJoinFlowSignUpMonthlyPay,
      routes.optimisedJoinFlowSignUpYearlyPay,
    ].includes(this.props.location.pathname);

    return (
      <div>
        <CookiesConsent
          hideCookieBanner={this.hideCookieBanner}
          cookieBannerVisible={cookieBannerVisible}
          isDiscordBannerDismissed={isDiscordBannerDismissed}
          route={matchedRoute}
        />

        {!isDownloadAppBannerDismissed &&
          matchedRoute === 'home' &&
          isMobile && (
            <DownloadAppBanner
              route={matchedRoute}
              hideDownloadAppBanner={this.hideDownloadAppBanner}
            />
          )}

        <div
          className={c('page-layout', {
            'page-layout--download-app-banner':
              (!isDownloadAppBannerDismissed && isMobile) ||
              (!isDiscordBannerDismissed && isMobile),
            'ui-tests': isUiTest(),
            'u-overflow-visible':
              matchedRoute === 'room' ||
              matchedRoute === 'workshop' ||
              matchedRoute === 'home',
            'u-min-height-auto': matchedRoute === 'roomsCreate',
          })}
          data-key-route={matchedRoute}
          data-auth={!!isAuthorised ? 'true' : 'false'}
          style={{
            height,
            top,
            paddingTop: matchedRoute !== 'alexJenkinsClass' ? paddingTop : 0,
          }}
        >
          {matchedRoute !== 'alexJenkinsClass' && (
            <Navigation
              page={matchedRoute}
              pathname={this.props.location.pathname}
              isDownloadAppBannerDismissed={
                this.state.isDownloadAppBannerDismissed
              }
              isDiscordBannerDismissed={this.state.isDiscordBannerDismissed}
            />
          )}
          <main
            role="main"
            style={{
              padding: matchedRoute !== 'alexJenkinsClass' ? undefined : 0,
            }}
          >
            {this.props.children}
          </main>
          <aside className="settings-tray mobile-only u-1/1" />
          <SettingsTray />
          <GlobalModalComponent />
          <Toaster />
        </div>
      </div>
    );
  };
}

export const AppFrame = connect(['sizing'])(withRouter(AppFrameComponent));
