import * as React from 'react';

import { ProgressBar } from '@/components/design_system/ProgressBar/ProgressBar';

interface Props {
  className?: string;
  start: Date;
  end: Date;
}

interface State {
  progressPercent?: number;
}

class ProgressBarTimeLeft extends React.Component<Props, State> {
  private refreshTimer?: NodeJS.Timeout;

  public state: State = {
    progressPercent: undefined,
  };

  public componentDidMount = () => {
    this.refresh();
  };

  public componentWillUnmount = () => {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
  };

  private refresh = () => {
    const { start, end } = this.props;
    const now = new Date();

    if (now.getTime() >= end.getTime()) {
      // This event has passed, so don't worry about refreshing
      this.setState({ progressPercent: undefined });
      return;
    }

    if (now.getTime() < start.getTime()) {
      // Event could start while the user is viewing, so hide the ProgressBar
      // but poll for the event starting
      this.setState({ progressPercent: undefined });
    }

    if (now.getTime() >= start.getTime() && now.getTime() < end.getTime()) {
      const timeElapsed = now.getTime() - start.getTime();
      const duration = end.getTime() - start.getTime();

      this.setState({ progressPercent: (timeElapsed / duration) * 100 });
    }

    this.refreshTimer = setTimeout(this.refresh, 1000);
  };

  public render = () => {
    const { className } = this.props;
    const { progressPercent } = this.state;

    if (!progressPercent) {
      return null;
    }

    return (
      <ProgressBar progressPercent={progressPercent} className={className} />
    );
  };
}

export { ProgressBarTimeLeft };
