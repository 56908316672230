import * as React from 'react';

import { connect } from 'unistore/react';
import widont from 'widont';

import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import imgReviewStars from '@/components/global/WorkshopReview/images/review-stars.svg';
import { WorkshopReview } from '@/components/global/WorkshopReview/WorkshopReview';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { Currency } from '@/enums/Currency';
import { SubscriptionInterval } from '@/enums/SubscriptionInterval';

import imgVideoReplacement from '@/pages/LoggedOutHomepage/HeroVariants/variant-b-video-replacement.jpg';

import { routes } from '@/routes';

import { findProductAndPrice } from '@/store/helpers/subscriptionHelpers';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';
import { getDisplayPrice } from '@/utils/strings/currency';

interface Props extends SizingState, ABTestState {
  formOnly?: boolean;
  variant: number;
  userCurrency: Currency;
  productsAndPrices?: SubscriptionProductPrice[];
}

const LoggedOutHomepageVariantBComp = ({
  formOnly,
  sizing,
  variant,
  userCurrency,
  productsAndPrices,
}: Props) => {
  const productPrice: SubscriptionProductPrice | undefined =
    findProductAndPrice({
      productsAndPrices: productsAndPrices,
      currency: userCurrency,
      recurringInterval: SubscriptionInterval.year,
    });

  const planSelectorForm = (
    <Button
      type={ButtonType.link}
      linkTo={routes.optimisedJoinFlowChoosePlan}
      buttonStyle={ButtonStyle.default}
      size={sizing.isMobile ? ButtonSize.xl : ButtonSize.l}
      text={t('Try for Free')}
      buttonTextClassName="ph48@m mh2@m pv2@m"
      className={c('u-fit-content mrauto', {
        mhauto: !!formOnly || sizing.isMobile,
      })}
    />
  );

  if (formOnly) {
    return planSelectorForm;
  }

  if (!productPrice) {
    return null;
  }

  return (
    <>
      {!sizing.isMobile && (
        <>
          <div
            style={{
              padding: '56.25% 0 0 0',
              position: 'absolute',
              width: '100%',
              top: '50%',
              transform: 'translateY(-50%) scale(1.5)',
            }}
          >
            <iframe
              src="https://player.vimeo.com/video/551941347?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&loop=1&background=1&muted=1"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
              title="hero-video"
            />
          </div>
          <script src="https://player.vimeo.com/api/player.js" />

          <div className="c-category-page-logged-out__hero__overlay_v" />
          <div className="c-category-page-logged-out__hero__overlay_h" />
        </>
      )}

      <div className="wrap u-h-100@m u-flex u-flex-column">
        <div className="relative u-1/1 u-h-100@m u-flex u-align-center u-justify-center">
          <div className="u-1/1 u-flex@m u-flex-column@m">
            <h2
              className="f-lynstone-bold-1 u-white mt32@s mb32 u-text-center@s u-3/4@m"
              style={{
                fontSize: sizing.isMobile ? '3rem' : undefined,
                lineHeight: sizing.isMobile ? '3rem' : undefined,
              }}
            >
              {!sizing.isMobile
                ? widont(
                    t(
                      `${
                        variant === 0
                          ? 'Learn from the best'
                          : 'Join the new creative community'
                      }`
                    )
                  )
                : t(
                    `${
                      variant === 0
                        ? 'Learn from the best'
                        : 'Join the new creative community'
                    }`
                  )}
              <span className="u-hide@m">.</span>
            </h2>

            <p className="mb44 f-title-2 u-white u-text-center@s u-2/5@m">
              {t(
                `${
                  variant === 0
                    ? `Join classes taught by industry-leading experts for ${getDisplayPrice(
                        userCurrency,
                        productPrice.amount
                      )} a year.`
                    : 'Join live classes, meet likeminded creators and achieve your goals'
                }`
              )}
            </p>

            {planSelectorForm}
          </div>
        </div>
      </div>

      {sizing.isMobile && (
        <>
          <ImageLoader src={imgVideoReplacement} alt="" className="mt44 mb64" />

          {/* <div className="u-white mt64">
            <WorkshopReview />
          </div> */}
        </>
      )}
    </>
  );
};

export const LoggedOutHomepageVariantB = connect(
  ['sizing', 'abtest'],
  () => ({})
)(LoggedOutHomepageVariantBComp);
