import * as React from 'react';

import { connect } from 'unistore/react';

import { AB_TEST_DISCOUNT_PERCENT_OFF_DISPLAY } from '@/components/subscription/constants';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { routes } from '@/routes';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props extends AuthState, ABTestState {
  className?: string;
}

class OptimisedJoinFlowNavButtonComp extends React.Component<Props> {
  public render = () => {
    const {
      className = '',
      auth: { user, isAuthorised },
      abtest: { variant },
    } = this.props;

    if (!isAuthorised || (!!user && user.subscriptionTier !== null)) {
      return null;
    }

    const isGrandfatheredUser: boolean = !!user && user.isGrandfathered;

    const hasABTestDiscount: boolean =
      typeof variant !== 'undefined' && variant === 2;

    const buttonText: string = hasABTestDiscount
      ? t(`Get ${AB_TEST_DISCOUNT_PERCENT_OFF_DISPLAY}% off`)
      : isGrandfatheredUser || user?.hasPastSub
      ? t(`Upgrade`)
      : t('Start free trial');

    const buttonLinkTo: string = isGrandfatheredUser
      ? routes.optimisedJoinFlowChoosePlan
      : hasABTestDiscount
      ? routes.optimisedJoinFlowSignUpYearlyPay
      : routes.optimisedJoinFlowChoosePlan;

    return (
      <Button
        type={ButtonType.link}
        linkTo={buttonLinkTo}
        text={buttonText}
        buttonStyle={ButtonStyle.default}
        size={ButtonSize.m}
        className={c([className])}
      />
    );
  };
}

export const OptimisedJoinFlowNavButton = connect(
  ['auth', 'abtest'],
  () => ({})
)(OptimisedJoinFlowNavButtonComp);
