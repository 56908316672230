import * as React from 'react';

import { connect } from 'unistore/react';

import { Icon } from '@/components/global/Icon/Icon';

import { c } from '@/utils/strings/c';

interface Props extends SizingState {
  onItemClick: (index: number | undefined) => void;
  isExpanded: boolean;
  section: FaqSection;
  index: number | undefined;
}

interface FaqSection {
  question: string;
  answer: React.ReactNode;
}

const FAQItemWorkshopV2Comp = ({
  sizing: { isMobile },
  onItemClick,
  isExpanded,
  section,
  index,
}: Props) => (
  <div
    // eslint-disable-next-line react/no-array-index-key
    key={index}
    className="u-white"
  >
    <button
      type="button"
      onClick={() => {
        onItemClick(!isExpanded ? index : undefined);
      }}
      className={c('', {
        'bb--charcoal-grey u-1/1 pv32': !isMobile,
        'bb--charcoal-grey u-1/1 pv20': isMobile,
      })}
    >
      <div className="u-flex u-align-center u-split">
        <p className="u-bold f-title-3">{section.question}</p>
        <Icon
          id={isExpanded ? 'minus' : 'add'}
          width={'18px'}
          className={c('ml16', {
            'u-dark-grey': !isExpanded,
            'u-white': isExpanded,
          })}
        />
      </div>

      {isExpanded && (
        <div className="mt24 mb8 u-line-height-1/2 u-text-left f-text-1 u-grey f-inter-light-1">
          {section.answer}
        </div>
      )}
    </button>
  </div>
);

export const FAQItemWorkshopV2 = connect(['sizing'])(FAQItemWorkshopV2Comp);
