export const getCuratedImagesFromData = (
  items: APICuratedImage[]
): CuratedImage[] => items.map(getCuratedImageFromData).filter(Boolean);

export const getCuratedImageFromData = (
  item: APICuratedImage
): CuratedImage => ({
  id: item.id,
  label: item.attributes.label,
  imageUrl: item.relationships.attachment.data.attributes.metadata
    ? item.relationships.attachment.data.attributes.metadata
        .processorMediaPath || ''
    : '',
  hexCode: item.attributes.hexCode,
  contrastMode: item.attributes.contrastMode,
});
