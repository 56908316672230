import * as React from 'react';

import WordmarkBlack from '@/components/global/Wordmark/wordmark-black.png';
import WordmarkWhite from '@/components/global/Wordmark/wordmark-white.png';

import { DaisieWordmarkColour } from '@/enums/DaisieWordmarkColour';

import { t } from '@/utils/i18n/i18n';

interface Props {
  colour?: DaisieWordmarkColour;
  width?: string;
  height?: string;
  className?: string;
}

class Wordmark extends React.Component<Props> {
  public render = () => {
    const { colour, className = '', width, height } = this.props;

    let src = WordmarkBlack;

    if (colour === DaisieWordmarkColour.White) {
      src = WordmarkWhite;
    }

    return (
      <img
        src={src}
        style={{ width, height }}
        className={className}
        alt={t('Daisie')}
      />
    );
  };
}

export { Wordmark };
