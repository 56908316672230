import * as React from 'react';

import { t } from '@/utils/i18n/i18n';

export const WorkshopReferAnInstructorModal = () => (
  <div className="u-overflow-scroll u-scrollbar-disabled">
    <div className="u-white">
      <p className="pb4">
        {t("We'll pay you $200 if you introduce us to a creator we work with.")}
      </p>
      <p className="pb4">
        <a className="u-link-white u-underline" href="mailto:nadine@daisie.com">
          {t('Send us an email')}
        </a>
        {t(' with the creators name & social media links.')}
      </p>
      <p>{t("We'll get back to you soon!")}</p>
    </div>
  </div>
);
