import * as React from 'react';

import Bowser from 'bowser';
import { connect } from 'unistore/react';

import { MobileOS } from '@/components/global/PreMobileWall/MobileOS';

interface Props extends SizingState {
  renderIos: React.ReactNode;
  renderAndroid: React.ReactNode;
}

interface State {
  os?: MobileOS;
}

class OSSwitchComponent extends React.Component<Props, State> {
  public state: State = {
    os: undefined,
  };

  public componentDidMount = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);

    this.setState({
      os: browser.getOS().name === 'Android' ? MobileOS.android : MobileOS.ios,
    });
  };

  public render = () => {
    const {
      renderIos,
      renderAndroid,
      sizing: { isMobile },
    } = this.props;
    const { os } = this.state;

    if (!isMobile) {
      return null;
    }

    if (os === MobileOS.ios) {
      return renderIos;
    } else if (os === MobileOS.android) {
      return renderAndroid || null;
    } else {
      return null;
    }
  };
}

export const OSSwitch = connect(['sizing'], () => ({}))(OSSwitchComponent);
