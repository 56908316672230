import * as React from 'react';

import { UsersCarouselItem } from '@/components/users/UsersList/UsersCarouselItem';

import { AvatarSize } from '@/components/design_system/Avatar/AvatarSize';
import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { c } from '@/utils/strings/c';

interface Props {
  users: User[];
  className?: string;
  cornerHoverAction?: (userId: string) => void;
  showCornerHoverAction?: (userId: string) => boolean;
  close?: () => void;
}

export const UserGrid = (props: Props) => {
  const { users, className = '', cornerHoverAction, close } = props;

  return (
    <div className={c(['dds-user-grid', className])}>
      {users.reverse().map((u: User) => {
        const showCornerHoverAction = props.showCornerHoverAction
          ? props.showCornerHoverAction(u.id)
          : false;

        return (
          <UsersCarouselItem
            key={u.id}
            user={u}
            close={close}
            avatarSize={AvatarSize.fill_m}
            cornerHoverItems={
              showCornerHoverAction ? (
                <Button
                  type={ButtonType.action}
                  onClick={() => {
                    if (cornerHoverAction) {
                      cornerHoverAction(u.id);
                    }
                  }}
                  buttonStyle={ButtonStyle.light}
                  size={ButtonSize.s}
                  iconId="clear"
                />
              ) : undefined
            }
          />
        );
      })}
    </div>
  );
};
