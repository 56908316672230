import { ContrastMode } from '@/enums/ContrastMode';
import { CustomError } from '@/enums/CustomError';
import { ProjectVisibility } from '@/enums/ProjectVisibility';

import {
  getBansFromData,
  getCollaborationRequestFromData,
  getOpenRolesFromData,
} from '@/store/helpers';
import { getRoomFromData } from '@/store/helpers/roomHelpers';

import { Http } from '@/utils/api/Http';
import { track } from '@/utils/mixpanel/mixpanel';

const roomState: RoomState = {
  room: {
    currentRoom: undefined,
    draftRoom: undefined,
  },
};

const roomActions = (store: any) => {
  const checkRoomURLHasntChanged = (
    state: GlobalStoreState,
    cachedRoom?: Room | false
  ) => {
    if (cachedRoom) {
      const cachedRoomId = cachedRoom.id.replace('project_', '');
      if (!window.location.pathname.includes(cachedRoomId)) {
        throw new Error(CustomError.CancelPolling.toString());
      }
    }
  };

  return {
    createDraft: (state: GlobalStoreState) => {
      const {
        auth: { user },
        room,
      } = state;

      return {
        room: {
          ...room,
          draftRoom: {
            title: '',
            industries: [],
            visibility: ProjectVisibility.open,
            members: [user],
            slug: undefined,
            isMember: true,
            isOwner: true,
            backgroundImage: {
              constrastMode: ContrastMode.light,
            },
            isSaved: false,
          },
        },
      };
    },

    updateDraft: (state: GlobalStoreState, params: Partial<Room>) => {
      const { room } = state;

      return {
        room: {
          ...room,
          draftRoom: {
            ...room.draftRoom,
            ...params,
          },
        },
      };
    },

    discardDraft: (state: GlobalStoreState) => {
      const { room } = state;

      return {
        room: {
          ...room,
          draftRoom: undefined,
        },
      };
    },

    saveDraft: async (state: GlobalStoreState, curatedImageId: string) => {
      const {
        room: { draftRoom: room },
      } = state;

      if (!room) {
        return;
      }

      const { title, visibility, industries } = room;

      try {
        const { data } = await new Http('/projects').post<APIObject>({
          title,
          visibility,
          categories: industries.map((c: Category) => c.id),
          backgroundImage: {
            uuid: curatedImageId,
          },
        });

        const savedRoom = getRoomFromData({
          roomData: data as APIRoom,
        });

        // bg: Original background id
        // These are short to save space in localStorage
        // localStorage.setItem(
        //   `${savedRoom.id}`,
        //   JSON.stringify({ bg: savedRoom.backgroundImage.id })
        // );

        return {
          room: {
            ...state.room,
            draftRoom: {
              ...state.room.draftRoom,
              slug: savedRoom.slug,
              isSaved: true,
            },
          },
        };
      } catch (e) {
        throw new Error();
      }
    },

    clearRoom: (state: GlobalStoreState) => ({
      room: {
        ...state.room,
        currentRoom: undefined,
      },
    }),

    getRoom: async (state: GlobalStoreState, { slug }: { slug: string }) => {
      const cachedRoom = state.room.currentRoom
        ? (JSON.parse(JSON.stringify(state.room.currentRoom)) as Room)
        : false;

      try {
        const id = `project_${slug.split('-').pop()}`;

        const { data: roomData } = await new Http(`/projects/${id}`).get<
          APIObject<APIRoom>
        >();

        const room = getRoomFromData({
          roomData,
          curatedImages: state.cinematicBackground.curatedImages,
        });

        // make sure the URL hasn't changed before we reset the store
        checkRoomURLHasntChanged(store.getState(), cachedRoom);

        return {
          room: {
            ...state.room,
            currentRoom: {
              ...room,
              bans: cachedRoom && cachedRoom.bans ? cachedRoom.bans : undefined,
            },
          },
        };
      } catch (e) {
        throw e;
      }
    },

    deleteRoom: async (state: GlobalStoreState, id: string) => {
      try {
        await new Http(`/projects/${id}`).delete();
        track('delete_project');
      } catch (e) {
        throw e;
      }
    },

    updateRoom: async (state: GlobalStoreState, updatedRoom: Partial<Room>) => {
      const {
        room: { currentRoom },
      } = state;

      if (!currentRoom) {
        throw new Error();
      }

      const industriesHasUpdated =
        typeof updatedRoom.industries !== 'undefined';
      const locationHasUpdated = typeof updatedRoom.location !== 'undefined';
      const backgroundImageHasUpdated =
        typeof updatedRoom.backgroundImage !== 'undefined';

      const payload = {
        ...updatedRoom,
        industries: undefined,
        categories: industriesHasUpdated
          ? updatedRoom.industries?.map((c: Category) => c.id)
          : undefined,
        location:
          locationHasUpdated && updatedRoom.location
            ? updatedRoom.location.id
            : undefined,
        backgroundImage:
          backgroundImageHasUpdated &&
          updatedRoom.backgroundImage &&
          updatedRoom.backgroundImage.id
            ? { uuid: updatedRoom.backgroundImage.id }
            : undefined,
      };

      let mergedRoomData = { ...currentRoom, ...updatedRoom };

      try {
        const { data } = await new Http(
          `/projects/${currentRoom.id}`
        ).patch<APIObject>(payload);

        // Find full info about the new background image
        const newBackgroundImage = backgroundImageHasUpdated
          ? state.cinematicBackground.curatedImages.find(
              (ci: CuratedImage) => ci.id === updatedRoom.backgroundImage!.id
            )
          : undefined;

        mergedRoomData = {
          ...mergedRoomData,
          ...updatedRoom,
          slug: data.attributes.slug,
          backgroundImage:
            // Update background image object if background was changed
            updatedRoom.backgroundImage && newBackgroundImage
              ? newBackgroundImage
              : currentRoom.backgroundImage,
        };

        if (locationHasUpdated && updatedRoom.location?.id === '') {
          mergedRoomData = {
            ...mergedRoomData,
            location: undefined,
          };
        }
      } catch (e) {
        throw e;
      }

      return {
        room: {
          currentRoom: mergedRoomData,
        },
      };
    },

    setContrastMode: (state: GlobalStoreState, contrastMode: ContrastMode) => {
      const {
        room: { currentRoom: room, draftRoom },
      } = state;

      if (!room && !draftRoom) {
        throw new Error();
      }

      if (room) {
        return {
          room: {
            ...state.room,
            currentRoom: {
              ...room,
              backgroundImage: {
                ...room.backgroundImage,
                contrastMode,
              },
            },
          },
        };
      }

      if (draftRoom) {
        return {
          room: {
            ...state.room,
            draftRoom: {
              ...draftRoom,
              backgroundImage: {
                ...draftRoom.backgroundImage,
                contrastMode,
              },
            },
          },
        };
      }

      return;
    },

    addOpenRole: async (state: GlobalStoreState, role: Role) => {
      const {
        room: { currentRoom: room },
      } = state;

      if (!room) {
        return;
      }

      let openRoles: OpenRole[] = [];

      try {
        await new Http(`/projects/${room.id}/openRoles`).post({
          openRole: {
            roleId: role.id,
            description: '', // Not being used for now
          },
        });

        // Refetch open roles so we have the openRole id
        const { data: openRolesData } = await new Http(
          `/projects/${room.id}/openRoles`
        ).get<APIArray<APIOpenRole>>();

        openRoles = getOpenRolesFromData(openRolesData);
      } catch (e) {
        throw e;
      }

      return {
        room: {
          ...state.room,
          currentRoom: {
            ...room,
            openRoles,
          },
        },
      };
    },

    removeOpenRole: async (state: GlobalStoreState, role: OpenRole) => {
      const {
        room: { currentRoom: room },
      } = state;

      if (!room) {
        throw new Error();
      }

      try {
        await new Http(`/projects/${room.id}/openRoles/${role.id}`).delete();
      } catch (e) {
        throw e;
      }

      return {
        room: {
          ...state.room,
          currentRoom: {
            ...room,
            openRoles: room.openRoles.filter((r: OpenRole) => r.id !== role.id),
          },
        },
      };
    },

    sendCollaboratorInvitation: async (state: GlobalStoreState, id: string) => {
      const { currentRoom } = state.room;

      if (!currentRoom) {
        return state;
      }

      try {
        await new Http(
          `/projects/${currentRoom.id}/collaborationInvitations`
        ).post({ userUuid: id });

        track('invite_member', { roomId: currentRoom.id, invited_user_id: id });
      } catch (e) {
        throw e;
      }

      return state;
    },

    sendCollaborationRequest: async (state: GlobalStoreState, message = '') => {
      const { currentRoom } = state.room;

      if (!currentRoom) {
        return state;
      }

      const { data } = await new Http(
        `/projects/${currentRoom.id}/collaborationRequests`
      ).post({ message });

      // we don't currently get back the project back from the POST request, so we're manually adding it into the response
      const newRequest = getCollaborationRequestFromData(data);
      newRequest.project = { id: currentRoom.id };

      track('request_collaboration');

      const newState = store.getState() as GlobalStoreState;

      const collaborationRequests = newState.auth.user
        ? [...newState.auth.user.collaborationRequests, newRequest]
        : [newRequest];

      return {
        auth: {
          ...newState.auth,
          user: {
            ...newState.auth.user,
            collaborationRequests,
          },
        },
      };
    },

    revokeCollaborationRequest: async (
      state: GlobalStoreState,
      uuid: string
    ) => {
      const {
        room: { currentRoom },
        auth: { user },
      } = state;

      if (!currentRoom || !uuid || !user) {
        return state;
      }

      try {
        await new Http(`/collaborationRequests/${uuid}/revoke`).post();

        currentRoom.collaborationRequests =
          currentRoom.collaborationRequests.filter(
            (x: CollaborationRequest) => x.id !== uuid
          );

        user.collaborationRequests = user.collaborationRequests.filter(
          (x: CollaborationRequest) => x.id !== uuid
        );

        track('revoke_collaboration_request');
      } catch (e) {
        throw e;
      }

      return {
        auth: {
          ...state.auth,
          user,
        },
      };
    },

    revokeCollaborationInvitation: async (
      state: GlobalStoreState,
      uuid: string
    ) => {
      const { currentRoom } = state.room;

      if (!currentRoom || !currentRoom.collaborationInvitations || !uuid) {
        return state;
      }

      await new Http(`/collaborationInvitations/${uuid}/revoke`).post();
      track('revoke_collaboration_invitation');

      currentRoom.collaborationInvitations =
        currentRoom.collaborationInvitations.filter(
          (x: CollaborationInvitation) => x.id !== uuid
        );

      return {
        room: {
          ...state.room,
          currentRoom,
        },
      };
    },

    removeCollaborationInvite: async (
      state: GlobalStoreState,
      { uuid, accept }: { uuid: string; accept?: boolean }
    ) => {
      const {
        room: { currentRoom },
      } = state;

      if (!uuid) {
        return state;
      }

      try {
        if (accept) {
          await new Http(`/collaborationInvitations/${uuid}/accept`).post();

          track('accept_invitation');

          if (currentRoom && currentRoom.collaborationInvitations) {
            const invite = currentRoom.collaborationInvitations.find(
              (x: CollaborationInvitation) => x.id === uuid
            );

            if (invite && state.auth.user && currentRoom.members) {
              currentRoom.collaborationInvitations =
                currentRoom.collaborationInvitations.filter(
                  (x: CollaborationInvitation) => x.id !== uuid
                );

              currentRoom.hasCollaborationInvitation = false;
              currentRoom.members.push(state.auth.user);
              currentRoom.memberCount++;
              currentRoom.isMember = true;
            }
          }
        } else {
          await new Http(`/collaborationInvitations/${uuid}/reject`).post();

          track('refuse_invitation');

          if (currentRoom && currentRoom.collaborationInvitations) {
            const invite = currentRoom.collaborationInvitations.find(
              (x: CollaborationInvitation) => x.id === uuid
            );

            if (invite) {
              currentRoom.collaborationInvitations =
                currentRoom.collaborationInvitations.filter(
                  (x: CollaborationInvitation) => x.id !== uuid
                );
              currentRoom.hasCollaborationInvitation = false;
            }
          }
        }
      } catch (e) {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }

      return {
        room: {
          ...state.room,
          currentRoom,
        },
      };
    },

    removeCollaborationRequest: async (
      state: GlobalStoreState,
      { uuid, accept }: { uuid: string; accept?: boolean }
    ) => {
      const { currentRoom } = state.room;

      if (!uuid || !currentRoom) {
        return state;
      }

      try {
        if (accept) {
          await new Http(`/collaborationRequests/${uuid}/accept`).post();

          track('accept_collaboration_request');

          if (currentRoom.collaborationRequests) {
            const request = currentRoom.collaborationRequests.find(
              (x: CollaborationRequest) => x.id === uuid
            );

            if (request && currentRoom.members) {
              currentRoom.memberCount++;
              currentRoom.members.push(request.requester);
              currentRoom.collaborationRequests =
                currentRoom.collaborationRequests.filter(
                  (x: any) => x.id !== uuid
                );
            }
          }
        } else {
          await new Http(`/collaborationRequests/${uuid}/reject`).post();

          track('refuse_collaboration_request');

          if (currentRoom && currentRoom.collaborationRequests) {
            const request = currentRoom.collaborationRequests.find(
              (x: CollaborationRequest) => x.id === uuid
            );

            if (request && currentRoom.members) {
              currentRoom.collaborationRequests =
                currentRoom.collaborationRequests.filter(
                  (x: CollaborationRequest) => x.id !== uuid
                );
            }
          }
        }
      } catch (e) {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }

      return {
        room: {
          ...state.room,
          currentRoom,
        },
      };
    },

    removeCollaborator: async (state: GlobalStoreState, id: string) => {
      const {
        room: { currentRoom },
      } = state;

      const isMe = state.auth.user ? state.auth.user.id === id : false;

      if (!currentRoom || !currentRoom.members || !id) {
        return;
      }

      try {
        await new Http(`/projects/${currentRoom.id}/users/${id}`).delete();

        if (!isMe) {
          track('remove_collaborator');
        }

        const members = currentRoom.members.filter((x: User) => x.id !== id);
        const membersCount = members.length;

        const newState = store.getState();

        store.setState({
          room: {
            ...newState.room,
            currentRoom: {
              ...newState.room.currentRoom,
              isMember:
                isMe && !currentRoom.isOwner ? false : currentRoom.isMember,
              members,
              membersCount,
            },
          },
        });
      } catch (e) {
        throw e;
      }
    },

    getBans: async (state: GlobalStoreState) => {
      const {
        room: { currentRoom },
      } = state;

      if (!currentRoom) {
        return;
      }

      try {
        const { data } = await new Http(`/projects/${currentRoom.id}/bans`).get<
          APIObject<APIBan[]>
        >();

        const bans = getBansFromData(data);

        const newState = store.getState();

        store.setState({
          room: {
            ...newState.room,
            currentRoom: {
              ...newState.room.currentRoom,
              bans,
            },
          },
        });
      } catch (e) {
        throw e;
      }
    },

    banCollaborator: async (
      state: GlobalStoreState,
      id: string,
      reason?: string
    ) => {
      const {
        room: { currentRoom },
      } = state;

      if (!currentRoom || !currentRoom.members || !id) {
        return;
      }

      try {
        await new Http(`/projects/${currentRoom.id}/ban`).post({
          userToBanUuid: id,
          reason,
        });

        track('ban_collaborator');
      } catch (e) {
        throw e;
      }
    },

    unbanCollaborator: async (state: GlobalStoreState, id: string) => {
      const {
        room: { currentRoom },
      } = state;

      if (!currentRoom || !currentRoom.members || !id) {
        return;
      }

      try {
        await new Http(`/projects/${currentRoom.id}/ban/${id}`).delete();

        track('unban_collaborator');

        const bans = currentRoom.bans?.filter((x: Ban) => x.id !== id) || [];

        const newState = store.getState();

        store.setState({
          room: {
            ...newState.room,
            currentRoom: {
              ...newState.room.currentRoom,
              bans,
            },
          },
        });
      } catch (e) {
        throw e;
      }
    },
  };
};

export { roomState, roomActions };
