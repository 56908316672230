import * as React from 'react';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';
import { ButtonGroup } from '@/components/design_system/ButtonGroup/ButtonGroup';

import { t } from '@/utils/i18n/i18n';

interface Props {
  header?: React.ReactNode;
  body?: React.ReactNode;
  confirmCtaLabel: string;
  confirmCtaAction: () => void;
  confirmButtonStyle?: ButtonStyle;
  cancelCtaLabel?: string;
  cancelCtaAction: () => void;
  confirmCtaActionEnabled?: boolean;
  className?: string;
}

interface State {
  processing: boolean;
}

class ModalConfirm extends React.Component<Props, State> {
  public state = {
    processing: false,
  };

  private onConfirmClick = async () => {
    const { confirmCtaAction } = this.props;
    const { processing } = this.state;

    if (processing) return;

    this.setState({ processing: true });
    await confirmCtaAction();
    this.setState({ processing: false });
  };

  public render() {
    const {
      header = null,
      body = null,
      confirmCtaLabel,
      confirmButtonStyle = ButtonStyle.light,
      cancelCtaAction,
      cancelCtaLabel = t('Cancel'),
      className,
      confirmCtaActionEnabled = true,
    } = this.props;
    const { processing } = this.state;

    return (
      <div className={className}>
        <div className="c-modal-confirm">
          {header ? (
            <div className="pb16 mb24 bb--dark-grey">{header}</div>
          ) : null}
          {body ? <div className="mb24">{body}</div> : null}

          <ButtonGroup>
            <Button
              type={ButtonType.action}
              onClick={cancelCtaAction}
              text={cancelCtaLabel}
              buttonStyle={ButtonStyle.dark}
              disabled={processing}
              className="u-flex-1@s"
            />

            <Button
              type={ButtonType.action}
              onClick={this.onConfirmClick}
              text={confirmCtaLabel}
              disabled={!confirmCtaActionEnabled || processing}
              buttonStyle={confirmButtonStyle}
              isLoading={processing}
              className="u-flex-1@s"
            />
          </ButtonGroup>
        </div>
      </div>
    );
  }
}

export { ModalConfirm };
