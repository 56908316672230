import * as React from 'react';

import { connect } from 'unistore/react';

import { Icon } from '@/components/global/Icon/Icon';
import { Link } from '@/components/global/Link/Link';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { GlobalModal } from '@/enums/GlobalModal';
import { GlobalPortal } from '@/enums/GlobalPortal';

import { routes } from '@/routes';

import { modalActions } from '@/store/modules/modal';
import { portalActions } from '@/store/modules/portal';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props
  extends ModalActions,
    ModalState,
    PortalActions,
    PortalState,
    UserState,
    AuthState,
    SizingState {
  toggle?: () => void;
}

class UserProfileMenuComponent extends React.Component<Props> {
  private inputProfileUrl: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.inputProfileUrl = React.createRef();
  }

  private returnToMenu = () => {
    const { user } = this.props;

    this.props.updatePortal({
      portal: GlobalPortal.profile_menu,
      data: {
        user,
      },
    });
  };

  public render = () => {
    const {
      toggle,
      user,
      auth: { user: currentUser },
      sizing: { isMobile },
    } = this.props;

    if (!user || !currentUser) return null;

    const { hasBlocked } = user;
    const isNotMe = user.username !== currentUser.username;

    const className = (isLast?: boolean, linkAlert?: boolean) =>
      c('u-flex u-text-left u-align-center', {
        'u-1/1 pv16': isMobile,
        mb16: !isMobile && !isLast,
        'u-link-alert u-link-alert--alt': linkAlert,
      });
    const classNameText = 'f-text-3';
    const classNameIcon = 'mr8';

    return (
      <div>
        {!isNotMe && isMobile && (
          <Link
            to={routes.editProfile}
            className={className()}
            onClick={() => {
              this.props.updatePortal({
                portal: undefined,
              });
            }}
          >
            <Icon id="edit" size="s" className={classNameIcon} />
            <span className={classNameText}>{t('Edit profile')}</span>
          </Link>
        )}

        <input
          type="text"
          ref={this.inputProfileUrl}
          value={`https://www.daisie.com/${user.username}`}
          className="absolute"
          style={{ left: '-9999px', top: '-9999px' }}
        />

        {isNotMe && (
          <>
            <button
              type="button"
              className={className(undefined, true)}
              data-ui-test-id={
                isUiTest() ? 'show-report-user-modal-portal' : undefined
              }
              onClick={() => {
                if (isMobile) {
                  this.props.updatePortal({
                    portal: GlobalPortal.report_user,
                    data: {
                      user,
                      returnTo: this.returnToMenu,
                    },
                  });
                } else {
                  this.props.updateModal({
                    modal: GlobalModal.report_user,
                    data: {
                      user,
                    },
                    className: 'page-modal__content--medium',
                  });

                  if (this.props.toggle) {
                    this.props.toggle();
                  }
                }
              }}
            >
              <Icon id="warning" size="s" className={classNameIcon} />
              <span className={classNameText}>
                {t('Report')} @{user.username}
              </span>
            </button>

            <button
              type="button"
              className={className(isNotMe, true)}
              onClick={() => {
                if (isMobile) {
                  this.props.updatePortal({
                    portal: GlobalPortal.block_user,
                    data: {
                      user,
                      returnTo: this.returnToMenu,
                    },
                  });
                } else {
                  this.props.updateModal({
                    modal: GlobalModal.block_user,
                    data: {
                      user,
                    },
                  });

                  if (this.props.toggle) {
                    this.props.toggle();
                  }
                }
              }}
              data-ui-test-id={
                isUiTest() ? 'show-block-user-modal-portal' : undefined
              }
            >
              <Icon id="block-user" size="s" className={classNameIcon} />
              <span className={classNameText}>
                {hasBlocked ? t('Unblock') : t('Block')} @{user.username}
              </span>
            </button>
          </>
        )}

        {!isNotMe && isMobile && (
          <>
            <Link
              to={routes.following}
              className={className(!isNotMe)}
              onClick={() => {
                if (isMobile) {
                  this.props.updatePortal({
                    portal: undefined,
                  });
                } else {
                  if (this.props.toggle) {
                    this.props.toggle();
                  }
                }
              }}
            >
              <Icon id="collaboration" size="s" className={classNameIcon} />
              <span className={classNameText}>{t('Following')}</span>
            </Link>

            <Link
              to={routes.userWorkshops}
              className={className(!isNotMe)}
              onClick={() => {
                if (isMobile) {
                  this.props.updatePortal({
                    portal: undefined,
                  });
                } else {
                  if (this.props.toggle) {
                    this.props.toggle();
                  }
                }
              }}
            >
              <Icon id="category-workshop" size="s" className={classNameIcon} />
              <span className={classNameText}>{t('Your Workshops')}</span>
            </Link>
          </>
        )}

        {isMobile && (
          <Button
            type={ButtonType.action}
            className="mt24 u-1/1"
            onClick={() => {
              if (toggle) {
                toggle();
              }
            }}
            text={t('Done')}
            buttonStyle={ButtonStyle.light}
          />
        )}
      </div>
    );
  };
}

export const UserProfileMenu = connect(['user', 'auth', 'sizing'], () => ({
  ...modalActions(),
  ...portalActions(),
}))(UserProfileMenuComponent);
