import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'unistore/react';
import widont from 'widont';

import { FormValidator } from '@/components/global/Forms/FormValidator/FormValidator';
import { Input } from '@/components/global/Forms/Input/Input';
import { Link } from '@/components/global/Link/Link';
import { PhoneNumberCode } from '@/components/global/PhoneNumberCode/PhoneNumberCode';
import { Toast } from '@/components/global/Toaster/Toast';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { AuthType } from '@/enums/AuthType';

import {
  AUTH_PHONE_CODE_FAIL,
  LOGIN_GOOGLE_SSO_FAIL,
  LOGIN_PHONE_USER_FAIL,
  LOGIN_USER_FAIL,
} from '@/messages/errors';

import { routes } from '@/routes';

import { authActions } from '@/store/modules/auth';

import { Http } from '@/utils/api/Http';
import { GOOGLE_SIGN_IN_BUTTON_STYLING } from '@/utils/constants';
import { isDesktop } from '@/utils/DOM/sizing';
import { enablePhoneSignUpLogin } from '@/utils/featureToggles';
import { t } from '@/utils/i18n/i18n';
import { track } from '@/utils/mixpanel/mixpanel';
import { c } from '@/utils/strings/c';

interface Props
  extends AuthActions,
    AuthState,
    SizingState,
    RouteComponentProps {
  inputClassName?: string;
}

interface State {
  errorMessage?: string;
  loading: boolean;
  phoneAuthAttemptUuid?: string;
  isResendClicked: boolean;
  chosenCountry: string;
  step: number;
}

class LoginFormComponent extends React.Component<Props, State> {
  public state: State = {
    errorMessage: undefined,
    loading: false,
    phoneAuthAttemptUuid: undefined,
    isResendClicked: false,
    chosenCountry: '44',
    step: 0,
  };

  public componentDidMount = async () => {
    // @ts-ignore
    if (!google) return;

    // @ts-ignore
    google.accounts.id.initialize({
      client_id: process.env.DAISIE_SSO_GOOGLE_CLIENT_ID_WEB,
      callback: this.signInWithGoogle,
    });

    // @ts-ignore
    google.accounts.id.renderButton(
      document.getElementById('google-sign-in'),
      GOOGLE_SIGN_IN_BUTTON_STYLING
    );
  };

  public incrementStep = () => {
    this.setState({ step: this.state.step + 1 });
  };

  public decrementStep = () => {
    this.setState({ step: this.state.step - 1 });
  };

  private switchToChosenCountry = (countryCallingCode: string) => {
    this.setState({ chosenCountry: countryCallingCode });
  };

  private getAuthPhoneCode = async (phoneNum: string) => {
    this.setState({ loading: true });

    try {
      const phoneNumber = `+${this.state.chosenCountry}${phoneNum}`;

      const { data } = await new Http('/users/phoneAuthCode').post<
        APIObject<APIPhoneAuthCode>
      >({ phoneNumber });

      this.setState({ phoneAuthAttemptUuid: data.id });

      if (!this.state.isResendClicked) {
        this.incrementStep();
      }
    } catch (e) {
      new Toast({ body: AUTH_PHONE_CODE_FAIL, failure: true }).dispatch();
    }

    this.setState({ loading: false });
  };

  private submit = async (credentials: any) => {
    const logInParams = credentials.username
      ? {
          username: credentials.username,
          password: credentials.password,
        }
      : {
          phoneNumber: `+${this.state.chosenCountry}${credentials.phoneNumber}`,
          phoneAuthAttemptUuid: this.state.phoneAuthAttemptUuid,
          authCode: credentials.authCode,
        };

    try {
      this.setState({
        loading: true,
      });
      const timeout = new Promise((_, reject) => setTimeout(reject, 5000));

      await Promise.race([this.props.login(logInParams), timeout]);
    } catch (e) {
      this.setState({
        errorMessage: credentials.phoneNumber
          ? LOGIN_PHONE_USER_FAIL
          : LOGIN_USER_FAIL,
        loading: false,
      });
    }
  };

  private signInWithGoogle = async (e: any) => {
    try {
      const idToken = e['credential'];
      this.setState({
        loading: true,
      });

      if (idToken) {
        const timeout = new Promise((_, reject) => setTimeout(reject, 5000));
        await Promise.race([
          this.props.login({ idToken }, AuthType.GoogleSSO),
          timeout,
        ]);
      } else {
        this.setState({
          errorMessage: LOGIN_GOOGLE_SSO_FAIL,
          loading: false,
        });
      }
    } catch (e) {
      this.setState({
        errorMessage: LOGIN_GOOGLE_SSO_FAIL,
        loading: false,
      });
    }
  };

  public render = () => {
    const { errorMessage, chosenCountry, step } = this.state;
    const {
      sizing: { isMobile },
      location: { search },
      inputClassName = 'input--dark u-white b--none mb12 u-border-radius pv20 pl32',
    } = this.props;

    const queryParams = new URLSearchParams(search);
    const to = queryParams.get('to') || '';

    return (
      <div>
        <FormValidator
          submit={this.submit}
          initialState={{
            username: '',
            password: '',
            authCode: '',
            phoneNumber: '',
          }}
        >
          {({
            submit,
            changeValidation,
            errors,
            state = {},
            updateForm,
          }: {
            submit(): void;
            changeValidation(): void;
            updateForm(key: string): (event: any) => void;
            updateFormArray: any;
            errors: any;
            state: any;
          }) => (
            <form onSubmit={submit} onChange={changeValidation}>
              {step === 0 && (
                <div className="mh128@m ph20@m">
                  <Input
                    id="username"
                    placeholder={t('Email or Username')}
                    type="text"
                    onChange={updateForm('username')}
                    error={errors.username}
                    value={state.username}
                    disabled={
                      state.phoneNumber ? true : false || this.state.loading
                    }
                    autoComplete="off"
                    className="u-lowercase"
                    inputClassName={inputClassName}
                    autoFocus={isDesktop()}
                    uiTestId="login-username"
                  />
                  {enablePhoneSignUpLogin() && (
                    <>
                      <div className="u-line-behind u-line-behind--dark f-text-4 u-grey u-text-center mb12 ">
                        <span>{t('or')}</span>
                      </div>
                      <div>
                        <div
                          className={c(
                            'absolute mt12 ml12 br--dark-grey u-z-index-1 mvauto u-white u-z-index-2',
                            {
                              'br--sad-grey': state.username,
                            }
                          )}
                        >
                          <PhoneNumberCode
                            chosenCountry={chosenCountry}
                            switchToChosenCountry={this.switchToChosenCountry}
                            usernameState={state.username}
                          />
                        </div>
                        <Input
                          id="phoneNumber"
                          placeholder={t('Phone number')}
                          type={isMobile ? 'tel' : 'number'}
                          required={true}
                          onChange={updateForm('phoneNumber')}
                          error={errors.phoneNumber}
                          value={state.phoneNumber}
                          autoComplete="off"
                          disabled={
                            state.username ? true : false || this.state.loading
                          }
                          className="u-lowercase dds-input__phone-code"
                          inputClassName={inputClassName}
                          uiTestId="login-username"
                        />
                      </div>
                    </>
                  )}
                </div>
              )}

              {step === 1 && state.username && (
                <div>
                  <div className="mb32@l u-text-center mt32@s u-white">
                    <div className="mb24">
                      {t('Log in as ')}
                      <span className="u-bold">{state.username}</span>
                    </div>
                    <div
                      className={c('u-grid', {
                        'u-grid--6': !isMobile,
                      })}
                    >
                      {!isMobile && (
                        <Button
                          text=""
                          type={ButtonType.action}
                          onClick={() => {
                            this.decrementStep();
                            this.setState({ errorMessage: undefined });
                            state.username = '';
                          }}
                          className="u-inline ml32@m"
                          iconId="chevron-left"
                          buttonStyle={ButtonStyle.outlineLight}
                          size={ButtonSize.s}
                        />
                      )}
                      <div className="mh64@m ph20@m">
                        <Input
                          id="password"
                          placeholder={t('Password')}
                          type="password"
                          required={true}
                          onChange={updateForm('password')}
                          error={errors.password}
                          value={state.password}
                          disabled={this.state.loading}
                          uiTestId="login-password"
                          autoFocus={isDesktop()}
                          inputClassName="input--dark u-white b--none u-border-radius pv20 u-text-center"
                        />
                      </div>
                      <span className="mr64@m" />
                    </div>

                    <div
                      className={c('f-text-3 u-grey u-inline-block mt16', {
                        'u-blue-alert': errorMessage,
                      })}
                    >
                      {errorMessage && (
                        <span className="f-text-3">{widont(errorMessage)}</span>
                      )}

                      <Link
                        to={{ name: 'forgot' }}
                        className={c('u-link-grey u-underline u-block', {
                          mt12: errorMessage,
                        })}
                        onClick={() => {
                          track('open_forgot_password');
                        }}
                      >
                        {t('Forgot your password?')}
                      </Link>
                    </div>
                  </div>
                </div>
              )}

              {step === 0 && (
                <div className="ph128@m pb64@m mh20@m">
                  <Button
                    type={ButtonType.submit}
                    disabled={
                      this.state.loading ||
                      (state.username && state.phoneNumber) ||
                      (!state.username && !state.phoneNumber)
                    }
                    isLoading={this.state.loading}
                    text={t('Continue')}
                    className="u-1/1 mt32 dds-button__signup-login"
                    onClick={() => {
                      if (!!state.username) {
                        this.incrementStep();
                      } else {
                        this.getAuthPhoneCode(state.phoneNumber);
                      }
                    }}
                  />
                  <div className="u-line-behind u-line-behind--dark f-text-4 u-grey u-text-center mv24 ">
                    <span>{t('or')}</span>
                  </div>
                  <div
                    id="google-sign-in"
                    className="google-sso-container u-flex u-justify-center"
                  />
                </div>
              )}

              {step === 1 && state.phoneNumber && (
                <div className="mb80@l u-text-center">
                  <div className="mv24 u-grey">
                    {t("Enter the code we've sent by SMS")}
                  </div>

                  <div
                    className={c('u-grid', {
                      'u-grid--6': !isMobile,
                    })}
                  >
                    {!isMobile && (
                      <Button
                        text=""
                        type={ButtonType.action}
                        onClick={() => {
                          this.decrementStep();
                          this.setState({ errorMessage: undefined });
                          state.phoneNumber = '';
                        }}
                        className="u-inline ml32@m"
                        iconId="chevron-left"
                        buttonStyle={ButtonStyle.outlineLight}
                        size={ButtonSize.s}
                      />
                    )}
                    <div className="mh64@m ph20@m">
                      <Input
                        id="authCode"
                        placeholder={t('Verification Code')}
                        type="tel"
                        required={true}
                        onChange={updateForm('authCode')}
                        error={errors.authCode}
                        value={state.authCode}
                        maxLength={6}
                        disabled={this.state.loading}
                        autoComplete="off"
                        uiTestId="login-password"
                        inputClassName="input--dark u-white b--none u-border-radius pv20 u-text-center"
                      />
                    </div>
                    <span className="mr64@m" />
                  </div>

                  <div
                    className={c(
                      'f-text-5 u-grey u-inline-block mt16 ph128@m',
                      {
                        'u-blue-alert': errorMessage,
                      }
                    )}
                  >
                    {errorMessage ? (
                      <span>{`${errorMessage} `}</span>
                    ) : (
                      <>{t("Didn't get your code? ")}</>
                    )}
                    <span
                      className={c('u-underline u-cursor-pointer', {
                        'u-link-grey': !errorMessage,
                        'u-link-blue-alert': errorMessage,
                      })}
                      onClick={async () => {
                        this.setState({ isResendClicked: true });
                        await this.getAuthPhoneCode(state.phoneNumber);
                      }}
                    >
                      {errorMessage ? 'Resend code?' : 'Resend'}
                    </span>
                  </div>
                </div>
              )}

              {step === 1 && (
                <div className="ph128@m pb64@m mh20@m">
                  <Button
                    type={ButtonType.submit}
                    disabled={
                      this.state.loading ||
                      (state.authCode.length < 6 && !state.password)
                    }
                    isLoading={this.state.loading}
                    uiTestId="login-submit"
                    text={t('Log in')}
                    className="u-1/1 mt32 dds-button__signup-login"
                  />
                </div>
              )}

              {isMobile && step === 1 && (
                <div
                  onClick={() => {
                    this.decrementStep();
                  }}
                  className="u-grey u-underline u-text-center mt20 f-text-3"
                >
                  {t('Back')}
                </div>
              )}
            </form>
          )}
        </FormValidator>

        <p className="u-grey u-text-center f-text-3">
          {t(`Don't have an account?`)}{' '}
          <Link
            to={`${routes.register}${
              !!to ? `?to=${encodeURIComponent(to)}` : ''
            }`}
            className="u-link-brand--dark u-underline"
          >
            {t('Sign Up')}
          </Link>
        </p>
      </div>
    );
  };
}

export const LoginForm = connect(
  ['auth', 'sizing'],
  authActions
)(withRouter(LoginFormComponent));
