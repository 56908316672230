import * as React from 'react';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { GlobalPortal } from '@/enums/GlobalPortal';

import { c } from '@/utils/strings/c';

interface Props {
  children: React.ReactNode;
  toggle?(): any;
  close?(): any;
  className?: string;
  type?: GlobalPortal;
  isDarkMode?: boolean;
  fullHeight?: boolean;
  horizontalTransition?: boolean;
}

class SettingsOverlay extends React.Component<Props> {
  public render() {
    const {
      close,
      className = '',
      type,
      isDarkMode,
      fullHeight,
      horizontalTransition = false,
    } = this.props;

    return (
      <div className={c(['settings-overlay', className])} data-portal={type}>
        <ReactCSSTransitionGroup
          transitionName={c('', {
            't-settings': !horizontalTransition,
            't-settings--horizontal': horizontalTransition,
          })}
          transitionAppear={true}
          transitionAppearTimeout={500}
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
          component="div"
        >
          {this.props.children && (
            <button
              type="button"
              onClick={close}
              onTouchStart={close}
              className="settings-overlay__close"
            >
              Close
            </button>
          )}
          {this.props.children && (
            <div
              className={c('settings-overlay__container u-scrollbar-disabled', {
                'settings-overlay__container--dark': isDarkMode,
                'settings-overlay__container--full-height': fullHeight,
              })}
            >
              {this.props.children}
            </div>
          )}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}

export { SettingsOverlay };
