export enum AttendButtonState {
  loading = 'loading',
  startFreeTrial = 'start_free_trial',
  unsubscribed = 'unsubscribed',
  inProgress = 'in_progress',
  ended = 'ended',
  hosting = 'hosting',
  attending = 'attending',
  notAttending = 'not_attending',
  onDemand = 'on_demand',
  isFull = 'is_full',
  isFree = 'is_free',
}
