import * as React from 'react';

import { c } from '@/utils/strings/c';

interface Props {
  className?: string;
  progressPercent: number;
}

export const ProgressBar = (props: Props) => {
  const { className = '', progressPercent } = props;

  return (
    <div className={c(['dds-progress-bar', className])}>
      <div
        className="dds-progress-bar__fill"
        style={{ width: `${progressPercent}%` }}
      />
    </div>
  );
};
