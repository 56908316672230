import * as React from 'react';

import { Redirect } from 'react-router-dom';
import { connect } from 'unistore/react';

import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { SubscriptionPlanSelector } from '@/components/subscription/SubscriptionPlanSelector/SubscriptionPlanSelector';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';
import { CheckedBulletList } from '@/components/design_system/CheckedBulletList/CheckedBulletList';

import { SubscriptionInterval } from '@/enums/SubscriptionInterval';
import { SubscriptionPlanSelectorStyle } from '@/enums/SubscriptionPlanSelectorStyle';

import imgHeroCallingAllCreators from '@/pages/LoggedOutHomepage/calling-all-creators-hero.png';

import { routes } from '@/routes';

import { findProductAndPrice } from '@/store/helpers/subscriptionHelpers';

import { t } from '@/utils/i18n/i18n';
import { getDisplayPrice } from '@/utils/strings/currency';

interface Props extends SubscriptionState, AuthState, SizingState {
  formOnly?: boolean;
}

interface State {
  selectedSubscriptionInterval?: SubscriptionInterval;
  redirectToHome: boolean;
}

class LoggedOutHomepageVariantStudentsComp extends React.Component<
  Props,
  State
> {
  public state: State = {
    selectedSubscriptionInterval: SubscriptionInterval.year,
    redirectToHome: false,
  };

  public componentDidMount = () => {
    const {
      auth: { isAuthorised },
    } = this.props;

    if (isAuthorised) {
      this.setState({ redirectToHome: true });
      return;
    }
  };

  public render = () => {
    const {
      subscription: { productsAndPrices, userCurrency },
      formOnly,
      sizing: { isMobile },
    } = this.props;

    const { selectedSubscriptionInterval, redirectToHome } = this.state;

    if (redirectToHome) {
      return <Redirect to={routes.home} />;
    }

    const yearlyProduct: SubscriptionProductPrice | undefined =
      findProductAndPrice({
        productsAndPrices,
        currency: userCurrency,
        recurringInterval: SubscriptionInterval.year,
      });

    if (!yearlyProduct) {
      return null;
    }

    const continueLink: string =
      selectedSubscriptionInterval === SubscriptionInterval.year
        ? routes.optimisedJoinFlowSignUpYearly
        : routes.optimisedJoinFlowSignUpMonthly;

    const planSelectorForm = (
      <>
        <SubscriptionPlanSelector
          selectorStyle={
            SubscriptionPlanSelectorStyle.loggedOutHomepageHorizontal
          }
          className="mb32"
          selectedSubscriptionInterval={selectedSubscriptionInterval}
          setSelectedSubscriptionInterval={(
            subscriptionInterval?: SubscriptionInterval
          ) => {
            this.setState({
              selectedSubscriptionInterval: subscriptionInterval,
            });
          }}
        />

        <Button
          type={ButtonType.link}
          linkTo={continueLink}
          buttonStyle={ButtonStyle.default}
          size={ButtonSize.xl}
          text={t('Get started')}
          className="u-fit-content mhauto"
        />
      </>
    );

    if (formOnly) {
      return planSelectorForm;
    }

    return (
      <div className="wrap u-flex u-flex-column-reverse@s u-h-100@m">
        <div className="u-white u-1/1 u-1/2@m u-flex u-align-center u-justify-center">
          <div className="u-1/1">
            <h1 className="f-lynstone-bold-1a u-white mb32 u-text-center@s">
              <span className="u-block">{t('More learning')}</span>
              <span className="u-block">{t('for less.')}</span>
            </h1>

            <p className="f-lynstone-bold-2 mb44 u-text-center@s">
              {t('Just')}{' '}
              <span className="u-alert u-line-through">
                {userCurrency === 'gbp'
                  ? t('£59.99')
                  : userCurrency === 'eur'
                  ? t('59.99 €')
                  : t('$99.99')}
              </span>{' '}
              <span className="u-go">
                {getDisplayPrice(userCurrency, yearlyProduct.amount)}{' '}
                {userCurrency === 'gbp'
                  ? t('GBP')
                  : userCurrency === 'eur'
                  ? t('EUR')
                  : t('USD')}
              </span>{' '}
              {t('per year')}
            </p>

            <CheckedBulletList
              className="mb48"
              items={
                isMobile
                  ? [
                      { text: t('Join live, interactive classes') },
                      { text: t('Meet likeminded creators') },
                      { text: t('Build a schedule on your terms') },
                    ]
                  : [
                      {
                        text: t(
                          'Join live, daily classes hosted by industry-leading experts'
                        ),
                      },
                      {
                        text: t(
                          'Meet creators across film, music, fashion, design & more'
                        ),
                      },
                      {
                        text: t(
                          'Build your own schedule with classes that fit your routine'
                        ),
                      },
                    ]
              }
            />

            <div className="mr128@m u-flex@m u-flex-column@m">
              {planSelectorForm}
            </div>
          </div>
        </div>

        <div className="u-1/1 u-1/2@m u-flex u-align-center u-justify-center">
          <ImageLoader src={imgHeroCallingAllCreators} alt="" className="" />
        </div>
      </div>
    );
  };
}

export const LoggedOutHomepageVariantStudents = connect(
  ['subscription', 'auth', 'sizing'],
  () => ({})
)(LoggedOutHomepageVariantStudentsComp);
