import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'unistore/react';

import { Icon } from '@/components/global/Icon/Icon';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { NAVIGATION_SEARCH_BAR_PLACEHOLDER_SHORT } from '@/messages/copy';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

import { MAX_NAME, MAX_PROJECT_TITLE } from '@/utils/constants';
import { c } from '@/utils/strings/c';

interface Props extends RouteComponentProps, SizingState {
  className?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  searchType?: string;
  style?: string;
  darkMode?: boolean;
  mobileNavOpen?: boolean;
  toggleBurgerMenu: () => void;
}

interface State {
  query: string;
  prevQuery?: string;
}

class SearchBarNavComponent extends React.Component<Props, State> {
  private inputRef: React.RefObject<HTMLInputElement>;

  public state: State = {
    query: '',
    prevQuery: undefined,
  };

  constructor(props: Props) {
    super(props);

    this.inputRef = React.createRef();
  }

  public componentDidMount = () => {
    const params = new URLSearchParams(this.props.location.search);

    const query = params.get('s') || '';

    if (query) {
      this.setState({ query: decodeURI(query), prevQuery: decodeURI(query) });
    }
  };

  public componentDidUpdate = (prevProps: Props) => {
    const { searchType } = this.props;
    const { searchType: prevSearchType } = prevProps;

    const prevParams = new URLSearchParams(prevProps.location.search);
    const currentParams = new URLSearchParams(this.props.location.search);

    const prevQuery = prevParams.get('s') || '';
    const currentQuery = currentParams.get('s') || '';

    if (prevQuery !== currentQuery && prevQuery !== '' && currentQuery !== '') {
      this.setState({
        query: decodeURI(currentQuery),
        prevQuery: decodeURI(prevQuery),
      });
    }

    const prevPathname = prevProps.location.pathname;
    const currentPathname = this.props.location.pathname;

    if (
      prevPathname !== currentPathname &&
      !currentPathname.includes('/search')
    ) {
      this.setState({ prevQuery: '', query: '' });

      if (this.inputRef.current) {
        this.inputRef.current.blur();
      }
    }

    if (
      prevPathname !== currentPathname &&
      currentPathname.includes('/search') &&
      currentQuery
    ) {
      this.setState({ query: decodeURI(currentQuery) });
    }

    if (searchType && prevSearchType && searchType !== prevSearchType) {
      this.setState({ query: currentQuery });
    }
  };

  private search = () => {
    const { query, prevQuery } = this.state;
    const {
      sizing: { isMobile },
    } = this.props;

    if (!query || (prevQuery && prevQuery === query && !isMobile)) return;

    const params = new URLSearchParams(window.location.href);

    const type = params.get('type') || '';
    const industry = params.get('industry') || '';
    const locationName = params.get('locationName') || '';
    const location = params.get('location') || '';
    const role = params.get('role') || '';

    const queryParams = new URLSearchParams();

    queryParams.set('s', query);

    if (type) {
      queryParams.set('type', type);
    }

    if (industry) {
      queryParams.set('industry', industry);
    }

    if (locationName) {
      queryParams.set('locationName', locationName);
    }

    if (location) {
      queryParams.set('location', location);
    }

    if (role) {
      queryParams.set('role', role);
    }

    this.props.history.push(`/search?${queryParams.toString()}`);

    this.setState({ prevQuery: query });
  };

  private clearQuery = () => {
    this.setState({
      query: '',
      prevQuery: '',
    });
  };

  public render = () => {
    const { className = '', style, darkMode, mobileNavOpen } = this.props;
    const { query } = this.state;

    return (
      <div
        className={c([
          'c-search-bar-nav c-search-bar-nav__animation',
          className,
        ])}
      >
        <form
          action="."
          onSubmit={(e: any) => e.preventDefault()}
          className={c('u-flex u-align-center u-border-radius', {
            'pv8 u-border-radius': darkMode,
            'u-bg-charcoal-grey': style === 'dark-mode' || darkMode,
            'u-bg-very-light-grey': style === 'default' || 'transparent-light',
          })}
        >
          <Icon
            id="search"
            width="16px"
            height="17px"
            className={c('u-grey ml12 u-cursor-default', {
              'u-white': style === 'dark-mode' || darkMode,
              ml16: mobileNavOpen,
            })}
          />
          <input
            autoFocus
            data-ui-test-id={isUiTest() ? 'search-bar' : undefined}
            ref={this.inputRef}
            type="search"
            style={{ padding: '0.7em' }}
            placeholder={NAVIGATION_SEARCH_BAR_PLACEHOLDER_SHORT}
            className={c('c-search-bar__input dds-input pl20 p-24@m f-text-3', {
              'dds-input--theme-dark--without-border u-white':
                style === 'dark-mode' || darkMode,
            })}
            onKeyUp={(e: any) => {
              if (e.keyCode === 13) {
                this.search();
                if (mobileNavOpen) {
                  this.props.toggleBurgerMenu();
                }
              }
            }}
            onChange={(e: any) => this.setState({ query: e.target.value })}
            onFocus={() => {
              if (this.props.onFocus) {
                this.props.onFocus();
              }
            }}
            onBlur={() => {
              if (this.props.onBlur) {
                this.props.onBlur();
              }
            }}
            value={query}
            maxLength={
              MAX_PROJECT_TITLE > MAX_NAME ? MAX_PROJECT_TITLE : MAX_NAME
            }
          />

          {mobileNavOpen && (
            <Button
              type={ButtonType.action}
              onClick={() => {
                this.search();
                this.props.toggleBurgerMenu();
              }}
              iconId="search"
              size={ButtonSize.s}
              className="c-search-bar__submit"
            />
          )}

          {!mobileNavOpen && (
            <button
              type="button"
              onClick={this.clearQuery}
              className={c(
                'c-search-bar-nav__clear u-flex u-align-center opacity-0 mt20 mr16',
                {
                  'opacity-1': query.length > 0,
                }
              )}
            >
              <Icon
                id="clear"
                width="10px"
                height="10px"
                className={c('pl40', {
                  'u-white': style === 'dark-mode' || darkMode,
                  'u-sad-grey': style === 'transparent-light',
                })}
              />
            </button>
          )}
        </form>
      </div>
    );
  };
}

export const SearchBarNav = connect(['sizing'], () => ({}))(
  withRouter(SearchBarNavComponent)
);
