import delve from 'dlv';

import { Toast } from '@/components/global/Toaster/Toast';

import {
  BLOCK_USER_SUCCESS,
  UNBLOCK_USER_SUCCESS,
  USER_UNFOLLOWED,
} from '@/messages/messages';

import {
  getUserFromData,
  getUsersFromData,
  getWorkshopsFromData,
} from '@/store/helpers';
import { getRoomsFromData } from '@/store/helpers/roomHelpers';

import { Http } from '@/utils/api/Http';
import { DISCUSSION_CATEGORY_NAME } from '@/utils/constants';
const userState: UserState = {};

const userActions = (store?: any) => ({
  getUserAttributesByUsername: async (
    state: GlobalStoreState,
    username: string
  ) => {
    let user;

    try {
      const { data } = await new Http(
        `/users/${username.toLowerCase()}/attributes`
      ).get<APIObject<APIUser>>();

      user = getUserFromData(data);

      // If we've changed routes in the meantime, get out
      // except if we're fetching the currently logged in user from the account settings
      const path = window.location.pathname.split('/');
      const usernamePath = path[1] || '';
      const loggedInUsername = state.auth.user
        ? state.auth.user.username
        : null;
      if (
        user.username !== usernamePath &&
        usernamePath === 'account' &&
        user.username !== loggedInUsername
      ) {
        return;
      }
    } catch (e) {
      user = false;
    }

    return {
      user,
    };
  },

  getUserProjectsByUsername: async (
    state: GlobalStoreState,
    username: string
  ) => {
    let user: User | false;

    const notCategories = [];

    if (
      state.organisedCategories.discussionCategory &&
      state.organisedCategories.discussionCategory.name
    ) {
      notCategories.push(state.organisedCategories.discussionCategory.name);
    }

    if (
      state.organisedCategories.workshopRoomCategory &&
      state.organisedCategories.workshopRoomCategory.name
    ) {
      notCategories.push(state.organisedCategories.workshopRoomCategory.name);
    }

    try {
      const { data } = await new Http(
        `/users/${username.toLowerCase()}/projects?pageNumber=1&pageSize=500&notCategories=${encodeURIComponent(
          notCategories.join(',')
        )}`
      ).get<APIObject<APIUser>>();

      if (!data.relationships) return;

      user = getUserFromData(data);

      const projectsData = delve(data, 'relationships.projects.data');
      const justProjectsData = projectsData.filter(
        (project: APIProject) =>
          delve(project, 'relationships.categories.data') &&
          !delve(project, 'relationships.categories.data').find(
            (category: APIProjectCategory) =>
              category.attributes.name === DISCUSSION_CATEGORY_NAME
          )
      );
      const projects = getRoomsFromData(
        justProjectsData,
        state.cinematicBackground.curatedImages
      );

      // If we've changed routes in the meantime, get out
      // except if we're fetching the currently logged in user from the account settings
      const path = window.location.pathname.split('/');
      const usernamePath = path[1] || '';
      const loggedInUsername = state.auth.user
        ? state.auth.user.username
        : null;

      if (
        user.username !== usernamePath &&
        usernamePath === 'account' &&
        user.username !== loggedInUsername
      ) {
        throw new Error('User profile changed');
      }

      user = {
        ...store.getState().user,
        projects,
      };
    } catch (e) {
      user = false;
    }

    return {
      user,
    };
  },

  getUserFollowingByUsername: async (
    state: GlobalStoreState,
    username: string,
    pageNumber?: number,
    pageSize?: number
  ) => {
    let user: User | false;

    const page = pageNumber || 1;

    try {
      const { data } = await new Http(
        `/users/${username.toLowerCase()}/following?pageNumber=${
          page || 1
        }&pageSize=${pageSize || 9}`
      ).get<APIObject<APIUser>>();

      if (!data.relationships) return;

      user = getUserFromData(data);

      const followingData = getUsersFromData(
        delve(data, 'relationships.following.data')
      );

      // If we've changed routes in the meantime, get out
      // except if we're fetching the currently logged in user from the account settings
      const path = window.location.pathname.split('/');
      const usernamePath = path[1] || '';
      const loggedInUsername = state.auth.user
        ? state.auth.user.username
        : null;

      if (
        user.username !== usernamePath &&
        usernamePath === 'account' &&
        user.username !== loggedInUsername
      ) {
        throw new Error('User profile changed');
      }

      user = {
        ...store.getState().user,
        following: {
          ...store.getState().user.following,
          [page]: followingData,
        },
      };
    } catch (e) {
      user = false;
    }

    return {
      user,
    };
  },

  getUserWorkshopsByUsername: async (state: any, username: string) => {
    try {
      // TODO: remove this polyfill when attendingWorkshops only takes into account
      // the workshops the user is attending when marking a workshop as passed
      const { data } = await new Http(
        `/users/${username}/attendingWorkshops?pageNumber=1&pageSize=500`
      ).get<APIObject>();

      if (!data.relationships) {
        throw new Error();
      }

      const allAttendingWorkshops = getWorkshopsFromData(
        data.relationships.workshops.data
      );

      const upcoming: Workshop[] = allAttendingWorkshops.filter(
        (w: Workshop) => {
          const attendingBatches: number = w.batches.filter(
            (b: WorkshopBatch) => b.isAttending
          ).length;

          const attendingElapsedBatches: number = w.batches.filter(
            (b: WorkshopBatch) => b.isAttending && b.hasElapsed
          ).length;

          return attendingElapsedBatches < attendingBatches;
        }
      );

      const past: Workshop[] = allAttendingWorkshops.filter((w: Workshop) => {
        const attendingBatches: number = w.batches.filter(
          (b: WorkshopBatch) => b.isAttending
        ).length;

        const attendingElapsedBatches: number = w.batches.filter(
          (b: WorkshopBatch) => b.isAttending && b.hasElapsed
        ).length;

        return attendingElapsedBatches === attendingBatches;
      });

      return {
        user: {
          ...store.getState().user,
          attendingWorkshops: {
            upcoming,
            past,
          },
        },
      };
    } catch (e) {
      throw new Error(e);
    }
  },

  // deprecated
  getUserByUsername: async (state: GlobalStoreState, username: string) => {
    let user;

    try {
      const { data } = await new Http(`/users/${username.toLowerCase()}`).get<
        APIObject<APIUser>
      >();

      user = getUserFromData(data);

      // If we've changed routes in the meantime, get out
      // except if we're fetching the currently logged in user from the account settings
      const path = window.location.pathname.split('/');
      const usernamePath = path[1] || '';
      const loggedInUsername = state.auth.user
        ? state.auth.user.username
        : null;
      if (
        user.username !== usernamePath &&
        usernamePath === 'account' &&
        user.username !== loggedInUsername
      ) {
        return;
      }
    } catch (e) {
      user = false;
    }

    return {
      user,
    };
  },

  clearUser: () => ({
    user: undefined,
  }),

  clearUserProjects: async (state: GlobalStoreState) => ({
    user: {
      ...state.user,
      projects: undefined,
    },
  }),

  clearUserFollowing: async (state: GlobalStoreState) => ({
    user: {
      ...state.user,
      following: undefined,
    },
  }),

  clearUserWorkshops: async (state: GlobalStoreState) => ({
    user: {
      ...state.user,
      attendingWorkshops: undefined,
    },
  }),

  updateFollowStatus: async (state: GlobalStoreState, isFollowing: boolean) => {
    if (state.user) {
      return {
        user: {
          ...state.user,
          isFollowing,
        },
      };
    } else {
      return state;
    }
  },

  unfollowUser: async (state: GlobalStoreState, user?: string) => {
    const userToUnfollow = user || state.user;

    if (!userToUnfollow) {
      return;
    }

    try {
      await new Http(`/users/${user}/unfollow`).post();
      new Toast({ body: USER_UNFOLLOWED }).dispatch();
    } catch (e) {
      throw new Error();
    }

    if (state.user) {
      return {
        user: {
          ...state.user,
          following: false,
        },
      };
    }
    return;
  },

  unblockUser: async (state: GlobalStoreState, user?: User) => {
    const userToUnblock = user || state.user;

    if (!userToUnblock) {
      return;
    }
    let hasBlocked = userToUnblock.hasBlocked;

    try {
      await new Http(`/users/${userToUnblock.username}/unblock`).post();
      hasBlocked = false;
      new Toast({ body: UNBLOCK_USER_SUCCESS }).dispatch();
    } catch (e) {
      throw new Error();
    }

    if (state.user) {
      return {
        user: {
          ...state.user,
          hasBlocked,
        },
      };
    }

    return;
  },

  blockUser: async (state: GlobalStoreState, user?: User) => {
    const userToBlock = user || state.user;

    if (!userToBlock) {
      return;
    }

    let hasBlocked = userToBlock.hasBlocked;

    try {
      await new Http(`/users/${userToBlock.username}/block`).post();
      hasBlocked = true;
      new Toast({ body: BLOCK_USER_SUCCESS }).dispatch();
    } catch (e) {
      throw new Error();
    }

    if (state.user) {
      return {
        user: {
          ...state.user,
          hasBlocked,
        },
      };
    }

    return;
  },
});

export { userState, userActions };
