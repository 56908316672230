import * as React from 'react';

import { t } from '@/utils/i18n/i18n';

interface Props {
  start: Date;
  end: Date;
  className?: string;
  useShortMinutesLabel?: boolean;
}

interface State {
  minutesLeft?: number;
}

class TimeRemaining extends React.Component<Props, State> {
  private refreshTimer?: NodeJS.Timeout;

  public state: State = {
    minutesLeft: undefined,
  };

  public componentDidMount = () => {
    this.refresh();
  };

  public componentWillUnmount = () => {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
  };

  private refresh = () => {
    const { start, end } = this.props;
    const now = new Date();

    if (now.getTime() >= end.getTime()) {
      // This event has passed, so don't worry about refreshing
      this.setState({ minutesLeft: undefined });
      return;
    }

    if (now.getTime() < start.getTime()) {
      // Event could start while the user is viewing, so hide the component
      // but poll for the event starting
      this.setState({ minutesLeft: undefined });
    }

    if (now.getTime() >= start.getTime() && now.getTime() < end.getTime()) {
      const timeElapsed = now.getTime() - start.getTime();
      const duration = end.getTime() - start.getTime();

      this.setState({
        minutesLeft: Math.ceil((duration - timeElapsed) / 1000 / 60),
      });
    }

    this.refreshTimer = setTimeout(this.refresh, 1000);
  };

  public render = () => {
    const { className, useShortMinutesLabel = false } = this.props;
    const { minutesLeft } = this.state;

    if (!minutesLeft) {
      return null;
    }

    return (
      <p className={className}>
        {minutesLeft}{' '}
        {useShortMinutesLabel
          ? t('mins')
          : minutesLeft === 1
          ? t('minute')
          : t('minutes')}{' '}
        {t('left')}
      </p>
    );
  };
}

export { TimeRemaining };
