import { SubscriptionInterval } from '@/enums/SubscriptionInterval';

export const getSubscriptionIntervalFromMatchedRoute = (
  matchedRoute: string
): SubscriptionInterval | undefined =>
  matchedRoute === 'optimisedJoinFlowSignUpYearly' ||
  matchedRoute === 'studentsSignUpYearly' ||
  matchedRoute === 'optimisedJoinFlowSignUpYearlyPay' ||
  matchedRoute === 'studentsSignUpYearlyPay'
    ? SubscriptionInterval.year
    : matchedRoute === 'optimisedJoinFlowSignUpMonthly' ||
      matchedRoute === 'studentsSignUpMonthly' ||
      matchedRoute === 'optimisedJoinFlowSignUpMonthlyPay' ||
      matchedRoute === 'studentsSignUpMonthlyPay'
    ? SubscriptionInterval.month
    : undefined;
