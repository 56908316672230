import * as React from 'react';

import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import smoothscroll from 'smoothscroll-polyfill';
import { connect } from 'unistore/react';

import { Head } from '@/components/global/Head/Head';
import { Icon } from '@/components/global/Icon/Icon';
import { Link } from '@/components/global/Link/Link';
import { SkeletonNew } from '@/components/global/Skeleton/SkeletonNew';
import { Toast } from '@/components/global/Toaster/Toast';
import { CollectionClipCard } from '@/components/ondemand/Collections/CollectionClipCard';
import { CollectionPageVideoPlayer } from '@/components/ondemand/Collections/CollectionPageVideoPlayer';
import { getCollection } from '@/components/ondemand/Collections/helpers';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { GlobalModal } from '@/enums/GlobalModal';
import { GlobalPortal } from '@/enums/GlobalPortal';
import { IconSize } from '@/enums/IconSize';

import { COLLECTION_FETCH_FAIL } from '@/messages/errors';

import { routes } from '@/routes';
import { parser } from '@/routes/parser';

import { modalActions } from '@/store/modules/modal';
import { portalActions } from '@/store/modules/portal';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface MatchParams {
  collectionId: string;
}

interface Props
  extends SizingState,
    ModalActions,
    PortalActions,
    AuthState,
    RouteComponentProps<MatchParams> {}

interface State {
  isLoading: boolean;
  collection?: Collection;
  isMetaDataTransitionedOut: boolean;
  currentClipIndex?: number;
  isCarouselScrollPrevVisible: boolean;
  isCarouselScrollNextVisible: boolean;
  redirectToGetStarted: boolean;
}

class CollectionPageComp extends React.Component<Props, State> {
  private refCarousel: React.RefObject<HTMLDivElement>;
  private refBlankCard: React.RefObject<HTMLDivElement>;

  public state: State = {
    isLoading: true,
    collection: undefined,
    isMetaDataTransitionedOut: false,
    currentClipIndex: undefined,
    isCarouselScrollPrevVisible: false,
    isCarouselScrollNextVisible: false,
    redirectToGetStarted: false,
  };

  // CONFIG
  private CAROUSEL_SCROLL_THRESHOLD: number = 25;

  constructor(props: Props) {
    super(props);

    this.refCarousel = React.createRef();
    this.refBlankCard = React.createRef();
  }

  public componentDidMount = async () => {
    smoothscroll.polyfill();

    await this.fetchCollection();

    if (this.refCarousel.current) {
      this.refCarousel.current.addEventListener(
        'scroll',
        this.onCarouselScroll
      );
    }
  };

  public componentWillUnmount = () => {
    if (this.refCarousel.current) {
      this.refCarousel.current.removeEventListener(
        'scroll',
        this.onCarouselScroll
      );
    }
  };

  private fetchCollection = async () => {
    const {
      match: {
        params: { collectionId },
      },
    } = this.props;

    try {
      const collection = await getCollection(collectionId);

      this.setState({
        isLoading: false,
        collection,
        isCarouselScrollNextVisible:
          !!collection &&
          !!collection.onDemandMedias &&
          collection.onDemandMedias.length > 2,
      });
    } catch (e) {
      new Toast({
        body: COLLECTION_FETCH_FAIL,
        failure: true,
      }).dispatch();
    }
  };

  private onCarouselScroll = () => {
    if (!this.refCarousel.current || !this.refBlankCard.current) {
      return;
    }

    const { scrollLeft, scrollWidth } = this.refCarousel.current;

    const {
      sizing: { isMobile },
    } = this.props;

    if (!isMobile) {
      const { isCarouselScrollPrevVisible, isCarouselScrollNextVisible } =
        this.state;

      const { width: cardWidth } =
        this.refBlankCard.current.getBoundingClientRect();

      const { width: carouselWidth } =
        this.refCarousel.current.getBoundingClientRect();

      if (scrollLeft >= cardWidth / 2 && !isCarouselScrollPrevVisible) {
        this.setState({ isCarouselScrollPrevVisible: true });
      } else if (scrollLeft < cardWidth / 2 && isCarouselScrollPrevVisible) {
        this.setState({ isCarouselScrollPrevVisible: false });
      }

      if (
        scrollLeft + carouselWidth >= scrollWidth - 50 &&
        isCarouselScrollNextVisible
      ) {
        this.setState({ isCarouselScrollNextVisible: false });
      } else if (
        scrollLeft + carouselWidth < scrollWidth - 50 &&
        !isCarouselScrollNextVisible
      ) {
        this.setState({ isCarouselScrollNextVisible: true });
      }
    }

    if (
      scrollLeft >= this.CAROUSEL_SCROLL_THRESHOLD &&
      !this.state.isMetaDataTransitionedOut
    ) {
      this.setState({ isMetaDataTransitionedOut: true });
    } else if (
      scrollLeft < this.CAROUSEL_SCROLL_THRESHOLD &&
      this.state.isMetaDataTransitionedOut
    ) {
      this.setState({ isMetaDataTransitionedOut: false });
    }
  };

  private handleShare = () => {
    const {
      sizing: { isMobile },
    } = this.props;

    if (isMobile) {
      this.props.updatePortal({
        portal: GlobalPortal.share_current_page,
        data: {
          isDarkMode: true,
        },
      });
    } else {
      this.props.updateModal({
        modal: GlobalModal.share_current_page,
        data: {
          isDarkMode: true,
        },
      });
    }
  };

  private scrollCarousel = (direction: 'forwards' | 'backwards') => {
    if (!this.refCarousel.current || !this.refBlankCard.current) {
      return;
    }

    const { width: cardWidth } =
      this.refBlankCard.current.getBoundingClientRect();

    const blankCardStyle =
      // @ts-ignore
      this.refBlankCard.current.currentStyle ||
      window.getComputedStyle(this.refBlankCard.current);

    const blankCardMarginRight = Number(
      blankCardStyle.marginRight.replace('px', '')
    );

    const totalCardWidth: number = cardWidth + blankCardMarginRight;

    const carouselScrollLeft = this.refCarousel.current.scrollLeft;

    const subtractor = direction === 'forwards' ? -1 : 1;

    this.refCarousel.current.scroll({
      left:
        (Math.floor(carouselScrollLeft / totalCardWidth) - subtractor) *
        totalCardWidth,
      behavior: 'smooth',
    });
  };

  public render = () => {
    const {
      sizing: { isMobile },
    } = this.props;

    const {
      isLoading,
      collection,
      isMetaDataTransitionedOut,
      currentClipIndex,
      isCarouselScrollPrevVisible,
      isCarouselScrollNextVisible,
      redirectToGetStarted,
    } = this.state;

    if (redirectToGetStarted) {
      return (
        <Redirect
          to={`${parser({
            name: 'getStarted',
            params: {},
          })}?to=${encodeURIComponent(window.location.pathname)}`}
        />
      );
    }

    const onDemandMedias = !!collection ? collection.onDemandMedias : undefined;

    return (
      <>
        <CollectionPageVideoPlayer
          videoUrl={
            typeof currentClipIndex !== 'undefined' &&
            !!onDemandMedias &&
            onDemandMedias[currentClipIndex] &&
            onDemandMedias[currentClipIndex].attachmentUrl
              ? onDemandMedias[currentClipIndex].attachmentUrl
              : undefined
          }
          workshopData={
            typeof currentClipIndex !== 'undefined' &&
            !!onDemandMedias &&
            onDemandMedias[currentClipIndex] &&
            onDemandMedias[currentClipIndex].workshop
              ? onDemandMedias[currentClipIndex].workshop
              : undefined
          }
          onClose={() => this.setState({ currentClipIndex: undefined })}
          onClickPrevious={() => {
            const { currentClipIndex } = this.state;

            if (
              typeof currentClipIndex === 'undefined' ||
              !!onDemandMedias === false
            ) {
              return;
            }

            if (currentClipIndex === 0) {
              this.setState({ currentClipIndex: onDemandMedias!.length - 1 });
            } else {
              this.setState({ currentClipIndex: currentClipIndex - 1 });
            }
          }}
          onClickNext={() => {
            const { currentClipIndex } = this.state;

            if (
              typeof currentClipIndex === 'undefined' ||
              !!onDemandMedias === false
            ) {
              return;
            }

            if (currentClipIndex === onDemandMedias!.length - 1) {
              this.setState({ currentClipIndex: 0 });
            } else {
              this.setState({ currentClipIndex: currentClipIndex + 1 });
            }
          }}
        />

        <div
          className={c('', {
            'u-100vh u-1/1 absolute absolute--tl': !isMobile,
            'u-flex u-flex-column-reverse': isMobile,
          })}
        >
          {!!collection && <Head title={collection.name} />}

          <div
            ref={this.refCarousel}
            className={c('c-collection-clip-card-carousel u-flex mb64@s', {
              'pointer-events-none': isLoading || !!collection === false,
            })}
          >
            <div className="c-collection-clip-card-carousel__margin" />

            {!isMobile && (
              <div
                ref={this.refBlankCard}
                className="c-collection-clip-card-carousel__item --mr0-i"
              />
            )}

            {!!onDemandMedias && !isLoading
              ? onDemandMedias.map((odm: OnDemandMedia, index: number) => (
                  <div
                    key={odm.id}
                    className={c('c-collection-clip-card-carousel__item', {
                      'mr0-i': index === onDemandMedias.length - 1,
                    })}
                  >
                    <CollectionClipCard
                      onDemandMedia={odm}
                      onClick={() => {
                        const {
                          auth: { user },
                        } = this.props;

                        if (!!user && user.subscriptionTier === null) {
                          this.setState({ redirectToGetStarted: true });
                          return;
                        }

                        this.setState({ currentClipIndex: index });
                      }}
                    />
                  </div>
                ))
              : Array.from(Array(3).keys()).map((_, key: number) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={key}
                    className={c('c-collection-clip-card-carousel__item', {
                      'mr0-i': key === 3,
                    })}
                  >
                    <div className="c-collection-clip-card relative" />
                  </div>
                ))}

            <div className="c-collection-clip-card-carousel__margin" />
          </div>

          <div
            className={c('c-collection-page__container ph20@s wrap@m u-white', {
              'pointer-events-none': !isMobile,
            })}
          >
            <div
              className={c('', {
                'u-1/3 u-h-100 u-flex u-align-center u-justify-center relative':
                  !isMobile,
              })}
            >
              <Link
                to={routes.explore}
                className={c(
                  'u-flex u-align-center u-link-white u-link-white--alt animate-opacity mt12@s mb32@s',
                  {
                    'absolute absolute--tl': !isMobile,
                    'opacity-1 pointer-events-all':
                      !isMetaDataTransitionedOut && !isMobile,
                    'opacity-0 pointer-events-none':
                      isMetaDataTransitionedOut && !isMobile,
                  }
                )}
              >
                <span className="c-collection-page__back mr12">
                  <Icon
                    id="chevron-left"
                    size={IconSize.xs}
                    className="u-black mr2"
                  />
                </span>

                <span>{t('Back to explore')}</span>
              </Link>

              <div
                className={c('u-1/1 mb32@s mr32 animate-opacity', {
                  'opacity-1': !isMetaDataTransitionedOut && !isMobile,
                  'opacity-0 pointer-events-none':
                    isMetaDataTransitionedOut && !isMobile,
                })}
              >
                {!!collection && !isLoading ? (
                  <>
                    <p className="f-yell-1 mb16 mb32@m pointer-events-all">
                      {collection.name}
                    </p>
                    <p className="f-text-2 mb32 pointer-events-all">
                      {collection.description}
                    </p>
                  </>
                ) : (
                  <>
                    <SkeletonNew
                      elementClassName="f-yell-1"
                      fillClassName="u-bg-almost-black"
                      containerClassName="mb12"
                      width="100%"
                    />
                    <SkeletonNew
                      elementClassName="f-yell-1"
                      fillClassName="u-bg-almost-black"
                      containerClassName="mb16 mb32@m"
                      width="80%"
                    />

                    <SkeletonNew
                      elementClassName="f-text-2"
                      fillClassName="u-bg-almost-black"
                      containerClassName="mb8"
                      width="100%"
                    />
                    <SkeletonNew
                      elementClassName="f-text-2"
                      fillClassName="u-bg-almost-black"
                      containerClassName="mb32"
                      width="80%"
                    />
                  </>
                )}

                <Button
                  type={ButtonType.action}
                  onClick={() => this.handleShare()}
                  buttonStyle={ButtonStyle.outlineLight}
                  size={ButtonSize.s}
                  iconId="share"
                  className="pointer-events-all"
                  disabled={!!collection === false || isLoading}
                />
              </div>
            </div>
            <div
              className={c(
                'u-hide@s absolute absolute--left-center animate-opacity',
                {
                  'pointer-events-all opacity-1': isCarouselScrollPrevVisible,
                  'pointer-events-none opacity-0': !isCarouselScrollPrevVisible,
                }
              )}
            >
              <Button
                type={ButtonType.action}
                onClick={() => this.scrollCarousel('backwards')}
                buttonStyle={ButtonStyle.veryLightTranslucent}
                iconId="arrow-left"
              />
            </div>

            <div
              className={c(
                'u-hide@s absolute absolute--right-center animate-opacity',
                {
                  'pointer-events-all opacity-1': isCarouselScrollNextVisible,
                  'pointer-events-none opacity-0': !isCarouselScrollNextVisible,
                }
              )}
            >
              <Button
                type={ButtonType.action}
                onClick={() => this.scrollCarousel('forwards')}
                buttonStyle={ButtonStyle.veryLightTranslucent}
                iconId="arrow-right"
              />
            </div>
          </div>
        </div>
      </>
    );
  };
}

export const CollectionPage = connect(['sizing', 'auth'], () => ({
  ...modalActions(),
  ...portalActions(),
}))(withRouter(CollectionPageComp));
