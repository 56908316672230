import * as React from 'react';

import { FeedbackButton } from '@/components/global/FeedbackButton/FeedbackButton';
import { Link } from '@/components/global/Link/Link';

import { routes } from '@/routes';

import { t } from '@/utils/i18n/i18n';

export const AUTO_LOGIN_FAIL =
  'Sorry, there was a problem logging you in automatically.';
export const TOKEN_EXPIRED: string =
  'To continue, please log back in to Daisie.';
export const BIO_LENGTH_EXCEEDED = 'Your bio must be less than %s characters';
export const BLOCK_USER_FAIL = `There was a problem blocking this creator, please try again later.`;
export const CHAT_START_FAILED =
  'There was a problem loading this chat, please try again later.';
export const CURRENT_PASSWORD_INCORRECT =
  'Your old password was entered incorrectly. Please try again.';
export const EMAIL_ALREADY_IN_USE =
  'There is already an account with that email address.';
export const EMAIL_ALREADY_IN_USE_TRY_LOGGING_IN = (
  linkParams: string = ''
) => (
  <span>
    {EMAIL_ALREADY_IN_USE} Did you try{' '}
    <Link
      className="u-underline"
      to={linkParams ? `${routes.login}${linkParams}` : routes.login}
    >
      logging in
    </Link>
    ?
  </span>
);
export const EMAIL_INVALID =
  'That email address does not appear to be valid. Please try again.';
export const FIELD_REQUIRED = 'This field is required';
export const LOGIN_USER_FAIL =
  'Incorrect username or password. Please check and try again.';
export const LOGIN_PHONE_USER_FAIL =
  'There is a problem with that code or no account is registered under this phone number.';
export const LOGIN_GOOGLE_SSO_FAIL =
  'There was a problem when signing in with Google';
export const MISSING_NAME = 'Please enter your name';

export const FOLLOW_USER_FAIL =
  'There was a problem with following that creator, please try again later';
export const INCORRECT_FORMATS =
  'Some of the files you dropped in are not supported yet and were not added';
export const NAME_LENGTH_EXCEEDED = 'Your name must be less than %s characters';
export const PASSWORDS_DO_NOT_MATCH = 'Please ensure your passwords match';
export const PASSWORD_REQUEST_FAIL =
  'There was a problem resetting your password. Are you sure your details are correct?';
export const PASSWORD_TOO_SHORT =
  'Your password must be at least %s characters';
export const PROJECT_CREATION_FAIL =
  'There was a problem creating your room, please try again';
export const PROJECT_UPDATE_FAIL =
  'There was a problem updating your room, please try again';
export const REPORT_PROJECT_FAIL = `There was a problem reporting this room, please try again later.`;
export const REPORT_USER_FAIL = `There was a problem reporting this creator, please try again later.`;
export const SIGNUP_FAIL =
  'There was a problem signing you up, please try again';
export const AUTH_PHONE_CODE_FAIL =
  'There was a problem getting your verification code, please try again later';
export const UNBLOCK_USER_FAIL = `There was a problem unblocking this creator, please try again later.`;
export const UNKNOWN_ERROR = "We're sorry but something's gone wrong";
export const UNFOLLOW_USER_FAIL =
  'There was a problem with unfollowing that creator, please try again later';
export const USERNAME_GENERATION_FAILED = 'Username generation failed';
export const USERNAME_IN_USE = `😤 Argh, someone already grabbed that one! Maybe it's your evil twin?`;
export const USERNAME_INVALID = `🤨 Oops! Only use letters, numbers, underscores, and full stops please.`;
export const USERNAME_INVALID_START_CHAR = `Please start your username with a letter`;
export const USERNAME_LENGTH_EXCEEDED =
  'Your username must be less than %s characters';
export const INSTAGRAM_HANDLE_LENGTH_EXCEEDED =
  'Your instagram handle must be less than %s characters';

export const PHONE_NUMBER_IN_USE =
  'Sorry, this phone number is already in use with another account.';
export const PHONE_NUMBER_VERIFICATION_FAIL =
  'There is a problem with that code.';
export const URL_INVALID = 'Please enter a valid URL';

export const PROFILE_PICTURE_INVALID_FILE_TYPE =
  "You can't use this type of file as your profile picture";
export const PROFILE_PICTURE_UNREADABLE =
  'Unable to upload this file. Please try another.';
export const PROFILE_PICTURE_UPLOAD_FAIL =
  'There was a problem saving your profile picture. Please try again.';
export const PROFILE_PICTURE_DELETE_FAIL =
  'Unable to clear your profile picture. Please try again.';
export const PROFILE_PICTURE_DELETED = 'Profile picture cleared.';
export const PROFILE_VISIBILITY_FAIL =
  'Unable to change profile privacy. Please try again.';

export const BLOCKED_USERS_FETCH_FAIL =
  'Unable to fetch blocked people. Please try again.';

export const PROJECT_PREVIEW_GENERATION_FAIL =
  'Something went wrong generating your room previews. Please try again later.';
export const PROFILE_PREVIEW_GENERATION_FAIL =
  'Something went wrong generating your profile previews. Please try again later.';

export const FOLLOWING_FETCH_FAIL =
  'Unable to fetch following users. Please try again.';
export const FOLLOWER_FETCH_FAIL =
  'Unable to fetch followers. Please try again.';

export const STRIPE_SAVE_CARD_FAIL: string =
  'There was a problem saving your card. Please try again.';

export const HOME_FEED_ACTIVE_ROOMS = 'Unable to fetch active rooms.';

export const WORKSHOP_ATTEND_FAIL: string =
  'There was a problem attending this workshop.';
export const WORKSHOP_UNATTEND_FAIL: string =
  'There was a problem unattending this workshop.';
export const WORKSHOP_BATCH_SOLD_OUT = 'Sorry, this session is now sold out!';
export const WORKSHOP_BATCH_ELAPSED =
  'Sorry, this session has already happened.';
export const WORKSHOP_BATCH_NOT_FOUND = `This session couldn't be found`;
export const WORKSHOP_RELATED_WORKSHOPS_FAIL =
  'Unable to fetch related workshops.';

export const ROOM_BACKGROUND_UPDATE_FAIL =
  'There was a problem changing the background, please try again';
export const ROOM_JOIN_FAIL =
  'There was a problem joining this room. Please try again.';
export const ROOM_BAN_FAIL: string =
  'There was a problem banning this user. Please try again.';
export const ROOM_KICK_FAIL: string =
  'There was a problem removing this user. Please try again.';
export const ROOM_UNBAN_FAIL: string =
  'There was a problem unbanning this user. Please try again.';
export const ROOM_VOICE_FAIL: string =
  'There was a problem joining audio for this room. Please try again.';

export const WEB3_JOIN_WAITLIST_FAIL =
  'There was a problem joining the waitlist. Please try again.';

export const COUNTRY_CODES_FETCH_FAIL: string =
  'Unable to fetch country codes, please try again.';

export const SUBSCRIPTION_CREATE_FAIL: string = `There's a problem creating your subscription. Please try again.`;
export const SUBSCRIPTION_DISCOUNT_FAIL: string = `There's a problem applying a discount to your subscription. Please try again.`;

export const STUDENT_EMAIL_INVALID = (
  <p>
    {t(`We couldn't verify your student email address.`)}{' '}
    <FeedbackButton
      trigger={({ onClick }: { onClick: () => void }) => (
        <button type="button" className="u-underline" onClick={onClick}>
          {t('Let us know.')}
        </button>
      )}
    />
  </p>
);

export const CLASSES_FETCH_FAIL: string =
  'Unable to fetch classes. Please try again';

export const COLLECTIONS_FETCH_FAIL: string =
  'Unable to fetch collections. Please try again.';
export const COLLECTION_FETCH_FAIL: string =
  'Unable to fetch this collection. Please try again.';

export const INSTRUCTOR_DASHBOARD_LOAD_FAIL: string =
  'There was a problem loading your workshops. Please try again.';

export const ORIGINALS_QUIZ_PROGRESS_FAIL: string =
  'Unable to move onto next step. Make sure to choose at least 1 answer.';

export const errorMessage = (msg: string, values?: any): string =>
  t(msg.replace(/%s/g, values || ''));
