import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'unistore/react';

import { Icon } from '@/components/global/Icon/Icon';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { TrackingLocation } from '@/enums/TrackingLocation';

import { modalActions } from '@/store/modules/modal';
import { portalActions } from '@/store/modules/portal';

import { isMobile } from '@/utils/DOM/sizing';
import { copyLink } from '@/utils/forms/copyToClipboard';
import { t } from '@/utils/i18n/i18n';
import { track } from '@/utils/mixpanel/mixpanel';
import { c } from '@/utils/strings/c';

interface Props extends ModalActions, PortalActions, RouteComponentProps {
  onDismiss: () => void;
  link?: string;
  from?: TrackingLocation;
  mixpanelTrackEventPrefix?: string;
  isDarkMode?: boolean;
  twitterText?: string;
  emailSubject?: string;
}

interface State {
  copied: boolean;
}

class OptimisedJoinFlowBogoModalComponent extends React.Component<
  Props,
  State
> {
  private projectLink: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.projectLink = React.createRef();

    this.state = {
      copied: false,
    };
  }

  public componentDidMount = () => {
    const { mixpanelTrackEventPrefix, from } = this.props;

    if (mixpanelTrackEventPrefix) {
      track(`${mixpanelTrackEventPrefix}_open`, { from });
    }
  };

  private trackClickEvent = (type: string) => {
    const { mixpanelTrackEventPrefix } = this.props;

    if (!mixpanelTrackEventPrefix) return;

    track(`${mixpanelTrackEventPrefix}_clicked`, { type });
  };

  private renderSocialMediaLinks = () => {
    const {
      link: linkProp,
      location: { pathname },
      twitterText,
      emailSubject = encodeURIComponent('Join Daisie'),
    } = this.props;

    const link = encodeURIComponent(
      linkProp || `${window.location.origin}${pathname}`
    );

    return (
      <div className="social-buttons-outer">
        <div className="social-buttons-inner">
          <div className="social-buttons-grid mb12">
            <a
              target="_blank"
              className="social-buttons-item"
              rel="noopener noreferrer"
              href={`mailto:?subject=${emailSubject}&body=${link}`}
              onClick={() => this.trackClickEvent('mail')}
            >
              <div className="button--circle button--circle--xlarge button--grey mr16">
                <Icon id="mail" size="l" className="u-white" />
              </div>
            </a>

            <a
              target="_blank"
              className="social-buttons-item"
              rel="noopener noreferrer"
              href={
                isMobile()
                  ? `fb-messenger://share/?link=${link}`
                  : `https://www.facebook.com/dialog/send?app_id=658687918236317&link=${link}&display=popup&redirect_uri=${link}`
              }
              onClick={() => this.trackClickEvent('facebook-messenger')}
            >
              <div className="button--circle button--circle--xlarge button--fb-messenger mr16">
                <Icon
                  id="facebook-messenger"
                  size="l"
                  className="u-fb-messenger"
                />
              </div>
            </a>

            <a
              target="_blank"
              className="social-buttons-item"
              rel="noopener noreferrer"
              href={`https://wa.me/?text=${link}`}
              onClick={() => this.trackClickEvent('whatsapp')}
            >
              <div className="button--circle button--circle--xlarge button--whatsapp mr16">
                <Icon id="whatsapp" size="l" className="u-white" />
              </div>
            </a>

            <a
              target="_blank"
              className="social-buttons-item"
              rel="noopener noreferrer"
              href={`https://www.twitter.com/share?${
                twitterText ? `text=${twitterText}&` : ''
              }url=${link}`}
              onClick={() => this.trackClickEvent('twitter')}
            >
              <div className="button--circle button--circle--xlarge button--twitter mr16">
                <Icon id="twitter" size="l" className="u-white" />
              </div>
            </a>

            <a
              target="_blank"
              className="social-buttons-item"
              rel="noopener noreferrer"
              href={`https://www.facebook.com/sharer.php?u=${link}`}
              onClick={() => this.trackClickEvent('facebook')}
            >
              <div className="button--circle button--circle--xlarge button--facebook mr16">
                <Icon id="facebook" size="l" className="u-white" />
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  };

  private renderCopyLink = () => {
    const {
      isDarkMode,
      link: linkProp,
      location: { pathname },
    } = this.props;
    const { copied } = this.state;

    const link = linkProp || `${window.location.origin}${pathname}`;

    return (
      <div
        className={c('dds-input p16 relative', {
          pr80: !copied,
          pr128: copied,
          'dds-input--theme-light': !isDarkMode,
          'dds-input--theme-dark': isDarkMode,
        })}
      >
        <input
          type="text"
          className={c('p0 u-bg-none', {
            'u-white': isDarkMode,
          })}
          id="project-link"
          ref={this.projectLink}
          value={link}
        />

        {copied ? (
          <div className="absolute--i absolute--top-center r8">
            <Button
              type={ButtonType.action}
              onClick={() =>
                copyLink(this.projectLink, () => {
                  track('copy_link', { url: this.projectLink.current?.value });

                  this.setState({ copied: true });
                })
              }
              text={t('Copied')}
              iconId="tick"
              size={ButtonSize.s}
            />
          </div>
        ) : (
          <div className="absolute--i absolute--top-center r8">
            <Button
              type={ButtonType.action}
              onClick={() =>
                copyLink(this.projectLink, () => {
                  track('copy_link', {
                    url: this.projectLink.current?.value,
                  });
                  this.setState({ copied: true });
                })
              }
              text={t('Copy')}
              size={ButtonSize.s}
            />
          </div>
        )}
      </div>
    );
  };

  private dismiss = () => {
    if (isMobile()) {
      this.props.updatePortal({ portal: undefined });
    } else {
      this.props.updateModal({ modal: undefined });
    }
  };

  public render = () => {
    const { isDarkMode } = this.props;

    return (
      <>
        <div className="mt24">{this.renderSocialMediaLinks()}</div>
        <div className="mt24">{this.renderCopyLink()}</div>

        <Button
          type={ButtonType.action}
          className="mobile-only mt24 u-1/1"
          onClick={this.dismiss}
          text={t('Done')}
          buttonStyle={isDarkMode ? ButtonStyle.dark : ButtonStyle.light}
        />
      </>
    );
  };
}

export const OptimisedJoinFlowBogoModal = connect([], () => ({
  ...modalActions(),
  ...portalActions(),
}))(withRouter(OptimisedJoinFlowBogoModalComponent));
