import * as React from 'react';

import { c } from '@/utils/strings/c';

interface Props {
  src: string;
  alt?: string;
  className?: string;
  type?: 'constrainSquareIfPortrait';
  onError?: () => void;
  loadedClassName?: string;
  backgroundClassName?: string;
  onLoad?: () => void;
  noAnimations?: boolean;
  draggable?: boolean;
  constrainSquare?: boolean;
  style?: any;
  convertToWebp?: boolean;
  lazyload?: boolean;
}

interface State {
  isLoaded: boolean;
  constrainSquare: boolean;
}

class ImageLoader extends React.Component<Props, State> {
  private refImg: React.RefObject<HTMLImageElement>;

  public state: State = {
    isLoaded: false,
    constrainSquare: false,
  };

  constructor(props: Props) {
    super(props);

    this.refImg = React.createRef();
  }

  public componentDidMount = () => {
    if (!this.refImg.current) return;

    this.refImg.current.onload = () => {
      if (!this.refImg.current) return;

      const { width, height } = this.refImg.current;

      const constrainSquare =
        this.props.type === 'constrainSquareIfPortrait' && width < height;

      this.setState({
        isLoaded: true,
        constrainSquare,
      });

      if (this.props.onLoad) {
        this.props.onLoad();
      }
    };

    this.refImg.current.onerror = () => {
      if (this.props.onError) {
        this.props.onError();
      }
    };
  };

  public render = () => {
    const {
      src,
      alt,
      className = '',
      loadedClassName = '',
      backgroundClassName,
      noAnimations = false,
      draggable = true,
      constrainSquare: constrainSquareProp,
      style,
      convertToWebp,
      lazyload = true,
    } = this.props;
    const { isLoaded, constrainSquare } = this.state;

    const image = (
      <img
        loading={lazyload ? 'lazy' : 'eager'}
        className={c([className], {
          'animate-opacity': !noAnimations,
          'opacity-0': !isLoaded && !noAnimations,
          'opacity-1': isLoaded && !loadedClassName && !noAnimations,
          'absolute fill u-object-cover':
            constrainSquare || constrainSquareProp,
          [loadedClassName]: isLoaded && loadedClassName,
        })}
        ref={this.refImg}
        src={
          !src?.includes('gif') && convertToWebp
            ? src.replace(/\.([^.]+)$/, '.webp')
            : src
        }
        alt={alt}
        draggable={draggable}
        style={style}
      />
    );

    if (constrainSquare) {
      return (
        <div className="mb16 relative u-overflow-hidden">
          <div className="u-aspect-square" />
          {image}
        </div>
      );
    }

    if (backgroundClassName) {
      return (
        <div
          className={c([className, backgroundClassName], {
            'animate-opacity': !noAnimations,
            'opacity-0': !isLoaded && !noAnimations,
            'opacity-1': isLoaded && !noAnimations,
          })}
        >
          {image}
        </div>
      );
    }

    return image;
  };
}

export { ImageLoader };
