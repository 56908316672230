import React, { useEffect, useRef, useState } from 'react';

import { Icon } from '@/components/global/Icon/Icon';
import Circle from '@/components/global/TextCircleCursor/TextCircle';

import { c } from '@/utils/strings/c';

interface Props {
  word: string;
  className?: string;
  height?: number;
  width?: number;
  iconId?: string;
  iconSize?: string;
  iconRotation?: number;
  theme?: string;
  onCursorMove: (bounds: DOMRect) => void;
}

const TextCircleCursor = React.memo(
  ({
    word,
    height = 80,
    width = 80,
    className,
    iconId = 'arrow-long',
    iconSize = 'xxl',
    iconRotation = 0,
    theme = 'purple',
    onCursorMove,
  }: Props) => {
    const mouseX = useRef(0);
    const mouseY = useRef(0);

    const cursor = useRef<HTMLDivElement>(null);
    const [showCursor, setShowCursor] = useState(false);

    useEffect(() => {
      const moveCursor = (e: MouseEvent) => {
        mouseY.current = e.clientY;
        mouseX.current = e.clientX;

        setShowCursor(true);
        updateCursorPos();
      };

      const updateCursorPos = () => {
        if (cursor.current) {
          // Position the element at the mouse cursor coordinates
          // and center it so the middle of it is at the position of the mouse cursor
          cursor.current.style.transform = `translate3d(${
            mouseX.current - cursor.current.clientWidth / 2
          }px, ${mouseY.current - cursor.current.clientHeight / 2}px, 0)`;

          // Feed back the position of cursor to the parent component
          if (cursor.current?.getBoundingClientRect()) {
            onCursorMove(cursor.current?.getBoundingClientRect());
          }
        }
      };

      window.addEventListener('mousemove', moveCursor);
      window.addEventListener('scroll', updateCursorPos);

      return () => {
        window.removeEventListener('mousemove', moveCursor);
        window.removeEventListener('scroll', updateCursorPos);
      };
    }, []);

    if (!showCursor) return null;

    return (
      <div
        className={c([
          className || '',
          `c-text-circle c-text-circle--${theme} relative u-flex u-justify-center u-align-center`,
        ])}
        ref={cursor}
        style={{
          height,
          width,
          zIndex: 99999,
        }}
      >
        <div
          className="c-text-circle__icon absolute"
          style={{ transform: `rotate(${iconRotation}deg)` }}
        >
          <Icon id={iconId} size={iconSize} />
        </div>
        <Circle word={word} />
      </div>
    );
  }
);
export default TextCircleCursor;
