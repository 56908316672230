import gsap from 'gsap';

import { colors } from '@/styles/chakra-theme/colors';
import { pxToRem } from '@/styles/chakra-theme/metrics';

import { isDesktop, isMobile } from '@/utils/DOM/sizing';

export const landingPageAnimations = () => {
  /**
   *
   * Menu color transition: Header.tsx and BottomMenuMobile.tsx
   */

  const mobileMenuTimeline = gsap.timeline();

  const headerTimeline = gsap.timeline();

  const enrollButtonTimeline = gsap.timeline();

  /*
   * First transition occurs when entering Outcomes section,
   * and the second occurs when entering Class Breakdown section
   */

  mobileMenuTimeline
    .fromTo(
      '.bottomMenuMobile',
      {
        backgroundColor: colors.greyDark,
        color: 'white',
      },
      {
        backgroundColor: colors.navBgLight,
        color: 'black',
        scrollTrigger: {
          trigger: '.skillsLearning',
          scrub: true,
          start: 'top bottom',
          end: 'top bottom',
        },
      }
    )
    .fromTo(
      '.bottomMenuMobile',
      {
        backgroundColor: colors.navBgLight,
        color: 'black',
      },
      {
        backgroundColor: colors.greyDark,
        color: 'white',
        scrollTrigger: {
          trigger: '.classBreakdown',
          scrub: true,
          start: 'top bottom',
          end: 'top bottom',
        },
        immediateRender: false,
      }
    )
    .fromTo(
      '.bottomMenuMobile',
      {
        backgroundColor: colors.greyDark,
        color: 'white',
      },
      {
        backgroundColor: colors.navBgLight,
        color: 'black',
        scrollTrigger: {
          trigger: '.reviews',
          scrub: true,
          start: 'top bottom',
          end: 'top bottom',
        },
      }
    )
    .fromTo(
      '.bottomMenuMobile',
      {
        display: 'block',
      },
      {
        display: 'none',
        scrollTrigger: {
          trigger: '.footer',
          scrub: true,
          start: 'top bottom',
          end: 'top bottom',
        },
        immediateRender: false,
      }
    );

  headerTimeline
    .fromTo(
      '.header',
      {
        backgroundColor: colors.greyDark,
        color: 'white',
      },
      {
        backgroundColor: colors.navBgLight,
        color: 'black',
        scrollTrigger: {
          trigger: '.skillsLearning',
          scrub: true,
          start: 'top bottom',
          end: 'top bottom',
        },
      }
    )
    .fromTo(
      '.header',
      {
        backgroundColor: colors.navBgLight,
        color: 'black',
      },
      {
        backgroundColor: colors.greyDark,
        color: 'white',
        scrollTrigger: {
          trigger: '.classBreakdown',
          scrub: true,
          start: 'top bottom',
          end: 'top bottom',
        },
        immediateRender: false,
      }
    )
    .fromTo(
      '.header',
      {
        backgroundColor: colors.greyDark,
        color: 'white',
      },
      {
        backgroundColor: colors.navBgLight,
        color: 'black',
        scrollTrigger: {
          trigger: '.reviews',
          scrub: true,
          start: 'top bottom',
          end: 'top bottom',
        },
      }
    )
    .fromTo(
      '.header',
      {
        backgroundColor: colors.navBgLight,
        color: 'black',
      },
      {
        backgroundColor: colors.greyDark,
        color: 'white',
        scrollTrigger: {
          trigger: '.faq',
          scrub: true,
          start: 'top bottom',
          end: 'top bottom',
        },
        immediateRender: false,
      }
    );

  enrollButtonTimeline
    .fromTo(
      '.enrollBtn',
      {
        backgroundColor: 'white',
        color: 'black',
      },
      {
        backgroundColor: colors.greyDark,
        color: 'white',
        scrollTrigger: {
          trigger: '.skillsLearning',
          scrub: true,
          start: 'top bottom',
          end: 'top bottom',
        },
      }
    )
    .fromTo(
      '.enrollBtn',
      {
        backgroundColor: 'black',
        color: 'white',
      },
      {
        backgroundColor: colors.navBgLight,
        color: 'black',
        scrollTrigger: {
          trigger: '.classBreakdown',
          scrub: true,
          start: 'top bottom',
          end: 'top bottom',
        },
        immediateRender: false,
      }
    )
    .fromTo(
      '.enrollBtn',
      {
        backgroundColor: 'white',
        color: 'black',
      },
      {
        backgroundColor: colors.greyDark,
        color: 'white',
        scrollTrigger: {
          trigger: '.reviews',
          scrub: true,
          start: 'top bottom',
          end: 'top bottom',
        },
      }
    )
    .fromTo(
      '.enrollBtn',
      {
        backgroundColor: 'black',
        color: 'white',
      },
      {
        backgroundColor: colors.navBgLight,
        color: 'black',
        scrollTrigger: {
          trigger: isDesktop() ? '.faq' : '.footer',
          scrub: true,
          start: 'top bottom',
          end: 'top bottom',
        },
        immediateRender: false,
      }
    );

  /**
   *
   * Mentor section: SkillsHeroSection.tsx
   */

  gsap.fromTo(
    '.pillMentor',
    {
      x: isMobile() ? 240 : 360,
      transform: 'rotate(0deg)',
    },
    {
      x: 0,
      transform: isMobile() ? 'rotate(-15deg)' : 'rotate(-5deg)',
      scrollTrigger: {
        trigger: '.pillMentor',
        scrub: true,
      },
    }
  );

  gsap.fromTo(
    '.pillCartoons',
    {
      x: isMobile() ? -240 : -360,
      transform: 'rotate(0deg)',
    },
    {
      x: 0,
      transform: isMobile() ? 'rotate(-15deg)' : 'rotate(-5deg)',
      scrollTrigger: {
        trigger: '.pillCartoons',
        scrub: true,
      },
    }
  );

  gsap.fromTo(
    '.pillIllustration',
    {
      x: window.innerWidth - (isMobile() ? 96 : 360),
      transform: 'rotate(0deg)',
    },
    {
      x: 0,
      transform: 'rotate(-15deg)',
      scrollTrigger: {
        trigger: '.pillIllustration',
        scrub: true,
      },
    }
  );

  gsap.fromTo(
    '.spinHeadsA',
    {
      transform: 'rotate(0deg)',
    },
    {
      transform: 'rotate(25deg)',
      scrollTrigger: {
        trigger: '.spinHeadsA',
        scrub: true,
      },
    }
  );

  gsap.fromTo(
    '.spinHeadsB',
    {
      transform: 'rotate(0deg)',
    },
    {
      transform: 'rotate(-25deg)',
      scrollTrigger: {
        trigger: '.spinHeadsB',
        scrub: true,
      },
    }
  );

  /**
   *
   * What you'll learn: SkillsLearningSection.tsx
   */

  if (!isMobile()) {
    gsap.fromTo(
      '.ideaGeneration',
      {
        x: '-100%',
      },
      {
        x: 0,
        scrollTrigger: {
          trigger: '.ideaGeneration',
          scrub: true,
          start: 'top bottom',
          end: 'top center',
        },
      }
    );

    gsap.fromTo(
      '.craftingNarratives',
      {
        x: '-100%',
      },
      {
        x: 0,
        scrollTrigger: {
          trigger: '.craftingNarratives',
          scrub: true,
          start: 'top bottom',
          end: 'top center',
        },
      }
    );

    gsap.fromTo(
      '.fundamentals',
      {
        x: '100%',
      },
      {
        x: 0,
        scrollTrigger: {
          trigger: '.fundamentals',
          scrub: true,
          start: 'top bottom',
          end: 'top center',
        },
      }
    );
  }

  gsap.fromTo(
    '.squares',
    {
      opacity: 0,
    },
    {
      opacity: 1,
      stagger: 3,
      scrollTrigger: {
        trigger: '.fundamentals',
        scrub: true,
        start: 'bottom 70%',
        end: '+=150%',
      },
    }
  );

  /**
   *
   * Reviews: ReviewsSection.tsx
   */

  gsap.fromTo(
    '.spin',
    {
      transform: 'rotate(0deg)',
    },
    {
      transform: 'rotate(-245deg)',
      scrollTrigger: {
        trigger: '.spin',
        scrub: true,
        start: 'bottom bottom',
        end: 'top top',
      },
    }
  );

  if (isDesktop()) {
    gsap.fromTo(
      '.reviewCards',
      {
        scale: 0,
      },
      {
        scale: 0.8,
        scrollTrigger: {
          trigger: '.reviewCards',
          scrub: true,
          start: 'bottom bottom',
          end: 'center center',
        },
      }
    );
  } else {
    gsap.fromTo(
      '.reviewCards',
      {
        x: 0,
      },
      {
        x: -650,
        ease: 'none',
        scrollTrigger: {
          trigger: '.reviewCards',
          scrub: true,
          start: 'top 100',
          end: '900',
          pin: true,
        },
      }
    );
  }

  /**
   *
   * Bottom Menu : BottomMenuMobile.tsx
   */

  gsap.fromTo(
    '.bottomMenuMobile',
    {
      bottom: pxToRem(32),
      width: '90%',
      x: 0.05 * window.innerWidth,
    },
    {
      bottom: 0,
      width: '100%',
      x: 0,
      scrollTrigger: {
        trigger: '#footer',
        scrub: true,
        start: 'center center',
        end: 'bottom center',
      },
    }
  );
};
