const tooltipState: TooltipState = {
  tooltip: {
    isVisible: false,
    posX: 0,
    posY: 0,
    parentOffsetX: 0,
    parentOffsetY: 0,
    content: undefined,
    isActionable: undefined,
  },
};

const tooltipActions = (store: any) => ({
  showTooltip: (
    state: GlobalStoreState,
    params: {
      isVisible: boolean;
      posX: number;
      posY: number;
      parentOffsetX: number;
      parentOffsetY: number;
      content: React.ReactNode;
      isActionable?: boolean;
    }
  ) => {
    const {
      isVisible,
      posX,
      posY,
      parentOffsetX,
      parentOffsetY,
      content,
      isActionable,
    } = params;

    if (posX === 0 && posY === 0) {
      return;
    }

    store.setState({
      tooltip: {
        isVisible,
        posX,
        posY,
        parentOffsetX,
        parentOffsetY,
        content,
        isActionable,
      },
    });
  },

  hideTooltip: () => {
    store.setState({
      tooltip: {
        isVisible: false,
        posX: 0,
        posY: 0,
        parentOffsetX: 0,
        parentOffsetY: 0,
        content: undefined,
        isActionable: undefined,
      },
    });
  },
});

export { tooltipState, tooltipActions };
