import React from 'react';

import { Box, BoxProps, Heading } from '@chakra-ui/react';

interface Props extends BoxProps {
  textColor: string;
  bgColor: string | string[];
  fontSize: number | number[];
  children: React.ReactElement | string;
}

export const TitleTag = ({
  children,
  textColor = 'white',
  bgColor = 'gray',
  fontSize = 40,
  ...props
}: Partial<Props>) => (
  <Box
    bg={bgColor}
    w="fit-content"
    h="fit-content"
    borderRadius={100}
    py={2}
    px={18}
    {...props}
  >
    <Heading
      color={textColor}
      fontSize={fontSize}
      fontFamily="Originals"
      lineHeight="normal"
      fontWeight={props.fontWeight || 400}
    >
      {children}
    </Heading>
  </Box>
);
