import * as React from 'react';

import { connect } from 'unistore/react';

import { Icon } from '@/components/global/Icon/Icon';
import { Link } from '@/components/global/Link/Link';
import { Avatar } from '@/components/users/Avatar/Avatar';
import { UserVerified } from '@/components/users/UserVerified/UserVerified';

import { portalActions } from '@/store/modules/portal';

import { c } from '@/utils/strings/c';

interface Props extends PortalActions, PortalState {
  user: User;
  linkEnabled: boolean;
  linkClassName?: string;
  type: 'compact' | 'normal' | 'userFieldsOnly';
  darkMode?: boolean;
  // Compact: used on collaborator lists
  // Normal: used in search
  // User fields only: full name and username only, used on blocked page
}

class UsersListItemComponent extends React.Component<Props> {
  public render = () => {
    const {
      user,
      linkEnabled = true,
      type,
      linkClassName = '',
      darkMode,
    } = this.props;

    const avatarSize = type === 'normal' ? 'xxl' : 'xl';

    const showAvatar = type !== 'userFieldsOnly';

    const component = (
      <div
        className={c('u-flex u-align-center u-1/1', {
          'u-truncate': type === 'compact',
        })}
      >
        {showAvatar && (
          <Avatar
            size={avatarSize}
            className="mr12 u-flex-hold"
            alt={user.primaryName}
            src={user.avatar}
            user={{
              name: user.primaryName,
              username: user.secondaryName,
            }}
          />
        )}

        <div
          className={c('', {
            'u-truncate': type === 'compact',
          })}
        >
          <p
            className={c('u-flex u-align-center', {
              'u-white': darkMode,
              'u-charcoal-grey': !darkMode,
              'f-text-3 u-bold':
                type === 'compact' || type === 'userFieldsOnly',
              'f-text-3 u-bold u-word-break': type === 'normal',
              mb8:
                type === 'normal' &&
                ((user.location && user.location.name) ||
                  (user.roles && user.roles.length > 0)),
              'u-truncate': type === 'compact',
              mb2: type === 'compact' && user.location,
            })}
          >
            <span
              className={c('u-flex u-align-center u-white', {
                'u-truncate': type === 'compact',
              })}
            >
              {user.primaryName}

              {type === 'normal' && <UserVerified user={user} />}
            </span>

            {(type === 'compact' || type === 'userFieldsOnly') && (
              <UserVerified
                user={user}
                className={c('', {
                  mh4: type === 'compact',
                })}
              />
            )}
          </p>

          {(type === 'userFieldsOnly' ||
            (type === 'compact' && user.primaryName !== user.username)) && (
            <p className="f-text-3 u-grey u-text-left">{user.username}</p>
          )}

          {user.location && type !== 'compact' && (
            <div
              className={c('f-text-3 u-flex u-align-center', {
                mb8: type === 'normal' && user.roles && user.roles.length > 0,
              })}
            >
              <Icon
                id="pin"
                width="14"
                height="14"
                className="u-flex-hold u-light-grey mvauto mr2"
              />
              <span className="u-flex-1 u-grey u-text-left">
                {user.location.name}
              </span>
            </div>
          )}

          {user.roles && type === 'normal' && (
            <div className="f-text-3 u-flex u-align-center">
              <Icon
                id="person"
                width="12"
                height="12"
                className="u-flex-hold u-light-grey mvauto mr4"
              />
              <span className="u-grey">
                {user.roles.map((r: Role, i: number) => {
                  if (!user.roles) return '';

                  return `${r.name.trim()}${
                    i < user.roles.length - 1 ? ', ' : ''
                  }`;
                })}
              </span>
            </div>
          )}
        </div>
      </div>
    );

    if (linkEnabled) {
      return (
        <Link
          to={{ name: 'user', params: { username: user.username } }}
          className={c(['u-flex', linkClassName], {
            'u-truncate': type === 'compact',
          })}
          onClick={() => {
            this.props.updatePortal({ portal: undefined });
          }}
        >
          {component}
        </Link>
      );
    }

    return component;
  };
}

export const UsersListItem = connect([], () => ({
  ...portalActions(),
}))(UsersListItemComponent);
