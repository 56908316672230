import * as React from 'react';

import { GenericMasonryBlock } from '@/components/projects/Masonry/GenericMasonryBlock';
import { Masonry } from '@/components/projects/Masonry/Masonry';
import {
  getMasonryTemplatePreset,
  MasonryTemplatePreset,
} from '@/components/projects/Masonry/MasonryTemplates';
import { UserCardboard } from '@/components/users/UserCards/UserCardboard';
import { UserCardboardSkeleton } from '@/components/users/UserCards/UserCardboardSkeleton';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

interface Props {
  users: User[];
  skeleton?: boolean | number;
  cornerButtonAction?: (username: string) => void;
  cornerButtonComponents?: React.ReactNode;
  uiTestIdLoaded?: string;
}

class UserCardboards extends React.Component<Props> {
  private masonry: React.RefObject<any>;

  constructor(props: Props) {
    super(props);

    this.masonry = React.createRef();
  }

  public componentDidMount = () => {
    setTimeout(() => {
      if (this.masonry.current) {
        this.masonry.current.repack();
      }
    }, 1);
  };

  public componentDidUpdate = (prevProps: Props) => {
    const oldUsers = prevProps.users.map((u: User) => u.id).join('');
    const newUsers = this.props.users.map((u: User) => u.id).join('');

    if (oldUsers !== newUsers) {
      setTimeout(() => {
        if (this.masonry.current) {
          this.masonry.current.repack();
        }
      }, 1);
    }
  };

  public render = () => {
    const {
      users = [],
      skeleton = false,
      cornerButtonAction,
      cornerButtonComponents,
      uiTestIdLoaded,
    } = this.props;

    if (!users.length && !skeleton) {
      return null;
    }

    if (!users.length && skeleton) {
      return (
        <ul className="c-user-cardboards">
          {Array.from(Array(8).keys()).map((x) => (
            <UserCardboardSkeleton key={x} />
          ))}
        </ul>
      );
    }

    return (
      <div
        className="mb20"
        data-ui-test-id={
          isUiTest() && uiTestIdLoaded ? uiTestIdLoaded : undefined
        }
      >
        <Masonry
          ref={this.masonry}
          blocks={users}
          editable={false}
          template={getMasonryTemplatePreset(
            MasonryTemplatePreset.alternative_4
          )}
          render={(items: MasonryBlock[]) =>
            items.map((item: any, index: number) => (
              <GenericMasonryBlock
                key={item.data.id}
                item={item}
                index={items.length - index}
              >
                {cornerButtonAction && cornerButtonComponents ? (
                  <div className="relative">
                    <UserCardboard key={item.data.id} user={item.data} />
                    <button
                      type="button"
                      className="button--corner-close tablet-and-desktop-only"
                      onClick={() => cornerButtonAction(item.data.username)}
                    >
                      {cornerButtonComponents}
                    </button>
                  </div>
                ) : (
                  <UserCardboard key={item.data.id} user={item.data} />
                )}
              </GenericMasonryBlock>
            ))
          }
        />
      </div>
    );
  };
}

export { UserCardboards };
