export const formatSeconds = (duration: number): string => {
  // `duration` comes back from Cloudflare as seconds

  const h = Math.floor(duration / 3600);
  const m = Math.floor((duration - h * 3600) / 60);
  const s = Math.floor(duration - h * 3600 - m * 60);

  if (h === 0) {
    return `${`0${m}`.slice(-2)}:${`0${s}`.slice(-2)}`;
  } else {
    return `${`0${h}`.slice(-2)}:${`0${m}`.slice(-2)}:${`0${s}`.slice(-2)}`;
  }
};
