export const isALink = (el: HTMLElement) => {
  if (el.nodeName === 'A') {
    return el;
  }

  while (el.parentNode) {
    el = el.parentNode as HTMLElement;
    if (el.nodeName === 'A') {
      return el;
    }
  }

  return null;
};
