import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'unistore/react';

import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';

import { cinematicBackgroundActions } from '@/store/modules/cinematicBackground';

import { c } from '@/utils/strings/c';
import { getRouteFromUrl } from '@/utils/urls/routes';

interface Props
  extends CinematicBackgroundState,
    SizingState,
    AuthState,
    RouteComponentProps {}

interface State {
  backgroundClassName?: string;
  isImageRendered: boolean;
  isImageVisible: boolean;
  currentImageUrl?: string;
  nextImageUrl?: string;
  isNextImageLoaded: boolean;
}

class CinematicBackgroundComponent extends React.Component<Props, State> {
  public state: State = {
    backgroundClassName: undefined,
    isImageRendered: false,
    isImageVisible: false,
    currentImageUrl: undefined,
    nextImageUrl: undefined,
    isNextImageLoaded: false,
  };

  public componentDidMount = () => {
    this.setBackgroundColour();
  };

  public componentDidUpdate = (prevProps: Props) => {
    const {
      location: { pathname },
      cinematicBackground: { imageUrl },
      sizing: { isMobile },
    } = this.props;

    const {
      location: { pathname: prevPathname },
      cinematicBackground: { imageUrl: prevImageUrl },
      sizing: { isMobile: prevIsMobile },
    } = prevProps;

    const matchedRoute = getRouteFromUrl(pathname);
    const prevMatchedRoute = getRouteFromUrl(prevPathname);

    if (prevImageUrl && imageUrl && imageUrl !== prevImageUrl) {
      this.setState({ nextImageUrl: imageUrl, isNextImageLoaded: false });

      return;
    }

    if (!prevImageUrl && imageUrl) {
      this.setState({
        isImageRendered: true,
        isImageVisible: true,
        currentImageUrl: imageUrl,
      });

      return;
    }

    if (prevImageUrl && !imageUrl) {
      this.setState({ isImageVisible: false }, () => {
        setTimeout(() => this.setState({ isImageRendered: false }), 500);
      });

      return;
    }

    if (matchedRoute !== prevMatchedRoute) {
      this.setBackgroundColour();

      return;
    }

    if (isMobile !== prevIsMobile) {
      this.setBackgroundColour();
      return;
    }
  };

  private setBackgroundColour = () => {
    const {
      location: { pathname },
    } = this.props;

    const matchedRoute = getRouteFromUrl(pathname) || 'home';

    // blackRoutes
    if (matchedRoute) {
      this.setState({ backgroundClassName: 'u-bg-black' });
      return;
    }
  };

  private transitionToNextImage = () => {
    this.setState({ isNextImageLoaded: true }, () => {
      setTimeout(() => {
        this.setState({
          currentImageUrl: this.state.nextImageUrl || '',
          nextImageUrl: undefined,
          isNextImageLoaded: false,
        });
      }, 250);
    });
  };

  public render = () => {
    const {
      isImageRendered,
      isImageVisible,
      currentImageUrl,
      nextImageUrl,
      isNextImageLoaded,
      backgroundClassName = '',
    } = this.state;

    return (
      <div
        className={c([
          'u-fixed u-fixed--tl u-100vh u-100vw u-bg-transition',
          backgroundClassName,
        ])}
        style={{ zIndex: -1 }}
      >
        <div className="relative u-1/1 u-h-100">
          {nextImageUrl && (
            <div
              className={c('fill u-object-cover animate-opacity', {
                'opacity-1': isNextImageLoaded,
                'opacity-0': !isNextImageLoaded,
              })}
              style={{ zIndex: 1 }}
            >
              <ImageLoader
                src={nextImageUrl}
                className="fill u-object-cover"
                noAnimations={true}
                onLoad={this.transitionToNextImage}
              />
            </div>
          )}

          {isImageRendered && (
            <div
              className={c('fill u-object-cover animate-opacity', {
                'opacity-1': isImageVisible,
                'opacity-0': !isImageVisible,
              })}
            >
              <ImageLoader
                src={currentImageUrl || ''}
                className="fill u-object-cover"
              />
            </div>
          )}
        </div>
      </div>
    );
  };
}

export const CinematicBackground = connect(
  ['cinematicBackground', 'sizing', 'auth'],
  (store: GlobalStoreState) => ({
    ...cinematicBackgroundActions(store),
  })
)(withRouter(CinematicBackgroundComponent));
