import * as React from 'react';

import { connect } from 'unistore/react';

import { FormValidator } from '@/components/global/Forms/FormValidator/FormValidator';
import { Input } from '@/components/global/Forms/Input/Input';
import { Toast } from '@/components/global/Toaster/Toast';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import {
  EMAIL_ALREADY_IN_USE,
  EMAIL_INVALID,
  FIELD_REQUIRED,
  UNKNOWN_ERROR,
} from '@/messages/errors';

import { authActions } from '@/store/modules/auth';
import { userActions } from '@/store/modules/user';

import { debounce } from '@/utils/api/throttle';
import { isEmailAvailable, isEmailValid } from '@/utils/forms/validators';
import { t } from '@/utils/i18n/i18n';
import { track } from '@/utils/mixpanel/mixpanel';
import { c } from '@/utils/strings/c';

interface Props extends AuthActions, AuthState, UserActions, UserState {
  onComplete: () => Promise<void>;
  closeModalPortal: () => void;
}

interface State {
  isChecking: boolean;
  isProcessing: boolean;
  email: string;
}

class WorkshopAddEmailComponent extends React.Component<Props, State> {
  public state: State = {
    isChecking: false,
    isProcessing: false,
    email: '',
  };

  private handleSubmit = async () => {
    const { onComplete, closeModalPortal } = this.props;
    const { email } = this.state;

    if (email === '') {
      return;
    }

    try {
      const updatedUser: any = {
        email,
      };

      this.setState({ isProcessing: true });

      await this.props.updateCurrentUser(updatedUser);

      track('update_account', { type: 'Email' });

      closeModalPortal();

      await onComplete();
    } catch (e) {
      new Toast({ body: UNKNOWN_ERROR, failure: true }).dispatch();
    }
  };

  private submitValidation = (state: State) => {
    const errors: any = {};
    const { email } = state;

    if (!email) {
      errors.email = FIELD_REQUIRED;
    }

    return errors;
  };

  private changeEmailValidation = async (state: State) => {
    const errors: any = {};
    const { email } = state;

    if (email.length > 0) {
      const isValid = isEmailValid(email);

      if (!isValid) {
        errors.email = EMAIL_INVALID;
        return errors;
      }

      const available = await isEmailAvailable(email);

      if (!available) {
        errors.email = EMAIL_ALREADY_IN_USE;
        return errors;
      }

      this.setState({ email });
    }

    return errors;
  };

  public render = () => {
    const {
      closeModalPortal,
      auth: { user },
    } = this.props;

    const { isProcessing } = this.state;

    const details = {
      ...user,
      email: '',
    };

    return (
      <div>
        <p className="f-text-2 u-white mb24">
          {t('To attend this workshop, enter your email.')}
        </p>

        <FormValidator
          submit={this.handleSubmit}
          submitValidation={this.submitValidation}
          changeValidation={this.changeEmailValidation}
          initialState={details}
        >
          {({
            submit,
            changeValidation,
            errors,
            state = {},
            updateForm,
          }: {
            submit(): void;
            changeValidation(): void;
            updateForm(key: string): (event: any) => void;
            updateFormArray: any;
            errors: any;
            state: any;
          }) => {
            const { isChecking } = this.state;

            return (
              <>
                <form
                  onSubmit={submit}
                  onChange={debounce(changeValidation, 500)}
                >
                  <Input
                    id="email"
                    placeholder={t('Email')}
                    type="text"
                    required={true}
                    value={state.email}
                    error={errors.email}
                    onChange={(e: any) => {
                      updateForm('email')({ value: e.value });
                      this.setState({ email: e.value });
                    }}
                    autoComplete="off"
                    checking={isChecking}
                    errorStyle="u-light-grey u-text-center f-text-5 pb16"
                    inputClassName={c(
                      'u-white dds-input dds-input--theme-dark u-bg-very-light-grey b--none mv16 u-border-radius',
                      {
                        mv16: !errors.email,
                      }
                    )}
                  />
                </form>

                <div className="u-flex mt32 u-justify-end">
                  <Button
                    type={ButtonType.action}
                    onClick={() => closeModalPortal()}
                    size={ButtonSize.m}
                    buttonStyle={ButtonStyle.dark}
                    className="mr12"
                    text={t('Cancel')}
                    disabled={isProcessing}
                  />

                  <Button
                    type={ButtonType.action}
                    onClick={() => this.handleSubmit()}
                    size={ButtonSize.m}
                    buttonStyle={ButtonStyle.default}
                    text={t('Attend')}
                    disabled={
                      !!errors.email || state.email.length === 0 || isChecking
                    }
                    isLoading={isProcessing}
                  />
                </div>
              </>
            );
          }}
        </FormValidator>
      </div>
    );
  };
}

export const WorkshopAddEmail = connect(
  ['auth', 'user'],
  (store: GlobalStoreState) => ({
    ...authActions(store),
    ...userActions(),
  })
)(WorkshopAddEmailComponent);
