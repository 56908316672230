import * as React from 'react';

import { useHistory } from 'react-router-dom';

import { CardStyle } from '@/components/global/Cards/CardStyle';
import { SkeletonNew } from '@/components/global/Skeleton/SkeletonNew';

import { AttendButtonState } from '@/components/design_system/AttendButton/AttendButtonState';
import { AttendButtonType } from '@/components/design_system/AttendButton/AttendButtonType';
import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { IconSize } from '@/enums/IconSize';
import { UserAccess } from '@/enums/UserAccess';

import { routes } from '@/routes';
import { parser } from '@/routes/parser';

import { t } from '@/utils/i18n/i18n';

interface Props {
  attendButtonType: AttendButtonType;
  buttonSize: ButtonSize;
  buttonIconSize: IconSize;
  buttonTextClassName?: string;
  cardStyle?: CardStyle;
  isButtonLoading?: boolean;
  isComponentLoading?: boolean;
  showStartFreeTrialWhileLoggedOut?: boolean;

  workshop?: Workshop;
  sessionId?: string;
  isInProgress?: boolean;
  hasWorkshopEnded?: boolean;

  room?: Room;
  hasRoomEnded?: boolean;

  handleAttend: (e: any) => Promise<void>;
  handleUnattend: (e: any) => Promise<void>;

  handleShowOnDemandPlayer?: () => void;

  isUnsubscribed: boolean;
  isAttending: boolean;
  isAuthorised: boolean;
  currentUser?: User;

  isWorkshopV2Page?: boolean;

  isFreeWorkshopBadgeDisplayed?: boolean;
}

export const AttendButton = (props: Props) => {
  const {
    attendButtonType,
    buttonSize,
    cardStyle,
    buttonIconSize,
    workshop,
    sessionId,
    isInProgress,
    isButtonLoading = false,
    isComponentLoading = false,
    isUnsubscribed,
    isAttending,
    isAuthorised,
    currentUser,
    showStartFreeTrialWhileLoggedOut = false,
    room,
    hasRoomEnded,
    buttonTextClassName,
    handleShowOnDemandPlayer,
    hasWorkshopEnded,
    isWorkshopV2Page,
    isFreeWorkshopBadgeDisplayed,
  } = props;

  const history = useHistory();

  let attendButtonState: AttendButtonState = AttendButtonState.loading;
  let hasElapsed: boolean = false;
  let isHost: boolean = false;

  // Workshops
  if (attendButtonType === AttendButtonType.workshop && workshop) {
    hasElapsed =
      typeof hasWorkshopEnded !== 'undefined'
        ? hasWorkshopEnded
        : workshop.hasElapsed;
    isHost = !!currentUser && currentUser.id === workshop.mentor.id;
  }

  // Rooms
  if (attendButtonType === AttendButtonType.room && room) {
    hasElapsed = !!hasRoomEnded;
    isHost = !!currentUser && currentUser.id === room.owner.id;
  }

  if (isComponentLoading) {
    attendButtonState = AttendButtonState.loading;
  } else if (workshop?.isFree && !workshop?.hasElapsed) {
    attendButtonState = AttendButtonState.isFree;
  } else if (!isAuthorised && showStartFreeTrialWhileLoggedOut) {
    attendButtonState = AttendButtonState.startFreeTrial;
  } else if (isUnsubscribed && !hasElapsed && !isInProgress) {
    attendButtonState = AttendButtonState.unsubscribed;
  } else if (!isComponentLoading) {
    if (isInProgress) {
      attendButtonState = AttendButtonState.inProgress;
    } else if (hasElapsed && !workshop?.hasAvailableCatchUpMedia) {
      attendButtonState = AttendButtonState.ended;
    } else if (isHost) {
      attendButtonState = AttendButtonState.hosting;
    } else if (isAttending && !hasElapsed) {
      attendButtonState = AttendButtonState.attending;
    } else if (hasElapsed && workshop?.hasAvailableCatchUpMedia) {
      attendButtonState = AttendButtonState.onDemand;
    } else if (workshop?.attendeeCount === workshop?.attendeeLimit) {
      attendButtonState = AttendButtonState.isFull;
    } else {
      attendButtonState = AttendButtonState.notAttending;
    }
  }

  let buttonType: ButtonType = ButtonType.action;
  let onClick: any = () => {};
  let buttonStyle: ButtonStyle = ButtonStyle.default;
  let text: string = '';
  let content: React.ReactNode | undefined = undefined;
  let disabled: boolean = false;
  let iconId: string | undefined = undefined;
  let linkTo: string | undefined = undefined;

  // Getting the variant directly from localStorage to
  // avoid importing the store
  const abTestVariant: string | null = localStorage.getItem('ab_test_variant');

  switch (attendButtonState) {
    case AttendButtonState.loading:
      buttonType = ButtonType.action;
      buttonStyle = ButtonStyle.outlineLight;
      text = t('Attend');
      content = (
        <SkeletonNew
          elementClassName="f-text-2"
          fillClassName="c-logged-in-homepage__skeleton"
          fitToText={t('Attend')}
        />
      );
      disabled = true;
      break;
    case AttendButtonState.unsubscribed:
    case AttendButtonState.startFreeTrial:
      buttonType = ButtonType.link;
      buttonStyle =
        isWorkshopV2Page ||
        attendButtonState === AttendButtonState.startFreeTrial
          ? ButtonStyle.default
          : ButtonStyle.outlineLight;
      text =
        cardStyle === CardStyle.profile
          ? ''
          : attendButtonState === AttendButtonState.startFreeTrial
          ? t('Start free trial')
          : isWorkshopV2Page
          ? abTestVariant === '1'
            ? t('Get started')
            : t('Join for free')
          : t('Attend');
      iconId =
        isWorkshopV2Page ||
        attendButtonState === AttendButtonState.startFreeTrial
          ? undefined
          : 'attend';

      if (attendButtonType === AttendButtonType.workshop) {
        linkTo = `${
          isAuthorised && isUnsubscribed
            ? routes.optimisedJoinFlowChoosePlan
            : !isAuthorised && workshop?.isFree
            ? routes.register
            : routes.register
        }?to=${
          workshop
            ? encodeURIComponent(
                parser({
                  name: 'workshop',
                  params: { workshopSlug: workshop.slug },
                })
              )
            : ''
        }${abTestVariant === '2' ? '&isFromWorkshop=true' : ''}`;
      } else if (attendButtonType === AttendButtonType.room) {
        linkTo = `${
          isAuthorised && isUnsubscribed
            ? routes.optimisedJoinFlowChoosePlan
            : routes.register
        }?to=${
          room
            ? encodeURIComponent(
                parser({
                  name: 'room',
                  params: { roomSlug: room.slug },
                })
              )
            : ''
        }`;
      }

      break;
    case AttendButtonState.inProgress:
      buttonType = ButtonType.link;
      text = cardStyle === CardStyle.profile ? t('Join') : t('Join on Zoom');
      iconId = 'zoom';

      if (attendButtonType === AttendButtonType.workshop && workshop) {
        const joinNowLink = parser({
          name: 'workshopJoinNow',
          params: {
            workshopSlug: workshop.slug,
            sessionId: sessionId
              ? sessionId.replace('workshopSession_', '')
              : '',
          },
        });

        linkTo =
          isAuthorised && isUnsubscribed
            ? `${routes.optimisedJoinFlowChoosePlan}?to=${
                workshop
                  ? encodeURIComponent(
                      parser({
                        name: 'workshop',
                        params: { workshopSlug: workshop.slug },
                      })
                    )
                  : ''
              }`
            : isAuthorised && isUnsubscribed && currentUser?.hasPastSub
            ? routes.optimisedJoinFlowChoosePlan
            : joinNowLink;
      }

      if (attendButtonType === AttendButtonType.room && room) {
        // TODO
      }

      break;
    case AttendButtonState.ended:
      buttonType = ButtonType.action;
      buttonStyle = ButtonStyle.outlineLight;
      text = t('Ended');
      disabled = true;
      break;
    case AttendButtonState.hosting:
      buttonType = ButtonType.action;
      buttonStyle = ButtonStyle.outlineLight;
      text = t('Hosting');
      iconId = 'tick-circle';
      disabled = true;
      break;
    case AttendButtonState.attending:
      buttonType = ButtonType.action;
      onClick = props.handleUnattend;
      buttonStyle = ButtonStyle.outlineLight;
      text = t('Going');
      iconId = 'tick-circle';
      break;
    case AttendButtonState.isFree:
      if (isAttending) {
        buttonType = ButtonType.action;
        onClick = props.handleUnattend;
        buttonStyle = ButtonStyle.outlineLight;
        text = t('Going');
        iconId = 'tick-circle';
      } else {
        buttonType = ButtonType.action;
        onClick = isAuthorised
          ? props.handleAttend
          : () =>
              history.push(
                `${
                  routes.optimisedJoinFlowSignUpYearly
                }?to=${encodeURIComponent(
                  parser({
                    name: 'workshop',
                    params: { workshopSlug: workshop?.slug },
                  })
                )}&isFree=true`
              );
        buttonStyle = ButtonStyle.outlineLight;
        text =
          workshop?.isFree && !isFreeWorkshopBadgeDisplayed
            ? t('Free')
            : t('Attend');
        iconId = 'attend';
      }
      break;
    case AttendButtonState.notAttending:
      buttonType = ButtonType.action;
      onClick = props.handleAttend;
      buttonStyle = ButtonStyle.outlineLight;
      text = t('Attend');
      iconId = 'attend';
      break;
    case AttendButtonState.onDemand:
      const workshopPath: string = parser({
        name: 'workshop',
        params: {
          workshopSlug: !!workshop ? workshop.slug : '',
        },
      });

      const encodedWorkshopPath: string = encodeURIComponent(workshopPath);

      const isAuthorisedAndUnsub: boolean =
        isAuthorised &&
        !!currentUser &&
        !currentUser?.hasPastSub &&
        currentUser.subscriptionTier === null;

      const isAuthorisedAndSub: boolean =
        isAuthorised && !!currentUser && currentUser.subscriptionTier !== null;

      const isWorkshopFree: boolean = workshop?.userAccess === UserAccess.Free;

      const onWorkshopCard: boolean =
        (isAuthorisedAndSub || isWorkshopFree) &&
        typeof handleShowOnDemandPlayer === 'undefined';

      const onWorkshopPage: boolean =
        (isAuthorisedAndSub || isWorkshopFree) &&
        typeof handleShowOnDemandPlayer !== 'undefined';

      if (!isAuthorised && !isWorkshopFree) {
        buttonType = ButtonType.link;
        linkTo = `${routes.register}?to=${encodedWorkshopPath}`;
      } else if (isAuthorisedAndUnsub && !isWorkshopFree) {
        buttonType = ButtonType.link;
        linkTo = `${routes.optimisedJoinFlowChoosePlan}?to=${encodedWorkshopPath}`;
      } else if (onWorkshopCard) {
        buttonType = ButtonType.link;
        linkTo = `${workshopPath}`;
      } else if (onWorkshopPage) {
        buttonType = ButtonType.action;
        onClick = handleShowOnDemandPlayer;
      }

      text = t(isAuthorised ? 'Watch now' : 'Try for free');
      iconId = 'play';

      break;
    case AttendButtonState.isFull:
      text = t(`Full (${workshop?.attendeeCount}/${workshop?.attendeeLimit})`);
      disabled = true;
      buttonType = ButtonType.action;
      buttonStyle = ButtonStyle.outlineLight;
  }

  return (
    <Button
      type={buttonType}
      linkTo={linkTo}
      onClick={onClick}
      size={buttonSize}
      buttonStyle={buttonStyle}
      text={text}
      content={content}
      disabled={disabled}
      iconId={iconId}
      iconSize={buttonIconSize}
      isLoading={isButtonLoading}
      buttonTextClassName={buttonTextClassName}
    />
  );
};
