import * as React from 'react';

import { getEventTimeRemaining } from '@/utils/date/date-manipulation';
import { t } from '@/utils/i18n/i18n';

interface Props {
  workshop: Workshop;
}

class WorkshopTimeRemaining extends React.Component<Props> {
  public render = () => {
    const { workshop } = this.props;

    return (
      <p className="u-danger pt4">
        {t(`${getEventTimeRemaining(workshop.end)} mins remaining`)}
      </p>
    );
  };
}

export { WorkshopTimeRemaining };
