import * as React from 'react';

import { Icon } from '@/components/global/Icon/Icon';

import { CategoryType } from '@/enums/CategoryType';
import { IconSize } from '@/enums/IconSize';

import { WORKSHOP_ROOM_CATEGORY_NAME } from '@/utils/constants';

export const getCategoryIcon = ({
  category,
  size = IconSize.s,
  width,
  height,
  className = '',
}: {
  category: Category;
  size?: IconSize;
  width?: string;
  height?: string;
  className?: string;
}) => (
  <Icon
    key={category.id}
    id={`category-${category.slug}`}
    size={!!width || !!height ? undefined : size}
    width={width}
    height={height}
    className={className}
  />
);

export const getDisplayCategories = (categories?: Category[]) =>
  categories
    ? categories.filter(
        (cat: Category) =>
          (cat.categoryType !== CategoryType.flag &&
            cat.categoryType !== CategoryType.entity) ||
          cat.name === WORKSHOP_ROOM_CATEGORY_NAME
      )
    : [];
