import { Currency } from '@/enums/Currency';

import { notProduction } from '@/utils/featureToggles';

// ALLOW LISTS
const INSTRUCTOR_DASHBOARD_USERNAME_ALLOWLIST_STAGE: string[] = [];
const INSTRUCTOR_DASHBOARD_USERNAME_ALLOWLIST_PROD: string[] = [];

export const INSTRUCTOR_DASHBOARD_USERNAME_ALLOWLIST: string[] = notProduction()
  ? INSTRUCTOR_DASHBOARD_USERNAME_ALLOWLIST_STAGE
  : INSTRUCTOR_DASHBOARD_USERNAME_ALLOWLIST_PROD;

// CURRENCY
export const PAYOUT_CURRENCY: Currency = Currency.usd;

// LINKS
export const INSTRUCTOR_DASHBOARD_HELP_EMAIL_ADDRESS: string =
  'taha@daisie.com';
export const INSTRUCTOR_DASHBOARD_HELP_EMAIL_SUBJECT: string =
  'Instructor Support';
export const INSTRUCTOR_DASHBOARD_HELP_PAYMENT_INQUIRIES_EMAIL_ADDRESS: string =
  'payments@daisie.com';
export const INSTRUCTOR_DASHBOARD_HELP_PAYMENT_INQUIRIES_EMAIL_SUBJECT: string =
  'Payment Inquiries';

export const INSTRUCTOR_DASHBOARD_SCHEDULE_NEXT_CLASS_LINK: string = ''; // TODO
export const INSTRUCTOR_DASHBOARD_REFER_LINK: string = ''; // TODO
