import * as React from 'react';

import { connect } from 'unistore/react';

import { tooltipActions } from '@/store/modules/tooltip';

import { c } from '@/utils/strings/c';

interface Props extends TooltipState, TooltipActions {}

interface State {
  isAnimatedIn: boolean;
  isAnimatedOut: boolean;
  offsetX: number;
  offsetY: number;
  hasTooltipInitialHover: boolean;
}

class GlobalTooltipComponent extends React.Component<Props, State> {
  // CONFIG
  private ACTIONABLE_DELAY: number = 250;
  private ACTIONABLE_DELAY_LONG: number = 2000;

  // REFS
  private refContainer: React.RefObject<HTMLDivElement>;

  // TIMERS
  private timeout?: NodeJS.Timeout;
  // These timers are for "actionable" tooltips, those that have buttons you can move towards and use
  // The "long" timer is the timeout when the user doesn't move onto the tooltip after initially hovering over it
  // The other timer, is the timeout used when the user has hovered over the tooltip and has moved away from it
  private actionableExitTimeout?: NodeJS.Timeout;
  private actionableExitTimeoutLong?: NodeJS.Timeout;

  public state: State = {
    isAnimatedIn: false,
    isAnimatedOut: false,
    offsetX: 0,
    offsetY: 0,
    hasTooltipInitialHover: false,
  };

  constructor(props: Props) {
    super(props);

    this.refContainer = React.createRef();
  }

  public componentDidUpdate = (prevProps: Props) => {
    const {
      tooltip: { isVisible: prevIsVisible },
    } = prevProps;

    const {
      tooltip: { isVisible },
    } = this.props;

    if (!prevIsVisible && isVisible) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.setState({ isAnimatedIn: false, isAnimatedOut: false }, () => {
        setTimeout(() => {
          this.setState({ isAnimatedIn: true }, () => {
            this.calculateOffsets();
          });
        }, 100);
      });
    }

    if (prevIsVisible && !isVisible) {
      this.setState({ isAnimatedOut: true }, () => {
        this.timeout = setTimeout(() => {
          this.setState({
            isAnimatedIn: false,
            isAnimatedOut: false,
            hasTooltipInitialHover: false,
          });
        }, 250);
      });
    }
  };

  private calculateOffsets = () => {
    if (!this.refContainer.current) {
      return;
    }

    const { width, height } = this.refContainer.current.getBoundingClientRect();

    this.setState({
      offsetX: width / 2,
      offsetY: height,
    });
  };

  public render = () => {
    const {
      tooltip: { posX, posY, parentOffsetX, content, isActionable },
    } = this.props;

    const { isAnimatedIn, isAnimatedOut, offsetX, offsetY } = this.state;

    if (!content) {
      return null;
    }

    const useVisibleClass = isAnimatedIn && !isAnimatedOut;
    const useInvisibleClass = !isAnimatedIn || isAnimatedOut;

    const visibleClass = 'opacity-1';
    const invisibleClass = 'opacity-0';

    return (
      <>
        <div
          ref={this.refContainer}
          className={c('c-global-tooltip animate-opacity', {
            [visibleClass]: useVisibleClass,
            [invisibleClass]: useInvisibleClass,
          })}
          // TODO: put top offset into configurable param
          style={{
            left: posX - offsetX + parentOffsetX,
            top: posY - offsetY - 12,
            zIndex: isActionable ? 9999 : undefined,
          }}
        >
          {content}
        </div>

        {isActionable && (
          <button
            type="button"
            className="u-100vh u-100vw u-fixed u-fixed--tl u-cursor-default"
            style={{ zIndex: 9998 }}
            onMouseOver={() => {
              if (!this.state.hasTooltipInitialHover) {
                this.actionableExitTimeoutLong = setTimeout(() => {
                  this.props.hideTooltip();
                }, this.ACTIONABLE_DELAY_LONG);

                return;
              }

              if (this.actionableExitTimeout) {
                clearTimeout(this.actionableExitTimeout);
              }

              this.actionableExitTimeout = setTimeout(() => {
                this.props.hideTooltip();
              }, this.ACTIONABLE_DELAY);
            }}
            onMouseOut={() => {
              if (!this.state.hasTooltipInitialHover) {
                if (this.actionableExitTimeoutLong) {
                  clearTimeout(this.actionableExitTimeoutLong);
                }

                this.setState({ hasTooltipInitialHover: true });
              }

              if (this.actionableExitTimeout) {
                clearTimeout(this.actionableExitTimeout);
              }
            }}
          />
        )}
      </>
    );
  };
}

export const GlobalTooltip = connect(
  ['tooltip'],
  (store: GlobalStoreState) => ({
    ...tooltipActions(store),
  })
)(GlobalTooltipComponent);
