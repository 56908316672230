import { Http } from '@/utils/api/Http';

export const makePriceLookGood = (price: number): number =>
  Math.floor(price / 100) * 100 + 99;

const getStudentDataFromData = (data: APIStudentData): StudentData => {
  const {
    attributes: { isStudent },
  } = data;

  return { isStudent };
};

export const checkValidStudentEmail = async (
  email: string
): Promise<boolean> => {
  try {
    const { data } = await new Http(`/students/email/${email}`).get();

    const studentData = getStudentDataFromData(data);

    return studentData.isStudent;
  } catch (e) {
    return false;
  }
};
