import * as React from 'react';

import imgSpriteBlack from '@/components/global/LoadingSymbol/daisie-flipbook-black.png';
import imgSpriteWhite from '@/components/global/LoadingSymbol/daisie-flipbook-white.png';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

import { c } from '@/utils/strings/c';

interface Props {
  className?: string;
  size?: 's' | 'sm' | 'm' | 'l';
  colour?: 'black' | 'white';
}

interface State {
  frame: number;
}

const sizes = {
  s: 14,
  m: 20,
  l: 40,
};

class LoadingSymbol extends React.Component<Props, State> {
  private timer: NodeJS.Timeout | undefined;
  private maxFrames: number = 70;
  private size: number;

  public state: State = {
    frame: isUiTest() ? Math.round(this.maxFrames / 2) : 0,
  };

  constructor(props: Props) {
    super(props);

    this.size = sizes[this.props.size || 'm'];
  }

  public componentDidMount = () => {
    if (isUiTest()) return;

    this.timer = setInterval(() => {
      const { frame } = this.state;

      this.setState({
        frame: frame < this.maxFrames - 1 ? frame + 1 : 0,
      });
    }, 50);
  };

  public componentWillUnmount = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
  };

  public render = () => {
    const { className = '', colour } = this.props;
    const { frame } = this.state;

    let imgSprite = imgSpriteBlack;

    if (colour === 'white') {
      imgSprite = imgSpriteWhite;
    }

    return (
      <div
        className={c(['u-inline-block', className])}
        style={{
          width: `${this.size}px`,
          height: `${this.size}px`,
          backgroundImage: `url('${imgSprite}')`,
          backgroundPositionX: `-${frame * this.size}px`,
          backgroundSize: `auto ${this.size}px`,
        }}
      />
    );
  };
}

export { LoadingSymbol };
