import * as React from 'react';

import { connect } from 'unistore/react';
import widont from 'widont';

import { Head } from '@/components/global/Head/Head';
import { Icon } from '@/components/global/Icon/Icon';

import { careersListings } from '@/pages/Careers/CareersListings';

import { t } from '@/utils/i18n/i18n';

interface Props extends SizingState {}

const CareersComponent = ({ sizing }: Props) => {
  const noListingsAvailable = !careersListings.length;

  return (
    <div className="c-careers wrap">
      <Head title="Careers" pathname={t('/careers')} />
      <div className="u-flex c-careers-section-container mt32@m">
        <div className="u-1/2@l mr16@l">
          <div className="featured-video c-careers-iframe-container u-border-radius">
            <iframe
              title={t('Join Daisie')}
              allow="autoplay; fullscreen"
              src="https://player.vimeo.com/video/647997922?title=0&byline=0&portrait=0&autoplay=1&muted=1&loop=1&background=1"
              frameBorder="0"
              style={{
                position: 'absolute',
                transform: `scale(${
                  sizing.isMobile
                    ? 1.1
                    : window.innerWidth < 1090
                    ? 1.3
                    : window.innerWidth < 1313
                    ? 3
                    : 2.5
                })`,
                opacity: '0.7',
              }}
            />

            <div className="featured-video__overlay u-flex u-align-center u-justify-center">
              <h1 className="f-yell-1 u-light-grey">Join the team</h1>
            </div>
          </div>
        </div>
        <div className="u-1/2@l u-overflow-auto ml16@l ph60@m u-scrollbar-disabled">
          <div className="mt48@m mt32@s mt60@m mt80@l">
            <h1 className="f-title-1 u-bold mb16">
              {widont(t("We're looking for creators"))}
            </h1>

            <div>
              <p className="c-careers-sentence f-text-1 f-lynstone-light-3 u-light-grey">
                {t(
                  `Daisie is a social/educational product based on collaborative learning. We provide creators around the world with a space to learn, grow, and network together. Our mission is to connect the creative world, empowering them with new skills and the ability to form a community.`
                )}
              </p>
            </div>

            <div className="mt48 mb32">
              <h2 className="f-title-1 u-bold mb16">
                {widont(t('Open Roles'))}
              </h2>

              <div>
                {noListingsAvailable ? (
                  <p className="f-text-1 u-devil-grey">
                    {t('Sorry, no roles currently available.')}
                  </p>
                ) : (
                  <div className="mb44">
                    {careersListings.map((listing: CareerListItem) => (
                      <a
                        href={listing.applyUrl}
                        className="c-careers-roles-container b--charcoal-grey pv20 ph32 u-flex u-flex-column mb20"
                        target="_blank"
                        rel="noopener noreferrer"
                        key={listing.id}
                      >
                        <div className="u-very-light-grey f-text-2 u-flex u-justify-between u-align-center">
                          <div className="">
                            <p className="u-grey f-text-5 u-bold mb4">
                              {`${listing.category} · ${' '}${
                                listing.location
                              }`}
                            </p>
                            <p>{listing.title}</p>
                          </div>
                          <div className="u-flex u-border-radius p6 u-bg-light-grey">
                            <Icon
                              id="arrow-link"
                              width="12"
                              height="12"
                              className="u-flex-hold u-black"
                            />
                          </div>
                        </div>
                      </a>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="mt48 mb32">
            <h2 className="f-title-1 u-bold mb16">
              {widont(t('Why Daisie?'))}
            </h2>
            <p className="c-careers-sentence f-text-1 f-lynstone-light-3 u-light-grey">
              {t(
                'Traditional Art Schools are expensive, exclusive and offline. The emerging alternative (video-based courses) are single-player, providing variable quality content without accountability, interactivity or feedback. We truly feel that there is an opportunity for a rethink.'
              )}
            </p>
          </div>

          <div className="mt48 mb32">
            <h2 className="f-title-1 u-bold mb16">
              {widont(t('Working at Daisie'))}
            </h2>
            <div className="c-careers-sentence f-text-1 f-lynstone-light-3 u-light-grey">
              <p className="mb16">
                {t(
                  "Daisie is built by a small, brilliant team. We're all creators ourselves: artists, dancers, entrepreneurs, developers, designers & more."
                )}
              </p>
              <p className="mb16">
                {t(
                  "We're remote-first — you can work from anywhere in the 🌎 — with a flexible work schedule. "
                )}
              </p>
              <p className="mb16">
                {t(
                  'As a team we move quickly, we iterate and we ship. We do this not by taking on everything, but by hiring a great team, focusing on the things that matter, and ignoring things that don’t. We are efficient, meeting-light, and focused on building.'
                )}
              </p>
              <p className="mb16">
                {t(
                  "We're early, so you'll have an opportunity to shape not just our product, but the company itself; who we work with, and how we work together."
                )}
              </p>
              <p>
                {t(
                  "Every person at Daisie raises the bar — and we're so excited for you to continue that tradition."
                )}
              </p>
            </div>
          </div>

          <div className="mt48 mb32 mb80">
            <h2 className="f-title-2 u-bold mb24">
              {widont(
                t(
                  "We're building Daisie to be even more compelling than in-person education. How?"
                )
              )}
            </h2>
            <div className="c-careers-sentence f-text-1 f-lynstone-light-3 u-light-grey">
              <ol>
                <li className="mb24">
                  <span className="u-bold u-block pb2@s u-very-light-grey pb4@m">
                    {t('It’s live and on-demand')}
                  </span>
                  {t(
                    'Daisie classes happen live and are available to watch on demand at any time thereafter.'
                  )}
                </li>
                <li className="mb24">
                  <span className="u-bold u-block pb2@s u-very-light-grey pb4@m">
                    {t('It’s full-service')}
                  </span>
                  {t(
                    'there’s accountability and feedback in video and audio classes through a live instructor, discussions, take-home tasks and a community of peers.'
                  )}
                </li>
                <li className="mb24">
                  <span className="u-bold u-block pb2@s u-very-light-grey pb4@m">
                    {t('Industry-leading instructors')}
                  </span>
                  {t(
                    'we work with the best creators, regardless of whether they’re already teaching elsewhere or if they’ve never taught a class before. If this were in-person, students would never get regular access to practicing experts.'
                  )}
                </li>
                <li>
                  <span className="u-block pb2@s u-very-light-grey u-bold pb4@m">
                    {t('Community')}
                  </span>
                  {t(
                    'one of the best parts about school is the people you meet. Daisie is where creators of all backgrounds come to learn, network and have fun together. We pride ourselves in creating a safe and inclusive space that not only educates, but encourages students to take the leap into creating.'
                  )}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Careers = connect(['sizing'], () => ({}))(CareersComponent);
