import { getRolesFromData } from '@/store/helpers';

import { Http } from '@/utils/api/Http';

const rolesState: RolesState = {
  roles: {
    allRoles: [],
  },
};

const rolesActions = () => ({
  fetchRoles: async (state: any, force?: boolean) => {
    if (!force && state.roles.allRoles.length) {
      return state;
    }

    let allRoles: Role[] = [];

    try {
      const { data } = await new Http('/roles').get<APIArray<APIRole>>();

      allRoles = getRolesFromData(data).sort((a: any, b: any) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      });
    } catch (e) {
      throw e;
    }

    return {
      roles: {
        allRoles,
      },
    };
  },
});

export { rolesState, rolesActions };
