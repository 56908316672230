import accents from 'remove-accents';

import { getRandomInt } from '@/utils/strings/number-manipulation';

const baseUsernames = [
  'creator',
  'maker',
  'collaborator',
  'inventor',
  'crafter',
  'user',
  'player',
  'builder',
];

const MAX_USERNAME = 30;
const SUFFIX_NUMBER_LENGTH = 6;
const MAX_USERNAME_STRING = MAX_USERNAME - (SUFFIX_NUMBER_LENGTH + 1);

const trimName = (input: string): string => {
  if (!input || input.length < MAX_USERNAME_STRING) {
    return input;
  }

  const splitUsername = input.split('_');

  if (splitUsername.length === 1) {
    return input.slice(0, MAX_USERNAME_STRING);
  }

  const lastWordRemoved = splitUsername.slice(0, -1).join('_');
  if (lastWordRemoved.length < MAX_USERNAME_STRING) {
    return lastWordRemoved;
  }

  return trimName(lastWordRemoved);
};

const usernameGenerator = (name: string, appendDateTime?: boolean) => {
  let username = '';

  // remove trailing white space
  username = name.trim();

  // convert to lowercase
  username = username.toLowerCase();

  // convert foreign characters to roman alphabet
  username = accents.remove(username);

  // convert all white space to underscores
  username = username.replace(/\s/g, '_');

  // remove punctuation and numbers
  username = username.replace(/\W/g, '').replace(/[0-9]/g, '');

  // remove words from really long names
  username = trimName(username);

  // if empty string or just underscores, use default base name
  username = !username.replace(/_/g, '')
    ? baseUsernames[getRandomInt(0, baseUsernames.length - 1)]
    : username;
  if (appendDateTime) {
    // append date time to the end of the username
    username = `${username}_${Math.round(+new Date() / 1000)}`;
  } else {
    // append random number to the end of the username
    username = `${username}_${getRandomInt(
      1,
      Math.pow(10, SUFFIX_NUMBER_LENGTH)
    )}`;
  }

  return username;
};

export { usernameGenerator, baseUsernames };
