import * as React from 'react';

import { SkeletonNew } from '@/components/global/Skeleton/SkeletonNew';

import { AvatarSize } from '@/components/design_system/Avatar/AvatarSize';
import { AvatarStack } from '@/components/design_system/AvatarStack/AvatarStack';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props {
  isLoading: boolean;
  workshop?: Workshop;
  attendees: User[];
  isAttendeesLoaded: boolean;
  className?: string;
}

class WorkshopAttendeesMobile extends React.Component<Props> {
  private classNameSkeleton: string = 'c-logged-in-homepage__skeleton';

  public render = () => {
    const {
      isLoading,
      workshop,
      attendees,
      isAttendeesLoaded,
      className = '',
    } = this.props;

    const attendeesCount =
      isAttendeesLoaded && attendees ? attendees.length - 1 : 0;

    return isLoading ? (
      <SkeletonNew
        elementClassName="f-text-3"
        containerClassName={className}
        fillClassName={this.classNameSkeleton}
        fitToText="Hosted by information"
      />
    ) : (
      <div className={c(['u-flex u-align-center u-justify-center', className])}>
        <AvatarStack
          users={attendees}
          maxAvatars={3}
          className="mr8"
          avatarSize={AvatarSize.card_attending}
        />
        {workshop && workshop.mentor && (
          <p className="f-text-3 u-white u-truncate" style={{ maxWidth: 250 }}>
            {t('Hosted by')}{' '}
            <span className="u-bold">{workshop.mentor.primaryName}</span>
            {isAttendeesLoaded && attendeesCount > 0 && (
              <span className="ml4">
                + {attendeesCount} {t('going')}
              </span>
            )}
          </p>
        )}
      </div>
    );
  };
}

export { WorkshopAttendeesMobile };
