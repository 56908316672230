import * as React from 'react';

import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'unistore/react';
import widont from 'widont';

import DiscordButton from '@/components/global/DiscordButton/DiscordButton';
import { Head } from '@/components/global/Head/Head';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { SiteFooter } from '@/components/global/SiteFooter/SiteFooter';
import { Toast } from '@/components/global/Toaster/Toast';
import imgReviewStars from '@/components/global/WorkshopReview/images/review-stars.svg';
import { WorkshopReview } from '@/components/global/WorkshopReview/WorkshopReview';
import { SectionApp } from '@/components/marketing/HomeCategoryPageSections/SectionApp/SectionApp';
import { SectionPromo } from '@/components/marketing/HomeCategoryPageSections/SectionPromo/SectionPromo';
import { PressLinks } from '@/components/marketing/PressLinks/PressLinks';
import WorkshopMentorCarousel from '@/components/workshops/WorkshopMentorCarousel';
import WorkshopReviewV2 from '@/components/workshops/WorkshopReviewV2';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';
import { Carousel } from '@/components/design_system/Carousel/Carousel';

import { ACCOUNT_DELETION_SUCCESS } from '@/messages/messages';

import imgDaisieMobile from '@/pages/Category/images/daisie-mobile.png';
import { LoggedOutHomepageVariantA } from '@/pages/LoggedOutHomepage/HeroVariants/LoggedOutHomepageVariantA';
import { LoggedOutHomepageVariantB } from '@/pages/LoggedOutHomepage/HeroVariants/LoggedOutHomepageVariantB';
import { LoggedOutHomepageVariantC } from '@/pages/LoggedOutHomepage/HeroVariants/LoggedOutHomepageVariantC';
import { LoggedOutHomepageVariantStudents } from '@/pages/LoggedOutHomepage/HeroVariants/LoggedOutHomepageVariantStudents';

import { routes } from '@/routes';
import { parser } from '@/routes/parser';

import { getWorkshopsFromData } from '@/store/helpers';

import { Http } from '@/utils/api/Http';
import { HOURS, REVIEW_RATING, REVIEW_SAMPLE_SIZE } from '@/utils/constants';
import { hotjarTrigger } from '@/utils/hotjar/hotjar';
import { t } from '@/utils/i18n/i18n';
import { track } from '@/utils/mixpanel/mixpanel';
import { c } from '@/utils/strings/c';
import { getRouteFromUrl } from '@/utils/urls/routes';

interface Props
  extends SizingState,
    SubscriptionState,
    ABTestState,
    RouteComponentProps {
  isStudentPromoPage?: boolean;
}

interface State {
  // Parallax
  parallaxB: number;
  workshops: Workshop[];
  isWorkshopsLoaded?: boolean;
}

class LoggedOutHomepageComponent extends React.Component<Props, State> {
  // CONFIG
  private ENABLE_PARALLAX: boolean = false;

  // REFS
  private refBody: React.RefObject<HTMLDivElement>;

  public state: State = {
    // Parallax
    parallaxB: 0,
    workshops: [],
    isWorkshopsLoaded: false,
  };

  constructor(props: Props) {
    super(props);

    this.refBody = React.createRef();
  }

  public componentDidMount = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);

      setTimeout(() => {
        const isCookieBannerVisible =
          this.props.sizing.cookieBannerHeight !== 0;

        if (isCookieBannerVisible) {
          const pageLayout = document.querySelector('.page-layout');

          if (pageLayout) {
            pageLayout.addEventListener('scroll', this.onScroll);
          }
        }

        window.addEventListener('scroll', this.onScroll);
      }, 1);
    }, 1000);

    if (window.location.href.includes(`delete-account=true`)) {
      new Toast({ body: t(ACCOUNT_DELETION_SUCCESS) }).dispatch();
    }

    this.fetchWorkshops();

    // Hotjar
    hotjarTrigger('logged_out_homepage');

    // Mixpanel tracking
    const {
      location: { pathname, search },
      abtest: { variant },
    } = this.props;

    const now = new Date();

    const queryParams = new URLSearchParams(search);
    const skipAnalyticsQueryParam = !!queryParams.get('skipAnalytics');

    const matchedRoute = getRouteFromUrl(pathname);

    const mixpanelEventLastTriggered = localStorage.getItem(
      'logged_out_homepage_viewed_last_triggered'
    );

    const allowTriggerMixpanelEvent: boolean =
      !mixpanelEventLastTriggered ||
      (!!mixpanelEventLastTriggered &&
        now.getTime() > Number(mixpanelEventLastTriggered) + 6 * HOURS);

    if (
      !skipAnalyticsQueryParam &&
      matchedRoute === 'home' &&
      typeof variant !== 'undefined' &&
      allowTriggerMixpanelEvent
    ) {
      track('logged_out_homepage_viewed', {
        variant,
      });

      localStorage.setItem(
        'logged_out_homepage_viewed_last_triggered',
        now.getTime().toString()
      );
    }
  };

  public componentWillUnmount = () => {
    const isCookieBannerVisible = this.props.sizing.cookieBannerHeight !== 0;

    if (isCookieBannerVisible) {
      const pageLayout = document.querySelector('.page-layout');

      if (pageLayout) {
        pageLayout.removeEventListener('scroll', this.onScroll);
      }
    }
    window.removeEventListener('scroll', this.onScroll);
  };

  private onScroll = () => {
    this.onScrollParallax();
  };

  private onScrollParallax = () => {
    if (
      window.scrollY >= 0 &&
      window.scrollY < window.innerHeight / 2 &&
      this.ENABLE_PARALLAX
    ) {
      this.setState({
        parallaxB: (0 - window.scrollY) / 2,
      });
    }
  };

  private fetchWorkshops = async () => {
    this.setState({ isWorkshopsLoaded: false });

    try {
      const { data } = await new Http(
        `/workshops?pageSize=10&pageNumber=1&sortBy=workshopStartDate&filters=isUpcoming`
      ).get<APIObject<APIWorkshop[]>>();

      const upcomingWorkshops = getWorkshopsFromData(data).reverse();

      this.setState({
        workshops: upcomingWorkshops,
        isWorkshopsLoaded: true,
      });
    } catch (e) {}
  };

  private renderHero = () => {
    const {
      sizing: { isMobile },
      isStudentPromoPage,
      abtest: { variant },
      subscription: { productsAndPrices, userCurrency },
    } = this.props;

    return (
      <div className="c-category-page-logged-out__hero_container u-overflow-hidden">
        <div
          className={c('c-category-page-logged-out__hero', {
            'c-category-page-logged-out__hero--alt': isStudentPromoPage,
          })}
        >
          {isStudentPromoPage ? (
            <LoggedOutHomepageVariantStudents />
          ) : (
            <LoggedOutHomepageVariantB
              variant={0}
              userCurrency={userCurrency}
              productsAndPrices={productsAndPrices}
            />
          )}
        </div>

        {!isMobile && (
          <PressLinks
            theme="dark"
            className="mb32 u-1/1@s m0@m pv64@m absolute absolute--bottom-center"
          />
        )}
      </div>
    );
  };

  private renderPromoCopyOne = (isMobile: boolean, isTablet: boolean) => (
    <section className="wrap u-text-center u-text-left@m mv200@m mv60@s ml100@m">
      <h1 className="f-lynstone-bold-1a ">
        <span
          className="u-block"
          style={{
            lineHeight: isMobile ? '3rem' : isTablet ? '4rem' : '6.5rem',
          }}
        >
          {t('Creative learning.')}
        </span>
        {t('Reimagined.')}
      </h1>
      <h3
        className="mt16@s mt32@m mt44@l mb44 f-lynstone-book-2a u-grey u-7/8@m u-5/8@l"
        style={{
          lineHeight: isMobile ? '1.6rem' : isTablet ? '2rem' : '2.3rem',
        }}
      >
        {t(
          'We partner with instructors from top-named brands like Pixar, Apple, Vogue, Sony and Adobe to teach on Daisie. Our instructors are professionals, not lecturers.'
        )}
      </h3>
    </section>
  );

  private renderPromoCopyTwo = (isMobile: boolean, isTablet: boolean) => (
    <section className="wrap u-flex@l u-4/5@l">
      <h1
        className="f-lynstone-bold-1a"
        style={{
          lineHeight: isMobile ? '2.8rem' : isTablet ? '3.5rem' : '5rem',
        }}
      >
        {t('Don’t just take our word for it.')}
      </h1>
      <h3
        className="f-lynstone-book-2a ml80@l u-grey u-1/2@l mt60@l"
        style={{
          lineHeight: isMobile ? '1.6rem' : isTablet ? '2rem' : '2.3rem',
        }}
      >
        {t(
          'Students give Daisie an average rating 4.8 of 5. See what they say about our classes.'
        )}
        <span className="u-flex u-align-center">
          {t(`${REVIEW_RATING}`)}
          <ImageLoader
            className="u-inline mh12@m mb2"
            src={imgReviewStars}
            alt="stars"
          />
          {t(`${REVIEW_SAMPLE_SIZE.toLocaleString()}+ reviews.`)}
        </span>
      </h3>
    </section>
  );

  private renderWorkshopCarousel = () => {
    const { workshops, isWorkshopsLoaded } = this.state;
    const {
      sizing: { isMobile },
    } = this.props;

    if (!workshops) return null;

    const workshopCards = workshops.map((w: Workshop) => (
      <Link
        key={w.id}
        to={parser({ name: 'workshop', params: { workshopSlug: w.slug } })}
        className="u-1/1 dds-carousel__item"
      >
        <div className="mb16 u-border-radius u-border-radius--s u-overflow-hidden pb16by9 u-h-0 relative">
          <div className="dds-carousel__shadow" />
          <ImageLoader
            src={w.coverMedia}
            className="u-1/1 u-object-cover"
            style={{
              height: isMobile ? '14rem' : '28rem',
            }}
            convertToWebp={true}
          />
          <div className="u-flex u-flex-column absolute absolute--bl ml20@m ml12@s mb12@m pb2@m mb4@s u-z-index-2 u-width-fill-available">
            <div className="u-flex mb6">
              <div className="u-flex-hold mr12">
                <ImageLoader
                  src={w.mentor.avatar}
                  className="u-rounded c-section-workshops__workshop_carousel__card__avatar"
                />
              </div>

              <div className="u-flex-1 u-flex u-flex-column u-justify-center">
                <p className="f-text-3 u-light-grey">{t('Hosted by')}</p>
                <p className="f-text-3 u-bold">{w.mentor.name}</p>
              </div>
            </div>
            <p
              className={c(
                'u-bold mb8 mr12 u-truncate u-truncate--1l u-overflow-hidden',
                {
                  'f-title-3': !isMobile,
                  'f-text-1': isMobile,
                }
              )}
            >
              {w.title}
            </p>
          </div>
        </div>
      </Link>
    ));

    const workshopSkeleton = Array.from(Array(10).keys()).map(
      (_, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className="u-1/1 dds-carousel__item">
          <div className="mb16 u-border-radius u-border-radius--s u-overflow-hidden pb16by9 u-h-0 u-bg-charcoal-grey" />
        </div>
      )
    );

    return (
      <div className="mt80 mb60 mb128@l">
        <div
          className={c('u-text-center', {
            wrap: isMobile,
          })}
        >
          <h1 className="f-lynstone-bold-1a">
            {t('Live workshops every day')}
          </h1>
          <h3 className="mt16 mb44 f-lynstone-book-2a">
            {widont(t('See what’s coming up soon on Daisie'))}
          </h3>
        </div>

        <Carousel>
          {isWorkshopsLoaded ? workshopCards : workshopSkeleton}
        </Carousel>

        <Button
          type={ButtonType.link}
          linkTo={routes.calendar}
          text={t('More in calendar')}
          buttonStyle={ButtonStyle.outlineLight}
          size={ButtonSize.m}
          className="u-fit-content mhauto mt16@s"
          buttonTextClassName="ph12"
        />
      </div>
    );
  };

  private renderJoinTheCommunity = () => (
    <div className="wrap u-flex@m u-align-center@m mv200@m u-7/8@m u-flex-row-reverse@m">
      <div className="u-flex-1@m ml44@m">
        <div className="u-text-center@s">
          <h2 className="f-lynstone-bold-1a mb24@m mb16@s">
            {t('Join the community')}
          </h2>
          <p className="f-lynstone-book-2a">
            {t('Join our Discord to meet likeminded creators.')}
          </p>

          <div className="u-flex@s u-justify-center@s">
            <DiscordButton copy="Go to the Discord" />
          </div>
        </div>
      </div>
      <div className="u-flex-1@m mr44@m pv60@s">
        <ImageLoader
          src={imgDaisieMobile}
          style={{ marginRight: 0, marginLeft: 'auto' }}
        />
      </div>
    </div>
  );

  public render = () => {
    const {
      abtest: { variant },
      sizing: { isMobile, isTablet },
      isStudentPromoPage,
    } = this.props;

    const { parallaxB } = this.state;

    return (
      <>
        <Head />

        {this.renderHero()}

        <div
          ref={this.refBody}
          className="c-logged-out-homepage u-bg-black u-white relative"
          style={{
            transform: `translateY(${parallaxB}px)`,
            marginBottom: `${parallaxB}px`,
          }}
        >
          {/* {this.renderPromoCopyOne(isMobile, isTablet)} */}

          <WorkshopMentorCarousel
            columns={isMobile ? 1 : isTablet ? 3 : 4}
            cardOffset={this.props.sizing.isMobile ? -64 : 10}
            category="film"
            titleClass="f-lynstone-bold-1"
          />

          {this.renderWorkshopCarousel()}

          {this.renderJoinTheCommunity()}

          <SectionApp />

          <SectionPromo />

          {/* {this.renderPromoCopyTwo(isMobile, isTablet)} */}

          <WorkshopReview />

          {/* Footer + registration from */}
          <section
            className={c(
              'c-logged-out-homepage__register_footer u-bg-black u-white pb128',
              {
                pt128: variant !== 1,
              }
            )}
          >
            <div className="wrap">
              <div className="mhauto u-3/5@m u-2/5@l">
                <h2 className="f-lynstone-bold-1b mb32 u-text-center">
                  {t('Join a global and inclusive community')}
                </h2>

                <p className="mb44 f-lynstone-regular-3 u-text-center mh32@m">
                  {t('Creativity for everyone, at every level.')}
                </p>

                <div className="">
                  {isStudentPromoPage ? (
                    <LoggedOutHomepageVariantStudents formOnly={true} />
                  ) : (
                    <LoggedOutHomepageVariantB formOnly={true} />
                  )}
                </div>
              </div>
            </div>
          </section>

          <SiteFooter />
        </div>
      </>
    );
  };
}

export const LoggedOutHomepage = connect(
  ['sizing', 'subscription', 'abtest'],
  () => ({})
)(withRouter(LoggedOutHomepageComponent));
