import * as React from 'react';

import { Link } from '@/components/global/Link/Link';
import { UserVerified } from '@/components/users/UserVerified/UserVerified';

import { Avatar } from '@/components/design_system/Avatar/Avatar';
import { AvatarSize } from '@/components/design_system/Avatar/AvatarSize';

import { parser } from '@/routes/parser';

import { c } from '@/utils/strings/c';

interface Props {
  user: User;
  cornerHoverItems?: React.ReactNode;
  avatarSize?: AvatarSize;
  className?: string;
  close?: () => void;
}

class UsersCarouselItem extends React.Component<Props> {
  public render = () => {
    const {
      user,
      cornerHoverItems,
      avatarSize,
      className = '',
      close,
    } = this.props;
    const { username, primaryName } = user;

    const link = (
      <Link
        to={parser({
          name: 'user',
          params: { username },
        })}
        className="u-flex u-flex-column u-link-white"
        onClick={close}
      >
        <Avatar user={user} size={avatarSize} />

        <p className="u-flex u-align-center f-text-3 u-bold u-word-break mt16 u-text-center">
          <span className="mlauto mrauto">
            {primaryName}
            <UserVerified user={user} className="relative t2" />
          </span>
        </p>
      </Link>
    );

    return cornerHoverItems ? (
      <div className={c(['dds-button__show_on_hover relative', className])}>
        {link}
        <div className="absolute absolute--tr">{cornerHoverItems}</div>
      </div>
    ) : (
      link
    );
  };
}

export { UsersCarouselItem };
