/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { HTTPStatusCode } from '@/enums/HTTPStatusCode';

import {
  USERNAME_IN_USE,
  USERNAME_INVALID,
  USERNAME_INVALID_START_CHAR,
} from '@/messages/errors';

import { Http } from '@/utils/api/Http';
import {
  INVALID_FIRST_CHARACTERS_USERNAME,
  MAX_EMAIL,
  MAX_USERNAME,
} from '@/utils/constants';

export const isUsernameAvailable = async (username: string) => {
  // TODO - debug CORS like issue on iOS for this request
  return true;
  // try {
  //   await new Http(`/users/username/${username}`).head();
  //   return false;
  // } catch (e) {
  //   return e.status === HTTPStatusCode.NOT_FOUND_404;
  // }
};

export const isEmailAvailable = async (email: string) => {
  // TODO - debug CORS like issue on iOS for this request
  return true;
  // try {
  //   await new Http(`/users/email/${email}`).head();
  //   return false;
  // } catch (e) {
  //   return e.status === HTTPStatusCode.NOT_FOUND_404;
  // }
};

export const isPhoneNumberAvailable = async (phoneNumber: string) => {
  try {
    await new Http(`/users/phoneNumber/${phoneNumber}`).head();
    return false;
  } catch (e) {
    return e.status === HTTPStatusCode.NOT_FOUND_404;
  }
};

export const isEmailValid = (email: string) => {
  if (email.length > MAX_EMAIL) return false;

  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const isUsernameValid = (username: string) => {
  const re = /^[a-z][0-9a-z_.]*$/;
  return re.test(String(username));
};

export const findUsernameErrors = async (username: string) => {
  const firstChar = username[0];

  if (INVALID_FIRST_CHARACTERS_USERNAME.includes(firstChar)) {
    return USERNAME_INVALID_START_CHAR;
  } else if (!isUsernameValid(username)) {
    return USERNAME_INVALID;
  } else {
    if (username.length <= MAX_USERNAME) {
      const available = await isUsernameAvailable(username);

      if (!available) {
        return USERNAME_IN_USE;
      }
    }

    return null;
  }
};
