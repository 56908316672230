import * as React from 'react';

import { connect } from 'unistore/react';

import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import imgAppStore from '@/components/marketing/HomeCategoryPageSections/SectionApp/app-store.svg';
import ImgLearnAnytimeAnywhere from '@/components/marketing/HomeCategoryPageSections/SectionApp/learn-anytime-anywhere.png';

import { APP_STORE_LINK } from '@/utils/constants';
import { t } from '@/utils/i18n/i18n';

interface Props extends SizingState, SubscriptionState {
  community: Category;
  communityName: string;
  ctaButton?: React.ReactNode;
  isCategoryHomepage?: boolean;
}

class SectionAppComponent extends React.Component<Props> {
  public render = () => {
    const {
      sizing: { isMobile },
      ctaButton = null,
      isCategoryHomepage,
    } = this.props;

    return (
      <div className="u-bg-brand@m pv64@m u-text-center@s">
        <div className="u-flex@m wrap">
          {!isMobile && (
            <div className="u-1/2@m">
              <ImageLoader
                src={ImgLearnAnytimeAnywhere}
                alt=""
                className="mb32@s"
              />
            </div>
          )}

          <div className="u-1/2@m u-flex u-align-center u-justify-center">
            <div className="ml128@m">
              <p className="f-lynstone-bold-1a mb32@m mb16@s">
                {t(
                  isCategoryHomepage
                    ? 'All at your own pace'
                    : 'At your own pace'
                )}
              </p>

              <p className="f-lynstone-book-2a mb32@m mb16@s u-grey@m u-white@s">
                {t(
                  isCategoryHomepage
                    ? 'Explore classes, meet creators and build a schedule on your terms.'
                    : 'Explore workshops, connect with creators and build a schedule at your own pace.'
                )}
              </p>

              {!isMobile ? (
                <a
                  href={APP_STORE_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={imgAppStore}
                    alt={t('Download on the App Store')}
                    className="pwe-industry-page__app_store u-2/5@s"
                  />
                </a>
              ) : (
                <div className="u-flex@s u-justify-center@s">{ctaButton}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export const SectionApp = connect(['sizing', 'subscription'])(
  SectionAppComponent
);
