export const getQueryParam = (key: string, props: any): string | undefined => {
  let result;

  const { location } = props;
  const search = location ? location.search : undefined;

  if (search) {
    const params = new URLSearchParams(search);
    const token = params.get(key);

    if (token) {
      result = token;
    }
  }

  return result;
};
