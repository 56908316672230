import React from 'react';

import { Box, BoxProps, Image, Text } from '@chakra-ui/react';

import { pxToRem } from '@/styles/chakra-theme/metrics';

interface Props extends BoxProps {
  image: string;
  name: string;
  flag: string;
  text: string;
  rotateDeg: number;
  bgColor: string;
  textColor: string;
  nameColor: string;
}

export const UserFeedbackCard = ({
  image,
  name,
  flag,
  text,
  rotateDeg = 0,
  bgColor = 'black',
  textColor = 'white',
  nameColor,
  ...props
}: Partial<Props>) => (
  <Box
    h={[432, 546]}
    minW={[290, 360]}
    maxW={[290, 360]}
    p={pxToRem(22)}
    borderRadius={14}
    bg={bgColor}
    transform={`rotate(${rotateDeg}deg)`}
    {...props}
  >
    <Image
      alt="illustrative image"
      src={image}
      w={120}
      h={120}
      objectFit="cover"
    />

    <Text
      fontSize={[34, 48]}
      fontWeight={400}
      mt={pxToRem(12)}
      color={nameColor || textColor}
      lineHeight="normal"
    >
      {name}
    </Text>

    <Image src={flag} width={12} />

    <Text
      fontSize={[18, 24]}
      fontWeight={400}
      color={textColor}
      mt={pxToRem(24)}
    >
      {text}
    </Text>
  </Box>
);
