import * as React from 'react';

import { SkeletonNew } from '@/components/global/Skeleton/SkeletonNew';

import { c } from '@/utils/strings/c';

export const FeedHeader = ({
  title,
  subtitle,
  isLoaded,
  secondaryContent,
  splitOnMobile,
  className = '',
}: {
  title: string;
  subtitle?: string;
  isLoaded: boolean;
  secondaryContent?: React.ReactElement[] | React.ReactElement;
  splitOnMobile?: boolean;
  className?: string;
}) => {
  if (!isLoaded) {
    return (
      <SkeletonNew
        elementClassName="f-title-1 u-bold"
        containerClassName="mb32"
        fitToText={title}
      />
    );
  }

  return (
    <div
      className={c(['mb32', className], {
        'u-flex@m u-split@m u-align-center@m':
          !!secondaryContent && !splitOnMobile,
        'u-flex u-split u-align-center': !!secondaryContent && splitOnMobile,
      })}
    >
      <div>
        <h2 className="f-title-1 u-bold">{title}</h2>
        {subtitle && <p className="f-text-2 u-grey mt4">{subtitle}</p>}
      </div>
      {secondaryContent && secondaryContent}
    </div>
  );
};
