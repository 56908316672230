import * as React from 'react';

import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props {
  speakingUsers?: User[];
  organiser?: User;
  nudgeUp?: boolean;
}

interface State {}

class CardLiveRoomSpeakers extends React.Component<Props, State> {
  public render = () => {
    const { speakingUsers = [], organiser, nudgeUp } = this.props;

    if (!speakingUsers || (speakingUsers && speakingUsers.length === 0)) {
      return null;
    }

    const isHostSpeaking =
      !!organiser &&
      !!speakingUsers &&
      speakingUsers.map((u: User) => u.id).includes(organiser.id);

    const speakingUsersWithoutHost = organiser
      ? speakingUsers.filter((u: User) => u.id !== organiser.id)
      : speakingUsers;

    let user1: User | undefined = undefined;
    let user2: User | undefined = undefined;
    let user3: User | undefined = undefined;

    if (speakingUsers.length >= 3) {
      user1 =
        isHostSpeaking && organiser
          ? speakingUsersWithoutHost[0]
          : speakingUsers[1];
      user2 = isHostSpeaking && organiser ? organiser : speakingUsers[0];
      user3 =
        isHostSpeaking && organiser
          ? speakingUsersWithoutHost[1]
          : speakingUsers[2];
    } else if (speakingUsers.length === 2) {
      user1 = isHostSpeaking && organiser ? organiser : speakingUsers[0];
      user2 =
        isHostSpeaking && organiser
          ? speakingUsersWithoutHost[0]
          : speakingUsers[1];
      user3 = undefined;
    } else if (speakingUsers.length === 1) {
      user1 = isHostSpeaking && organiser ? organiser : speakingUsers[0];
      user2 = undefined;
      user3 = undefined;
    }

    return (
      <div
        className={c(
          'c-card__room_speakers fill u-flex u-flex-column u-align-center u-justify-center u-z-index-1',
          {
            'c-card__room_speakers--nudge-up': nudgeUp,
          }
        )}
      >
        <div
          className={c('mb16 c-card__room_speakers__bubbles', {
            'c-card__room_speakers__bubbles--1up': speakingUsers.length === 1,
            'c-card__room_speakers__bubbles--2up': speakingUsers.length === 2,
            'c-card__room_speakers__bubbles--3up': speakingUsers.length >= 3,
          })}
        >
          {user1 && (
            <div className="c-card__room_speakers__bubble c-card__room_speakers__bubble--1">
              <ImageLoader
                src={user1.avatar}
                style={{ border: `${user1.ringColour} 2px solid` }}
              />

              <div
                className="c-card__room_speakers__bubble__pulse"
                style={{ background: user1.ringColour }}
              />
            </div>
          )}

          {user2 && (
            <div className="c-card__room_speakers__bubble c-card__room_speakers__bubble--2">
              <ImageLoader
                src={user2.avatar}
                style={{ border: `${user2.ringColour} 2px solid` }}
              />

              <div
                className="c-card__room_speakers__bubble__pulse"
                style={{ background: user2.ringColour }}
              />
            </div>
          )}

          {user3 && (
            <div className="c-card__room_speakers__bubble c-card__room_speakers__bubble--3">
              <ImageLoader
                src={user3.avatar}
                style={{ border: `${user3.ringColour} 2px solid` }}
              />

              <div
                className="c-card__room_speakers__bubble__pulse"
                style={{ background: user3.ringColour }}
              />
            </div>
          )}
        </div>

        <p className="f-text-3">
          {isHostSpeaking && organiser ? (
            <>
              <span className="u-bold">{organiser?.primaryName}</span>{' '}
              {speakingUsersWithoutHost.length > 0
                ? `+ ${speakingUsersWithoutHost.length} ${t('more speaking')}`
                : t('is speaking')}
            </>
          ) : (
            <>
              {speakingUsersWithoutHost.length}{' '}
              {speakingUsersWithoutHost.length === 1
                ? t('person')
                : t('people')}{' '}
              {t('speaking')}
            </>
          )}
        </p>
      </div>
    );
  };
}

export { CardLiveRoomSpeakers };
