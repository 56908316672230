import * as React from 'react';

import { c } from '@/utils/strings/c';
import { mapRange } from '@/utils/strings/number-manipulation';

interface Props {
  value: number;
  onChange: (val: number) => void;
  className?: string;
}

class Scrubber extends React.Component<Props> {
  private scrubberContainer: React.RefObject<HTMLDivElement>;
  private scrubberHead: React.RefObject<HTMLDivElement>;

  private scrubberActive: boolean = false;
  private scrubberLeft: number | undefined;
  private scrubberWidth: number | undefined;

  constructor(props: Props) {
    super(props);

    this.scrubberContainer = React.createRef<HTMLDivElement>();
    this.scrubberHead = React.createRef<HTMLDivElement>();
  }

  public componentDidMount = () => {
    if (this.scrubberContainer.current) {
      // Scrubber - click track to change position
      this.scrubberContainer.current.addEventListener(
        'click',
        this.handleScrubberTrackClick
      );
    }

    if (this.scrubberHead.current) {
      // Scrubber - Click/touch start
      this.scrubberHead.current.addEventListener(
        'mousedown',
        this.handleScrubberHeadDown
      );
      this.scrubberHead.current.addEventListener(
        'touchstart',
        this.handleScrubberHeadDown
      );

      // Scrubber - Click/touch end
      this.scrubberHead.current.addEventListener(
        'mouseup',
        this.handleScrubberHeadUp
      );
      this.scrubberHead.current.addEventListener(
        'touchend',
        this.handleScrubberHeadUp
      );
    }

    // Scrubber - Click/touch move
    window.addEventListener('mousemove', this.handleScrubberHeadMove);
    window.addEventListener('touchmove', this.handleScrubberHeadMove);

    // Scrubber - Click/touch end outside of scrubber
    window.addEventListener('mouseup', this.handleScrubberHeadUp);
    window.addEventListener('touchend', this.handleScrubberHeadUp);
  };

  public componentWillUnmount = () => {
    if (this.scrubberContainer.current) {
      this.scrubberContainer.current.removeEventListener(
        'click',
        this.handleScrubberTrackClick
      );
    }

    if (this.scrubberHead.current) {
      this.scrubberHead.current.removeEventListener(
        'mousedown',
        this.handleScrubberHeadDown
      );

      this.scrubberHead.current.removeEventListener(
        'touchstart',
        this.handleScrubberHeadDown
      );

      this.scrubberHead.current.removeEventListener(
        'mouseup',
        this.handleScrubberHeadUp
      );

      this.scrubberHead.current.removeEventListener(
        'touchend',
        this.handleScrubberHeadUp
      );
    }

    window.removeEventListener('mousemove', this.handleScrubberHeadMove);
    window.removeEventListener('touchmove', this.handleScrubberHeadMove);

    window.removeEventListener('mouseup', this.handleScrubberHeadUp);
    window.removeEventListener('touchend', this.handleScrubberHeadUp);
  };

  private handleScrubberTrackClick = (e: any) => {
    if (!e.target || !this.scrubberContainer.current) return;

    const { clientX: mousePosition, target } = e;
    const { className } = target;
    const { current: scrubberContainer } = this.scrubberContainer;

    if (className === 'scrubber__track') {
      const trackLeft = scrubberContainer.getBoundingClientRect().left;
      const trackWidth = scrubberContainer.getBoundingClientRect().width;

      const value = this.calculateScrubberPos(
        mousePosition,
        trackLeft,
        trackWidth
      );

      this.props.onChange(value);
    }
  };

  private handleScrubberHeadDown = (e: any) => {
    if (!this.scrubberContainer.current) return;

    e.preventDefault();

    const { current: scrubberContainer } = this.scrubberContainer;

    this.scrubberActive = true;
    this.scrubberLeft = scrubberContainer.getBoundingClientRect().left;
    this.scrubberWidth = scrubberContainer.getBoundingClientRect().width;
  };

  private handleScrubberHeadUp = () => {
    if (!this.scrubberActive) return;

    this.scrubberActive = false;
    this.scrubberLeft = undefined;
    this.scrubberWidth = undefined;
  };

  private handleScrubberHeadMove = (e: any) => {
    if (
      !this.scrubberActive ||
      !this.scrubberLeft ||
      !this.scrubberWidth ||
      !this.scrubberHead.current
    )
      return;

    const { clientX: mousePosition, touches } = e;

    const value = this.calculateScrubberPos(
      mousePosition || touches[0].clientX,
      this.scrubberLeft,
      this.scrubberWidth
    );

    this.props.onChange(value);
  };

  private calculateScrubberPos = (
    inputPosition: number,
    scrubberLeft: number,
    scrubberWidth: number
  ): number => {
    let scrubberPos = mapRange(
      inputPosition,
      scrubberLeft,
      scrubberLeft + scrubberWidth,
      0.0,
      1.0
    );

    if (scrubberPos < 0.0) {
      scrubberPos = 0.0;
    } else if (scrubberPos > 1.0) {
      scrubberPos = 1.0;
    }

    return scrubberPos;
  };

  public render = () => {
    const { className, value } = this.props;

    return (
      <div
        className={c('scrubber', {
          [`${className}`]: !!className,
        })}
      >
        <div className="scrubber__track" ref={this.scrubberContainer}>
          <div
            className="scrubber__head"
            style={{
              left: this.scrubberHead.current
                ? `calc(${value * 100.0}% - ${
                    this.scrubberHead.current.getBoundingClientRect().width / 2
                  }px)`
                : 'calc(0% - 15px)',
            }}
            ref={this.scrubberHead}
          >
            <div className="scrubber__head__inner" />
          </div>
        </div>
      </div>
    );
  };
}

export { Scrubber };
