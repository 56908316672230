import { PromoType } from '@/enums/PromoType';
import React from 'react';
import { useParams } from 'react-router-dom';
import { PromoBogo } from './PromoBogo';

const Promo = () => {
  const params = useParams();
  const { promoType }: any = params;
  const searchParams = new URLSearchParams(window.location.search);
  const promoCode = searchParams.get('promotionalCode');

  return (
    <>{promoType === PromoType.bogo && <PromoBogo promoCode={promoCode} />}</>
  );
};

export default Promo;
