import * as React from 'react';

import { connect } from 'unistore/react';

import { Icon } from '@/components/global/Icon/Icon';
import { LoadingSymbol } from '@/components/global/LoadingSymbol/LoadingSymbol';
import { OnDemandPlayer } from '@/components/ondemand/OnDemandPlayer';

import { IconSize } from '@/enums/IconSize';
import { OnDemandMediaType } from '@/enums/OnDemandMediaType';

import { getOnDemandMediasForWorkshop } from '@/store/helpers/onDemandHelpers';

import { c } from '@/utils/strings/c';

interface Props extends SizingState {
  workshopId?: string;
  onClose: () => void;
  onClickPrevious: () => void;
  onClickNext: () => void;
}

interface State {
  isRendered: boolean;
  isVisible: boolean;
  isLoading: boolean;
  onDemandMediaCatchup?: OnDemandMedia;
  isOnDemandPlayerVisible: boolean;
  isMobile: boolean;
}

class ClassPageVideoPlayerComp extends React.Component<Props, State> {
  public state: State = {
    isRendered: false,
    isVisible: false,
    isLoading: false,
    onDemandMediaCatchup: undefined,
    isOnDemandPlayerVisible: false,
    isMobile: false,
  };

  public componentDidMount = () => {
    // Cache the `sizing.isMobile` prop in the component state,
    // so that changing a mobile device orientation doesn't cause
    // the player to unmount

    const {
      sizing: { isMobile },
    } = this.props;

    this.setState({ isMobile });
  };

  public componentDidUpdate = (prevProps: Props) => {
    const { workshopId: prevWorkshopId } = prevProps;
    const { workshopId } = this.props;

    if (!prevWorkshopId && !!workshopId) {
      this.showPlayer();
    } else if (prevWorkshopId !== workshopId) {
      this.setState({ isRendered: false }, () => {
        this.setState({ isRendered: true });
      });
    }
  };

  private showPlayer = () => {
    this.setState({ isRendered: true }, () => {
      setTimeout(() => {
        this.setState({ isVisible: true }, () => {
          document.body.classList.add('prevent-scroll-all');
          this.fetchStreamUrl();
        });
      }, 1);
    });
  };

  private hidePlayer = () => {
    this.setState({ isVisible: false }, () => {
      setTimeout(() => {
        this.setState(
          {
            isRendered: false,
            onDemandMediaCatchup: undefined,
            isOnDemandPlayerVisible: false,
          },
          () => {
            document.body.classList.remove('prevent-scroll-all');
            this.props.onClose();
          }
        );
      }, 250);
    });
  };

  private fetchStreamUrl = async () => {
    this.setState({ isLoading: true });

    const { workshopId: workshopUuid } = this.props;

    try {
      if (!workshopUuid) {
        throw new Error();
      }

      const onDemandMedias = await getOnDemandMediasForWorkshop({
        workshopUuid,
      });

      let onDemandMediaCatchup = onDemandMedias.find(
        (odm: OnDemandMedia) => odm.type === OnDemandMediaType.WorkshopSamsonCut
      );

      // If there's no Samson cut, default to a catch up
      if (!onDemandMediaCatchup) {
        onDemandMediaCatchup = onDemandMedias.find(
          (odm: OnDemandMedia) => odm.type === OnDemandMediaType.WorkshopCatchUp
        );
      }

      if (
        !onDemandMediaCatchup ||
        (onDemandMediaCatchup && !onDemandMediaCatchup.readyToStream)
      ) {
        throw new Error();
      }

      this.setState(
        {
          isLoading: false,
          onDemandMediaCatchup,
        },
        () => {
          this.setState({ isOnDemandPlayerVisible: true });
        }
      );
    } catch (e) {
      // TODO: show toast
    }
  };

  public render = () => {
    const {
      isRendered,
      isVisible,
      isLoading,
      onDemandMediaCatchup,
      isOnDemandPlayerVisible,
      isMobile,
    } = this.state;

    if (!isRendered) {
      return null;
    }

    return (
      <div
        className={c(
          'animate-opacity u-fixed u-fixed--tl u-1/1 u-h-100vh u-bg-less-translucent-black',
          {
            'opacity-1': isVisible,
            'opacity-0 pointer-events-none': !isVisible,
          }
        )}
        // TODO: use class here
        style={{ zIndex: 9999 }}
      >
        {isLoading || !onDemandMediaCatchup ? (
          <LoadingSymbol
            colour="white"
            size="l"
            className="absolute absolute--mid-center"
          />
        ) : (
          <OnDemandPlayer
            className="absolute absolute--mid-center u-z-index-1"
            onDemandMedia={onDemandMediaCatchup}
            onClose={() => {
              if (isMobile) {
                this.hidePlayer();
              }
            }}
            isVisible={isOnDemandPlayerVisible}
            useMobilePlayer={isMobile}
            hideCloseButton={true}
          />
        )}

        <button
          type="button"
          onClick={() => this.hidePlayer()}
          className="u-white absolute absolute--tr mt32 mr32 u-hide@s"
        >
          <Icon id="clear" size={IconSize.s} className="" />
        </button>
      </div>
    );
  };
}

export const ClassPageVideoPlayer = connect(['sizing'], () => ({}))(
  ClassPageVideoPlayerComp
);
