import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { connect } from 'unistore/react';

import { Toast } from '@/components/global/Toaster/Toast';
import OriginalsQuizEmoji from '@/components/originals/OriginalsQuizEmoji';

import { DaisieWordmarkColour } from '@/enums/DaisieWordmarkColour';

import { ORIGINALS_QUIZ_PROGRESS_FAIL } from '@/messages/errors';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

import { Button } from '../design_system/Button/Button';
import { ButtonSize } from '../design_system/Button/ButtonSize';
import { ButtonType } from '../design_system/Button/ButtonType';
import { Icon } from '../global/Icon/Icon';
import { AnimatedWordmark } from '../global/Wordmark/AnimatedWordmark';

interface Answer {
  title: string;
  isCorrect: boolean;
  image?: {
    uuid: string;
    processorPath: string;
  };
}

interface Props extends SizingState {
  quiz: Quiz;
  onQuizEnd: (skip?: boolean) => void;
  onQuizDismiss: () => void;
  episodes: OnDemandMedia[];
}

const OriginalsQuizComponent = ({
  quiz,
  onQuizEnd,
  sizing,
  onQuizDismiss,
  episodes,
}: Props) => {
  const params = useParams();

  const { onDemandMediaId }: any = params;

  const [isQuizTitleVisible, setQuizTitleVisible] = useState(true);
  const [shouldAnimateTitleExit, setAnimateTitleExit] = useState(false);
  const [score, setScore] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState<any[]>([]);
  const [showEmojis, setShowEmoji] = useState(false);
  const [highlightAnswers, setHighlightAnswers] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [answeredIndexes, setAnsweredIndexes] = useState<number[]>([]);

  const correctAnswers = quiz.questions[currentQuestion]?.answers.filter(
    (a) => a?.isCorrect
  );

  const correctlyAnswered = selectedAnswers.filter(
    (a) => quiz.questions[currentQuestion]?.answers[a]?.isCorrect
  );

  const isCorrectAnswer = correctlyAnswered.length === correctAnswers.length;

  const handleSubmittedAnswer = () => {
    if (isCorrectAnswer) {
      setScore(score + 1);
    }

    setAnsweredIndexes((prevState) => [...prevState, currentQuestion]);

    const nextQuestion = currentQuestion + 1;

    if (selectedAnswers.length !== 0 && nextQuestion <= quiz.questions.length) {
      setShowEmoji(true);
      setHighlightAnswers(true);

      setTimeout(() => {
        setSelectedAnswers([]);
        setShowEmoji(false);
        setHighlightAnswers(false);
        nextQuestion <= quiz.questions.length - 1
          ? setCurrentQuestion(nextQuestion)
          : setIsCompleted(true);
      }, 4000);
    } else {
      new Toast({
        body: t(ORIGINALS_QUIZ_PROGRESS_FAIL),
        failure: true,
      }).dispatch();
    }
  };

  const handleSelectedAnswer = (answerIndex: any) => {
    const numOfCorrectAnswers = quiz.questions[
      currentQuestion
    ]?.answers?.filter((a) => a.isCorrect).length;

    const answerAlreadyExists = selectedAnswers.includes(answerIndex);

    if (answerAlreadyExists) {
      const updatedSelectedAnswers = [...selectedAnswers];
      updatedSelectedAnswers.splice(selectedAnswers.indexOf(answerIndex), 1);

      setSelectedAnswers(updatedSelectedAnswers);
      return;
    }

    if (numOfCorrectAnswers === selectedAnswers.length) {
      setSelectedAnswers([answerIndex]);
      return;
    }

    setSelectedAnswers([...selectedAnswers, answerIndex]);
  };

  const handleNextEpisodeClick = () => {
    onQuizEnd();
  };

  const currentQuestionData = quiz.questions[currentQuestion];

  const currentClassIndex = episodes.findIndex(
    (episode) => episode.id === onDemandMediaId
  );

  useEffect(() => {
    setTimeout(() => {
      setAnimateTitleExit(true);
      setTimeout(() => {
        setQuizTitleVisible(false);
      }, 400);
    }, 1500);
  }, []);

  return (
    <div className="c-originals-quiz u-flex u-flex-column absolute u-z-index-1 u-overflow-y-scroll u-overflow-x-hidden">
      {showEmojis && (
        <OriginalsQuizEmoji
          count={sizing.isMobile ? 40 : sizing.isTablet ? 50 : 150}
          characters={isCorrectAnswer ? ['🥳', '🎉'] : ['😰']}
          size={sizing.isMobile ? '30vw' : sizing.isTablet ? '17vw' : '8vw'}
        />
      )}
      <div className="u-flex u-justify-between u-4/5 mhauto pv24">
        <AnimatedWordmark colour={DaisieWordmarkColour.White} />
        <button
          type="button"
          onClick={onQuizDismiss}
          className="u-flex u-align-center"
        >
          <Icon id="close" size="m" fill="white" />
        </button>
      </div>
      {isQuizTitleVisible ? (
        <div
          className="p64 mhauto mv60"
          style={{ height: '70vh', display: 'flex', alignItems: 'center' }}
        >
          <h1
            className={c('f-lynstone-bold-1 u-very-light-grey', {
              'c-originals-quiz__title-enter': isQuizTitleVisible,
              'c-originals-quiz__title-exit': shouldAnimateTitleExit,
            })}
          >
            {t(`Class ${currentClassIndex + 1}: Quiz`)}
          </h1>
        </div>
      ) : null}
      {!isQuizTitleVisible ? (
        <div
          className="c-originals-quiz__progress--parent u-4/5 mhauto"
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${quiz.questions.length}, 1fr)`,
            gridGap: '1rem',
          }}
        >
          {quiz.questions.map((question, index) => (
            <div
              key={`question-marker-${index + 1}`}
              className="c-originals-quiz__progress--child--wrapper"
            >
              <div
                className="c-originals-quiz__progress--child--content"
                style={{
                  width: answeredIndexes.includes(index) ? `100%` : 0,
                }}
              />
            </div>
          ))}
        </div>
      ) : null}
      {!isQuizTitleVisible ? (
        <div
          className={`
          c-originals-quiz__content
          u-flex
          u-flex-column
          u-align-center
          u-justify-center@m
          u-flex-grow
          u-1/1
          mhauto
          u-very-light-grey
          mv32
          p32
        `}
        >
          {!isCompleted ? (
            <>
              <h1 className="c-originals-quiz__question f-lynstone-bold-2 mb24">
                {t(`${currentQuestionData.title}`)}
              </h1>
              <p className="c-originals-quiz__question-subtitle mb24 u-light-grey f-lynstone-regular-4">
                Please select all answers that apply.
              </p>
              <div
                className={`c-originals-quiz__answers u-grid ${
                  currentQuestionData.answers.length === 3
                    ? 'u-grid--3'
                    : 'u-grid--2'
                } u-text-center mb24 u-1/1@s`}
                style={{
                  gridTemplateRows: `repeat(${
                    currentQuestionData.answers.length <= 3
                      ? 1
                      : Math.ceil(currentQuestionData.answers.length / 2)
                  }, 1fr)`,
                }}
              >
                {currentQuestionData.answers.map(
                  (answer: Answer, index: number) => (
                    <button
                      type="button"
                      key={String(index)}
                      className={c(
                        'c-originals-quiz__answer u-cursor-pointer',
                        {
                          'c-originals-quiz__answer--selected':
                            selectedAnswers.includes(index),
                          'c-originals-quiz__answer--highlighted-correct':
                            highlightAnswers && answer.isCorrect,
                          'c-originals-quiz__answer--highlighted-incorrect':
                            highlightAnswers &&
                            selectedAnswers.includes(index) &&
                            !answer.isCorrect,
                        }
                      )}
                      onClick={() => handleSelectedAnswer(index)}
                    >
                      {answer.image ? (
                        <img
                          src={answer.image.processorPath}
                          alt={`answer-${index}`}
                        />
                      ) : (
                        t(`${answer.title}`)
                      )}
                    </button>
                  )
                )}
              </div>
              <Button
                className="c-originals-quiz__submit-button dds-button--no-focus u-1/1@s"
                buttonTextClassName="ph12 pv6"
                onClick={handleSubmittedAnswer}
                type={ButtonType.submit}
                text={t('Submit answer')}
                size={ButtonSize.m}
              />
            </>
          ) : (
            <>
              <h2 className="f-lynstone-bold-2 mb24">
                {t(`you've scored ${score} in total`)}
              </h2>
              <Button
                className="dds-button--no-focus"
                buttonTextClassName="ph12 pv6"
                onClick={handleNextEpisodeClick}
                type={ButtonType.submit}
                text={t('Play next episode')}
              />
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

export const OriginalsQuiz = connect(['sizing'])(OriginalsQuizComponent);
