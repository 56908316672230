import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import widont from 'widont';

import DiscordButton from '@/components/global/DiscordButton/DiscordButton';
import { Icon } from '@/components/global/Icon/Icon';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { LoadingSymbol } from '@/components/global/LoadingSymbol/LoadingSymbol';

import { ProgressBarArcTimer } from '@/components/design_system/ProgressBar/ProgressBarArcTimer';

import { DaisieCustomEvent } from '@/enums/DaisieCustomEvent';
import { IconSize } from '@/enums/IconSize';

import {
  createGCalEventURL,
  createICSFiles,
} from '@/utils/calendar/generate-calendar-invites';
import { SECONDS } from '@/utils/constants';
import { renderEventDateTime } from '@/utils/date/date-manipulation';
import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';
import { getRouteFromUrl } from '@/utils/urls/routes';

interface Props extends RouteComponentProps {}

interface State {
  isRendered: boolean;
  isAnimatingIn: boolean;
  workshopCoverImageUrl?: string;
  workshopTimeStart?: Date;
  workshopTimeEnd?: Date;
  workshopTitle?: string;
  workshopDescription?: string;
  isTimerCancelled: boolean;
  isLoading: boolean;
  isCloseButtonRendered: boolean;
  isCloseButtonVisible: boolean;
  isContentVisible: boolean;
  onDismiss?: () => void;
}

class WorkshopAttendModalComponent extends React.Component<Props, State> {
  public state: State = {
    isRendered: false,
    isAnimatingIn: false,
    workshopCoverImageUrl: undefined,
    workshopTimeStart: undefined,
    workshopTimeEnd: undefined,
    workshopTitle: undefined,
    workshopDescription: undefined,
    isTimerCancelled: false,
    isLoading: false,
    isCloseButtonRendered: true,
    isCloseButtonVisible: true,
    isContentVisible: true,
    onDismiss: undefined,
  };

  // RUNTIME
  private hideModalTimeout?: NodeJS.Timeout;

  // CONFIG
  private AUTO_HIDE_TIMEOUT: number = 10 * SECONDS;

  public componentDidMount = () => {
    window.addEventListener(
      DaisieCustomEvent.show_workshop_attend_modal,
      this.showModal
    );

    window.addEventListener(
      DaisieCustomEvent.show_workshop_attend_modal_loading,
      this.showLoadingModal
    );
  };

  public componentDidUpdate = (prevProps: Props) => {
    const {
      location: { pathname: prevPathname },
    } = prevProps;

    const {
      location: { pathname },
    } = this.props;

    const { isAnimatingIn } = this.state;

    if (pathname !== prevPathname && isAnimatingIn) {
      this.hideModal();
    }
  };

  public componentWillUnmount = () => {
    window.removeEventListener(
      DaisieCustomEvent.show_workshop_attend_modal,
      this.showModal
    );

    window.removeEventListener(
      DaisieCustomEvent.show_workshop_attend_modal_loading,
      this.showLoadingModal
    );
  };

  private showLoadingModal = (e: any) => {
    const { coverImageUrl } = e.detail;
    const { isAnimatingIn } = this.state;

    const show = () => {
      this.setState(
        {
          isRendered: true,
          workshopCoverImageUrl: coverImageUrl,
          isTimerCancelled: false,
          isLoading: true,
          isCloseButtonRendered: false,
          isCloseButtonVisible: false,
          isContentVisible: true,
        },
        () => {
          setTimeout(() => {
            this.setState({ isAnimatingIn: true });
          }, 1);
        }
      );
    };

    if (isAnimatingIn) {
      // Something already visible
      this.hideModal(() => show());
    } else {
      show();
    }
  };

  private showModal = (e: any) => {
    const { coverImageUrl, timeStart, timeEnd, title, description, onDismiss } =
      e.detail;
    const { isAnimatingIn, isLoading } = this.state;

    const show = () => {
      this.setState(
        {
          isRendered: true,
          workshopCoverImageUrl: coverImageUrl,
          workshopTimeStart: timeStart,
          workshopTimeEnd: timeEnd,
          workshopTitle: title,
          workshopDescription: description,
          isTimerCancelled: false,
          isCloseButtonRendered: true,
          isContentVisible: isLoading ? false : true,
          onDismiss,
        },
        () => {
          const runSetTimeout = () => {
            this.hideModalTimeout = setTimeout(() => {
              this.hideModal(onDismiss);
            }, this.AUTO_HIDE_TIMEOUT + 100);
          };

          if (isLoading) {
            this.setState({ isContentVisible: false }, () => {
              setTimeout(() => {
                this.setState({ isLoading: false }, () => {
                  setTimeout(() => {
                    this.setState(
                      { isContentVisible: true, isCloseButtonVisible: true },
                      () => {
                        runSetTimeout();
                      }
                    );
                  }, 250);
                });
              }, 250);
            });
          } else {
            setTimeout(() => {
              this.setState({ isAnimatingIn: true }, () => {
                runSetTimeout();
              });
            }, 1);
          }
        }
      );
    };

    if (isAnimatingIn && !isLoading) {
      // Something already visible
      this.hideModal(() => show());
    } else {
      show();
    }
  };

  private hideModal = (callback?: () => void) => {
    if (this.hideModalTimeout) {
      clearTimeout(this.hideModalTimeout);
    }

    this.setState({ isAnimatingIn: false }, () => {
      setTimeout(() => {
        this.setState(
          {
            isRendered: false,
            workshopCoverImageUrl: undefined,
            workshopTimeStart: undefined,
            workshopTimeEnd: undefined,
            workshopTitle: undefined,
            workshopDescription: undefined,
            isTimerCancelled: false,
            onDismiss: undefined,
          },
          () => {
            if (callback) {
              callback();
            }
          }
        );
      }, 300);
    });
  };

  private formatCalendarEventTitle = (workshopTitle: string): string =>
    `Daisie Workshop: ${workshopTitle}`;

  private createGoogleCalLinks = () => {
    const {
      workshopTimeStart,
      workshopTimeEnd,
      workshopTitle,
      workshopDescription,
    } = this.state;

    if (!workshopTimeStart || !workshopTimeEnd || !workshopTitle) {
      return;
    }

    const googleCalLink = createGCalEventURL({
      start: workshopTimeStart.toISOString(),
      end: workshopTimeEnd.toISOString(),
      title: this.formatCalendarEventTitle(workshopTitle),
      description: !!workshopDescription ? workshopDescription : '',
    });

    window.open(googleCalLink);
  };

  private cancelTimer = () => {
    if (this.hideModalTimeout) {
      clearTimeout(this.hideModalTimeout);
    }

    this.setState({ isTimerCancelled: true });
  };

  public render = () => {
    const {
      isRendered,
      isAnimatingIn,
      workshopCoverImageUrl,
      workshopTimeStart,
      workshopTimeEnd,
      workshopTitle,
      workshopDescription,
      isTimerCancelled,
      isLoading,
      isCloseButtonRendered,
      isCloseButtonVisible,
      isContentVisible,
      onDismiss,
    } = this.state;

    if (!isRendered) {
      return null;
    }
    const matchedRoute = getRouteFromUrl(this.props.location.pathname);
    const isWorkshopPage = matchedRoute === 'workshop';

    const icsPayload =
      workshopTimeStart && workshopTimeEnd && workshopTitle
        ? () => [
            {
              start: workshopTimeStart.toISOString(),
              end: workshopTimeEnd.toISOString(),
              title: this.formatCalendarEventTitle(workshopTitle),
              description: workshopDescription || '',
            },
          ]
        : undefined;

    const icsFile = !!icsPayload ? createICSFiles(icsPayload()) : '';

    return (
      <div className="c-workshop-attend-modal">
        <div
          className={c(
            'c-workshop-attend-modal__content mb32 p32 u-text-center u-overflow-hidden',
            {
              'c-workshop-attend-modal__content--animate-in': isAnimatingIn,
              'c-workshop-attend-modal__content__workshop-page': isWorkshopPage,
            }
          )}
          onMouseOver={() => this.cancelTimer()}
        >
          {!!workshopCoverImageUrl && (
            <ImageLoader
              src={workshopCoverImageUrl}
              className="absolute absolute--tl"
            />
          )}

          <div className="c-workshop-attend-modal__overlay" />

          {isCloseButtonRendered && (
            <div
              className={c(
                'c-workshop-attend-modal__close mt16 mr16 animate-opacity',
                {
                  'opacity-1': isCloseButtonVisible,
                  'opacity-0 pointer-events-none': !isCloseButtonVisible,
                }
              )}
            >
              <ProgressBarArcTimer
                size={32}
                strokeWidth={2}
                durationMs={this.AUTO_HIDE_TIMEOUT}
                className={c(
                  'absolute absolute--tl u-z-index-1 pointer-events-none animate-opacity',
                  {
                    'opacity-1': !isTimerCancelled,
                    'opacity-0': isTimerCancelled,
                  }
                )}
              />

              <button
                type="button"
                className={c('c-workshop-attend-modal__close__button', {
                  'u-z-index-1': isTimerCancelled,
                })}
                onClick={() => this.hideModal(onDismiss)}
              >
                <Icon id="clear" size={IconSize.xs} className="u-white" />
              </button>
            </div>
          )}

          <div
            className={c(
              'relative u-h-100 u-white u-flex u-flex-column u-align-center u-justify-center animate-opacity',
              {
                'opacity-1': isContentVisible,
                'opacity-0 pointer-events-none': !isContentVisible,
              }
            )}
          >
            {isLoading ? (
              <div>
                <LoadingSymbol colour="white" size="l" />
              </div>
            ) : (
              <>
                <p
                  className={c('f-yodel-2a u-bold mb12', {
                    mt16: isWorkshopPage,
                  })}
                >
                  {t(`You're in!`)}
                </p>
                <p
                  className={c('f-text-2 mh24@m', {
                    mb32: !isWorkshopPage,
                    mb16: isWorkshopPage,
                  })}
                >
                  {workshopTitle} {t('starts')}{' '}
                  {workshopTimeStart && (
                    <span className="u-bold mb32">
                      {widont(
                        renderEventDateTime(workshopTimeStart, {
                          lowercase: true,
                          atSeparator: true,
                        })
                      )}
                    </span>
                  )}
                </p>

                <p
                  className={c(
                    'f-text-2 u-light-grey u-flex u-align-center u-justify-center',
                    {
                      'mb-4': isWorkshopPage,
                    }
                  )}
                >
                  <Icon
                    id="calendar"
                    size={IconSize.s}
                    className="mr8 relative b2"
                  />
                  <span>
                    {t('Add to')}{' '}
                    <button
                      type="button"
                      onClick={() => this.createGoogleCalLinks()}
                      className="u-link-light-grey u-underline"
                    >
                      {t('Google')}
                    </button>{' '}
                    {t('or')}{' '}
                    <a
                      href={`data:text/calendar;charset=utf-8,${encodeURIComponent(
                        icsFile
                      )}`}
                      download="daisie-workshop.ics"
                      className="u-link-light-grey u-underline"
                    >
                      {t('Apple')}
                    </a>{' '}
                    {t('calendar')}
                  </span>
                </p>
                {matchedRoute === 'workshop' && (
                  <DiscordButton
                    copy="Join the Discord"
                    className="c-workshop-attend-modal__discord__button dds-button--size-m u-light-grey"
                    isAttendModal={true}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
}

export const WorkshopAttendModal = withRouter(WorkshopAttendModalComponent);
