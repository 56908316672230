import * as React from 'react';

import { c } from '@/utils/strings/c';

interface Props {
  className?: string;
}

export const HeroBlurOverlay = ({ className }: Props) => (
  <div
    className={c([
      className || '',
      'u-overflow-hidden relative u-1/1 u-h-100 u-translate-3d-fix',
    ])}
  >
    <div className="dds-hero-blur-overlay dds-hero-blur-overlay--main" />
    <div className="dds-hero-blur-overlay dds-hero-blur-overlay--l" />
    <div className="dds-hero-blur-overlay dds-hero-blur-overlay--r" />
    <div className="dds-hero-blur-overlay dds-hero-blur-overlay--c" />
  </div>
);
