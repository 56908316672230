import React, { SVGProps } from 'react';

import { createIcon } from '@chakra-ui/react';

export const ArrowRightIcon = createIcon({
  displayName: 'ArrowRight',
  path: (
    <path
      d="M16 9.00006L8.03375 1.03381M16 9.00006L8.03375 16.9663M16 9.00006H0"
      stroke="currentColor"
      strokeWidth="1.6"
    />
  ),
});

export const ArrowTopRightIcon = createIcon({
  displayName: 'ArrowTopRight',
  path: (
    <path
      d="M12.3666 1.3432H1.10064M12.3666 1.3432V12.6092M12.3666 1.3432L1.05291 12.6569"
      stroke="currentColor"
      strokeWidth="1.6"
    />
  ),
});

export const ArrowBottomRightIcon = createIcon({
  displayName: 'ArrowBottomRight',
  path: (
    <path
      d="M18.5211 18.42V1.52108M18.5211 18.42H1.62213M18.5211 18.42L1.55052 1.44948"
      stroke="white"
      strokeWidth="2"
    />
  ),
});

export const PlayIcon = createIcon({
  displayName: 'Play',
  viewBox: '0 0 96 96',
  path: (
    <path
      d="M48 6C39.6932 6 31.5729 8.46326 24.6661 13.0783C17.7592 17.6933 12.376 24.2528 9.19708 31.9273C6.0182 39.6018 5.18646 48.0466 6.80704 56.1938C8.42762 64.341 12.4277 71.8247 18.3015 77.6985C24.1753 83.5723 31.659 87.5724 39.8062 89.193C47.9534 90.8136 56.3982 89.9818 64.0727 86.8029C71.7472 83.6241 78.3067 78.2408 82.9217 71.3339C87.5368 64.4271 90 56.3068 90 48C90 36.8609 85.575 26.178 77.6985 18.3015C69.822 10.425 59.1391 6 48 6ZM70.3419 50.6835L34.3419 68.6835C33.8845 68.9123 33.3762 69.0203 32.8653 68.9973C32.3544 68.9744 31.8578 68.8212 31.4228 68.5523C30.9878 68.2834 30.6287 67.9078 30.3797 67.4611C30.1306 67.0144 30 66.5114 30 66V30C30 29.4886 30.1306 28.9856 30.3797 28.5389C30.6287 28.0922 30.9878 27.7166 31.4228 27.4477C31.8578 27.1788 32.3544 27.0256 32.8653 27.0027C33.3762 26.9797 33.8845 27.0877 34.3419 27.3165L70.3419 45.3165C70.8403 45.5656 71.2596 45.9486 71.5525 46.4226C71.8455 46.8966 72.0007 47.4428 72.0007 48C72.0007 48.5572 71.8455 49.1034 71.5525 49.5774C71.2596 50.0514 70.8403 50.4344 70.3419 50.6835Z"
      fill="currentColor"
    />
  ),
});

export const StarIcon = createIcon({
  displayName: 'Star',
  viewBox: '0 0 48 48',
  path: (
    <path
      d="M18 0.970825L22 14.0426L35.3205 10.9708L26 20.9708L35.3205 30.9708L22 27.899L18 40.9708L14 27.899L0.679491 30.9708L10 20.9708L0.679491 10.9708L14 14.0426L18 0.970825Z"
      fill="#D8C549"
    />
  ),
});
