import * as React from 'react';

import { connect } from 'unistore/react';

import { EmptyState } from '@/components/global/EmptyState/EmptyState';
import { List } from '@/components/global/List/List';
import { LoadingSymbol } from '@/components/global/LoadingSymbol/LoadingSymbol';
import { Toast } from '@/components/global/Toaster/Toast';
import { NotificationItem } from '@/components/notifications/NotificationItem';

import { NotificationItemLocations } from '@/enums/NotificationItemLocations';
import { PaginatorPosition } from '@/enums/PaginatorPosition';

import { UNKNOWN_ERROR } from '@/messages/errors';

import { notificationsActions } from '@/store/modules/notifications';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

import { t } from '@/utils/i18n/i18n';

interface Props extends NotificationsState, NotificationsActions {
  className?: string;
  size?: string;
  fixed?: boolean;
  onClickToggle: () => void;
  isDarkMode: boolean;
}

interface State {
  loading: boolean;
  pageNumber: number;
  notifications: DaisieNotification[] | boolean;
}

class NotificationFeedComponent extends React.Component<Props, State> {
  public state = {
    loading: false,
    pageNumber: 1,
    notifications: false,
  };

  public componentDidMount = async () => {
    await this.fetchData(1);

    this.processNotifications();
  };

  public componentDidUpdate = async (prevProps: Props) => {
    if (
      prevProps.notifications.unreadNotificationsCount !==
      this.props.notifications.unreadNotificationsCount
    ) {
      this.fetchData(1);
    }
  };

  private fetchData = async (pageNumber: number) => {
    const { loading } = this.state;

    if (loading) return;

    await this.setState({
      loading: true,
    });

    try {
      await this.props.fetchNotifications(pageNumber);
      await this.props.markAllAsRead();
      await this.props.getUnreadNotificationsCount();
      this.processNotifications();
      this.setState({ loading: false, pageNumber });
    } catch (e) {
      this.setState({ loading: false, pageNumber: pageNumber - 1 || 1 });
      new Toast({ body: UNKNOWN_ERROR, failure: true }).dispatch();
    }
  };

  private processNotifications = () => {
    const n: DaisieNotification[] = [];

    Object.values(this.props.notifications.notifications).map((page: any) => {
      page.map((notification: DaisieNotification) => {
        n.push(notification);
      });
    });

    this.setState({
      notifications: n,
    });
  };

  public render() {
    const {
      className = '',
      size = 'large',
      fixed = false,
      isDarkMode,
    } = this.props;
    const { loading, notifications } = this.state;

    if (Array.isArray(notifications) && notifications.length) {
      return (
        <div>
          <List
            fixed={fixed}
            className={className}
            onEndReached={(end: PaginatorPosition) => {
              if (end === PaginatorPosition.end) {
                if (isUiTest()) {
                  return;
                }

                this.setState({ pageNumber: this.state.pageNumber + 1 });
                this.fetchData(this.state.pageNumber);
              }
            }}
            data={notifications}
            renderItem={({ item }) => (
              <NotificationItem
                key={item.id}
                className="mb24"
                notification={item}
                size={size}
                location={NotificationItemLocations.NotificationsDropdown}
                onClickToggle={this.props.onClickToggle}
                isDarkMode={isDarkMode}
              />
            )}
          />
          {loading && (
            <div className="u-flex mb48 mt20">
              <LoadingSymbol className="mlauto mrauto" colour="white" />
            </div>
          )}
        </div>
      );
    }

    if (Array.isArray(notifications) && !notifications.length) {
      return (
        <EmptyState
          className=""
          titleText={t('No notifications yet!')}
          subtitleText={t(
            "We'll let you know when there's something you should know about."
          )}
          showCTAs={true}
        />
      );
    }

    return (
      <div className="u-flex">
        <LoadingSymbol className="mlauto mrauto" colour="white" />
      </div>
    );
  }
}

const NotificationFeed = connect(
  'notifications',
  notificationsActions
)(NotificationFeedComponent);

export { NotificationFeed };
