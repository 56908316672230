import * as React from 'react';

import { connect } from 'unistore/react';

import { Icon } from '@/components/global/Icon/Icon';

import { c } from '@/utils/strings/c';

interface Props extends SizingState {
  onItemClick: (index: number | undefined) => void;
  isExpanded: boolean;
  section: FaqSection;
  index: number | undefined;
}

interface FaqSection {
  question: string;
  answer: React.ReactNode;
}

const FAQItemOriginalComp = ({
  sizing: { isDesktop },
  onItemClick,
  isExpanded,
  section,
  index,
}: Props) => {
  const answer = React.useRef<HTMLDivElement>(null);

  return (
    <div
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      className="u-white"
    >
      <button
        type="button"
        onClick={() => {
          onItemClick(!isExpanded ? index : undefined);
        }}
        className={c(
          'u-1/1 u-border-radius--xs b--sad-grey u-bg-almost-black',
          {
            'p24 mb24': isDesktop,
            'p20 mb12': !isDesktop,
          }
        )}
      >
        <div className="u-flex u-align-center u-split">
          <p className="u-bold f-title-3 f-text-1">{section.question}</p>

          <Icon
            id={isExpanded ? 'originals-chevron-up' : 'originals-chevron-down'}
            width={isDesktop ? '22px' : '20px'}
            className={c('ml16', {
              'u-devil-grey': !isExpanded,
              'u-very-light-grey': isExpanded,
            })}
          />
        </div>

        <div
          ref={answer}
          className="c-faq__answer u-overflow-hidden"
          style={{
            height: isExpanded ? `${answer.current?.scrollHeight}px` : '0px',
          }}
        >
          <div className="u-line-height-1/2 u-text-left f-text-2 u-grey mt24 mb8">
            {section.answer}
          </div>
        </div>
      </button>
    </div>
  );
};

export const FAQItemOriginal = connect(['sizing'])(FAQItemOriginalComp);
