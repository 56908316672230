/* eslint-disable @typescript-eslint/no-magic-numbers */

export enum HTTPStatusCode {
  OK_200 = 200,
  NO_CONTENT_204 = 204,
  BAD_REQUEST_400 = 400,
  UNAUTHORIZED_401 = 401,
  FORBIDDEN_403 = 403,
  NOT_FOUND_404 = 404,
  CONFLICT_409 = 409,
}
