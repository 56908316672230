import { abtestState } from '@/store/modules/abtest';
import { authState } from '@/store/modules/auth';
import { calendarState } from '@/store/modules/calendar';
import { categoryState } from '@/store/modules/category';
import { channelState } from '@/store/modules/channel';
import { cinematicBackgroundState } from '@/store/modules/cinematicBackground';
import { feedState } from '@/store/modules/feed';
import { modalState } from '@/store/modules/modal';
import { mutedUsersState } from '@/store/modules/mutedUsers';
import { notificationsState } from '@/store/modules/notifications';
import { portalState } from '@/store/modules/portal';
import { rolesState } from '@/store/modules/roles';
import { roomState } from '@/store/modules/room';
import { sizingState } from '@/store/modules/sizing';
import { subscriptionState } from '@/store/modules/subscription';
import { tooltipState } from '@/store/modules/tooltip';
import { userState } from '@/store/modules/user';
import { workshopState } from '@/store/modules/workshop';

const store: GlobalStoreState = {
  ...authState,
  ...categoryState,
  ...channelState,
  ...cinematicBackgroundState,
  ...feedState,
  ...modalState,
  ...notificationsState,
  ...portalState,
  ...rolesState,
  ...sizingState,
  ...userState,
  ...workshopState,
  ...roomState,
  ...tooltipState,
  ...mutedUsersState,
  ...subscriptionState,
  ...abtestState,
  ...calendarState,
};

export { store };
