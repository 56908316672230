import * as React from 'react';

import { connect } from 'unistore/react';
import widont from 'widont';

import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { Link } from '@/components/global/Link/Link';
import { SkeletonNew } from '@/components/global/Skeleton/SkeletonNew';
import { TimeRemaining } from '@/components/global/TimeRemaining/TimeRemaining';
import { YourMentorOrRoomHost } from '@/components/global/YourMentorOrRoomHost/YourMentorOrRoomHost';
import { addWorkshopComponentActions } from '@/components/workshops/addWorkshopComponentActions';
import { WorkshopAttendeesMobile } from '@/components/workshops/WorkshopAttendeesMobile';

import { LabelPill } from '@/components/design_system/LabelPill/LabelPill';

import { parser } from '@/routes/parser';

import { modalActions } from '@/store/modules/modal';
import { portalActions } from '@/store/modules/portal';

import { renderBulletTimestamp } from '@/utils/date/date-manipulation';
import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';
import { getFontClass } from '@/utils/workshop/workshopUtils';

interface Props extends WorkshopComponentActions {
  isLoading: boolean;
  workshopCalendarEvent?: CalendarEvent;
}

class FeaturedWorkshopComponent extends React.Component<Props> {
  private classNameSkeleton: string = 'c-logged-in-homepage__skeleton';

  public componentDidMount = () => {
    const batchId = this.props.workshopCalendarEvent?.workshop?.batch.id;
    const mentor = this.props.workshopCalendarEvent?.workshop?.workshop.mentor;

    if (!batchId || !mentor) {
      return;
    }

    this.props.fetchWorkshopAttendees({ batchId, mentor });
  };

  public componentDidUpdate = (prevProps: Props) => {
    const { workshopCalendarEvent } = this.props;
    const { workshopCalendarEvent: prevWorkshopCalendarEvent } = prevProps;

    if (!prevWorkshopCalendarEvent && workshopCalendarEvent) {
      const batchId = this.props.workshopCalendarEvent?.workshop?.batch.id;
      const mentor =
        this.props.workshopCalendarEvent?.workshop?.workshop.mentor;

      if (!batchId || !mentor) {
        return;
      }

      this.props.fetchWorkshopAttendees({ batchId, mentor });
    }
  };

  public render = () => {
    const {
      isLoading,
      workshopCalendarEvent,
      auth: { isAuthorised },
      sizing: { isMobile },
      isAttendeesLoaded,
      displayAttendees,
    } = this.props;

    const workshop =
      workshopCalendarEvent && workshopCalendarEvent.workshop
        ? workshopCalendarEvent.workshop.workshop
        : undefined;

    return (
      <Link
        to={
          workshop
            ? parser({
                name: 'workshop',
                params: { workshopSlug: workshop.slug },
              })
            : ''
        }
        className={c('c-calendar__featured mb32', {
          'pointer-events-none': isLoading,
        })}
      >
        <div
          className={c('c-calendar__featured__hero mb16', {
            'u-bg-charcoal-grey': !isLoading,
            'u-bg-almost-black': isLoading,
          })}
        >
          {!isLoading && workshop && (
            <ImageLoader
              src={workshop.coverMedia}
              className="u-object-cover fill"
              convertToWebp={true}
            />
          )}

          <div
            className={c(
              'c-calendar__featured__hero__content fill u-flex u-align-center u-justify-center',
              {
                'c-calendar__featured__hero__content--with-bg': !isLoading,
              }
            )}
          >
            <div className="u-flex u-flex-column u-align-center u-justify-center">
              {isLoading ? (
                <SkeletonNew
                  elementClassName=""
                  containerClassName="mb16"
                  fillClassName={this.classNameSkeleton}
                  fitToText="Category"
                />
              ) : workshop && workshop.categories && workshop.categories[0] ? (
                <LabelPill
                  text={workshop.categories[0].name}
                  iconId={`category-${workshop.categories[0].slug}`}
                  style="dark"
                  className="mb16"
                />
              ) : null}

              {isLoading ? (
                <>
                  <SkeletonNew
                    elementClassName="u-white f-yell-1b"
                    containerClassName="u-1/3 u-text-center mb12"
                    fillClassName={this.classNameSkeleton}
                    fitToText="Mastering film"
                  />
                  <SkeletonNew
                    elementClassName="u-white f-yell-1b"
                    containerClassName="u-1/3 u-text-center"
                    fillClassName={this.classNameSkeleton}
                    fitToText="photography for"
                  />
                </>
              ) : workshop ? (
                <h1
                  className={`c-calendar__featured__hero__title u-white u-text-center mh24 mh80@m ${getFontClass(
                    workshop?.attributes?.fontFamily
                  )} ${!workshop?.attributes?.fontFamily ? 'f-yell-1b' : ''}`}
                >
                  {widont(workshop.title)}
                </h1>
              ) : null}

              {isMobile && (
                <WorkshopAttendeesMobile
                  isLoading={isLoading}
                  workshop={workshop}
                  attendees={displayAttendees}
                  isAttendeesLoaded={isAttendeesLoaded}
                  className="mt12"
                />
              )}
            </div>

            {!isLoading && workshop && (
              <>
                <div className="c-calendar__featured__hero__content__active_bg fill" />

                <div className="c-calendar__featured__hero__content__description fill p24 u-flex">
                  <p className="u-white f-text-1a mtauto u-2/3">
                    {workshop.description}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className={c('c-calendar__featured__meta', {
            'u-split@l': isAuthorised,
            'u-split@m': !isAuthorised,
          })}
        >
          {!isMobile && (
            <div
              className={c('u-1/1 u-1/2@l', {
                'mb16 mb0@l': isAuthorised,
              })}
            >
              <YourMentorOrRoomHost
                isLoading={isLoading}
                workshop={workshop}
                attendees={displayAttendees}
                isAttendeesLoaded={isAttendeesLoaded}
              />
            </div>
          )}

          <div className="u-flex u-1/1 u-1/2@l">
            <div
              className={c('u-flex-1 u-flex u-align-center mr24', {
                'u-justify-end@l u-text-right@l': isAuthorised,
                'u-justify-end u-text-right@m': !isAuthorised,
              })}
            >
              {isLoading ? (
                <SkeletonNew
                  elementClassName="f-text-2"
                  containerClassName=""
                  fillClassName={this.classNameSkeleton}
                  width="40%"
                />
              ) : workshopCalendarEvent &&
                workshopCalendarEvent.isInProgress ? (
                <div className="u-flex u-flex-column">
                  <p className="f-text-3 u-white mb2 u-bold">
                    {t('Workshop in progress')}
                  </p>
                  <TimeRemaining
                    start={workshopCalendarEvent.start}
                    end={workshopCalendarEvent.end}
                    className="f-text-3 u-bold u-danger"
                  />
                </div>
              ) : workshopCalendarEvent &&
                !workshopCalendarEvent.isInProgress ? (
                <p className="f-text-2 u-white u-bold">
                  {renderBulletTimestamp(workshopCalendarEvent.start)}
                </p>
              ) : null}
            </div>

            {this.props.renderActionButton({
              isLoading,
              workshop,
              batchId:
                workshopCalendarEvent && workshopCalendarEvent.workshop
                  ? workshopCalendarEvent.workshop.batch.id
                  : undefined,
              sessionId: workshopCalendarEvent
                ? workshopCalendarEvent.id.replace('workshopSession_', '')
                : undefined,
              isAttendingRaw:
                workshopCalendarEvent && workshopCalendarEvent.workshop
                  ? workshopCalendarEvent.workshop.isAttending
                  : false,
              isInProgress: workshopCalendarEvent
                ? workshopCalendarEvent.isInProgress
                : false,
              timeStart:
                workshopCalendarEvent && workshopCalendarEvent.start
                  ? workshopCalendarEvent.start
                  : undefined,
              timeEnd:
                workshopCalendarEvent && workshopCalendarEvent.end
                  ? workshopCalendarEvent.end
                  : undefined,
            })}
          </div>
        </div>
      </Link>
    );
  };
}

export const FeaturedWorkshop = connect(['sizing', 'auth'], () => ({
  ...modalActions(),
  ...portalActions(),
}))(addWorkshopComponentActions(FeaturedWorkshopComponent));
