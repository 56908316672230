import React from 'react';

import {
  Box,
  BoxProps,
  Flex,
  Grid,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { ArrowBottomRightIcon } from '@/components/design_system/AlexJenkinsClass/atoms';
import { DetailsCard } from '@/components/design_system/AlexJenkinsClass/molecules';

import { routes } from '@/routes';

import { colors } from '@/styles/chakra-theme/colors';
import { pxToRem } from '@/styles/chakra-theme/metrics';

export const ClassForYouSection = (props: BoxProps) => {
  const history = useHistory();

  return (
    <Grid
      templateColumns={{
        base: `repeat(9, ${window.innerWidth / 9}px)`,
        md: `repeat(9, ${window.innerWidth / 9}px)`,
        lg: `repeat(9, ${window.innerWidth / 9}px)`,
        xl: `repeat(12, ${window.innerWidth / 12}px)`,
        '2xl': `repeat(16, ${window.innerWidth / 16}px)`,
      }}
      templateRows={{
        base: `repeat(53, ${window.innerWidth / 9}px)`,
        md: `repeat(19, ${window.innerWidth / 9}px)`,
        lg: `repeat(23, ${window.innerWidth / 9}px)`,
        xl: `repeat(21, ${window.innerWidth / 12}px)`,
        '2xl': `repeat(18, ${window.innerWidth / 16}px)`,
      }}
      bgColor="#000"
      backgroundImage="linear-gradient(#ffffff20 1px, transparent 1px), linear-gradient(to right, #ffffff20 1px, #000 1px)"
      backgroundSize={{
        base: `${window.innerWidth / 9}px ${window.innerWidth / 9}px`,
        md: `${window.innerWidth / 9}px ${window.innerWidth / 9}px`,
        lg: `${window.innerWidth / 9}px ${window.innerWidth / 9}px`,
        xl: `${window.innerWidth / 12}px ${window.innerWidth / 12}px`,
        '2xl': `${window.innerWidth / 16}px ${window.innerWidth / 16}px`,
      }}
      {...props}
    >
      <Box
        gridColumn={{ base: '2 / -2', md: '2 / -2', lg: '2 / 8' }}
        gridRow={{ base: '4 / 6', md: '1 / 3', lg: '3 / 5' }}
        bg="black"
        border="1px solid #ffffff20" // border to match background grid
      >
        <Image
          alt="originals logo"
          src="/AlexJenkinsClass/img/originals.svg"
          w={{ base: 112, lg: 224 }}
          transform="rotate(-12deg)"
          ml={{ base: 0, md: pxToRem(18) }}
          mb={pxToRem(-10)}
        />

        <Heading
          color="white"
          fontSize={{ base: 50, md: 56, xl: 92 }}
          lineHeight={{ base: pxToRem(58), lg: pxToRem(110) }}
          fontFamily="NeueMontreal"
          fontWeight={400}
          ml={{ base: 0, md: pxToRem(18) }}
        >
          How it works
        </Heading>
      </Box>

      <Image
        alt="illustrative image"
        src="/AlexJenkinsClass/img/Nightwatch.webp"
        gridColumn={{
          base: '2 / -2',
          md: '2 / -6',
          lg: '2 / 5',
          '2xl': '2 / 6',
        }}
        gridRow={{ base: '9 / 12', md: '4 / 7', lg: '6 / 9', '2xl': '6 / 10' }}
        height={{ base: 'auto', md: '100%' }}
        width={{ base: 'auto', md: '100%' }}
      />

      <Image
        alt="illustrative image"
        src="/AlexJenkinsClass/img/Forest.webp"
        gridColumn={{
          base: '2 / -2',
          md: '2 / -6',
          lg: '2 / 5',
          '2xl': '7 / 11',
        }}
        gridRow={{
          base: '22 / 24',
          md: '8 / 11',
          lg: '10 / 13',
          '2xl': '6 / 10',
        }}
        height={{ base: 'auto', md: '100%' }}
        width={{ base: 'auto', md: '100%' }}
      />

      <Image
        alt="illustrative image"
        src="/AlexJenkinsClass/img/Aftermath.webp"
        gridColumn={{
          base: '2 / -2',
          md: '2 / -6',
          lg: '2 / 5',
          '2xl': '12 / 16',
        }}
        gridRow={{ base: '35 / 39', md: '12', lg: '14 / 17', '2xl': '6 / 10' }}
        height={{ base: 'auto', lg: '100%' }}
        width={{ base: 'auto', md: '100%' }}
      />

      <DetailsCard
        tag="LEARN"
        title="Watch video lessons"
        text="Access pre-recorded classes at any time and watch them on your schedule. Enjoy a BTS view of Alex’s process."
        gridColumn={{
          base: '2 / -2',
          md: '5 / -2',
          lg: '6 / -2',
          '2xl': '2 / 6',
        }}
        gridRow={{
          base: '14 / 20',
          md: '4 / 7',
          lg: '6 / 9',
          '2xl': '11 / 14',
        }}
      />

      <DetailsCard
        tag="CREATE"
        title="Practice with projects"
        text="Put what you learn into practice with hands-on projects. Work at anytime throughout your week, on your own schedule."
        gridColumn={{
          base: '2 / -2',
          md: '5 / -2',
          lg: '6 / -2',
          '2xl': '7 / 11',
        }}
        gridRow={{
          base: '27 / 33',
          md: '8 / 11',
          lg: '10 / 13',
          '2xl': '11 / 14',
        }}
      />

      <DetailsCard
        tag="SHARE"
        title="Learn with a peer group"
        text="Share your work, give feedback, and connect with others who share your passion. Our always-on Discord will help you stay accountable and inspired."
        gridColumn={{
          base: '2 / -2',
          md: '5 / -2',
          lg: '6 / -2',
          '2xl': '12 / 16',
        }}
        gridRow={{
          base: '40 / 48',
          md: '12 / 15',
          lg: '14 / 17',
          '2xl': '11 / 14',
        }}
      />

      <Flex
        justify="space-between"
        align="flex-end"
        gridColumn={{ base: '2 / -2', md: '2 / -2', lg: '3 / -3' }}
        gridRow={{
          base: '50 / 52',
          md: '17 / 18',
          lg: '20',
          xl: '19',
          '2xl': '16',
        }}
        bg={[colors.purpleRain, colors.purpleGradient]}
        pt={pxToRem(48)}
        pb={pxToRem(22)}
        px={pxToRem(22)}
        cursor="pointer"
        _hover={{ opacity: 0.9 }}
        onClick={() => history.push(routes.optimisedJoinFlowChoosePlan)}
      >
        <Text
          fontSize={{ base: 20, lg: 36 }}
          fontWeight={400}
          color="white"
          lineHeight="normal"
        >
          Enroll now
        </Text>
        <ArrowBottomRightIcon
          fontSize={[20, 28]}
          mt="auto"
          transform="rotate(-90deg)"
        />
      </Flex>
    </Grid>
  );
};
