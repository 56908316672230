import { getOnDemandMediasFromData } from '@/store/helpers/onDemandHelpers';

import { Http } from '@/utils/api/Http';

export const getCollections = async (): Promise<Collection[]> => {
  try {
    const { data } = await new Http('/collections').get<
      APIArray<APICollection>
    >();

    return getCollectionsFromData(data);
  } catch (e) {
    throw new Error();
  }
};

export const getCollection = async (id: string): Promise<Collection> => {
  try {
    const { data } = await new Http(`/collections/${id}`).get<
      APIObject<APICollection>
    >();

    return getCollectionFromData(data);
  } catch (e) {
    throw new Error();
  }
};

export const getCollectionFromData = (data: APICollection): Collection => {
  const {
    id,
    attributes: { name, description, onDemandMediasCount },
    relationships,
  } = data;

  const onDemandMedias =
    relationships && relationships.onDemandMedias
      ? getOnDemandMediasFromData(relationships.onDemandMedias.data)
      : undefined;

  return {
    id,
    name,
    description,
    mediaCount: onDemandMediasCount,
    onDemandMedias,
  };
};

export const getCollectionsFromData = (data: APICollection[]): Collection[] =>
  data.map(getCollectionFromData).filter(Boolean);
