export const getParentElement = (el: any, className: string) => {
  while (el && el.parentNode) {
    el = el.parentNode;

    if (el.className && el.className.includes(className)) {
      return el;
    }
  }

  return null;
};
