import * as React from 'react';

import { connect } from 'unistore/react';

import { CardCover } from '@/components/global/Cards/CardCover';
import { CardStyle } from '@/components/global/Cards/CardStyle';
import { CardTitleAttendees } from '@/components/global/Cards/CardTitleAttendees';
import { Link } from '@/components/global/Link/Link';
import { addWorkshopComponentActions } from '@/components/workshops/addWorkshopComponentActions';

import { ButtonSize } from '@/components/design_system/Button/ButtonSize';

import { parser } from '@/routes/parser';

import { modalActions } from '@/store/modules/modal';
import { portalActions } from '@/store/modules/portal';

import { c } from '@/utils/strings/c';

interface Props extends WorkshopCardProps, WorkshopComponentActions {}

class WorkshopCardComponent extends React.Component<Props> {
  public componentDidMount = () => {
    const {
      rawAttendees,
      rawAttendeeCount,
      workshop: { mentor },
    } = this.props;

    this.props.setWorkshopAttendees({ rawAttendees, rawAttendeeCount, mentor });
  };

  public render = () => {
    const {
      workshop,
      start,
      end,
      isInProgress,
      sizing: { isMobile },
      cardStyle = CardStyle.default,
      isProfile,
      displayAttendees,
      displayAttendeeCount,
      batchId,
      sessionId,
      isAttendingRaw,
      className = '',
      hideAttendButton = false,
      isEnded,
    } = this.props;

    const { slug, coverMedia, description, categories, title, mentor, isFree } =
      workshop;

    // workshop.isFree = true; // disable this line to simulate free workshops

    return (
      <Link
        to={parser({ name: 'workshop', params: { workshopSlug: slug } })}
        className={c(['c-card', className])}
        style={{
          color: 'white',
        }}
      >
        <CardCover
          type="workshop"
          coverImageUrl={coverMedia}
          description={description}
          start={start}
          isFree={isFree}
          end={end}
          category={categories && categories[0] ? categories[0] : undefined}
          actionButton={
            hideAttendButton
              ? null
              : this.props.renderActionButton({
                  isLoading: false,
                  buttonSize: ButtonSize.s,
                  cardStyle,
                  workshop,
                  batchId,
                  sessionId,
                  isAttendingRaw,
                  isInProgress,
                  isEnded,
                  timeStart: start,
                  timeEnd: end,
                  isFreeWorkshopBadgeDisplayed: isFree,
                })
          }
          isInProgress={isInProgress}
          useShortMinutesLabel={isMobile}
          cardStyle={cardStyle}
        />

        <CardTitleAttendees
          title={title}
          attendees={displayAttendees}
          attendeeCount={displayAttendeeCount}
          organiser={mentor}
          isProfile={isProfile}
          isEnded={isEnded}
        />
      </Link>
    );
  };
}

export const WorkshopCard = connect(['auth', 'sizing'], () => ({
  ...modalActions(),
  ...portalActions(),
}))(addWorkshopComponentActions(WorkshopCardComponent));
