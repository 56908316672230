import * as React from 'react';

import { Helmet } from 'react-helmet';

interface Props {
  title?: string;
  desc?: string;
  pathname?: string;
  isNoindexPage?: boolean;
}

interface State {
  isDarkMode?: boolean;
}

class Head extends React.Component<Props, State> {
  public state: State = {
    isDarkMode: undefined,
  };

  public componentDidMount = () => {
    if (!window.matchMedia) return;

    try {
      const darkModeQuery = window.matchMedia(
        'screen and (prefers-color-scheme: dark)'
      );

      const isDarkMode = darkModeQuery.matches;

      this.toggleColorScheme(isDarkMode);

      // @ts-ignore
      if (darkModeQuery.addEventListener) {
        darkModeQuery.addEventListener('change', this.handleColorSchemeChange);
      } else {
        darkModeQuery.addListener(this.handleColorSchemeChange);
      }
    } catch (e) {
      // do nothing
    }
  };

  public componentWillUnmount = () => {
    if (!window.matchMedia) return;

    try {
      const darkModeQuery = window.matchMedia(
        'screen and (prefers-color-scheme: dark)'
      );

      // @ts-ignore
      if (darkModeQuery.removeEventListener) {
        darkModeQuery.removeEventListener(
          'change',
          this.handleColorSchemeChange
        );
      } else {
        darkModeQuery.removeListener(this.handleColorSchemeChange);
      }
    } catch (e) {
      // do nothing
    }
  };

  private handleColorSchemeChange = (e: MediaQueryListEvent) => {
    this.toggleColorScheme(e.matches);
  };

  private toggleColorScheme = (isDarkMode: boolean) => {
    this.setState({ isDarkMode });
  };

  public render() {
    const { isDarkMode } = this.state;

    const defaultDesc = 'The playground for creative collaboration.';
    const {
      title,
      desc = defaultDesc,
      pathname = '',
      isNoindexPage,
    } = this.props;

    const environment = process.env.DAISIE_ENV || 'production';
    const isProduction = environment === 'production';
    const isTest = process.env.NODE_ENV === 'test';
    const titleSuffix =
      !isProduction && !isTest && environment
        ? `Daisie | ${environment}`
        : 'Daisie';
    const constructedTitle = title ? `${title} - ${titleSuffix}` : titleSuffix;

    return (
      <Helmet>
        <title>{`${constructedTitle}`}</title>
        <meta property="og:title" content={constructedTitle} />
        <meta name="twitter:title" content={constructedTitle} />
        <meta name="description" content={desc} />
        <meta property="og:description" content={desc} />
        <meta name="twitter:description" content={desc} />
        <link
          rel="shortcut icon"
          href={isDarkMode ? '/favicon-white.ico' : '/favicon.ico'}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <link rel="canonical" href={`https://www.daisie.com${pathname}`} />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="robots" content={isNoindexPage ? 'noindex' : 'index'} />
      </Helmet>
    );
  }
}

export { Head };
