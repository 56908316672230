import { getProjectsFromData } from '@/store/helpers';
import { getRoomsFromData } from '@/store/helpers/roomHelpers';

import { Http } from '@/utils/api/Http';
import {
  ALL_CATEGORIES_SLUG,
  CATEGORY_PAGE_PAGE_SIZE,
} from '@/utils/constants';

const feedState: FeedState = { feed: {} };

const feedActions = () => {
  const getDefaultNotCategories = (state: GlobalStoreState) => {
    const {
      organisedCategories: { discussionCategory, workshopRoomCategory },
    } = state;

    if (!discussionCategory || !workshopRoomCategory) {
      // TODO: get categories if missing
      return;
    }

    const notCategories = [
      discussionCategory.name,
      workshopRoomCategory.name,
    ].map((name: string) => encodeURIComponent(name));

    return notCategories.join(',');
  };

  return {
    clearFeeds: async () => ({
      feed: {},
    }),

    getFollowingFeed: async (
      state: GlobalStoreState,
      pageNumber?: number,
      pageSize?: number
    ) => {
      const page = pageNumber || 1;

      let feed;

      const queryPageSize = pageSize || CATEGORY_PAGE_PAGE_SIZE;
      const notCategories = getDefaultNotCategories(state);

      try {
        const { data } = await new Http(
          `/feed/following?pageNumber=${pageNumber}&pageSize=${queryPageSize}&sortBy=updated&notCategories=${notCategories}`
        ).get<APIObject<APIProject[]>>();

        const projectData = getProjectsFromData(data);

        feed = {
          ...state.feed,
          followingFeed: {
            ...state.feed.followingFeed,
            [page]: projectData,
          },
        };
      } catch (e) {
        feed = {};
      }

      return {
        feed,
      };
    },

    getDiscoverFeed: async (
      state: GlobalStoreState,
      pageNumber?: number,
      pageSize?: number,
      sortBy?: string
    ) => {
      const page = pageNumber || 1;

      let feed = {};

      const queryPageSize = pageSize || CATEGORY_PAGE_PAGE_SIZE;
      const querySortBy = sortBy || 'updatedAt';
      const notCategories = getDefaultNotCategories(state);

      try {
        const { data } = await new Http(
          `/feed/moderatedProjects?pageNumber=${pageNumber}&pageSize=${queryPageSize}&sortBy=${querySortBy}&notCategories=${notCategories}`
        ).get<APIObject<APIProject[]>>();

        const projectData = getProjectsFromData(data);

        const existingFeed = state.feed.discoveryFeed;

        if (existingFeed && existingFeed[page - 1]) {
          existingFeed[page - 1].forEach((project: Project) => {
            projectData.forEach((newProject: Project, index) => {
              if (newProject.id === project.id) {
                projectData.splice(index, 1);
              }
            });
          });
        }

        feed = {
          ...state.feed,
          discoveryFeed: {
            ...state.feed.discoveryFeed,
            [page]: projectData,
          },
        };
      } catch (e) {
        throw e;
      }

      return {
        feed,
      };
    },

    getMostRecentByCategoryAndLFCFeed: async (
      state: GlobalStoreState,
      {
        categoryName,
        pageNumber,
        pageSize,
        sortBy,
        location,
        curatedImages,
      }: {
        categoryName: string;
        pageNumber?: number;
        pageSize?: number;
        sortBy?: string;
        location?: string;
        curatedImages?: CuratedImage[];
      }
    ) => {
      const page = pageNumber || 1;

      const queryPageSize = pageSize || CATEGORY_PAGE_PAGE_SIZE;
      const querySortBy = sortBy || 'updatedAt';
      const queryLocation = location ? location : '';

      const notCategories = getDefaultNotCategories(state);

      const queryCategoryName = [encodeURIComponent(categoryName)]
        .filter((name?: string) => !!name && name !== ALL_CATEGORIES_SLUG)
        .join(',');

      let feed = {};

      try {
        const { data } = await new Http(
          `/feed/moderatedProjects?pageNumber=${pageNumber}&pageSize=${queryPageSize}&sortBy=${querySortBy}&categories=${queryCategoryName}&location=${queryLocation}&notCategories=${notCategories}`
        ).get<APIObject<APIRoom[]>>();

        const projectData = getRoomsFromData(data, curatedImages);

        const existingFeed = state.feed.mostRecentByCategoryAndLFCFeed;

        if (existingFeed && existingFeed[page - 1]) {
          existingFeed[page - 1].forEach((project: Room) => {
            projectData.forEach((newProject: Room, index) => {
              if (newProject.id === project.id) {
                projectData.splice(index, 1);
              }
            });
          });
        }

        feed = {
          ...state.feed,
          mostRecentByCategoryAndLFCFeed: {
            ...state.feed.mostRecentByCategoryAndLFCFeed,
            [page]: projectData,
          },
        };
      } catch (e) {
        throw e;
      }

      return {
        feed,
      };
    },

    getMostActiveByCategoryAndLFCFeed: async (
      state: GlobalStoreState,
      categoryName: string,
      pageNumber?: number,
      pageSize?: number,
      updatedDays?: number,
      location?: string
    ) => {
      const page = pageNumber || 1;

      const queryUpdatedDays = updatedDays || 7;
      const queryPageSize = pageSize || CATEGORY_PAGE_PAGE_SIZE;
      const queryLocation = location ? location : '';

      const queryCategoryName = [encodeURIComponent(categoryName)]
        .filter((name?: string) => !!name && name !== ALL_CATEGORIES_SLUG)
        .join(',');

      const notCategories = getDefaultNotCategories(state);

      let feed = {};

      try {
        const { data } = await new Http(
          `/feed/repProjects?updatedDays=${queryUpdatedDays}&pageNumber=${pageNumber}&pageSize=${queryPageSize}&categories=${queryCategoryName}&location=${queryLocation}&notCategories=${notCategories}`
        ).get<APIObject<APIProject[]>>();

        const projectData = getProjectsFromData(data);

        const existingFeed = state.feed.mostActiveByCategoryAndLFCFeed;

        if (existingFeed && existingFeed[page - 1]) {
          existingFeed[page - 1].forEach((project: Project) => {
            projectData.forEach((newProject: Project, index) => {
              if (newProject.id === project.id) {
                projectData.splice(index, 1);
              }
            });
          });
        }

        feed = {
          ...state.feed,
          mostActiveByCategoryAndLFCFeed: {
            ...state.feed.mostActiveByCategoryAndLFCFeed,
            [page]: projectData,
          },
        };
      } catch (e) {
        throw e;
      }

      return {
        feed,
      };
    },
  };
};

export { feedState, feedActions };
