import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';

import { connect } from 'unistore/react';

import { Carousel } from '@/components/global/Carousel/Carousel';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';

import imgAlexJenkins from '@/pages/Category/images/Alex-Jenkins-Illustration.jpg';
import imgAliceBloomfield from '@/pages/Category/images/Alice-Bloomfield.jpg';
import imgAriaShahrokhshahi from '@/pages/Category/images/Aria-Shahrokhshahi-Documentary-Film-and-Photography.jpg';
import imgBertieGilbert from '@/pages/Category/images/Bertie-Gilbert-Filmmaking-and-Directing.jpg';
import imgBrunelJohnson from '@/pages/Category/images/Brunel-Johnson-Street-Photography.jpg';
import imgDebbieKnoxHewson from '@/pages/Category/images/Debbie-Knox-Hewson.jpg';
import imgElizabethWirija from '@/pages/Category/images/Elizabeth-Wirija-Campaign-Photography.jpg';
import imgEmmanuelAfolabi from '@/pages/Category/images/Emmanuel-Afolabi-Film-Production.jpg';
import imgGenieEspinosa from '@/pages/Category/images/Genie-Espinosa-Illustration.jpg';
import imgGeorgeMuncey from '@/pages/Category/images/George-Muncey-Film-Photography.jpg';
import imgHaleyPowers from '@/pages/Category/images/Haley-Powers.png';
import imgHouseOfAama from '@/pages/Category/images/House-of-Aama-Fashion-Design.png';
import imgHouseOfAama2 from '@/pages/Category/images/House-of-Aama.jpg';
import imgIrieWata from '@/pages/Category/images/Irie-Wata.jpg';
import imgJesseGold from '@/pages/Category/images/Jesse-Gold.jpg';
import imgJessyMoussallem from '@/pages/Category/images/Jessy-Moussallem-Directing.jpg';
import imgJimmyOctober from '@/pages/Category/images/Jimmy-October.jpg';
import imgLucyForbes from '@/pages/Category/images/Lucy-Forbes-Screenwriting-and-Directing.jpg';
import imgMolleyMay from '@/pages/Category/images/Molley-May-teaches-Art.jpg';
import imgRankin from '@/pages/Category/images/Rankin-Portrait-Photography.jpg';
import imgSnootieStudios from '@/pages/Category/images/Snootie-Studios.jpg';
import imgTiChang from '@/pages/Category/images/Ti-Chang.jpg';
import imgTomGlendinng from '@/pages/Category/images/Tom-Glendinng.jpg';
import imgYrJohannsdottir from '@/pages/Category/images/Yr-Johannsdottir-Textiles.jpg';
import imgZoeZimmer from '@/pages/Category/images/Zoe-Zimmer-Mixed-Media-Photography.jpg';

import { initializeMentorCarousel } from '@/utils/gsap/workshopTransitions';
import { t } from '@/utils/i18n/i18n';

interface Props extends SizingState {
  columns: number;
  cardOffset: number;
  category: string;
  titleClass?: string;
}

interface Creator {
  name: string;
  teaching: string;
  imageUrl: string;
}

interface Creators {
  [name: string]: Creator[];
}

const WorkshopMentorCarousel = forwardRef<HTMLDivElement, Props>(
  (
    {
      columns,
      cardOffset,
      category,
      titleClass = 'f-inter-bold-1',
      sizing,
    }: Props,
    sectionRef
  ) => {
    const [sectionHeight, setSectionHeight] = useState<number>(0);
    const mentorCarouselSection = sectionRef || useRef<HTMLDivElement>(null);
    const mentorCarouselTransition = useRef<any>(null);

    useEffect(() => {
      const carouselItems = document.querySelectorAll(
        '.c-workshop-creator-carousel .carousel__item'
      );
      const carouselHeight =
        carouselItems.length * carouselItems[0].clientWidth;
      setSectionHeight(carouselHeight);
    }, []);

    useEffect(() => {
      if (mentorCarouselTransition.current) {
        mentorCarouselTransition.current.scrollTrigger?.kill();
      }

      // Manage mentor carousel transitions
      const mentorCarouselTrack = document.querySelector(
        '.c-workshop-creator-carousel .carousel__track'
      );
      const mentorCarouselSection = document.querySelector(
        '.c-workshop-creator-carousel'
      );

      const mentorCarouselCards = document.querySelectorAll(
        '.c-workshop-creator-carousel .carousel__item'
      );

      // Initialise after 1s due to images not being loaded in time
      // for correct element height to be calculated
      const initializeTransitionTimeout = setTimeout(() => {
        mentorCarouselTransition.current = initializeMentorCarousel(
          mentorCarouselSection,
          mentorCarouselTrack,
          mentorCarouselCards,
          sizing.isDesktop
        );
      }, 1000);

      return () => clearTimeout(initializeTransitionTimeout);
    }, [sizing]);

    const creators: Creators = {
      art: [
        {
          name: 'Genie Espinosa',
          imageUrl: imgGenieEspinosa,
          teaching: 'Illustration',
        },
        {
          name: 'House Of Aama',
          imageUrl: imgHouseOfAama,
          teaching: 'Fashion Design',
        },
        {
          name: 'Alex Jenkins',
          imageUrl: imgAlexJenkins,
          teaching: 'Illustration',
        },
        {
          name: 'Molley may',
          imageUrl: imgMolleyMay,
          teaching: 'Art',
        },
        {
          name: 'Yr Jóhannsdóttir',
          imageUrl: imgYrJohannsdottir,
          teaching: 'Textiles',
        },
      ],
      music: [
        {
          name: 'Tom Glendinng',
          imageUrl: imgTomGlendinng,
          teaching: 'Ableton certified trainer',
        },
        {
          name: 'Jimmy October',
          imageUrl: imgJimmyOctober,
          teaching: 'Singer, songwriter & creative director',
        },
        {
          name: 'Haley Powers',
          imageUrl: imgHaleyPowers,
          teaching: 'Guitarist',
        },
        {
          name: 'Jesse Gold',
          imageUrl: imgJesseGold,
          teaching: 'Pop/R&B singer, songwriter & producer',
        },
        {
          name: 'Debbie Knox-Hewson',
          imageUrl: imgDebbieKnoxHewson,
          teaching: 'Drummer',
        },
      ],
      design: [
        {
          name: 'Alice Bloomfield',
          imageUrl: imgAliceBloomfield,
          teaching: 'Animation',
        },
        {
          name: 'Irie Wata',
          imageUrl: imgIrieWata,
          teaching: 'Digital Collage',
        },
        {
          name: 'Snootie Studios',
          imageUrl: imgSnootieStudios,
          teaching: 'Graphic design & Illustration',
        },
        {
          name: 'Ti Chang',
          imageUrl: imgTiChang,
          teaching: 'Industrial Design',
        },
        {
          name: 'House Of Aama',
          imageUrl: imgHouseOfAama2,
          teaching: 'Fashion & Textile design',
        },
      ],
      film: [
        {
          name: 'Aria Shahrokhshahi',
          imageUrl: imgAriaShahrokhshahi,
          teaching: 'Documentary Film & Photography',
        },
        {
          name: 'Emmanuel Afolabi',
          imageUrl: imgEmmanuelAfolabi,
          teaching: 'Film Production',
        },
        {
          name: 'Lucy Forbes',
          imageUrl: imgLucyForbes,
          teaching: 'Screenwriting & Directing',
        },
        {
          name: 'Bertie Gilbert',
          imageUrl: imgBertieGilbert,
          teaching: 'Filmmaking & Directing',
        },
        {
          name: 'Jessy Moussallem',
          imageUrl: imgJessyMoussallem,
          teaching: 'Directing',
        },
      ],
      photography: [
        {
          name: 'George Muncey',
          imageUrl: imgGeorgeMuncey,
          teaching: 'Film Photography',
        },
        {
          name: 'Zoe Zimmer',
          imageUrl: imgZoeZimmer,
          teaching: 'Mixed Media Photography',
        },
        {
          name: 'Elizabeth Wirija',
          imageUrl: imgElizabethWirija,
          teaching: 'Campaign Photography',
        },
        {
          name: 'Brunel Johnson',
          imageUrl: imgBrunelJohnson,
          teaching: 'Street Photography',
        },
        {
          name: 'Rankin',
          imageUrl: imgRankin,
          teaching: 'Portrait Photography',
        },
      ],
    };

    return (
      <section
        className="c-workshop-creator-carousel relative mt368@l mt256@m mt200@s"
        ref={mentorCarouselSection}
        style={{ height: sectionHeight }}
      >
        <Carousel
          columns={columns}
          padding={32}
          wrap={false}
          className="u-sticky u-sticky--t"
          buttonClassName="carousel__button--theme-light u-link-black--alt u-hide u-flex@l"
          counterDotsClassName="u-hide"
          shadowFix={true}
          disableSnap={false}
          showFade={false}
          isLoaded={true}
          hideArrows={true}
          cardOffset={cardOffset}
        >
          {creators[category].map((creator, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className="c-workshop-creator-carousel__card relative u-overflow-hidden"
            >
              <ImageLoader
                src={creator.imageUrl}
                alt={creator.name}
                className="relative u-object-cover"
                lazyload={false}
              />
              <h3
                className={`absolute absolute--mid-center f-title-1 u-bold pb6 u-text-center u-z-index-1 ${titleClass}`}
              >
                {t(creator.name)}
              </h3>
              <p className="absolute absolute--bottom-center u-caps u-light-grey f-text-3 f-inter-medium-1 u-letter-spacing mb20 u-z-index-1 u-text-center">
                {t(creator.teaching)}
              </p>
              <div className="fill u-bg-fade" />
            </div>
          ))}
        </Carousel>
      </section>
    );
  }
);

export default connect(['sizing'], () => ({}))(memo(WorkshopMentorCarousel));
