import * as React from 'react';

import { Icon } from '@/components/global/Icon/Icon';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { Link } from '@/components/global/Link/Link';
import ImgDaisieAvatar from '@/components/notifications/daisie-avatar.jpg';
import { Avatar } from '@/components/users/Avatar/Avatar';
import { UserVerified } from '@/components/users/UserVerified/UserVerified';

import { IconSize } from '@/enums/IconSize';

import { renderTimeLabel } from '@/utils/date/date-manipulation';
import { c } from '@/utils/strings/c';

import '@/utils/class-extensions/Date';

interface Props {
  notification: DaisieNotification;
  primary?: string | React.ReactNode;
  secondary?: string | React.ReactNode;
  tertiary?: string | React.ReactNode;
  action?: React.ReactNode;
  className?: string;
  showActor?: boolean;
  icon?: string;
  iconClassName?: string;
  useDaisieLogo?: boolean;
  onClickToggle: () => void;
  isDarkMode: boolean;
  borderBottom?: boolean;
}

class BaseNotification extends React.Component<Props> {
  public render() {
    const {
      notification,
      primary = null,
      secondary = null,
      tertiary = null,
      className = '',
      action = null,
      showActor = true,
      icon,
      iconClassName = '',
      useDaisieLogo = false,
      isDarkMode,
    } = this.props;

    const { user: u, collaborator, createdAt } = notification;
    const primaryUser = collaborator || u;
    const collaborators = collaborator ? [collaborator, u] : [u];

    return (
      <article className={className}>
        <div
          className={c('u-flex u-justify-between u-align-start mh24@s', {
            'pb20 bb--sad-grey u-light-grey': isDarkMode,
          })}
        >
          <div
            className="c-notification__avatar u-flex-hold"
            onClick={this.props.onClickToggle}
          >
            {useDaisieLogo ? (
              <ImageLoader src={ImgDaisieAvatar} className="u-rounded" />
            ) : (
              <>
                {collaborators.map((user, i) => {
                  if (!user) {
                    return null;
                  }

                  return (
                    <Link
                      // eslint-disable-next-line react/no-array-index-key
                      key={user.id + i}
                      to={{ name: 'user', params: { username: user.username } }}
                      className="relative"
                    >
                      {user && user.avatar && (
                        <Avatar
                          src={user.avatar}
                          user={user}
                          size="notification"
                        />
                      )}
                      {icon && (
                        <div className="c-notification__icon">
                          <Icon
                            id={icon}
                            className={c(['u-block', iconClassName])}
                            size={IconSize.xs}
                          />
                        </div>
                      )}
                    </Link>
                  );
                })}
              </>
            )}
          </div>
          <div className="u-1/1 mr8">
            <div className="u-line-height c-notification__primary">
              {showActor && primaryUser && (
                <Link
                  to={{
                    name: 'user',
                    params: { username: primaryUser.username },
                  }}
                  className={c('mr4', {
                    'u-inline-flex u-align-center': !!primaryUser.flair,
                  })}
                >
                  {primaryUser.primaryName}
                  <UserVerified user={primaryUser} />
                </Link>
              )}
              {primary}
            </div>
            <div className="c-notification__secondary u-grey">
              {typeof secondary === 'function'
                ? secondary({ date: renderTimeLabel(createdAt) })
                : secondary}
            </div>
          </div>
          {action}
        </div>
        {tertiary}
      </article>
    );
  }
}

export { BaseNotification };
