import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { routes } from '@/routes';
import { authActions } from '@/store/modules/auth';
import { t } from '@/utils/i18n/i18n';
import { getDisplayPrice } from '@/utils/strings/currency';
import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'unistore/react';
import bogoCardImage1 from './img/bogo_card_1.jpg';
import bogoCardImage2 from './img/bogo_card_2.jpg';
import bogoCardImage3 from './img/bogo_card_3.jpg';

interface Props extends SubscriptionState, SizingState {
  promoCode: string;
}

const PromoBogoComponent = ({ subscription, sizing, promoCode }: Props) => (
  <div className="u-100vh-var@m u-flex u-align-center">
    <div className="u-flex u-flex-column@s u-1/1">
      <div className="u-flex u-flex-column u-align-center u-justify-center u-flex-1 u-white">
        <div className="p24" style={{ maxWidth: '530px' }}>
          <h1 className="f-lynstone-bold-1 mb24">
            {t('Redeem free membership')}
          </h1>
          <h2 className="f-lynstone-book-2b mb24">
            Get annual access today for{' '}
            <span className="u-go">
              {getDisplayPrice(subscription.userCurrency, 0)}
            </span>
          </h2>
          <Button
            type={ButtonType.action}
            onClick={() => {
              if (!promoCode) return;

              localStorage.setItem(
                'promotionalCode',
                JSON.stringify(promoCode)
              );

              window.location.href = routes.optimisedJoinFlowSignUpYearly;
            }}
            buttonStyle={ButtonStyle.default}
            size={ButtonSize.xl}
            text={t('Continue')}
            className="u-fit-content u-1/1@s"
          />
        </div>
      </div>
      <div
        className="u-flex-1 u-flex@m u-grid@s u-grid--3-col ph24@s"
        style={{ columnGap: '12px' }}
      >
        <ImageLoader
          className="mr12@m u-border-radius--s u-object-cover"
          src={bogoCardImage1}
          style={{
            width: !sizing.isMobile ? '310px' : '100%',
            aspectRatio: '1/1.5',
          }}
        />
        <ImageLoader
          className="mr12@m u-border-radius--s u-object-cover"
          src={bogoCardImage2}
          style={{
            width: !sizing.isMobile ? '310px' : '100%',
            aspectRatio: '1/1.5',
          }}
        />
        <ImageLoader
          className="u-border-radius--s u-object-cover"
          style={{
            width: !sizing.isMobile ? '310px' : '100%',
            aspectRatio: '1/1.5',
          }}
          src={bogoCardImage3}
        />
      </div>
    </div>
  </div>
);

export const PromoBogo = connect(
  ['subscription', 'sizing'],
  (store: GlobalStoreState) => ({
    ...authActions(store),
  })
)(PromoBogoComponent);
