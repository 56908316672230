import React, { useEffect, useState } from 'react';

import { useCookies } from 'react-cookie';
import {
  Redirect,
  RouteComponentProps,
  useHistory,
  withRouter,
} from 'react-router-dom';
import { connect } from 'unistore/react';

import { Head } from '@/components/global/Head/Head';
import { LoadingSymbol } from '@/components/global/LoadingSymbol/LoadingSymbol';
import { SiteFooter } from '@/components/global/SiteFooter/SiteFooter';
import { Toast } from '@/components/global/Toaster/Toast';
import { OriginalsWrapper } from '@/components/originals/OriginalsWrapper';
import { WorkshopWrapper } from '@/components/workshops/WorkshopWrapper';
import { WorkshopWrapperV2 } from '@/components/workshops/WorkshopWrapperV2';

import { DaisieSubscriptionTier } from '@/enums/DaisieSubscriptionTier';
import { ViewReferer } from '@/enums/ViewReferer';
import { WorkshopEventType } from '@/enums/WorkshopEventType';

import { WORKSHOP_RELATED_WORKSHOPS_FAIL } from '@/messages/errors';

import { PageNotFound } from '@/pages/PageNotFound/PageNotFound';

import { routes } from '@/routes';
import { parser } from '@/routes/parser';

import { cinematicBackgroundActions } from '@/store/modules/cinematicBackground';
import { roomActions } from '@/store/modules/room';
import { workshopActions } from '@/store/modules/workshop';

import { t } from '@/utils/i18n/i18n';
import { track } from '@/utils/mixpanel/mixpanel';
import { c } from '@/utils/strings/c';
import { getTrackingLocationFromLocationState } from '@/utils/urls/routes';

interface MatchParams {
  workshopSlug: string;
}

interface Props
  extends WorkshopState,
    WorkshopActions,
    SizingState,
    WorkshopActions,
    AuthState,
    RoomActions,
    RoomState,
    CinematicBackgroundActions,
    CinematicBackgroundState,
    RouteComponentProps<MatchParams, {}, LocationState> {}

const WorkshopComponent = ({
  getFeaturedWorkshops,
  fetchCuratedImages,
  changeBackgroundImage,
  auth,
  workshop,
  getCurrentWorkshop,
  clearWorkshop,
  clearRoom,
  location,
  match,
}: Props) => {
  const [loading, setLoading] = useState(true);
  const [additionalWorkshopData, setAdditionalWorkshopData] = useState(null);
  const [cookies, setCookie] = useCookies();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        await getFeaturedWorkshops();
      } catch (e) {
        new Toast({
          body: WORKSHOP_RELATED_WORKSHOPS_FAIL,
          failure: true,
        }).dispatch();
      }

      if (auth.isAuthorised) {
        fetchCuratedImages();
      }

      await fetchData();
    })();

    processToasts();

    return () => {
      if (
        !window.location.pathname.includes('/workshops/') &&
        workshop &&
        workshop.currentWorkshop &&
        workshop.currentWorkshop.isAttending
      ) {
        changeBackgroundImage(undefined);
      }

      clearWorkshop();
      clearRoom();
    };
  }, []);

  useEffect(() => {
    // !!! TODO this is called 3 times
    setLoading(true);
    setAdditionalWorkshopData(null);
    fetchData();
  }, [
    match.params.workshopSlug,
    !!workshop.currentWorkshop && workshop.currentWorkshop.isAttending,
  ]);

  // Some workshops may still use v1 of the design
  // check if all of the required properties have been set to enable
  // the v2 of the design.
  const isWorkshopV2 = () => {
    if (!workshop.currentWorkshop || !workshop.currentWorkshop.attributes) {
      return;
    }

    const isOriginal =
      workshop.currentWorkshop.eventType === WorkshopEventType.original;

    const workshopAttributes = workshop.currentWorkshop.attributes;

    return (
      !isOriginal &&
      workshopAttributes.fontFamily &&
      workshopAttributes.learnContent.length > 0 &&
      workshopAttributes.themeColor &&
      workshopAttributes?.sentences.length > 0 &&
      workshopAttributes.outcomeImages &&
      workshopAttributes.outcomeImages.length === 2
    );
  };

  const processToasts = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const errorMessage = urlParams.get('errorMessage');

    if (errorMessage) {
      new Toast({
        body: errorMessage,
        failure: true,
      }).dispatch();

      window.history.replaceState(null, '', window.location.pathname);
    }
  };

  const getWorkshopId = () => {
    const { workshopSlug } = match.params;
    return `workshop_${workshopSlug.split('&')[0].split('-').pop()}`;
  };

  const fetchData = async () => {
    try {
      await clearRoom();

      // Look for `utm_source` query param in current URL to detect
      // whether the visit originates from an ad
      const params = new URLSearchParams(location.search);
      const hasUtmSource: boolean = !!params.get('utm_source');

      const referer: ViewReferer | undefined =
        hasUtmSource &&
        !auth.isAuthorised &&
        params.get('utm_source') === 'mentor'
          ? ViewReferer.mentor
          : hasUtmSource &&
            !auth.isAuthorised &&
            params.get('utm_source') !== 'mentor'
          ? ViewReferer.ad
          : undefined;

      const expiryDate = new Date(new Date().getTime() + 1 * 24 * 3600 * 1000);

      if (referer === ViewReferer.mentor) {
        setCookie('referrerWorkshopId', getWorkshopId(), {
          expires: expiryDate,
          path: '/',
        });
      }

      await getCurrentWorkshop(getWorkshopId(), referer);

      track('open_workshop', {
        workshopId: getWorkshopId(),
        isAuthorised: auth.isAuthorised,
        from: getTrackingLocationFromLocationState(location.state),
      });

      // Current date with seconds and milliseconds set to 0
      // appending this as a parameter will allow the browser to only cache
      // the data.json file for 1 minute.
      const currentDate = new Date();
      const timestamp = currentDate.setHours(
        currentDate.getHours(),
        currentDate.getMinutes(),
        0,
        0
      );

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="u-flex u-1/1 u-justify-center u-h-75vh u-align-center">
        <Head title={t('Workshops')} pathname={location.pathname} />
        <LoadingSymbol size="l" colour="white" />
      </div>
    );
  }

  if (!workshop.currentWorkshop) {
    return <PageNotFound />;
  }

  const currentWorkshopId = workshop.currentWorkshop.id;

  const filteredFeaturedWorkshops = workshop.featuredWorkshops
    ? workshop.featuredWorkshops.filter(
        (w: Workshop) => !w.hasElapsed && w.id !== currentWorkshopId
      )
    : [];

  const isV2 = isWorkshopV2();

  const isOriginals = workshop.currentWorkshop.eventType === 'original';
  const shouldRedirectToOriginals =
    !auth.isAuthorised ||
    (auth.isAuthorised &&
      auth.user?.subscriptionTier !== DaisieSubscriptionTier.premium);
  const shouldRedirectToOverview =
    auth.isAuthorised &&
    auth.user?.subscriptionTier === DaisieSubscriptionTier.premium;

  if (isOriginals && shouldRedirectToOriginals) {
    return <Redirect to={routes.alexJenkinsClass} />;
  }

  if (isOriginals && shouldRedirectToOverview) {
    return (
      <Redirect to={`/workshops/overview/${workshop.currentWorkshop.slug}`} />
    );
  }

  return (
    <>
      <Head
        title={`${workshop.currentWorkshop.title} - ${t('Workshops')}`}
        desc={workshop.currentWorkshop.description.slice(0, 200)}
        pathname={location.pathname}
      />

      {isV2 ? (
        <WorkshopWrapperV2
          workshop={workshop.currentWorkshop}
          additionalWorkshopData={additionalWorkshopData}
        />
      ) : (
        <WorkshopWrapper
          workshop={workshop.currentWorkshop}
          moreWorkshops={filteredFeaturedWorkshops || []}
        />
      )}
      <div
        className={c('', {
          'mb100@m mb100@s': isV2,
        })}
      >
        <SiteFooter />
      </div>
    </>
  );
};

export const Workshop = connect(
  ['workshop', 'sizing', 'auth', 'room', 'cinematicBackground'],
  (store: GlobalStoreState) => ({
    ...workshopActions(store),
    ...roomActions(store),
    ...cinematicBackgroundActions(store),
  })
)(withRouter(WorkshopComponent));
