import React from 'react';

interface Props {
  sentences: string[];
}

const WorkshopSentences = ({ sentences }: Props) => (
  <section className="c-workshop-sentences u-7/8@m u-3/4@l wrap u-flex@l">
    <h2 className="c-workshop-sentences__h2 f-inter-medium-1 u-word-break mr20@s u-flex-1 u-flex-grow mb48@m mb128@l">
      {sentences[0]}
    </h2>
    <div className="ml128@l ml64@m ml48@s mt200@l mt24@s mr12@s mb48@m u-flex-1 u-flex-grow u-align-flex-end">
      <p className="mb32 f-inter-light-1 c-workshop-sentences__p">
        {sentences[1]}
      </p>
      <p className="f-inter-light-1 c-workshop-sentences__p">{sentences[2]}</p>
    </div>
  </section>
);

export default WorkshopSentences;
