import React, { ReactNode, useState } from 'react';

import {
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  Circle,
  Text,
} from '@chakra-ui/react';

import { ArrowBottomRightIcon } from '@/components/design_system/AlexJenkinsClass/atoms';

import { colors } from '@/styles/chakra-theme/colors';
import { pxToRem } from '@/styles/chakra-theme/metrics';

import { isMobile } from '@/utils/DOM/sizing';

interface Props extends AccordionProps {
  items: { title: string; text: string | ReactNode }[];
}

export const SimpleAccordion = ({ items = [], ...props }: Props) => {
  const [isOpenTitle, setIsOpenTitle] = useState('');
  const [isHoverTitle, setIsHoverTitle] = useState('');

  return (
    <ChakraAccordion
      allowToggle
      gap={pxToRem(8)}
      borderBottomWidth="0.5px"
      borderColor="white"
      {...props}
    >
      {items.map((item) => (
        <AccordionItem
          key={item.title}
          borderTopWidth="0.5px"
          borderColor="white"
        >
          <AccordionButton
            px={0}
            py={pxToRem(16)}
            onClick={() =>
              setIsOpenTitle(isOpenTitle === item.title ? '' : item.title)
            }
            onMouseEnter={() => setIsHoverTitle(item.title)}
            onMouseLeave={() => setIsHoverTitle('')}
            _focus={{ border: 'none' }}
          >
            <Text
              flex={1}
              fontWeight={400}
              fontSize={[22, 22, 36]}
              textAlign="left"
              color="white"
              mr={pxToRem(4)}
            >
              {item.title}
            </Text>

            <Circle
              centerContent
              size={isMobile() ? 14 : 16}
              bg={
                [isHoverTitle, isOpenTitle].includes(item.title)
                  ? 'linear-gradient(90deg, #8543E3 0%, #633BEB 100%)'
                  : 'greyDark'
              }
              borderRadius={100}
            >
              <ArrowBottomRightIcon
                fontSize={isMobile() ? 16 : 28}
                transform={
                  isOpenTitle === item.title
                    ? 'rotate(-135deg)'
                    : 'rotate(45deg)'
                }
                mt={pxToRem(item.title === isOpenTitle ? -6 : 4)}
                transitionDuration="0.25s"
              />
            </Circle>
          </AccordionButton>

          <AccordionPanel px={0} py={pxToRem(16)}>
            <Text
              maxWidth="90%"
              fontSize={[22, 36]}
              lineHeight={[pxToRem(27.5), pxToRem(50)]}
              color={colors.grey}
              pb={pxToRem(28)}
            >
              {item.text}
            </Text>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </ChakraAccordion>
  );
};
