import { getCalendarEventsFromData } from '@/store/helpers/calendarHelpers';

import { Http } from '@/utils/api/Http';

const calendarState: CalendarState = {
  calendar: {
    isLoaded: false,
    events: [],
  },
};

const getCalendarPage = async (
  pageNumber: number = 1,
  events: CalendarEvent[] = [],
  earliestDate?: Date,
  latestDate?: Date
): Promise<CalendarEvent[]> => {
  const params = new URLSearchParams();
  params.append('pageNumber', pageNumber.toString());
  params.append('pageSize', '100');

  if (earliestDate) {
    params.append('earliest', earliestDate.toISOString());
  }

  if (latestDate) {
    params.append('latest', latestDate.toISOString());
  }

  const { data } = await new Http(`/calendar?${params.toString()}`).get<
    APIArray<APICalendarEvent>
  >();

  const calendarEvents = getCalendarEventsFromData(data);

  if (calendarEvents.length === 0 || calendarEvents.length < 100) {
    return [...events, ...calendarEvents];
  } else {
    return await getCalendarPage(
      pageNumber + 1,
      [...events, ...calendarEvents],
      earliestDate,
      latestDate
    );
  }
};

const calendarActions = () => ({
  getCalendar: async (state: any, earliestDate?: Date, latestDate?: Date) => {
    const unsortedEvents = await getCalendarPage(
      1,
      [],
      earliestDate,
      latestDate
    );

    const events = unsortedEvents.sort(
      (a: CalendarEvent, b: CalendarEvent) =>
        a.start.getTime() - b.start.getTime()
    );

    return {
      calendar: {
        isLoaded: true,
        events,
        eventDateFirst: events[0] ? events[0].start : undefined,
        eventDateLast: events[events.length - 1]
          ? events[events.length - 1].start
          : undefined,
      },
    };
  },
  updateCalendar: async (
    state: any,
    earliestDate?: Date,
    latestDate?: Date
  ) => {
    let unsortedEvents = await getCalendarPage(1, [], earliestDate, latestDate);
    unsortedEvents = [...state.calendar.events, ...unsortedEvents];

    const events = unsortedEvents.sort(
      (a: CalendarEvent, b: CalendarEvent) =>
        a.start.getTime() - b.start.getTime()
    );

    return {
      calendar: {
        isLoaded: true,
        events,
        eventDateFirst: events[0] ? events[0].start : undefined,
        eventDateLast: events[events.length - 1]
          ? events[events.length - 1].start
          : undefined,
      },
    };
  },
});

export { calendarActions, calendarState };
