/* eslint-disable react/no-unused-state */
import * as React from 'react';

import { Icon } from '@/components/global/Icon/Icon';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { Wordmark } from '@/components/global/Wordmark/Wordmark';
import imgWilf from '@/components/maintenance_mode/wilf.jpg';

import { DaisieWordmarkColour } from '@/enums/DaisieWordmarkColour';
import { IconSize } from '@/enums/IconSize';

import { Http } from '@/utils/api/Http';
import { SECONDS } from '@/utils/constants';
import {
  enableMaintenanceModeCheck,
  notProduction,
} from '@/utils/featureToggles';
import { t } from '@/utils/i18n/i18n';

interface Props {}

interface State {
  isFirstLoad: boolean;
  isPending: boolean;
  isMaintenanceMode: boolean;
  estimatedCompletion?: string;
}

class MaintenanceMode extends React.Component<Props, State> {
  // CONFIG
  private CHECK_INTERVAL: number = 60 * SECONDS;

  public state: State = {
    isFirstLoad: true,
    isPending: true,
    isMaintenanceMode: false,
    estimatedCompletion: undefined,
  };

  public componentDidMount = async () => {
    if (!enableMaintenanceModeCheck()) {
      if (notProduction()) {
        // eslint-disable-next-line no-console
        console.log('-> Maintenance mode disabled');
      }

      this.setState({ isPending: false });
      return;
    }

    await this.checkMaintenanceMode();
  };

  public componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { isMaintenanceMode } = this.state;
    const {
      isMaintenanceMode: prevIsMaintenanceMode,
      isFirstLoad: prevIsFirstLoad,
    } = prevState;

    if (prevIsMaintenanceMode !== isMaintenanceMode && !prevIsFirstLoad) {
      location.reload();
    }
  };

  private checkMaintenanceMode = async () => {
    if (notProduction()) {
      // eslint-disable-next-line no-console
      console.log('-> Checking maintenance mode...');
    }

    try {
      const url = notProduction()
        ? 'https://dais.ie/status-stage'
        : 'https://dais.ie/status';

      const { maintenanceMode, estimatedCompletion } = await new Http(
        url
      ).get();

      this.setState({
        isFirstLoad: false,
        isPending: false,
        isMaintenanceMode: !!maintenanceMode,
        estimatedCompletion,
      });

      if (notProduction()) {
        // eslint-disable-next-line no-console
        console.log('-> Maintenance mode check successful', {
          isMaintenanceMode: !!maintenanceMode,
          estimatedCompletion,
        });
      }

      setTimeout(() => {
        this.checkMaintenanceMode();
      }, this.CHECK_INTERVAL);
    } catch (e) {
      if (notProduction()) {
        // eslint-disable-next-line no-console
        console.log('-> Maintenance error', e);
      }

      this.setState({
        isFirstLoad: false,
        isPending: false,
        isMaintenanceMode: false,
        estimatedCompletion: undefined,
      });
    }
  };

  public render = () => {
    const { children } = this.props;

    if (!enableMaintenanceModeCheck()) {
      return children;
    }

    const { isPending, isMaintenanceMode, estimatedCompletion } = this.state;

    if (isPending) {
      return null;
    }

    if (isMaintenanceMode) {
      return (
        <div className="c-maintain-mode">
          <Wordmark
            colour={DaisieWordmarkColour.White}
            className="c-maintain-mode__wordmark"
          />

          <div className="c-maintain-mode__content">
            <ImageLoader
              src={imgWilf}
              className="c-maintain-mode__content__wilf mhauto mb44"
            />

            <h1 className="f-yodel-1b mb16">{t(`Be right back`)}</h1>

            <p className="f-text-1 mb16 u-grey">
              {t(
                `We're taking care of some essential updates to keep everything running smoothly.`
              )}
            </p>

            {!!estimatedCompletion && (
              <p className="f-text-1 mb16 u-grey">
                {t(`We'll be back at`)} {estimatedCompletion}.
              </p>
            )}

            <p className="f-text-1 u-grey mb64">{t(`See you soon!`)}</p>
          </div>

          <div className="c-maintain-mode__stay_in_touch">
            <p className="f-text-4 u-grey mb16 u-text-center">
              {t('Stay in touch')}
            </p>

            <div className="u-flex u-align-center u-justify-center">
              <a
                href="https://www.instagram.com/daisieapp/"
                className="u-link-grey"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon className="mr16" size={IconSize.s} id="instagram" />
              </a>

              <a
                href="https://www.facebook.com/daisieapp/"
                className="u-link-grey"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon className="mr16" size={IconSize.s} id="facebook" />
              </a>

              <a
                href="https://twitter.com/daisie"
                className="u-link-grey"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon className="mr16" size={IconSize.s} id="twitter" />
              </a>

              <a
                href="https://www.youtube.com/channel/UClWp9PFiSBh9h0nnkAM0MqA"
                className="u-link-grey"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon className="mr16" size={IconSize.s} id="youtube" />
              </a>

              <a
                href="https://www.tiktok.com/@daisieapp"
                className="u-link-grey"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon size={IconSize.s} id="tiktok" />
              </a>
            </div>
          </div>
        </div>
      );
    }

    return children;
  };
}

export { MaintenanceMode };
