import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { connect } from 'unistore/react';
import widont from 'widont';

import { FormValidator } from '@/components/global/Forms/FormValidator/FormValidator';
import { Input } from '@/components/global/Forms/Input/Input';
import { Head } from '@/components/global/Head/Head';
import { Icon } from '@/components/global/Icon/Icon';
import { Toast } from '@/components/global/Toaster/Toast';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { IconSize } from '@/enums/IconSize';

import {
  EMAIL_INVALID,
  FIELD_REQUIRED,
  WEB3_JOIN_WAITLIST_FAIL,
} from '@/messages/errors';

import { debounce } from '@/utils/api/throttle';
import { isEmailValid } from '@/utils/forms/validators';
import { t } from '@/utils/i18n/i18n';

interface Props extends SizingState, AuthState, RouteComponentProps {
  close: () => void;
}

interface State {
  isSubscribing: boolean;
  isSubscribed: boolean;
  isInputModalOpen: boolean;
  email?: string;
  checking: boolean;
  isUserSignedUp: boolean;
}

class CryptoSignUpComponent extends React.Component<Props, State> {
  public state: State = {
    isSubscribing: false,
    isSubscribed: false,
    isInputModalOpen: false,
    email: '',
    checking: false,
    isUserSignedUp: !!localStorage.getItem('signed_up_for_crypto'),
  };

  public componentDidMount = () => {
    this.appendGhostMouseScript();
  };

  private appendGhostMouseScript = () => {
    const script = document.createElement('script');
    script.src = '/scripts/ghostMouse.js';
    script.async = true;
    script.id = 'ghostMouse';
    document.body.appendChild(script);
    document.body.removeChild(script);
  };

  private submitValidation = (state: any) => {
    const errors: any = {};
    const { email } = state;

    if (!email) errors.email = FIELD_REQUIRED;

    return errors;
  };

  private changeValidation = async (state: any) => {
    this.setState({ checking: true });

    const errors: any = {};
    const { email } = state;

    if (email.length > 0 && email !== this.state.email) {
      const isValid = isEmailValid(email.toLowerCase());

      if (!isValid) {
        errors.email = widont(EMAIL_INVALID);
      } else {
        this.setState({ email });
      }
    }

    this.setState({ checking: false });

    return errors;
  };

  private handleSubmit = async (state: any) => {
    const {
      auth: { user },
    } = this.props;

    const { isSubscribing, isSubscribed } = this.state;

    if (!user && (isSubscribed || isSubscribing)) {
      return;
    }

    if ((!user && !state.email) || (user && !user.email)) {
      return;
    }

    this.setState({
      isSubscribing: true,
    });

    const formData = new FormData();

    formData.append('EMAIL', !!user ? user.email : state.email);
    formData.append('FNAME', '');
    formData.append('LNAME', '');
    formData.append('b_fb49619603308032d5cb5887a_a0c34bf987', '');

    try {
      await fetch(
        'https://daisie.us16.list-manage.com/subscribe/post?u=fb49619603308032d5cb5887a&id=a0c34bf987',
        {
          method: 'POST',
          mode: 'no-cors',
          body: formData,
        }
      );

      this.setState({
        isSubscribed: true,
        isSubscribing: false,
        isInputModalOpen: false,
      });

      localStorage.setItem('signed_up_for_crypto', 'true');
    } catch (e) {
      new Toast({
        body: WEB3_JOIN_WAITLIST_FAIL,
      }).dispatch();
    }
  };

  private dismissModal = () => {
    this.setState({ isInputModalOpen: false });
  };

  private inputModal = () => (
    <div
      className="pv44 c-crypto-signup__modal u-justify-start ph44@m ph20@s u-flex u-flex-column u-bg-white u-bg-charcoal-grey u-border-radius u-border-radius--s"
      style={{ zIndex: 9999 }}
    >
      <button
        type="button"
        className="u-flex u-justify-end u-1/1 mb32"
        onClick={() => {
          this.dismissModal();
        }}
      >
        <Icon id="clear" className="u-white" size={IconSize.xs} />
      </button>
      <div className="pb32@s pb60@m">
        <h2 className="f-lynstone-bold-2 pb16">{t('Join the waitlist')}</h2>
        <p className="u-grey f-text-1">
          {t('We’ll let you know when we’re ready.')}
        </p>
      </div>
      <FormValidator
        submit={this.handleSubmit}
        submitValidation={this.submitValidation}
        changeValidation={this.changeValidation}
        initialState={{
          email: '',
        }}
      >
        {({
          submit,
          changeValidation,
          errors,
          state = {},
          updateForm,
        }: {
          submit(): void;
          changeValidation(): void;
          updateForm(key: string): (event: any) => void;
          updateFormArray: any;
          errors: any;
          state: any;
        }) => {
          const preventSubmit = !!Object.keys(errors).length || !state.email;

          const { checking, isSubscribed, isSubscribing } = this.state;
          return (
            <form onSubmit={submit} onChange={debounce(changeValidation, 750)}>
              <div>
                <Input
                  id="email"
                  placeholder={t('Email address')}
                  type="text"
                  required={true}
                  value={state.email}
                  error={errors.email}
                  errorStyle="input-group--error-alt"
                  onChange={updateForm('email')}
                  autoComplete="off"
                  autoFocus={true}
                  delay={true}
                  checking={checking}
                  uiTestId="register-email"
                  inputClassName="u-bg-sad-grey u-white b--none mb20 u-border-radius pv20 pl32"
                />
              </div>

              <div>
                <Button
                  type={ButtonType.action}
                  onClick={() => {
                    this.handleSubmit(state);
                  }}
                  size={ButtonSize.xl}
                  disabled={preventSubmit || this.state.checking}
                  text={
                    isSubscribing || isSubscribed
                      ? t('Joining...')
                      : t('Submit')
                  }
                  uiTestId="register-submit"
                  className="u-1/1 opacity-1"
                  buttonStyle={ButtonStyle.default}
                  buttonTextClassName="pv2"
                />
              </div>
            </form>
          );
        }}
      </FormValidator>
    </div>
  );

  public render = () => {
    const {
      sizing: isMobile,
      auth: { user },
    } = this.props;

    const { isInputModalOpen } = this.state;

    return (
      <>
        <div
          className="page-content--centered wrap wrap--m u-white u-text-center"
          id="container"
          touch-action="none"
          onClick={() => isInputModalOpen && this.dismissModal()}
          style={{
            filter: isInputModalOpen ? 'grayscale(100%) blur(5px)' : '',
            transition: 'filter 1s',
          }}
        >
          <Head title="Crypto" pathname={t('/crypto')} />
          <CSSTransition
            in={this.state.isSubscribed}
            appear={true}
            timeout={2000}
            classNames="fadein"
            unmountOnExit={false}
          >
            <div
              className="u-flex u-flex-column u-align-center absolute mh20@s mb44@s"
              style={{ zIndex: !isMobile ? 9998 : undefined }}
            >
              <h1 className="f-lynstone-bold-1a mt32@s mb12@s mb24@m">
                {this.state.isUserSignedUp ? (
                  <>
                    <span className="u-block pb12">Coming soon.</span>You’re on
                    the waitlist.
                  </>
                ) : this.state.isSubscribed ? (
                  'Thank you!'
                ) : (
                  'Enter the rabbit hole'
                )}
              </h1>
              <h2 className="f-title-3 mb44@m mb32@s u-grey">
                {t(
                  this.state.isUserSignedUp
                    ? ''
                    : this.state.isSubscribed
                    ? 'You’ve been added to the Waitlist.'
                    : 'Learn about the exciting world of Web3, Crypto, NFTs and the Blockchain'
                )}
              </h2>

              {!this.state.isUserSignedUp && !this.state.isSubscribed ? (
                <Button
                  type={ButtonType.action}
                  onClick={() => {
                    !user || (!!user && user.email === '')
                      ? this.setState({ isInputModalOpen: true })
                      : this.handleSubmit(user);
                  }}
                  buttonStyle={ButtonStyle.default}
                  text={!!user ? 'Be the first to know' : 'Join Waitlist'}
                  className="u-1/2@m u-1/1@s"
                  buttonTextClassName="pv4"
                  size={ButtonSize.xl}
                />
              ) : undefined}
            </div>
          </CSSTransition>
        </div>
        <div className="u-white u-text-center">
          {isInputModalOpen && this.inputModal()}
        </div>
      </>
    );
  };
}

export const CryptoSignUp = connect(['sizing', 'auth'], () => ({}))(
  withRouter(CryptoSignUpComponent)
);
