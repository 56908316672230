import * as React from 'react';

import { connect } from 'unistore/react';

import { Icon } from '@/components/global/Icon/Icon';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { IconSize } from '@/enums/IconSize';

import { modalActions } from '@/store/modules/modal';
import { portalActions } from '@/store/modules/portal';

import { t } from '@/utils/i18n/i18n';
import { track } from '@/utils/mixpanel/mixpanel';
import { c } from '@/utils/strings/c';

interface Props
  extends SizingState,
    ModalActions,
    PortalActions,
    AuthState,
    SubscriptionState {}

interface State {
  step: number;
  selectedReasons: boolean[];
  isLoading: boolean;
}

class SubscriptionCancelConfirmModalComp extends React.Component<Props, State> {
  private leaveReasons: string[] = [
    'The workshops feel repetitive',
    'I want more personalized courses',
    "It's too expensive",
    'I want more advanced learning',
    "Workshop times didn't work for me",
    "I don't use Daisie enough",
  ];

  public state: State = {
    step: 0,
    selectedReasons: this.leaveReasons.map(() => false),
    isLoading: false,
  };

  private dismissModal = () => {
    const {
      sizing: { isMobile },
    } = this.props;

    if (isMobile) {
      this.props.updatePortal({ portal: undefined });
    } else {
      this.props.updateModal({ modal: undefined });
    }
  };

  private submitAnswersAndOpenStripe = () => {
    this.setState({ isLoading: true }, () => {
      try {
        const {
          auth: { user },
        } = this.props;

        if (!user) {
          throw new Error();
        }

        const { selectedReasons } = this.state;

        const selectedReasonsForMixpanel: string[] = this.leaveReasons
          .map((reason: string, index: number) =>
            selectedReasons[index] ? reason : undefined
          )
          .filter((s: string | undefined) => !!s) as string[];

        track('subscription_cancel_reasons', {
          userId: user.id,
          reasons: selectedReasonsForMixpanel,
        });

        const {
          subscription: { customerPortalUrl, userSubscription },
        } = this.props;

        if (!userSubscription) {
          throw new Error();
        }

        const { stripeSubscriptionId } = userSubscription;

        window.open(
          `${customerPortalUrl}/subscriptions/${stripeSubscriptionId}/cancel`
        );

        this.setState({ step: 1 });
      } catch (e) {
        // TODO
        this.setState({ isLoading: false });
      }
    });
  };

  public render = () => {
    const { step, selectedReasons, isLoading } = this.state;

    return (
      <div className="u-bg-black u-white">
        <div
          className="u-flex"
          style={{
            transform: `translateX(-${step * 100}%)`,
            transition: 'transform 250ms',
          }}
        >
          <div
            className={c(
              'u-flex-hold u-1/1 u-flex u-align-center u-justify-center animate-opacity',
              {
                'opacity-1': step === 0,
                'opacity-0': step !== 0,
              }
            )}
          >
            <div className="ph32@m">
              <p className="f-title-2 u-bold u-text-center mb12 mt32">
                {t('Cancel your subscription')}
              </p>

              <p className="f-lynstone-regular-3 u-text-center u-grey mb64">
                {t(
                  `Before you go, let us know why you're cancelling. Select as many as you like.`
                )}
              </p>

              {this.leaveReasons.map((reason: string, key: number) => {
                const isSelected = !!selectedReasons[key];

                return (
                  <Button
                    // eslint-disable-next-line react/no-array-index-key
                    key={key}
                    type={ButtonType.action}
                    onClick={() => {
                      const { selectedReasons } = this.state;

                      selectedReasons[key] = !isSelected;

                      this.setState({ selectedReasons });
                    }}
                    buttonStyle={
                      isSelected ? ButtonStyle.light : ButtonStyle.outlineLight
                    }
                    size={ButtonSize.l}
                    text={reason}
                    className="u-1/1 mb12"
                    iconId={isSelected ? 'tick' : undefined}
                    buttonTextClassName="relative"
                    iconClassName="absolute absolute--r r-12"
                    iconSize={IconSize.s}
                    disabled={isLoading}
                  />
                );
              })}

              <div className="u-flex u-flex-column u-align-center u-justify-center mt64 mb32">
                <Button
                  type={ButtonType.action}
                  onClick={() => this.submitAnswersAndOpenStripe()}
                  size={ButtonSize.m}
                  buttonStyle={ButtonStyle.veryLightTranslucentDanger}
                  text={t('Cancel subscription')}
                  className="mb16"
                  isLoading={isLoading}
                />

                <Button
                  type={ButtonType.action}
                  onClick={() => this.dismissModal()}
                  size={ButtonSize.s}
                  buttonStyle={ButtonStyle.veryLightTranslucent}
                  text={t('Keep subscription')}
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>

          <div
            className={c(
              'u-flex-hold u-1/1 u-flex u-align-center u-justify-center animate-opacity',
              {
                'opacity-1': step === 1,
                'opacity-0': step !== 1,
              }
            )}
          >
            <div className="u-flex u-flex-column u-align-center u-justify-center">
              <Icon id="tick" size={IconSize.xxl} className="mhauto" />

              <p className="f-lynstone-bold-2a u-text-center mb12 mt12">
                {t('Thanks for your feedback')}
              </p>

              <p className="f-text-2 u-text-center u-grey mb12">
                {t(`Stripe, our payment processor, has opened in a new tab.`)}
              </p>

              <button
                type="button"
                onClick={() => {
                  try {
                    const {
                      subscription: { customerPortalUrl, userSubscription },
                    } = this.props;

                    if (!userSubscription) {
                      throw new Error();
                    }

                    const { stripeSubscriptionId } = userSubscription;

                    window.open(
                      `${customerPortalUrl}/subscriptions/${stripeSubscriptionId}/cancel`
                    );
                  } catch (e) {
                    // TODO
                  }
                }}
                className="u-underline u-link-grey u-link-grey--alt mb64 f-text-2"
              >
                {t('Reopen Stripe tab')}
              </button>

              <Button
                type={ButtonType.action}
                onClick={() => this.dismissModal()}
                buttonStyle={ButtonStyle.default}
                size={ButtonSize.m}
                text={t('Done')}
                buttonTextClassName="ph32"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export const SubscriptionCancelConfirmModal = connect(
  ['sizing', 'auth', 'subscription'],
  () => ({
    ...modalActions(),
    ...portalActions(),
  })
)(SubscriptionCancelConfirmModalComp);
