import {
  URL_AVATAR_DEFAULTS_EXT,
  URL_AVATAR_DEFAULTS_ROOT,
} from '@/utils/constants';
import { letterToNumber } from '@/utils/strings/lerp';

export const getDefaultAvatarFromTitle = (title: string) =>
  `${URL_AVATAR_DEFAULTS_ROOT}${letterToNumber(
    title
  )}${URL_AVATAR_DEFAULTS_EXT}`;
