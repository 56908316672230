import * as React from 'react';

import { c } from '@/utils/strings/c';

interface Props {
  item: MasonryBlock;
  index?: number;
  className?: string;
}

class GenericMasonryBlock extends React.Component<Props> {
  public render = () => {
    const { children, item, index, className = '' } = this.props;

    const style: any = {
      width: `${item.percentage}%`,
      zIndex: index,
    };

    if (item.transform) {
      style.transform = item.transform;
    }

    return (
      <div
        className={c(['bpm', className])}
        key={item.data.id}
        style={style}
        id={item.data.id}
        data-block-width={item.percentage}
        data-block-size={item.data.displayProps.size || 1}
      >
        {children}
      </div>
    );
  };
}

export { GenericMasonryBlock };
