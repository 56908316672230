import * as React from 'react';

import { DISCORD_URL } from '@/utils/constants';
import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props {
  className?: string;
  copy: string;
  isAttendModal?: boolean;
}

const DiscordButton = ({ className, copy, isAttendModal }: Props) => (
  <button
    type="button"
    className={c(['dds-button mt20 mb6', className!], {
      'dds-button--discord dds-button--size-xl': !isAttendModal,
    })}
  >
    <a
      href={DISCORD_URL}
      target="_blank"
      rel="noreferrer"
      className={c('', {
        'u-light-grey': isAttendModal!,
      })}
    >
      {t(`${copy}`)}
    </a>
  </button>
);

export default DiscordButton;
