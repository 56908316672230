import React from 'react';
import { useEffect, useState } from 'react';

import {
  RouteComponentProps,
  useHistory,
  useParams,
  withRouter,
} from 'react-router-dom';
import { connect } from 'unistore/react';
import widont from 'widont';

import { Head } from '@/components/global/Head/Head';
import { Icon } from '@/components/global/Icon/Icon';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { Link } from '@/components/global/Link/Link';
import { LoadingSymbol } from '@/components/global/LoadingSymbol/LoadingSymbol';
import { Avatar } from '@/components/users/Avatar/Avatar';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';
import { HeroBlurOverlay } from '@/components/design_system/HeroBlurOverlay/HeroBlurOverlay';

import { GlobalModal } from '@/enums/GlobalModal';
import { GlobalPortal } from '@/enums/GlobalPortal';
import { IconSize } from '@/enums/IconSize';
import { OnDemandMediaType } from '@/enums/OnDemandMediaType';

import { modalActions } from '@/store/modules/modal';
import { portalActions } from '@/store/modules/portal';
import { workshopActions } from '@/store/modules/workshop';

import { CLASS_NOTION_LINKS } from '@/utils/constants';
import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';
import { secondsToTime } from '@/utils/strings/converters';
import {
  getFontClass,
  getWorkshopIdFromSlug,
} from '@/utils/workshop/workshopUtils';

import useVideoHistory from '@/hooks/useVideoHistory';
import useVideoProgress from '@/hooks/useVideoProgress';

interface Props
  extends WorkshopActions,
    WorkshopState,
    SizingState,
    AuthState,
    PortalActions,
    ModalActions,
    RouteComponentProps<MatchParams, {}, LocationState> {}

interface MatchParams {
  workshopSlug: string;
}

interface LessonGuide {
  resources: string;
  tools: string;
}

const WorkshopOverviewComponent = ({
  match,
  getCurrentWorkshop,
  workshop,
  updatePortal,
  updateModal,
  sizing,
}: Props) => {
  const [loading, setLoading] = useState(true);
  const [hoveredEpisode, setHoveredEpisode] = useState<OnDemandMedia>();
  const [isMoreLessonsClicked, setIsMoreLessonsClicked] = useState(false);

  const params = useParams();
  const history = useHistory();
  const { workshopSlug }: any = params;

  const [videoProgress, setVideoProgress] = useVideoProgress(workshopSlug, '');
  const [videoHistory, setLastVideoWatched] = useVideoHistory(workshopSlug);

  const fetchWorkshop = async () => {
    try {
      await getCurrentWorkshop(
        getWorkshopIdFromSlug(match.params.workshopSlug),
        undefined
      );
      setLoading(false);
    } catch (e) {}
    setLoading(false);
  };

  useEffect(() => {
    fetchWorkshop();
  }, []);

  const { currentWorkshop } = workshop;

  if (loading) {
    return (
      <div className="u-flex u-1/1 u-justify-center u-h-75vh u-align-center">
        <Head title={t('Class overview')} pathname={location.pathname} />
        <LoadingSymbol size="l" colour="white" />
      </div>
    );
  }

  if (!currentWorkshop) return null;

  const fontClass = getFontClass(currentWorkshop.attributes.fontFamily);

  const episodes: OnDemandMedia[] = currentWorkshop.onDemandMediaAll.filter(
    (om) => om.type === OnDemandMediaType.WorkshopOriginal
  );

  const handleShareClick = () => {
    if (sizing.isMobile) {
      updatePortal({
        portal: GlobalPortal.share_current_page,
      });
    } else {
      updateModal({
        modal: GlobalModal.share_current_page,
      });
    }
  };

  const handleHeaderPlayClick = () => {
    const latestWatchedVideoId =
      videoHistory[currentWorkshop?.id] || episodes?.[0]?.id;

    history.push(`/watch/${workshopSlug}/${latestWatchedVideoId}`);
  };

  const getOverallProgressPercentage = () => {
    const numOfEpisodes = episodes.length;
    let completedEpisodeCount = 0;

    if (!videoProgress[currentWorkshop.id]) return 0;
    Object.keys(videoProgress[currentWorkshop.id]).forEach((mediaId) => {
      const matchingEpisode = episodes.find((e) => e.id === mediaId);
      if (!matchingEpisode) return;

      if (
        // @ts-ignore
        videoProgress[currentWorkshop.id][mediaId] >= matchingEpisode.duration
      ) {
        completedEpisodeCount++;
      }
    });

    return Math.round((completedEpisodeCount / numOfEpisodes) * 100);
  };

  const getEpisodeProgressPercentage = (episode: any) => {
    const episodeDuration = episode.duration;
    const currentProgress = videoProgress[currentWorkshop.id][episode.id];

    if (!videoProgress[currentWorkshop.id]) return 0;

    return (currentProgress / episodeDuration) * 100;
  };

  const isEpisodeFullyWatched = (episode: any) => {
    getEpisodeProgressPercentage(episode);
    return getEpisodeProgressPercentage(episode) >= 99;
  };

  return (
    <div className="c-workshop-overview">
      <div
        className="c-workshop-cover relative u-bg-charcoal-grey u-flex u-justify-center u-align-center u-flex-column animate-opacity"
        style={{ height: sizing.isMobile ? '50vh' : '70vh' }}
      >
        <ImageLoader
          src={currentWorkshop.coverMedia}
          className="absolute fill u-object-cover"
          convertToWebp={true}
        />

        <p className="f-text-3 u-light-grey u-z-index-1 mb16@m mb8@s">
          <span className="u-uppercase">
            {t(`${currentWorkshop.eventType}`)}
          </span>
          <span className="ph6">{t('•')}</span>
          {t(`${episodes.length} Classes`)}
        </p>
        <h1
          className={c(
            `${fontClass} u-white relative u-z-index-1 u-text-center u-x-bold u-3/4@m u-1/2@l u-flex u-justify-center`,
            {
              'u-line-height--workshop-title-extended':
                fontClass === 'f-monument-extended-ultrabold-1' ||
                'f-obviously-extended-semibold-1a',
            }
          )}
        >
          {t(`${currentWorkshop.title}`)}
        </h1>
        <HeroBlurOverlay className="absolute absolute--t" />
      </div>

      <section className="wrap u-flex u-justify-between u-align-end u-flex-column@s mb44">
        <div className="pr32@m u-3/4@m">
          <Link
            className="u-flex u-align-center"
            to={`/${currentWorkshop.mentor.username}`}
          >
            <Avatar
              src={currentWorkshop.mentor.avatar}
              user={currentWorkshop.mentor}
              size="xl"
              className="mr16"
            />
            <div className="u-flex u-flex-column">
              <span className="f-text-3 u-grey mb4">{t('Instructor')}</span>
              <span className="u-white">{t(currentWorkshop.mentor.name)}</span>
            </div>
          </Link>
          <p className="f-text-3 u-very-light-grey mt20@m mv20@s u-2/3@l">
            {widont(t(`${currentWorkshop.description}`))}
          </p>
        </div>

        <div className="u-flex u-align-center u-1/1@s">
          {!sizing.isMobile && (
            <button
              type="button"
              className="u-grey f-text-2 u-flex u-align-center mr20"
              onClick={handleShareClick}
            >
              {t('Share')}
              <Icon className="ml6" id="share" size={IconSize.s} />
            </button>
          )}
          <Button
            type={ButtonType.action}
            onClick={handleHeaderPlayClick}
            buttonStyle={ButtonStyle.default}
            size={ButtonSize.xl}
            iconSize={IconSize.s}
            iconId="play"
            text={
              episodes?.length
                ? t(
                    getOverallProgressPercentage() === 0
                      ? 'Start Class'
                      : 'Resume'
                  )
                : t('Start Class')
            }
            iconClassName="ml8"
            buttonTextClassName="ph16"
            className="u-1/1@s"
            disabled={!episodes?.length}
          />
        </div>
      </section>

      {/* TODO: make this a separate component and add it to the quiz as well */}
      {/* <section className="wrap">
        <div className="c-line-progress-bar__overall relative">
          <div
            className="c-line-progress-bar__overall--inner absolute absolute--tl"
            style={{ width: `${getOverallProgressPercentage()}%` }}
          />
        </div>
      </section> */}

      {episodes?.length ? (
        <section className="wrap">
          <div className="relative u-flex">
            {/* sort episodes in order of completion */}
            {episodes.map((episode: any) => (
              <div
                key={episode.id}
                className="c-line-progress-bar__overall u-white mr8 u-border-radius relative"
                style={{
                  width: `${(episode.duration / episodes.length) * 10}%`,
                }}
              >
                <div
                  className="absolute c-line-progress-bar__overall--inner mr8"
                  style={{
                    width: `${getEpisodeProgressPercentage(episode)}%`,
                  }}
                />
              </div>
            ))}
          </div>
        </section>
      ) : null}

      {episodes?.length ? (
        <main className="wrap mt60@m mb100 mhauto">
          <h2 className="f-lynstone-bold-2a mb24 mb32@l u-very-light-grey u-4/5@l mhauto">
            Class Plan
          </h2>
          <ul className="u-4/5@l mhauto">
            {episodes
              .slice(0, isMoreLessonsClicked ? episodes.length : 5)
              .map((episode, index) => (
                <li key={episode.id}>
                  <section
                    className="u-flex@m u-align-center@m"
                    onMouseOver={() => setHoveredEpisode(episode)}
                    onMouseOut={() => setHoveredEpisode(undefined)}
                  >
                    <div
                      className={c(
                        'c-workshop-overview__thumbnail mr32@m mb20@s relative',
                        {
                          'c-originals-overview__episodes-watched':
                            isEpisodeFullyWatched(episode),
                        }
                      )}
                    >
                      <div
                        className={c(
                          'absolute absolute--tr c-workshop-overview__thumbnail--time u-white f-text-5 u-bold u-inline-block u-border-radius pv8 ph16 mt16 mr16',
                          {
                            'c-workshop-overview__thumbnail--time-watched':
                              isEpisodeFullyWatched(episode),
                          }
                        )}
                      >
                        <p className="u-flex u-align-center">
                          {isEpisodeFullyWatched(episode) && (
                            <Icon id="checkmark" size="xs" className="mr6" />
                          )}
                          {t(`${secondsToTime(episode.duration || 0, true)}`)}
                        </p>
                      </div>
                      <ImageLoader
                        className={c(
                          'u-object-cover u-border-radius--xs c-workshop-overview__thumbnail--img'
                        )}
                        alt={`episode-${index + 1}-thumbnail`}
                        src={episode.thumbnail || '/thumbnail-placeholder.png'}
                      />
                    </div>
                    <div
                      className="u-flex u-flex-column u-flex-row@m u-align-center@m u-justify-between@m"
                      style={{ width: '100%' }}
                    >
                      <Link
                        to={`/watch/${workshopSlug}/${episode.id}`}
                        className={c(
                          'mr60@l u-flex@m u-flex-column@m u-justify-center@m',
                          {
                            'c-originals-overview__episodes-watched':
                              isEpisodeFullyWatched(episode),
                          }
                        )}
                        style={{ width: !sizing?.isMobile ? '80%' : '100%' }}
                      >
                        <p className="u-grey f-text-5 u-bold mt20@s">
                          {/* episode.displayOrder tho */}
                          {t(`Class ${index + 1}`)}
                        </p>
                        <h5 className="u-very-light-grey f-text-1 u-bold mv8">
                          {t(`${episode.title}`)}
                        </h5>
                        <p className="u-light-grey f-inter-medium-3 f-text-3">
                          {t(`${episode.description}`)}
                        </p>
                      </Link>
                      <div
                        className="u-1/1@m u-1/3@l mt12@m mt12@s mt0@l u-flex u-justify-center u-flex-column"
                        style={{ minWidth: '192px' }}
                      >
                        <Link
                          to={`/watch/${workshopSlug}/${episode.id}`}
                          className="c-originals-player__play-btn ph24 pv16 u-1/1 u-very-light-grey f-text-3 mt6 u-flex u-align-center u-justify-center"
                        >
                          <p>
                            {t(
                              !!videoProgress[currentWorkshop.id][episode.id] &&
                                !isEpisodeFullyWatched(episode)
                                ? 'Resume'
                                : 'Play'
                            )}
                          </p>
                          <Icon id="play" className="ml6" width="16" />
                        </Link>
                        <Link
                          to={CLASS_NOTION_LINKS[index] || ''}
                          target="blank"
                          className="c-originals-player__guide-btn-overview ph24 pv16 u-1/1 u-very-light-grey f-text-3 mt12"
                        >
                          {t('Class Guide')}
                        </Link>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div className="c-line-progress-bar__episode relative u-overflow-hidden mv32">
                      <div
                        className="c-line-progress-bar__episode--inner absolute absolute--tl"
                        style={{
                          width: `${getEpisodeProgressPercentage(episode)}%`,
                          display:
                            !Number(getEpisodeProgressPercentage(episode)) ||
                            hoveredEpisode !== episode
                              ? 'none'
                              : 'block',
                        }}
                      />
                    </div>
                  </section>
                </li>
              ))}
          </ul>

          {!isMoreLessonsClicked && episodes.length > 5 && (
            <div
              className="u-line-behind u-line-behind--black u-grey mv32 u-cursor-pointer"
              onClick={() => setIsMoreLessonsClicked(true)}
            >
              <span className="u-border-radius f-text-3 b--sad-grey u-white">
                {t('More classes')}
              </span>
            </div>
          )}
        </main>
      ) : null}
    </div>
  );
};

export const WorkshopOverview = connect(
  ['workshop', 'sizing', 'auth'],
  (store: GlobalStoreState) => ({
    ...workshopActions(store),
    ...portalActions(),
    ...modalActions(),
  })
)(withRouter(WorkshopOverviewComponent));
