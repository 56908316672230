import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'unistore/react';

import { Carousel } from '@/components/global/Carousel/Carousel';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { Link } from '@/components/global/Link/Link';
import { SkeletonNew } from '@/components/global/Skeleton/SkeletonNew';

import { parser } from '@/routes/parser';

import { getCategoryIcon } from '@/utils/category/category';
import { CAROUSEL_MARGIN_DEFAULT } from '@/utils/constants';
import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props extends SizingState, RouteComponentProps {
  theme: 'dark' | 'light';
  workshops?: Workshop[];
  isLoaded: boolean;
  className?: string;
  trackOnClick?: string;
  showMoreButton?: {
    show: boolean;
    category: Category;
  };
  thinCards?: boolean;
  hideArrows?: boolean;
  customCarouselProps?: {
    wrap?: boolean;
    buttonClassName?: string;
    buttonClassNameDisabled?: boolean;
    showFade?: boolean;
    itemClassName?: string;
    scrollToEnd?: boolean;
    expectedItems?: number;
    equalHeight?: boolean;
    columns?: number;
  };
  isWorkshopPage?: boolean;
}

class WorkshopCarouselNewComponent extends React.Component<Props> {
  private getDefaultCarouselColumns = (thinCards?: boolean) => {
    const {
      sizing: { isMobile, isDesktop },
      customCarouselProps,
      location: { pathname },
    } = this.props;

    if (customCarouselProps && customCarouselProps.columns) {
      return customCarouselProps.columns;
    }

    // gave first aid here but will need to investigate further
    return isMobile && pathname.includes('/community')
      ? 5
      : isMobile
      ? 1
      : thinCards && isDesktop
      ? 4
      : 2;
  };

  private getDefaultCarouselPadding = () => {
    const {
      sizing: { isMobile, isTablet },
    } = this.props;

    return isMobile ? 12 : isTablet ? 24 : 30;
  };

  private renderItems = () => {
    const {
      workshops,
      showMoreButton: showMore,
      theme,
      isWorkshopPage,
      sizing: { isMobile },
    } = this.props;

    if (!workshops) return null;

    const workshopCards = workshops.map((w: Workshop) => (
      <Link
        key={w.id}
        to={parser({ name: 'workshop', params: { workshopSlug: w.slug } })}
        className="u-1/1"
      >
        <div className="mb16 u-border-radius u-border-radius--s u-overflow-hidden u-h-0 pb16by9">
          <ImageLoader
            src={w.coverMedia}
            className="u-1/1 u-object-cover"
            // this is to fill the container when the height of image is extremely thin
            style={{
              height: isMobile ? '14rem' : isWorkshopPage ? '30rem' : '20rem',
            }}
            convertToWebp={true}
          />
        </div>

        <div className="u-flex mb16">
          <div className="u-flex-hold mr12">
            <ImageLoader
              src={w.mentor.avatar}
              className="u-rounded c-section-workshops__workshop_carousel__card__avatar"
              convertToWebp={true}
            />
          </div>

          <div className="u-flex-1 u-flex u-flex-column u-justify-center">
            <p
              className={c('f-text-3', {
                'u-light-grey': theme === 'dark',
                'u-dark-grey': theme === 'light',
              })}
            >
              {t('Hosted by')}
            </p>
            <p className="f-text-3 u-bold">{w.mentor.name}</p>
          </div>
        </div>

        <p className="f-text-2 u-bold mb8 u-truncate u-truncate--1l u-overflow-hidden">
          {w.title}
        </p>
        <p
          className={c(
            'f-text-3 u-truncate u-truncate--2l u-overflow-hidden c-section-workshops__workshop_carousel__card__description',
            {
              'u-grey': theme === 'dark',
              'u-dark-grey': theme === 'light',
            }
          )}
        >
          {w.description}
        </p>
      </Link>
    ));

    const showMoreButton =
      showMore && showMore.show
        ? () => {
            const { category } = showMore;
            const { slug, name } = category;

            return (
              <Link
                to={`${parser({
                  name: 'categoryIndex',
                  params: {
                    slug,
                  },
                })}?view=workshops`}
                className="c-workshop-card u-bg-white u-1/1 u-flex u-flex-column u-align-center u-justify-center u-link-black p32 u-text-center"
              >
                {getCategoryIcon({ category, className: 'mb8' })}
                <p className="f-text-3">
                  {t('See more')} {name.toLowerCase()} {t('workshops')}
                </p>
              </Link>
            );
          }
        : undefined;

    return showMore && showMore.show && showMoreButton
      ? [...workshopCards, showMoreButton()]
      : workshopCards;
  };

  public render = () => {
    const {
      workshops,
      className,
      isLoaded,
      hideArrows,
      thinCards,
      customCarouselProps,
      theme,
    } = this.props;

    return (
      <Carousel
        columns={this.getDefaultCarouselColumns(thinCards)}
        padding={this.getDefaultCarouselPadding()}
        wrap={true}
        className={className || CAROUSEL_MARGIN_DEFAULT}
        buttonClassName="carousel__button--theme-light u-link-black--alt u-hide u-flex@l"
        counterDotsClassName="u-hide"
        disableSnap={true}
        showFade={true}
        isLoaded={isLoaded}
        hideArrows={hideArrows}
        itemClassName="u-flex u-height-auto"
        {...(customCarouselProps ? customCarouselProps : {})}
      >
        {workshops && isLoaded
          ? this.renderItems()
          : Array.from(Array(this.getDefaultCarouselColumns() + 1).keys()).map(
              (_, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className="u-1/1">
                  <div
                    className={c(
                      'mb16 u-border-radius u-border-radius--s u-overflow-hidden u-h-0 pb16by9 u-1/1',
                      {
                        'u-bg-skeleton': theme === 'light',
                        'u-bg-charcoal-grey': theme === 'dark',
                      }
                    )}
                  />

                  <div className="u-flex mb16">
                    <div className="u-flex-hold mr12">
                      <div
                        className={c(
                          'u-rounded c-section-workshops__workshop_carousel__card__avatar',
                          {
                            'u-bg-skeleton': theme === 'light',
                            'u-bg-charcoal-grey': theme === 'dark',
                          }
                        )}
                      />
                    </div>

                    <div className="u-flex-1 u-flex u-flex-column u-justify-center">
                      <SkeletonNew
                        elementClassName="f-text-3"
                        fitToText="Hosted by"
                        fillClassName={c('mb4', {
                          'u-bg-skeleton': theme === 'light',
                          'u-bg-charcoal-grey': theme === 'dark',
                        })}
                      />
                      <SkeletonNew
                        elementClassName="f-text-3 u-bold"
                        fitToText="Ian Hutchinson"
                        fillClassName={c('', {
                          'u-bg-skeleton': theme === 'light',
                          'u-bg-charcoal-grey': theme === 'dark',
                        })}
                      />
                    </div>
                  </div>

                  <SkeletonNew
                    elementClassName="f-text-2 u-bold u-truncate u-truncate--1l u-overflow-hidden"
                    fitToText="Workshop Title"
                    fillClassName={c('', {
                      'u-bg-skeleton': theme === 'light',
                      'u-bg-charcoal-grey': theme === 'dark',
                    })}
                  />

                  <SkeletonNew
                    elementClassName="f-text-3"
                    fillClassName={c('', {
                      'u-bg-skeleton': theme === 'light',
                      'u-bg-charcoal-grey': theme === 'dark',
                    })}
                  />
                </div>
              )
            )}
      </Carousel>
    );
  };
}

export const WorkshopCarouselNew = connect(['sizing'], () => ({}))(
  withRouter(WorkshopCarouselNewComponent)
);
