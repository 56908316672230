import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'unistore/react';

import { Dropdown } from '@/components/global/Dropdown/Dropdown';
import { Icon } from '@/components/global/Icon/Icon';
import { Link } from '@/components/global/Link/Link';
import { NavigationDropdown } from '@/components/global/Navigation/NavigationDropdown';
import { NavLink } from '@/components/global/NavLink/NavLink';
import { Wordmark } from '@/components/global/Wordmark/Wordmark';
import { AB_TEST_DISCOUNT_PERCENT_OFF_DISPLAY } from '@/components/subscription/constants';
import { Avatar } from '@/components/users/Avatar/Avatar';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { DaisieWordmarkColour } from '@/enums/DaisieWordmarkColour';
import { NavigationStyle } from '@/enums/NavigationStyle';
import { TrackingLocation } from '@/enums/TrackingLocation';

import { Notifications } from '@/pages/Notifications/Notifications';

import { routes } from '@/routes';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

import { isALink } from '@/utils/DOM/dom-testing';
import { t } from '@/utils/i18n/i18n';
import { track } from '@/utils/mixpanel/mixpanel';
import { c } from '@/utils/strings/c';
import { getRouteFromUrl } from '@/utils/urls/routes';

interface Props
  extends AuthState,
    SizingState,
    RoomState,
    NotificationsState,
    ABTestState,
    SubscriptionState,
    RouteComponentProps {
  mobileClassName: string;
  page?: string;
  pathname?: string;
  showLogo?: boolean;
  isDownloadAppBannerDismissed?: boolean;
  isDiscordBannerDismissed?: boolean;
  close: () => void;
}

interface State {
  mobileNavOpen: boolean;
  isNotificationsOpen: boolean;
  isDarkMode: boolean;
  style: NavigationStyle;
}

class NavigationMobileComponent extends React.Component<Props, State> {
  public state: State = {
    mobileNavOpen: false,
    isNotificationsOpen: false,
    isDarkMode: true,
    style: NavigationStyle.default,
  };

  public componentDidMount = () => {
    window.addEventListener('popstate', this.escapeListener);
  };

  // eslint-disable-next-line react/no-deprecated
  public componentWillUpdate = (prevProps: Props) => {
    const { page } = this.props;
    const { page: prevPage } = prevProps;

    if (page !== prevPage) {
      return true;
    }

    return false;
  };

  public componentDidUpdate = (prevProps: Props) => {
    const { page } = this.props;
    const { page: prevPage } = prevProps;

    if (page !== prevPage) {
      return true;
    }

    return false;
  };

  private toggleBurgerMenu = () => {
    this.setState(
      ({ mobileNavOpen }) => ({
        mobileNavOpen: !mobileNavOpen,
      }),
      () => {
        const { mobileNavOpen } = this.state;

        if (mobileNavOpen) {
          this.setState({ style: NavigationStyle.default });
        }

        const isDownloadAppBannerDismissed = localStorage.getItem(
          'dismissed_download_app_banner'
        );

        const isDiscordBannerDismissed = localStorage.getItem(
          'dismissed_discord_banner'
        );

        if (this.state.mobileNavOpen) {
          document.body.classList.add('prevent-scroll-mobile');

          const navOverlay = document.querySelector(
            '.nav-overlay'
          ) as HTMLDivElement;

          if (navOverlay) {
            navOverlay.style.cssText = '';
          }
        } else {
          document.body.classList.remove('prevent-scroll-mobile');

          const homeNav = document.querySelector('header[role="banner"]');

          let top = 0;

          if (!isDiscordBannerDismissed || !isDownloadAppBannerDismissed) {
            const pageLayout = document.querySelector('.page-layout');

            if (pageLayout) {
              top = pageLayout.scrollTop;
            }
          } else {
            top = window.pageYOffset || document.documentElement.scrollTop;
          }

          if (homeNav && top > 50) {
            homeNav.classList.add('visible-no-transition');
          }
        }
      }
    );
  };

  private toggleNotifications = () => {
    this.setState(
      ({ isNotificationsOpen }) => ({
        isNotificationsOpen: !isNotificationsOpen,
      }),
      () => {
        if (this.state.isNotificationsOpen) {
          document.body.classList.add('prevent-scroll-mobile');
        } else {
          document.body.classList.remove('prevent-scroll-mobile');
        }
      }
    );
  };

  private escapeListener = () => {
    if (this.state.mobileNavOpen) {
      this.setState({ mobileNavOpen: false });
    }
  };

  private closeMobileNav = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!event || !event.target) return;

    if (isALink(event.target as HTMLElement)) {
      this.setState({ mobileNavOpen: false });
      document.body.classList.remove('prevent-scroll-mobile');
    }
  };

  private wordmark = () => {
    const { page } = this.props;

    const { mobileNavOpen, style } = this.state;

    return (
      <NavLink
        exact={true}
        to="/"
        className="wordmark-container u-flex-hold pr16@m pv8 pv16@m u-flex"
        onClick={() => {
          if (page === 'home') {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          } else {
            track('go_home', {
              from: TrackingLocation.daisieLogo,
            });
          }
        }}
      >
        <Wordmark
          colour={
            mobileNavOpen ||
            style === NavigationStyle.default ||
            style === NavigationStyle.dark
              ? DaisieWordmarkColour.White
              : DaisieWordmarkColour.Black
          }
          height="20px"
        />
      </NavLink>
    );
  };

  private avatarDropdown = (user: User) => {
    const { notifications } = this.props;
    const { isNotificationsOpen, isDarkMode } = this.state;
    const unreadNotificationCount = notifications.unreadNotificationsCount;

    return (
      <>
        <Dropdown
          side="right"
          width="small"
          darkMode={true}
          id="navigation-dropdown u-bg-black--i"
          trigger={({
            openHover,
          }: {
            open: boolean;
            openHover: () => void;
          }) => (
            <div className="relative u-block" onClick={openHover}>
              <Avatar
                size="large"
                alt={user.primaryName}
                src={user.avatar}
                user={user}
              />
            </div>
          )}
          children={({ close }: { close: () => void }) => (
            <div>
              <ul className="u-bold mb20 u-link-black f-text-1 u-z-index-1">
                <li className="u-white bb--sad-grey">
                  <Link
                    className="u-inline-block pt24 pb20 ph24 u-text-left u-1/1"
                    to={`/${user.username}`}
                    onClick={() => {
                      close();
                    }}
                  >
                    {t('Profile')}
                  </Link>
                </li>

                <li className="u-white bb--sad-grey u-flex u-justify-between u-align-center">
                  <button
                    className="pv20 ph24 u-text-left u-1/1"
                    type="button"
                    onClick={() => {
                      this.toggleNotifications();
                      close();
                    }}
                  >
                    {t(`Notifications`)}
                  </button>
                  <span
                    className={c('u-flex-hold mr24', {
                      'counter-dot counter-dot--brand': unreadNotificationCount,
                    })}
                  />
                </li>

                <li className="u-white bb--sad-grey u-flex u-justify-between u-align-center">
                  <Link
                    to={routes.account}
                    className="pv20 ph24 u-text-left u-1/1"
                    onClick={() => {
                      close();
                    }}
                  >
                    {t('Settings')}
                  </Link>
                </li>

                <li className="u-white pt16 pb2 pl24">
                  <Link
                    to={routes.logout}
                    className="u-link-white f-text-3 u-flex u-align-center"
                  >
                    {t('Log Out')}
                  </Link>
                </li>
              </ul>
            </div>
          )}
        />

        <div
          className={c('nav-overlay mobile-only', {
            'nav-overlay--open nav-overlay--avatar': !!isNotificationsOpen,
          })}
        >
          <header
            role="banner"
            className="u-bg-black--i f-title-3 u-flex u-justify-item-centre u-align-center u-justify-between ph16 pv16 u-fixed u-white u-bold"
          >
            <span>&nbsp;</span>
            <div className="absolute absolute--x-center">
              {t('Notifications')}
            </div>
            <Button
              type={ButtonType.action}
              size={ButtonSize.s}
              iconId="clear"
              buttonStyle={ButtonStyle.transparent}
              onClick={this.toggleNotifications}
            />
          </header>
          <Notifications
            onClickToggle={this.toggleNotifications}
            isDarkMode={isDarkMode}
          />
        </div>
      </>
    );
  };

  private burgerMenu = () => {
    const { page } = this.props;
    const matchedRoute = getRouteFromUrl(this.props.location.pathname);

    return (
      <>
        <button
          type="button"
          className={c('mobile-only pv8', {
            'navicon--active': !!this.state.mobileNavOpen,
            ...this.getNavigationButtonStyles(),
          })}
          aria-label="Open Menu"
          onClick={() => {
            this.toggleBurgerMenu();
          }}
          data-ui-test-id={isUiTest() ? 'mobile-nav-open' : undefined}
        >
          <div className="navicon">
            <i />
            <i />
            <i />
          </div>
        </button>

        <div
          className={c('nav-overlay nav-overlay--padding mobile-only', {
            'nav-overlay--open': !!this.state.mobileNavOpen,
            'nav-overlay--padding-discord-banner':
              !this.props.isDiscordBannerDismissed,
            'nav-overlay--padding-download-banner':
              !this.props.isDownloadAppBannerDismissed &&
              matchedRoute === 'home',
          })}
        >
          <NavigationDropdown
            page={page}
            mobileNavOpen={this.state.mobileNavOpen}
            toggleBurgerMenu={this.toggleBurgerMenu}
          />
        </div>
      </>
    );
  };

  private getNavigationButtonStyles = () => {
    const { style } = this.state;

    return {
      'u-link-grey':
        style === NavigationStyle.default || style === NavigationStyle.dark,
      'u-link-white u-link-white--alt':
        style === NavigationStyle.transparentLight,
      'u-link-black--alt': style === NavigationStyle.transparentDark,
    };
  };

  public render = () => {
    const {
      auth,
      page,
      abtest: { variant },
    } = this.props;

    const { user, isAuthorised } = auth;
    const noNavOnPages = ['onboarding'];
    const hideNav = page && noNavOnPages.includes(page);

    if (hideNav) return null;

    const showNavFixedJoinButton: boolean =
      !isAuthorised &&
      (page === 'home' ||
        page === 'categoryIndex' ||
        page === 'students' ||
        page === 'workshop');

    const navFixedJoinButtonLinkTo: string =
      page === 'students'
        ? routes.studentsSignUpYearly
        : typeof variant !== 'undefined' && variant === 2
        ? routes.optimisedJoinFlowSignUpYearly
        : routes.optimisedJoinFlowChoosePlan;

    const navFixedJoinButtonText: string =
      page === 'workshop'
        ? variant === 1
          ? t('Get started')
          : t('Join for free')
        : page === 'students'
        ? t('Try for free')
        : typeof variant !== 'undefined' && variant === 2
        ? t(`${AB_TEST_DISCOUNT_PERCENT_OFF_DISPLAY}% off`)
        : t('Try for free');

    return (
      <nav
        role="navigation"
        className="wrap u-1/1 u-h-100 u-flex u-justify-between u-align-center"
        onClick={!!this.state.mobileNavOpen ? this.closeMobileNav : undefined}
      >
        {this.burgerMenu()}

        <div
          className={c('absolute', {
            'absolute--x-center': !showNavFixedJoinButton,
            'ml32 pl6': !!showNavFixedJoinButton,
          })}
        >
          {this.wordmark()}
        </div>

        {showNavFixedJoinButton && (
          <div className="u-white absolute absolute--tr">
            <Button
              type={ButtonType.link}
              linkTo={navFixedJoinButtonLinkTo}
              text={navFixedJoinButtonText}
              buttonStyle={ButtonStyle.default}
              size={ButtonSize.s}
              className="mr16 mt12"
            />
          </div>
        )}

        <div>{user && isAuthorised ? this.avatarDropdown(user) : null}</div>
      </nav>
    );
  };
}

export const NavigationMobile = connect(
  ['auth', 'sizing', 'room', 'notifications', 'abtest', 'subscription'],
  () => ({})
)(withRouter(NavigationMobileComponent));
