import * as React from 'react';

import { FeedbackButton } from '@/components/global/FeedbackButton/FeedbackButton';
import { Head } from '@/components/global/Head/Head';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';
import { ButtonGroup } from '@/components/design_system/ButtonGroup/ButtonGroup';

import { routes } from '@/routes';

import { t } from '@/utils/i18n/i18n';

const PageNotFound = () => {
  const title = t('Oopsie daisie');

  return (
    <div className="page-content--centered wrap wrap--m u-white u-text-center">
      <Head title={title} />
      <div>
        <h1 className="f-yell-1a mb20">{title}</h1>
        <div className="f-lynstone-regular-3 u-light-grey mb32">
          <p className="mb6">{t('This page was not found.')}</p>
          <p>{t('If something is wrong, please send us a message.')}</p>
        </div>
        <ButtonGroup className="u-justify-center u-flex-column@s u-align-center@s">
          <FeedbackButton
            // eslint-disable-next-line react/no-unused-prop-types
            trigger={({ onClick }: { onClick: () => void }) => (
              <Button
                type={ButtonType.action}
                text={t('Message Daisie')}
                onClick={onClick}
                buttonStyle={ButtonStyle.veryLightTranslucent}
                className="mb16@s mr0@s-i u-1/1@s"
                buttonTextClassName="p4"
              />
            )}
          />
          <Button
            type={ButtonType.link}
            linkTo={routes.home}
            text={t('Back to homepage')}
            buttonStyle={ButtonStyle.outlineDark}
            className="u-1/1@s"
            buttonTextClassName="p4"
          />
        </ButtonGroup>
      </div>
    </div>

    // </div>
  );
};

export { PageNotFound };
