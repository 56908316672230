import delve from 'dlv';

import {
  getFeaturedWorkshopProjectOrUserFromData,
  getProjectsAndDiscussionsFromData,
  getUsersFromData,
  getWorkshopsFromData,
} from '@/store/helpers';
import { getRoomsFromData } from '@/store/helpers/roomHelpers';

import { Http } from '@/utils/api/Http';

export const fetchEditorialConfig = async (configType: string) => {
  const { data: allEditorialConfigs } = await new Http('/editorialConfigs').get<
    APIArray<APIEditorialConfig>
  >();

  if (!allEditorialConfigs) {
    throw new Error('No editorial configs found');
  }

  const specificEditorialConfig = allEditorialConfigs.find(
    (editorialConfig: APIEditorialConfig) =>
      editorialConfig.attributes.type === configType
  );

  if (!specificEditorialConfig) {
    throw new Error(
      `No editorial config for ${configType} found within the configs list`
    );
  }

  const { data: config } = await new Http(
    `/editorialConfigs/${specificEditorialConfig.id}`
  ).get<APIObject<APIEditorialConfig>>();

  if (!config) {
    throw new Error(`Editorial config for ${configType} not found`);
  }

  return config;
};

export const getEditorialConfig = async ({
  configType,
  category,
  getFeaturedProject,
  getAnnotations,
  curatedImages,
}: {
  configType: string;
  category?: Category;
  getFeaturedProject?: boolean;
  getAnnotations?: boolean;
  curatedImages?: CuratedImage[];
}) => {
  const config = await fetchEditorialConfig(configType);

  if (category) {
    const listType = `top-${category.slug}`;

    const specificList = config.relationships.lists.data.find(
      (editorialProjectList: APIRoomsList) =>
        editorialProjectList.attributes.type === listType
    );

    if (!specificList) {
      throw new Error(
        `Editorial list ${listType} not found in config ${configType}`
      );
    }

    const { data: list } = await new Http(
      `/editorialProjectLists/${specificList.id}`
    ).get<APIObject<APIRoomsList>>();

    let processedData: any = {};

    if (list.relationships.projects.data.length > 1 && !getFeaturedProject) {
      processedData.id = list.id;
      processedData.title = list.attributes.title;
      processedData.description = list.attributes.description;
      processedData.max = list.attributes.max;
      processedData.projects = getRoomsFromData(
        list.relationships.projects.data,
        curatedImages
      );
    } else {
      processedData = getFeaturedWorkshopProjectOrUserFromData(
        list,
        curatedImages
      );
    }

    return {
      [specificList.attributes.type]: processedData,
    };
  }

  const promiseArray: Array<Promise<APIObject<APIRoomsList>>> = [];

  config.relationships.lists.data.map(
    async (editorialProjectList: APIRoomsList) => {
      promiseArray.push(
        new Http(
          `/editorialProjectLists/${editorialProjectList.id}${
            getAnnotations ? '?getAnnotations=true' : ''
          }`
        ).get<APIObject<APIRoomsList>>()
      );
    }
  );

  const lists = await Promise.all(promiseArray);

  const result: { [key: string]: Project[] | FeaturedItem } = {};

  lists.map(({ data: list }) => {
    let processedData: any = {};

    if (
      list.relationships.projects.data.length > 1 ||
      list.relationships.users.data.length > 1 ||
      list.relationships.workshops.data.length > 1
    ) {
      processedData.id = list.id;
      processedData.title = list.attributes.title;
      processedData.description = list.attributes.description;
      processedData.max = list.attributes.max;
      processedData.type = list.attributes.type;
      processedData.projects = getProjectsAndDiscussionsFromData(
        list.relationships.projects.data,
        curatedImages
      );
      processedData.users = getUsersFromData(
        delve(list, 'relationships.users.data')
      );
      processedData.workshops = getWorkshopsFromData(
        delve(list, 'relationships.workshops.data')
      );
    } else {
      processedData = getFeaturedWorkshopProjectOrUserFromData(
        list,
        curatedImages
      );
    }

    result[list.attributes.type] = processedData;
  });

  return result;
};
