export const BLOCK_USER_SUCCESS = `Successfully blocked person.`;
export const CHANGES_SAVED = `Changes saved!`;
export const COPIED_TO_CLIPBOARD_PROFILE_URL =
  'Profile link copied to clipboard';
export const PASSWORD_UPDATE_SUCCESS = 'Password updated successfully';
export const PROJECT_DELETION_SUCCESS = 'Room deleted!';
export const ACCOUNT_DELETION_SUCCESS = 'Account deleted successfully';
export const PROFILE_PICTURE_SAVE_SUCCESS = 'Profile picture saved!';
export const PROFILE_VISIBILITY_SUCCESS = 'Profile privacy changed!';
export const PASSWORD_RESET_SUCCESS = `We\'ve sent you an email with a link to reset your password`;
export const REPORT_PROJECT_SUCCESS = `Successfully reported room.`;
export const REPORT_USER_SUCCESS = `Successfully reported person.`;
export const UNBLOCK_USER_SUCCESS = 'Successfully unblocked person.';
export const PASSWORD_RESET_COMPLETE =
  'You have successfully reset your password.';
export const USER_UNFOLLOWED = 'User unfollowed';

export const ROOM_PUBLIC_EXPLAINER =
  'Anyone can see your room and join the conversation';
export const ROOM_PRIVATE_EXPLAINER = 'Only people with the room link can join';
export const ROOM_USER_BANNED: string = 'User removed and banned';
export const ROOM_USER_KICKED: string = 'User removed';
export const ROOM_USER_UNBANNED_SUCCESS: string = 'User succesfully unbanned';
export const ROOM_NEW_NAVIGATE_TO_CONFIRM: string = `You'll need to leave this room before you create a new one. Do you want to continue?`;
