import * as React from 'react';

import { connect } from 'unistore/react';
import widont from 'widont';

import { WorkshopCarouselNew } from '@/components/marketing/HomeCategoryPageSections/SectionWorkshops/WorkshopCarouselNew';

import { getWorkshopsFromData } from '@/store/helpers';

import { Http } from '@/utils/api/Http';
import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props extends SizingState, SubscriptionState {
  category?: Category;
  theme: 'light' | 'dark';
  community: Category;
  communityName: string;
  ctaButton: React.ReactNode;
  showPastWorkshopsIfNoUpcoming?: boolean;
  getProfessionWord: () => void;
}

interface State {
  workshops: Workshop[];
  isWorkshopsLoaded: boolean;
}

class SectionWorkshopsComponent extends React.Component<Props, State> {
  public state: State = {
    workshops: [],
    isWorkshopsLoaded: false,
  };

  public componentDidMount = () => {
    this.fetchWorkshops();
  };

  public componentDidUpdate = (prevProps: Props) => {
    const { category: prevCategory } = prevProps;
    const { category } = this.props;

    if (prevCategory && category && prevCategory.id !== category.id) {
      this.fetchWorkshops();
    }
  };

  private fetchWorkshops = async () => {
    const { category } = this.props;

    this.setState({ isWorkshopsLoaded: false });

    try {
      const { data } = await new Http(
        `/workshops?pageSize=10&pageNumber=1&sortBy=workshopStartDate${
          !!category ? `&categories=${encodeURIComponent(category.name)}` : ''
        }&filters=isUpcoming`
      ).get<APIObject<APIWorkshop[]>>();

      const upcomingWorkshops = getWorkshopsFromData(data).reverse();

      if (upcomingWorkshops.length === 0) {
        // Get past workshops if no upcoming workshops exist

        const { data: allWorkshopsData } = await new Http(
          `/workshops?pageSize=10&pageNumber=1&sortBy=workshopStartDate${
            !!category ? `&categories=${encodeURIComponent(category.name)}` : ''
          }`
        ).get<APIObject<APIWorkshop[]>>();

        this.setState({
          workshops: getWorkshopsFromData(allWorkshopsData),
          isWorkshopsLoaded: true,
        });
      } else {
        this.setState({
          workshops: upcomingWorkshops,
          isWorkshopsLoaded: true,
        });
      }
    } catch (e) {}
  };

  public render = () => {
    const {
      sizing: { isDesktop, isTablet },
      theme,
      ctaButton,
      category,
    } = this.props;

    const { workshops, isWorkshopsLoaded } = this.state;

    return (
      <div>
        <section className="wrap mb80 mv200@m u-flex u-flex-column@s">
          <div className="c-section-workshops__workshop_carousel__copy u-1/1 u-1/2@m ml16@m mlauto@l">
            <div className="c-section-workshops__workshop_carousel__copy__content">
              <h2 className="f-lynstone-bold-1a mb16 mb24@m">
                {category?.slug
                  ? t(`A global ${category?.slug} community`)
                  : widont(t('Hands on & interactive'))}
              </h2>
              <p className="f-lynstone-book-2a mr64@m">
                {widont(
                  t(
                    category?.slug
                      ? `Join classes from anywhere in the world alongside a community of ${this.props.getProfessionWord()}.`
                      : 'Ask questions and get feedback from creators around the world.'
                  )
                )}
              </p>

              {ctaButton}
            </div>

            <div
              className={c(
                'c-section-workshops__workshop_carousel__copy__mask',
                {
                  'c-section-workshops__workshop_carousel__copy__mask--dark':
                    theme === 'dark',
                  'c-section-workshops__workshop_carousel__copy__mask--light':
                    theme === 'light',
                }
              )}
            />
          </div>

          <div className="c-section-workshops__workshop_carousel u-1/1 u-1/2@m mt44@s mr16@m mr0@l u-flex u-flex-column u-justify-center">
            <WorkshopCarouselNew
              theme={theme}
              workshops={workshops}
              isLoaded={isWorkshopsLoaded}
              className="mb0"
              customCarouselProps={
                isDesktop
                  ? {
                      wrap: false,
                      buttonClassName:
                        'carousel__button--theme-xsmall u-link-black--alt u-hide u-flex@l',
                      buttonClassNameDisabled: 'u-very-translucent',
                      showFade: false,
                      itemClassName: 'u-flex',
                      expectedItems: 10,
                      equalHeight: true,
                    }
                  : isTablet
                  ? {
                      columns: 1,
                      wrap: false,
                      buttonClassName: 'u-hide',
                      showFade: false,
                      itemClassName: 'u-flex',
                      equalHeight: true,
                    }
                  : undefined
              }
            />
          </div>
        </section>
      </div>
    );
  };
}

export const SectionWorkshops = connect(['sizing', 'subscription'], () => ({}))(
  SectionWorkshopsComponent
);
