import * as React from 'react';

import delve from 'dlv';
import { connect } from 'unistore/react';

import { EmptyState } from '@/components/global/EmptyState/EmptyState';
import { Head } from '@/components/global/Head/Head';
import { Icon } from '@/components/global/Icon/Icon';
import { LoadingSymbol } from '@/components/global/LoadingSymbol/LoadingSymbol';
import { Paginator } from '@/components/global/Paginator/Paginator';
import { Toast } from '@/components/global/Toaster/Toast';
import { UserCardboards } from '@/components/users/UserCards/UserCardboards';

import { BackButton } from '@/components/design_system/BackButton/BackButton';

import { IconSize } from '@/enums/IconSize';
import { PaginatorPosition } from '@/enums/PaginatorPosition';

import { UNFOLLOW_USER_FAIL } from '@/messages/errors';
import { USER_UNFOLLOWED } from '@/messages/messages';

import { routes } from '@/routes';

import { authActions } from '@/store/modules/auth';
import { userActions } from '@/store/modules/user';

import { Http } from '@/utils/api/Http';
import { t } from '@/utils/i18n/i18n';
import { track } from '@/utils/mixpanel/mixpanel';

interface Props extends UserState, UserActions, AuthState, AuthActions {}

interface State {
  pageNumber: number;
  following: User[];
  loading: boolean;
  initialLoaded: boolean;
  unfollowedUsers: string[];
}

class FollowingComponent extends React.Component<Props, State> {
  public state: State = {
    pageNumber: 1,
    following: [],
    loading: false,
    initialLoaded: false,
    unfollowedUsers: [],
  };

  public componentDidMount = async () => {
    const {
      auth: { user },
    } = this.props;

    if (!user) return;

    await this.props.getUserAttributesByUsername(user.username);
    await this.fetchFollowingData();
  };

  public componentWillUnmount = () => {
    this.props.clearUserFollowing();
  };

  private fetchFollowingData = async () => {
    const { getUserFollowingByUsername, user } = this.props;
    const { loading } = this.state;

    if (loading || !user) return;

    let pageNumber = this.state.pageNumber;

    const followingForPageNumber = user.following
      ? user.following[pageNumber]
      : null;

    if (followingForPageNumber && followingForPageNumber.length) {
      pageNumber = pageNumber + 1;
    }

    this.setState({ loading: true });

    await getUserFollowingByUsername(user.username, pageNumber);
    this.processUsers();

    this.setState({
      loading: false,
      pageNumber,
      initialLoaded: true,
    });
  };

  private processUsers = () => {
    const following = delve(this.props, 'user.following');

    if (!following) return;

    const f: User[] = [];

    Object.values(following).map((page: any) => {
      page.map((u: User) => {
        f.push(u);
      });
    });

    this.setState({ following: f });
  };

  private unfollowUser = async (username: string) => {
    try {
      await new Http(`/users/${username}/unfollow`).post();

      track('unfollow_user');

      this.setState({
        unfollowedUsers: [...this.state.unfollowedUsers, username],
      });

      new Toast({ body: USER_UNFOLLOWED }).dispatch();
    } catch (e) {
      new Toast({
        body: UNFOLLOW_USER_FAIL,
        failure: true,
      }).dispatch();
    }
  };

  public render = () => {
    const { following, initialLoaded, loading, unfollowedUsers } = this.state;

    const displayFollowing = following.filter(
      (user: User) => !unfollowedUsers.includes(user.username)
    );

    return (
      <div className="wrap mt8 mt24@m">
        <Head title={t('Following')} pathname={t('/following')} />

        <BackButton
          to={routes.me}
          text={t('Back to profile')}
          className="mb32 u-link-grey u-link-grey--alt"
        />

        <h1 className="f-title-1 u-bold mb32 pb16 bb--sad-grey u-white">
          {t('Following')}
        </h1>

        {initialLoaded ? (
          displayFollowing.length > 0 ? (
            <>
              <Paginator
                onEndReached={(end: PaginatorPosition) => {
                  if (end === PaginatorPosition.end) {
                    this.fetchFollowingData();
                  }
                }}
              >
                <UserCardboards
                  users={displayFollowing}
                  cornerButtonAction={async (username: string) => {
                    await this.unfollowUser(username);
                  }}
                  cornerButtonComponents={
                    <Icon id="clear" size={IconSize.xs} />
                  }
                />
              </Paginator>

              {loading && (
                <div className="u-flex u-1/1 u-justify-center mb32">
                  <LoadingSymbol size="l" />
                </div>
              )}
            </>
          ) : (
            <EmptyState
              className="u-very-light-grey"
              titleText={t('Not a lot going on here!')}
              subtitleText={t('Why not find some people to follow?')}
              ctaText={t('Discover people')}
              linkRoute={`${routes.search}?type=people`}
              showCTAs={true}
            />
          )
        ) : (
          <UserCardboards users={[]} skeleton={true} />
        )}
      </div>
    );
  };
}

export const Following = connect(['user', 'auth'], (store: any) => ({
  ...userActions(store),
  ...authActions(store),
}))(FollowingComponent);
