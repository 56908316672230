import { getCategoriesFromData, processCategories } from '@/store/helpers';

import { Http } from '@/utils/api/Http';

const categoryState: CategoryState = {
  organisedCategories: {
    allCategories: [],
    interests: [],
    orderedInterests: [],
    interestsOnly: [],
    nonInterestsOnly: [],
    industries: [],
    orderedIndustries: [],
    campaigns: [],
    closedCampaigns: [],
    statuses: [],
    flags: [],
    entities: [],
    discussionCategory: undefined,
    lookingForCollaboratorsCategory: undefined,
    discussionOnlyCategories: [],
    discussionsCategories: [],
    workshopRoomCategory: undefined,
  },
};

const categoryActions = () => ({
  fetchAndSortCategories: async (state: any, force?: boolean) => {
    if (!force && state.organisedCategories.allCategories.length) {
      return state;
    }

    let allCategories: Category[] = [];
    let organisedCategories: {
      [key: string]: Category[] | Category | undefined;
    } = categoryState.organisedCategories;

    try {
      const { data } = await new Http('/projectCategories').get<
        APIArray<APIProjectCategory>
      >();

      allCategories = getCategoriesFromData(data);

      organisedCategories = await processCategories(
        allCategories,
        state.auth.user
      );
    } catch (e) {
      throw e;
    }

    return {
      organisedCategories,
    };
  },
});

export { categoryState, categoryActions };
