export const notProduction = () => process.env.DAISIE_ENV !== 'production';

export const showContrastTester = () => false;
export const isShareOnDaisieEnabled = () => false;
export const showBlogLinks = () => false;
export const isShareablesEnabled = () => false;
export const enablePreMobileWall = () => false;
export const enableAutojoinPublicRooms = () => true;
export const enableAutojoinVoiceAudience = () => true;
export const enableAutojoinVoiceAdmin = () => true;
export const enableGtmEvents = () => true;
export const enableMoveToAudienceConfirm = () => false;
export const enableActiveWorkshopsPolling = () => true;
export const enablePhoneSignUpLogin = () => true;
export const disableLoggedOutCategoryPages = () => true;
export const enableCareersPage = () => false;
export const showPriceOnLoggedOutHomepageScroll = () => false;
export const enableRoomAudioInBackground = () => false;
export const enableWorkshopChatInMessages = () => true;
export const enableOnDemandBanner = () => false;
export const enableExplore = () => false;

// Before May 2021, Daisie used the react-ga library to integrate Google Analytics.
// Instead, we now use Google Tag Manager to dynamically add the tracking code, as it
// integrates better with Google Optimize
export const enableGoogleAnalyticsLibrary = () => false;

// DEPRECATED ROOM FEATURES
export const enableRoomNotificationActions = () => false;

// TEMP DISABLED FEATURES
export const enableRoomVoiceAudioSettings = () => false;
export const enableAttendeeListOnWorkshopAndRoomCards = () => false;

// SUBSCRIPTIONS
export const enableMonthlyYearlySubscriptionSwitch = () => false;
export const enableApplePay = () => notProduction();

export const enableMaintenanceModeCheck = () => true;
