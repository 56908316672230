import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'unistore/react';

import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { Avatar } from '@/components/users/Avatar/Avatar';

import { DaisieCustomEvent } from '@/enums/DaisieCustomEvent';

import { getWorkshopFromData, getWorkshopsFromData } from '@/store/helpers';

import { Http } from '@/utils/api/Http';
import { c } from '@/utils/strings/c';
import { getRouteFromUrl } from '@/utils/urls/routes';

interface Props extends SizingState, AuthState, RouteComponentProps {}

interface State {
  isVisible: boolean;
  workshops: Workshop[];
  selectedWorkshop?: Workshop;
  isLoaded: boolean;
}

class OptimisedJoinFlowWorkshopHeroComp extends React.Component<Props, State> {
  public state: State = {
    isVisible: false,
    workshops: [],
    selectedWorkshop: undefined,
    isLoaded: false,
  };

  public componentDidMount = () => {
    // Add an event handler so the hero can be hidden without a route change,
    // for example, when stepping through /join/choose-plan on mobile
    window.addEventListener(
      DaisieCustomEvent.force_hide_onboarding_workshop_hero,
      this.forceHide
    );

    // Do an initial check on first page load
    this.checkIsVisible();
  };

  public componentDidUpdate = (prevProps: Props) => {
    const {
      location: { pathname, search },
      auth: { user },
    } = this.props;

    const { isVisible } = this.state;

    // The hero isn't rendered outside of the subscription onboarding flow,
    // so don't bother checking for it if the current user is signed in and subscribed
    if (!!user && user.subscriptionTier !== null && !isVisible) {
      return;
    }

    // Whenever the route changes, check if the hero needs to be rendered or not
    const {
      location: { pathname: prevPathname, search: prevSearch },
    } = prevProps;

    const hasPathnameChanged: boolean = pathname !== prevPathname;
    const hasSearchChanged: boolean = search !== prevSearch;

    if (hasPathnameChanged || hasSearchChanged) {
      this.checkIsVisible();
    }
  };

  public componentWillUnmount = () => {
    window.removeEventListener(
      DaisieCustomEvent.force_hide_onboarding_workshop_hero,
      this.forceHide
    );
  };

  private checkIsVisible = () => {
    // Make the workshop hero visible on certain routes, and if a workshop URL
    // is in the `to` query parameter
    const {
      location: { pathname, search },
      sizing: { isMobile },
    } = this.props;

    const matchedRoute: string = getRouteFromUrl(pathname);

    const queryParams: URLSearchParams = new URLSearchParams(search);
    const to: string = queryParams.get('to') || '';

    const allowedRoutesMobile: string[] = ['optimisedJoinFlowChoosePlan'];

    const allowedRoutesTabletDesktop: string[] = [
      'optimisedJoinFlowChoosePlan',
      'optimisedJoinFlowSignUpMonthly',
      'optimisedJoinFlowSignUpMonthlyPay',
      'optimisedJoinFlowSignUpYearly',
      'optimisedJoinFlowSignUpYearlyPay',
    ];

    const allowedRoutes: string[] = isMobile
      ? allowedRoutesMobile
      : allowedRoutesTabletDesktop;

    const hasMatchedRoute: boolean = allowedRoutes.includes(matchedRoute);
    const hasWorkshopRedirect: boolean = !!to && to.includes('/workshops/');

    if (hasMatchedRoute && hasWorkshopRedirect) {
      // If the route changes, the hero is already visible, and is visible again, don't do anything.
      // This only works because you can't currently switch between different workshops,
      // without visiting a page that hides this hero, so there's no need for any refresh logic
      if (this.state.isVisible) {
        return;
      }

      this.setState(
        {
          isVisible: true,
          workshops: [],
          selectedWorkshop: undefined,
          isLoaded: false,
        },
        () => {
          this.fetchWorkshops({ workshopRedirectPath: to });
        }
      );
    } else {
      this.setState({ isVisible: false });
    }
  };

  private forceHide = () => {
    this.setState({ isVisible: false });
  };

  private fetchWorkshops = async ({
    workshopRedirectPath,
  }: {
    workshopRedirectPath: string;
  }) => {
    try {
      const { data: allWorkshopsData } = await new Http(
        `/workshops?pageSize=10&pageNumber=1&sortBy=workshopStartDate`
      ).get<APIObject<APIWorkshop[]>>();

      const workshops: Workshop[] =
        getWorkshopsFromData(allWorkshopsData).reverse();

      const workshopUuid: string | undefined = workshopRedirectPath
        .split('/')
        .pop()
        ?.split('-')
        .pop();

      if (!workshopUuid) {
        throw Error();
      }

      const { data: selectedWorkshopData } = await new Http(
        `/workshops/workshop_${workshopUuid}`
      ).get<APIObject<APIWorkshop>>();

      const selectedWorkshop: Workshop =
        getWorkshopFromData(selectedWorkshopData);

      this.setState({
        workshops,
        selectedWorkshop,
        isLoaded: true,
      });
    } catch (e) {
      // TODO
    }
  };

  public render = () => {
    const { isVisible, workshops, isLoaded, selectedWorkshop } = this.state;

    const {
      sizing: { isMobile },
    } = this.props;

    if (!isVisible || !selectedWorkshop) {
      return null;
    }

    const classnameForImgContainer: string =
      'u-overflow-hidden c-workshop-hero--img-container';
    const classnameForImg: string =
      'u-1/1 u-fit-content c-workshop-hero--img u-object-cover';

    const desktopSkeleton: React.ReactNode = (
      <div className="c-workshop-hero-skeleton">
        {Array.from(Array(3).keys()).map((x) => (
          <div
            key={x}
            className={c([classnameForImgContainer, 'u-border-radius--s'])}
          >
            <div className={c([classnameForImg, 'u-bg-charcoal-grey'])} />
          </div>
        ))}
      </div>
    );

    return (
      <div className="c-workshop-hero-wrapper">
        {!isMobile ? (
          <div className="absolute absolute--top-center" style={{ left: -44 }}>
            {isLoaded && (
              <div>
                <div className="c-workshop-hero-shadow c-workshop-hero-shadow--top" />
                <div className="c-workshop-hero-shadow c-workshop-hero-shadow--bottom" />
                <div className="c-workshop-hero-shadow c-workshop-hero-shadow--right" />
                {desktopSkeleton}
                <div className="c-workshop-hero-desktop">
                  {workshops.slice(0, 9).map((w) => (
                    <div
                      key={w.id}
                      className={c([
                        classnameForImgContainer,
                        'u-border-radius--s relative',
                      ])}
                      style={{
                        opacity:
                          workshops[4].id === w.id ||
                          workshops[0].id === w.id ||
                          workshops[6].id === w.id
                            ? '1'
                            : '0.2',
                      }}
                    >
                      {workshops[0].id === w.id || workshops[6].id === w.id ? (
                        <div
                          className={c([
                            classnameForImgContainer,
                            'u-border-radius--s u-bg-charcoal-grey',
                          ])}
                        >
                          <div
                            className={c([
                              classnameForImg,
                              'u-bg-charcoal-grey',
                            ])}
                          />
                        </div>
                      ) : (
                        <div className="u-h-100 mvauto">
                          {workshops[4].id === w.id ? (
                            <ImageLoader
                              src={
                                workshops[4].coverMedia !==
                                selectedWorkshop.coverMedia
                                  ? selectedWorkshop.coverMedia
                                  : w.coverMedia
                              }
                              alt={selectedWorkshop.title}
                              className={classnameForImg}
                            />
                          ) : (
                            <ImageLoader
                              src={
                                selectedWorkshop.coverMedia !== w.coverMedia
                                  ? w.coverMedia
                                  : workshops[0].coverMedia
                              }
                              alt={w.title}
                              className={classnameForImg}
                            />
                          )}
                        </div>
                      )}
                      {workshops[4].id === w.id && (
                        <div className="absolute absolute--bl u-flex u-align-center mb12 ml12">
                          <Avatar
                            src={selectedWorkshop.mentor.avatar}
                            user={selectedWorkshop.mentor}
                            alt={selectedWorkshop.mentor.name}
                            size="xs"
                            className="mr6 u-fit-content u-h-fit-content"
                          />
                          <p className="u-white f-text-3 u-bold">
                            {selectedWorkshop.mentor.name}
                          </p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                {desktopSkeleton}
              </div>
            )}
          </div>
        ) : (
          <div className="u-1/1 u-overflow-hidden c-workshop-hero-container">
            <div
              className="u-flex u-justify-center u-overflow-hidden u-block relative"
              style={{ top: '-115px' }}
            >
              <div>
                {isLoaded && (
                  <div className="relative" style={{ maxHeight: '365px' }}>
                    <div className="c-workshop-hero-shadow c-workshop-hero-shadow--bottom" />
                    <div className="c-workshop-hero-a">
                      {workshops.slice(0, 4).map((w) => (
                        <div
                          key={w.id}
                          className={c([
                            classnameForImgContainer,
                            'u-border-radius--xs',
                          ])}
                        >
                          <ImageLoader
                            src={w.coverMedia}
                            alt={w.title}
                            className={classnameForImg}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="c-workshop-hero-b u-flex u-justify-center u-block">
                      {workshops.slice(4, 7).map((w) => (
                        <div
                          key={w.id}
                          className={c([
                            classnameForImgContainer,
                            'u-border-radius--xs relative',
                          ])}
                        >
                          {workshops[5].id === w.id ? (
                            <ImageLoader
                              src={
                                workshops[5].coverMedia !==
                                selectedWorkshop.coverMedia
                                  ? selectedWorkshop.coverMedia
                                  : w.coverMedia
                              }
                              alt={selectedWorkshop.title}
                              className={classnameForImg}
                            />
                          ) : (
                            <ImageLoader
                              src={
                                selectedWorkshop.coverMedia !== w.id
                                  ? w.coverMedia
                                  : selectedWorkshop.coverMedia
                              }
                              alt={w.title}
                              className={classnameForImg}
                            />
                          )}

                          {workshops[5].id === w.id && (
                            <div className="absolute absolute--bl u-flex u-align-center mb8 ml8">
                              <Avatar
                                src={selectedWorkshop.mentor.avatar}
                                user={selectedWorkshop.mentor}
                                alt={selectedWorkshop.mentor.name}
                                size="xs"
                                className="mr6 u-fit-content u-h-fit-content"
                              />
                              <p className="u-white f-text-5 u-bold">
                                {selectedWorkshop.mentor.name}
                              </p>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    <div
                      className="c-workshop-hero-a"
                      style={{ marginTop: '39px' }}
                    >
                      {workshops.slice(6, 10).map((w) => (
                        <div
                          key={w.id}
                          className={c([
                            classnameForImgContainer,
                            'u-border-radius--xs',
                          ])}
                        >
                          <ImageLoader
                            src={
                              selectedWorkshop.coverMedia !== w.coverMedia
                                ? w.coverMedia
                                : workshops[0].coverMedia
                            }
                            alt={w.title}
                            className={classnameForImg}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
}

export const OptimisedJoinFlowWorkshopHero = connect(
  ['sizing', 'auth'],
  () => ({})
)(withRouter(OptimisedJoinFlowWorkshopHeroComp));
