export enum Currency {
  gbp = 'gbp',
  eur = 'eur',
  usd = 'usd',
  // Brazil
  brl = 'brl',
  // Mexico
  mxn = 'mxn',
  // India
  inr = 'inr',
  // Canada
  cad = 'cad',
  // South African
  zar = 'zar',
}
