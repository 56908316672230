import * as React from 'react';

import { Icon } from '@/components/global/Icon/Icon';
import { NativePlayer } from '@/components/ondemand/NativePlayer';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { IconSize } from '@/enums/IconSize';
import { OnDemandMediaType } from '@/enums/OnDemandMediaType';

import { parser } from '@/routes/parser';

import { getOnDemandMediasForWorkshop } from '@/store/helpers/onDemandHelpers';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props {
  videoUrl?: string;
  onClose: () => void;
  onClickPrevious: () => void;
  onClickNext: () => void;
  workshopData?: Workshop;
}

interface State {
  isRendered: boolean;
  isVisible: boolean;
  hasFullWorkshop: boolean;
}

class CollectionPageVideoPlayer extends React.Component<Props, State> {
  public state: State = {
    isRendered: false,
    isVisible: false,
    hasFullWorkshop: false,
  };

  public componentDidUpdate = (prevProps: Props) => {
    const { videoUrl: prevVideoUrl, workshopData: prevWorkshopData } =
      prevProps;
    const { videoUrl, workshopData } = this.props;

    if (!prevVideoUrl && !!videoUrl) {
      this.showPlayer();
    } else if (prevVideoUrl !== videoUrl) {
      this.setState({ isRendered: false }, () => {
        this.setState({ isRendered: true });
      });
    }

    // When the currently viewed clip changes, check if the workshop it belongs to
    // has a full workshop catch-up video attached to it
    if (
      (!prevWorkshopData && !!workshopData) ||
      (!!prevWorkshopData &&
        !!workshopData &&
        prevWorkshopData.id !== workshopData.id)
    ) {
      this.checkForFullWorkshop(workshopData.id);
    }
  };

  private checkForFullWorkshop = async (workshopUuid: string) => {
    try {
      this.setState({ hasFullWorkshop: false });

      const onDemandMedias = await getOnDemandMediasForWorkshop({
        workshopUuid,
      });

      const hasFullWorkshop: boolean = !!onDemandMedias.find(
        (odm: OnDemandMedia) =>
          odm.type === OnDemandMediaType.WorkshopCatchUp && odm.readyToStream
      );

      // Don't update `hasFullWorkshop` if the workshop id has changed since
      // this function was first called -- useful for when people quickly skip
      // through clips in a collecton
      if (
        !!this.props.workshopData &&
        this.props.workshopData.id === workshopUuid
      ) {
        this.setState({ hasFullWorkshop });
      }
    } catch (e) {
      // do nothing
    }
  };

  private showPlayer = () => {
    this.setState({ isRendered: true }, () => {
      setTimeout(() => {
        this.setState({ isVisible: true });
      }, 1);
    });
  };

  private hidePlayer = () => {
    this.setState({ isVisible: false }, () => {
      setTimeout(() => {
        this.setState({ isRendered: false }, () => this.props.onClose());
      }, 250);
    });
  };

  public render = () => {
    const { isRendered, isVisible } = this.state;

    if (!isRendered) {
      return null;
    }

    const { videoUrl, onClickPrevious, onClickNext, workshopData } = this.props;

    return (
      <div
        className={c(
          'animate-opacity absolute absolute--tl u-1/1 u-h-100vh u-bg-less-translucent-black',
          {
            'opacity-1': isVisible,
            'opacity-0 pointer-events-none': !isVisible,
          }
        )}
        // TODO: use class here
        style={{ zIndex: 9999 }}
      >
        {!!videoUrl && (
          <NativePlayer
            videoUrl={videoUrl}
            className="u-1/1 u-h-100vh p32"
            onMobileFullScreenExit={() => {
              this.setState(
                {
                  isVisible: false,
                  isRendered: false,
                },
                () => {
                  setTimeout(() => {
                    this.props.onClose();
                  }, 1);
                }
              );
            }}
            actionButton={() => {
              const { hasFullWorkshop } = this.state;

              return (
                <div
                  className={c('mr24 animate-opacity', {
                    'opacity-1': hasFullWorkshop,
                    'opacity-0 pointer-events-none': !hasFullWorkshop,
                  })}
                >
                  <Button
                    type={ButtonType.link}
                    size={ButtonSize.s}
                    buttonStyle={ButtonStyle.default}
                    linkTo={
                      hasFullWorkshop && !!workshopData
                        ? `${parser({
                            name: 'workshop',
                            params: {
                              workshopSlug: workshopData.slug,
                            },
                          })}`
                        : ''
                    }
                    text={t('Watch full workshop')}
                    iconId="play"
                    iconSide="left"
                  />
                </div>
              );
            }}
          />
        )}

        <div className="absolute absolute--left-center ml32 u-hide@s">
          <button
            type="button"
            onClick={onClickPrevious}
            className="u-link-white u-link-white--alt"
          >
            <Icon id="chevron-left" size={IconSize.s} />
          </button>
        </div>

        <div className="absolute absolute--right-center mr32 u-hide@s">
          <button
            type="button"
            onClick={onClickNext}
            className="u-link-white u-link-white--alt"
          >
            <Icon id="chevron-right" size={IconSize.s} />
          </button>
        </div>

        <button
          type="button"
          onClick={() => this.hidePlayer()}
          className="u-white absolute absolute--tr mt32 mr32"
        >
          <Icon id="clear" size={IconSize.s} className="" />
        </button>
      </div>
    );
  };
}

export { CollectionPageVideoPlayer };
