import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import { LoadingSymbol } from '@/components/global/LoadingSymbol/LoadingSymbol';

import { APP_STORE_LINK } from '@/utils/constants';
import { track } from '@/utils/mixpanel/mixpanel';
import { getRouteFromUrl } from '@/utils/urls/routes';

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

class AppStoreReferralComponent extends React.Component<Props> {
  public componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
      location: { pathname },
    } = this.props;

    const matchedRoute = getRouteFromUrl(pathname);

    if (matchedRoute === 'app') {
      track('app_store_shortcut_followed');
    } else {
      track('ambassador_link_visited', { id });
    }

    setTimeout(() => {
      window.location.href = APP_STORE_LINK;
    }, 2000);
  };

  public render = () => (
    <div className="u-bg-black u-100vh-var u-flex u-align-center u-justify-center">
      <LoadingSymbol colour="white" size="l" />
    </div>
  );
}

export const AppStoreReferral = withRouter(AppStoreReferralComponent);
