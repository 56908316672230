import * as React from 'react';

import { ModalConfirm } from '@/components/global/Modal/ModalConfirm/ModalConfirm';

interface Props {
  data: {
    confirmCtaLabel: string;
    confirmCtaAction: () => void;
    cancelCtaLabel: string;
    cancelCtaAction: () => void;
    body: React.ReactNode;
  };
}

class SaveChanges extends React.Component<Props> {
  public render = () => {
    const {
      confirmCtaLabel,
      confirmCtaAction,
      cancelCtaLabel,
      cancelCtaAction,
      body,
    } = this.props.data;

    return (
      <ModalConfirm
        confirmCtaLabel={confirmCtaLabel}
        confirmCtaAction={confirmCtaAction}
        cancelCtaLabel={cancelCtaLabel}
        cancelCtaAction={cancelCtaAction}
        body={body}
      />
    );
  };
}

export { SaveChanges };
