import {
  getStripeCouponsFromData,
  getStripeProductsAndPrices,
  getUserSubscription,
} from '@/store/helpers/subscriptionHelpers';

import { Http } from '@/utils/api/Http';

const subscriptionState: SubscriptionState = {
  subscription: {
    userSubscription: undefined,
    freeTrialPeriodInWeeks: undefined,
    customerPortalUrl: undefined,
    userCurrency: undefined,
    productsAndPrices: undefined,
    coupons: [],
  },
};

const subscriptionActions = (store: any) => ({
  getUserSubscription: async (state: GlobalStoreState, username: string) => {
    const { data } = await new Http(
      `/users/${username}/stripeSubscription`
    ).get<APIObject<APIStripeSubscription>>();

    if (!data.relationships) {
      // TODO: handle error
      return;
    }

    const userSubscription = getUserSubscription(
      data.relationships.subscription.data
    );

    const stateCopy = store.getState();

    return {
      subscription: { ...stateCopy.subscription, userSubscription },
    };
  },

  getFreeTrialPeriodInWeeks: async () => {
    const { data } = await new Http('/freeTrialPeriodInWeeks').get<
      APIObject<APIFreeTrialPeriodInWeeks>
    >();

    const stateCopy = store.getState();

    return {
      subscription: {
        ...stateCopy.subscription,
        freeTrialPeriodInWeeks: data.attributes.freeTrialPeriodLengthInWeeks,
      },
    };
  },

  getCustomerPortalUrl: async () => {
    const { data } = await new Http('/stripe/customerPortalUrl').get<
      APIObject<APIStripeCustomerPortal>
    >();

    const stateCopy = store.getState();

    return {
      subscription: {
        ...stateCopy.subscription,
        customerPortalUrl: data.attributes.url,
      },
    };
  },

  getStripeProductsAndPrices: async () => {
    const initialStateCopy = store.getState();

    const isGrandfathered =
      initialStateCopy && initialStateCopy.auth && initialStateCopy.auth.user
        ? initialStateCopy.auth.user.isGrandfathered
        : false;

    // This Selleck call only returns grandfathering pricing if the user
    // is entitled to it. There's no need to refresh this when someone logs
    // in or out, because window.location.href is used and the page is fully reloaded
    const { data } = await new Http('/stripe/productsAndPrices').get<
      APIObject<APIStripeProductsAndCoupons>
    >();

    // If the current user isGrandfathered, remove non-grandfathered pricing, otherwise
    // return everything because Selleck returns non-grandfathered pricing only when
    // logged out or unentitled
    const productsAndPrices = getStripeProductsAndPrices(data).filter(
      (scp: SubscriptionProductPrice) => {
        if (isGrandfathered) {
          return scp.isGrandfathering;
        } else {
          return true;
        }
      }
    );

    const coupons = getStripeCouponsFromData(data.attributes.coupons);

    const stateCopy = store.getState();

    return {
      subscription: {
        ...stateCopy.subscription,
        productsAndPrices,
        userCurrency: data.attributes.currency,
        coupons,
      },
    };
  },
});

export { subscriptionActions, subscriptionState };
