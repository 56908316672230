import * as React from 'react';

import { SubNavigationLink } from 'definitions/SubNavigationLink';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'unistore/react';

import { Badge } from '@/components/global/Badge/Badge';
import { Icon } from '@/components/global/Icon/Icon';
import { Link } from '@/components/global/Link/Link';

import { IconSize } from '@/enums/IconSize';
import { SubNavigationLinkType } from '@/enums/SubNavigationLinkType';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

import { c } from '@/utils/strings/c';

interface SubNavigationProps {
  classNameLink: string;
  classNameLinkActive: string;
  classNameLinkInactive: string;
  classNameSpacing: string;
  classNameLine: string;
  classNameContainerInner?: string;
  classNameContainer: string;
  alwaysShowFade?: boolean;
  links: SubNavigationLink[];
  onClickEach?: (id?: string) => void;
  centred?: boolean;
  editable?: boolean;
  halfLine?: boolean;
  lineColour?: 'black' | 'white';
  noHighlight?: boolean;
}

interface State {
  centredFadeOverride: boolean;
}

interface Props extends SubNavigationProps, RouteComponentProps, SizingState {}

class SubNavigationComponent extends React.Component<Props, State> {
  public state: State = {
    centredFadeOverride: true,
  };

  private subNavigation: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this.subNavigation = React.createRef();
  }

  public componentDidMount = () => {
    if (this.subNavigation.current) {
      this.subNavigation.current.addEventListener('scroll', this.onScroll);
    }

    setTimeout(() => this.checkCentredFade(), 1);
  };

  public componentDidUpdate = (
    prevProps: SubNavigationProps & RouteComponentProps
  ) => {
    if (
      this.props.links.length !== prevProps.links.length &&
      this.props.centred
    ) {
      this.checkCentredFade();
    }
  };

  public componentWillUnmount = () => {
    if (this.subNavigation.current) {
      this.subNavigation.current.removeEventListener('scroll', this.onScroll);
    }

    window.removeEventListener('resize', this.onResize);
  };

  private checkCentredFade = () => {
    if (this.props.centred) {
      const subNavigation = this.subNavigation.current as HTMLElement;

      if (
        subNavigation &&
        subNavigation.parentNode &&
        subNavigation.parentNode.parentNode
      ) {
        const contentWidth = subNavigation.getBoundingClientRect().width;

        const subNavigationParentNode = subNavigation.parentNode
          .parentNode as HTMLElement;

        const upperContainerWidth =
          subNavigationParentNode.getBoundingClientRect().width;

        if (contentWidth < upperContainerWidth) {
          this.setState({ centredFadeOverride: true });
        } else {
          this.setState({ centredFadeOverride: false });
        }
      }
    }
  };

  private onResize = () => {
    if (this.props.centred) {
      this.checkCentredFade();
    } else {
      const subNavigation = this.subNavigation.current;

      if (subNavigation && subNavigation.parentNode) {
        const elementBlur = subNavigation.parentNode.querySelector(
          '.c-sub-navigation__blur'
        );

        if (!elementBlur) return;

        if (
          subNavigation.scrollWidth >
          subNavigation.getBoundingClientRect().width
        ) {
          elementBlur.classList.remove('c-sub-navigation__blur--hide');
        } else {
          elementBlur.classList.add('c-sub-navigation__blur--hide');
        }
      }
    }
  };

  private onScroll = () => {
    const { alwaysShowFade, centred } = this.props;

    if (
      (!this.props.sizing.isMobile && !alwaysShowFade && !centred) ||
      !this.subNavigation.current ||
      !this.subNavigation.current.parentNode
    )
      return;

    const distanceToEnd =
      this.subNavigation.current.scrollWidth -
      this.subNavigation.current.scrollLeft -
      this.subNavigation.current.getBoundingClientRect().width;

    const elementBlur = this.subNavigation.current.parentNode.querySelector(
      '.c-sub-navigation__blur'
    );

    if (!elementBlur) return;

    if (distanceToEnd < elementBlur.getBoundingClientRect().width) {
      elementBlur.classList.add('c-sub-navigation__blur--hide');
    } else {
      elementBlur.classList.remove('c-sub-navigation__blur--hide');
    }
  };

  public render = () => {
    const {
      links,
      classNameContainer,
      classNameContainerInner = '',
      classNameLink,
      classNameLinkActive,
      classNameLinkInactive,
      classNameSpacing,
      classNameLine,
      alwaysShowFade,
      location,
      onClickEach,
      centred,
      editable,
      sizing: { isMobile },
      halfLine,
      lineColour,
      noHighlight,
    } = this.props;

    const { centredFadeOverride } = this.state;

    return (
      <nav
        className={c(['c-sub-navigation__wrapper', classNameContainer], {
          'c-sub-navigation__wrapper--width-auto mlauto mrauto':
            centred && !isMobile,
          'c-sub-navigation__wrapper--editable': editable,
        })}
      >
        <div
          className={c(['c-sub-navigation', classNameContainerInner], {
            'c-sub-navigation--editable': editable,
          })}
          ref={this.subNavigation}
        >
          {links.map((link: SubNavigationLink, i: number) => {
            if (link.type === SubNavigationLinkType.route) {
              const {
                to,
                text,
                imageUrl,
                counterBadge,
                wordBadge,
                className = '',
                iconId,
                iconSide = 'right',
                iconClassName,
                customIsActive,
                tooltip,
                id,
                tooltipId,
                uiTestId,
              } = link;

              const active =
                customIsActive && customIsActive()
                  ? true
                  : location.pathname + location.hash === to;

              return (
                <>
                  <Link
                    disabled={editable}
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    to={to}
                    className={c(['c-sub-navigation__link', className], {
                      [classNameSpacing]: i !== links.length - 1,
                      [classNameLink]: isMobile && !halfLine,
                      'c-sub-navigation__link--active': active && !editable,
                      'c-sub-navigation__link--no-highlight': noHighlight,
                      [classNameLinkActive]: active,
                      'pointer-events-none': active,
                      [classNameLinkInactive]: !active,
                      'u-flex': counterBadge || wordBadge,
                      'c-sub-navigation__link--editable': editable,
                      'c-sub-navigation__link--active--half-line':
                        active && halfLine,
                    })}
                    onClick={() => {
                      if (onClickEach) {
                        onClickEach(id);
                      }

                      if (link.onClick) {
                        link.onClick();
                      }
                    }}
                    uiTestId={isUiTest() && uiTestId ? uiTestId : undefined}
                  >
                    <span
                      id={tooltipId}
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      className={c('c-sub-navigation__link__text', {
                        [classNameLink]: !isMobile || (isMobile && halfLine),
                      })}
                    >
                      {iconId && iconSide === 'left' && (
                        <Icon
                          id={iconId}
                          className={
                            !!iconClassName ? iconClassName : 'ml4 mt2 mt4@m'
                          }
                          size={IconSize.s}
                        />
                      )}

                      {text}

                      {imageUrl && (
                        <span
                          className="c-sub-navigation__link__text__image"
                          style={{
                            backgroundImage: `url('${imageUrl}')`,
                          }}
                        />
                      )}

                      {iconId && iconSide === 'right' && (
                        <Icon
                          id={iconId}
                          className={
                            !!iconClassName ? iconClassName : 'ml4 mt2 mt4@m'
                          }
                          size={IconSize.s}
                        />
                      )}
                    </span>

                    {halfLine && (
                      <span
                        className={c(
                          'c-sub-navigation__link__text c-sub-navigation__link__text--half-line',
                          {
                            [classNameLink]:
                              !isMobile || (isMobile && halfLine),
                            'c-sub-navigation__link__text--half-line--active':
                              active,
                          }
                        )}
                      >
                        {text}
                      </span>
                    )}

                    {counterBadge && (
                      <span className="counter-badge">{counterBadge}</span>
                    )}

                    {wordBadge && <Badge text={wordBadge} animated={true} />}
                  </Link>
                  {tooltip}
                </>
              );
            }

            if (link.type === SubNavigationLinkType.action) {
              const {
                activeState,
                text,
                counterBadge,
                wordBadge,
                className = '',
                uiTestId,
              } = link;

              return (
                <button
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  type="button"
                  className={c(['c-sub-navigation__link', className], {
                    [classNameSpacing]: i !== links.length - 1,
                    [classNameLink]: isMobile && !halfLine,
                    'c-sub-navigation__link--active':
                      activeState && !lineColour,
                    [`c-sub-navigation__link--active-${lineColour}`]:
                      activeState && lineColour,
                    'c-sub-navigation__link--active--half-line':
                      activeState && halfLine,
                    [classNameLinkActive]: activeState,
                    'pointer-events-none': activeState,
                    [classNameLinkInactive]: !activeState,
                    'u-flex': counterBadge || wordBadge,
                  })}
                  onClick={() => {
                    if (link.onClick) {
                      link.onClick();
                    }
                  }}
                  data-ui-test-id={
                    isUiTest() && uiTestId ? uiTestId : undefined
                  }
                >
                  <span
                    className={c('c-sub-navigation__link__text', {
                      [classNameLink]: !isMobile || (isMobile && halfLine),
                      'bb--none': halfLine,
                    })}
                  >
                    {text}
                  </span>

                  {halfLine && (
                    <span
                      className={c(
                        'c-sub-navigation__link__text c-sub-navigation__link__text--half-line',
                        {
                          [classNameLink]: !isMobile || (isMobile && halfLine),
                          'c-sub-navigation__link__text--half-line--active':
                            activeState,
                        }
                      )}
                    >
                      {text}
                    </span>
                  )}

                  {counterBadge && (
                    <span className="counter-badge">{counterBadge}</span>
                  )}

                  {wordBadge && <Badge text={wordBadge} animated={true} />}
                </button>
              );
            }

            if (link.type === SubNavigationLinkType.separator) {
              return (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  className={c([
                    'c-sub-navigation__seperator',
                    classNameSpacing,
                    classNameLink,
                  ])}
                >
                  <i />
                </div>
              );
            }

            if (link.type === SubNavigationLinkType.component) {
              return link.component;
            }

            return null;
          })}

          {!!classNameContainerInner && (
            <div
              className={c([
                'c-sub-navigation__spacer',
                classNameContainerInner,
              ])}
            />
          )}
        </div>

        <div className={c(['c-sub-navigation__line', classNameLine])} />
        <div
          className={c(['c-sub-navigation__blur'], {
            'c-sub-navigation__blur--fixed': alwaysShowFade,
            'c-sub-navigation__blur--centred': centred,
            'c-sub-navigation__blur--hide': centredFadeOverride,
          })}
        />
      </nav>
    );
  };
}

export const SubNavigation = connect(['sizing'], () => ({}))(
  withRouter(SubNavigationComponent)
);
