import * as React from 'react';

import { Icon } from '@/components/global/Icon/Icon';

import { IconSize } from '@/enums/IconSize';

import { c } from '@/utils/strings/c';

interface Props {
  onClick: () => void;
  fixed?: boolean;
  className?: string;
  isDarkMode?: boolean;
  size?: IconSize;
}

const CloseButton = ({
  onClick,
  fixed = false,
  className = 'ml16',
  isDarkMode,
  size = IconSize.s,
}: Props) => (
  <button
    type="button"
    onClick={onClick}
    aria-label="Close"
    className={c(['c-close-button u-flex u-align-center', className], {
      'c-close-button--fixed': fixed,
      'u-white': isDarkMode,
    })}
  >
    <Icon id="clear" size={size} />
  </button>
);

export { CloseButton };
