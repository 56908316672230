import * as React from 'react';

import Bowser from 'bowser';

import { Textarea } from '@/components/global/Forms/Textarea/Textarea';

import { RELEASE_VERSION } from '@/release';

interface Props {}

interface State {
  text?: string;
}

class DiagnosticTool extends React.Component<Props, State> {
  public state: State = {
    text: undefined,
  };

  public componentDidMount = () => {
    this.createText();

    window.addEventListener('resize', this.createText);
  };

  public componentWillUnmount = () => {
    window.removeEventListener('resize', this.createText);
  };

  private createText = () => {
    const userAgent = Bowser.parse(window.navigator.userAgent);

    const {
      browser: { name: browserName, version: browserVersion },
      os: { name: osName, version: osVersion },
      platform: { type: platformType },
    } = userAgent;

    const browser = `Browser: ${browserName} ${browserVersion}`;
    const os = `OS: ${osName} ${osVersion} (${platformType})`;
    const timestamp = `Time: ${new Date().toString()}`;
    const viewport = `Window size: ${window.innerWidth}x${window.innerHeight}`;
    const releaseVersion = `Release version: ${RELEASE_VERSION}`;

    this.setState({
      text: `${browser}\n${os}\n${timestamp}\n${viewport}\n${releaseVersion}`,
    });
  };

  public render = () => {
    const { text } = this.state;

    return (
      <div className="wrap mv32">
        <Textarea
          id="diagnostic-info"
          value={text || ''}
          className="u-1/1 u-1/2@m mhauto mb32"
        />
      </div>
    );
  };
}

export { DiagnosticTool };
