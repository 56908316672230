import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'unistore/react';

import { Dropdown } from '@/components/global/Dropdown/Dropdown';
import { Icon } from '@/components/global/Icon/Icon';
import { Link } from '@/components/global/Link/Link';
import { NavigationCategoriesLoggedOutDropdown } from '@/components/global/Navigation/NavigationCategoriesLoggedOutDropdown';
import { NavigationDropdown } from '@/components/global/Navigation/NavigationDropdown';
import { NavigationMobile } from '@/components/global/Navigation/NavigationMobile';
import { NavLink } from '@/components/global/NavLink/NavLink';
import { SearchBarNav } from '@/components/global/SearchBar/SearchBarNav';
import { SubNavigation } from '@/components/global/SubNavigation/SubNavigation';
import { AnimatedWordmark } from '@/components/global/Wordmark/AnimatedWordmark';
import { AB_TEST_DISCOUNT_PERCENT_OFF_DISPLAY } from '@/components/subscription/constants';
import { Avatar } from '@/components/users/Avatar/Avatar';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';
import { Tooltip } from '@/components/design_system/Tooltip/Tooltip';
import { TooltipBehaviour } from '@/components/design_system/Tooltip/TooltipBehaviour';
import { TooltipSize } from '@/components/design_system/Tooltip/TooltipSize';

import { ContrastMode } from '@/enums/ContrastMode';
import { DaisieCustomEvent } from '@/enums/DaisieCustomEvent';
import { IconSize } from '@/enums/IconSize';
import { NavigationStyle } from '@/enums/NavigationStyle';
import { SubNavigationLinkType } from '@/enums/SubNavigationLinkType';
import { SubscriptionInterval } from '@/enums/SubscriptionInterval';
import { TrackingLocation } from '@/enums/TrackingLocation';
import { UserFlairType } from '@/enums/UserFlairType';

import { OptimisedJoinFlowNavButton } from '@/pages/OptimisedJoinFlow/OptimisedJoinFlowNavButton';

import { routes } from '@/routes';
import { parser } from '@/routes/parser';

import { findProductAndPrice } from '@/store/helpers/subscriptionHelpers';
import { channelActions } from '@/store/modules/channel';

import { DISCORD_URL } from '@/utils/constants';
import { isALink } from '@/utils/DOM/dom-testing';
import { enableCareersPage, enableExplore } from '@/utils/featureToggles';
import {
  disableLoggedOutCategoryPages,
  showPriceOnLoggedOutHomepageScroll,
} from '@/utils/featureToggles';
import { t } from '@/utils/i18n/i18n';
import { track } from '@/utils/mixpanel/mixpanel';
import { c } from '@/utils/strings/c';
import { getDisplayPrice } from '@/utils/strings/currency';
import { getRouteFromUrl } from '@/utils/urls/routes';

interface Props
  extends AuthState,
    NotificationsState,
    CategoryActions,
    CategoryState,
    SizingState,
    RoomState,
    ChannelActions,
    ChannelState,
    SubscriptionState,
    WorkshopState,
    ABTestState,
    NotificationsState,
    RouteComponentProps {
  mobileClassName: string;
  page?: string;
  pathname?: string;
  showLogo?: boolean;
  isDownloadAppBannerDismissed?: boolean;
  isDiscordBannerDismissed?: boolean;
}

interface State {
  mobileNavOpen: boolean;
  style: NavigationStyle;
  hideForRoomScroll: boolean;
  showSubscriptionPricing: boolean;
  isSearchBarClicked: boolean;
}

class NavigationComponent extends React.Component<Props, State> {
  public state: State = {
    mobileNavOpen: false,
    style: NavigationStyle.default,
    hideForRoomScroll: false,
    showSubscriptionPricing: false,
    isSearchBarClicked: false,
  };

  private refSearchButton: React.RefObject<HTMLButtonElement>;
  private refSearchTooltip: React.RefObject<Tooltip>;

  private refRabbitHoleLink: React.RefObject<HTMLDivElement>;
  private refRabbitHoleTooltip: React.RefObject<Tooltip>;

  private refDiscordLink: React.RefObject<HTMLDivElement>;
  private refDiscordTooltip: React.RefObject<Tooltip>;

  constructor(props: Props) {
    super(props);

    this.refSearchButton = React.createRef();
    this.refSearchTooltip = React.createRef();

    this.refRabbitHoleLink = React.createRef();
    this.refRabbitHoleTooltip = React.createRef();

    this.refDiscordLink = React.createRef();
    this.refDiscordTooltip = React.createRef();
  }

  public componentDidMount = () => {
    window.addEventListener('popstate', this.escapeListener);
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener(
      DaisieCustomEvent.change_navigation_style,
      this.handleRemoteNavigationStyleChange
    );

    const pageLayout = document.querySelector('.page-layout');

    if (pageLayout) {
      pageLayout.addEventListener('scroll', this.onScrollPageLayout);
    }

    this.checkNavigationStyle();
  };

  // eslint-disable-next-line react/no-deprecated
  public componentWillUpdate = (prevProps: Props) => {
    const { page } = this.props;
    const { page: prevPage } = prevProps;

    if (page !== prevPage) {
      return true;
    }

    return false;
  };

  public componentDidUpdate = (prevProps: Props) => {
    const {
      location: { pathname },
      room: { currentRoom, draftRoom },
      page,
    } = this.props;

    const {
      location: { pathname: prevPathname },
      room: { currentRoom: prevRoom, draftRoom: prevDraftRoom },
      page: prevPage,
    } = prevProps;

    if (pathname !== prevPathname) {
      this.checkNavigationStyle();

      if (this.state.showSubscriptionPricing) {
        this.setState({ showSubscriptionPricing: false });
      }
    }

    if (this.props.room.currentRoom && !prevProps.room.currentRoom) {
      this.checkNavigationStyle();
    }

    if (
      draftRoom &&
      draftRoom.backgroundImage &&
      prevDraftRoom &&
      prevDraftRoom.backgroundImage &&
      draftRoom.backgroundImage.contrastMode !==
        prevDraftRoom.backgroundImage.contrastMode
    ) {
      this.checkNavigationStyle();
    }

    if (
      currentRoom &&
      currentRoom.backgroundImage &&
      prevRoom &&
      prevRoom.backgroundImage &&
      currentRoom.backgroundImage.contrastMode !==
        prevRoom.backgroundImage.contrastMode
    ) {
      this.checkNavigationStyle();
    }

    if (
      page !== 'room' &&
      prevPage === 'room' &&
      this.state.hideForRoomScroll
    ) {
      this.setState({ hideForRoomScroll: false });
    }
  };

  public componentWillUnmount = () => {
    window.removeEventListener('popstate', this.escapeListener);
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener(
      DaisieCustomEvent.change_navigation_style,
      this.handleRemoteNavigationStyleChange
    );

    const pageLayout = document.querySelector('.page-layout');

    if (pageLayout) {
      pageLayout.removeEventListener('scroll', this.onScrollPageLayout);
    }
  };

  private handleRemoteNavigationStyleChange = (e: any) => {
    if (this.state.style === e.detail.style) {
      return;
    }

    this.setState({ style: e.detail.style });
  };

  private roomScrollClassName = () => {
    // Returns the classNames for hiding particular navigation items when in rooms,
    // so they fade out if the room pages will collide with them

    // The `c` function should have `animate-opacity` applied to it

    const { hideForRoomScroll } = this.state;
    const {
      sizing: { isDesktop },
    } = this.props;

    return {
      'opacity-1': !hideForRoomScroll && isDesktop,
      'opacity-0 pointer-events-none': hideForRoomScroll && isDesktop,
    };
  };

  private checkNavigationStyle = () => {
    const { page } = this.props;

    // NavigationStyle.transparent[light|dark]
    const isRoomPage =
      page === 'room' ||
      (page === 'workshop' && !!this.props.room.currentRoom) ||
      page === 'roomsCreate';

    if (isRoomPage) {
      const {
        room: { currentRoom, draftRoom },
      } = this.props;

      if (currentRoom) {
        if (
          currentRoom.backgroundImage &&
          currentRoom.backgroundImage.contrastMode === ContrastMode.light
        ) {
          this.setState({ style: NavigationStyle.transparentLight });
        } else {
          this.setState({ style: NavigationStyle.transparentDark });
        }
      }

      if (draftRoom) {
        if (
          draftRoom.backgroundImage &&
          draftRoom.backgroundImage.contrastMode === ContrastMode.light
        ) {
          this.setState({ style: NavigationStyle.transparentLight });
        } else {
          this.setState({ style: NavigationStyle.transparentDark });
        }
      }
    } else {
      this.setState({ style: NavigationStyle.dark });
    }
  };

  public onScroll = () => {
    const {
      page,
      sizing: { cookieBannerHeight },
      auth: { isAuthorised },
    } = this.props;

    const { showSubscriptionPricing } = this.state;

    const isRoom = page === 'room' || page === 'workshop';

    // Prevent scrolling moodboards from colliding with the nav
    if (isRoom) {
      const roomSubnav = document.querySelector(
        '.dds-cinematic .c-sub-navigation'
      );

      if (!roomSubnav) {
        return;
      }

      const { y } = roomSubnav.getBoundingClientRect();

      if (y < 50 + cookieBannerHeight) {
        if (!this.state.hideForRoomScroll) {
          this.setState({ hideForRoomScroll: true });
        }
      } else {
        if (this.state.hideForRoomScroll) {
          this.setState({ hideForRoomScroll: false });
        }
      }
    }

    // Show subscription pricing after scrolling down
    if (showPriceOnLoggedOutHomepageScroll()) {
      const allowSubscriptionPricing =
        !isAuthorised && (page === 'home' || page === 'categoryIndex');

      if (
        window.scrollY >= window.innerHeight / 2 &&
        !showSubscriptionPricing &&
        allowSubscriptionPricing
      ) {
        this.setState({ showSubscriptionPricing: true });
      }

      if (
        window.scrollY < window.innerHeight / 2 &&
        showSubscriptionPricing &&
        allowSubscriptionPricing
      ) {
        this.setState({ showSubscriptionPricing: false });
      }
    }
  };

  public onScrollPageLayout = () => {
    if (this.props.sizing.cookieBannerHeight === 0) {
      return;
    }

    this.onScroll();
  };

  private escapeListener = () => {
    if (this.state.mobileNavOpen) {
      this.setState({ mobileNavOpen: false });
    }
  };

  private closeMobileNav = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!event || !event.target) return;

    if (isALink(event.target as HTMLElement)) {
      this.setState({ mobileNavOpen: false });
      document.body.classList.remove('prevent-scroll-mobile');
    }
  };

  private wordmark = () => {
    const {
      page,
      auth: { user },
    } = this.props;

    const matchedRoute = getRouteFromUrl(this.props.location.pathname);

    const showInstructorDashboardLink: boolean =
      !!user && user.flair === UserFlairType.mentor;

    return (
      <>
        <NavLink
          exact={true}
          to="/"
          className={c('', {
            'u-fixed': matchedRoute === 'room',
          })}
          onClick={() => {
            if (page === 'home') {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            } else {
              track('go_home', {
                from: TrackingLocation.daisieLogo,
              });
            }
          }}
        >
          <AnimatedWordmark />
        </NavLink>

        {showInstructorDashboardLink && (
          <div
            className={c('ml12 animate-opacity', {
              'opacity-1': page !== 'instructorDashboard',
              'opacity-0 pointer-events-none': page === 'instructorDashboard',
            })}
          >
            <Button
              type={ButtonType.link}
              linkTo={routes.instructorDashboard}
              buttonStyle={ButtonStyle.outlineGreen}
              size={ButtonSize.s}
              text={t('Instructor Dashboard')}
            />
          </div>
        )}
      </>
    );
  };

  private avatarDropdown = (user: User) => {
    const {
      notifications: { unreadNotificationsCount },
    } = this.props;

    return (
      <Dropdown
        side="right"
        width="sm"
        darkerMode={true}
        triggerOnHover={true}
        className={c('ml16 mb4 animate-opacity u-white', {
          ...this.roomScrollClassName(),
        })}
        id="navigation-dropdown"
        trigger={({
          props,
          open,
          openHover,
        }: {
          props: any;
          open: boolean;
          openHover: () => void;
        }) => (
          <Link
            uiTestId="navigation-profile-photo-dropdown"
            to={`/${user.username}`}
            {...{ ...props, onTouchStart: undefined, onClick: undefined }}
            onTouchEnd={(event: TouchEvent) => {
              if (!open) {
                event.preventDefault();
                openHover();
              }
            }}
          >
            <div className="relative u-block">
              <Avatar
                size="large"
                alt={user.primaryName}
                src={user.avatar}
                user={user}
              />
              {!!unreadNotificationsCount && (
                <span className="c-navigation__dot c-navigation__dot--dropdown" />
              )}
            </div>
          </Link>
        )}
        children={({ close }: { close: () => void }) => (
          <div
            className="pv24 ph24"
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              if (isALink(event.target as HTMLElement)) {
                close();
              }
            }}
          >
            <NavigationDropdown />
          </div>
        )}
      />
    );
  };

  private logInAndSignUpButtons = (fadeOutLogin?: boolean) => {
    const {
      page,
      location: { pathname },
      workshop: { currentWorkshop: workshop },
      abtest: { variant },
    } = this.props;

    const { style, showSubscriptionPricing } = this.state;

    const useDarkNav =
      style === NavigationStyle.transparentDark ||
      style === NavigationStyle.dark;

    const useTransparentLight = style === NavigationStyle.transparentLight;
    const useTransparentDark = style === NavigationStyle.transparentDark;
    const isTransparent = useTransparentDark || useTransparentLight;

    const linkFontColor = {
      'u-link-black': (!useDarkNav && !isTransparent) || useTransparentDark,
      'u-link-white u-link-white--alt':
        useTransparentLight || (useDarkNav && !isTransparent),
    };

    const workshopUrl = !!workshop
      ? parser({
          name: 'workshop',
          params: { workshopSlug: workshop.slug },
        })
      : undefined;

    const loginRoute =
      page && page === 'room'
        ? `${routes.login}?to=${encodeURIComponent(pathname)}`
        : page && page === 'workshop' && workshop
        ? `${routes.login}${
            workshopUrl ? `?to=${encodeURIComponent(workshopUrl)}` : ''
          }`
        : routes.login;

    const registerRoute =
      page && page === 'room'
        ? `${routes.register}?to=${encodeURIComponent(pathname)}`
        : page && page === 'workshop' && workshop && variant === 2
        ? `${routes.register}${
            workshopUrl
              ? `?to=${encodeURIComponent(workshopUrl)}&isFromWorkshop=true`
              : ''
          }`
        : page && page === 'workshop' && workshop
        ? `${routes.register}${
            workshopUrl ? `?to=${encodeURIComponent(workshopUrl)}` : ''
          }`
        : routes.register;

    const joinButtonText: string =
      page === 'students'
        ? t('Get started')
        : typeof variant === 'undefined'
        ? ''
        : variant === 0
        ? t('Try for free')
        : variant === 1
        ? t('Get started')
        : // : variant === 2 && page !== 'workshop'
          // ? t(`Get ${AB_TEST_DISCOUNT_PERCENT_OFF_DISPLAY}% off`)
          '';

    const joinButtonLink: string =
      page === 'students'
        ? routes.optimisedJoinFlowSignUpYearly
        : registerRoute;

    return (
      <>
        <Link
          className={c('ml32 f-text-2 u-flex-hold', {
            ...linkFontColor,
            'animate-opacity': fadeOutLogin,
            'opacity-0 pointer-events-none':
              fadeOutLogin && showSubscriptionPricing,
            'opacity-1': fadeOutLogin && !showSubscriptionPricing,
          })}
          to={loginRoute}
        >
          {t('Log in')}
        </Link>

        <Button
          type={ButtonType.link}
          linkTo={joinButtonLink}
          text={joinButtonText}
          className="ml32 u-flex-hold"
          onClick={(e: any) => {
            this.closeMobileNav(e);

            const {
              auth: { isAuthorised },
              location: { pathname },
              organisedCategories: { industries },
              subscription: { userCurrency },
            } = this.props;

            // Workshop pricing experiment
            if (!isAuthorised) {
              if (getRouteFromUrl(pathname) === 'categoryIndex') {
                const community = industries.find(
                  (category: Category) =>
                    category.slug === pathname.split('/')[2]
                );

                track('pwe_community_page_win', {
                  currency: userCurrency,
                  communityName: community ? community.name : undefined,
                  source: 'navigation_top',
                });
              } else if (getRouteFromUrl(pathname) === 'workshop') {
                track('pwe_logged_out_workshop_win', {
                  currency: userCurrency,
                  source: 'navigation_top',
                });
              }
            }
          }}
        />
      </>
    );
  };

  private searchBar = () => {
    const { auth } = this.props;
    const { user } = auth;
    const { isSearchBarClicked, style } = this.state;

    return (
      <div className="u-cursor-pointer u-flex u-align-center">
        <Tooltip
          ref={this.refSearchTooltip}
          refAnchor={this.refSearchButton}
          tooltipContent={() => <p className="f-text-3">{t('Search')}</p>}
          dismissBehaviour={TooltipBehaviour.none}
          size={TooltipSize.wrap}
        />

        {user && (
          <>
            <button
              type="button"
              className={c(
                'p8 pv16@m pl16@m pr12@m relative u-flex u-align-center animate-opacity t1',
                {
                  ...this.getNavigationButtonStyles(),
                }
              )}
              onClick={() => this.setState({ isSearchBarClicked: true })}
              ref={this.refSearchButton}
              onMouseOver={() => {
                if (this.refSearchTooltip.current) {
                  this.refSearchTooltip.current.showTooltip();
                }
              }}
              onMouseOut={() => {
                if (this.refSearchTooltip.current) {
                  this.refSearchTooltip.current.dismissTooltip();
                }
              }}
            >
              {!isSearchBarClicked && (
                <Icon
                  id="search"
                  width="22"
                  height="22"
                  className="pointer-events-none"
                />
              )}
            </button>

            {isSearchBarClicked && (
              <SearchBarNav
                onBlur={() => this.setState({ isSearchBarClicked: false })}
                style={style}
                getNavigationButtonStyles={this.getNavigationButtonStyles}
              />
            )}
          </>
        )}
      </div>
    );
  };

  private loggedOutNav = () => {
    const {
      auth: { isAuthorised },
      page,
      subscription: { productsAndPrices, userCurrency },
    } = this.props;

    const { style, showSubscriptionPricing } = this.state;

    const useDarkNav =
      style === NavigationStyle.transparentDark ||
      style === NavigationStyle.dark;

    const useTransparentLight = style === NavigationStyle.transparentLight;
    const useTransparentDark = style === NavigationStyle.transparentDark;
    const isTransparent = useTransparentDark || useTransparentLight;

    const linkFontColor = {
      'u-link-black': (!useDarkNav && !isTransparent) || useTransparentDark,
      'u-link-white u-link-white--alt':
        useTransparentLight || (useDarkNav && !isTransparent),
    };

    const linkClassName = c(
      'ml32 f-text-2 tablet-and-desktop-only animate-opacity',
      {
        ...linkFontColor,
        ...this.roomScrollClassName(),
      }
    );

    const allowSubscriptionPricing =
      !isAuthorised && (page === 'home' || page === 'categoryIndex');

    const monthlyProduct = findProductAndPrice({
      productsAndPrices,
      currency: userCurrency,
      recurringInterval: SubscriptionInterval.month,
    });

    const priceText = monthlyProduct
      ? `${getDisplayPrice(monthlyProduct.currency, monthlyProduct.amount)}/${t(
          'month'
        )}`
      : null;

    return (
      <div className="u-flex u-align-center relative">
        <div
          className={c('u-flex u-align-center', {
            'animate-opacity': allowSubscriptionPricing,
            'opacity-1': allowSubscriptionPricing && !showSubscriptionPricing,
            'opacity-0 pointer-events-none':
              allowSubscriptionPricing && showSubscriptionPricing,
          })}
        >
          {!disableLoggedOutCategoryPages() && (
            <NavigationCategoriesLoggedOutDropdown className={linkClassName} />
          )}

          <Link to={parser({ name: 'calendar' })} className={linkClassName}>
            {t('Calendar')}
          </Link>

          {enableCareersPage() && (
            <Link to={parser({ name: 'careers' })} className={linkClassName}>
              {t('Careers')}
            </Link>
          )}

          <Link to={parser({ name: 'classes' })} className={linkClassName}>
            {t('Classes')}
          </Link>
        </div>

        {allowSubscriptionPricing && (
          <div
            className={c('absolute u-1/1 u-text-right', {
              'animate-opacity': allowSubscriptionPricing,
              'opacity-1': allowSubscriptionPricing && showSubscriptionPricing,
              'opacity-0 pointer-events-none':
                allowSubscriptionPricing && !showSubscriptionPricing,
            })}
            style={{ right: 160 }}
          >
            <p className="u-white">
              {t('Get unlimited access for')} {priceText}
            </p>
          </div>
        )}

        <div
          className={c('u-flex u-align-center animate-opacity', {
            ...this.roomScrollClassName(),
          })}
        >
          {this.logInAndSignUpButtons(true)}
        </div>
      </div>
    );
  };

  private loggedInNav = () => this.searchBar();

  private rabbitHole = () => (
    <Link
      className="u-link-grey"
      to={routes.cryptoSignUp}
      onMouseOver={() => {
        if (this.refRabbitHoleTooltip.current) {
          this.refRabbitHoleTooltip.current.showTooltip();
        }
      }}
      onMouseOut={() => {
        if (this.refRabbitHoleTooltip.current) {
          this.refRabbitHoleTooltip.current.dismissTooltip();
        }
      }}
    >
      <div ref={this.refRabbitHoleLink}>
        <Tooltip
          ref={this.refRabbitHoleTooltip}
          refAnchor={this.refRabbitHoleLink}
          tooltipContent={() => (
            <p className="f-text-3 u-charcoal-grey">{t('Follow me')}</p>
          )}
          dismissBehaviour={TooltipBehaviour.none}
          size={TooltipSize.wrap}
        />
        <Icon
          id="hole"
          size={IconSize.ml}
          className="mt12 mr8 pointer-events-none"
        />
      </div>
    </Link>
  );

  private discord = () => (
    <a
      className="u-link-grey"
      target="_blank"
      rel="noreferrer"
      href={DISCORD_URL}
      onMouseOver={() => {
        if (this.refDiscordTooltip.current) {
          this.refDiscordTooltip.current.showTooltip();
        }
      }}
      onMouseOut={() => {
        if (this.refDiscordTooltip.current) {
          this.refDiscordTooltip.current.dismissTooltip();
        }
      }}
    >
      <div
        ref={this.refDiscordLink}
        className="p8 pv16@m pl16@m pr12@m relative u-flex u-align-center animate-opacity"
      >
        <Tooltip
          ref={this.refDiscordTooltip}
          refAnchor={this.refDiscordLink}
          tooltipContent={() => (
            <p className="f-text-3 u-charcoal-grey">{t('Daisie Discord')}</p>
          )}
          dismissBehaviour={TooltipBehaviour.none}
          size={TooltipSize.wrap}
        />
        <Icon id="discord" size={IconSize.m} className="pointer-events-none" />
      </div>
    </a>
  );

  private getNavigationButtonStyles = () => {
    const { style } = this.state;

    return {
      'u-link-grey':
        style === NavigationStyle.default || style === NavigationStyle.dark,
      'u-link-white u-link-white--alt':
        style === NavigationStyle.transparentLight,
      'u-link-black--alt': style === NavigationStyle.transparentDark,
    };
  };

  private tabs = () => {
    const {
      auth: { isAuthorised },
    } = this.props;

    const matchedRoute = getRouteFromUrl(this.props.location.pathname);

    if (
      !isAuthorised ||
      matchedRoute === 'login' ||
      matchedRoute === 'register'
    ) {
      return null;
    }

    const { style } = this.state;

    const useDarkNav =
      style === NavigationStyle.transparentDark ||
      style === NavigationStyle.dark;

    const useTransparentLight = style === NavigationStyle.transparentLight;
    const useTransparentDark = style === NavigationStyle.transparentDark;
    const isTransparent = useTransparentDark || useTransparentLight;

    const linkFontColorActive = c('', {
      'u-white': style === NavigationStyle.dark,
    });

    const linkFontColorInactive = c('', {
      'u-link-dark-grey--alt': useDarkNav && !isTransparent,
      'u-link-black': (!useDarkNav && !isTransparent) || useTransparentDark,
      'u-link-white u-link-white--alt': useTransparentLight,
    });

    return (
      <div
        className={c(
          'u-flex u-align-center absolute absolute--mid-center animate-opacity',
          {
            ...this.roomScrollClassName(),
          }
        )}
      >
        <SubNavigation
          classNameLink="u-flex u-justify-center pb2 f-text-1"
          classNameSpacing="mr16 mr32@m"
          classNameLinkActive={linkFontColorActive}
          classNameLinkInactive={linkFontColorInactive}
          classNameContainer="u-flex-1"
          classNameLine="u-hide"
          noHighlight={true}
          links={
            enableExplore()
              ? [
                  {
                    type: SubNavigationLinkType.route,
                    text: t('Home'),
                    to: routes.home,
                  },
                  {
                    type: SubNavigationLinkType.route,
                    text: t('Calendar'),
                    to: routes.calendar,
                  },
                  {
                    type: SubNavigationLinkType.route,
                    text: t('Classes'),
                    to: routes.classes,
                  },
                  {
                    type: SubNavigationLinkType.route,
                    text: t('Explore'),
                    to: routes.explore,
                    // iconId: 'search',
                    // iconSide: 'left',
                    // iconClassName: 'mr8 mt2 mt4@m',
                  },
                ]
              : [
                  {
                    type: SubNavigationLinkType.route,
                    text: t('Home'),
                    to: routes.home,
                  },
                  {
                    type: SubNavigationLinkType.route,
                    text: t('Calendar'),
                    to: routes.calendar,
                  },
                  {
                    type: SubNavigationLinkType.route,
                    text: t('Classes'),
                    to: routes.classes,
                  },
                ]
          }
        />
      </div>
    );
  };

  public render = () => {
    const {
      auth,
      page,
      sizing: {
        isMobile,
        cookieBannerHeight,
        downloadAppBannerHeight,
        discordBannerHeight,
      },
    } = this.props;

    const { user } = auth;

    const { style } = this.state;

    const isDownloadAppBannerDismissed = localStorage.getItem(
      'dismissed_download_app_banner'
    );

    const isDiscordBannerDismissed = localStorage.getItem(
      'dismissed_discord_banner'
    );

    const matchedRoute = getRouteFromUrl(this.props.location.pathname);

    const joinFlowRoutes: boolean =
      matchedRoute === 'optimisedJoinFlowSignUpYearlyPay' ||
      matchedRoute === 'optimisedJoinFlowSignUpMonthlyPay';

    const cryptoSignUpRoute: boolean = matchedRoute === 'cryptoSignUp';

    const noNavOnPages = ['onboarding'];

    const hideNav = page && noNavOnPages.includes(page);

    const isCookieBannerPresent =
      cookieBannerHeight && cookieBannerHeight !== 0;

    const isDiscordBannerPresent =
      discordBannerHeight > 0 && !isDiscordBannerDismissed;

    const isCookieAndDiscordBannerBothPresent =
      isCookieBannerPresent && isDiscordBannerPresent;

    if (hideNav) return null;

    return (
      <header
        role="banner"
        className={c('top pv8', {
          absolute: matchedRoute === 'room',
          'u-fixed': matchedRoute !== 'room',
          'navigation-menu-open': !!this.state.mobileNavOpen,
          'header--transparent':
            (joinFlowRoutes && isMobile) || cryptoSignUpRoute,
          'header--dark':
            joinFlowRoutes || style === NavigationStyle.dark || isMobile,
          'header--transparent-dark': style === NavigationStyle.transparentDark,
          'header--transparent-light':
            style === NavigationStyle.transparentLight,
        })}
        style={{
          top: isMobile
            ? isDiscordBannerPresent
              ? discordBannerHeight
              : downloadAppBannerHeight > 0 &&
                matchedRoute === 'home' &&
                !isDownloadAppBannerDismissed
              ? downloadAppBannerHeight
              : undefined
            : isCookieAndDiscordBannerBothPresent
            ? discordBannerHeight + cookieBannerHeight
            : isDiscordBannerPresent
            ? discordBannerHeight
            : isCookieBannerPresent
            ? cookieBannerHeight
            : undefined,
        }}
        onClick={() => {
          !!this.state.mobileNavOpen ? this.closeMobileNav : undefined;
        }}
      >
        {isMobile ? (
          <NavigationMobile
            page={page}
            isDownloadAppBannerDismissed={
              this.props.isDownloadAppBannerDismissed
            }
            isDiscordBannerDismissed={this.props.isDiscordBannerDismissed}
          />
        ) : (
          <nav
            role="navigation"
            className="wrap u-1/1 u-h-100 u-flex u-justify-between"
          >
            <div className="u-flex u-align-center">{this.wordmark()}</div>
            {this.tabs()}
            <div className="u-flex u-align-center">
              {!user ? this.loggedOutNav() : this.loggedInNav()}
              {user && this.avatarDropdown(user)}
              {user && user.subscriptionTier === null && (
                <OptimisedJoinFlowNavButton className="ml32" />
              )}
            </div>
          </nav>
        )}
      </header>
    );
  };
}

export const Navigation = connect(
  [
    'auth',
    'organisedCategories',
    'sizing',
    'room',
    'channel',
    'subscription',
    'workshop',
    'abtest',
    'notifications',
  ],
  (store: GlobalStoreState) => ({
    ...channelActions(store),
  })
)(withRouter(NavigationComponent));
