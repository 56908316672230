import * as React from 'react';

import { NavLink as RouterLink } from 'react-router-dom';

import { parser } from '@/routes/parser';

interface NavLinkProps extends LinkProps {
  exact: boolean;
  activeClassName?: string;
  isActive?: (match: any, location: any) => any;
}

const NavLink: React.FunctionComponent<NavLinkProps> = (
  props: NavLinkProps
) => {
  const { exact, ...rest } = props;
  const link = typeof props.to === 'string' ? props.to : parser(props.to);
  return (
    <RouterLink {...rest} to={link} exact={exact}>
      {props.children || null}
    </RouterLink>
  );
};

export { NavLink };
