import Loadable from 'react-loadable';

import { CreateLoader } from '@/utils/loader/loader';

/* PAGES */

export const About = Loadable({
  loader: () => import('@/pages/About/About'),
  ...CreateLoader('@/pages/About/About', 'About'),
});

export const Account = Loadable({
  loader: () => import('@/pages/Account/Account'),
  ...CreateLoader('@/pages/Account/Account', 'Account'),
});

export const Category = Loadable({
  loader: () => import('@/pages/Category/Category'),
  ...CreateLoader('@/pages/Category/Category', 'Category'),
});

export const Calendar = Loadable({
  loader: () => import('@/pages/Calendar/Calendar'),
  ...CreateLoader('@/pages/Calendar/Calendar', 'Calendar'),
});

export const Home = Loadable({
  loader: () => import('@/pages/Home/Home'),
  ...CreateLoader('@/pages/Home/Home', 'Home'),
});

export const Legal = Loadable({
  loader: () => import('@/pages/Legal/Legal'),
  ...CreateLoader('@/pages/Legal/Legal', 'Legal'),
});

export const Notifications = Loadable({
  loader: () => import('@/pages/Notifications/Notifications'),
  ...CreateLoader('@/pages/Notifications/Notifications', 'Notifications'),
});

export const Profile = Loadable({
  loader: () => import('@/pages/Profile/Profile'),
  ...CreateLoader('@/pages/Profile/Profile', 'Profile'),
});

export const PasswordRequest = Loadable({
  loader: () => import('@/pages/PasswordRequest/PasswordRequest'),
  ...CreateLoader('@/pages/PasswordRequest/PasswordRequest', 'PasswordRequest'),
});

export const Search = Loadable({
  loader: () => import('@/pages/Search/Search'),
  ...CreateLoader('@/pages/Search', 'Search'),
});

export const Unsubscribe = Loadable({
  loader: () => import('@/pages/Unsubscribe/Unsubscribe'),
  ...CreateLoader('@/pages/Unsubscribe', 'Unsubscribe'),
});

/* COMPONENTS */

export const AccountSettings: any = Loadable({
  loader: () => import('@/components/accounts/AccountSettings/AccountSettings'),
  ...CreateLoader(
    '@/components/accounts/AccountSettings/AccountSettings',
    'AccountSettings'
  ),
});

export const BlockUser: any = Loadable({
  loader: () => import('@/components/users/BlockUser/BlockUser'),
  ...CreateLoader('@/components/users/BlockUser/BlockUser', 'BlockUser'),
});

export const UnfollowUser: any = Loadable({
  loader: () => import('@/components/users/UnfollowUser/UnfollowUser'),
  ...CreateLoader(
    '@/components/users/UnfollowUser/UnfollowUser',
    'UnfollowUser'
  ),
});

export const DeleteAccount: any = Loadable({
  loader: () => import('@/components/accounts/AccountSettings/DeleteAccount'),
  ...CreateLoader(
    '@/components/projects/ProjectSettings/DeleteAccount',
    'DeleteAccount'
  ),
});

export const EditProfile: any = Loadable({
  loader: () => import('@/components/accounts/AccountSettings/EditProfile'),
  ...CreateLoader(
    '@/components/accounts/AccountSettings/EditProfile',
    'EditProfile'
  ),
});

export const InterestsPreferences: any = Loadable({
  loader: () =>
    import('@/components/accounts/AccountSettings/InterestsPreferences'),
  ...CreateLoader(
    '@/components/accounts/AccountSettings/InterestsPreferences',
    'InterestsPreferences'
  ),
});

export const MarkdownHelp: any = Loadable({
  loader: () => import('@/components/global/MarkdownHelp/MarkdownHelp'),
  delay: 300,
  ...CreateLoader(
    '@/components/global/MarkdownHelp/MarkdownHelp',
    'MarkdownHelp'
  ),
});

export const MarketingPreferencesSettings: any = Loadable({
  loader: () =>
    import(
      '@/components/accounts/AccountSettings/MarketingPreferencesSettings'
    ),
  ...CreateLoader(
    '@/components/accounts/AccountSettings/MarketingPreferencesSettings',
    'MarketingPreferencesSettings'
  ),
});

export const NotificationPreferences: any = Loadable({
  loader: () =>
    import('@/components/accounts/AccountSettings/NotificationPreferences'),
  ...CreateLoader(
    '@/components/accounts/AccountSettings/NotificationPreferences',
    'NotificationPreferences'
  ),
});

export const ReportUser: any = Loadable({
  loader: () => import('@/components/users/ReportUser/ReportUser'),
  ...CreateLoader('@/components/users/ReportUser/ReportUser', 'ReportUser'),
});
