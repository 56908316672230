import React from 'react';

import { Flex, FlexProps, Grid, Image, Link } from '@chakra-ui/react';

import { ArrowTopRightIcon } from '@/components/design_system/AlexJenkinsClass';

import { routes } from '@/routes';

import { colors } from '@/styles/chakra-theme/colors';
import { pxToRem } from '@/styles/chakra-theme/metrics';

import { isDesktop } from '@/utils/DOM/sizing';

interface Props extends FlexProps {}

export const Header = (props: Props) => {
  const menuItems = [
    { label: 'Outcomes', href: '#outcomes' },
    { label: 'Overview', href: '#overview' },
    { label: 'Reviews', href: '#reviews' },
    { label: 'FAQ', href: '#faq' },
    {
      label: 'Enroll',
      href: routes.optimisedJoinFlowChoosePlan,
    },
  ];

  if (!isDesktop()) {
    return (
      <Grid
        templateColumns={{
          base: `repeat(9, ${window.innerWidth / 9}px)`,
          md: `repeat(9, ${window.innerWidth / 9}px)`,
          lg: `repeat(9, ${window.innerWidth / 9}px)`,
          xl: `repeat(12, ${window.innerWidth / 12}px)`,
          '2xl': `repeat(16, ${window.innerWidth / 16}px)`,
        }}
        templateRows={`repeat(2, ${window.innerWidth / 9}px)`}
        bgColor="#000"
        backgroundImage="linear-gradient(#ffffff20 1px, transparent 1px), linear-gradient(to right, #ffffff20 1px, #000 1px)"
        backgroundSize={{
          base: `${window.innerWidth / 9}px ${window.innerWidth / 9}px`,
          md: `${window.innerWidth / 9}px ${window.innerWidth / 9}px`,
          lg: `${window.innerWidth / 9}px ${window.innerWidth / 9}px`,
          xl: `${window.innerWidth / 12}px ${window.innerWidth / 12}px`,
          '2xl': `${window.innerWidth / 16}px ${window.innerWidth / 16}px`,
        }}
      >
        <Link
          href={routes.home}
          style={{
            outline: 'none',
            border: 'none',
            textDecoration: 'none',
            boxShadow: 'none',
          }}
          gridColumn={{ base: '2 / 4', md: '2 / 4' }}
          mt={pxToRem(8)}
          gridRow="2"
        >
          <Image
            alt="daisie_logo"
            src="/AlexJenkinsClass/img/daisie_logo.png"
          />
        </Link>

        <Link
          href={routes.optimisedJoinFlowChoosePlan}
          fontSize={pxToRem(18)}
          bg={colors.purpleGradient}
          color="white"
          fontWeight={400}
          px={pxToRem(8)}
          py={pxToRem(8)}
          gridColumn="-2 / -5"
          gridRow="2 / 3"
          cursor="pointer"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          _hover={{ opacity: 0.9 }}
        >
          Enroll now
          <ArrowTopRightIcon mt={pxToRem(12)} w={pxToRem(24)} h={pxToRem(24)} />
        </Link>
      </Grid>
    );
  }

  return (
    <Flex
      width={window.innerWidth - 240}
      alignItems="center"
      justifyContent="space-between"
      position="fixed"
      top={pxToRem(62)}
      left={{
        xl: pxToRem(window.innerWidth / 12),
        '2xl': pxToRem(window.innerWidth / 16),
      }}
      zIndex={100}
      {...props}
    >
      <Flex
        flexDir="row"
        alignItems="center"
        justifyContent="space-between"
        h="100%"
        w={['100%', 600]}
        px={pxToRem(12)}
        py={pxToRem(6)}
        className="header"
      >
        {menuItems.map((item) => {
          const isEnroll = item.label === 'Enroll';
          return (
            <Link
              display={{ base: 'none', md: 'flex' }}
              key={item.label}
              fontSize={18}
              fontWeight={400}
              px={pxToRem(16)}
              py={pxToRem(8)}
              ml={isEnroll ? pxToRem(16) : 0}
              _hover={{ opacity: 0.8 }}
              cursor="pointer"
              href={item.href}
              className={isEnroll ? 'enrollBtn' : undefined}
              alignItems="center"
            >
              <p>{item.label}</p>
              {isEnroll && (
                <ArrowTopRightIcon
                  ml={pxToRem(32)}
                  mt={pxToRem(10)}
                  w={pxToRem(22)}
                  h={pxToRem(22)}
                />
              )}
            </Link>
          );
        })}
      </Flex>

      <Link
        href={routes.home}
        style={{
          outline: 'none',
          border: 'none',
          textDecoration: 'none',
          boxShadow: 'none',
        }}
      >
        <Image
          display={['none', 'block']}
          alt="daisie_logo"
          src="/AlexJenkinsClass/img/daisie_logo.png"
          h={pxToRem(30)}
        />
      </Link>
    </Flex>
  );
};
