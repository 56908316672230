import * as React from 'react';

import { Icon } from '@/components/global/Icon/Icon';

import { IconSize } from '@/enums/IconSize';

import { c } from '@/utils/strings/c';

interface Props {
  className?: string;
  selectedDate?: DateMonthYear;
  defaultMonthYear?: DateMonthYear;
  minMonthYear?: DateMonthYear;
  maxMonthYear?: DateMonthYear;
  onSelectDate?: (date: DateMonthYear) => void;
  events?: CalendarEvent[];
  onChangeMonth?: (date: DateMonthYear) => void;
  filterEventsByCategory?: Category;
  isLoading?: boolean;
}

interface State {
  currentMonthYear: DateMonthYear;
  isPrevMonthDisabled: boolean;
  isNextMonthDisabled: boolean;
}

interface CalendarDay {
  date: number;
  previousOrNextMonth?: 'previous' | 'next';
  eventsCount?: number;
}

class Calendar extends React.Component<Props, State> {
  private monthLabels: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  private dayLabels: string[] = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

  constructor(props: Props) {
    super(props);

    const currentDate = new Date();

    this.state = {
      currentMonthYear: {
        month: props.defaultMonthYear
          ? props.defaultMonthYear.month
          : currentDate.getMonth(),
        year: props.defaultMonthYear
          ? props.defaultMonthYear.year
          : currentDate.getFullYear(),
      },
      isPrevMonthDisabled: false,
      isNextMonthDisabled: false,
    };
  }

  public componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { minMonthYear, maxMonthYear } = this.props;

    const { minMonthYear: prevMinMonthYear, maxMonthYear: prevMaxMonthYear } =
      prevProps;

    const {
      currentMonthYear: { month: prevMonth },
    } = prevState;

    const {
      currentMonthYear: { month: currentMonth, year: currentYear },
    } = this.state;

    const hasMonthChanged = prevMonth !== currentMonth;
    const hasMinMonthYearChanged = minMonthYear !== prevMinMonthYear;
    const hasMaxMonthYearChanged = maxMonthYear !== prevMaxMonthYear;

    if (hasMonthChanged || hasMinMonthYearChanged || hasMaxMonthYearChanged) {
      const prevMonthYear = this.getPreviousMonth(currentMonth, currentYear);
      const nextMonthYear = this.getNextMonth(currentMonth, currentYear);

      this.setState({
        isPrevMonthDisabled: minMonthYear
          ? this.hasHitMinMonthYearLimit(minMonthYear, prevMonthYear)
          : false,
        isNextMonthDisabled: maxMonthYear
          ? this.hasHitMaxMonthYearLimit(maxMonthYear, nextMonthYear)
          : false,
      });
    }
  };

  private getWeeksForMonth = (
    displayMonth: number,
    displayYear: number,
    startFrom: number = 1,
    weeks: (number | null)[][] = []
  ): (number | null)[][] => {
    // Uses recursion to create an array of seven-element arrays that represent
    // each day and each week in a calendar month

    const month = new Date();
    month.setDate(1);
    month.setMonth(displayMonth);
    month.setFullYear(displayYear);
    month.setHours(0, 0, 0, 0);

    const firstDayOfMonth = new Date(month.getFullYear(), month.getMonth(), 1);
    const lastDayOfMonth = new Date(
      month.getFullYear(),
      month.getMonth() + 1,
      0
    );

    // TODO: refactor calculating the beginning of the month
    if (startFrom === 1) {
      // Javascript .getDay() returns Sunday = 0, so set a variable that translates
      // this to Monday = 0 so it works better when rendering Monday as the start of the week
      const fixedDayOfWeek =
        firstDayOfMonth.getDay() === 0 ? 6 : firstDayOfMonth.getDay() - 1;

      const week = Array.from(Array(7).keys()).map((_, key: number) => {
        if (key < fixedDayOfWeek) {
          return null;
        } else {
          return key - fixedDayOfWeek + 1;
        }
      });

      const nextStartFrom = week[week.length - 1]! + 1;

      return this.getWeeksForMonth(displayMonth, displayYear, nextStartFrom, [
        ...weeks,
        week,
      ]);
    } else {
      let hasReachedEndOfMonth: boolean = false;

      const week = Array.from(Array(7).keys()).map((_, key: number) => {
        const day = startFrom + key;

        if (day > lastDayOfMonth.getDate()) {
          hasReachedEndOfMonth = true;
          return null;
        } else {
          return startFrom + key;
        }
      });

      if (hasReachedEndOfMonth) {
        return [...weeks, week].filter(
          (f: (number | null)[]) =>
            !(!!f[0] === false && !!f[f.length - 1] === false)
        );
      } else {
        const nextStartFrom = week[week.length - 1]! + 1;
        return this.getWeeksForMonth(displayMonth, displayYear, nextStartFrom, [
          ...weeks,
          week,
        ]);
      }
    }
  };

  private getLastDayOfPreviousMonth = (
    currentMonth: number,
    currentYear: number
  ) => {
    const lastDayOfPreviousMonth = new Date();
    lastDayOfPreviousMonth.setDate(1);
    lastDayOfPreviousMonth.setMonth(currentMonth);
    lastDayOfPreviousMonth.setFullYear(currentYear);
    lastDayOfPreviousMonth.setHours(0, 0, 0, 0);
    lastDayOfPreviousMonth.setDate(lastDayOfPreviousMonth.getDate() - 1);

    return lastDayOfPreviousMonth;
  };

  private fillOutMonth = (
    weeks: (number | null)[][],
    currentMonth: number,
    currentYear: number
  ): CalendarDay[][] =>
    weeks.map((week: (number | null)[], index: number) => {
      // Get the number of days that belong to another month
      const emptyDays = week.filter((w: number | null) => w === null).length;

      if (index === 0) {
        // Handle first week
        const lastDayOfPreviousMonth = this.getLastDayOfPreviousMonth(
          currentMonth,
          currentYear
        );

        const filledOutWeek = week.map((w: number | null, index: number) => {
          if (w === null) {
            return {
              date:
                lastDayOfPreviousMonth.getDate() - (emptyDays - (index + 1)),
              previousOrNextMonth: 'previous',
            };
          } else {
            return {
              date: w,
            };
          }
        }) as CalendarDay[];

        return filledOutWeek;
      } else if (index === weeks.length - 1) {
        // Handle last week
        const filledOutWeek = week.map((w: number | null, index: number) => {
          if (w === null) {
            return {
              date: index + 1 - (7 - emptyDays),
              previousOrNextMonth: 'next',
            };
          } else {
            return {
              date: w,
            };
          }
        }) as CalendarDay[];

        return filledOutWeek;
      } else {
        // Full week, return original
        return week.map((w: number | null) => ({
          date: w,
        })) as CalendarDay[];
      }
    });

  private addEvents = (weeks: CalendarDay[][]) => {
    const { events, filterEventsByCategory } = this.props;

    if (!events) {
      return weeks;
    }

    const {
      currentMonthYear: { month, year },
    } = this.state;

    return weeks.map((week: CalendarDay[]) =>
      week.map((day: CalendarDay) => {
        const thisDayStart = new Date(year, month, day.date, 0, 0, 0, 0);
        const thisDayEnd = new Date(year, month, day.date, 23, 59, 59, 999);

        if (day.previousOrNextMonth === 'next') {
          const nextMonth = month + 1 === 12 ? 0 : month + 1;
          const nextMonthYear = nextMonth === 0 ? year + 1 : year;

          thisDayStart.setFullYear(nextMonthYear);
          thisDayStart.setMonth(nextMonth);
          thisDayEnd.setFullYear(nextMonthYear);
          thisDayEnd.setMonth(nextMonth);
        } else if (day.previousOrNextMonth === 'previous') {
          const previousMonth = month - 1 === -1 ? 11 : month - 1;
          const previousMonthYear = previousMonth === 1 ? year - 1 : year;

          thisDayStart.setFullYear(previousMonthYear);
          thisDayStart.setMonth(previousMonth);
          thisDayEnd.setFullYear(previousMonthYear);
          thisDayEnd.setMonth(previousMonth);
        }

        const eventsThisDay = events
          .filter(
            (ce: CalendarEvent) =>
              ce.start.getTime() >= thisDayStart.getTime() &&
              ce.start.getTime() < thisDayEnd.getTime()
          )
          .filter((ce: CalendarEvent) => {
            if (filterEventsByCategory) {
              if (!!ce.workshop) {
                return ce.workshop.workshop.categories
                  .map((c: Category) => c.id)
                  .includes(filterEventsByCategory.id);
              } else if (!!ce.room) {
                return ce.room.room.industries
                  .map((c: Category) => c.id)
                  .includes(filterEventsByCategory.id);
              } else {
                return false;
              }
            } else {
              return true;
            }
          });

        return {
          ...day,
          eventsCount: eventsThisDay.length > 4 ? 4 : eventsThisDay.length,
        };
      })
    );
  };

  private renderMonth = () => {
    const {
      currentMonthYear: { month, year },
    } = this.state;

    const { selectedDate, isLoading } = this.props;

    const weeks = this.addEvents(
      this.fillOutMonth(this.getWeeksForMonth(month, year), month, year)
    );

    const now: Date = new Date();

    return (
      <div className="u-flex u-flex-column">
        {weeks.map((week: CalendarDay[], keyWeek: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={keyWeek} className="u-flex">
            {week.map((dayOfWeek: CalendarDay, keyDay: number) => {
              const isSelected =
                selectedDate &&
                selectedDate.date === dayOfWeek.date &&
                selectedDate.month === month &&
                selectedDate.year === year &&
                !dayOfWeek.previousOrNextMonth;

              const dayOfWeekMonth: number =
                dayOfWeek.previousOrNextMonth === 'next'
                  ? month === 11
                    ? 0
                    : month + 1
                  : dayOfWeek.previousOrNextMonth === 'previous'
                  ? month === 0
                    ? 11
                    : month - 1
                  : month;

              const dayOfWeekYear: number =
                dayOfWeek.previousOrNextMonth === 'next'
                  ? month === 11
                    ? year + 1
                    : year
                  : dayOfWeek.previousOrNextMonth === 'previous'
                  ? month === 0
                    ? year - 1
                    : year
                  : year;

              const isToday: boolean =
                now.getDate() === dayOfWeek.date &&
                now.getMonth() === dayOfWeekMonth &&
                now.getFullYear() === dayOfWeekYear;

              return (
                <button
                  // eslint-disable-next-line react/no-array-index-key
                  key={keyDay}
                  type="button"
                  className={c(
                    'dds-calendar__day f-text-2 u-flex-1 u-text-center',
                    {
                      'dds-calendar__day--today': isToday,
                      'dds-calendar__day--selected u-bold': isSelected,
                      'dds-calendar__day--previous-or-next':
                        !!dayOfWeek.previousOrNextMonth,
                      'dds-calendar__day--no-events pointer-events-none':
                        dayOfWeek.eventsCount === 0 && !isLoading,
                    }
                  )}
                  onClick={() => {
                    if (
                      isLoading ||
                      (dayOfWeek.eventsCount === 0 && !isLoading)
                    ) {
                      return;
                    }

                    const nextMonth =
                      dayOfWeek.previousOrNextMonth === 'previous'
                        ? month === 0
                          ? 11
                          : month - 1
                        : dayOfWeek.previousOrNextMonth === 'next'
                        ? month === 11
                          ? 0
                          : month + 1
                        : month;

                    const nextYear =
                      dayOfWeek.previousOrNextMonth === 'previous'
                        ? month === 0
                          ? year - 1
                          : year
                        : dayOfWeek.previousOrNextMonth === 'next'
                        ? month === 11
                          ? year + 1
                          : year
                        : year;

                    if (this.props.onSelectDate) {
                      this.props.onSelectDate({
                        date: dayOfWeek.date,
                        month: nextMonth,
                        year: nextYear,
                      });
                    }
                  }}
                >
                  <span>{dayOfWeek.date}</span>
                  <div className="dds-calendar__dots">
                    {!!dayOfWeek.eventsCount
                      ? Array.from(Array(dayOfWeek.eventsCount).keys()).map(
                          (_, key: number) => (
                            <div
                              // eslint-disable-next-line react/no-array-index-key
                              key={key}
                              className="dds-calendar__dots__dot"
                            />
                          )
                        )
                      : null}
                  </div>
                </button>
              );
            })}
          </div>
        ))}
      </div>
    );
  };

  private getPreviousMonth = (currentMonth: number, currentYear: number) => ({
    month: currentMonth - 1 < 0 ? 11 : currentMonth - 1,
    year: currentMonth - 1 < 0 ? currentYear - 1 : currentYear,
  });

  private hasHitMinMonthYearLimit = (
    minMonthYear: DateMonthYear,
    nextMonthYear: DateMonthYear
  ) =>
    (nextMonthYear.month < minMonthYear.month &&
      nextMonthYear.year === minMonthYear.year) ||
    (minMonthYear.month === 0 && nextMonthYear.year < minMonthYear.year);

  private getNextMonth = (currentMonth: number, currentYear: number) => ({
    month: currentMonth + 1 > 11 ? 0 : currentMonth + 1,
    year: currentMonth + 1 > 11 ? currentYear + 1 : currentYear,
  });

  private hasHitMaxMonthYearLimit = (
    maxMonthYear: DateMonthYear,
    nextMonthYear: DateMonthYear
  ) =>
    (nextMonthYear.month > maxMonthYear.month &&
      nextMonthYear.year === maxMonthYear.year) ||
    (maxMonthYear.month === 11 && nextMonthYear.year !== maxMonthYear.year);

  public prevMonth = () => {
    const {
      currentMonthYear: { month, year },
    } = this.state;

    const { minMonthYear, isLoading } = this.props;

    if (isLoading) {
      return;
    }

    const newCurrentMonthYear = this.getPreviousMonth(month, year);

    if (
      minMonthYear &&
      this.hasHitMinMonthYearLimit(minMonthYear, newCurrentMonthYear)
    ) {
      return;
    }

    this.setState({ currentMonthYear: newCurrentMonthYear }, () => {
      if (this.props.onChangeMonth) {
        this.props.onChangeMonth(newCurrentMonthYear);
      }
    });
  };

  public nextMonth = () => {
    const {
      currentMonthYear: { month, year },
    } = this.state;

    const { maxMonthYear, isLoading } = this.props;

    if (isLoading) {
      return;
    }

    const newCurrentMonthYear = this.getNextMonth(month, year);

    if (
      maxMonthYear &&
      this.hasHitMaxMonthYearLimit(maxMonthYear, newCurrentMonthYear)
    ) {
      return;
    }

    this.setState({ currentMonthYear: newCurrentMonthYear }, () => {
      if (this.props.onChangeMonth) {
        this.props.onChangeMonth(newCurrentMonthYear);
      }
    });
  };

  public setMonthYear = (monthYear: DateMonthYear) =>
    this.setState({ currentMonthYear: monthYear });

  private renderHeaderAndButtons = () => {
    const { currentMonthYear, isPrevMonthDisabled, isNextMonthDisabled } =
      this.state;

    return (
      <div className="u-flex u-split bb bb--very-translucent-white pb24 mb24">
        <p className="f-title-2">
          <span className="u-bold">
            {this.monthLabels[currentMonthYear.month]}
          </span>{' '}
          {currentMonthYear.year}
        </p>

        <div className="u-flex">
          <button
            type="button"
            onClick={this.prevMonth}
            className="dds-calendar__month_selector mr12"
            disabled={isPrevMonthDisabled}
          >
            <Icon
              id="chevron-left"
              size={IconSize.xs}
              className="u-black mr2"
            />
          </button>
          <button
            type="button"
            onClick={this.nextMonth}
            className="dds-calendar__month_selector"
            disabled={isNextMonthDisabled}
          >
            <Icon
              id="chevron-right"
              size={IconSize.xs}
              className="u-black ml2"
            />
          </button>
        </div>
      </div>
    );
  };

  private renderDayLabels = () => (
    <div className="u-flex mb16">
      {this.dayLabels.map((label: string, key: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <p key={key} className="f-text-3 u-flex-1 u-text-center u-grey">
          {label}
        </p>
      ))}
    </div>
  );

  public render = () => {
    const { className = '', isLoading } = this.props;

    return (
      <div
        className={c(['dds-calendar u-white animate-opacity', className], {
          'opacity-1': !isLoading,
          'u-translucent pointer-events-none': isLoading,
        })}
      >
        {this.renderHeaderAndButtons()}
        {this.renderDayLabels()}
        {this.renderMonth()}
      </div>
    );
  };
}

export { Calendar };
