import * as React from 'react';

import { Icon } from '@/components/global/Icon/Icon';

import { IconSize } from '@/enums/IconSize';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface State {
  inputLength: number;
  active: boolean;
  showMessage: boolean;
}

interface Props {
  name?: string;
  required?: boolean;
  value?: string | number;
  showMaxLength?: number;
  showMaxLengthInset?: boolean;
  hardMaxLength?: number;
  clearable?: boolean;
  inputClassName?: string;
  onClear?: () => void;
  errorStyle?: string;
  successMessage?: string;
  checking?: boolean;
  delay?: boolean;
  ref?: React.RefObject<any>;
  onPressEnter?: () => void;
  autoFocus?: boolean;
  errorOutputStyle?: string;
  labelClassName?: string;
  uiTestId?: string;
  onClick?: () => void;
  refContainer?: React.RefObject<HTMLDivElement>;
  darkMode?: boolean;
}

class Input extends React.Component<
  Props & InputGroupProps & Omit<React.HTMLProps<HTMLInputElement>, 'onChange'>,
  State
> {
  public inputElement: React.RefObject<HTMLInputElement>;
  private timerDelay: NodeJS.Timeout | null;

  public state = {
    inputLength: 0,
    active: false,
    showMessage: false,
  };

  constructor(
    props: Props &
      InputGroupProps &
      Omit<React.HTMLProps<HTMLInputElement>, 'onChange'>
  ) {
    super(props);

    this.inputElement = React.createRef();
    this.timerDelay = null;
  }

  public componentDidMount = () => {
    this.updateInputLength();
  };

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.checking && !this.props.checking) {
      if (this.props.delay) {
        if (this.timerDelay) {
          clearTimeout(this.timerDelay);
        }

        this.timerDelay = setTimeout(() => {
          this.setState({ showMessage: true });
        }, 300);
      } else {
        this.setState({ showMessage: true });
      }
    }
  }

  private handleChange = (event: any) => {
    this.updateInputLength();
    this.props.onChange({ value: event.target.value, event });

    if (this.timerDelay) {
      clearTimeout(this.timerDelay);
    }
  };

  private harvest = (event: any) => {
    const { current } = this.inputElement;

    if (current) {
      this.updateInputLength();
      this.props.onChange({ value: '', event });
      if (this.props.onClear) {
        this.props.onClear();
      }
      current.focus();
    }
  };

  private updateInputLength = () => {
    if (this.inputElement.current) {
      this.setState({
        inputLength: this.inputElement.current.value.length,
      });
    }
  };

  private renderMaxLength = (className: string[] = []) => {
    const { inputLength, active } = this.state;
    const { showMaxLength } = this.props;

    if (!showMaxLength) {
      return null;
    }

    return (
      <span
        className={c(['f-text-4 u-dark-grey', ...className], {
          'u-danger': Math.sign(showMaxLength - inputLength) === -1,
          'u-hide': !active || inputLength > showMaxLength,
        })}
      >
        {inputLength > showMaxLength
          ? showMaxLength - inputLength
          : `${inputLength} / ${showMaxLength}`}
      </span>
    );
  };

  public render() {
    const {
      error,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onChange,
      className = '',
      label,
      id,
      type,
      name,
      containerClassName = '',
      showMaxLength,
      hardMaxLength,
      clearable = false,
      inputClassName = 'mb12',
      value,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onClear,
      errorStyle,
      disabled,
      successMessage,
      delay,
      onPressEnter,
      autoFocus = false,
      showMaxLengthInset,
      errorOutputStyle,
      labelClassName = '',
      uiTestId,
      onClick,
      refContainer,
      darkMode,
      ...rest
    } = this.props;

    const { showMessage, inputLength } = this.state;

    return (
      <div
        ref={refContainer}
        className={c(['input-group', containerClassName], {
          'input-group--error-alt':
            successMessage && !error && inputLength > 0 && showMessage,
          [errorStyle ? errorStyle : 'input-group--error']: error,
          mb44: !showMessage && successMessage && !error,
        })}
      >
        {(label || showMaxLength) && !showMaxLengthInset && (
          <div
            className={c('u-flex u-justify-between u-align-center mb8', {
              'u-light-grey': darkMode,
            })}
          >
            {label && (
              <label
                htmlFor={id}
                children={t(label)}
                className={labelClassName}
              />
            )}
            {this.renderMaxLength()}
          </div>
        )}
        <div className={c(['relative', className])}>
          <input
            type={type || 'text'}
            name={name || id}
            id={name || id}
            ref={this.inputElement}
            onFocus={() => this.setState({ active: true })}
            onBlur={() => this.setState({ active: false })}
            onChange={this.handleChange}
            className={c(['bv bh', inputClassName], {
              pr32: clearable && !!value,
              'dds-input--disabled': disabled,
            })}
            value={value}
            onKeyUp={(e) => {
              if (onPressEnter && e.keyCode === 13) {
                onPressEnter();
              }
            }}
            disabled={disabled}
            autoFocus={autoFocus}
            maxLength={hardMaxLength}
            data-ui-test-id={isUiTest() && uiTestId ? uiTestId : undefined}
            onClick={onClick}
            {...rest}
          />
          {clearable && value && (
            <button
              type="button"
              className="absolute absolute--tr u-h-100 u-flex u-align-center ph16"
              disabled={disabled}
              onClick={this.harvest}
            >
              <Icon id="clear" size={IconSize.xs} className="u-grey" />
            </button>
          )}
          {showMaxLength &&
            showMaxLengthInset &&
            this.renderMaxLength(['absolute absolute--br u-black mb8 mr8'])}
        </div>

        {(error && !delay) || (error && delay && showMessage) ? (
          <output className={errorOutputStyle}>{error}</output>
        ) : null}

        {successMessage && !error && inputLength > 0 && showMessage ? (
          <output>{successMessage}</output>
        ) : null}
      </div>
    );
  }
}

export { Input };
