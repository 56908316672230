import { getNotificationsFromData } from '@/store/helpers';

import { Http } from '@/utils/api/Http';

const notificationsState: NotificationsState = {
  notifications: {
    notifications: {},
    unreadNotificationsCount: 0,
  },
};

const notificationsActions = (store: any) => ({
  clearNotifications: async () => ({
    notifications: {
      ...store.getState().notifications,
      notifications: {},
    },
  }),

  fetchNotifications: async (state: any, pageNumber?: number) => {
    if (state.auth.user === undefined) {
      return state;
    }

    let notifications: { [key: string]: DaisieNotification[] } = {};
    const page = pageNumber || '1';

    try {
      const { data } = await new Http(
        `/users/me/notifications?pageSize=21&pageNumber=${page}`
      ).get<APIArray<APINotification>>();

      if (!data) throw new Error();

      notifications = {
        ...store.getState().notifications.notifications,
        [Number(page) - 1]: getNotificationsFromData(data),
      };
    } catch (e) {
      throw e;
    }

    return {
      notifications: {
        ...store.getState().notifications,
        notifications,
      },
    };
  },

  markPageAsRead: async (state: GlobalStoreState, pageNumber?: string) => {
    if (state.auth.user === undefined) {
      return state;
    }

    const page = pageNumber || '1';

    let pageNotifications = store.getState().notifications.notifications[page];

    if (pageNotifications) {
      const unreadIds = pageNotifications
        .filter((n: any) => !n.read)
        .map((n: any) => ({ id: n.id }));

      if (unreadIds.length) {
        await new Http('/users/me/notifications/read').post({
          data: unreadIds,
        });

        pageNotifications = pageNotifications.map((n: any) => ({
          ...n,
          read: true,
        }));
      }
    }

    return {
      notifications: {
        ...store.getState().notifications,
        notifications: {
          ...store.getState().notifications.notifications,
          [Number(page) - 1]: pageNotifications,
        },
      },
    };
  },

  markAllAsRead: async (state: GlobalStoreState) => {
    if (state.auth.user === undefined) {
      return state;
    }

    const notificationPages = store.getState().notifications.notifications;
    const readNotifs = notificationPages;

    await Object.keys(notificationPages).map(async (pageNumber: string) => {
      let notificationPage = notificationPages[pageNumber];

      const unreadIds = notificationPage
        .filter((n: any) => !n.read)
        .map((n: any) => ({ id: n.id }));

      if (unreadIds.length) {
        await new Http('/users/me/notifications/read').post({
          data: unreadIds,
        });

        notificationPage = notificationPage.map((n: any) => ({
          ...n,
          read: true,
        }));
      }

      readNotifs[pageNumber] = notificationPage;
    });

    return {
      notifications: {
        ...store.getState().notifications,
        notifications: readNotifs,
      },
    };
  },

  getUnreadNotificationsCount: async () => {
    const {
      data: {
        attributes: { unreadNotificationsCount },
      },
    } = await new Http('/users/me/notifications/unreads').get<APIObject>();

    return {
      notifications: {
        ...store.getState().notifications,
        unreadNotificationsCount,
      },
    };
  },
});

export { notificationsState, notificationsActions };
