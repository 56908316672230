import React from 'react';

import { Box, BoxProps, Grid, Heading, Image, Text } from '@chakra-ui/react';

import { TitleTag } from '@/components/design_system/AlexJenkinsClass/atoms';

import { transitionSquares } from '@/pages/AlexJenkinsClass/animations/transitionSquares';

import { colors } from '@/styles/chakra-theme/colors';
import { pxToRem } from '@/styles/chakra-theme/metrics';

import { isDesktop, isMobile } from '@/utils/DOM/sizing';

export const SkillsLearningSection = (props: BoxProps) => (
  <Grid
    templateColumns={{
      base: `repeat(9, ${window.innerWidth / 9}px)`,
      md: `repeat(9, ${window.innerWidth / 9}px)`,
      lg: `repeat(9, ${window.innerWidth / 9}px)`,
      xl: `repeat(12, ${window.innerWidth / 12}px)`,
      '2xl': `repeat(16, ${window.innerWidth / 16}px)`,
    }}
    templateRows={{
      base: `repeat(95, ${window.innerWidth / 9}px)`,
      md: `repeat(52, ${window.innerWidth / 9}px)`,
      lg: `repeat(48, ${window.innerWidth / 9}px)`,
      xl: `repeat(48, ${window.innerWidth / 12}px)`,
      '2xl': `repeat(48, ${window.innerWidth / 16}px)`,
    }}
    backgroundSize={{
      base: `${window.innerWidth / 9}px ${window.innerWidth / 9}px`,
      md: `${window.innerWidth / 9}px ${window.innerWidth / 9}px`,
      lg: `${window.innerWidth / 9}px ${window.innerWidth / 9}px`,
      xl: `${window.innerWidth / 12}px ${window.innerWidth / 12}px`,
      '2xl': `${window.innerWidth / 16}px ${window.innerWidth / 16}px`,
    }}
    backgroundImage={`linear-gradient(#00000020 1px, transparent 1px), linear-gradient(to right, #00000020 1px, ${colors.offWhite} 1px)`}
    bgColor={colors.offWhite}
    className="skillsLearning"
    {...props}
  >
    <Box
      bg={colors.offWhite}
      gridColumn={{ base: '2 / -2', md: '2 / -2' }}
      gridRow={{ base: '3 / 18', md: '3 / 7', lg: '3 / 6' }}
      border="1px solid #00000020" // border to match background grid
      px={pxToRem(20)}
    >
      <Heading
        color="black"
        fontSize={{ base: 38, lg: 54, xl: 92 }}
        lineHeight={{ base: pxToRem(56), md: pxToRem(72), xl: pxToRem(110) }}
        fontFamily="NeueMontreal"
        fontWeight={400}
      >
        {!isDesktop() ? `What \n you'll learn` : "What you'll learn"}
      </Heading>
      <Text
        color="black"
        fontSize={{ base: 20, md: 26, xl: 28, '2xl': 32 }}
        lineHeight={{ base: pxToRem(32), xl: pxToRem(44) }}
        fontWeight={400}
        mt={{
          base: pxToRem(24),
          md: pxToRem(12),
          xl: pxToRem(16),
          '2xl': pxToRem(34),
        }}
      >
        In Alex’s Original, you’ll learn the process of creating an animation
        from scratch. Explore Character Design and Storyboarding, to Motion and
        Rendering. Throughout the course, Alex provides advice to help you get
        familiar with advanced software, giving you time-saving tips, accessible
        suggestions and the confidence to start turning your illustrations into
        animations you can be proud to share.
      </Text>
    </Box>

    <Image
      src="/AlexJenkinsClass/img/skills-learning-1.png"
      gridColumn={{
        base: '2 / 5',
        md: '3 / 7',
        lg: '3 / 5',
        xl: '3 / 6',
        '2xl': '4 / 7',
      }}
      gridRow={{ base: '19', md: '9', lg: '8' }}
    />

    <Image
      src={
        isMobile()
          ? '/AlexJenkinsClass/img/skills-learning-2-mobile.png'
          : '/AlexJenkinsClass/img/skills-learning-2.png'
      }
      gridColumn={{
        base: '4 / 9',
        md: '6 / -2',
        lg: '4 / -2',
        xl: '5 / -2',
        '2xl': '6 / 15',
      }}
      gridRow={{ base: '21', md: '12', lg: '9' }}
      width={{ base: 'auto', xl: 840, '2xl': 'auto' }}
      height={{ base: 'auto', xl: 480, '2xl': 'auto' }}
    />

    <Box
      bg={colors.offWhite}
      border="1px solid black"
      p={pxToRem(20)}
      py={pxToRem(24)}
      pb={pxToRem(50)}
      gridColumn={{ base: '2 / -3', md: '2 / -2', lg: '2 / 8' }}
      gridRow={{ base: '23 / 36', md: '14 / 18', lg: '11 / 14', xl: '12 / 15' }}
      className="ideaGeneration"
    >
      <TitleTag
        bgColor="black"
        fontSize={[12, 16]}
        px={pxToRem(16)}
        py={pxToRem(4)}
        mb={[pxToRem(12), pxToRem(16)]}
      >
        CHARACTERS
      </TitleTag>
      <Heading
        color="black"
        fontSize={[28, 48]}
        fontFamily="NeueMontreal"
        fontWeight={400}
        mb={pxToRem(12)}
      >
        Idea Generation
      </Heading>
      <Text color="black" fontSize={[16, 20]} fontWeight={400}>
        Alex introduces and guides you through his creative process, kicking off
        with exercises to find meaningful ideas from everyday inspiration,
        followed by ideation, exploration and a deep-dive into what it means to
        sketch for the intention of animation. Alongside Alex’s discovery of
        creative ideas, he regularly returns to explain how he’s developing a
        narrative using pen and paper as well as getting the most out of the
        equipment he has at hand.
      </Text>
    </Box>

    <Image
      src={
        isMobile()
          ? '/AlexJenkinsClass/img/skills-learning-3-mobile.png'
          : '/AlexJenkinsClass/img/skills-learning-3.png'
      }
      gridColumn={{
        base: '2 / -2',
        md: '2 / -2',
        '2xl': '4 / -4',
      }}
      gridRow={{ base: '38', md: '20', lg: '16', xl: '17' }}
      height={{ base: 336, md: 384, lg: 480, xl: 720, '2xl': 600 }}
      width={{ base: 'auto', '2xl': 1200 }}
    />

    <Box
      bg={colors.offWhite}
      border="1px solid black"
      p={pxToRem(20)}
      py={pxToRem(24)}
      pb={pxToRem(50)}
      gridColumn={{
        base: '3 / -3',
        md: '2 / -2',
        lg: '3 / -3',
        xl: '4 / -4',
        '2xl': '6 / 12',
      }}
      gridRow={{
        base: '44 / 61',
        md: '23 / 27',
        lg: '19 / 23',
        xl: '22 / 25',
        '2xl': '21 / 24',
      }}
      className="craftingNarratives"
    >
      <TitleTag
        bgColor="black"
        fontSize={[12, 16]}
        px={pxToRem(16)}
        py={pxToRem(4)}
        mb={[pxToRem(12), pxToRem(16)]}
      >
        STORYTELLING
      </TitleTag>
      <Heading
        color="black"
        fontSize={[28, 48]}
        fontFamily="NeueMontreal"
        fontWeight={400}
        mb={pxToRem(12)}
      >
        Crafting Narratives
      </Heading>
      <Text color="black" fontSize={[16, 20]} fontWeight={400}>
        Building upon the character you’ve created, Alex moves onto describing
        his process of developing a storyline. Demonstrating his methods for
        ideation and audience manipulation, we get an insight into the dark and
        witty narratives his work is known for. You’ll develop familiarity with
        new tools in Procreate, get a comprehensive narrative building overview
        and finish with a sequence of frames in full colour.
      </Text>
    </Box>

    <Image
      src="/AlexJenkinsClass/img/skills-learning-4.png"
      gridColumn={{
        base: '-2 / -4',
        md: '-4 / -2',
        lg: '-2 / -4',
        xl: '-3 / -5',
        '2xl': '-4 / -6',
      }}
      gridRow={{
        base: '63 / 65',
        md: '29 / 31',
        lg: '25 / 27',
        xl: '27 / 31',
        '2xl': '26 / 30',
      }}
    />

    <Image
      src="/AlexJenkinsClass/img/skills-learning-5.png"
      gridColumn={{
        base: '2 / 7',
        md: '2 / -4',
        lg: '2 / 7',
        xl: '2 / -5',
        '2xl': '4 / -4',
      }}
      gridRow={{ base: '65 / 68', md: '31', lg: '27', xl: '29', '2xl': '28' }}
      width={{ base: 'auto', md: 480, lg: 600, xl: 840, '2xl': 'auto' }}
      // height={{ base: 192, md: 288, lg: 360, xl: 480, '2xl': 'auto' }}
    />

    <Box
      bg={colors.offWhite}
      border="1px solid black"
      p={pxToRem(20)}
      py={pxToRem(24)}
      pb={pxToRem(50)}
      gridColumn={{
        base: '3 / -2',
        md: '2 / -2',
        lg: '-8 / -2',
        xl: '-8 / -2',
        '2xl': '-8 / -2',
      }}
      gridRow={{
        base: '67 / 80',
        md: '34 / 38',
        lg: '29 / 32',
        xl: '31 / 34',
        '2xl': '31 / 34',
      }}
      className="fundamentals"
    >
      <TitleTag
        bgColor="black"
        fontSize={[12, 16]}
        px={pxToRem(16)}
        py={pxToRem(4)}
        mb={[pxToRem(12), pxToRem(16)]}
      >
        MOVEMENT
      </TitleTag>
      <Heading
        color="black"
        fontSize={[28, 48]}
        fontFamily="NeueMontreal"
        fontWeight={400}
        mb={pxToRem(12)}
      >
        Fundamentals of Animation
      </Heading>
      <Text color="black" fontSize={[16, 20]} fontWeight={400}>
        Moving onto Animation, Alex reveals his approach to storytelling,
        explaining how he creates variation and keeps audiences engaged through
        storytelling tricks and new mediums. By moving from Procreate to
        Photoshop (using the Animator’s Toolbar), he shares instructions for a
        variety of techniques: Onion Skinning, Motion Effects, Pacing, Tone,
        Colour, Exporting. You’ll finish this section with a completed
        Animation!
      </Text>
    </Box>

    {transitionSquares.slice(1)?.map((square, index) => (
      <Box
        key={`square_${square.x}_${index}`}
        gridColumn={square.x}
        gridRow={square.y}
        bgColor="black"
        border="1px solid #ffffff20"
        className="squares"
      />
    ))}
  </Grid>
);
