import React from 'react';

import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

import { Icon } from '@/components/global/Icon/Icon';

import 'react-circular-progressbar/dist/styles.css';

interface Props {
  progress: number;
  isPlaying: boolean;
}

const OriginalsVideoProgressButton = ({ progress, isPlaying }: Props) => (
  <div
    className="c-originals-progress-bar u-flex-hold relative"
    style={{ width: 40, height: 40 }}
  >
    <CircularProgressbar
      styles={buildStyles({ pathColor: '#fff', trailColor: '#333333' })}
      value={progress > 100 ? 100 : progress}
      strokeWidth={3}
    />
    <Icon
      className="c-originals-progress-bar__icon absolute"
      id={isPlaying ? 'pause' : 'play-round'}
      fill="white"
      size="s"
    />
  </div>
);

export default OriginalsVideoProgressButton;
