/* eslint-disable @typescript-eslint/no-magic-numbers */

export enum MasonryTemplatePreset {
  default = 'default',
  alternative_1 = 'alternative_1',
  alternative_2 = 'alternative_2',
  alternative_3 = 'alternative_3',
  alternative_4 = 'alternative_4',
  alternative_5 = 'alternative_5',
}

export const getMasonryTemplatePreset = (
  preset: MasonryTemplatePreset
): MasonryTemplate[] => {
  switch (preset) {
    case MasonryTemplatePreset.default:
      return [
        {
          breakpoint: '0em',
          columns: 2,
          widths: [50, 100, 100, 100],
        },
        {
          breakpoint: '55em',
          columns: 4,
          widths: [25, 50, 75, 100],
        },
      ];
    case MasonryTemplatePreset.alternative_1:
      return [
        { breakpoint: '0em', columns: 1, widths: [100, 100, 100, 100] },
        { breakpoint: '768px', columns: 2, widths: [50, 100, 100, 100] },
        { breakpoint: '1200px', columns: 3, widths: [33, 33, 66, 66] },
      ];
    case MasonryTemplatePreset.alternative_2:
      return [
        {
          breakpoint: '0em',
          columns: 1,
          widths: [100, 100, 100, 100],
        },
        {
          breakpoint: '900px',
          columns: 2,
          widths: [50, 100, 100, 100],
        },
      ];
    case MasonryTemplatePreset.alternative_3:
      return [
        { breakpoint: '0em', columns: 1, widths: [100, 100, 100, 100] },
        { breakpoint: '1024px', columns: 2, widths: [50, 100, 100, 100] },
      ];
    case MasonryTemplatePreset.alternative_4:
      return [
        { breakpoint: '0em', columns: 1, widths: [100, 100, 100, 100] },
        { breakpoint: '768px', columns: 2, widths: [50, 100, 100, 100] },
        { breakpoint: '1200px', columns: 4, widths: [25, 50, 75, 100] },
      ];
    case MasonryTemplatePreset.alternative_5:
      return [
        { breakpoint: '0em', columns: 1, widths: [100, 100, 100, 100] },
        { breakpoint: '500px', columns: 2, widths: [50, 100, 100, 100] },
        { breakpoint: '768px', columns: 3, widths: [33, 33, 66, 66] },
        { breakpoint: '1200px', columns: 4, widths: [25, 50, 75, 100] },
      ];
  }
};
