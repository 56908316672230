const throttle = (callback: any, wait: number) => {
  let time: any;
  let ready: boolean = true;
  return () => {
    if (ready) {
      ready = false;
      time = setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        time = null;
        ready = true;
        callback.call();
      }, wait);
    }
  };
};

const debounce = (
  func: any,
  wait: number = 250,
  immediate: boolean = false
) => {
  let timeout: any;
  return function () {
    // @ts-ignore
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

const poll = async (
  pollingFunction: (interval: number) => void,
  requestTimeout?: number,
  prevRequestTime?: number
) => {
  const minInterval = 10000;
  const minRequestTimeout = 5000;
  const interval = requestTimeout || minRequestTimeout;
  const prevRequest = prevRequestTime || 0;

  try {
    const start = performance.now();

    await pollingFunction(interval);

    const end = performance.now();

    const requestTime = end - start;

    const nextInterval = Math.max(
      interval + (requestTime - prevRequest),
      minInterval
    );

    setTimeout(
      () => poll(pollingFunction, nextInterval, requestTime),
      interval
    );
  } catch (e) {
    setTimeout(
      () => poll(pollingFunction, interval + prevRequest, interval),
      interval
    );
  }
};

export { throttle, debounce, poll };
