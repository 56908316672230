import * as React from 'react';

import { connect } from 'unistore/react';

import { Icon } from '@/components/global/Icon/Icon';

import { throttle } from '@/utils/api/throttle';
import { c } from '@/utils/strings/c';

interface Props extends SizingState {
  children?: any;
}

interface State {
  currentItem: number;
  distanceToNextItem?: number;
  firstItemScrollPos?: number;
  lastItemScrollPos?: number;
  allowButtonClick: boolean;
}

class CarouselComponent extends React.Component<Props, State> {
  public state: State = {
    currentItem: 0,
    distanceToNextItem: undefined,
    firstItemScrollPos: undefined,
    lastItemScrollPos: undefined,
    allowButtonClick: false,
  };

  private refCarousel: React.RefObject<HTMLDivElement>;
  private refFirstItem: React.RefObject<HTMLDivElement>;
  private refLastItem: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this.refCarousel = React.createRef();
    this.refFirstItem = React.createRef();
    this.refLastItem = React.createRef();
  }

  public componentDidMount = () => {
    // Initialise for children that are directly rendered
    this.initialise();
  };

  public componentDidUpdate = (prevProps: Props) => {
    const { children: prevChildren } = prevProps;
    const { children } = this.props;

    // When using .map(), the length of children when `this.initialise()` is called in componentDidMount is zero.
    // So everything works, wait for the length of the children to change, then call `this.initialise()`
    if (prevChildren.length !== children.length) {
      this.initialise();
    }
  };

  public componentWillUnmount = () => {
    // Remove the resizing event handler when you go away from the current page
    window.removeEventListener('resize', this.throttledOnResize);
  };

  private initialise = () => {
    const {
      sizing: { isMobile },
    } = this.props;

    // We don't need any of this on mobile
    if (isMobile) {
      return;
    }

    if (
      !this.refCarousel.current ||
      !this.refFirstItem.current ||
      !this.refLastItem.current
    ) {
      return;
    }

    // Add event handler to re-render everything after resizing window
    window.addEventListener('resize', this.throttledOnResize);

    // Set the scroll back to 0, it makes the maths easier to do!
    this.refCarousel.current.scrollTo({ left: 0 });

    // Get the width of the carousel container
    const { width: carouselWidth } =
      this.refCarousel.current.getBoundingClientRect();

    // Get the width and position of the first card
    const { width: firstItemWidth, x: firstItemX } =
      this.refFirstItem.current.getBoundingClientRect();

    // Figure out where to scroll the carousel container, so the first card is in the middle
    const firstItemScrollPos: number =
      firstItemX - carouselWidth / 2 + firstItemWidth / 2;

    // Figure out where to scroll to, to see the last item in the carousel
    const { width: lastItemWidth, x: lastItemX } =
      this.refLastItem.current.getBoundingClientRect();
    const lastItemScrollPos: number =
      lastItemX - carouselWidth / 2 + lastItemWidth / 2;

    // Do the scrolling to align the first card to the middle of the carousel
    this.refCarousel.current.scrollTo({ left: firstItemScrollPos });

    // Calculate how much the carousel needs to be scrolled so the next item is centred
    // We use `firstItemWidth` for this, but we also need to take the margins between items into account
    const firstItemMarginLeft: number = this.getCssValueForElement(
      this.refFirstItem.current,
      'margin-left'
    );

    const firstItemMarginRight: number = this.getCssValueForElement(
      this.refFirstItem.current,
      'margin-right'
    );

    const distanceToNextItem: number =
      firstItemWidth + firstItemMarginLeft + firstItemMarginRight;

    // Save `firstItemScrollPos` and `lastItemScrollPos` so we can go back to it when we reach the end of the carousel,
    // and save `distanceToNextItem` in the state, so we can use it for the forward/back buttons
    this.setState({
      firstItemScrollPos,
      lastItemScrollPos,
      distanceToNextItem,
      allowButtonClick: true,
    });
  };

  // `onResize` will recalculate and scroll the carousel container into the correct position
  // when the window size changes
  // To improve performance, we use `throttledOnResize` to make sure `onResize` is only called once
  // when the user has stopped resizing the window, not repeatedly while they're resizing it
  private onResize = () => {
    const { currentItem, distanceToNextItem } = this.state;

    if (
      !this.refCarousel.current ||
      !this.refFirstItem.current ||
      !this.refLastItem.current ||
      !distanceToNextItem
    ) {
      return;
    }

    // Set the scroll back to 0, it makes the maths easier to do!
    this.refCarousel.current.scrollTo({ left: 0 });

    // Get the width of the carousel container
    const { width: carouselWidth } =
      this.refCarousel.current.getBoundingClientRect();

    // Get the width and position of the first card
    const { width: firstItemWidth, x: firstItemX } =
      this.refFirstItem.current.getBoundingClientRect();

    // Figure out where to scroll the carousel container, so the first card is in the middle
    const firstItemScrollPos: number =
      firstItemX - carouselWidth / 2 + firstItemWidth / 2;

    // Then use `distanceToNextItem` and `currentItem` to calculate the extra distance needed
    // to place the current item in the middle
    const currentItemOffset: number = distanceToNextItem * currentItem;

    // Figure out where to scroll to, to see the last item in the carousel
    const { width: lastItemWidth, x: lastItemX } =
      this.refLastItem.current.getBoundingClientRect();
    const lastItemScrollPos: number =
      lastItemX - carouselWidth / 2 + lastItemWidth / 2;

    // Do the scrolling!
    this.refCarousel.current.scrollTo({
      left: firstItemScrollPos + currentItemOffset,
    });

    // We need to update the position values for the first item and last item
    // so our scrolling buttons work properly
    this.setState({ firstItemScrollPos, lastItemScrollPos });
  };

  // eslint-disable-next-line react/sort-comp
  private throttledOnResize = throttle(this.onResize, 1000);

  // This function takes an instance of a ref (this.refFoo.current) and a CSS property
  // and returns that property as an integer. By default, these properties are returned as strings.
  private getCssValueForElement = (
    element: any | undefined,
    property: string
  ): number => {
    if (!element) {
      return 0;
    }

    return Number(
      window
        .getComputedStyle(element)
        .getPropertyValue(property)
        .replace('px', '')
    );
  };

  private handleBackwards = () => {
    const {
      distanceToNextItem,
      currentItem,
      lastItemScrollPos,
      allowButtonClick,
    } = this.state;

    const { children } = this.props;

    if (
      !allowButtonClick ||
      !this.refCarousel.current ||
      !distanceToNextItem ||
      !children ||
      !lastItemScrollPos
    ) {
      return;
    }

    this.disableButtonClick();

    const left = this.refCarousel.current.scrollLeft - distanceToNextItem;

    this.refCarousel.current.scrollTo({
      left,
      behavior: 'smooth',
    });

    const totalItems: number = React.Children.count(children);

    if (currentItem === 0) {
      this.setState({ currentItem: totalItems - 1 }, () => {
        setTimeout(() => {
          if (!this.refCarousel.current) {
            return;
          }

          this.refCarousel.current.scrollTo({
            left: lastItemScrollPos,
          });
        }, 500);
      });
    } else {
      this.setState({ currentItem: currentItem - 1 });
    }
  };

  private handleForwards = () => {
    const {
      distanceToNextItem,
      currentItem,
      firstItemScrollPos,
      allowButtonClick,
    } = this.state;

    const { children } = this.props;

    if (
      !allowButtonClick ||
      !this.refCarousel.current ||
      !distanceToNextItem ||
      !children
    ) {
      return;
    }

    this.disableButtonClick();

    const left = this.refCarousel.current.scrollLeft + distanceToNextItem;

    this.refCarousel.current.scrollTo({
      left,
      behavior: 'smooth',
    });

    const totalItems: number = React.Children.count(children);

    if (currentItem === totalItems - 1) {
      this.setState({ currentItem: 0 }, () => {
        setTimeout(() => {
          if (!this.refCarousel.current) {
            return;
          }

          this.refCarousel.current.scrollTo({
            left: firstItemScrollPos,
          });
        }, 500);
      });
    } else {
      this.setState({ currentItem: currentItem + 1 });
    }
  };

  private renderButtons = () => (
    <div className="absolute dds-carousel__buttons">
      <button
        type="button"
        className="carousel__button carousel__button--prev u-flex u-align-center u-justify-center carousel__button--theme-light u-link-black--alt"
        onClick={this.handleBackwards}
      >
        <Icon id="arrow-left" size="s" />
      </button>

      <button
        type="button"
        className="carousel__button carousel__button--next u-flex u-align-center u-justify-center carousel__button--theme-light u-link-black--alt"
        onClick={this.handleForwards}
      >
        <Icon id="arrow-right" size="s" />
      </button>
    </div>
  );

  // To prevent the user from breaking the carousel by clicking the forward/back buttons really quickly,
  // use a timeout and the state to stop the buttons from working for 500ms to allow the animation to complete
  private disableButtonClick = () => {
    this.setState({ allowButtonClick: false }, () => {
      setTimeout(() => {
        this.setState({ allowButtonClick: true });
      }, 500);
    });
  };

  public render = () => {
    const {
      children,
      sizing: { isMobile },
    } = this.props;
    const { currentItem } = this.state;

    const totalItems: number = React.Children.count(children);

    return (
      <div className="u-flex u-flex-column u-align-center u-justify-center">
        <div ref={this.refCarousel} className={c('dds-carousel', {})}>
          {/* Duplicate children so last items appear when looking at the first item */}
          {!isMobile
            ? React.Children.map(children, (child: any, index: number) =>
                React.cloneElement(child, {
                  className: c([child.props.className], {
                    'dds-carousel__item--on': currentItem === index,
                    'dds-carousel__item--off': currentItem !== index,
                  }),
                })
              )
            : null}

          {React.Children.map(children, (child: any, index: number) =>
            React.cloneElement(child, {
              ref:
                index === 0
                  ? this.refFirstItem
                  : index === totalItems - 1
                  ? this.refLastItem
                  : undefined,
              className: c([child.props.className], {
                'dds-carousel__item--on': currentItem === index,
                'dds-carousel__item--off': currentItem !== index,
              }),
            })
          )}

          {/* Duplicate children so first items appear when looking at the last item */}
          {!isMobile
            ? React.Children.map(children, (child: any, index: number) =>
                React.cloneElement(child, {
                  className: c([child.props.className], {
                    'dds-carousel__item--on': currentItem === index,
                    'dds-carousel__item--off': currentItem !== index,
                  }),
                })
              )
            : null}
        </div>
        {this.renderButtons()}
      </div>
    );
  };
}

export const Carousel = connect(['sizing'], () => ({}))(CarouselComponent);
