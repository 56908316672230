import { EventAttributes } from 'ics';

import { CustomDate } from '@/utils/class-extensions/Date';

const ics = require('ics');

const generateICSJSON = (
  start: string,
  end: string,
  title: string,
  description: string
): EventAttributes => {
  const startObject = new CustomDate(start).getDateObject();
  const endObject = new CustomDate(end).getDateObject();

  return {
    start: [
      startObject.year,
      startObject.monthDate + 1,
      startObject.dayDate,
      Number(startObject.hours),
      startObject.rawMinutes,
    ],
    end: [
      endObject.year,
      endObject.monthDate + 1,
      endObject.dayDate,
      Number(endObject.hours),
      endObject.rawMinutes,
    ],
    title,
    description,
    organizer: { name: 'Daisie', email: 'hello@daisie.com' },
    attendees: [],
  };
};

export const createGCalEventURL = ({
  start,
  end,
  title,
  description,
}: {
  start: string;
  end: string;
  title: string;
  description: string;
}) =>
  `https://calendar.google.com/calendar/r/eventedit?text=${title.replace(
    / /g,
    '+'
  )}&dates=${start.replace(/([-:])/gm, '').replace('.000Z', 'Z')}/${end
    .replace(/([-:])/gm, '')
    .replace('.000Z', 'Z')}&details=${description.replace(/ /g, '+')}`;

export const createICSFiles = (
  events: Array<{
    start: string;
    end: string;
    title: string;
    description: string;
  }>
) => {
  const { error, value } = ics.createEvents(
    events.map(({ start, end, title, description }) =>
      generateICSJSON(start, end, title, description)
    )
  );

  if (error) {
    throw error;
  }

  return value;
};
