import { matchPath } from 'react-router';

import { TrackingLocation } from '@/enums/TrackingLocation';

import { routes } from '@/routes/index';

export const getRouteFromUrl = (pathname: string = '') => {
  pathname = pathname || window.location.pathname;

  if (pathname !== '/' && pathname[pathname.length - 1] === '/') {
    pathname = pathname.slice(0, -1);
  }

  if (pathname.includes('?')) {
    pathname = pathname.split('?')[0];
  }

  const route = Object.keys(routes).find(
    (key) =>
      !!matchPath(pathname, {
        path: routes[key],
        exact: true,
        strict: true,
      })
  );

  return !!route ? route : 'home';
};

export const getTrackingLocationFromLocationState = (state?: LocationState) => {
  if (!state) return '';

  const pathname = state.prevPathname || window.location.pathname;
  const route = getRouteFromUrl(pathname);
  const hash = state.prevHash || '';

  switch (hash) {
    case '#foryou':
      return TrackingLocation.forYou;
    default:
      break;
  }

  switch (route) {
    case 'user':
      return TrackingLocation.userProfile;
    case 'search':
      return TrackingLocation.search;
    default:
      return route || '';
  }
};
