import * as React from 'react';

import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'unistore/react';

import { LoadingSymbol } from '@/components/global/LoadingSymbol/LoadingSymbol';

import {
  WORKSHOP_BATCH_ELAPSED,
  WORKSHOP_BATCH_NOT_FOUND,
  WORKSHOP_BATCH_SOLD_OUT,
} from '@/messages/errors';

import { routes } from '@/routes';
import { parser } from '@/routes/parser';

import { getWorkshopFromData } from '@/store/helpers';
import { authActions } from '@/store/modules/auth';

import { Http } from '@/utils/api/Http';
import { track } from '@/utils/mixpanel/mixpanel';

interface MatchParams {
  workshopSlug: string;
  sessionId: string;
}

interface Props
  extends AuthState,
    AuthActions,
    RouteComponentProps<MatchParams, {}, LocationState> {}

interface State {
  processing: boolean;
  redirectToWorkshop: boolean;
  redirectToCalendar: boolean;
  redirectToSignUp: boolean;
  redirectToWorkshopConfirmation: boolean;
  redirectToWorkshopSessionNotFound: boolean;
  redirectToWorkshopSessionElapsed: boolean;
  redirectToWorkshopSessionSoldOut: boolean;
}

class WorkshopJoinNowComponent extends React.Component<Props, State> {
  private workshop: Workshop | null = null;
  private batch: WorkshopBatch | null = null;
  private session: WorkshopSession | null = null;

  public state: State = {
    processing: false,
    redirectToWorkshop: false,
    redirectToCalendar: false,
    redirectToSignUp: false,
    redirectToWorkshopConfirmation: false,
    redirectToWorkshopSessionNotFound: false,
    redirectToWorkshopSessionElapsed: false,
    redirectToWorkshopSessionSoldOut: false,
  };

  public componentDidMount = async () => {
    await this.process();
  };

  public componentDidUpdate = async () => {
    await this.process();
  };

  private process = async (): Promise<void> => {
    const {
      location: { search },
    } = this.props;
    const { processing } = this.state;

    if (processing) {
      return;
    }

    this.setState({ processing: true });

    // Check for and use `token` query parameter so the user doesn't need to log in
    const queryParams = new URLSearchParams(search);
    const queryParamToken = queryParams.get('token');

    if (!!queryParamToken) {
      try {
        await this.props.tokenLogin(queryParamToken);
      } catch (e) {
        // If token is invalid, do nothing so user gets redirected to /join
      }
    }

    const {
      auth: { user },
    } = this.props;

    // Fetch the workshop correlating to the workshop ID in the URL
    const workshop = await this.fetchWorkshop();

    if (!workshop) {
      // If the URL was malformed and had a dodgy workshop ID,
      // redirect the user to the workshop URL so they see a 404
      this.setState({ redirectToWorkshop: true });
      return;
    }

    this.workshop = workshop;
    this.batch = this.getBatchForSession() || null;
    this.session = this.batch
      ? this.getSessionInBatch(this.batch) || null
      : null;

    if (!this.session || !this.batch) {
      this.setState({ redirectToWorkshopSessionNotFound: true });
      return;
    }

    if (!user) {
      track('join_workshop_now', {
        workshopId: this.workshop!.id,
        userId: null,
        isAttending: null,
      });

      // Redirect to sign up page
      // to then redirect back to here once they're logged in
      this.setState({ redirectToSignUp: true });
      return;
    }

    // Figure out based on the isAttending flags if the user is attending the workshop
    const isAttending = this.batch.isAttending;

    track('join_workshop_now', {
      workshopId: this.workshop!.id,
      userId: user.id,
      isAttending,
    });

    if (isAttending) {
      // If they're attending, grab their magic zoom link
      const magicLink = await this.getMagicLink();

      if (magicLink) {
        // Woo, send them on to Zoom
        this.redirectToMagicZoomLink(magicLink);
        return;
      } else {
        // No link available sadly, for some reason
        // Should never happen theoretically if they're meant to be attending the session
        // Redirect to the workshop page either way
        this.setState({ redirectToWorkshop: true });
        return;
      }
    }

    if (this.session.hasEnded) {
      // If the user is not attending, and the session is over,
      // Redirect them to the workshop page and show a toast explaining this
      this.setState({ redirectToWorkshopSessionElapsed: true });
      return;
    }

    if (!this.batch.spacesRemaining && !this.batch.hasElapsed) {
      // If the user is not attending, and there's no spaces left
      // and the batch hasn't yet elapsed (BE sends spacesRemaining = 0 if elapsed has elapsed),
      // Redirect them to the workshop page and show a toast explaining this
      this.setState({ redirectToWorkshopSessionSoldOut: true });
      return;
    }

    // If they're not attending and the batch is free
    // Let's auto add them as an attendee
    const successfullyAttended = await this.attendBatch();

    if (typeof successfullyAttended !== 'boolean') {
      // We only get to this if the workshop is paid,
      // and we don't want to auto-register users for paid workshop
      // so just take them to the workshop page and bring up the payment confirmation page
      this.setState({ redirectToWorkshopConfirmation: true });
      return;
    }

    if (!successfullyAttended) {
      // if something goes wrong with attending,
      // just take them to the workshop page
      this.setState({ redirectToWorkshop: true });
      return;
    }

    // If all went well with auto-registering
    // and the user is now theoretically attending the workshop batch,
    // try and grab their magic zoom link again
    const magicLink = await this.getMagicLink();

    if (!magicLink) {
      // If we still weren't able to get their zoom link
      // Maybe the registration went wrong somewhere,
      // or the workshop is paid
      // Redirect to the workshop page
      this.setState({ redirectToWorkshop: true });
      return;
    }

    // Woo, auto-registration worked!
    // Send them on to Zoom
    this.redirectToMagicZoomLink(magicLink);
  };

  private fetchWorkshop = async (): Promise<Workshop | null> => {
    try {
      const { data } = await new Http(
        `/workshops/${this.getWorkshopIdFromUrl()}`
      ).get<APIObject<APIWorkshop>>();

      if (!data.relationships) {
        throw new Error();
      }

      return getWorkshopFromData(data);
    } catch (e) {
      return null;
    }
  };

  private getMagicLink = async (): Promise<string | null> => {
    try {
      const { data } = await new Http(
        `/workshops/${this.getWorkshopIdFromUrl()}/session/${this.getWorkshopSessionIdFromUrl()}/magicLink`
      ).get<APIObject>();

      if (!data.attributes) {
        throw new Error();
      }

      return data.attributes.url;
    } catch (e) {
      return null;
    }
  };

  private getSessionInBatch = (batch: WorkshopBatch): WorkshopSession | null =>
    // Find the batch that houses the session we're looking at
    batch.sessions.find(
      (session: WorkshopSession) =>
        session.id === this.getWorkshopSessionIdFromUrl()
    ) || null;

  private getBatchForSession = (): WorkshopBatch | undefined =>
    // Find the batch that correlates to the session in the URL
    // this.workshop is definitely going to be defined, don't worry
    this.workshop?.batches.find((batch: WorkshopBatch) =>
      this.getSessionInBatch(batch)
    );

  private attendBatch = async (): Promise<boolean | void> => {
    if (!this.batch) {
      // Should never get to here in this flow
      // As we've already checked the batch in process()
      // But we gotta keep TS happy
      throw new Error('Could not find batch to attend');
    }

    if (this.batch.isFree) {
      // try and auto-register users for this workshop batch
      try {
        await new Http(`/workshopBatch/attend/free`).post({
          uuid: this.batch.id,
        });
        return true;
      } catch (e) {
        return false;
      }
    } else {
      // Don't do anything for paid workshops
      return;
    }
  };

  private redirectToMagicZoomLink = (magicLink: string): void => {
    window.location.href = magicLink;
  };

  private getWorkshopIdFromUrl = (): string => {
    const { workshopSlug } = this.props.match.params;
    return `workshop_${workshopSlug.split('-').pop()}`;
  };

  private getWorkshopSessionIdFromUrl = (): string => {
    const { sessionId } = this.props.match.params;
    return `workshopSession_${sessionId.split('-').pop()}`;
  };

  public render = () => {
    const {
      match: {
        params: { workshopSlug, sessionId },
      },
    } = this.props;

    const {
      redirectToWorkshop,
      redirectToCalendar,
      redirectToSignUp,
      redirectToWorkshopConfirmation,
      redirectToWorkshopSessionNotFound,
      redirectToWorkshopSessionElapsed,
      redirectToWorkshopSessionSoldOut,
    } = this.state;

    if (redirectToSignUp) {
      return (
        <Redirect
          to={parser({
            name: 'register',
            query: {
              to: parser({
                name: 'workshopJoinNow',
                params: { workshopSlug, sessionId },
              }),
            },
          })}
        />
      );
    }

    if (redirectToCalendar) {
      return <Redirect to={routes.calendar} />;
    }

    if (redirectToWorkshop) {
      return (
        <Redirect
          to={parser({
            name: 'workshop',
            params: { workshopSlug },
          })}
        />
      );
    }

    if (redirectToWorkshopConfirmation) {
      // @ts-ignore
      const session = this.batch?.id.split('_')[1];

      return (
        <Redirect
          to={parser({
            name: 'workshop',
            params: { workshopSlug },
            query: {
              session: session ?? undefined,
            },
          })}
        />
      );
    }

    if (redirectToWorkshopSessionNotFound) {
      return (
        <Redirect
          to={parser({
            name: 'workshop',
            params: { workshopSlug },
            query: {
              errorMessage: WORKSHOP_BATCH_NOT_FOUND,
            },
          })}
        />
      );
    }

    if (redirectToWorkshopSessionSoldOut) {
      return (
        <Redirect
          to={parser({
            name: 'workshop',
            params: { workshopSlug },
            query: {
              errorMessage: WORKSHOP_BATCH_SOLD_OUT,
            },
          })}
        />
      );
    }

    if (redirectToWorkshopSessionElapsed) {
      return (
        <Redirect
          to={parser({
            name: 'workshop',
            params: { workshopSlug },
            query: {
              errorMessage: WORKSHOP_BATCH_ELAPSED,
            },
          })}
        />
      );
    }

    return (
      <div className="u-flex u-1/1 u-justify-center">
        <div className="pt32">
          <LoadingSymbol size="l" />
        </div>
      </div>
    );
  };
}

export const WorkshopJoinNow = connect(['auth'], (store: GlobalStoreState) => ({
  ...authActions(store),
}))(withRouter(WorkshopJoinNowComponent));
