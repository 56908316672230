import * as React from 'react';

import { RouteComponentProps } from 'react-router';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'unistore/react';

import { LoginForm } from '@/components/accounts/LoginForm/LoginForm';
import { RegistrationForm } from '@/components/accounts/RegistrationForm/RegistrationForm';
import { Head } from '@/components/global/Head/Head';
import { Icon } from '@/components/global/Icon/Icon';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { Toast } from '@/components/global/Toaster/Toast';
import ImgLearnAnytimeAnywhere from '@/components/marketing/HomeCategoryPageSections/SectionApp/learn-anytime-anywhere.png';
import { TRIAL_LENGTH_TEXT_SINGULAR } from '@/components/subscription/constants';

import { AutoLoginError } from '@/enums/AutoLoginError';
import { GtmEvent } from '@/enums/GtmEvent';
import { SubscriptionInterval } from '@/enums/SubscriptionInterval';
import { TrackingLocation } from '@/enums/TrackingLocation';

import { AUTO_LOGIN_FAIL, TOKEN_EXPIRED } from '@/messages/errors';
import { PASSWORD_RESET_COMPLETE } from '@/messages/messages';

import { routes } from '@/routes';

import { findProductAndPrice } from '@/store/helpers/subscriptionHelpers';
import { abtestActions } from '@/store/modules/abtest';

import { sendGtmEvent } from '@/utils/gtm/sendGtmEvent';
import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';
import { getDisplayPrice } from '@/utils/strings/currency';
import { getRandomInt } from '@/utils/strings/number-manipulation';
import { getRouteFromUrl } from '@/utils/urls/routes';

interface Props
  extends RouteComponentProps,
    SizingState,
    SubscriptionState,
    ABTestState,
    ABTestActions {}

interface State {
  redirectToOptimisedRegisterFlow: boolean;
}

class SignUpOrLoginComponent extends React.Component<Props, State> {
  public state: State = {
    redirectToOptimisedRegisterFlow: false,
  };

  public componentDidMount = async () => {
    const {
      location: { search, pathname },
    } = this.props;
    const matchedRoute = getRouteFromUrl(pathname);

    const isRegisterPage = matchedRoute === 'register';

    const queryParams = new URLSearchParams(search);

    if (isRegisterPage) {
      const isFromWorkshop = queryParams.get('isFromWorkshop') || undefined;

      if (isFromWorkshop) {
        await this.props.forceVariant(getRandomInt(0, 2));
      }

      this.setState({ redirectToOptimisedRegisterFlow: true });

      return;
    }

    this.sendRegisterGtmEvent();

    const isAuthExpired = !!queryParams.get('auth-expired');

    if (isAuthExpired) {
      setTimeout(() => {
        new Toast({
          body: TOKEN_EXPIRED,
          failure: true,
        }).dispatch();
      }, 500);
    }
  };

  public componentDidUpdate = (prevProps: Props) => {
    const {
      location: { pathname: prevPathname },
    } = prevProps;

    const {
      location: { pathname },
    } = this.props;

    if (pathname !== prevPathname) {
      this.sendRegisterGtmEvent();
    }

    if (pathname !== prevPathname && pathname === routes.register) {
      this.setState({ redirectToOptimisedRegisterFlow: true });
    }
  };

  private sendRegisterGtmEvent = () => {
    const {
      location: { pathname },
    } = this.props;

    const matchedRoute = getRouteFromUrl(pathname);
    const isRegisterPage = matchedRoute === 'register';

    if (isRegisterPage) {
      sendGtmEvent(GtmEvent.SignUpPage);
    }
  };

  public render = () => {
    const {
      sizing: { isMobile },
      location: { search, pathname },
      subscription: { productsAndPrices, userCurrency },
    } = this.props;

    const { redirectToOptimisedRegisterFlow } = this.state;

    if (redirectToOptimisedRegisterFlow) {
      const queryParams = new URLSearchParams(search);
      const to = queryParams.get('to') || undefined;

      return (
        <Redirect
          to={`${routes.optimisedJoinFlowChoosePlan}${
            !!to ? `?to=${encodeURIComponent(to)}` : ''
          }`}
        />
      );
    }

    const queryParams = new URLSearchParams(search);

    const passwordReset = !!queryParams.get('passwordReset');
    const to = queryParams.get('to') || '';
    const message = queryParams.get('msg');

    const email = queryParams.get('email') || undefined;
    const acceptMarketing = queryParams.get('acceptMarketing') || undefined;

    if (message && message === AutoLoginError.TokenError) {
      new Toast({ body: AUTO_LOGIN_FAIL, failure: true }).dispatch();
    }

    const matchedRoute = getRouteFromUrl(pathname);

    const isRegisterPage = matchedRoute === 'register';

    const monthlyProduct = findProductAndPrice({
      productsAndPrices,
      currency: userCurrency,
      recurringInterval: SubscriptionInterval.month,
    });

    return (
      <>
        <Head
          title={t(!isRegisterPage ? 'Log in' : 'Sign up')}
          pathname={this.props.location.pathname}
          isNoindexPage={true}
        />
        <div className="page-content--centered@m page-content--centered@l">
          <div
            className={c('wrap u-1/1 mv32@s', {
              'wrap--sm': !isRegisterPage,
              'u-flex u-flex-row': isRegisterPage,
            })}
          >
            {isRegisterPage && (
              <div className="u-1/2 u-hide u-flex@l u-align-center">
                <div className="u-1/1">
                  <ImageLoader
                    src={ImgLearnAnytimeAnywhere}
                    alt=""
                    className="mb64 u-1/2"
                  />

                  <p className="u-white f-lynstone-bold-2 mb32">
                    {t(
                      `Welcome to Daisie — the world's fastest growing creative community`
                    )}
                  </p>

                  <p className="u-white f-lynstone-book-2a">
                    {t(
                      `Get unlimited access to live, daily workshops & classes with industry-leading creators. Free ${TRIAL_LENGTH_TEXT_SINGULAR} trial, then only`
                    )}{' '}
                    {monthlyProduct
                      ? t(
                          `${getDisplayPrice(
                            monthlyProduct.currency,
                            monthlyProduct.amount
                          )}/month after.`
                        )
                      : null}{' '}
                    {t('Cancel anytime.')}
                  </p>
                </div>
              </div>
            )}

            <div
              className={c('', {
                'u-1/2@l': isRegisterPage,
              })}
            >
              <div className="u-white u-flex u-flex-column u-align-center u-text-center f-title-1 u-x-bold mb32">
                <Icon id="daisie" width={!isMobile ? '80px' : '54px'} />
                <h1 className="pt24">
                  {isRegisterPage ? t('Sign Up') : t('Log In')}
                </h1>
              </div>

              <div>
                {passwordReset && (
                  <p className="f-shout-4 u-brand u-text-center mb24">
                    {t(PASSWORD_RESET_COMPLETE)}
                  </p>
                )}

                {isRegisterPage ? (
                  <RegistrationForm
                    formLocation={TrackingLocation.join}
                    textClassName="u-white"
                    emailAlreadyInUseUrlParam={`?to=${encodeURIComponent(to)}`}
                    email={email}
                    acceptMarketing={
                      acceptMarketing ? acceptMarketing === 'true' : undefined
                    }
                  />
                ) : (
                  <LoginForm />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
}

export const SignUpOrLogin = connect(['sizing', 'subscription'], () => ({
  ...abtestActions(),
}))(withRouter(SignUpOrLoginComponent));
