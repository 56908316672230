import * as React from 'react';

import { connect } from 'unistore/react';

import { Follow } from '@/components/following/Follow/Follow';
import { BaseNotification } from '@/components/notifications/BaseNotification';

import { t } from '@/utils/i18n/i18n';
import { pluralise } from '@/utils/strings/string-manipulation';

interface Props extends AuthState {
  className?: string;
  notification: DaisieNotification;
  isDarkMode: boolean;
  onClickToggle: () => void;
}

const FollowRequestNotificationComponent: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { className = '', notification, isDarkMode, onClickToggle } = props;
  const { user } = notification;
  let { userCount } = notification;

  userCount = userCount ? userCount - 1 : 0;

  let primary = null;

  if (userCount) {
    primary = (
      <>
        {t('and')}{' '}
        <span className="u-bold">
          {userCount} {t(pluralise('other', userCount))}
        </span>{' '}
        {t('are now following you')}
      </>
    );
  } else {
    primary = t('is now following you');
  }

  const action =
    !userCount && user ? (
      <Follow
        username={user.username}
        following={user.isFollowing}
        className="u-flex-hold"
      />
    ) : null;

  return (
    <BaseNotification
      isDarkMode={isDarkMode}
      onClickToggle={onClickToggle}
      notification={notification}
      className={className}
      primary={primary}
      secondary={({ date }: { date: string }) => date}
      action={action}
    />
  );
};

export const FollowRequestNotification = connect('auth')(
  FollowRequestNotificationComponent
);
