export enum DaisieCustomEvent {
  change_channel = 'change_channel',
  change_navigation_style = 'change_navigation_style',
  disable_dragging = 'disable_dragging',
  enable_dragging = 'enable_dragging',
  profile_picture_crop_done = 'profile_picture_crop_done',
  repack = 'repack',
  show_interests_selector = 'show_interests_selector',
  upload_modal_show = 'upload_modal_show',
  upload_modal_hide = 'upload_modal_hide',
  update_incorrect_files = 'update_incorrect_files',
  upload_file_to_messages = 'upload_file_to_messages',
  upload_modal_update = 'upload_modal_update',

  // ROOMS
  room_voice_add_admin_event = 'room_voice_add_admin_event',
  room_mute_mic = 'room_mute_mic',
  room_enter = 'room_enter',
  room_end = 'room_end',
  room_non_admin_show_permissions_helper = 'room_non_admin_show_permissions_helper',
  room_private_initialise_audio = 'room_private_initialise_audio',
  room_private_room_host_leave_dialog = 'room_private_room_host_leave_dialog',
  room_private_room_host_leave_confirm = 'room_private_room_host_leave_confirm',
  room_private_room_kick_or_ban = 'room_private_room_kick_or_ban',
  room_move_self_to_audience_dialog = 'room_move_self_to_audience_dialog',
  agora_recording_message_audience = 'agora_recording_message_audience',
  agora_recording_message_audience_raise_hand = 'agora_recording_message_audience_raise_hand',
  agora_recording_message_audience_invite = 'agora_recording_message_audience_invite',

  // WORKSHOPS
  force_hide_onboarding_workshop_hero = 'force_hide_onboarding_workshop_hero',
  show_workshop_attend_modal = 'show_workshop_attend_modal',
  show_workshop_attend_modal_loading = 'show_workshop_attend_modal_loading',
}
