import { getCuratedImagesFromData } from '@/store/helpers/cinematicBackgroundHelpers';

import { Http } from '@/utils/api/Http';

const cinematicBackgroundState: CinematicBackgroundState = {
  cinematicBackground: {
    imageUrl: undefined,
    curatedImages: [],
  },
};

const cinematicBackgroundActions = (store: any) => ({
  changeBackgroundImage: (state: GlobalStoreState, imageUrl?: string) => {
    store.setState({
      cinematicBackground: {
        ...state.cinematicBackground,
        imageUrl,
      },
    });
  },

  fetchCuratedImages: async (state: GlobalStoreState) => {
    // If already loaded once, don't fetch again
    if (
      (state.cinematicBackground.curatedImages &&
        state.cinematicBackground.curatedImages.length > 0) ||
      !state.auth.isAuthorised
    ) {
      return;
    }

    let curatedImages: CuratedImage[] = [];

    try {
      const { data } = await new Http(
        '/curatedImages?pageSize=500&pageNumber=1'
      ).get<APIArray<APICuratedImage>>();

      curatedImages = getCuratedImagesFromData(data);
    } catch (e) {
      throw e;
    }

    store.setState({
      cinematicBackground: {
        ...state.cinematicBackground,
        curatedImages,
        imageUrl: store.getState().cinematicBackground.imageUrl,
      },
    });
  },
});

export { cinematicBackgroundState, cinematicBackgroundActions };
