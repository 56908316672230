export enum ButtonStyle {
  light = 'light',
  dark = 'dark',
  darkTranslucent = 'dark-translucent',
  lightTranslucent = 'light-translucent',
  veryLightTranslucent = 'very-light-translucent',
  veryLightTranslucentDanger = 'very-light-translucent-danger',
  default = 'default',
  danger = 'danger',
  dangerInvert = 'danger-invert',
  outlineLight = 'outline-light',
  outlineDark = 'outline-dark',
  outlineWhite = 'outline-white',
  outlineGreen = 'outline-green',
  transparent = 'transparent',
  darkBrand = 'dark-brand',
  originals = 'originals',
}
