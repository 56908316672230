/**
 * Flatten an object into its constituent properties
 *
 * @param obj  The object you need to merge
 *
 * @return obj The merged object
 */
export const flatten = (obj: any): object =>
  Object.keys(obj).reduce(
    (n, key) =>
      typeof obj[key] === 'object'
        ? Object.assign(n, flatten(obj[key]))
        : { ...n, [key]: obj[key] },
    {}
  );
