import * as React from 'react';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface TextareaProps extends InputGroupProps {
  name?: string;
  required?: boolean;
  value?: string | number;
  showMaxLength?: number;
  errorOutputStyle?: string;
  uiTestId?: string;
  darkMode?: boolean;
}

interface ComponentState {
  inputLength: number;
  active: boolean;
}

type ComponentProps = TextareaProps & React.HTMLProps<HTMLTextAreaElement>;

class Textarea extends React.Component<ComponentProps, ComponentState> {
  private textareaElement: React.RefObject<HTMLTextAreaElement>;

  public state = {
    inputLength: 0,
    active: false,
  };

  constructor(props: ComponentProps) {
    super(props);
    this.textareaElement = React.createRef();
  }

  public componentDidMount = () => {
    this.updateInputLength();
  };

  private handleChange = (event: any) => {
    this.updateInputLength();
    this.props.onChange({ value: event.target.value, event });
  };

  private updateInputLength = () => {
    if (this.textareaElement.current) {
      this.setState({
        inputLength: this.textareaElement.current.value.length,
      });
    }
  };

  private renderMaxLength = () => {
    const { inputLength, active } = this.state;
    const { showMaxLength } = this.props;

    if (!showMaxLength) {
      return null;
    }

    const hasExceededMaxLength = Math.sign(showMaxLength - inputLength) === -1;

    return (
      <span
        className={c('f-text-4 mt4', {
          'u-black': !hasExceededMaxLength,
          'u-danger': hasExceededMaxLength,
          'u-hide': !active,
        })}
      >
        {inputLength > showMaxLength
          ? showMaxLength - inputLength
          : `${inputLength} / ${showMaxLength}`}
      </span>
    );
  };

  public render() {
    const {
      error,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onChange,
      label,
      showMaxLength,
      className = '',
      errorOutputStyle = '',
      uiTestId,
      darkMode,
      ...rest
    } = this.props;
    return (
      <div className={error ? 'input-group input-group--error' : 'input-group'}>
        {!!label && !!showMaxLength ? (
          <header className="u-flex u-justify-between mb8">
            <label
              htmlFor={this.props.id}
              children={t(label)}
              className={c('', {
                'u-light-grey': darkMode,
              })}
            />
            {this.renderMaxLength()}
          </header>
        ) : (
          !!label &&
          !showMaxLength && (
            <header className="mb8">
              <label
                htmlFor={this.props.id}
                children={t(label)}
                className={c('', {
                  'u-light-grey': darkMode,
                })}
              />
            </header>
          )
        )}
        <div className="relative">
          <textarea
            className={c(['bv bh', className])}
            ref={this.textareaElement}
            style={{ resize: 'none' }}
            name={this.props.name || this.props.id}
            onFocus={() => this.setState({ active: true })}
            onBlur={() => this.setState({ active: false })}
            onChange={this.handleChange}
            data-ui-test-id={isUiTest() && uiTestId ? uiTestId : undefined}
            {...rest}
          />
          <div className="absolute absolute--br mb8 mr8">
            {!!showMaxLength && !label && this.renderMaxLength()}
          </div>
        </div>
        {error && typeof error === 'string' ? (
          <output className={errorOutputStyle}>{error}</output>
        ) : null}
      </div>
    );
  }
}

export { Textarea };
