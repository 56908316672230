import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'unistore/react';

import { Head } from '@/components/global/Head/Head';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { LoadingSymbol } from '@/components/global/LoadingSymbol/LoadingSymbol';
import { OnDemandPlayer } from '@/components/ondemand/OnDemandPlayer';
import OriginalsPlayer from '@/components/originals/OriginalsPlayer';
import { addWorkshopComponentActions } from '@/components/workshops/addWorkshopComponentActions';

import { HeroBlurOverlay } from '@/components/design_system/HeroBlurOverlay/HeroBlurOverlay';

import { OnDemandMediaType } from '@/enums/OnDemandMediaType';
import { WorkshopEventType } from '@/enums/WorkshopEventType';

import { workshopActions } from '@/store/modules/workshop';

import { t } from '@/utils/i18n/i18n';
import { track } from '@/utils/mixpanel/mixpanel';

interface MatchParams {
  workshopSlug: string;
}

interface Props
  extends RouteComponentProps<MatchParams, {}, LocationState>,
    WorkshopComponentActions,
    WorkshopActions,
    AuthState,
    WorkshopState,
    SizingState {}

const WatchComponent = ({
  location,
  match,
  workshop,
  sizing,
  auth,
  getCurrentWorkshop,
}: Props) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await getCurrentWorkshop(
        workshopId,
        undefined,
        OnDemandMediaType.WorkshopCatchUp
      );

      if (workshop && workshop.currentWorkshop) {
        track('watch_full_recording', {
          workshopId: workshop.currentWorkshop.id,
          userId: auth.user ? auth.user.id : null,
        });
      }
    } catch {
      // Do nothing
      return;
    }

    setIsLoading(false);
  };

  const workshopId = `workshop_${match.params.workshopSlug.split('-').pop()}`;

  const getCoverMediaUrl = (): string => {
    const coverMedia = workshop.currentWorkshop
      ? workshop.currentWorkshop.coverMedia
      : '';
    const { isMobile, isTablet } = sizing;

    if (isMobile) {
      return coverMedia.replace('resize(1200', 'resize(800');
    } else if (isTablet) {
      return coverMedia.replace('resize(1200', 'resize(1200');
    } else {
      return coverMedia.replace('resize(1200', 'resize(1680');
    }
  };

  const currentWorkshop = workshop.currentWorkshop;
  const isOriginal =
    currentWorkshop && currentWorkshop.eventType === WorkshopEventType.original;

  return (
    <div className="c-watch">
      <Head
        title={`${currentWorkshop ? currentWorkshop.title : ''} - ${t(
          'Watch'
        )}`}
        desc={currentWorkshop ? currentWorkshop.description.slice(0, 200) : ''}
        pathname={location.pathname}
      />

      {currentWorkshop && isOriginal && (
        <OriginalsPlayer workshop={currentWorkshop} />
      )}
      {!isOriginal && (
        <div
          className="c-workshop-cover relative u-bg-charcoal-grey"
          style={{
            height: `calc(100vh - 72px)`,
          }}
        >
          <ImageLoader
            src={workshop.currentWorkshop ? getCoverMediaUrl() : ''}
            className="absolute fill u-object-cover"
            convertToWebp={true}
          />
          {isLoading && (
            <LoadingSymbol
              className="absolute absolute--mid-center u-z-index-1"
              size="l"
              colour="white"
            />
          )}
          <HeroBlurOverlay />
          {currentWorkshop && currentWorkshop.onDemandMediaCatchup && (
            <OnDemandPlayer
              className="absolute absolute--mid-center u-z-index-1"
              onDemandMedia={currentWorkshop.onDemandMediaCatchup}
              isVisible={true}
              useMobilePlayer={false}
              hideCloseButton={true}
            />
          )}
          {currentWorkshop && !currentWorkshop.onDemandMediaCatchup && (
            <p className="absolute absolute--mid-center u-text-center u-z-index-1 u-white f-yell-1a">
              No recording found for this workshop
            </p>
          )}
          {!isLoading && !currentWorkshop && (
            <p className="absolute absolute--mid-center u-text-center u-z-index-1 u-white f-yell-1a">
              Workshop not found
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export const Watch = connect(
  ['auth', 'workshop', 'sizing'],
  (store: GlobalStoreState) => ({
    ...workshopActions(store),
  })
)(addWorkshopComponentActions(withRouter(WatchComponent)));

export default Watch;
