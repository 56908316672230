import * as React from 'react';

import { Redirect, Route } from 'react-router-dom';
import { connect } from 'unistore/react';

import { routes } from '@/routes';

import { authActions } from '@/store/modules/auth';

interface PrivateRouteProps extends AuthState, AuthActions {
  component: any;
  exact: boolean;
  path: string;
}

const PrivateRouteComponent = ({
  component: Component,
  auth,
  logout,
  ...props
}: PrivateRouteProps) => {
  let comp: any = () => null;

  if (auth.user === undefined) {
    logout();
    comp = () => (
      <Redirect
        to={`${routes.login}?to=${encodeURIComponent(
          window.location.pathname
        )}`}
      />
    );
  } else if (auth.user !== null) {
    comp = (p: object) => <Component {...p} />;
  }

  return <Route {...props} render={comp} />;
};

export const PrivateRoute = connect('auth', authActions)(PrivateRouteComponent);
