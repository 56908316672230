import { GlobalModal } from '@/enums/GlobalModal';

const modalState: ModalState = {
  modal: {
    modal: undefined,
    data: {},
    className: '',
  },
};

const modalActions = () => ({
  updateModal: (
    state: GlobalStoreState,
    {
      modal,
      data = {},
      className = '',
      disableClose,
      bodyClassName = '',
      onClose,
    }: {
      modal?: GlobalModal;
      data?: any;
      className?: string;
      disableClose?: boolean;
      bodyClassName?: string;
      onClose?: () => void;
    }
  ) => ({
    modal: {
      modal,
      data,
      className,
      disableClose,
      bodyClassName,
      onClose,
    },
  }),
});

export { modalState, modalActions };
