import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import { connect } from 'unistore/react';

import { Icon } from '@/components/global/Icon/Icon';

import { c } from '@/utils/strings/c';

interface State {
  page: number;
  hasScrolledToEnd?: boolean;
}

interface Props extends SizingState, RouteComponentProps {
  children: React.ReactElement[] | React.ReactElement;
  columns: number;
  padding?: number;
  wrap?: boolean;
  className?: string;
  containerClassName?: string;
  buttonClassName?: string;
  buttonClassNameDisabled?: string;
  counterDotsClassName?: string;
  prevNextIconSize?: string;
  isLoaded?: boolean;
  shadowFix?: boolean;
  disableSnap?: boolean;
  equalHeight?: boolean;
  showFade?: boolean;
  height?: number;
  itemClassName?: string;
  type?: 'navigation-bar';
  hideArrows?: boolean;
  currentIndex?: number;
  currentIndexClassName?: string;
  currentIndexScrollTo?: boolean;
  enableItemWidthAdjustment?: boolean;
  cardOffset?: number;

  // Scroll to end
  scrollToEnd?: boolean;
  expectedItems?: number;

  // Quick fixes
  overrideWidth?: string;
}

class CarouselComponent extends React.Component<Props, State> {
  public state: State = {
    page: 0,
    hasScrolledToEnd: false,
  };

  // Settings
  private MOBILE_SNAP_BACK_THRESHOLD: number = 60;
  private DESKTOP_SCROLL_END_TIMEOUT: number = 300;
  private DESKTOP_SCROLL_UNBLOCK_TIMEOUT: number = 750;

  // Refs
  private carouselContainer: React.RefObject<HTMLDivElement>;
  private carouselItems: React.RefObject<HTMLDivElement>;

  // Mobile
  private startScreenX: number = 0;
  private deltaX: number = 0;

  // Desktop
  private desktopResizeTimeout: NodeJS.Timeout | undefined;
  private desktopScrollTimeout: NodeJS.Timeout | undefined;
  private blockScrollHandler: boolean = false;

  constructor(props: Props) {
    super(props);

    this.carouselContainer = React.createRef();
    this.carouselItems = React.createRef();
  }

  public componentDidMount = () => {
    smoothscroll.polyfill();

    window.addEventListener('resize', this.handleDesktopResize);

    this.scrollToCurrentIndex();
  };

  public componentDidUpdate = () => {
    const { scrollToEnd, expectedItems, children, columns } = this.props;
    const { hasScrolledToEnd } = this.state;

    // If scrollToEnd and expectedItems is assigned, then scroll to the end
    // of the carousel on load
    if (
      scrollToEnd &&
      expectedItems &&
      React.Children.count(children) === expectedItems &&
      !hasScrolledToEnd
    ) {
      this.goTo(React.Children.count(children) / columns);

      setTimeout(() => this.setState({ hasScrolledToEnd: true }), 1000);
    }
  };

  public componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleDesktopResize);
  };

  private scrollToCurrentIndex = () => {
    const { currentIndex, currentIndexScrollTo } = this.props;

    if (
      !currentIndex ||
      !currentIndexScrollTo ||
      !this.carouselItems.current ||
      !this.carouselContainer.current
    ) {
      return;
    }

    const carouselItems =
      this.carouselItems.current.querySelectorAll('.carousel__item');

    if (!carouselItems[currentIndex]) {
      return;
    }

    const { x } = carouselItems[currentIndex].getBoundingClientRect();

    this.carouselContainer.current.scrollTo({ left: x });
  };

  private handleDesktopResize = () => {
    const {
      sizing: { isDesktop },
    } = this.props;

    if (!isDesktop) return;

    if (this.desktopResizeTimeout) {
      clearTimeout(this.desktopResizeTimeout);
    }

    this.blockScrollHandler = true;

    this.desktopResizeTimeout = setTimeout(() => {
      if (!this.carouselContainer.current) return;

      const carouselWidth = this.getCarouselWidth();

      this.carouselContainer.current.scrollTo({
        left: this.state.page * carouselWidth,
        behavior: 'smooth',
      });

      setTimeout(() => {
        this.blockScrollHandler = false;
      }, this.DESKTOP_SCROLL_UNBLOCK_TIMEOUT);
    }, 500);
  };

  private previous = () => {
    if (this.state.page === 0) return;

    this.blockScrollHandler = true;

    const prevPage = this.state.page - 1;

    this.setState({ page: prevPage }, () => this.scrollTo(prevPage));
  };

  private goTo = (newPage: number) => {
    if (this.state.page === newPage) return;

    this.setState({ page: newPage }, () => this.scrollTo(newPage));
  };

  private next = () => {
    const items = this.getCarouselItems(this.props.children);

    if (this.state.page === items.length - 1) return;

    this.blockScrollHandler = true;

    const nextPage = this.state.page + 1;

    this.setState({ page: nextPage }, () => this.scrollTo(nextPage));
  };

  private scrollTo = (page: number) => {
    const {
      sizing: { isDesktop },
    } = this.props;

    const carouselWidth = this.getCarouselWidth();

    if (!this.carouselContainer.current) return;

    if (isDesktop) {
      this.carouselContainer.current.scrollTo({
        left:
          page * carouselWidth +
          page * (this.props.padding ? this.props.padding : 0),
        behavior: 'smooth',
      });

      setTimeout(() => {
        this.blockScrollHandler = false;
      }, this.DESKTOP_SCROLL_UNBLOCK_TIMEOUT);
    } else {
      this.carouselContainer.current.classList.add(
        'carousel__container--animate'
      );

      this.carouselContainer.current.style.transform = `translateX(-${
        page * carouselWidth
      }px)`;

      setTimeout(() => {
        if (this.carouselContainer.current) {
          this.carouselContainer.current.classList.remove(
            'carousel__container--animate'
          );
        }
      }, 250);
    }
  };

  private desktopHandleScroll = () => {
    const {
      sizing: { isDesktop },
      disableSnap,
    } = this.props;

    if (!isDesktop) return;

    if (this.desktopScrollTimeout) {
      clearTimeout(this.desktopScrollTimeout);
    }

    if (this.blockScrollHandler) {
      return;
    }

    this.desktopScrollTimeout = setTimeout(() => {
      this.blockScrollHandler = true;

      const carouselScrollLeft = this.getCarouselScrollLeft();
      const carouselWidth = this.getCarouselWidth();

      const targetPage = Math.round(carouselScrollLeft / carouselWidth);

      this.setState(
        {
          page: targetPage,
        },
        () => {
          if (!disableSnap) {
            this.setScrollLeft();
          }

          setTimeout(() => {
            this.blockScrollHandler = false;
          }, this.DESKTOP_SCROLL_UNBLOCK_TIMEOUT);
        }
      );
    }, this.DESKTOP_SCROLL_END_TIMEOUT);
  };

  private mobileHandleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const {
      sizing: { isDesktop },
      disableSnap,
    } = this.props;

    if (isDesktop || disableSnap) return;

    this.startScreenX = e.touches[0].screenX;
  };

  private mobileHandleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const {
      sizing: { isDesktop },
      disableSnap,
    } = this.props;

    if (isDesktop || !this.carouselContainer.current || disableSnap) return;

    const screenX = e.touches[0].screenX;

    this.deltaX = this.startScreenX - screenX;

    const direction = this.deltaX >= 0 ? 'rightToLeft' : 'leftToRight';

    const carouselWidth = this.getCarouselWidth();

    if (direction === 'rightToLeft') {
      this.carouselContainer.current.style.transform = `translateX(-${
        this.state.page * carouselWidth + this.deltaX
      }px)`;
    } else {
      this.carouselContainer.current.style.transform = `translateX(${
        0 - (this.state.page * carouselWidth + this.deltaX)
      }px)`;
    }
  };

  private mobileHandleTouchEnd = () => {
    const {
      sizing: { isDesktop },
      disableSnap,
    } = this.props;

    if (isDesktop || !this.carouselContainer.current || disableSnap) return;

    const carouselWidth = this.getCarouselWidth();

    this.carouselContainer.current.classList.add(
      'carousel__container--animate'
    );

    const direction = this.deltaX >= 0 ? 'rightToLeft' : 'leftToRight';

    if (direction === 'rightToLeft') {
      if (this.deltaX > this.MOBILE_SNAP_BACK_THRESHOLD) {
        // Snap to next

        const items = this.getCarouselItems(this.props.children);

        if (this.state.page === items.length - 1) {
          this.carouselContainer.current.style.transform = `translateX(-${
            (items.length - 1) * carouselWidth
          }px)`;

          return;
        }

        this.setState({ page: this.state.page + 1 }, () => {
          if (!this.carouselContainer.current) return;

          this.carouselContainer.current.style.transform = `translateX(-${
            this.state.page * carouselWidth
          }px)`;
        });
      } else {
        // Snap back to current
        this.carouselContainer.current.style.transform = `translateX(-${
          this.state.page * carouselWidth
        }px)`;
      }
    } else {
      if (this.deltaX < this.MOBILE_SNAP_BACK_THRESHOLD) {
        // Snap to previous

        if (this.state.page === 0) {
          this.carouselContainer.current.style.transform = 'translateX(0px)';
          return;
        }

        this.setState({ page: this.state.page - 1 }, () => {
          if (!this.carouselContainer.current) return;

          this.carouselContainer.current.style.transform = `translateX(-${
            this.state.page * carouselWidth
          }px)`;
        });
      }
    }

    setTimeout(() => {
      if (this.carouselContainer.current) {
        this.carouselContainer.current.classList.remove(
          'carousel__container--animate'
        );
      }
    }, 250);

    this.deltaX = 0;
  };

  private getCarouselScrollLeft = () => {
    if (!this.carouselContainer.current) return 0;

    return this.carouselContainer.current.scrollLeft;
  };

  private getCarouselWidth = () => {
    if (!this.carouselContainer.current) return 0;

    const carouselComputedStyle = getComputedStyle(
      this.carouselContainer.current
    );

    return (
      this.carouselContainer.current.clientWidth -
      parseFloat(carouselComputedStyle.paddingLeft) -
      parseFloat(carouselComputedStyle.paddingRight)
    );
  };

  private setScrollLeft = () => {
    if (!this.carouselContainer.current) return;

    const carouselWidth = this.getCarouselWidth();

    this.carouselContainer.current.scrollTo({
      left: this.state.page * carouselWidth,
      behavior: 'smooth',
    });
  };

  private getCarouselItems = (
    children: React.ReactElement[] | React.ReactElement
  ): React.ReactElement[] => {
    const items = Array.isArray(children)
      ? children
      : children.props.children && Array.isArray(children.props.children)
      ? children.props.children
      : [children];

    // Remove any null elements and flatten to deal with fragments
    return items.filter(Boolean).flat();
  };

  private renderButtons = () => {
    const {
      prevNextIconSize = 'm',
      buttonClassName = '',
      buttonClassNameDisabled = '',
      wrap,
      hideArrows,
      isLoaded = true,
    } = this.props;
    const { page } = this.state;

    const pages = this.getPages();

    const classNameDisabled = c([
      buttonClassNameDisabled,
      'pointer-events-none opacity-0',
    ]);

    if (hideArrows) return null;

    return (
      <>
        <button
          type="button"
          className={c(
            [
              'carousel__button carousel__button--prev u-flex u-align-center u-justify-center',
              buttonClassName,
            ],
            {
              [classNameDisabled]: page === 0 || !isLoaded,
              'pointer-events-all': page !== 0,
              'carousel__button--prev--wrap': wrap,
            }
          )}
          onClick={this.previous}
        >
          <Icon id="arrow-left" size={prevNextIconSize} />
        </button>

        <button
          type="button"
          className={c(
            [
              'carousel__button carousel__button--next u-flex u-align-center u-justify-center',
              buttonClassName,
            ],
            {
              [classNameDisabled]: page === pages - 1 || !isLoaded,
              'pointer-events-all': page !== pages - 1,
              'carousel__button--next--wrap': wrap,
            }
          )}
          onClick={this.next}
        >
          <Icon id="arrow-right" size={prevNextIconSize} />
        </button>
      </>
    );
  };

  public renderCounterDots = () => {
    const { counterDotsClassName = '', isLoaded } = this.props;
    const { page } = this.state;

    if (!isLoaded && typeof isLoaded !== 'undefined') return null;

    const pages = this.getPages();

    return (
      <div className={c(['u-flex u-justify-center', counterDotsClassName])}>
        {Array.from(Array(pages).keys()).map((_, index: number) => (
          <button
            type="button"
            className="p4 u-flex u-align-center"
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            onClick={() => this.goTo(index)}
          >
            <span
              className={c('counter-dot', {
                'counter-dot--white': index === page,
                'counter-dot--grey': index !== page,
              })}
            />
          </button>
        ))}
      </div>
    );
  };

  private getColumnWidth = (): number => {
    const { columns } = this.props;

    return 100 / columns;
  };

  private getPages = (): number => {
    const { children, columns } = this.props;

    const items = this.getCarouselItems(children);

    if (columns) {
      return Math.ceil(items.length / columns);
    } else {
      return items.length;
    }
  };

  public render = () => {
    const {
      children,
      className = '',
      wrap,
      padding: paddingRight,
      shadowFix,
      disableSnap,
      sizing: { isMobile },
      equalHeight,
      showFade,
      height,
      itemClassName = '',
      type,
      containerClassName = '',
      currentIndex,
      currentIndexClassName = '',
      columns,
      enableItemWidthAdjustment = true,
      scrollToEnd = false,
      location: { pathname },
      overrideWidth,
      cardOffset = 0,
    } = this.props;

    const { hasScrolledToEnd } = this.state;

    const items = this.getCarouselItems(children);

    const columnWidth = this.getColumnWidth();

    const isPathnameCommunity = pathname.includes('community');

    return (
      <div
        className={c([className, 'animate-opacity'], {
          'opacity-1': !scrollToEnd || (scrollToEnd && hasScrolledToEnd),
          'opacity-0': scrollToEnd && !hasScrolledToEnd,
        })}
      >
        <div
          className={c('carousel', {
            'carousel--wrap': wrap,
            [`carousel--${type}`]: type,
          })}
        >
          <div
            className={c('carousel__buttons', {
              'carousel__buttons--fade': showFade,
            })}
          >
            {this.renderButtons()}
          </div>

          <div
            ref={this.carouselContainer}
            className={c(['carousel__container', containerClassName], {
              'carousel__container--disable-snap': disableSnap,
            })}
            onScroll={this.desktopHandleScroll}
            onTouchStart={this.mobileHandleTouchStart}
            onTouchMove={this.mobileHandleTouchMove}
            onTouchEnd={this.mobileHandleTouchEnd}
          >
            <div
              ref={this.carouselItems}
              className={c('carousel__track', {
                'pt2 pb4 pb12@l': shadowFix,
              })}
              style={{ height }}
            >
              {items.map((item: React.ReactNode, key: number) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={key}
                  className={c(['carousel__item', itemClassName], {
                    'carousel__item--equal-height': equalHeight,
                    [currentIndexClassName]:
                      typeof currentIndex !== 'undefined' &&
                      currentIndex === key,
                  })}
                  style={{
                    width: overrideWidth
                      ? overrideWidth
                      : columnWidth && (!isPathnameCommunity || !isMobile)
                      ? `calc(${columnWidth}% + ${cardOffset}px + ${
                          paddingRight && enableItemWidthAdjustment
                            ? `${paddingRight / columns}px`
                            : '0px'
                        })`
                      : `calc(${columnWidth * 5}% + ${cardOffset}px + ${
                          paddingRight && enableItemWidthAdjustment
                            ? `${paddingRight / columns}px`
                            : '0px'
                        })`,
                    paddingRight:
                      items.length === 1 && isMobile ? undefined : paddingRight,
                  }}
                >
                  {item}
                </div>
              ))}
              <div
                className="carousel__track__spacer"
                style={{
                  width: `calc(((100vw - 100%) / 2) - ${
                    paddingRight ? paddingRight : 0
                  }px)`,
                }}
              />
            </div>
          </div>
        </div>

        {this.renderCounterDots()}
      </div>
    );
  };
}

export const Carousel = connect(['sizing'], () => ({}))(
  withRouter(CarouselComponent)
);
