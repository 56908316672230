import * as React from 'react';

import { Skeleton } from '@/components/global/Skeleton/Skeleton';

import { URL_AVATAR_PLACEHOLDER } from '@/utils/constants';

const UserCardboardSkeleton = () => (
  <div className="u-flex u-card u-card--dark p16 u-align-center u-1/1">
    <div className="avatar__container relative mr12 u-flex-hold">
      <img
        src={URL_AVATAR_PLACEHOLDER}
        className="avatar avatar--xxl"
        alt="avatar placeholder"
      />
    </div>

    <div className="u-flex u-flex-column">
      <Skeleton width="80%" className="mb8" />
      <Skeleton width="50%" height="0.8em" className="mb8" />
      <Skeleton width="50%" height="0.8em" />
    </div>
  </div>
);

export { UserCardboardSkeleton };
