import * as React from 'react';

import { connect } from 'unistore/react';

import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import imgAvatar1 from '@/components/global/WorkshopReview/images/1.jpg';
import imgAvatar2 from '@/components/global/WorkshopReview/images/2.jpg';
import imgAvatar3 from '@/components/global/WorkshopReview/images/3.jpg';
import imgAvatar4 from '@/components/global/WorkshopReview/images/4.jpg';
import imgAvatar5 from '@/components/global/WorkshopReview/images/5.jpg';
import imgAvatar6 from '@/components/global/WorkshopReview/images/6.jpg';
import imgAvatar7 from '@/components/global/WorkshopReview/images/7.jpg';
import imgAvatar8 from '@/components/global/WorkshopReview/images/8.jpg';
import imgAvatar9 from '@/components/global/WorkshopReview/images/9.jpg';
import imgAvatar10 from '@/components/global/WorkshopReview/images/10.jpg';
import imgAvatar11 from '@/components/global/WorkshopReview/images/11.jpg';
import imgAvatar12 from '@/components/global/WorkshopReview/images/12.jpg';
import imgReviewStars from '@/components/global/WorkshopReview/images/review-stars.svg';
import { Avatar } from '@/components/users/Avatar/Avatar';

import { REVIEW_RATING, REVIEW_SAMPLE_SIZE } from '@/utils/constants';
import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface State extends SizingState {}

class WorkshopReviewComponent extends React.Component<State> {
  private renderReviewsSlideLeft = () => {
    const classNameForCard =
      'u-border-radius b--sad-grey pv44@m pv60@s ph32 mb32@m mr20@s u-75vw@s u-flex@s u-flex-column@s u-justify-center@s';

    return (
      <>
        <div className={classNameForCard}>
          <p className="pb24">
            {t(
              "It's greatly helpful to have young directors talking to and advising us. Best workshop ever."
            )}
          </p>
          <div className="u-flex u-align-center">
            <Avatar
              src={imgAvatar1}
              user={null}
              size="xl"
              className="mr16 mv2"
            />
            <p>{t('Laila, South America')}</p>
          </div>
        </div>
        <div className={classNameForCard}>
          <p className="pb24">
            {t(
              'The host made sure to answer every question and address every comment. It felt like we were hanging out together!'
            )}
          </p>
          <div className="u-flex u-align-center">
            <Avatar
              src={imgAvatar10}
              user={null}
              size="xl"
              className="mr16 mv2"
            />
            <p>{t('James, UK')}</p>
          </div>
        </div>
        <div className={classNameForCard}>
          <p className="pb24">
            {t(
              'Daisie is literally the best thing that happened to me all year.'
            )}
          </p>
          <div className="u-flex u-align-center">
            <Avatar
              src={imgAvatar3}
              user={null}
              size="xl"
              className="mr16 mv2"
            />
            <p>{t('Maya, Sweden')}</p>
          </div>
        </div>
        <div className={classNameForCard}>
          <p className="pb24">
            {t(
              'To be able to talk to such a renowned creator was amazing and I enjoyed it a lot.'
            )}
          </p>
          <div className="u-flex u-align-center">
            <Avatar
              src={imgAvatar12}
              user={null}
              size="xl"
              className="mr16 mv2"
            />
            <p>{t('Minie, US')}</p>
          </div>
        </div>
        <div className={classNameForCard}>
          <p className="pb24">
            {t(
              'This felt like a really positive space and it made me feel really motivated and hopeful about my creative journey.'
            )}
          </p>
          <div className="u-flex u-align-center">
            <Avatar
              src={imgAvatar8}
              user={null}
              size="xl"
              className="mr16 mv2"
            />
            <p>{t('Jonathan, UK')}</p>
          </div>
        </div>
        <div className={classNameForCard}>
          <p className="pb24">
            {t(
              'Workshops make me feel connected to the community and keep me creative.'
            )}
          </p>
          <div className="u-flex u-align-center">
            <Avatar
              src={imgAvatar6}
              user={null}
              size="xl"
              className="mr16 mv2"
            />
            <p>{t('Sanna, Asia')}</p>
          </div>
        </div>
      </>
    );
  };

  private renderReviewsSlideRight = () => {
    const classNameForCard =
      'u-border-radius b--sad-grey pv44@m pv60@s ph32 mb32@m mr20@s u-75vw@s u-flex@s u-flex-column@s u-justify-center@s';

    return (
      <>
        <div className={classNameForCard}>
          <p className="pb24">
            {t('The workshop gave everyone such an amazing boost.')}
          </p>
          <div className="u-flex u-align-center">
            <Avatar
              src={imgAvatar7}
              user={null}
              size="xl"
              className="mr16 mv2"
            />
            <p>{t('Tim, US')}</p>
          </div>
        </div>
        <div className={classNameForCard}>
          <p className="pb24">
            {t(
              'It felt good to learn and be engaged and talk about a topic I’m passionate about. I am excited to join more workshops!'
            )}
          </p>
          <div className="u-flex u-align-center">
            <Avatar
              src={imgAvatar11}
              user={null}
              size="xl"
              className="mr16 mv2"
            />
            <p>{t('Nicole, US')}</p>
          </div>
        </div>
        <div className={classNameForCard}>
          <p className="pb24">
            {t(
              'I found it comforting to hear from an experienced creative and loved the engagement from the workshop chat.'
            )}
          </p>
          <div className="u-flex u-align-center">
            <Avatar
              src={imgAvatar9}
              user={null}
              size="xl"
              className="mr16 mv2"
            />
            <p>{t('Tracey, US')}</p>
          </div>
        </div>
        <div className={classNameForCard}>
          <p className="pb24">
            {t(
              'It was great to hear from someone operating and pushing into the top tiers of the industry'
            )}
          </p>
          <div className="u-flex u-align-center">
            <Avatar
              src={imgAvatar5}
              user={null}
              size="xl"
              className="mr16 mv2"
            />
            <p>{t('Jess, UK')}</p>
          </div>
        </div>
        <div className={classNameForCard}>
          <p className="pb24">
            {t(
              'This workshop was awesome! It was like someone made a lecture out of all the little internal debates you have.'
            )}
          </p>
          <div className="u-flex u-align-center">
            <Avatar
              src={imgAvatar2}
              user={null}
              size="xl"
              className="mr16 mv2"
            />
            <p>{t('Steven, US')}</p>
          </div>
        </div>
        <div className={classNameForCard}>
          <p className="pb24">
            {t(
              'This host is an iconic and renowned figure - his advice and insight was incredibly valuable.'
            )}
          </p>
          <div className="u-flex u-align-center">
            <Avatar
              src={imgAvatar4}
              user={null}
              size="xl"
              className="mr16 mv2"
            />
            <p>{t('Keri, Belgium')}</p>
          </div>
        </div>
      </>
    );
  };

  public render = () => {
    const {
      sizing: { isMobile, isTablet, isDesktop },
    } = this.props;

    return (
      <div
        className={c('u-flex@m mhauto u-align-center u-justify-center', {
          'u-4/5': isDesktop,
          'u-7/8': isTablet,
        })}
      >
        <div className="u-1/3@m u-text-center@s mb44@s">
          {!isMobile && (
            <h3 className="f-title-3 u-bold pb32@m u-7/8@s u-3/4@m mhauto@s">
              {t(
                `Students give Daisie an average rating ${REVIEW_RATING} of 5. See what they say about our classes.`
              )}
            </h3>
          )}
          <h1
            className={c('f-shout-1 pv20@s', {
              'c-workshop-review--score': isMobile,
            })}
          >
            {REVIEW_RATING}
          </h1>
          <ImageLoader
            src={imgReviewStars}
            alt="review stars"
            draggable={false}
            className="mhauto@s pt12 pb16"
          />
          <p className="u-dark-grey">
            {t(`${REVIEW_SAMPLE_SIZE.toLocaleString()}+ reviews`)}
          </p>
        </div>

        {!isMobile ? (
          <div
            className="u-flex u-2/3@m u-1/2@l u-overflow-hidden c-workshop-review relative"
            style={{ height: '55vh' }}
          >
            <div className="c-workshop-review--slide-left pr8">
              <div className="mr16@m">{this.renderReviewsSlideLeft()}</div>
              <div className="mr16@m">{this.renderReviewsSlideLeft()}</div>
            </div>
            <div className="c-workshop-review--slide-right">
              <div className="mr16@m">{this.renderReviewsSlideRight()}</div>
              <div className="mr16@m">{this.renderReviewsSlideRight()}</div>
            </div>
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                padding: '35px 0',
                backgroundImage:
                  'linear-gradient(to bottom, transparent, black)',
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                width: '100%',
                padding: '35px 0',
                backgroundImage: 'linear-gradient(to top, transparent, black)',
              }}
            />
          </div>
        ) : (
          <div className="u-overflow-hidden u-flex">
            <div className="c-workshop-review--slide-row u-flex">
              {this.renderReviewsSlideLeft()}
              {this.renderReviewsSlideRight()}
              {this.renderReviewsSlideLeft()}
              {this.renderReviewsSlideRight()}
            </div>
          </div>
        )}
      </div>
    );
  };
}

export const WorkshopReview = connect(['sizing'])(WorkshopReviewComponent);
