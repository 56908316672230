import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'unistore/react';

import { ManageBlockedUsers } from '@/components/accounts/AccountSettings/ManageBlockedUsers';
import { PrivateProfileModalPortal } from '@/components/accounts/AccountSettings/PrivateProfileModalPortal';
import {
  BlockUser,
  DeleteAccount,
  ReportUser,
} from '@/components/application/Loadables';
import { CloseButton } from '@/components/global/Button/CloseButton';
import { CalendarEventUnattendConfirm } from '@/components/global/Cards/CalendarEventUnattendConfirm';
import { Portal } from '@/components/global/Portal/Portal';
import { ProfilePictureCrop } from '@/components/global/ProfilePictureCrop/ProfilePictureCrop';
import { SettingsOverlay } from '@/components/global/SettingsOverlay/SettingsOverlay';
import { ShareLink } from '@/components/global/ShareLink/ShareLink';
import { ShareOptions } from '@/components/share/ShareOptions';
import { SubscriptionCancelConfirmModal } from '@/components/subscription/SubscriptionSettings/SubscriptionCancelConfirmModal';
import { UserProfileFollowersModal } from '@/components/users/UserProfile/UserProfileFollowersModal';
import { UserProfileMenu } from '@/components/users/UserProfile/UserProfileMenu';
import { WorkshopAddEmail } from '@/components/workshops/WorkshopAddEmail';
import { WorkshopAttendeesModal } from '@/components/workshops/WorkshopAttendeesModal';

import { GlobalPortal } from '@/enums/GlobalPortal';
import { IconSize } from '@/enums/IconSize';

import { InstagramAssetsModalPortal } from '@/pages/InstructorDashboard/InstagramAssetsModalPortal';

import { portalActions } from '@/store/modules/portal';

import { SHARE_LINK_JOIN_DAISIE } from '@/utils/constants';
import { c } from '@/utils/strings/c';

interface Props extends PortalState, PortalActions, RouteComponentProps {}

class SettingsTrayComponent extends React.Component<Props> {
  public componentDidMount() {
    window.addEventListener('popstate', this.escapeListener);
  }

  public componentWillUnmount() {
    window.removeEventListener('popstate', this.escapeListener);
  }

  private clearPortal = () => {
    const { data = {} } = this.props.portal;

    if (data.disableClose) return;

    this.props.updatePortal({ portal: undefined });
  };

  private escapeListener = () => {
    if (this.open()) {
      this.clearPortal();
    }
  };

  private open = () => !!this.props.portal.portal;

  public render() {
    const { portal, data = {} } = this.props.portal;

    const {
      block_user,
      invite_friends,
      manage_blocked_users,
      private_profile,
      report_user,
      share_current_page,
      share_link,
      share_project,
      share_profile,
      workshop_attendees,
      calendar_event_unattend_confirm,
      mentor_followers,
      profile_menu,
      workshop_add_email,
      delete_account,
      workshop_instagram_assets,
    } = GlobalPortal;

    const header =
      {
        [block_user]: () =>
          `${data.user.hasBlocked ? 'Unblock' : 'Block'} ${
            data.user.primaryName
          }`,
        [invite_friends]: () => 'Invite friends',
        [manage_blocked_users]: () => 'Blocked people',
        [private_profile]: () => 'Make your profile private?',
        [report_user]: () => 'Could you tell us more?',
        [share_current_page]: () => data.title || 'Share',
        [share_link]: () => data.title || 'Share',
        [share_project]: () => 'Share',
        [share_profile]: () => 'Share',
        [workshop_instagram_assets]: () => 'Download assets',
      }[portal] || '';

    const headerClassName = 'mb24';

    const hideCloseButton =
      {
        [block_user]: () => true,
        [invite_friends]: () => true,
        [private_profile]: () => true,
        [report_user]: () => true,
        [share_current_page]: () => true,
        [share_link]: () => true,
      }[portal] || false;

    const darkMode =
      {
        [workshop_attendees]: () => true,
        [mentor_followers]: () => true,
        [manage_blocked_users]: () => true,
        [private_profile]: () => true,
        [calendar_event_unattend_confirm]: () => true,
        [workshop_add_email]: () => true,
        [share_link]: () => true,
        [delete_account]: () => true,
        [share_profile]: () => true,
        [workshop_instagram_assets]: () => true,
        [share_current_page]: () => true,
      }[portal] ||
      data.isDarkMode ||
      false;

    const fullHeight =
      {
        [share_current_page]: () => true,
      }[portal] ||
      data.fullHeight ||
      false;

    const horizontalTransition =
      {
        [share_current_page]: () => true,
      }[portal] ||
      data.transitionDirection ||
      undefined;

    const overrideContainerClassName =
      {
        [workshop_attendees]: () => 'mt0',
      }[portal] || undefined;

    return (
      <Portal
        component={
          <SettingsOverlay
            close={this.clearPortal}
            type={portal}
            isDarkMode={darkMode}
            fullHeight={fullHeight}
            horizontalTransition={horizontalTransition}
          >
            {portal && (
              <div
                className={
                  overrideContainerClassName
                    ? overrideContainerClassName()
                    : header
                    ? ''
                    : 'mt24'
                }
              >
                {header && (
                  <header
                    className={c(
                      [
                        'settings-overlay__header u-sticky pt20 pb16 u-flex u-justify-between u-align-center',
                        headerClassName,
                      ],
                      {
                        'u-bg-white bb': !darkMode,
                        'u-bg-charcoal-grey u-white bb--dark-grey': darkMode,
                      }
                    )}
                  >
                    <h1 className="f-title-2 u-bold">{header()}</h1>
                    {!hideCloseButton && (
                      <CloseButton
                        onClick={
                          data.clearPortal ? data.clearPortal : this.clearPortal
                        }
                        size={IconSize.xs}
                      />
                    )}
                  </header>
                )}
                {portal === GlobalPortal.block_user && (
                  <BlockUser
                    toggle={data.returnTo}
                    user={data.user}
                    onSuccess={this.clearPortal}
                  />
                )}
                {portal === GlobalPortal.delete_account && (
                  <DeleteAccount toggle={this.clearPortal} />
                )}
                {portal === GlobalPortal.mentor_followers && (
                  <UserProfileFollowersModal users={data.followers} />
                )}
                {portal === GlobalPortal.invite_friends && (
                  <ShareLink
                    from={data.from}
                    link={SHARE_LINK_JOIN_DAISIE}
                    mixpanelTrackEventPrefix="invite"
                    isDarkMode={darkMode}
                    twitterText={data.twitterText}
                  />
                )}
                {portal === GlobalPortal.private_profile && (
                  <PrivateProfileModalPortal />
                )}
                {portal === GlobalPortal.report_user && (
                  <ReportUser
                    user={data.user}
                    toggle={data.returnTo}
                    onSuccess={this.clearPortal}
                  />
                )}
                {portal === share_current_page && (
                  <ShareLink
                    from={data.from}
                    mixpanelTrackEventPrefix={data.mixpanelTrackEventPrefix}
                    isDarkMode={darkMode}
                    twitterText={data.twitterText}
                    emailSubject={data.emailSubject}
                  />
                )}
                {portal === share_link && (
                  <ShareLink
                    from={data.from}
                    link={data.link}
                    mixpanelTrackEventPrefix={data.mixpanelTrackEventPrefix}
                    isDarkMode={darkMode}
                    twitterText={data.twitterText}
                    emailSubject={data.emailSubject}
                  />
                )}
                {portal === share_project && (
                  <ShareOptions room={data.project} />
                )}
                {portal === profile_menu && (
                  <UserProfileMenu toggle={this.clearPortal} user={data.user} />
                )}
                {portal === GlobalPortal.profile_picture_crop && (
                  <ProfilePictureCrop
                    image={data.image}
                    fileName={data.fileName}
                    fileMimeType={data.fileMimeType}
                  />
                )}
                {portal === manage_blocked_users && (
                  <ManageBlockedUsers toggle={this.clearPortal} />
                )}
                {portal === GlobalPortal.share_profile && (
                  <ShareOptions user={data.user} />
                )}
                {portal === GlobalPortal.workshop_attendees && (
                  <WorkshopAttendeesModal
                    users={data.attendees}
                    close={this.clearPortal}
                  />
                )}
                {portal === GlobalPortal.subscription_cancel_confirm && (
                  <SubscriptionCancelConfirmModal />
                )}
                {portal === calendar_event_unattend_confirm && (
                  <CalendarEventUnattendConfirm
                    onUnattend={data.onUnattend}
                    closeModalPortal={this.clearPortal}
                  />
                )}
                {portal === GlobalPortal.workshop_add_email && (
                  <WorkshopAddEmail
                    onComplete={data.onComplete}
                    closeModalPortal={this.clearPortal}
                  />
                )}
                {portal === GlobalPortal.workshop_instagram_assets && (
                  <InstagramAssetsModalPortal workshop={data.workshop} />
                )}
              </div>
            )}
          </SettingsOverlay>
        }
        to=".settings-tray"
      />
    );
  }
}

export const SettingsTray = withRouter(
  connect('portal', portalActions)(SettingsTrayComponent)
);
