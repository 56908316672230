import * as React from 'react';

import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'unistore/react';
import widont from 'widont';

import { FAQ } from '@/components/global/FAQ/FAQ';
import { Head } from '@/components/global/Head/Head';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import { Link } from '@/components/global/Link/Link';
import { AnimatedWordmark } from '@/components/global/Wordmark/AnimatedWordmark';
import { Wordmark } from '@/components/global/Wordmark/Wordmark';
import { WorkshopReview } from '@/components/global/WorkshopReview/WorkshopReview';
import { SectionPromo } from '@/components/marketing/HomeCategoryPageSections/SectionPromo/SectionPromo';
import { SectionWorkshops } from '@/components/marketing/HomeCategoryPageSections/SectionWorkshops/SectionWorkshops';
import { PressLinks } from '@/components/marketing/PressLinks/PressLinks';
import {
  AB_TEST_DISCOUNT_PERCENT_OFF_DISPLAY,
  GRANDFATHER_DISCOUNT_PERCENT_OFF_YEARLY_DISPLAY_GBP_EUR,
  GRANDFATHER_DISCOUNT_PERCENT_OFF_YEARLY_DISPLAY_USD,
} from '@/components/subscription/constants';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { Currency } from '@/enums/Currency';
import { DaisieWordmarkColour } from '@/enums/DaisieWordmarkColour';

import imgDaisieMobile from '@/pages/Category/images/daisie-mobile.png';
import imgHeroBackground from '@/pages/GrandfatheredUserHomepage/images/hero-background.jpg';
import imgIphoneFrame from '@/pages/GrandfatheredUserHomepage/images/iphone-frame-charcoal-grey.svg';
import { OptimisedJoinFlowSkipCloseButton } from '@/pages/OptimisedJoinFlow/OptimisedJoinFlowSkipCloseButton';

import { routes } from '@/routes';

import { authActions } from '@/store/modules/auth';
import { subscriptionActions } from '@/store/modules/subscription';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props
  extends SizingState,
    SubscriptionState,
    SubscriptionActions,
    AuthActions,
    AuthState,
    ABTestState,
    RouteComponentProps {
  isGetStartedPromoPage?: boolean;
}

interface State {
  redirectToHome: boolean;
}

class GrandfatheredUserHomepageComponent extends React.Component<Props, State> {
  public state: State = {
    redirectToHome: false,
  };

  public componentDidMount = async () => {
    const {
      auth: { user },
      isGetStartedPromoPage,
    } = this.props;

    if (isGetStartedPromoPage && !!user && user.subscriptionTier !== null) {
      this.setState({ redirectToHome: true });
      return;
    }

    await this.props.getStripeProductsAndPrices();
  };

  public render = () => {
    const {
      sizing: { isMobile },
      isGetStartedPromoPage: isGetStartedPromoPageProp,
      abtest: { variant },
      auth: { isAuthorised, user },
      subscription: { userCurrency },
    } = this.props;

    const { redirectToHome } = this.state;

    const isGetStartedPromoPage: boolean =
      !!user && user.isGrandfathered ? false : !!isGetStartedPromoPageProp;

    const renderJoinButton = () =>
      isGetStartedPromoPage ? (
        <Button
          type={ButtonType.link}
          linkTo={
            isAuthorised
              ? routes.optimisedJoinFlowChoosePlan
              : typeof variant !== 'undefined' && variant === 2
              ? routes.optimisedJoinFlowSignUpYearly
              : routes.optimisedJoinFlowChoosePlan
          }
          buttonStyle={ButtonStyle.default}
          size={ButtonSize.l}
          text={
            typeof variant !== 'undefined' && variant === 2
              ? t(`Get ${AB_TEST_DISCOUNT_PERCENT_OFF_DISPLAY}% off`)
              : t('Try for free')
          }
          className="u-fit-content mt24"
        />
      ) : (
        <Button
          type={ButtonType.link}
          linkTo={routes.optimisedJoinFlowChoosePlan}
          text={t('Upgrade')}
          buttonStyle={ButtonStyle.default}
          size={ButtonSize.l}
          className={c('u-fit-content mt24', {
            'u-1/1': isMobile,
          })}
        />
      );

    if (redirectToHome) {
      return <Redirect to={routes.home} />;
    }

    return (
      <>
        <Head />

        {isAuthorised && (
          <OptimisedJoinFlowSkipCloseButton type="close" linkTo={routes.home} />
        )}

        <div className="u-bg-black u-white">
          <div className="u-h-100vh@m u-1/1 relative c-grandfathered-homepage__hero_container u-overflow-hidden">
            <div
              className={c(
                'wrap u-1/1 absolute absolute--x-center u-z-index-1 u-flex u-align-center',
                {
                  'u-justify-center': isGetStartedPromoPage,
                }
              )}
              style={{ height: 72 }}
            >
              <Link to={routes.home}>
                <Wordmark
                  colour={DaisieWordmarkColour.White}
                  height="20px"
                  className="u-hide@m absolute absolute--x-center"
                />
                <AnimatedWordmark
                  className="u-hide@s"
                  colour={DaisieWordmarkColour.White}
                />
              </Link>
            </div>

            <div className="c-grandfathered-homepage--hero relative u-h-100">
              <ImageLoader
                src={imgHeroBackground}
                className={c('absolute', {
                  'c-grandfathered-homepage__hero_background_img': isMobile,
                })}
              />
              <div className="wrap u-h-100 u-flex u-flex-column@s u-align-center@m">
                <div className="relative mvauto u-flex-1@m u-text-center@s mt80@s">
                  <h1 className="f-lynstone-bold-1 mb44">
                    {isGetStartedPromoPage ? (
                      <>
                        <span className="u-block">{t(`Let's get `)}</span>
                        <span className="u-block">{t(`started.`)}</span>
                      </>
                    ) : (
                      t('Years in the making.')
                    )}
                  </h1>

                  {isGetStartedPromoPage ? (
                    <p className="mb44 f-lynstone-regular-2a">
                      {t(
                        'Daisie brings creators together in live classes hosted by industry-leading experts.'
                      )}
                    </p>
                  ) : (
                    <p className="mb44 f-lynstone-regular-2a">
                      {t('Commit to your creativity.')}
                      <br /> {t('Get founding member access today')}
                      <span className="u-go">
                        {widont(
                          t(
                            ` for ${
                              userCurrency === Currency.usd
                                ? GRANDFATHER_DISCOUNT_PERCENT_OFF_YEARLY_DISPLAY_USD
                                : GRANDFATHER_DISCOUNT_PERCENT_OFF_YEARLY_DISPLAY_GBP_EUR
                            }% off.`
                          )
                        )}
                      </span>
                    </p>
                  )}

                  {!isMobile && renderJoinButton()}
                </div>
                <div className="u-flex-1@m u-flex u-justify-center u-align-center u-overflow-hidden">
                  <ImageLoader
                    src={imgIphoneFrame}
                    className="u-1/1@s relative u-flex u-z-index-1 u-align-center u-justify-center pointer-events-none"
                    noAnimations={true}
                    style={{
                      height: '555px',
                      width: '297px',
                    }}
                  />
                  <div className="absolute u-flex u-align-center u-justify-center u-border-radius u-overflow-hidden c-grandfathered-homepage__hero_video">
                    <iframe
                      src="https://player.vimeo.com/video/571190914"
                      width="640"
                      height="1138"
                      frameBorder="0"
                      allow="fullscreen; picture-in-picture"
                      allowFullScreen
                      title="grandfathering-video"
                    />
                  </div>
                </div>

                {isMobile && (
                  <div className="u-flex u-justify-center">
                    {renderJoinButton()}
                  </div>
                )}

                {isMobile && (
                  <PressLinks theme="dark" className="mv44@m mv60@s" />
                )}
              </div>
            </div>

            {!isMobile && (
              <PressLinks
                theme="dark"
                className="mb32 u-1/1@s m0@m pv64@m absolute absolute--bottom-center"
              />
            )}
          </div>

          <div className="u-flex u-flex-column u-align-center@m wrap mt128@m u-text-center@s">
            <h2
              className={c('mb24@m mb16@s', {
                'f-lynstone-bold-1a': !isMobile,
                'f-lynstone-bold-2': isMobile,
              })}
            >
              {isGetStartedPromoPage
                ? t('Creativity is everything.')
                : t('A new era for creators.')}
            </h2>
            <p className="f-lynstone-book-2a">
              {widont(
                isGetStartedPromoPage
                  ? t('Join the world’s fastest growing creative community.')
                  : t(
                      'Join thousands already enrolled in the next-gen art school.'
                    )
              )}
            </p>
          </div>

          <SectionPromo
            isGrandfatheringPage={isGetStartedPromoPage ? false : true}
          />

          <SectionWorkshops
            theme="dark"
            ctaButton={
              isGetStartedPromoPage ? (
                renderJoinButton()
              ) : (
                <Button
                  type={ButtonType.link}
                  linkTo={routes.optimisedJoinFlowChoosePlan}
                  text={t('Upgrade')}
                  buttonStyle={ButtonStyle.default}
                  size={ButtonSize.l}
                  className="u-fit-content mt24"
                />
              )
            }
          />

          <div className="wrap u-flex u-align-center@m mb64 mb200@m u-7/8@m u-flex-column-reverse@s">
            <div className="u-flex-1@m mr44@m">
              <ImageLoader
                src={imgDaisieMobile}
                style={{ marginRight: 0, marginLeft: 'auto' }}
              />
            </div>
            <div className="u-flex-1@m ml44@m mb32@s">
              <div className="">
                <h2 className="f-lynstone-bold-1a mb24@m mb16@s">
                  {widont(t('Create your own schedule'))}
                </h2>
                <p className="f-lynstone-book-2a">
                  {t('Choose classes that fit your lifestyle.')}
                </p>

                {!isMobile && renderJoinButton()}
              </div>
            </div>
          </div>

          <WorkshopReview />

          <div className="wrap">
            {!isMobile && (
              <div className="u-line-behind u-line-behind--black u-grey pv128@m" />
            )}
            <FAQ />
          </div>
        </div>
      </>
    );
  };
}

export const GrandfatheredUserHomepage = connect(
  ['sizing', 'subscription', 'auth', 'abtest'],
  (store: GlobalStoreState) => ({
    ...subscriptionActions(store),
    ...authActions(store),
  })
)(withRouter(GrandfatheredUserHomepageComponent));
