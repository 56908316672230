import * as React from 'react';

import widont from 'widont';

import { Icon } from '@/components/global/Icon/Icon';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';

import { Avatar } from '@/components/design_system/Avatar/Avatar';

import { IconSize } from '@/enums/IconSize';

import { formatSeconds } from '@/utils/media/formatSeconds';

interface Props {
  onDemandMedia: OnDemandMedia;
  onClick: (attachmentUrl: string) => void;
}

class CollectionClipCard extends React.Component<Props> {
  public componentDidMount = async () => {};

  public render = () => {
    const {
      onDemandMedia: { title, workshop, attachmentUrl, duration },
    } = this.props;

    return (
      <button
        type="button"
        onClick={() => this.props.onClick(attachmentUrl)}
        className="c-collection-clip-card relative"
      >
        {!!workshop && workshop.coverMedia && (
          <ImageLoader
            src={workshop.coverMedia}
            className="fill u-object-cover"
          />
        )}

        <Icon
          id="play"
          size={IconSize.xxl}
          className="u-white absolute absolute--mid-center u-z-index-1"
        />

        {!!duration && (
          <p className="absolute absolute--tr mt24 mr24 u-white u-border-radius ph8 pv4 u-bg-translucent-black f-text-3">
            {formatSeconds(duration)}
          </p>
        )}

        <div className="absolute absolute--bl u-1/1 p24 u-white u-z-index-1">
          {!!workshop && !!workshop.mentor && (
            <p className="u-flex u-align-center mb16">
              <Avatar user={workshop.mentor} className="mr8" />

              <span>
                {workshop.mentor.name !== ''
                  ? workshop.mentor.name
                  : workshop.mentor.username}
              </span>
            </p>
          )}
          <p className="f-title-2 u-bold">{widont(title)}</p>
        </div>

        <div className="c-collection-clip-card__fade" />
      </button>
    );
  };
}

export { CollectionClipCard };
