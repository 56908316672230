const routes = {
  about: '/about',
  app: '/app',
  account: '/account',
  accountSettings: '/account/settings',
  appStoreReferral: '/get/:id',
  cancellationPolicy: '/terms/cancellation-policy',
  careers: '/careers',
  careersListing: '/careers/:slug',
  classes: '/classes',
  categoryIndex: '/community/:slug',
  cryptoSignUp: '/crypto',
  industryRedirect: '/industry/:slug',
  categoryRedirect: '/category/:slug',
  collection: '/collection/:collectionId',
  communityGuidelines: '/community-guidelines',
  contact: '/contact',
  cookiePolicy: '/cookie-policy',
  daisiePrompts: '/daisie-prompts',
  daisieWorkshops: '/daisie-workshops',
  designSystemGuide: '/design-system-guide',
  diagnosticTool: '/help/info',
  calendar: '/calendar',
  editProfile: '/account/edit',
  explore: '/explore',
  getStarted: '/getstarted',
  getDiscount: '/get-discount',
  help: '/help',
  helpCentre: '/help-centre',
  helpCenter: '/help-center',
  firstProjectHelp: '/help-first-project',
  following: '/me/following',
  forgot: '/forgot-password',
  home: '/',
  alexJenkinsClass: '/AlexJenkinsClass',
  login: '/login',
  logout: '/logout',
  me: '/me',
  instructorDashboard: '/instructor-dashboard',
  myInterests: '/account/interests',
  notificationPreferences: '/account/notifications',
  notifications: '/notifications',
  projectsCreate: '/projects/new',
  roomsCreate: '/rooms/new',
  project: '/projects/:projectSlug',
  room: '/rooms/:roomSlug',
  projects: '/projects',
  rooms: '/rooms',
  privacyPolicy: '/privacy',
  register: '/join',
  reportCenter: '/report-center',
  safetyCenter: '/safety-center',
  search: '/search',
  students: '/students',
  studentsChoosePlan: '/students/choose-plan',
  studentsSignUpMonthly: '/students/monthly',
  studentsSignUpMonthlyPay: '/students/monthly/pay',
  studentsSignUpYearly: '/students/yearly',
  studentsSignUpYearlyPay: '/students/yearly/pay',
  subscriptionSettings: '/account/subscription',
  teach: '/teachwithdaisie',
  terms: '/terms',
  campaignTerms: '/terms/:campaign',
  unsubscribe: '/unsubscribe',
  workshop: '/workshops/:workshopSlug',
  workshopOverview: '/workshops/overview/:workshopSlug',
  originals: '/originals',
  watch: '/watch/:workshopSlug/:onDemandMediaId?',
  workshopJoinNow: '/workshops/:workshopSlug/session/:sessionId/joinNow',
  user: '/:username',
  userWorkshops: '/me/workshops',
  optimisedJoinFlowChoosePlan: '/join/choose-plan',
  optimisedJoinFlowSignUpMonthly: '/join/monthly',
  optimisedJoinFlowSignUpYearly: '/join/yearly',
  optimisedJoinFlowSignUpMonthlyPay: '/join/monthly/pay/welcome',
  optimisedJoinFlowSignUpYearlyPay: '/join/yearly/pay/welcome',
  discordConnect: '/apps/discord/connect',
  connectedApps: '/account/apps',
  promo: '/promo/:promoType',
};

export { routes };
