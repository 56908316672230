import React, { useCallback, useEffect, useRef, useState } from 'react';

import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { connect } from 'unistore/react';

import { BottomBanner } from '@/components/global/BottomBanner/BottomBanner';
import { FAQ } from '@/components/global/FAQ/FAQ';
import { Icon } from '@/components/global/Icon/Icon';
import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import TextCircleCursor from '@/components/global/TextCircleCursor/TextCircleCursor';
import TextMarquee from '@/components/global/TextMarquee/TextMarquee';
import { YourMentorOrRoomHost } from '@/components/global/YourMentorOrRoomHost/YourMentorOrRoomHost';
import { OnDemandPlayer } from '@/components/ondemand/OnDemandPlayer';
import { Avatar } from '@/components/users/Avatar/Avatar';
import { addWorkshopComponentActions } from '@/components/workshops/addWorkshopComponentActions';
import { RecommendedWorkshops } from '@/components/workshops/RecommendedWorkshops';
import WorkshopMentorCarousel from '@/components/workshops/WorkshopMentorCarousel';
import WorkshopMetadataV2 from '@/components/workshops/WorkshopMetadataV2';
import WorkshopOutcomes from '@/components/workshops/WorkshopOutcomes';
import WorkshopReviewV2 from '@/components/workshops/WorkshopReviewV2';
import WorkshopSentences from '@/components/workshops/WorkshopSentences';
import { WorkshopTimeRemaining } from '@/components/workshops/WorkshopTimeRemaining';
import { WorkshopWhatYouLearnCarousel } from '@/components/workshops/WorkshopWhatYouLearnCarousel';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonType } from '@/components/design_system/Button/ButtonType';
import { HeroBlurOverlay } from '@/components/design_system/HeroBlurOverlay/HeroBlurOverlay';

import { DaisieSubscriptionTier } from '@/enums/DaisieSubscriptionTier';
import { SubscriptionInterval } from '@/enums/SubscriptionInterval';

import { routes } from '@/routes';
import { parser } from '@/routes/parser';

import { findProductAndPrice } from '@/store/helpers/subscriptionHelpers';
import { modalActions } from '@/store/modules/modal';
import { portalActions } from '@/store/modules/portal';

import { isInProgress } from '@/utils/date/date-manipulation';
import { t } from '@/utils/i18n/i18n';
import { track } from '@/utils/mixpanel/mixpanel';
import { c } from '@/utils/strings/c';
import { getDisplayPrice } from '@/utils/strings/currency';
import { getColorName, getFontClass } from '@/utils/workshop/workshopUtils';

interface Props
  extends RouteComponentProps,
    ABTestState,
    WorkshopComponentActions,
    AuthState,
    SubscriptionState {
  workshop: Workshop;
}

const WorkshopWrapperV2Component = ({
  auth,
  sizing,
  abtest,
  workshop,
  renderActionButton,
  fetchWorkshopAttendees,
  subscription,
  displayAttendees,
  isAttendeesLoaded,
  attendWorkshop,
  location,
  match,
}: Props) => {
  const [showBottomBanner, setShowBottomBanner] = useState(false);
  const [isAutoAttending, setIsAutoAttending] = useState(false);
  const [isMobileCached, setIsMobileCached] = useState(false);
  const [showOnDemandPlayer, setShowOnDemandPlayer] = useState(false);
  const [isCursorHover, setIsCursorHover] = useState(false);
  const [cursorRotation, setCursorRotation] = useState(0);
  const [cursorIcon, setCursorIcon] = useState('arrow-long');
  const mentorCarouselSection = useRef<HTMLDivElement>(null);
  const workshopSentencesSection = useRef<HTMLDivElement>(null);
  const learnSection = useRef<HTMLDivElement>(null);
  const isScrollingDown = useRef(true);
  const previousScrollY = useRef(0);
  const history = useHistory();

  useEffect(() => {
    const { batches, mentor } = workshop;
    window.addEventListener('scroll', checkScrollDirection);
    if (batches && batches[0]) {
      fetchWorkshopAttendees({
        batchId: batches[0].id,
        mentor,
      });
    }

    // On-demand video autoplay
    const queryParams = new URLSearchParams(location.search);
    const autoplayParam = queryParams.get('autoplay') || '';
    const allowAutoPlay: boolean =
      !!workshop.onDemandMediaCatchup &&
      auth.isAuthorised &&
      !!auth.user &&
      auth.user.subscriptionTier !== null;

    const shouldAutoplay: boolean =
      allowAutoPlay && !!autoplayParam && autoplayParam === 'true';

    if (shouldAutoplay) {
      setShowOnDemandPlayer(true);
      history.replace(window.location.pathname);
    }

    // Auto-attend after subscribing
    const autoAttendParam = !!queryParams.get('autoattend');

    if (
      autoAttendParam &&
      auth.isAuthorised &&
      auth.user &&
      auth.user.subscriptionTier === DaisieSubscriptionTier.premium &&
      !workshop.hasElapsed &&
      !workshop.isAttending
    ) {
      setIsAutoAttending(true);
      attendWorkshop({
        workshop: workshop,
        batchId: workshop.batches[0].id,
        timeStart: new Date(workshop.start),
        timeEnd: new Date(workshop.end),
        showLoadingState: true,
        onDismiss: () => {
          setIsAutoAttending(false);
        },
      });

      window.history.replaceState(null, '', match.url);
    }

    // Cache the `sizing.isMobile` prop in the component state,
    // so that changing a mobile device orientation doesn't cause
    // the player to unmount
    setIsMobileCached(sizing.isMobile);

    // A/B testing
    if (!auth.isAuthorised) {
      track('pwe_logged_out_workshop_load', {
        currency: subscription.userCurrency,
      });
    }

    // Set a workshop page theme, use a wrapper class for now
    // TODO: a better solution
    document
      .querySelector('.page-layout')
      ?.classList.add(
        `page-layout--theme-${getColorName(workshop.attributes.themeColor)}`
      );

    return () => {
      window.removeEventListener('scroll', checkScrollDirection);
    };
  }, []);

  const handleCursorMove = useCallback((cursorBounds: DOMRect) => {
    const sectionsToCheck = [workshopSentencesSection, learnSection];

    const overlappingSection = sectionsToCheck.find((section) => {
      if (!section.current) return;

      return elementsOverlap(
        cursorBounds,
        section.current.getBoundingClientRect()
      );
    });

    if (overlappingSection) {
      applyCursorEffect(overlappingSection);
    } else {
      resetCursorState();
    }
  }, []);

  const applyCursorEffect = (element: React.RefObject<HTMLDivElement>) => {
    if (element === mentorCarouselSection) {
      setCursorRotation(isScrollingDown.current ? -90 : 90);
    } else if (element === workshopSentencesSection) {
      setCursorRotation(isScrollingDown.current ? -45 : 45);
    } else if (element === learnSection) {
      setCursorIcon('plus');
    }
  };

  const resetCursorState = () => {
    setCursorRotation(isScrollingDown.current ? 0 : 180);
    setCursorIcon('arrow-long');
  };

  // eslint-disable-next-line func-style
  const elementsOverlap = (cursorBounds: DOMRect, elementBounds: DOMRect) =>
    !(
      cursorBounds.top > elementBounds.bottom ||
      cursorBounds.right < elementBounds.left ||
      cursorBounds.bottom < elementBounds.top ||
      cursorBounds.left > elementBounds.right
    );

  const checkScrollDirection = () => {
    if (previousScrollY.current > window.scrollY) {
      isScrollingDown.current = false;
    } else {
      isScrollingDown.current = true;
    }

    previousScrollY.current = window.scrollY;
  };

  const handleCursorOver = () => {
    setIsCursorHover(true);
  };

  const handleCursorOut = () => {
    setIsCursorHover(false);
  };

  useEffect(() => {
    // Temporarily hide the cursor here until a better solution is implemented
    // there should be a separate global state that manages classes added to body
    if (!sizing.isTablet && !sizing.isMobile && !showOnDemandPlayer) {
      document.body.style.cursor = 'none';
      document.body.style.overflowX = 'hidden';
    }

    let buttons,
      links,
      hoverElements: any[] = [];
    let hoverElementTimeout;

    if (!showOnDemandPlayer) {
      // The bottom banner appears after a delay, we should wait for a bit before
      // finding all the A and Button elements so the button inside the banner is included.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      hoverElementTimeout = setTimeout(function () {
        buttons = Array.from(document.querySelectorAll('button'));
        links = Array.from(document.querySelectorAll('a'));
        hoverElements = [...buttons, ...links];

        hoverElements.forEach((elm: any) => {
          elm.style.cursor = 'none';
          elm.addEventListener('mouseover', handleCursorOver);
          elm.addEventListener('mouseout', handleCursorOut);
        });
      }, 50);
    }

    return () => {
      // Reset to normal cursor behaviour
      if (!sizing.isTablet && !sizing.isMobile) {
        document.body.style.cursor = '';
        document.body.style.overflowX = '';
      }

      hoverElements.forEach((elm: any) => {
        elm.style.cursor = '';
        elm.removeEventListener('mouseover', handleCursorOver);
        elm.removeEventListener('mouseout', handleCursorOut);
      });
    };
  }, [showOnDemandPlayer, showBottomBanner]);

  const checkScrollForBottomBanner = () => {
    const header = document.querySelector('header[role="banner"]');

    if (!header) return;

    if (isScrollingDown.current) {
      setShowBottomBanner(true);
    } else {
      setShowBottomBanner(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollForBottomBanner);

    return () => {
      window.removeEventListener('scroll', checkScrollForBottomBanner);
    };
  }, []);

  const handleCursorHover = (isHover: boolean) => {
    if (isHover) {
      setIsCursorHover(true);
    } else {
      setIsCursorHover(false);
    }
  };

  const renderWorkshopStatusInBanner = () => (
    <div className="f-text-3 u-text-right@m">
      <p>{t('Workshop in progress')}</p>
      <WorkshopTimeRemaining workshop={workshop} />
    </div>
  );

  const renderCategoryAndDateInBanner = () => (
    <div>
      <div className="f-text-5 u-grey u-text-right@m">
        <Icon
          id={`category-${workshop.categories[0].name.toLowerCase()}`}
          width="8px"
          className="mr6"
        />
        {workshop.categories[0].name}
      </div>
      <p className="f-text-3 pt4">{workshop.whenLabel.short}</p>
    </div>
  );

  const renderCallToAction = ({
    hidePricingInfo = false,
    hideCategoryAndDate = false,
  }: {
    hidePricingInfo?: boolean;
    hideCategoryAndDate?: boolean;
  }) => {
    const productsAndPrices = subscription.productsAndPrices;

    const yearlyPrice = findProductAndPrice({
      productsAndPrices,
      currency: subscription.userCurrency,
      recurringInterval: SubscriptionInterval.year,
    });

    return (
      <p className="u-flex u-align-center u-justify-between@s u-z-index-1">
        {workshop.priceLabel && !hidePricingInfo && (
          <div className="f-text-2 u-bold u-white mr24">
            {isInProgress(workshop.start, workshop.end) ? (
              <>{renderWorkshopStatusInBanner()}</>
            ) : auth.isAuthorised && !hideCategoryAndDate ? (
              <>{renderCategoryAndDateInBanner()}</>
            ) : !auth.isAuthorised ? (
              <div>
                {yearlyPrice
                  ? t(
                      `Daisie starts at ${getDisplayPrice(
                        yearlyPrice.currency,
                        Math.floor(yearlyPrice.amount / 12)
                      )}/month`
                    )
                  : null}
              </div>
            ) : undefined}
          </div>
        )}
        <div>
          {renderActionButton({
            isLoading: false,
            workshop,
            batchId: workshop.batches[0].id,
            sessionId: workshop.batches[0].sessions[0].id,
            isAttendingRaw: workshop.isAttending,
            isInProgress: !!workshop.isInProgress,
            showStartFreeTrialWhileLoggedOut: false,
            handleShowOnDemandPlayer: () => {
              setShowOnDemandPlayer(true),
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
            },
            timeStart: new Date(workshop.start),
            timeEnd: new Date(workshop.end),
          })}
        </div>
      </p>
    );
  };

  const ctaButtonForLoggedOutUser = (
    <Button
      type={ButtonType.link}
      linkTo={`${routes.optimisedJoinFlowChoosePlan}?to=${
        workshop
          ? encodeURIComponent(
              parser({
                name: 'workshop',
                params: { workshopSlug: workshop.slug },
              })
            )
          : ''
      }`}
      text={abtest.variant === 1 ? t('Get started') : t('Join for free')}
      className="u-flex-hold u-z-index-2"
      buttonTextClassName="ph8 pv6"
    />
  );

  return (
    <div className="u-bg-black u-white">
      {!sizing.isMobile && !sizing.isTablet && !showOnDemandPlayer && (
        <TextCircleCursor
          className="u-fixed"
          word="explore"
          theme={getColorName(workshop.attributes.themeColor)}
          iconId={isCursorHover ? 'plus' : cursorIcon}
          width={isCursorHover ? 55 : 65}
          height={isCursorHover ? 55 : 65}
          iconSize={isCursorHover ? 'l' : 'xl'}
          iconRotation={cursorRotation}
          onCursorMove={handleCursorMove}
        />
      )}
      <div
        className={c(
          'c-workshop-cover relative u-bg-charcoal-grey u-flex u-justify-center u-align-center u-100vh u-flex-column animate-opacity'
        )}
      >
        <ImageLoader
          src={workshop.coverMedia}
          className="absolute fill u-object-cover"
          convertToWebp={true}
        />

        <WorkshopMetadataV2
          fontClass={getFontClass(workshop.attributes.fontFamily)}
          workshop={workshop}
          sizing={sizing}
          auth={auth}
        />

        {renderCallToAction({
          hidePricingInfo: false,
          hideCategoryAndDate: true,
        })}

        {/* TODO: Add a 'watch a trailer button' */}
        {/* <Button
          text="Watch trailer"
          type={ButtonType.action}
          onClick={() => {
            () => {};
          }}
          className="u-inline u-z-index-1 mt12 p28"
          buttonStyle={ButtonStyle.default}
        /> */}

        <HeroBlurOverlay className="absolute absolute--t" />

        {workshop.onDemandMediaCatchup && (
          <OnDemandPlayer
            className="absolute absolute--mid-center u-z-index-1"
            onDemandMedia={workshop.onDemandMediaCatchup}
            onClose={() => setShowOnDemandPlayer(false)}
            isVisible={showOnDemandPlayer}
            useMobilePlayer={isMobileCached}
          />
        )}
      </div>
      {workshop.mentor && (
        <section className="u-flex u-flex-column u-align-center mb32@s mb100@m">
          <div className="u-flex-hold mb20">
            <Avatar
              size="xxxxxl"
              alt="mentor_avatar"
              src={workshop.mentor.avatar}
              user={workshop.mentor}
            />
          </div>

          <div className="u-flex u-flex-column u-align-center u-text-center">
            <p className="f-text-2 f-inter-medium-1 u-grey">
              {t('Your instructor')}
            </p>
            <h4
              className={`c-workshop-instructor__name u-x-bold ${getFontClass(
                workshop.attributes.fontFamily
              )}`}
            >
              {t(`${workshop.mentor.name}`)}
            </h4>
          </div>
        </section>
      )}
      <WorkshopSentences sentences={workshop.attributes.sentences} />
      <WorkshopOutcomes
        theme={getColorName(workshop.attributes.themeColor)}
        isMobile={sizing.isMobile}
        workshop={workshop}
      />
      <section ref={learnSection}>
        <WorkshopWhatYouLearnCarousel
          workshop={workshop}
          isMobile={sizing.isMobile}
          content={workshop.attributes.learnContent}
          fontClass={getFontClass(workshop.attributes.fontFamily)}
        />
      </section>
      {!auth.user && (
        <section className="wrap u-flex u-justify-center u-flex-column u-align-center mv200@m mv44@s">
          <h1
            className={`c-workshop-sentences__cta ${getFontClass(
              workshop.attributes.fontFamily
            )} u-text-center u-x-bold pb44@m pb32@s`}
          >
            <span className="u-block">Start your</span>journey today
          </h1>
          {ctaButtonForLoggedOutUser}
        </section>
      )}
      {!auth.user && (
        <>
          <TextMarquee
            dark="about daisie"
            light="about daisie"
            className="mt60@m mb312@m pb80@m mv32@s"
          />
          <WorkshopReviewV2
            sizing={sizing}
            titleClass={getFontClass(workshop.attributes.fontFamily)}
          />
          <section
            className="c-workshop-sentences wrap u-flex@m mt32@s u-3/4@l u-7/8@m mt100@m"
            ref={workshopSentencesSection}
          >
            <div className="relative u-flex-1 mb32@s mr32@m u-h-fit-content">
              <p
                className={`c-workshop-sentences__h1 u-x-bold ${getFontClass(
                  workshop.attributes.fontFamily
                )}`}
              >
                Learn from world-class creators
              </p>
            </div>
            <p
              className={`c-workshop-sentences__coloured c-workshop-sentences__coloured--${getColorName(
                workshop.attributes.themeColor
              )} f-inter-light-1 u-flex-1`}
            >
              Our community features instructors from top-named brands like
              Pixar, Apple, Vogue, Sony, Adobe and more.
            </p>
          </section>
          <WorkshopMentorCarousel
            columns={sizing.isMobile ? 1 : sizing.isTablet ? 3 : 4}
            cardOffset={sizing.isMobile ? -64 : 10}
            category="film"
            ref={mentorCarouselSection}
          />
        </>
      )}
      {!auth.user && (
        <section className="wrap mv100@m mb44@s mt-128@s">
          <FAQ
            titleFontClass={getFontClass(workshop.attributes.fontFamily)}
            isWorkshopWrapperV2={true}
          />
        </section>
      )}
      <RecommendedWorkshops workshop={workshop} />
      {!auth.user && (
        <>
          <section className="wrap u-flex u-justify-center u-flex-column u-align-center mv200@m mv44@s">
            <h1
              className={`c-workshop-sentences__cta ${getFontClass(
                workshop.attributes.fontFamily
              )} u-text-center u-x-bold pb44@m pb32@s`}
            >
              <span className="u-block">Commit to</span>
              <span className="u-block">your</span>
              creativity
            </h1>
            {ctaButtonForLoggedOutUser}
          </section>

          <TextMarquee
            dark="today"
            light="join"
            className="mt60@m mb128@m mv32@s"
          />
        </>
      )}
      <BottomBanner
        visible={showBottomBanner}
        className="c-workshop__banner u-bg-charcoal-grey"
        flexDirection="column"
      >
        <div className="u-flex u-split">
          <div className="u-flex u-align-center animate-opacity u-flex-hold u-overflow-hidden mr24 u-flex-no-hold">
            <ImageLoader
              src={workshop.coverMedia}
              className="c-workshop__banner__img mr16 u-flex-hold u-hide@s"
              convertToWebp={true}
            />
            <div>
              <p className="u-white f-text-5 mb4">{workshop.whenLabel.short}</p>
              <p className="f-text-2 u-bold u-white mb2">{workshop.title}</p>
              <YourMentorOrRoomHost
                isLoading={false}
                workshop={workshop}
                attendees={displayAttendees}
                isAttendeesLoaded={isAttendeesLoaded}
                isCompact={true}
              />
            </div>
          </div>
          <div className="u-flex-hold">
            {renderCallToAction({
              hidePricingInfo: false,
              hideCategoryAndDate: true,
            })}
          </div>
        </div>
      </BottomBanner>
    </div>
  );
};

export const WorkshopWrapperV2 = connect(
  ['auth', 'sizing', 'room', 'subscription', 'calendar', 'abtest'],
  () => ({ ...portalActions(), ...modalActions() })
)(addWorkshopComponentActions(withRouter(WorkshopWrapperV2Component)));
