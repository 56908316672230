export const STRIPE_ELEMENTS_CONFIG = {
  style: {
    base: {
      backgroundColor: 'transparent',
      textShadow: 'unset',
      // brand: default
      iconColor: '#6468dd',
      // base: white
      color: '#ffffff',
      fontWeight: '400',
      fontFamily:
        'Lynstone, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#FFF' },
      // base: dark_grey
      '::placeholder': { color: '#737677' },
    },
    invalid: {
      // other: alert
      iconColor: '#ff4646',
      color: '#ff4646',
      backgroundColor: 'transparent',
      textShadow: 'unset',
    },
  },
};

// NOTE: Stripe fonts only work when served over HTTPS, so Lynstone won't be used
// for the card form when running locally
export const STRIPE_ELEMENTS_FONTS = [
  {
    family: 'Lynstone',
    src: 'url(/fonts/Lynstone/Lynstone-Regular.otf)',
    weight: '400',
  },
];
