/**
 * The packing algorithm
 *
 * @param {array} group   Group of columns
 * @param {int}   height  The block height
 * @param {int}   size    The block size (in columns)
 */
const PackBlock = (columns: number[], height: number, size: number) => {
  /**
   * Given the block size, slice the columns into chunks of that width
   */
  const groups = [];
  for (let index = 0; index <= columns.length - size; index++) {
    groups.push(columns.slice(index, index + size));
  }

  /**
   * Each column contains the highest (Block.Y + Block.Height) value
   * from within that column.
   *
   * For each column group, find the highest column value. This will
   * denote the first position where a new block could appear in that group
   */
  const largest = groups.map((x) => Math.max(...x));

  /**
   * Find the column group with the lowest value - this is the new Y value
   */
  const column = largest.indexOf(Math.min(...largest));
  const y = largest[column];

  /**
   * Calculate gaps
   *
   * If there's more than one column involved, create some gaps.
   * Loop the group, merging adjacent gaps and ticking over when the column
   * height matches the new Y value (ie, the tallest block - so no chance of gaps)
   */
  let newGaps: Gap[][] = [[]];
  if (groups[column].length > 1) {
    const group = groups[column];
    let tickOver = 0;
    group.forEach((min, id) => {
      if (min === y) {
        tickOver++;
        return (newGaps[tickOver] = []);
      }

      return newGaps[tickOver].push({
        min,
        max: y,
        column: id + column,
      });
    });
  }

  // Remove empty gaps
  newGaps = newGaps.filter((x) => x.length);

  // Update each column with the new (Y + height) value
  for (let index = column; index < column + size; index++) {
    columns[index] = y + height;
  }

  return { column, y, newGaps };
};

/**
 * Gap packer
 *
 * @param {array} gaps    The gaps
 * @param {int}   height  The block height
 * @param {int}   size    The block size
 */
const PackGaps = (gaps: Gap[][], height: number, size: number) => {
  let placedInGap = false;
  let column = 0;
  let y = 0;

  // Loop the gaps, and abandon if the block is in a gap, or too big for this one
  if (gaps.length) {
    gaps.forEach((gap) => {
      if (placedInGap) return;
      if (size > gap.length) return;

      // Chunk the gaps into the size of the block
      const subGroups = [];
      for (let index = 0; index <= gap.length - size; index++) {
        subGroups.push(gap.slice(index, index + size));
      }

      /**
       * Find the largest gap MIN, and the smallest gap MAX.
       */
      const largeMin = subGroups.map((a) => Math.max(...a.map((b) => b.min)));
      const smallMax = subGroups.map((a) => Math.min(...a.map((b) => b.max)));
      let smallest = { index: -1, space: Infinity };

      /**
       * Loop around calculating the difference between the min and max
       * If there's not enough space for the block, or the space is larger
       * than a previous space, carry on looping
       * If not, update the new smallest value
       */
      largeMin.forEach((min, index) => {
        const space = smallMax[index] - min;
        if (space < height || space > smallest.space) return;
        smallest = { index, space };
      });

      /**
       * If we have a suitable gap, get the column, update the y value,
       * and update the placedInGap flag
       */
      if (smallest.index !== -1) {
        const col = subGroups[smallest.index][0];
        column = col.column;
        y = largeMin[smallest.index];
        placedInGap = true;

        /**
         * Loop the affected column indexes and update the min height for
         * the affected gapColumns
         */
        for (let index = column; index < column + size; index++) {
          const gapColumn = gap.find((x) => x.column === index);
          if (gapColumn) gapColumn.min = y + height;
        }
      }
    });
  }

  return { placedInGap, column, y };
};

export { PackBlock, PackGaps };
