/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from 'react';

import { Link, useHistory, useParams } from 'react-router-dom';
import { connect } from 'unistore/react';

import { Link as GlobalLink } from '@/components/global/Link/Link';
import { OriginalsQuiz } from '@/components/originals/OriginalsQuiz';

import { GlobalModal } from '@/enums/GlobalModal';
import { GlobalPortal } from '@/enums/GlobalPortal';
import { OnDemandMediaType } from '@/enums/OnDemandMediaType';

import { routes } from '@/routes';

import { modalActions } from '@/store/modules/modal';
import { portalActions } from '@/store/modules/portal';

import { CLASS_NOTION_LINKS } from '@/utils/constants';
import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';
import { secondsToTime } from '@/utils/strings/converters';

import OriginalsVideoCard from './OriginalsVideoCard';
import useQuizProgress from '@/hooks/useQuizProgress';
import useVideoHistory from '@/hooks/useVideoHistory';
import useVideoProgress from '@/hooks/useVideoProgress';
import { Icon } from '../global/Icon/Icon';
import { OnDemandPlayer } from '../ondemand/OnDemandPlayer';
import { Avatar } from '../users/Avatar/Avatar';

interface Props extends PortalActions, SizingState, ModalActions, SizingState {
  workshop: Workshop;
}

// const quiz = {
//   uuid: 'quiz_mEfy69DrB76weBgm6Nx8C4',
//   questions: [
//     {
//       title: 'What were the names of the two methods covered in this class?',
//       answers: [
//         {
//           title:
//             'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a congue nibh. Nulla non lobortis neque',
//           isCorrect: false,
//         },
//         {
//           title: 'Cross Hatching',
//           isCorrect: true,
//         },
//         {
//           title: 'Spilling',
//           isCorrect: false,
//         },
//         {
//           title: 'Cross Scratching',
//           isCorrect: true,
//         },
//         {
//           title: 'Lorem ipsum dolor sit amet',
//           isCorrect: false,
//         },
//         {
//           title: 'Cross Matching',
//           isCorrect: false,
//         },
//       ],
//     },
//     {
//       title: 'Question 2',
//       answers: [
//         {
//           title: '',
//           isCorrect: true,
//           image: {
//             uuid: 'attachment_hPzPqwi7nfm1DK4wQwxCBt',
//             processorPath:
//               'https://daisie-assets-dev.accelerator.net/images/user_tyiiGB3aZGExT5HAC4ptDq/media_7C1WWyDsw6UQibrVK55e2u.jpg;resize(1200,9999,fit).jpg',
//           },
//         },
//         {
//           title: '',
//           isCorrect: true,
//           image: {
//             uuid: 'attachment_hk9E8xGM3r2qxF3ND4mABs',
//             processorPath:
//               'https://daisie-assets-dev.accelerator.net/images/user_tyiiGB3aZGExT5HAC4ptDq/media_5zGjuJDgGzjMmoPiVTrvdm.jpg;resize(1200,9999,fit).jpg',
//           },
//         },
//         {
//           title: '',
//           isCorrect: false,
//           image: {
//             uuid: 'attachment_kSHtVQNoCYEijChB9CF8N9',
//             processorPath:
//               'https://daisie-assets-dev.accelerator.net/images/user_tyiiGB3aZGExT5HAC4ptDq/media_8WchDf3GLiNXneic5QzrzW.jpg;resize(1200,9999,fit).jpg',
//           },
//         },
//       ],
//     },
//   ],
// };

const OriginalsPlayer = ({
  workshop,
  updatePortal,
  updateModal,
  sizing,
}: Props) => {
  const history = useHistory();
  const params = useParams();
  const { onDemandMediaId, workshopSlug }: any = params;

  const [episodes, setEpisodes] = useState<OnDemandMedia[]>([]);
  const [currentEpisode, setCurrentEpisode] = useState<OnDemandMedia>();
  const [videoHistory, setLastVideoWatched] = useVideoHistory(workshopSlug);
  const [isPlaying, setIsPlaying] = useState(false);
  // Used to track progress of all videos in local storage
  const [videoProgress, setVideoProgress] = useVideoProgress(
    workshopSlug,
    onDemandMediaId
  );
  const [currentEpisodeProgress, setCurrentEpisodeProgress] =
    useState<number>(0);

  const [currentQuiz, setCurrentQuiz] = useState<Quiz | undefined>();
  const [opacityForQuiz, setOpacityForQuiz] = useState<number>(1);
  const [quizProgress, setQuizCompleted, setQuizSkipped] =
    useQuizProgress(onDemandMediaId);

  useEffect(() => {
    const episodes = workshop.onDemandMediaAll.filter(
      (odm) => odm.type === OnDemandMediaType.WorkshopOriginal
    );

    if (episodes.length === 0) {
      // Redirect back to the homepage
      history.push(routes.home);
    }

    const onDemandMedia = workshop.onDemandMediaAll.find(
      (odm) => odm.id === onDemandMediaId
    );

    if (!onDemandMedia) {
      // Redirect to the either the last watched episode or the 1st episode
      const episodeId = videoHistory[workshop.id] || episodes[0].id;
      history.push(`/watch/${workshopSlug}/${episodeId}`);
      return;
    }

    setEpisodes(episodes);
    setCurrentEpisode(onDemandMedia);
    setLastVideoWatched(workshop.id, onDemandMediaId);
    // setCurrentEpisodeProgress(0);
  }, [onDemandMediaId, workshopSlug]);

  // If video is already fully watched and is selected,
  // we want to rewatch it from the beginning
  useEffect(() => {
    if (!currentEpisode?.duration || !currentEpisodeProgress) {
      return;
    }

    const percentWatched = getVideoPercentWatched(
      currentEpisode?.duration,
      currentEpisodeProgress
    );

    if (percentWatched >= 100) {
      handleEpisodeProgressChange(0, onDemandMediaId, true);
    } else {
      handleEpisodeProgressChange(
        currentEpisodeProgress,
        onDemandMediaId,
        true
      );
    }
  }, [onDemandMediaId]);

  useEffect(() => {
    if (isPlaying && currentQuiz) {
      setIsPlaying(false);
    }
  }, [videoProgress]);

  const handleEpisodeSelect = (onDemandMediaId: string) => {
    history.push(`/watch/${workshopSlug}/${onDemandMediaId}`);
  };

  const goToNextEpisode = () => {
    let currentEpisodeIndex = currentEpisode
      ? episodes?.indexOf(currentEpisode)
      : undefined;

    if (
      currentEpisodeIndex === undefined ||
      currentEpisodeIndex === episodes.length - 1
    ) {
      return;
    }

    const nextEpisodeIndex = ++currentEpisodeIndex;

    handleEpisodeSelect(episodes[nextEpisodeIndex].id);

    // setTimeout(() => {
    //   handleEpisodeSelect(episodes[nextEpisodeIndex].id);
    // }, 1500);
  };

  const handleEpisodeProgressChange = (
    currentTime: number,
    onDemandMediaId: string,
    shouldUpdateOverallProgress: boolean
  ) => {
    // Update current video's progress
    setCurrentEpisodeProgress(currentTime);

    // Only update progress in local storage if the video was watched further than previously
    if (!shouldUpdateOverallProgress) return;
    setVideoProgress(workshop.id, onDemandMediaId, currentTime);

    // Display a quiz at the end of the video if there's one.
    // do this only once by checking the video progress in the local storage
    const percentWatched = getVideoPercentWatched(
      currentEpisode?.duration || 0,
      currentTime
    );

    // When the video ends, we want to display the quiz if the video has one.
    // If it doesn't, we want to go to the next class' video
    if (percentWatched <= 99) {
      return;
    } else {
      const quiz = currentEpisode?.quiz;

      if (!!quiz && !!quiz.id) {
        // The quiz will only be shown the 1st time the video is watched until the end
        // If the user closes the quiz, it will be considered as skipped
        // To make the quiz appear again while debugging, delete the videoProgress and quizProgress
        // objects from the local storage

        const progress = quizProgress?.[onDemandMediaId]?.[quiz.id];

        if (!progress?.isCompleted) {
          // Bring the opacity down and show the quiz after 1.5 seconds
          const interval = setInterval(() => {
            setOpacityForQuiz((state) => state - 0.025);
          }, 125);

          setTimeout(() => {
            clearInterval(interval);
            setCurrentQuiz(quiz);
          }, 1500);
        } else {
          goToNextEpisode();
        }
      } else {
        goToNextEpisode();
      }
    }
  };

  const getVideoPercentWatched = (totalDuration: number, currentTime: number) =>
    Math.round((currentTime / totalDuration) * 100);

  const handleQuizEnd = (skip?: boolean) => {
    if (skip) {
      setQuizSkipped(currentEpisode?.id, currentQuiz?.id, true);
    }

    setQuizCompleted(currentEpisode?.id, currentQuiz?.id, true);

    setCurrentQuiz(undefined);
    setOpacityForQuiz(1);
    goToNextEpisode();
  };

  const handleQuizDismiss = () => {
    setCurrentQuiz(undefined);
    setOpacityForQuiz(1);
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handleShareClick = () => {
    if (sizing.isMobile) {
      updatePortal({
        portal: GlobalPortal.share_current_page,
      });
    } else {
      updateModal({
        modal: GlobalModal.share_current_page,
      });
    }
  };

  if (!currentEpisode) return null;

  const startTime = videoProgress[workshop.id][currentEpisode.id]
    ? videoProgress[workshop.id][currentEpisode.id]
    : 0;

  const getFeaturedClasses = (currentIndex: number) => {
    if (!episodes || !episodes?.length) {
      return [];
    }

    const itemQuantity = sizing.isMobile ? 3 : 4;

    if (episodes.length <= itemQuantity) {
      return episodes.slice(0, itemQuantity);
    }

    if (currentIndex >= episodes.length - itemQuantity) {
      return episodes.slice(episodes.length - itemQuantity);
    }

    return episodes.slice(
      currentIndex + 1,
      sizing.isMobile ? currentIndex + 4 : currentIndex + 5
    );
  };

  const isFeaturedClassFullyWatched = (featuredClass: any) => {
    const currentProgress = videoProgress[workshop.id][featuredClass.id];
    const totalDuration = featuredClass.duration;
    return currentProgress >= totalDuration;
  };

  const currentEpisodeIndex = episodes?.indexOf(currentEpisode);

  return (
    <div
      className="c-originals-player wrap u-1/1 mb64"
      style={{
        height: !!currentQuiz ? '70vh' : 'auto',
      }}
    >
      {!!currentQuiz && (
        <OriginalsQuiz
          onQuizEnd={handleQuizEnd}
          onQuizDismiss={handleQuizDismiss}
          quiz={currentQuiz}
          episodes={episodes}
        />
      )}
      <div
        className="c-originals-player__wrapper mb48 u-flex u-flex-column u-white mt48"
        style={{ opacity: opacityForQuiz }}
      >
        {!sizing.isMobile && (
          <Link
            className="u-link-white u-title-1 u-flex u-align-center mb20 u-z-index-2"
            to={`/workshops/overview/${workshop.slug}`}
          >
            <Icon id="arrow-left-filled" className="mr8" width="18" />
            <p className="f-text-1 u-bold u-link-white u-link-white--alt">
              {t('Go back')}
            </p>
          </Link>
        )}
        <Link
          className="f-lynstone-bold-2 mb32 c-originals-player__title"
          to={`/workshops/overview/${workshop.slug}`}
        >
          {t(`${workshop.title}`)}
        </Link>
        <div className="u-flex@m">
          <div className="c-originals-player__left u-1/2@m u-2/3@l">
            <OnDemandPlayer
              className="on-demand-player--no-border on-demand-player--originals mb32"
              onDemandMedia={currentEpisode}
              isVisible={true}
              hideCloseButton={true}
              onCurrentTimeChanged={handleEpisodeProgressChange}
              startTime={startTime}
              onPlay={handlePlay}
              onPause={handlePause}
              setVideoProgress={setVideoProgress}
            />
            <div className="u-flex u-flex-column@s">
              <div className="c-originals-player__metadata u-flex-grow">
                <h2 className="f-lynstone-bold-3 mb12">
                  {currentEpisode?.title}
                </h2>
                <p className="f-text-3 f-inter-medium-1 u-light-grey">
                  {currentEpisode?.description}
                </p>
              </div>
              <div className="f-text-3 u-flex u-flex-column u-align-center u-flex-hold ph24@m">
                <button
                  type="button"
                  className="c-originals-player__share-btn u-flex u-align-center mb20@m mv16@s"
                  onClick={handleShareClick}
                >
                  {t('Share')}
                  <Icon className="ml8" id="share" size="s" />
                </button>
                <GlobalLink
                  to={CLASS_NOTION_LINKS[currentEpisodeIndex] || ''}
                  target="blank"
                  className="c-originals-player__guide-btn ph24 pv12 u-1/1@s"
                >
                  {t('Class Guide')}
                </GlobalLink>
              </div>
            </div>
          </div>
          <div className="c-originals-player__right u-1/2@m u-1/4@l ml32@m ml44@l">
            <div className="u-flex u-align-center mb24 mt24@s">
              <Avatar
                src={workshop.mentor.avatar}
                user={workshop.mentor}
                size="xl"
                className="mr16"
              />
              <div className="u-flex u-flex-column">
                <span className="f-text-5 u-grey">{t('Your instructor')}</span>
                <span className="f-text-3">{t(workshop.mentor.name)}</span>
              </div>
            </div>
            <p className="f-text-3 u-grey mb32">
              {workshop.attributes.sentences[0]}
            </p>
            {/* <span className="u-inline-block">
          {episodes?.length} videos -{' '}
          {secondsToTime(workshop.totalWorkshopDuration)}
        </span> */}
            <div className="c-originals-player-playlist relative">
              <ul className="c-originals-player-playlist__videos u-flex u-flex-column relative pb24 u-scrollbar-disabled">
                {episodes?.map((episode, index) => {
                  const isCurrentEpisode = episode.id === currentEpisode.id;
                  const episodeProgress = isCurrentEpisode
                    ? getVideoPercentWatched(
                        episode.duration || 0,
                        currentEpisodeProgress
                      )
                    : 0;

                  const overallEpisodeProgress = getVideoPercentWatched(
                    episode.duration || 0,
                    videoProgress[workshop.id][episode.id] || 0
                  );

                  return (
                    <OriginalsVideoCard
                      onEpisodeSelect={handleEpisodeSelect}
                      episode={episode}
                      isCurrentEpisode={episode.id === currentEpisode.id}
                      episodeNumber={index + 1}
                      key={episode.id}
                      progress={episodeProgress}
                      isPlaying={isCurrentEpisode && isPlaying}
                      isCompleted={overallEpisodeProgress >= 100}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="c-originals-featured-classes u-white">
        <h2 className="f-lynstone-bold-2 mb32">Featured classes</h2>
        <ul className="c-originals-featured-classes__list u-grid u-grid--4">
          {getFeaturedClasses(currentEpisodeIndex).map((featuredClass: any) => (
            <Link
              to={`/watch/${workshopSlug}/${featuredClass.id}`}
              key={featuredClass.id}
              className="c-originals-featured-classes__card u-flex u-flex-column relative"
            >
              <div
                className={c(
                  'absolute absolute--tr c-workshop-overview__thumbnail--time u-white f-text-5 u-bold u-inline-block u-border-radius pv8 ph16 mt16 mr16',
                  {
                    'c-workshop-overview__thumbnail--time-watched':
                      isFeaturedClassFullyWatched(featuredClass),
                  }
                )}
              >
                <p className="u-flex u-align-center">
                  {isFeaturedClassFullyWatched(featuredClass) && (
                    <Icon id="checkmark" size="xs" className="mr6" />
                  )}
                  {t(`${secondsToTime(featuredClass.duration || 0, true)}`)}
                </p>
              </div>
              {/* <a href={featuredClass.url}> */}
              <img
                src={featuredClass.thumbnail || '/thumbnail-placeholder.png'}
                className={c('u-1/1 u-object-cover mb12', {
                  'c-originals-overview__episodes-watched':
                    isFeaturedClassFullyWatched(featuredClass),
                })}
                alt={featuredClass.title}
              />
              <h5 className="f-lynstone-bold-3">{featuredClass.title}</h5>
              {/* </a> */}
            </Link>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default connect(['sizing'], () => ({
  ...portalActions(),
  ...modalActions(),
}))(OriginalsPlayer);
