export enum TrackingLocation {
  banner = 'banner',
  sidebar = 'sidebar',
  mobileNav = 'mobile-nav',
  createProject = 'create-project',
  canvas = 'canvas',
  categoryHeader = 'category-header',
  centreButton = 'centre-button',
  homepage = 'homepage',
  join = 'join',
  modal = 'modal',
  userProfile = 'user-profile',
  desktopNavDropdown = 'desktop-nav-dropdown',
  categoriesNavigation = 'categories-navigation',
  daisieLogo = 'daisie-logo',
  backButton = 'back-button',
  loggedOutRoom = 'logged-out-room',
  loggedOutRoomPublic = 'logged-out-room-public',
  loggedOutRoomPrivate = 'logged-out-room-private',
  messagesSidebar = 'messages-side-bar',
  forYou = 'for-you',
  search = 'search',
}
