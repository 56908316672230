import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'unistore/react';

import { FeedbackButton } from '@/components/global/FeedbackButton/FeedbackButton';
import { Icon } from '@/components/global/Icon/Icon';
import { Link } from '@/components/global/Link/Link';
import { NavigationCategoriesLoggedOutDropdown } from '@/components/global/Navigation/NavigationCategoriesLoggedOutDropdown';
import { OSSwitch } from '@/components/global/PreMobileWall/OsSwitch';
import { SearchBarNav } from '@/components/global/SearchBar/SearchBarNav';
import { Avatar } from '@/components/users/Avatar/Avatar';
import { UserVerified } from '@/components/users/UserVerified/UserVerified';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { GlobalModal } from '@/enums/GlobalModal';
import { GtmEvent } from '@/enums/GtmEvent';
import { IconSize } from '@/enums/IconSize';
import { TrackingLocation } from '@/enums/TrackingLocation';
import { UserFlairType } from '@/enums/UserFlairType';

import { TWITTER_SHARE_TEXT } from '@/messages/copy';

import { routes } from '@/routes';
import { parser } from '@/routes/parser';

import { modalActions } from '@/store/modules/modal';
import { portalActions } from '@/store/modules/portal';

import { DISCORD_URL, HELP_CENTRE_URL } from '@/utils/constants';
import { APP_STORE_LINK, COMMUNITY_GUIDELINES_URL } from '@/utils/constants';
import { isALink } from '@/utils/DOM/dom-testing';
import { enableCareersPage, enableExplore } from '@/utils/featureToggles';
import { disableLoggedOutCategoryPages } from '@/utils/featureToggles';
import { sendGtmEvent } from '@/utils/gtm/sendGtmEvent';
import { t } from '@/utils/i18n/i18n';
import { track } from '@/utils/mixpanel/mixpanel';
import { getRouteFromUrl } from '@/utils/urls/routes';

interface Props
  extends AuthState,
    PortalActions,
    ModalActions,
    SizingState,
    WorkshopState,
    CategoryState,
    SubscriptionState,
    NotificationsState,
    ABTestState,
    RouteComponentProps {
  page?: string;
  close: () => void;
  mobileNavOpen?: boolean;
  toggleBurgerMenu: () => void;
}

class NavigationDropdownComponent extends React.Component<Props> {
  private footerLinks() {
    return (
      <div className="f-text-5 u-flex u-flex-wrap ph4@m">
        <div className="f-text-5 u-flex u-flex-wrap pt16 ph4@m">
          <a
            href={COMMUNITY_GUIDELINES_URL}
            className="mr16 u-link-grey mb8"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Community Guidelines')}
          </a>
          <div>
            <Link
              className="mb8 u-link-grey"
              to={parser({
                name: 'terms',
                params: {
                  campaign: '',
                },
              })}
            >
              {t('Terms')}
            </Link>
            <span className="mh8">&#183;</span>
            <Link className="mb8 u-link-grey" to={routes.privacyPolicy}>
              {t('Privacy')}
            </Link>
            <span className="mh8">&#183;</span>
            <Link className="u-link-grey" to={routes.safetyCenter}>
              {t('Help Centre')}
            </Link>
          </div>
        </div>
      </div>
    );
  }

  private renderMobileCta = () => (
    <OSSwitch
      renderIos={
        <a
          className="mv24"
          href={APP_STORE_LINK}
          onClick={() => sendGtmEvent(GtmEvent.DownloadApp)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <p className="u-flex u-white u-1/1 animate-opacity f-title-3 opacity-1 u-bold">
            <span>{t('Download')}</span>
          </p>
        </a>
      }
      renderAndroid={null}
    />
  );

  private renderLogInButton = () => {
    const {
      page,
      location: { pathname },
      workshop: { currentWorkshop: workshop },
    } = this.props;

    const workshopUrl = !!workshop
      ? parser({
          name: 'workshop',
          params: { workshopSlug: workshop.slug },
        })
      : undefined;

    const workshopBatch =
      !!workshop && workshop.batches && workshop.batches[0]
        ? `?session=${workshop.batches[0].id.replace('workshopBatch_', '')}`
        : undefined;

    const loginRoute =
      page && page === 'room'
        ? `${routes.login}?to=${encodeURIComponent(pathname)}`
        : page && page === 'workshop'
        ? `${routes.login}${
            workshopUrl && workshopBatch
              ? `?to=${encodeURIComponent(`${workshopUrl}${workshopBatch}`)}`
              : ''
          }`
        : routes.login;

    return (
      <Link to={loginRoute} className="u-inline-block pv24 u-text-left u-1/1">
        {t('Log in')}
      </Link>
    );
  };

  private renderSignUpOrUpgradeButtons = () => {
    const {
      page,
      location: { pathname },
      workshop: { currentWorkshop: workshop },
      auth: { isAuthorised },
      organisedCategories: { industries },
      subscription: { userCurrency },
      auth: { user },
      abtest: { variant },
    } = this.props;

    const workshopUrl = !!workshop
      ? parser({
          name: 'workshop',
          params: { workshopSlug: workshop.slug },
        })
      : undefined;

    const registerRoute: string =
      isAuthorised && user && user.isGrandfathered
        ? routes.getStarted
        : isAuthorised && variant === 2
        ? routes.optimisedJoinFlowSignUpYearlyPay
        : isAuthorised
        ? routes.optimisedJoinFlowChoosePlan
        : page && page === 'room'
        ? `${routes.login}?to=${encodeURIComponent(pathname)}`
        : page && page === 'workshop'
        ? `${routes.register}${
            workshopUrl
              ? `?to=${encodeURIComponent(`${workshopUrl}`)}${
                  variant === 2 ? '&isFromWorkshop=true' : ''
                }`
              : ''
          }`
        : routes.register;

    return (
      <div className="u-flex u-flex-column u-justify-center mb12 u-text-center">
        <h4 className="u-white u-bold f-title-3 pb8">
          {t('Learn something new today')}
        </h4>

        <p className="u-light-grey f-text-3 pb20">
          {t('Access live, daily classes across 10 categories.')}
        </p>

        <Button
          type={ButtonType.link}
          linkTo={!user?.isGrandfathered ? registerRoute : routes.getStarted}
          text={t(user && user?.isGrandfathered ? 'Upgrade' : 'Get Started')}
          className="u-flex-hold u-flex-1"
          buttonTextClassName="pv6"
          onClick={(e: any) => {
            if (!isAuthorised) {
              if (getRouteFromUrl(pathname) === 'categoryIndex') {
                const community = industries.find(
                  (category: Category) =>
                    category.slug === pathname.split('/')[2]
                );

                track('pwe_community_page_win', {
                  currency: userCurrency,
                  communityName: community ? community.name : undefined,
                  source: 'navigation_mobile',
                });
              } else if (getRouteFromUrl(pathname) === 'workshop') {
                track('pwe_logged_out_workshop_win', {
                  currency: userCurrency,
                  source: 'navigation_mobile',
                });
              }
            }

            this.closeMobileNav(e);
          }}
        />
      </div>
    );
  };

  private closeMobileNav = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!event || !event.target) return;

    if (isALink(event.target as HTMLElement)) {
      document.body.classList.remove('prevent-scroll-mobile');
    }
  };

  private loggedIn(user: User) {
    const {
      sizing: { isMobile },
      notifications: { unreadNotificationsCount },
      auth: { isAuthorised },
    } = this.props;

    const showInstructorDashboardLink: boolean =
      user.flair === UserFlairType.mentor;

    return (
      <div>
        {isMobile ? (
          <ul className="u-bold mb20 u-link-black f-title-3">
            <li className="u-white pv24 bb--charcoal-grey">
              <Link to={routes.home}>{t('Home')}</Link>
            </li>
            <li className="u-white pv24 bb--charcoal-grey">
              <Link to={routes.calendar}>{t('Calendar')}</Link>
            </li>
            <li className="u-white pv24 bb--charcoal-grey">
              <Link to={routes.classes}>{t('Classes')}</Link>
            </li>
            {enableExplore() && (
              <li className="u-white pv24 bb--charcoal-grey">
                <Link to={routes.explore}>{t('Explore')}</Link>
              </li>
            )}

            {showInstructorDashboardLink && (
              <li className="u-white pv24 bb--charcoal-grey">
                <Link to={routes.instructorDashboard}>
                  {t('Instructor Dashboard')}
                </Link>
              </li>
            )}

            <li className="u-white pv24 bb--charcoal-grey">
              <a
                href={DISCORD_URL}
                target="_blank"
                rel="noreferrer"
                className="u-1/1 u-inline-block"
              >
                {t('Discord')}
              </a>
            </li>

            <li className="pt24">
              <SearchBarNav
                darkMode={true}
                mobileNavOpen={this.props.mobileNavOpen}
                toggleBurgerMenu={this.props.toggleBurgerMenu}
              />
            </li>
            <li className="pt24">
              {(!isAuthorised || (user && user.subscriptionTier === null)) &&
                this.renderSignUpOrUpgradeButtons()}
            </li>
          </ul>
        ) : (
          <>
            <Link
              to={{ name: 'user', params: { username: user.username } }}
              className="u-flex u-justify-start u-align-center relative pb24"
            >
              <div className="mr16">
                <Avatar
                  alt={user.primaryName}
                  src={user.avatar}
                  size="xl"
                  user={user}
                />
              </div>
              <div className="mr8 u-truncate u-flex-1">
                <h4 className="f-text-2 u-bold mb2 u-flex u-align-center">
                  <span className="u-truncate">{user.primaryName}</span>
                  <UserVerified
                    user={user}
                    className="u-flex-hold relative@s t-1@s"
                  />
                </h4>
                <h5 className="f-text-3 u-truncate">
                  {t('Go to your profile')}
                </h5>
              </div>
            </Link>
            <div className="pb16 bb--sad-grey">
              <ul>
                <li className="mb24 mb16@m">
                  <a
                    href={DISCORD_URL}
                    target="_blank"
                    rel="noreferrer"
                    className="u-link-grey f-text-3 u-flex u-align-center"
                  >
                    <Icon id="discord" size="s" className="mr8" />
                    {t('Discord')}
                  </a>
                </li>
                <li className="mb24 mb16@m">
                  <button
                    type="button"
                    className="u-link-grey f-text-3 u-flex u-align-center"
                    onClick={() => {
                      this.props.updateModal({
                        modal: GlobalModal.invite_friends,
                        data: {
                          from: TrackingLocation.desktopNavDropdown,
                          twitterText: TWITTER_SHARE_TEXT,
                        },
                      });
                    }}
                  >
                    <Icon id="collaboration" size="s" className="mr8" />
                    {t('Invite Friends')}
                  </button>
                </li>
                {showInstructorDashboardLink && (
                  <li className="mb24 mb16@m">
                    <Link
                      className="u-link-grey f-text-3 u-flex u-align-center"
                      to={routes.instructorDashboard}
                    >
                      <Icon id="mentor-mono" size="s" className="mr8" />
                      {t('Instructor Dashboard')}
                    </Link>
                  </li>
                )}
                <li className="mb24 mb16@m">
                  <Link
                    className="u-link-grey f-text-3 u-flex u-align-center"
                    to={routes.account}
                  >
                    <Icon id="settings" size="s" className="mr8" />
                    {t('Account Settings')}
                  </Link>
                </li>
                <li>
                  <Link
                    to={routes.logout}
                    className="u-link-grey f-text-3 u-flex u-align-center"
                  >
                    <Icon id="exit" size="s" className="mr8" />
                    {t('Log Out')}
                  </Link>
                </li>
              </ul>
            </div>
          </>
        )}

        {!isMobile && this.footerLinks()}
      </div>
    );
  }

  private loggedOut() {
    return (
      <div>
        <ul className="u-bold mb20 u-link-black f-title-3">
          <li className="u-white bb--charcoal-grey">
            <Link to={routes.calendar} className="u-inline-block pb24 u-1/1">
              {t('Calendar')}
            </Link>
          </li>
          <li className="u-white bb--charcoal-grey">
            <Link to={routes.classes} className="u-inline-block pv24 u-1/1">
              {t('Classes')}
            </Link>
          </li>
          {!disableLoggedOutCategoryPages() && (
            <li className="u-white bb--charcoal-grey u-1/1">
              <NavigationCategoriesLoggedOutDropdown className="pv24" />
            </li>
          )}
          <li className="u-white bb--charcoal-grey">
            <div className="pv24">{this.renderMobileCta()}</div>
          </li>
          <li className="u-white bb--charcoal-grey">
            {this.renderLogInButton()}
          </li>
          {enableCareersPage() && (
            <li className="u-white bb--charcoal-grey">
              <Link to={routes.careers} className="u-inline-block pv24 u-1/1">
                {t('Careers')}
              </Link>
            </li>
          )}
          <li className="pt24">{this.renderSignUpOrUpgradeButtons()}</li>
        </ul>
      </div>
    );
  }

  public render() {
    const { auth } = this.props;
    return !auth.user ? this.loggedOut() : this.loggedIn(auth.user);
  }
}

export const NavigationDropdown = connect(
  [
    'auth',
    'sizing',
    'workshop',
    'organisedCategories',
    'subscription',
    'notifications',
    'abtest',
  ],
  () => ({
    ...portalActions(),
    ...modalActions(),
  })
)(withRouter(NavigationDropdownComponent));
