import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface TransitionElements {
  reviewIntros: NodeListOf<HTMLElement>;
  carouselSlides: NodeListOf<Element>;
  carouselTrack: Element | null;
  triggerElement: Element | null;
}

export const initWorkshopTransitions = (
  isMobile: boolean,
  isTablet: boolean,
  elements: TransitionElements
) => {
  const initializedTransitions = [];

  initializedTransitions.push(
    gsap.fromTo(
      elements.reviewIntros[0],
      {
        scale: isMobile ? 1.1 : isTablet ? 1.3 : 1.5,
        y: '-50%',
      },
      {
        scale: 1,
        scrollTrigger: {
          trigger: elements.triggerElement,
          start: () => 'top center',
          end: () => '400',
          scrub: true,
        },
      }
    )
  );

  // Intro text 1 disappear
  initializedTransitions.push(
    gsap.fromTo(
      elements.reviewIntros[0],
      {
        opacity: 1,
      },
      {
        y: '-60%',
        opacity: 0,
        scrollTrigger: {
          trigger: elements.triggerElement,
          start: () => '400',
          end: () => '800',
          scrub: true,
        },
      }
    )
  );

  // Intro text 2 disappear
  initializedTransitions.push(
    gsap.fromTo(
      elements.reviewIntros[1],
      {
        opacity: 1,
        y: '-50%',
      },
      {
        opacity: 0,
        y: '-60%',
        scrollTrigger: {
          trigger: elements.triggerElement,
          start: () => '1200',
          end: () => '1600',
          scrub: true,
        },
      }
    )
  );

  // Intro text 2 appear
  initializedTransitions.push(
    gsap.fromTo(
      elements.reviewIntros[1],
      {
        opacity: 0,
        y: '-40%',
      },
      {
        opacity: 1,
        y: '-50%',
        scrollTrigger: {
          trigger: elements.triggerElement,
          start: () => '800',
          end: () => '1200',
          scrub: true,
          refreshPriority: 1,
        },
      }
    )
  );

  // Intro text 3 disappear
  initializedTransitions.push(
    gsap.fromTo(
      elements.reviewIntros[2],
      {
        opacity: 1,
        // y: '-50%',
      },
      {
        opacity: 0,
        // y: '-60%',
        scale: 0.7,
        scrollTrigger: {
          trigger: elements.triggerElement,
          start: () => '2200',
          end: () => '2800',
          scrub: true,
        },
      }
    )
  );

  // Intro text 3 appear
  initializedTransitions.push(
    gsap.fromTo(
      elements.reviewIntros[2],
      {
        opacity: 0,
        y: '-40%',
      },
      {
        opacity: 1,
        y: '-50%',
        scrollTrigger: {
          trigger: elements.triggerElement,
          start: () => '1600',
          end: () => '2000',
          scrub: true,
        },
      }
    )
  );

  const numOfItemsDisplayed = isTablet ? 2 : isMobile ? 1 : 4;

  const maxXoffset =
    elements.carouselSlides[0].clientWidth * elements.carouselSlides.length -
    elements.carouselSlides[0].clientWidth * numOfItemsDisplayed;

  initializedTransitions.push(
    gsap.fromTo(
      elements.carouselTrack,
      {
        x: 0,
      },
      {
        x: -maxXoffset,
        scrollTrigger: {
          trigger: elements.triggerElement,
          start: () => (isTablet ? '3200' : '2800'),
          end: () => 'bottom center',
          scrub: 1,
        },
      }
    )
  );

  initializedTransitions.push(
    gsap.fromTo(
      elements.carouselSlides[0],
      {
        x: isMobile ? 0 : '-70vw',
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: elements.triggerElement,
          start: () => (isTablet ? '2600' : isMobile ? '2300' : '2000'),
          end: () => (isTablet ? '3000' : '2800'),
          scrub: true,
        },
      }
    )
  );

  // Don't transition the rest of the slides on mobile
  if (isMobile) return;

  initializedTransitions.push(
    gsap.fromTo(
      elements.carouselSlides[1],
      {
        x: isTablet ? '70vw' : '-50vw',
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: elements.triggerElement,
          start: () => (isTablet ? '2600' : '2000'),
          end: () => (isTablet ? '3000' : '2800'),
          scrub: true,
        },
      }
    )
  );

  // Don't transition the rest of the slides on tabled
  if (isTablet) return;

  initializedTransitions.push(
    gsap.fromTo(
      elements.carouselSlides[2],
      {
        x: '50vw',
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: elements.triggerElement,
          start: () => '2000',
          end: () => '2800',
          scrub: true,
        },
      }
    )
  );

  initializedTransitions.push(
    gsap.fromTo(
      elements.carouselSlides[3],
      {
        x: '70vw',
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: elements.triggerElement,
          start: () => '2000',
          end: () => '2800',
          scrub: true,
        },
      }
    )
  );

  initializedTransitions.push(
    gsap.fromTo(
      elements.carouselSlides[4],
      {
        x: '90vw',
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: elements.triggerElement,
          start: () => '2000',
          end: () => '2800',
          scrub: true,
        },
      }
    )
  );

  return initializedTransitions;
};

export const initializeMentorCarousel = (
  trigger: Element | null,
  mentorCarouselTrack: Element | null,
  carouselCards: NodeListOf<Element>,
  isDesktopViewport?: boolean
) => {
  if (mentorCarouselTrack === null && mentorCarouselTrack == null) return null;

  const mentorCarouselTransition = gsap.fromTo(
    mentorCarouselTrack,
    {
      x: 0,
    },
    {
      x: -(
        carouselCards.length * carouselCards[0].clientWidth -
        window.innerWidth +
        (isDesktopViewport ? 84 : 48)
      ),
      ease: 'none',
      scrollTrigger: {
        trigger: trigger,
        start: () => 'top center',
        end: () => 'bottom bottom',
        scrub: true,
      },
    }
  );

  return mentorCarouselTransition;
};
