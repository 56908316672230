import * as React from 'react';

import { c } from '@/utils/strings/c';

interface Props {
  width?: string;
  height?: string;
  preserveAspectRatio?: string;
  className?: string;
  fill?: string;
}

const Skeleton: React.FunctionComponent<Props> = (props: Props) => {
  const {
    width = '100%',
    height = '1em',
    preserveAspectRatio = 'none',
    className = '',
    fill = '#333333',
  } = props;

  return (
    <svg
      width={width}
      height={height}
      preserveAspectRatio={preserveAspectRatio}
      xmlns="http://www.w3.org/2000/svg"
      className={c(['skeleton', className])}
    >
      <path fill={fill} d="M0 0h500v500H0z" fillRule="evenodd" />
    </svg>
  );
};

export { Skeleton };
