import * as React from 'react';

import delve from 'dlv';

import { Link } from '@/components/global/Link/Link';
import { BaseNotification } from '@/components/notifications/BaseNotification';
import { CollaborationInvitation } from '@/components/notifications/NotificationTypes/CollaborationInvitation';
import { CollaborationRequest } from '@/components/notifications/NotificationTypes/CollaborationRequest';
import { CollaboratorAccepted } from '@/components/notifications/NotificationTypes/CollaboratorAccepted';
import { CollaboratorAcceptedAll } from '@/components/notifications/NotificationTypes/CollaboratorAcceptedAll';
import { FollowRequestNotification } from '@/components/notifications/NotificationTypes/FollowRequestNotification';

import { NotificationItemLocations } from '@/enums/NotificationItemLocations';
import { NotificationTypes } from '@/enums/NotificationTypes';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props {
  className?: string;
  notification: DaisieNotification;
  size?: string;
  parentProject?: Project;
  location?: NotificationItemLocations;
  onClickToggle: () => void;
  isDarkMode: boolean;
}

interface State {
  isWorkshopLinkOpen: boolean;
}

class NotificationItem extends React.Component<Props, State> {
  public render() {
    const {
      notification,
      size = 'large',
      parentProject,
      location,
      isDarkMode,
      onClickToggle,
    } = this.props;

    const className = c([
      this.props.className || '',
      `c-notification--${size}`,
    ]);

    if (!notification || !notification.type) return null;

    const defaultProps = {
      isDarkMode,
      className,
      notification,
      secondary: ({ date }: { date: string }) => date,
      onClickToggle,
    };

    switch (notification.type) {
      case NotificationTypes.CollaboratorRemove:
        if (!notification.user || !notification.actor) return null;

        return (
          <BaseNotification
            {...defaultProps}
            primary={
              <>
                {notification.user.username === notification.actor.username &&
                  'removed themselves as a member'}
                {notification.user.username !== notification.actor.username && (
                  <>
                    {'removed '}
                    <Link
                      to={{
                        name: 'user',
                        params: { username: notification.actor.username },
                      }}
                    >
                      {notification.actor.primaryName}
                    </Link>
                    {' as a member'}
                  </>
                )}
              </>
            }
            icon="clear"
            iconClassName="u-danger"
          />
        );

      case NotificationTypes.CollaborationInvitation:
        return (
          <CollaborationInvitation {...defaultProps} location={location} />
        );

      case NotificationTypes.CollaborationInvite:
        if (!notification.collaborationInvitation) return null;

        return (
          <BaseNotification
            {...defaultProps}
            primary={
              notification.collaborationInvitation.invitee && (
                <>
                  {'invited '}
                  <Link
                    to={{
                      name: 'user',
                      params: {
                        username:
                          notification.collaborationInvitation.invitee.username,
                      },
                    }}
                  >
                    {notification.collaborationInvitation.invitee.primaryName}
                  </Link>
                  {' to become a member'}
                </>
              )
            }
            icon="add"
            iconClassName="u-go"
          />
        );

      case NotificationTypes.CollaborationInviteAccept:
        return (
          <BaseNotification
            {...defaultProps}
            primary={
              <>
                {'accepted '}
                {delve(
                  notification,
                  'collaborationInvitation.inviter.primaryName'
                ) ? (
                  <>
                    <Link
                      to={{
                        name: 'user',
                        params: {
                          username: delve(
                            notification,
                            'collaborationInvitation.inviter.username'
                          ),
                        },
                      }}
                    >
                      {delve(
                        notification,
                        'collaborationInvitation.inviter.primaryName'
                      )}
                    </Link>
                    &apos;s invite to collaborate
                  </>
                ) : (
                  notification.project && (
                    <>
                      {'your invite to join '}
                      <Link
                        to={{
                          name: 'room',
                          params: {
                            roomSlug: notification.project.slug,
                          },
                        }}
                      >
                        {notification.project.title}
                      </Link>
                    </>
                  )
                )}
              </>
            }
            icon="tick"
          />
        );

      case NotificationTypes.CollaborationInviteDecline:
        return (
          <BaseNotification
            {...defaultProps}
            primary={
              <span>
                {'declined '}
                {delve(
                  notification,
                  'collaborationInvitation.inviter.primaryName'
                ) ? (
                  <>
                    <Link
                      to={{
                        name: 'user',
                        params: {
                          username: delve(
                            notification,
                            'collaborationInvitation.inviter.username'
                          ),
                        },
                      }}
                    >
                      {delve(
                        notification,
                        'collaborationInvitation.inviter.primaryName'
                      )}
                    </Link>
                    &apos;s invite to collaborate
                  </>
                ) : (
                  notification.project && (
                    <>
                      {'your invite to join '}
                      <Link
                        to={{
                          name: 'room',
                          params: {
                            roomSlug: notification.project.slug,
                          },
                        }}
                      >
                        {notification.project.title}
                      </Link>
                    </>
                  )
                )}
              </span>
            }
            icon="clear"
            iconClassName="u-danger"
          />
        );

      case NotificationTypes.CollaborationRequest:
        return (
          <CollaborationRequest
            {...defaultProps}
            parentProject={parentProject}
            location={location}
          />
        );

      case NotificationTypes.CollaboratorRequestAccept:
        return (
          <CollaboratorAccepted
            {...defaultProps}
            parentProject={parentProject}
          />
        );

      case NotificationTypes.CollaboratorRequestAcceptAll:
        return <CollaboratorAcceptedAll {...defaultProps} />;

      case NotificationTypes.CollaborationRequestReject:
        if (!notification.actor) return null;

        return (
          <BaseNotification
            {...defaultProps}
            primary={
              <>
                {'declined '}
                <Link
                  to={{
                    name: 'user',
                    params: { username: notification.actor.username },
                  }}
                >
                  {notification.actor.primaryName}
                </Link>
                &apos;s request to collaborate
              </>
            }
            icon="clear"
            iconClassName="u-danger"
          />
        );

      case NotificationTypes.FirstCollaborator:
        return null;

      case NotificationTypes.FirstProject:
        return null;

      case NotificationTypes.FollowRequest:
        return <FollowRequestNotification {...defaultProps} />;

      case NotificationTypes.FollowingProjectUpdated:
        return (
          <BaseNotification
            {...defaultProps}
            primary={
              <>
                {t('updated')}{' '}
                {notification.project ? (
                  <Link
                    to={{
                      name: 'room',
                      params: {
                        roomSlug: notification.project.slug,
                      },
                    }}
                  >
                    {notification.project.title}
                  </Link>
                ) : (
                  t('their room')
                )}
              </>
            }
          />
        );

      case NotificationTypes.FollowingUserPortfolioUpdated:
        return (
          <BaseNotification
            {...defaultProps}
            primary={
              <>
                {t('updated')}{' '}
                <Link
                  to={{
                    name: 'userPortfolio',
                    params: {
                      username: notification.user
                        ? notification.user.username
                        : '',
                    },
                  }}
                >
                  {t('their portfolio')}
                </Link>
              </>
            }
          />
        );

      case NotificationTypes.FollowingUserProjectCreated:
        const { project } = notification;

        if (!project) return null;

        return (
          <BaseNotification
            {...defaultProps}
            primary={
              <>
                {t('created a')} {t('room')}{' '}
                <Link
                  to={{
                    name: 'room',
                    params: {
                      roomSlug: project.slug,
                    },
                  }}
                >
                  {project.title}
                </Link>
              </>
            }
          />
        );

      case NotificationTypes.FollowingUserProjectJoined:
        return (
          <BaseNotification
            {...defaultProps}
            primary={
              <>
                {t('joined')}{' '}
                {notification.project ? (
                  <Link
                    to={{
                      name: 'room',
                      params: {
                        roomSlug: notification.project.slug,
                      },
                    }}
                  >
                    {notification.project.title}
                  </Link>
                ) : (
                  t('a room')
                )}
              </>
            }
          />
        );

      case NotificationTypes.ProjectCategoriesUpdated:
        return (
          <BaseNotification
            {...defaultProps}
            primary={t('updated the category')}
          />
        );

      case NotificationTypes.ProjectDescriptionUpdated:
        return (
          <BaseNotification
            {...defaultProps}
            primary={t('updated the description')}
          />
        );

      case NotificationTypes.ProjectTitleUpdated:
        return (
          <BaseNotification
            {...defaultProps}
            primary={t('updated the title')}
          />
        );
      case NotificationTypes.WaitlistInvite:
        return (
          <BaseNotification
            {...defaultProps}
            primary={
              <p>
                <span className="u-bold">
                  {t(`Your invitation to join the Daisie beta is now ready!`)}
                </span>{' '}
                <span>
                  {t(
                    `To update, open Daisie on your iPhone and tap 'Get access'.`
                  )}
                </span>
              </p>
            }
            useDaisieLogo={true}
          />
        );
      case NotificationTypes.FollowingUserIsHostingWorkshop:
        return (
          <BaseNotification
            {...defaultProps}
            primary={
              <>
                {t('is hosting a workshop')}{' '}
                {notification.workshop ? (
                  <Link
                    to={{
                      name: 'workshop',
                      params: {
                        workshopSlug: notification.workshop.slug,
                      },
                    }}
                    className="u-bold"
                    onClick={this.props.onClickToggle}
                  >
                    &quot;{notification.workshop.title}&quot;
                  </Link>
                ) : null}
              </>
            }
          />
        );
      default:
        return null;
    }
  }
}

export { NotificationItem };
