import * as React from 'react';

import { connect } from 'unistore/react';

import { Ribbons } from '@/components/global/Ribbons/Ribbons';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';
import { ButtonGroup } from '@/components/design_system/ButtonGroup/ButtonGroup';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';

interface Props extends SizingState {
  showCTAs?: boolean;
  className?: string;
  titleText?: string;
  subtitleText?: string;
  linkRoute?: string;
  buttonOnClick?: () => any;
  ctaText?: string;
  ctaIcon?: string;
  secondButtonOnClick?: () => any;
  secondLinkRoute?: string;
  secondCtaText?: string;
  ribbonsClassName?: string;
  ribbonText?: string;
  hideBackground?: boolean;
}

class EmptyStateComponent extends React.Component<Props> {
  private renderButton = (
    ctaText: string,
    ctaIcon?: string,
    buttonOnClick?: () => any,
    linkRoute?: string,
    buttonStyle: ButtonStyle = ButtonStyle.dark
  ) =>
    linkRoute ? (
      <Button
        type={ButtonType.link}
        linkTo={linkRoute}
        text={ctaText}
        onClick={buttonOnClick}
        buttonStyle={buttonStyle}
      />
    ) : (
      buttonOnClick && (
        <Button
          type={ButtonType.action}
          onClick={buttonOnClick}
          text={ctaText}
          iconId={ctaIcon}
          buttonStyle={buttonStyle}
        />
      )
    );

  private renderCTAView() {
    const {
      className = '',
      ctaText,
      buttonOnClick,
      linkRoute,
      secondCtaText,
      secondButtonOnClick,
      secondLinkRoute,
      titleText,
      subtitleText,
      ctaIcon,
      hideBackground,
      sizing: { isMobile },
    } = this.props;

    if (!titleText) {
      return this.renderRibbonView();
    }

    return (
      <div
        className={c(['c-empty-state', className], {
          'c-empty-state--background': !hideBackground,
          ' u-bg-black': isMobile,
        })}
      >
        <div
          className={c('u-flex-column u-text-center ph24 wrap--s', {
            'page-content--centered': isMobile,
          })}
        >
          <p
            className={c('f-title-2 u-bold mb16 mt8@s', {
              'u-white': isMobile,
            })}
          >
            {titleText}
          </p>
          <p className="f-title-5 u-dark-grey mb24">{subtitleText}</p>
          <ButtonGroup className="u-1/1 u-justify-center">
            {ctaText &&
              this.renderButton(
                ctaText,
                ctaIcon,
                buttonOnClick,
                linkRoute,
                ButtonStyle.dark
              )}
            {secondCtaText &&
              this.renderButton(
                secondCtaText,
                undefined,
                secondButtonOnClick,
                secondLinkRoute,
                ButtonStyle.light
              )}
          </ButtonGroup>
        </div>
      </div>
    );
  }

  private renderRibbonView() {
    const { ribbonsClassName = '', ribbonText } = this.props;

    return (
      <Ribbons
        classNameContainer={c(['c-empty-state', ribbonsClassName])}
        text={ribbonText || t('Nothing here')}
        styleA={{
          className: 'u-bg-almost-black u-white',
          rotate: '10deg',
          scale: 1.2,
          transformOrigin: 'center',
        }}
        styleB={{
          className: 'u-bg-almost-black u-white',
          rotate: '-11deg',
          scale: 1.2,
          transformOrigin: 'center',
        }}
      />
    );
  }

  public render() {
    const { showCTAs = false } = this.props;

    if (showCTAs) {
      return this.renderCTAView();
    }

    return this.renderRibbonView();
  }
}

export const EmptyState = connect(['sizing'])(EmptyStateComponent);
