/**
 * This script generates random positioned
 * black squares for the transition between
 * the Outcomes and Overview sections
 *
 */

import { isDesktop } from '@/utils/DOM/sizing';

interface Square {
  x: string;
  y: string;
}

// Gets random number between given interval
const randomIntFromInterval = (min: number, max: number) =>
  String(Math.floor(Math.random() * (max - min + 1) + min));

let breakpoint;

if (window.innerWidth < 768) {
  breakpoint = 'base';
} else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
  breakpoint = 'md';
} else {
  breakpoint = 'lg';
}

export const transitionSquares: Square[] = [{ x: '', y: '' }];

for (let i = 0; i <= 140; i++) {
  transitionSquares.push({
    x: randomIntFromInterval(
      1,
      Math.floor(
        window.innerWidth /
          (!isDesktop() ? window.innerWidth / 9 : window.innerWidth / 16) +
          1
      )
    ),
    y:
      breakpoint === 'base'
        ? randomIntFromInterval(82, 95)
        : breakpoint === 'md'
        ? randomIntFromInterval(40, 52)
        : randomIntFromInterval(37, 48),
  });
}
