import * as React from 'react';

import MockDate from 'mockdate';
import ReactGA from 'react-ga';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import { connect } from 'unistore/react';

import {
  About,
  Account,
  Category,
  Home,
  Legal,
  Notifications,
  PasswordRequest,
  Profile,
  Search,
  Unsubscribe,
} from '@/components/application/Loadables';
import { CustomRedirects } from '@/components/application/Redirects';
import { AppFrame } from '@/components/global/AppFrame/AppFrame';
import { PrivateRoute } from '@/components/global/PrivateRoute/PrivateRoute';
import { CollectionPage } from '@/components/ondemand/Collections/CollectionPage';

import { DesignSystemGuide } from '@/components/design_system/DesignSystemGuide';

import { TrackingLocation } from '@/enums/TrackingLocation';

import { AlexJenkinsClass } from '@/pages/AlexJenkinsClass';
import { AppStoreReferral } from '@/pages/AppStoreReferral';
import { CalendarPage } from '@/pages/Calendar/Calendar';
import { Careers } from '@/pages/Careers/Careers';
import { Classes } from '@/pages/Classes/Classes';
import { CryptoSignUp } from '@/pages/CryptoSignUp/CryptoSignUp';
import { DiagnosticTool } from '@/pages/DiagnosticTool';
import { DiscordAuth } from '@/pages/DiscordAuth/DiscordAuth';
import { EmailDiscountLink } from '@/pages/EmailDiscountLink/EmailDiscountLink';
import { Explore } from '@/pages/Explore/Explore';
import { Following } from '@/pages/Following/Following';
import { GrandfatheredUserHomepage } from '@/pages/GrandfatheredUserHomepage/GrandfatheredUserHomepage';
import { InstructorDashboard } from '@/pages/InstructorDashboard/InstructorDashboard';
import { LoggedOutHomepage } from '@/pages/LoggedOutHomepage/LoggedOutHomepage';
import { Logout } from '@/pages/Logout';
import { OptimiseJoinFlowChoosePlan } from '@/pages/OptimisedJoinFlow/OptimisedJoinFlowChoosePlan';
import { OptimisedJoinFlowPay } from '@/pages/OptimisedJoinFlow/OptimisedJoinFlowPay';
import { OptimisedJoinFlowSignUp } from '@/pages/OptimisedJoinFlow/OptimisedJoinFlowSignUp';
import Promo from '@/pages/Promo/Promo';
import { Redirector } from '@/pages/Redirector';
import { SignUpOrLogin } from '@/pages/SignUpOrLogin/SignUpOrLogin';
import { Teach } from '@/pages/Teach/Teach';
import Watch from '@/pages/Watch/Watch';
import { Workshop } from '@/pages/Workshop/Workshop';
import { WorkshopJoinNow } from '@/pages/WorkshopJoinNow/WorkshopJoinNow';
import { WorkshopOverview } from '@/pages/WorkshopOverview/WorkshopOverview';

import { routes } from '@/routes';
import { parser } from '@/routes/parser';

import { authActions } from '@/store/modules/auth';
import { categoryActions } from '@/store/modules/category';
import { cinematicBackgroundActions } from '@/store/modules/cinematicBackground';
import { notificationsActions } from '@/store/modules/notifications';
import { subscriptionActions } from '@/store/modules/subscription';

import '@/styles/global.scss';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

import { poll, throttle } from '@/utils/api/throttle';
import {
  enableCareersPage,
  enableExplore,
  enableGoogleAnalyticsLibrary,
} from '@/utils/featureToggles';
import { track } from '@/utils/mixpanel/mixpanel';

interface Props
  extends RouteComponentProps,
    AuthState,
    AuthActions,
    CategoryState,
    CategoryActions,
    NotificationsState,
    NotificationsActions,
    CinematicBackgroundActions,
    CinematicBackgroundState,
    SubscriptionActions,
    SubscriptionState {}

interface State {
  pending: boolean;
  // If <CustomRedirects> is invoked and will redirect to a non-daisie.com link
  // hide the 404 message so it doesn't flash before the redirect happens
  hidePageNotFound: boolean;
}

class AppComponent extends React.Component<Props, State> {
  private throttledResize: any;

  constructor(props: Props) {
    super(props);

    this.throttledResize = throttle(this.setVH, 100);

    this.state = {
      pending: true,
      hidePageNotFound: false,
    };
  }

  public componentDidMount = async () => {
    this.setVH();
    this.track();

    this.overrideTimeForUiTests();

    // Initialise window events
    window.addEventListener('resize', this.throttledResize);
    window.addEventListener('popstate', this.trackBackButton);

    if (!this.props.auth.isAuthorised) {
      try {
        await this.props.getStripeProductsAndPrices();
      } catch (e) {
        // do nothing
      }
    }

    try {
      await this.props.getCurrentUser(undefined, true);
    } catch (e) {
      await this.props.logout();
      window.location.href = `${routes.login}?auth-expired=true`;
    }

    const {
      auth: { user: currentUser },
    } = this.props;

    this.setState({ pending: false });

    if (!currentUser) {
      return;
    }

    try {
      await this.props.getStripeProductsAndPrices();
    } catch (e) {
      // do nothing
    }

    await this.props.getUserSubscription(currentUser.username);
    await this.props.fetchAndSortCategories();
    await this.props.fetchCuratedImages();

    if (process.env.DAISIE_DISABLE_NOTIFICATIONS) {
      if (process.env.DAISIE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.info('Notifications are disabled');
      }

      return;
    }

    const pollForNotifications =
      process.env.DAISIE_ENV !== 'local' &&
      !this.isLimitedUser(currentUser.username) &&
      !isUiTest();

    if (pollForNotifications) {
      poll(this.getNotifications);
    } else {
      if (!isUiTest()) {
        await this.getNotifications(1000);
      }
    }
  };

  public componentDidUpdate = (prevProps: Props) => {
    const { pathname } = this.props.location;

    if (pathname !== prevProps.location.pathname) {
      this.track();

      window.scrollTo(0, 0);
    }
  };

  public componentWillUnmount = () => {
    window.removeEventListener('resize', this.throttledResize);
    window.removeEventListener('popstate', this.trackBackButton);
  };

  private overrideTimeForUiTests = () => {
    // Use MockDate to override the system time, useful for fixing state
    // for UI tests. Just put ?overrideTime=[unix timestamp in nanoseconds]
    // at the end of a frontend URL
    if (!isUiTest()) return;

    const params = new URLSearchParams(this.props.location.search);
    const overrideTimestamp = decodeURIComponent(
      params.get('overrideTime') || ''
    );

    if (overrideTimestamp === '') return;

    MockDate.set(Number(overrideTimestamp));
  };

  private isLimitedUser = (username: string) => {
    const isListedAsProblemChild =
      process.env.DAISIE_PROBLEM_CHILDREN &&
      process.env.DAISIE_PROBLEM_CHILDREN.split(',').includes(username);

    if (isListedAsProblemChild && process.env.DAISIE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.info('Listed as limited user');
    }

    return isListedAsProblemChild;
  };

  private getNotifications = async (timeOutInterval: number) => {
    const timeout = new Promise((_, reject) =>
      setTimeout(reject, timeOutInterval)
    );

    try {
      await Promise.race([this.props.getUnreadNotificationsCount(), timeout]);
    } catch (e) {
      // Do nothing
    }

    const {
      notifications: { unreadNotificationsCount },
    } = this.props;

    if (unreadNotificationsCount > 0) {
      this.props.clearNotifications();
      this.props.fetchNotifications();
    }

    return;
  };

  private trackBackButton = (e: PopStateEvent) => {
    try {
      if (!e.target) return;

      const targetWindow = e.target as Window;

      if (targetWindow.location.pathname === '/') {
        track('go_home', { from: TrackingLocation.backButton });
      }
    } catch (e) {
      // do nothing
    }
  };

  private setVH = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  private track = () => {
    if (!enableGoogleAnalyticsLibrary()) {
      return;
    }

    const page = this.props.location.pathname + this.props.location.search;

    if (process.env.DAISIE_GA) {
      ReactGA.initialize(process.env.DAISIE_GA);
      ReactGA.pageview(page);
    }
  };

  public render() {
    const {
      auth: { user },
      location: { pathname },
    } = this.props;

    if (!this.state.pending) {
      return (
        <AppFrame isAuthorised={!!user}>
          <CustomRedirects
            hidePageNotFound={this.state.hidePageNotFound}
            setHidePageNotFound={(newState: boolean) =>
              this.setState({ hidePageNotFound: newState })
            }
          />

          <Switch>
            {user && (
              <Route
                render={() => <Redirect to={routes.home} />}
                exact={true}
                path={routes.login}
              />
            )}
            {user && (
              <Route
                render={() => <Redirect to={routes.home} />}
                exact={true}
                path={routes.register}
              />
            )}
            <Route component={Home} exact={true} path={routes.home} />
            <Route
              component={AlexJenkinsClass}
              exact={true}
              path={routes.alexJenkinsClass}
            />
            <Route component={About} exact={true} path={routes.about} />
            {enableCareersPage() && (
              <Route component={Careers} exact={true} path={routes.careers} />
            )}
            <Route component={Teach} exact={true} path={routes.teach} />
            <Route
              component={DiagnosticTool}
              exact={true}
              path={routes.diagnosticTool}
            />
            <Route
              component={AppStoreReferral}
              exact={true}
              path={routes.appStoreReferral}
            />
            <Route
              component={AppStoreReferral}
              exact={true}
              path={routes.app}
            />
            <Route
              component={Unsubscribe}
              exact={true}
              path={routes.unsubscribe}
            />
            <PrivateRoute
              component={Search}
              exact={true}
              path={routes.search}
            />
            <Route component={SignUpOrLogin} exact={true} path={routes.login} />
            <Route component={Logout} exact={true} path={routes.logout} />
            <Route component={Legal} exact={true} path={routes.campaignTerms} />
            <Route component={Legal} exact={true} path={routes.safetyCenter} />
            <Route component={Legal} exact={true} path={routes.reportCenter} />
            <Route component={Legal} exact={true} path={routes.contact} />
            <Route component={Legal} exact={true} path={routes.cookiePolicy} />
            <Route
              component={Legal}
              exact={true}
              path={routes.cancellationPolicy}
            />
            {/* Redirects to Notion pages */}
            <Route
              component={Redirector}
              exact={true}
              path={routes.communityGuidelines}
            />
            <Route component={Redirector} exact={true} path={routes.help} />
            <Route
              component={Redirector}
              exact={true}
              path={routes.helpCenter}
            />
            <Route
              component={Redirector}
              exact={true}
              path={routes.helpCentre}
            />
            <Route component={Redirector} exact={true} path={routes.terms} />
            <Route
              component={Redirector}
              exact={true}
              path={routes.privacyPolicy}
            />
            <Route
              render={() => <LoggedOutHomepage isStudentPromoPage={true} />}
              exact={true}
              path={routes.students}
            />
            <Route
              render={() => (
                <GrandfatheredUserHomepage isGetStartedPromoPage={true} />
              )}
              exact={true}
              path={routes.getStarted}
            />
            <Route
              component={EmailDiscountLink}
              exact={true}
              path={routes.getDiscount}
            />
            <PrivateRoute
              component={Notifications}
              exact={true}
              path={routes.notifications}
            />
            <PrivateRoute
              component={Following}
              exact={true}
              path={routes.following}
            />
            <Route
              component={SignUpOrLogin}
              exact={true}
              path={routes.register}
            />
            {/* /join/choose-plan */}
            <Route
              component={OptimiseJoinFlowChoosePlan}
              exact={true}
              path={routes.optimisedJoinFlowChoosePlan}
            />
            {/* /students/choose-plan */}
            <Route
              component={OptimiseJoinFlowChoosePlan}
              exact={true}
              path={routes.studentsChoosePlan}
            />
            {/* /join/monthly */}
            <Route
              component={OptimisedJoinFlowSignUp}
              exact={true}
              path={routes.optimisedJoinFlowSignUpMonthly}
            />
            {/* /join/monthly/pay */}
            <PrivateRoute
              component={OptimisedJoinFlowPay}
              exact={true}
              path={routes.optimisedJoinFlowSignUpMonthlyPay}
            />
            {/* /students/monthly */}
            <Route
              component={OptimisedJoinFlowSignUp}
              exact={true}
              path={routes.studentsSignUpMonthly}
            />
            {/* /students/monthly/pay */}
            <PrivateRoute
              component={OptimisedJoinFlowPay}
              exact={true}
              path={routes.studentsSignUpMonthlyPay}
            />
            {/* /join/yearly */}
            <Route
              component={OptimisedJoinFlowSignUp}
              exact={true}
              path={routes.optimisedJoinFlowSignUpYearly}
            />
            {/* /join/yearly/pay */}
            <PrivateRoute
              component={OptimisedJoinFlowPay}
              exact={true}
              path={routes.optimisedJoinFlowSignUpYearlyPay}
            />
            {/* /students/yearly */}
            <Route
              component={OptimisedJoinFlowSignUp}
              exact={true}
              path={routes.studentsSignUpYearly}
            />
            {/* /students/yearly/pay */}
            <PrivateRoute
              component={OptimisedJoinFlowPay}
              exact={true}
              path={routes.studentsSignUpYearlyPay}
            />
            <Route
              component={PasswordRequest}
              exact={true}
              path={routes.forgot}
            />

            <PrivateRoute
              component={InstructorDashboard}
              exact={true}
              path={routes.instructorDashboard}
            />

            <Route
              component={Account}
              exact={true}
              path={routes.accountSettings}
            />
            <Route
              component={Account}
              exact={true}
              path={routes.subscriptionSettings}
            />
            <PrivateRoute
              component={Account}
              exact={false}
              path={routes.account}
            />
            <Route component={Category} path={routes.categoryIndex} />
            {/* /category/:slug -> /community/:slug */}
            <Route
              render={(props: any) => (
                <Redirect
                  to={parser({
                    name: 'categoryIndex',
                    params: {
                      slug: props.match.params.slug,
                    },
                  })}
                />
              )}
              path={routes.categoryRedirect}
            />
            {/* /industry/:slug -> /community/:slug */}
            <Route
              render={(props: any) => (
                <Redirect
                  to={parser({
                    name: 'categoryIndex',
                    params: {
                      slug: props.match.params.slug,
                    },
                  })}
                />
              )}
              path={routes.industryRedirect}
            />
            <Route component={WorkshopJoinNow} path={routes.workshopJoinNow} />
            <Route
              component={WorkshopOverview}
              path={routes.workshopOverview}
            />
            <Route component={Workshop} path={routes.workshop} />
            <Route component={Promo} path={routes.promo} />
            <PrivateRoute component={Watch} path={routes.watch} />
            <Route
              component={CalendarPage}
              exact={true}
              path={routes.calendar}
            />
            <Route
              component={CryptoSignUp}
              exact={true}
              path={routes.cryptoSignUp}
            />
            {enableExplore() && (
              <PrivateRoute
                component={Explore}
                exact={true}
                path={routes.explore}
              />
            )}
            <PrivateRoute component={CollectionPage} path={routes.collection} />
            <Route component={Classes} exact={true} path={routes.classes} />
            <PrivateRoute
              component={DiscordAuth}
              exact={true}
              path={routes.discordConnect}
            />
            {user ? (
              <Route
                render={() => (
                  <Redirect
                    to={parser({
                      name: 'user',
                      params: { username: user.username },
                    })}
                  />
                )}
                exact={false}
                path={routes.me}
              />
            ) : (
              <Route
                render={() => (
                  <Redirect
                    to={`${routes.login}?to=${encodeURIComponent(pathname)}`}
                  />
                )}
                exact={false}
                path={routes.me}
              />
            )}
            {process.env.DAISIE_ENV !== 'production' && (
              <Route
                component={DesignSystemGuide}
                path={routes.designSystemGuide}
                exact={true}
              />
            )}
            <Route
              render={(props) => (
                <Profile
                  {...props}
                  // @ts-ignore
                  hidePageNotFound={this.state.hidePageNotFound}
                />
              )}
              exact={false}
              path={routes.user}
            />
          </Switch>
        </AppFrame>
      );
    }
    return null;
  }
}

export const App = connect(
  ['auth', 'notifications', 'cinematicBackground', 'subscription'],
  (store: GlobalStoreState) => ({
    ...notificationsActions(store),
    ...authActions(store),
    ...categoryActions(),
    ...cinematicBackgroundActions(store),
    ...subscriptionActions(store),
  })
)(withRouter(AppComponent));
