import * as React from 'react';

import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';
import imgComplex from '@/components/marketing/PressLinks/complex.svg';
import imgForbes from '@/components/marketing/PressLinks/forbes.svg';
import imgHypebeast from '@/components/marketing/PressLinks/hypebeast.svg';
import imgSkyNews from '@/components/marketing/PressLinks/sky-news.svg';
import imgVanityFair from '@/components/marketing/PressLinks/vanity-fair.svg';
import imgVogue from '@/components/marketing/PressLinks/vogue.svg';

import { c } from '@/utils/strings/c';

interface PressLink {
  name: string;
  imageUrl: string;
}

interface Props {
  className?: string;
  theme: 'light' | 'dark';
}

class PressLinks extends React.Component<Props> {
  private links: PressLink[] = [
    { name: 'Hypebeast', imageUrl: imgHypebeast },
    {
      name: 'Complex',
      imageUrl: imgComplex,
    },
    {
      name: 'Vanity Fair',
      imageUrl: imgVanityFair,
    },
    {
      name: 'Forbes',
      imageUrl: imgForbes,
    },
    {
      name: 'Sky News',
      imageUrl: imgSkyNews,
    },
    {
      name: 'Vogue',
      imageUrl: imgVogue,
    },
  ];

  public render = () => {
    const { theme, className = '' } = this.props;

    return (
      <div className={c([className])}>
        <div className="u-flex u-flex-wrap@s u-align-center u-justify-center">
          {this.links.map((link: PressLink, key: number) => (
            <ImageLoader
              // eslint-disable-next-line react/no-array-index-key
              key={key}
              src={link.imageUrl}
              alt={link.name}
              className={c('c-press-links__logo mr12@s mv8@s', {
                'mr64@m': key !== this.links.length - 1,
                'c-press-links__logo--light': theme === 'light',
                'c-press-links__logo--dark': theme === 'dark',
              })}
            />
          ))}
        </div>
      </div>
    );
  };
}

export { PressLinks };
