import * as React from 'react';

import Marquee from 'react-fast-marquee';

import { c } from '@/utils/strings/c';

interface Props {
  dark: string;
  light: string;
  className: string;
}

const TextMarquee = ({ dark, light, className }: Props) => (
  <Marquee
    gradient={false}
    speed={50}
    className={c([className, 'f-yell-1 c-text-marquee'])}
  >
    <>
      {Array.from(Array(5).keys()).map((_, key: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <h1 key={key}>
          <span className="dark mr12">{dark}</span>
          <span className="light mr12">{light}</span>
        </h1>
      ))}
    </>
  </Marquee>
);

export default TextMarquee;
