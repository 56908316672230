import * as React from 'react';

import { connect } from 'unistore/react';

import { ImageLoader } from '@/components/global/ImageLoader/ImageLoader';

import { WorkshopSectionType } from '@/enums/WorkshopSectionType';

import { c } from '@/utils/strings/c';

interface Props extends SizingState {
  sections: WorkshopSection[];
  name: string;
  showFullWidthImageCaption?: boolean;
  workshop: Workshop;
}

class WorkshopPageSectionComponent extends React.Component<Props> {
  private renderQuoteImage = (section: WorkshopSection) => {
    const {
      showFullWidthImageCaption,
      sizing: { isMobile },
    } = this.props;
    const { caption = '', image = '' } = section;

    const hasCaption = !!caption.trim();
    return (
      <>
        {hasCaption && !isMobile && (
          <h2
            className={c('f-title-1 u-bold pb20', {
              'u-text-center': showFullWidthImageCaption,
            })}
          >
            {caption}
          </h2>
        )}
        <ImageLoader
          src={image}
          className={c('', {
            'u-1/1': showFullWidthImageCaption,
          })}
          type="constrainSquareIfPortrait"
          convertToWebp={true}
        />
      </>
    );
  };

  private renderTextRows = (section: WorkshopSection) => {
    if (!section.copy) return null;
    const lastContent = section.copy.length - 1;
    const {
      sizing: { isMobile },
    } = this.props;

    const editedSection =
      isMobile && section.copy.length > 1
        ? section.copy.slice(1)
        : section.copy;

    return editedSection.map(({ heading, content }, index: number) => {
      const lines = content.split('\n').filter((line: string) => !!line);

      return (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={c('u-4/5@m', {
            'pb44@m pb24@s': index !== lastContent,
          })}
          style={{ marginRight: 0, marginLeft: 'auto' }}
        >
          <div className="u-flex-1">
            <h2 className="f-title-2 u-bold pb12">{heading}</h2>
          </div>
          <div className="u-flex-1">
            {lines.map((line: string, key: number) => (
              <p
                // eslint-disable-next-line react/no-array-index-key
                key={key}
                className={c('f-text-2 u-line-height-1/2 u-grey u-word-break', {
                  mb12: key !== lines.length - 1,
                })}
              >
                {line}
              </p>
            ))}
          </div>
        </div>
      );
    });
  };

  public render = () => {
    const { sections, name } = this.props;

    const section = sections.find((s: WorkshopSection) => s.name === name);

    if (!section) return null;

    switch (section.sectionType) {
      case WorkshopSectionType.image:
        return this.renderQuoteImage(section);
      case WorkshopSectionType.copy:
        return <div className="u-flex-1@m">{this.renderTextRows(section)}</div>;
      default:
        return null;
    }
  };
}

export const WorkshopPageSection = connect(['sizing'], () => ({}))(
  WorkshopPageSectionComponent
);
