import * as React from 'react';

import { connect } from 'unistore/react';

import { Toast } from '@/components/global/Toaster/Toast';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonSize } from '@/components/design_system/Button/ButtonSize';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';

import { GlobalModal } from '@/enums/GlobalModal';

import { FOLLOW_USER_FAIL, UNFOLLOW_USER_FAIL } from '@/messages/errors';

import { modalActions } from '@/store/modules/modal';
import { portalActions } from '@/store/modules/portal';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

import { Http } from '@/utils/api/Http';
import { track } from '@/utils/mixpanel/mixpanel';
import { c } from '@/utils/strings/c';

interface Props
  extends AuthState,
    ModalActions,
    ModalState,
    UserState,
    SizingState {
  className?: string;
  following: boolean;
  username: string;
  onChange?: (following: boolean) => void;
  uiTestId?: string;
  toggle?: () => void;
  // quick fixes
  noText?: boolean;
  isUserProfile?: boolean;
}

interface State {
  following: boolean;
  hovering: boolean;
}

class FollowComponent extends React.Component<Props, State> {
  private button: React.RefObject<HTMLButtonElement>;

  constructor(props: Props) {
    super(props);

    this.button = React.createRef();
    this.state = {
      following: props.following,
      hovering: false,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.username !== prevProps.username) {
      this.setState({
        following: this.props.following,
      });
    }
  }

  private handleClick = async () => {
    const { following } = this.state;
    const { username } = this.props;

    const action = following ? 'unfollow' : 'follow';

    try {
      await new Http(`/users/${username}/${action}`).post();
      track(following ? 'unfollow_user' : 'follow_user');
      this.setState({ following: !following });
    } catch (e) {
      new Toast({
        body: following ? FOLLOW_USER_FAIL : UNFOLLOW_USER_FAIL,
        failure: true,
      }).dispatch();
    }
    if (this.props.onChange) {
      this.props.onChange(this.state.following);
    }
  };

  public render() {
    const {
      username,
      auth,
      uiTestId,
      className,
      noText,
      isUserProfile,
      sizing: { isMobile },
    } = this.props;
    const { following, hovering } = this.state;

    if (auth.user && auth.user.username === username) return null;

    return (
      <Button
        buttonRef={this.button}
        className={className}
        buttonTextClassName={c('', {
          'pv6 pointer-events-none f-text-2': isUserProfile,
        })}
        type={ButtonType.action}
        onClick={() => {
          if (following) {
            this.props.updateModal({
              modal: GlobalModal.unfollow_user,
              data: {
                username,
                onSuccess: () => {
                  this.setState({ following: false });
                },
              },
              className: 'page-modal__content--medium',
            });
          } else {
            this.handleClick();
          }
        }}
        text={
          !following && noText
            ? ''
            : following && hovering
            ? 'Unfollow'
            : following
            ? 'Following'
            : 'Follow'
        }
        iconId={
          !following ? undefined : following && hovering ? 'clear' : 'done'
        }
        size={!isUserProfile ? ButtonSize.s : ButtonSize.sm}
        buttonStyle={
          following
            ? ButtonStyle.light
            : isMobile
            ? ButtonStyle.default
            : ButtonStyle.outlineDark
        }
        uiTestId={isUiTest() && uiTestId ? uiTestId : undefined}
        onMouseOver={() => {
          this.setState({
            hovering: true,
          });
        }}
        onMouseOut={() => {
          this.setState({
            hovering: false,
          });

          if (this.button.current) {
            this.button.current.blur();
          }
        }}
      />
    );
  }
}

export const Follow = connect(['auth', 'user', 'sizing'], () => ({
  ...modalActions(),
  ...portalActions(),
}))(FollowComponent);
