import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import { LoadingSymbol } from '@/components/global/LoadingSymbol/LoadingSymbol';

import {
  COMMUNITY_GUIDELINES_URL,
  HELP_CENTRE_URL,
  PRIVACY_POLICY_URL,
  TERMS_URL,
} from '@/utils/constants';
import { getRouteFromUrl } from '@/utils/urls/routes';

interface Props extends RouteComponentProps {}

class RedirectorComp extends React.Component<Props> {
  public componentDidMount = () => {
    const matchedRoute = getRouteFromUrl(this.props.location.pathname);

    let redirectTo: string | undefined;

    switch (matchedRoute) {
      case 'communityGuidelines':
        redirectTo = COMMUNITY_GUIDELINES_URL;
        break;
      case 'help':
      case 'helpCentre':
      case 'helpCenter':
        redirectTo = HELP_CENTRE_URL;
        break;
      case 'terms':
        redirectTo = TERMS_URL;
        break;
      case 'privacyPolicy':
        redirectTo = PRIVACY_POLICY_URL;
        break;
      default:
        redirectTo = undefined;
    }

    setTimeout(() => {
      if (redirectTo) {
        window.location.href = redirectTo;
      }
    }, 250);
  };

  public render = () => (
    <div className="u-100vh-var u-flex u-align-center u-justify-center">
      <LoadingSymbol colour="black" size="l" />
    </div>
  );
}

export const Redirector = withRouter(RedirectorComp);
