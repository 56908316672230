import * as React from 'react';

import { SkeletonNew } from '@/components/global/Skeleton/SkeletonNew';

export const CardSkeleton = () => (
  <div className="c-card">
    <div className="c-card__cover u-bg-almost-black mb16 relative" />

    <SkeletonNew
      elementClassName="f-text-1 u-bold"
      fillClassName="u-bg-almost-black"
      containerClassName="mb4"
      width="80%"
    />

    <SkeletonNew
      elementClassName="f-text-3"
      fillClassName="u-bg-almost-black"
      containerClassName=""
      width="35%"
    />
  </div>
);
