import { DaisieSubscriptionTier } from '@/enums/DaisieSubscriptionTier';
import { OnDemandMediaType } from '@/enums/OnDemandMediaType';
import { UserAccess } from '@/enums/UserAccess';
import { ViewReferer } from '@/enums/ViewReferer';

import {
  getFeaturedWorkshopsFromEditorialData,
  getWorkshopFromData,
} from '@/store/helpers';
import { getOnDemandMediasForWorkshop } from '@/store/helpers/onDemandHelpers';

import { fetchEditorialConfig } from '@/utils/api/fetchEditorialConfig';
import { Http } from '@/utils/api/Http';
import {
  EXPLORE_CONFIG_TYPE,
  MORE_WORKSHOPS_LIST_TYPE,
} from '@/utils/constants';

const workshopState: WorkshopState = {
  workshop: {
    currentWorkshop: undefined,
    featuredWorkshops: undefined,
  },
};

const workshopActions = (store: any) => ({
  getCurrentWorkshop: async (
    state: GlobalStoreState,
    id: string,
    referer?: ViewReferer,
    preferredCatchUpType?: OnDemandMediaType
  ) => {
    let currentWorkshop: Partial<Workshop> | false;

    try {
      const queryParameter = referer ? `?referer=${referer}` : '';
      const { data } = await new Http(
        `/workshops/${id}${queryParameter}`,
        '',
        2
      ).get<APIObject<APIWorkshop>>();

      if (!data.relationships) {
        throw new Error();
      }

      currentWorkshop = getWorkshopFromData(data);

      // Get on-demand media
      let onDemandMediaCatchup: OnDemandMedia | undefined = undefined;
      let onDemandMediaAll: OnDemandMedia[] = [];

      if (currentWorkshop && currentWorkshop.id) {
        try {
          const onDemandMedias = await getOnDemandMediasForWorkshop({
            workshopUuid: currentWorkshop.id,
          });

          onDemandMediaAll = onDemandMedias;

          let catchUpType: OnDemandMediaType | undefined = undefined;

          // Display Samson Cut for premium users, a trailer for non premium
          // and not logged in users
          if (preferredCatchUpType) {
            catchUpType = preferredCatchUpType;
          } else if (
            (state.auth.isAuthorised &&
              state.auth.user?.subscriptionTier ===
                DaisieSubscriptionTier.premium) ||
            currentWorkshop.userAccess === UserAccess.Free
          ) {
            catchUpType = OnDemandMediaType.WorkshopSamsonCut;
          } else {
            catchUpType = OnDemandMediaType.WorkshopTrailer;
          }

          onDemandMediaCatchup = onDemandMedias.find(
            (odm: OnDemandMedia) => odm.type === catchUpType
          );

          // If we're looking for a WorkshopSamsonCut and it doesn't exist, look for a WorkshopCatchUp
          // if that doesn't exist, default to a trailer
          if (
            catchUpType === OnDemandMediaType.WorkshopSamsonCut &&
            !onDemandMediaCatchup
          ) {
            onDemandMediaCatchup = onDemandMedias.find(
              (odm: OnDemandMedia) =>
                odm.type === OnDemandMediaType.WorkshopCatchUp
            );

            if (!onDemandMediaCatchup) {
              onDemandMediaCatchup = onDemandMedias.find(
                (odm: OnDemandMedia) =>
                  odm.type === OnDemandMediaType.WorkshopTrailer
              );
            }
          }
          if (onDemandMediaCatchup && !onDemandMediaCatchup.readyToStream) {
            onDemandMediaCatchup = undefined;
          }
        } catch (e) {
          // TODO
        }
      }

      const newState = store.getState();

      return {
        workshop: {
          ...newState.workshop,
          currentWorkshop: {
            ...currentWorkshop,
            onDemandMediaCatchup,
            onDemandMediaAll,
          },
        },
      };
    } catch (e) {
      throw e;
    }
  },

  clearWorkshop: () => ({
    workshop: {
      currentWorkshop: undefined,
    },
  }),

  registerToWorkshop: async (
    state: GlobalStoreState,
    workshop: Workshop,
    batchId: string
  ) => {
    try {
      const newState = store.getState();

      const { batches: updateBatches } = newState.workshop.currentWorkshop;

      const batchAttendingIndex = updateBatches.findIndex(
        (b: WorkshopBatch) => b.id === batchId
      );

      const batch = workshop.batches[batchAttendingIndex];

      if (batch.isFree) {
        await new Http(`/workshopBatch/attend/free`).post({ uuid: batchId });
      }

      updateBatches[batchAttendingIndex].isAttending = true;

      return {
        workshop: {
          ...newState.workshop,
          currentWorkshop: {
            ...newState.workshop.currentWorkshop,
            batches: updateBatches,
          },
        },
      };
    } catch (e) {
      throw e;
    }
  },

  setIsAttending: (state: GlobalStoreState, isAttending: boolean) => {
    const newState = store.getState();

    return {
      workshop: {
        ...newState.workshop,
        currentWorkshop: {
          ...newState.workshop.currentWorkshop,
          isAttending,
        },
      },
    };
  },

  getFeaturedWorkshops: async (state: GlobalStoreState) => {
    if (!state.auth.isAuthorised) return;

    try {
      const {
        relationships: {
          lists: { data: lists },
        },
      } = await fetchEditorialConfig(EXPLORE_CONFIG_TYPE);

      const moreWorkshopsList = lists.find(
        (list: APIRoomsList) =>
          list.attributes.type === MORE_WORKSHOPS_LIST_TYPE
      );

      if (!moreWorkshopsList) return;

      const { data: moreWorkshopsListData } = await new Http(
        `/editorialProjectLists/${moreWorkshopsList.id}`
      ).get<APIObject<APIRoomsList>>();

      const featuredWorkshops = getFeaturedWorkshopsFromEditorialData(
        moreWorkshopsListData
      );

      const newState = store.getState();

      return {
        workshop: {
          ...newState.workshop,
          featuredWorkshops,
        },
      };
    } catch (e) {
      throw e;
    }
  },
});

export { workshopState, workshopActions };
