import * as React from 'react';

import { connect } from 'unistore/react';

import { FAQItemGeneral } from '@/components/global/FAQ/FAQItemGeneral';
import { FAQItemOriginal } from '@/components/global/FAQ/FAQItemOriginal';
import { FAQItemWorkshopV2 } from '@/components/global/FAQ/FAQItemWorkshopV2';

import { SubscriptionInterval } from '@/enums/SubscriptionInterval';

import { findProductAndPrice } from '@/store/helpers/subscriptionHelpers';

import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';
import { getDisplayPrice } from '@/utils/strings/currency';

interface State {
  currentFaq?: number;
}

interface Props extends SubscriptionState, SizingState, AuthState {
  isGrandfatheringPage?: boolean;
  isWorkshopWrapperV2?: boolean;
  titleFontClass?: string;
  isOriginals?: boolean;
}

interface FaqSection {
  question: string;
  answer: React.ReactNode;
}

class FAQWrapper extends React.Component<Props, State> {
  public state: State = {
    currentFaq: undefined,
  };

  // eslint-disable-next-line react/sort-comp
  private answerWithCurrency = (yearlyOrMonthly: string) => {
    const {
      subscription: { productsAndPrices, userCurrency },
    } = this.props;

    const yearlyPrice = findProductAndPrice({
      productsAndPrices,
      currency: userCurrency,
      recurringInterval: SubscriptionInterval.year,
    });

    const monthlyPrice = findProductAndPrice({
      productsAndPrices,
      currency: userCurrency,
      recurringInterval: SubscriptionInterval.month,
    });

    if (yearlyOrMonthly === 'yearly') {
      return `${
        yearlyPrice &&
        t(`${getDisplayPrice(yearlyPrice.currency, yearlyPrice.amount)}`)
      }`;
    } else if (yearlyOrMonthly === 'monthly') {
      return `${
        monthlyPrice &&
        t(`${getDisplayPrice(monthlyPrice.currency, monthlyPrice.amount)}`)
      }`;
    } else {
      return null;
    }
  };

  private renderFaq = (sections: FaqSection[], currentItemStateKey: string) => {
    const { isWorkshopWrapperV2, isOriginals } = this.props;

    const currentFaq = this.state[currentItemStateKey];

    return sections.map((s: FaqSection, index: number) => {
      const showItem =
        typeof currentFaq !== 'undefined' && currentFaq === index;

      if (isWorkshopWrapperV2) {
        return (
          <FAQItemWorkshopV2
            index={index}
            section={s}
            isExpanded={showItem}
            key={index}
            onItemClick={() => {
              this.setState({
                currentFaq: index !== currentFaq ? index : undefined,
              });
            }}
          />
        );
      } else if (isOriginals) {
        return (
          <FAQItemOriginal
            index={index}
            section={s}
            isExpanded={showItem}
            key={index}
            onItemClick={() => {
              this.setState({
                currentFaq: index !== currentFaq ? index : undefined,
              });
            }}
          />
        );
      } else {
        return (
          <FAQItemGeneral
            index={index}
            section={s}
            isExpanded={showItem}
            key={index}
            onItemClick={() => {
              this.setState({
                currentFaq: index !== currentFaq ? index : undefined,
              });
            }}
          />
        );
      }
    });
  };

  public render = () => {
    const {
      auth: { user },
      isWorkshopWrapperV2,
      titleFontClass,
      isOriginals,
      sizing: { isMobile, isDesktop },
    } = this.props;

    const FAQ_ABOUT_DAISIE: FaqSection[] = [
      {
        question: 'What is Daisie?',
        answer: (
          <p>
            {t(
              `Daisie is where creators of all backgrounds come to learn, network and kickstart their careers. Join to access unlimited live workshops taught daily by industry-leading creators across film, fashion, art, photography, design, music, literature and so much more.`
            )}
          </p>
        ),
      },
      {
        question: 'Who is Daisie for?',
        answer: (
          <p>
            {t(
              `We’re building Daisie for creators around the world, regardless of location, background, life stage or experience. To join, download the Daisie iOS app via the App Store or create an account on the web.`
            )}
          </p>
        ),
      },
      {
        question: 'What’s included in a Daisie subscription?',
        answer: (
          <>
            <p className="pb12">
              {t(
                'Unlimited access to live, interactive video workshops and intimate audio talks hosted by our community of industry-leading creators. This is so much more than an online class.'
              )}
            </p>
            <p className="pb12">
              {t(
                'In Daisie Workshops, you’ll join peer groups of aspiring creators from around the world. Each workshop provides an opportunity to learn from experts, develop your skills, get answers to your most pressing questions and solve real life challenges.'
              )}
            </p>
            <p>
              {t(
                'In Daisie Rooms, you’ll build new relationships and meet creators in intimate, audio spaces. Connect, exchange support, encouragement and inspiration with the community. The creative journey is best shared.'
              )}
            </p>
          </>
        ),
      },
      {
        question: 'How can I join workshops and rooms?',
        answer: (
          <p>
            {t(
              `You can create a Daisie account via our iOS app or on the web. You'll see all live and upcoming events on your home feed. We stream workshops live on Zoom!`
            )}
          </p>
        ),
      },
      {
        question: 'How do I meet creators on Daisie?',
        answer: (
          <>
            <p className="pb12">
              {t(
                'With each workshop and room you join, you’ll meet like-minded creators with shared interests, at similar stages in their careers. This is a fantastic opportunity to discover new people & grow your network.'
              )}
            </p>
            <p>
              {t(
                "As you spend more time on Daisie, you'll get to know some of our 700+ industry-leading instructors. They’re here to host interactive, engaging (and fun!) sessions. They’re awesome people. Reach out and introduce yourself!"
              )}
            </p>
          </>
        ),
      },
      {
        question: 'How much does Daisie cost?',
        answer: (
          <p>
            {t(
              `The annual subscription is ${this.answerWithCurrency(
                'yearly'
              )} and provides unlimited access to live, daily workshops. You can also join via the monthly pass - available for 
            ${this.answerWithCurrency(
              'monthly'
            )}/mo. Both plans include a 7 day free trial.`
            )}
          </p>
        ),
      },
      {
        question: 'How do I cancel?',
        answer: (
          <p>
            {t(
              `If you want to cancel your membership, follow the prompts to cancel via the Settings on your profile. Any questions? Please reach out. We’re here to help!`
            )}
          </p>
        ),
      },
    ];

    const FAQ_GRANDFATHERING: FaqSection[] = [
      {
        question: 'Why is Daisie introducing a subscription?',
        answer: (
          <p>
            {t(
              `In order to continue building our platform, we've introduced a small monthly fee. This way we can pay for our community to host new workshops, rooms, and eventually on demand lessons. By taking this step, it enabled us to create a next-gen art school of real, lasting value for our creators.`
            )}
          </p>
        ),
      },
      {
        question: `What's new?`,
        answer: (
          <p>
            {t(
              `We've just launched the long anticipated calendar feature in the app and on daisie.com! This will allow you to browse upcoming classes and plan your schedule more easily.`
            )}
          </p>
        ),
      },
      {
        question: 'Do existing users get a discounted price?',
        answer: (
          <p>
            {t(
              `Yes, as a thank you to all of our existing users we're offering a discounted price on the annual membership.`
            )}
          </p>
        ),
      },
      {
        question: 'Can I cancel my subscription?',
        answer: (
          <p>
            {t(
              'Yes, you can cancel your subscription at any time. Please note that if you choose an annual subscription, the annual fee will be billed to your account once your free trial is over and is not reimbursable.'
            )}
          </p>
        ),
      },
      {
        question: 'What features are only available for subscribed users?',
        answer: (
          <>
            <p className="pb12">
              {t(
                `Live, interactive workshops and audio rooms are available exclusively to subscribed users.`
              )}
            </p>
            <p className="pb12">
              {t(
                `In Daisie Workshops, you’ll join peer groups of aspiring creators from around the world. Each workshop provides an opportunity to learn from experts, develop your skills, get answers to your most pressing questions and solve real life challenges.`
              )}
            </p>
            <p>
              {t(
                `In Daisie Rooms, you’ll build new relationships and meet creators in intimate audio spaces. Connect, exchange support, encouragement and inspiration with the community. The creative journey is best shared.`
              )}
            </p>
          </>
        ),
      },
    ];

    return (
      <div
        className={c('u-1/1 u-5/8@m mhauto pv64 pb100@m', {
          'wrap--sm': isDesktop,
          ph20: isOriginals && isMobile,
          pb100: isOriginals && !isMobile,
        })}
      >
        <h2
          className={c('u-text-center u-bold', {
            mb44: !isOriginals || (isOriginals && !isDesktop),
            'u-very-light-grey': isOriginals,
            mb100: isOriginals && isDesktop,
          })}
        >
          {isWorkshopWrapperV2 ? (
            <span className={titleFontClass}>
              <span className="u-block">{t('Frequent')}</span>
              <span className="u-block">{t('questions')}</span>
            </span>
          ) : isOriginals ? (
            <span className={titleFontClass}>{t('Questions?')}</span>
          ) : (
            <span className="f-title-2 f-yell-1">{t('FAQ')}</span>
          )}
        </h2>
        {user && user.isGrandfathered
          ? this.renderFaq(FAQ_GRANDFATHERING, 'currentFaq')
          : this.renderFaq(FAQ_ABOUT_DAISIE, 'currentFaq')}
      </div>
    );
  };
}

export const FAQ = connect(['subscription', 'sizing', 'auth'])(FAQWrapper);
