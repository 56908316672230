import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'unistore/react';

import { Icon } from '@/components/global/Icon/Icon';
import { LoadingSymbol } from '@/components/global/LoadingSymbol/LoadingSymbol';

import { PageNotFound } from '@/pages/PageNotFound/PageNotFound';

import { routes } from '@/routes';

import { authActions } from '@/store/modules/auth';

import { DISCORD_DAISIE } from '@/utils/constants';
import { connectUser, redirectToDiscordOAuth } from '@/utils/discord/discord';
import { t } from '@/utils/i18n/i18n';
import { c } from '@/utils/strings/c';
import { getQueryParam } from '@/utils/urls/getQueryParam';

interface Props extends UserState, AuthActions, AuthState {}

const DiscordAuthComponent = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const code = getQueryParam('code', props);

  useEffect(() => {
    const { user } = props.auth;
    if (user && user.discordUserId) {
      window.location.href = routes.connectedApps;
    } else if (code) {
      connect(code);
    } else {
      redirectToDiscordOAuth();
    }
  }, []);

  const connect = async (code: string) => {
    setIsLoading(true);
    try {
      const response = await connectUser(code);

      if (response) {
        setIsConnected(true);
      }
      // get latest user object for discordUserId prop
      await props.getCurrentUser(true);
    } catch (e) {
      throw e;
    }
    setIsLoading(false);
  };

  if (isLoading)
    return (
      <div className="u-h-75vh u-flex u-align-center u-justify-center">
        <LoadingSymbol colour="white" size="l" />
      </div>
    );

  return (
    <div className="wrap page-content--centered u-very-light-grey">
      {isConnected && (
        <div
          className={c('u-text-center mb32@m', {
            'u-animate--fadeIn': isConnected,
          })}
        >
          <div className="u-flex u-align-center u-justify-center">
            <a
              className="ph20"
              href={DISCORD_DAISIE}
              style={{ color: '#7a82f5' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon id="discord" width="95" />
            </a>
            <Icon id="add" width="20" className="mh12@m" />
            <Link to={routes.home} className="ph20">
              <Icon id="daisie" width="95" />
            </Link>
          </div>
          <h4 className="f-title-3 pt24@m pt12@s">
            {t('Connected your Daisie account to Discord')}
          </h4>
          <p className="pt6 u-grey">
            {t('You can close this window and go back to Discord.')}
          </p>
        </div>
      )}
    </div>
  );
};

export const DiscordAuth = connect(
  ['auth', 'user'],
  (store: GlobalStoreState) => ({
    ...authActions(store),
  })
)(DiscordAuthComponent);
