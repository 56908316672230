import { GtmEvent } from '@/enums/GtmEvent';

import { sendGtmEvent } from '@/utils/gtm/sendGtmEvent';
import { track } from '@/utils/mixpanel/mixpanel';

export const sendRegisterToWorkshopTrackingEvents = ({
  workshop,
}: // price,
{
  workshop: Workshop;
  // price?: number;
}) => {
  track('user_registered_for_workshop', {
    workshopId: workshop.id,
    mentor: workshop.mentor.username,
  });

  track('tapped_confirm_workshop_booking', {
    workshopId: workshop.id,
    type: workshop.isFree ? 'free' : 'paid',
  });

  sendGtmEvent(GtmEvent.RegisterWorkshop, {
    workshopName: workshop.title,
    // workshopPrice: price ? price : 0.0,
  });
};
