import * as React from 'react';

import Cropper from 'react-cropper';
import { connect } from 'unistore/react';

import { CloseButton } from '@/components/global/Button/CloseButton';
import { Icon } from '@/components/global/Icon/Icon';
import { Scrubber } from '@/components/global/Scrubber/Scrubber';

import { Button } from '@/components/design_system/Button/Button';
import { ButtonStyle } from '@/components/design_system/Button/ButtonStyle';
import { ButtonType } from '@/components/design_system/Button/ButtonType';
import { ButtonGroup } from '@/components/design_system/ButtonGroup/ButtonGroup';

import { DaisieCustomEvent } from '@/enums/DaisieCustomEvent';

import { modalActions } from '@/store/modules/modal';

import 'cropperjs/dist/cropper.css';

import { t } from '@/utils/i18n/i18n';
import { clamp } from '@/utils/integers/clamp';
import { mapRange } from '@/utils/strings/number-manipulation';

interface Props extends ModalState, ModalActions, SizingState {
  image: string;
  fileName: string;
  fileMimeType: string;
  hideCloseButton?: boolean;
  onCancel?: () => void;
}

interface State {
  zoom: number;
  isUploading: boolean;
}

class ProfilePictureCropComp extends React.Component<Props, State> {
  private MAX_ZOOM: number = 1.5;
  private DEFAULT_MOBILE_SIZE: number = 200;
  private DEFAULT_DESKTOP_SIZE: number = 400;

  private cropper: React.RefObject<any>;
  private startingZoomRatio: number = 0;

  public state = {
    zoom: 0.0,
    isUploading: false,
  };

  constructor(props: Props) {
    super(props);
    this.cropper = React.createRef();
  }

  public checkImageData = () => {
    if (!this.cropper.current) return;

    const { height, naturalHeight } = this.cropper.current.getImageData();

    this.startingZoomRatio = height / naturalHeight;
  };

  private submit = () => {
    const { isUploading } = this.state;
    const { fileMimeType, fileName } = this.props;

    if (isUploading || !this.cropper.current) return;

    this.setState({ isUploading: true });

    const pictureData = this.cropper.current
      .getCroppedCanvas()
      .toDataURL(fileMimeType);

    window.dispatchEvent(
      new CustomEvent(DaisieCustomEvent.profile_picture_crop_done, {
        detail: {
          pictureData,
          fileName,
        },
      })
    );
  };

  private handleScrubberChange = (zoom: number) => {
    if (!this.cropper.current) return;

    const scalar = mapRange(
      zoom,
      0.0,
      1.0,
      0 + this.startingZoomRatio,
      this.MAX_ZOOM + this.startingZoomRatio
    );

    this.setState({ zoom });

    this.cropper.current.zoomTo(scalar);
  };

  private handleInputZoom = (e: any) => {
    if (!this.cropper.current) return;

    const zoom = clamp(
      mapRange(
        e.detail.ratio,
        0 + this.startingZoomRatio,
        this.MAX_ZOOM + this.startingZoomRatio,
        0.0,
        1.0
      ),
      0.0,
      1.0
    );

    if (zoom === 1.0 || zoom === 0.0) {
      e.preventDefault();
    }

    this.setState({ zoom });
  };

  public render = () => {
    const {
      image,
      hideCloseButton,
      onCancel,
      sizing: { isMobile },
    } = this.props;

    const { zoom, isUploading } = this.state;

    const size = isMobile
      ? this.DEFAULT_MOBILE_SIZE
      : this.DEFAULT_DESKTOP_SIZE;

    const showCloseButton = !isMobile && !hideCloseButton;

    return (
      <>
        {showCloseButton && (
          <CloseButton
            className="u-flex mlauto-i ml16 mb16 mr0"
            onClick={() => {
              this.props.updateModal({ modal: undefined });
            }}
          />
        )}

        <div
          className="profile-picture-crop"
          style={{ height: size, width: size }}
        >
          <Cropper
            // @ts-ignore-line
            ref={this.cropper}
            src={image}
            style={{ height: size, width: size }}
            guides={false}
            dragMode="move"
            cropBoxMovable={false}
            cropBoxResizable={false}
            minCropBoxWidth={size}
            minCropBoxHeight={size}
            toggleDragModeOnDblclick={false}
            viewMode={3}
            zoom={this.handleInputZoom}
            ready={this.checkImageData}
          />
          <div
            className="profile-picture-crop__mask profile-picture-crop__mask--circle"
            style={{ height: size, width: size }}
          />
        </div>

        <div className="u-flex u-align-center">
          <Icon id="image-size" className="profile-picture-crop__icon--small" />
          <Scrubber
            value={zoom}
            onChange={this.handleScrubberChange}
            className="u-flex-1 mh16 mv32"
          />
          <Icon id="image-size" className="profile-picture-crop__icon--big" />
        </div>

        <ButtonGroup className="u-flex u-justify-center">
          <Button
            type={ButtonType.action}
            onClick={this.submit}
            isLoading={isUploading}
            text={t('Save')}
          />

          {onCancel && (
            <Button
              type={ButtonType.action}
              onClick={onCancel}
              disabled={isUploading}
              text={t('Cancel')}
              buttonStyle={ButtonStyle.light}
            />
          )}
        </ButtonGroup>
      </>
    );
  };
}

export const ProfilePictureCrop = connect(['sizing'], () => ({
  ...modalActions(),
}))(ProfilePictureCropComp);
