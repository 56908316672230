import * as React from 'react';

import { PaginatorPosition } from '@/enums/PaginatorPosition';

interface Props {
  onEndReached?(end: PaginatorPosition): void;
  children: React.ReactNode;
}

interface State {
  processing: boolean;
}

class Paginator extends React.Component<Props, State> {
  private startEl: React.RefObject<HTMLSpanElement>;
  private endEl: React.RefObject<HTMLSpanElement>;
  private observer?: IntersectionObserver;

  constructor(props: Props) {
    super(props);

    this.startEl = React.createRef();
    this.endEl = React.createRef();
    this.observer = undefined;

    this.state = {
      processing: false,
    };
  }

  public componentDidMount() {
    if (
      typeof IntersectionObserver !== 'undefined' &&
      this.props.onEndReached
    ) {
      this.observer = new IntersectionObserver(this.intersecting, {
        threshold: 1.0,
      });
      if (this.startEl.current && this.endEl.current) {
        this.observer.observe(this.startEl.current);
        this.observer.observe(this.endEl.current);
      }
    }
  }

  public componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private intersecting = async (entries: IntersectionObserverEntry[]) => {
    const { onEndReached } = this.props;
    const { processing } = this.state;

    entries.forEach(async (entry) => {
      if (entry.isIntersecting && onEndReached && !processing) {
        await this.setState({ processing: true });
        if (entry.target === this.startEl.current) {
          await onEndReached(PaginatorPosition.start);
        }
        if (entry.target === this.endEl.current) {
          await onEndReached(PaginatorPosition.end);
        }
        await this.setState({ processing: false });
      }
    });
  };

  public render() {
    const { children, onEndReached } = this.props;

    if (!onEndReached) {
      return children;
    }

    return (
      <>
        <span ref={this.startEl} />
        {children}
        <span ref={this.endEl} />
      </>
    );
  }
}

export { Paginator };
