const mutedUsersState: MutedUsersState = {
  mutedUsers: {
    mutedUsersNew: [],
  },
};

const mutedUsersActions = (store: any) => ({
  setMutedStateNew: (
    state: GlobalStoreState,
    userId: string,
    isMuted: boolean
  ) => {
    let mutedUsers = store.getState().mutedUsers.mutedUsersNew;

    if (isMuted) {
      // Add to muted users
      mutedUsers = [
        ...mutedUsers.filter((uid: string) => uid !== userId),
        userId,
      ];
    } else {
      // Remove from muted users
      mutedUsers = mutedUsers.filter((uid: string) => uid !== userId);
    }

    return {
      mutedUsers: {
        ...store.getState().mutedUsers,
        mutedUsersNew: mutedUsers,
      },
    };
  },

  resetMutedState: () => ({
    mutedUsers: {
      mutedUsersNew: [],
    },
  }),
});

export { mutedUsersActions, mutedUsersState };
