import * as React from 'react';

import { Link } from '@/components/global/Link/Link';
import { BaseNotification } from '@/components/notifications/BaseNotification';

import { t } from '@/utils/i18n/i18n';

interface Props {
  className?: string;
  notification: DaisieNotification;
  parentProject?: Project;
  onClickToggle: () => void;
  isDarkMode: boolean;
}

const CollaboratorAccepted: React.FunctionComponent<Props> = (props: Props) => {
  const {
    className = '',
    notification,
    parentProject,
    isDarkMode,
    onClickToggle,
  } = props;

  const project = notification.project || parentProject;

  if (!project) {
    return null;
  }

  const primary = (
    <>
      {t('accepted ')}

      {notification.actor && notification.user && (
        <>
          <Link
            to={{
              name: 'user',
              params: { username: notification.actor.username },
            }}
          >
            {notification.actor.primaryName}
          </Link>
          &apos;s request to collaborate
        </>
      )}

      {!notification.actor && notification.user && t(' your request to join ')}

      {!parentProject && (
        <Link
          to={{
            name: 'room',
            params: { roomSlug: project.slug },
          }}
        >
          {project.title}
        </Link>
      )}
    </>
  );

  return (
    <BaseNotification
      notification={notification}
      className={className}
      primary={primary}
      isDarkMode={isDarkMode}
      onClickToggle={onClickToggle}
      secondary={({ date }: { date: string }) => date}
      icon="tick"
    />
  );
};

export { CollaboratorAccepted };
