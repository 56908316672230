import * as React from 'react';

import { Stream, StreamPlayerApi } from '@cloudflare/stream-react';
import Bowser from 'bowser';
import Hls from 'hls.js';

import { Icon } from '@/components/global/Icon/Icon';
import { LoadingSymbol } from '@/components/global/LoadingSymbol/LoadingSymbol';
import { MobileOS } from '@/components/global/PreMobileWall/MobileOS';

import { IconSize } from '@/enums/IconSize';

import { c } from '@/utils/strings/c';

interface Props {
  className?: string;
  onDemandMedia: OnDemandMedia;
  onClose?: () => void;
  onCurrentTimeChanged?: (
    currentTime: number,
    onDemandMediaId: string,
    shouldUpdateOverallProgress: boolean
  ) => void;
  onPlay?: () => void;
  onPause?: () => void;
  isVisible: boolean;
  useMobilePlayer?: boolean;
  hideCloseButton?: boolean;
  startTime?: number;
  setVideoProgress?: (
    workshopId: string,
    onDemandMediaId: string,
    currentTime: number
  ) => void;
  isPlaying?: boolean;
}

interface State {
  // RUNTIME
  mobileOs?: MobileOS;
  isChangingMedia?: boolean;

  // ANIMATION + RENDERING
  isRendered: boolean;
  isAnimatedIn: boolean;
  isAnimatedOut: boolean;
  hasVideoStarted: boolean;
}

class OnDemandPlayer extends React.Component<Props, State> {
  // REFS
  private refNativeVideoIos: React.RefObject<HTMLVideoElement>;
  private refNativeVideoAndroid: React.RefObject<HTMLVideoElement>;
  private refStream: any;

  // RUNTIME
  private hls: Hls | undefined;
  private changeVideoTimeout: any;
  private updateProgressInterval: any;
  private previousProgress: number | undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      // RUNTIME
      mobileOs: undefined,

      // ANIMATION + RENDERING
      isRendered: false,
      isAnimatedIn: false,
      isAnimatedOut: false,
      isChangingMedia: false,
      hasVideoStarted: false,
    };

    this.refNativeVideoIos = React.createRef();
    this.refNativeVideoAndroid = React.createRef();
    this.refStream = React.createRef();
    this.previousProgress = props.startTime;
  }

  public componentDidMount = async () => {
    const { isVisible } = this.props;

    await this.setMobileOs();

    if (isVisible) {
      this.setState({ isRendered: true, isAnimatedIn: true });
    }

    // Send an update every x seconds to keep track of video progress
    this.updateProgressInterval = this.initializeProgressUpdate();
  };

  public componentDidUpdate = (prevProps: Props) => {
    const {
      isVisible: prevIsVisible,
      onDemandMedia: prevOnDemandMedia,
      isPlaying: prevIsPlaying,
    } = prevProps;
    const { isVisible, useMobilePlayer, onDemandMedia, startTime, isPlaying } =
      this.props;

    if (prevOnDemandMedia.id !== onDemandMedia.id) {
      this.setState({ isChangingMedia: true, hasVideoStarted: false });

      this.changeVideoTimeout = setTimeout(
        () => this.setState({ isChangingMedia: false }),
        10
      );

      this.previousProgress = startTime;

      clearInterval(this.updateProgressInterval);
      this.updateProgressInterval = this.initializeProgressUpdate();
    }

    if (!prevIsVisible && isVisible) {
      this.setState({ isRendered: true }, () => {
        setTimeout(() => {
          this.setState({ isAnimatedIn: true }, async () => {
            if (useMobilePlayer) {
              await this.setMobileOs();
              this.addMobileVideoListeners();
            }
          });
        }, 250);
      });
    }

    if (prevIsPlaying !== isPlaying && !isPlaying) {
      this.refStream?.current?.pause();
    }
  };

  public componentWillUnmount = () => {
    this.removeMobileVideoListeners();
    clearTimeout(this.changeVideoTimeout);
    clearInterval(this.updateProgressInterval);
  };

  private initializeProgressUpdate = () => {
    const { onCurrentTimeChanged, onDemandMedia, startTime } = this.props;

    if (!onCurrentTimeChanged) return;

    const playerRef =
      this.refStream || this.refNativeVideoAndroid || this.refNativeVideoIos;

    return setInterval(() => {
      if (!playerRef) return;
      let shouldUpdateOverallProgress =
        playerRef.current.currentTime > (startTime || 0);

      const currentProgress = playerRef.current.currentTime;

      if (!this.previousProgress && shouldUpdateOverallProgress) {
        this.previousProgress = 0;
      }

      if (currentProgress <= (this.previousProgress || 0)) {
        shouldUpdateOverallProgress = false;
      }

      this.previousProgress = currentProgress;

      onCurrentTimeChanged(
        this.refStream.current.currentTime,
        onDemandMedia.id,
        shouldUpdateOverallProgress
      );
    }, 1000);
  };

  private setMobileOs = (): Promise<void> =>
    new Promise((resolve) => {
      const { useMobilePlayer } = this.props;

      if (!useMobilePlayer) {
        return resolve();
      }

      const browser = Bowser.getParser(window.navigator.userAgent);

      this.setState(
        {
          mobileOs:
            browser.getOS().name === 'Android'
              ? MobileOS.android
              : MobileOS.ios,
        },
        () => {
          resolve();
        }
      );
    });

  private addMobileVideoListeners = async () => {
    const { useMobilePlayer } = this.props;

    if (!useMobilePlayer) {
      return;
    }

    if (!this.state.mobileOs) {
      await this.setMobileOs();
    }

    const { mobileOs } = this.state;

    if (mobileOs === MobileOS.ios) {
      this.addMobileVideoListenersIos();
    } else if (mobileOs === MobileOS.android) {
      this.addMobileVideoListenersAndroid();
    }
  };

  private removeMobileVideoListeners = async () => {
    const { useMobilePlayer } = this.props;

    if (!useMobilePlayer) {
      return;
    }

    if (!this.state.mobileOs) {
      await this.setMobileOs();
    }

    const { mobileOs } = this.state;

    if (mobileOs === MobileOS.ios) {
      this.removeMobileVideoListenersIos();
    } else if (mobileOs === MobileOS.android) {
      this.removeMobileVideoListenersAndroid();
    }
  };

  private addMobileVideoListenersIos = () => {
    if (!this.refNativeVideoIos.current) {
      return;
    }

    this.refNativeVideoIos.current.addEventListener(
      'webkitendfullscreen',
      this.handleMobileFullScreenExit
    );
  };

  private addMobileVideoListenersAndroid = () => {
    if (!this.refNativeVideoAndroid.current) {
      return;
    }

    const {
      onDemandMedia: { streamUrl },
    } = this.props;

    if (Hls.isSupported()) {
      const video = this.refNativeVideoAndroid.current;

      this.hls = new Hls();
      this.hls.attachMedia(video);

      this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        if (this.hls && streamUrl) {
          this.hls.loadSource(streamUrl);

          if (this.refNativeVideoAndroid.current) {
            this.refNativeVideoAndroid.current.requestFullscreen();

            document.addEventListener(
              'fullscreenchange',
              this.handleMobileFullScreenExit
            );
          }
        }
      });
    } else {
      // TODO: show error
    }
  };

  private removeMobileVideoListenersIos = () => {
    if (!this.refNativeVideoIos.current) {
      return;
    }

    this.refNativeVideoIos.current.removeEventListener(
      'webkitendfullscreen',
      this.handleMobileFullScreenExit
    );
  };

  private removeMobileVideoListenersAndroid = () => {
    if (this.hls) {
      this.hls.destroy();
    }

    document.removeEventListener(
      'fullscreenchange',
      this.handleMobileFullScreenExit
    );
  };

  private handleMobileFullScreenExit = async () => {
    if (!this.state.mobileOs) {
      await this.setMobileOs();
    }

    const { mobileOs } = this.state;

    if (mobileOs === MobileOS.ios) {
      this.closePlayer();
    }

    if (mobileOs === MobileOS.android) {
      // document.fullscreenElement is defined when full screen is active,
      // and undefined when it isn't
      const isFullScreen = !!document.fullscreenElement;

      if (!isFullScreen) {
        this.closePlayer();
      }
    }
  };

  private closePlayer = () => {
    this.setState({ isAnimatedOut: true }, () => {
      setTimeout(() => {
        this.setState(
          {
            isRendered: false,
            isAnimatedIn: false,
            isAnimatedOut: false,
          },
          () => {
            if (this.props.onClose) {
              this.props.onClose();
            }
          }
        );
      }, 250);
    });
  };

  private renderCloseButton = () => {
    const { hideCloseButton } = this.props;

    if (hideCloseButton) {
      return null;
    }

    return (
      <button
        type="button"
        className="on-demand-player__close u-white"
        onClick={() => this.closePlayer()}
      >
        <Icon id="clear" size={IconSize.xs} />
      </button>
    );
  };

  private handlePlay = (e: any) => {
    const { onPlay } = this.props;

    if (this.refStream && this.props.startTime && !this.state.hasVideoStarted) {
      this.refStream.current.currentTime = this.props.startTime;
      this.setState({ hasVideoStarted: true });
    }

    if (!onPlay) return;
    onPlay();
  };

  private handlePause = (e: any) => {
    const { onPause } = this.props;

    if (!onPause) return;
    onPause();
  };

  public render = () => {
    const {
      className = '',
      onDemandMedia,
      useMobilePlayer = false,
      startTime,
    } = this.props;

    const {
      isRendered,
      isAnimatedIn,
      isAnimatedOut,
      mobileOs,
      isChangingMedia,
    } = this.state;

    if (!isRendered) {
      return null;
    }

    if (useMobilePlayer) {
      return (
        <>
          {mobileOs === MobileOS.ios && (
            <video
              ref={this.refNativeVideoIos}
              className="u-visibility-hidden"
              controls
              autoPlay
            >
              <source src={onDemandMedia.streamUrl} type="video/mp4" />
            </video>
          )}

          {mobileOs === MobileOS.android && (
            <video
              ref={this.refNativeVideoAndroid}
              // className="u-visibility-hidden"
              controls
              autoPlay
            />
          )}

          <LoadingSymbol
            colour="white"
            size="l"
            className="absolute absolute--mid-center"
          />
        </>
      );
    }

    return (
      <div
        className={c(['on-demand-player animate-opacity', className], {
          'opacity-0 pointer-events-none': !isAnimatedIn || isAnimatedOut,
          'opacity-1': isAnimatedIn && !isAnimatedOut,
        })}
      >
        <div className="on-demand-player__stream_container">
          {!isChangingMedia && (
            <Stream
              streamRef={this.refStream}
              controls
              autoplay={true}
              src={onDemandMedia.streamUrl}
              onPlay={this.handlePlay}
              onPause={this.handlePause}
              // onSeeked={(info: any) => {
              //   if (!!this.props.setVideoProgress && !!onDemandMedia.workshop) {
              //     this.props.setVideoProgress(
              //       onDemandMedia.workshop.id,
              //       onDemandMedia.id,
              //       info.timeStamp
              //     );
              //   }
              //   // if (!!this.props.onCurrentTimeChanged) {
              //   //   this.props.onCurrentTimeChanged(
              //   //     info.timeStamp,
              //   //     onDemandMedia.id,
              //   //     true
              //   //   );
              //   // }
              // }}
              onSeeking={(info: any) => {
                if (!!this.props.setVideoProgress && !!onDemandMedia.workshop) {
                  this.props.setVideoProgress(
                    onDemandMedia.workshop.id,
                    onDemandMedia.id,
                    info.timeStamp
                  );
                }
                // if (!!this.props.onCurrentTimeChanged) {
                //   this.props.onCurrentTimeChanged(
                //     info.timeStamp,
                //     onDemandMedia.id,
                //     true
                //   );
                // }
              }}
              onTimeUpdate={(time: any) => {
                // if (!!this.props.onCurrentTimeChanged) {
                //   this.props.onCurrentTimeChanged(
                //     timeStamp,
                //     onDemandMedia.id,
                //     true
                //   );
                // }
              }}
              preload="auto"
            />
          )}
        </div>

        {this.renderCloseButton()}
      </div>
    );
  };
}

export { OnDemandPlayer };
