import * as React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { parser } from '@/routes/parser';

import { isUiTest } from '@/uiTests/helpers/componentHelpers';

interface Props extends LinkProps {
  disabled?: boolean;
  title?: string;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  uiTestId?: string;
}

const Link: React.FunctionComponent<Props> = (props: Props) => {
  const { to, className, disabled, title, uiTestId, ...rest } = props;

  if (disabled) {
    return (
      <span className="dds-button--disabled">{props.children || null}</span>
    );
  }

  const path = typeof to === 'string' ? to : parser(to);

  // Code stolen directly from what react-router does to parse its Location state objects
  let pathname = path || '/';
  let search = '';
  let hash = '';

  const hashIndex = pathname.indexOf('#');

  if (hashIndex !== -1) {
    hash = pathname.substr(hashIndex);
    pathname = pathname.substr(0, hashIndex);
  }

  const searchIndex = pathname.indexOf('?');

  if (searchIndex !== -1) {
    search = pathname.substr(searchIndex);
    pathname = pathname.substr(0, searchIndex);
  }

  const newTo = {
    state: {
      prevPathname: window.location.pathname,
      prevHash: window.location.hash,
      prevSearch: window.location.search,
    },
    pathname: pathname,
    search: search === '?' ? '' : search,
    hash: hash === '#' ? '' : hash,
  };

  return (
    <RouterLink
      {...rest}
      className={className}
      to={newTo}
      title={title}
      data-ui-test-id={isUiTest() && uiTestId ? uiTestId : undefined}
    >
      {props.children || null}
    </RouterLink>
  );
};

export { Link };
