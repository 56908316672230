import React from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import { useInView } from 'react-intersection-observer';

import 'animate.css';

interface Props extends BoxProps {
  name: string;
  repeat: number | 'infinite';
  duration: string;
  children: React.ReactElement;
}

export const AnimateInView = ({
  name = 'fadeIn',
  repeat = 1,
  duration = 'fast',
  children,
  ...props
}: Partial<Props>) => {
  const { ref, inView } = useInView();

  return (
    <Box
      ref={ref}
      {...(inView && {
        className: `animate__animated animate__${duration} animate__${name} ${
          repeat === 'infinite'
            ? ' animate__infinite'
            : `animate__repeat-${repeat}`
        }`,
      })}
      {...props}
    >
      {children}
    </Box>
  );
};
