export const getFontClass = (font: string) => {
  switch (font) {
    case 'synthemesc':
      return 'f-synthemesc-regular-1';
    case 'pp-hatton':
      return 'f-pp-hatton-bold-1';
    case 'agrandir':
      return 'f-agrandir-wide-black-italic-1';
    case 'monument-extended':
      return 'f-monument-extended-ultrabold-1';
    case 'obviously-extended':
      return 'f-obviously-extended-semibold-1a';
    default:
      return 'f-workshop-1';
  }
};

export const getColorName = (hexColor: string) => {
  switch (hexColor) {
    case '#8543e3':
      return 'purple';
    case '#ecdaa4':
      return 'yellow';
    case '#a7c796':
      return 'green';
    case '#dc8cae':
      return 'pink';
    case '#a7aaf8':
      return 'light-blue';
    case '#545adf':
      return 'blue';
    default:
      return 'purple';
  }
};

export const getWorkshopIdFromSlug = (workshopSlug: string) => {
  return `workshop_${workshopSlug.split('&')[0].split('-').pop()}`;
};
