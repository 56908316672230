import delve from 'dlv';

import { ROOM_FALLBACK_IMAGE_LABEL } from '@/components/rooms/constants';

import { ContrastMode } from '@/enums/ContrastMode';
import { ProjectVisibility } from '@/enums/ProjectVisibility';

import {
  getCategoriesFromData,
  getCollaborationRequestsFromData,
  getLocationFromData,
  getUserFromData,
  getUsersFromData,
  getWorkshopBatchFromData,
} from '@/store/helpers';
import { getCuratedImageFromData } from '@/store/helpers/cinematicBackgroundHelpers';

import {
  LOOKING_FOR_COLLABORATORS_CATEGORY_NAME,
  SHOW_VIEWS_COUNT_AFTER_TIME,
  SHOW_VIEWS_COUNT_AFTER_VIEWS,
} from '@/utils/constants';
import { t } from '@/utils/i18n/i18n';
import { pluralise } from '@/utils/strings/string-manipulation';

export const getRoomsFromData = (
  rooms: APIRoom[],
  curatedImages?: CuratedImage[]
): Room[] =>
  rooms
    .map((roomData: APIRoom) => getRoomFromData({ roomData, curatedImages }))
    .filter(Boolean);

export const getRoomFromData = ({
  roomData,
  curatedImages,
}: {
  roomData: APIRoom;
  curatedImages?: CuratedImage[];
}): Room => {
  const userFlags = userFlagsForRoom(
    delve(roomData, 'relationships.currentUserData.data.attributes')
  );

  const industriesData =
    delve(roomData, 'relationships.projectCategories.data') ||
    delve(roomData, 'relationships.categories.data');

  const industries = industriesData
    ? // LFC isn't used anymore, so filter it out
      getCategoriesFromData(industriesData)?.filter(
        (i: Category) => i.name !== LOOKING_FOR_COLLABORATORS_CATEGORY_NAME
      )
    : undefined;

  const ownerData = delve(roomData, 'relationships.owner.data');
  const owner = ownerData ? getUserFromData(ownerData) : undefined;

  const membersData = delve(roomData, 'relationships.collaborators.data');
  const members = membersData
    ? getUsersFromData([ownerData, ...membersData])
    : [];

  const memberCount = roomData.attributes.userCount || members.length;
  const isOpen = roomData.attributes.visibility === ProjectVisibility.open;

  const chatRoomData = delve(roomData, 'relationships.chatRoom.data');
  const chatRoom = chatRoomData ? getChatRoomFromData(chatRoomData) : undefined;

  const rootBlockId = delve(roomData, 'relationships.rootBlock.data.id');

  const locationData = delve(roomData, 'relationships.location.data');
  const location = locationData ? getLocationFromData(locationData) : undefined;

  const collaborationRequests = getCollaborationRequestsFromData(
    delve(roomData, 'relationships.collaborationRequests.data')
  ).filter((x) => x.status === 'pending');

  const nowMs = Date.now();
  const createdAtMs = new Date(roomData.attributes.createdAt).getTime();
  const timeSinceCreatedMs = nowMs - createdAtMs;

  const viewsCount = roomData.attributes.viewsCount;
  const viewsCountLabel =
    timeSinceCreatedMs >= SHOW_VIEWS_COUNT_AFTER_TIME ||
    viewsCount >= SHOW_VIEWS_COUNT_AFTER_VIEWS
      ? `${Number(viewsCount).toLocaleString()} ${pluralise(
          t('view'),
          viewsCount
        )}`
      : t('New');

  const workshopBatchData = delve(roomData, 'relationships.workshopBatch.data');
  const workshopBatch = workshopBatchData
    ? getWorkshopBatchFromData(workshopBatchData)
    : undefined;

  // BACKGROUND
  const backgroundImageData = delve(
    roomData,
    'relationships.backgroundImage.data'
  );
  let backgroundImage: CuratedImage | undefined = backgroundImageData
    ? getCuratedImageFromData(backgroundImageData)
    : curatedImages
    ? getDefaultBackground(curatedImages)
    : undefined;

  if (backgroundImage && workshopBatch) {
    // If this is a workshop room, prevent the randomly selected background image (which isn't shown)
    // from overriding the contrast mode

    backgroundImage = {
      ...backgroundImage,
      contrastMode: ContrastMode.light,
    };
  }

  // SPEAKING USERS
  const speakingUsersData = delve(roomData, 'relationships.speakingUsers.data');
  const speakingUsers = speakingUsersData
    ? getUsersFromData(speakingUsersData)
    : undefined;

  return {
    id: roomData.id,
    ...roomData.attributes,
    ...userFlags,
    description: workshopBatch?.workshop?.description
      ? workshopBatch.workshop?.description
      : roomData.attributes.description,
    viewsCountLabel,
    viewsCount,
    industries,
    owner,
    members,
    memberCount,
    chatRoom,
    rootBlockId,
    location,
    collaborationRequests,
    backgroundImage,
    workshopBatch,
    isSaved: true,
    isOpen,
    speakingUsers,
  };
};

export const getDefaultBackground = (
  curatedImages?: CuratedImage[]
): CuratedImage | undefined => {
  if (!curatedImages) {
    return undefined;
  }

  return curatedImages.find(
    (ci: CuratedImage) => ci.label === ROOM_FALLBACK_IMAGE_LABEL
  );
};

const getChatRoomFromData = (chatRoomData: any) => {
  if (!chatRoomData) return undefined;

  return {
    chatRoomUuid: chatRoomData.id,
    chatRoomId: chatRoomData.attributes.roomID,
  };
};

export const userFlagsForRoom = (currentUserAttr: any) => {
  if (!currentUserAttr) return undefined;

  return {
    ...currentUserAttr,
    isMember: currentUserAttr.isCollaborator || currentUserAttr.isOwner,
  };
};
